import styled from 'styled-components';

export const MainContainer = styled.div`
 display: flex;
 flex-flow: column nowrap;
 gap: 1rem;
 height: 100%;
`;

export const ButtonContainer = styled.div`
 display: flex;
 flex-flow: column nowrap;
 gap: 1rem;
 height: 100%;
 justify-content: flex-end;
`;

export const Title = styled.span`
 font-size: 1.3rem;
 font-weight: 700;
 text-align: center;
 margin-bottom: 1rem;
`;

export const ModalTitle = styled.div`
 text-align: center;
 align-items: center;
 padding: 0 auto;
 font-size: 1.5rem;
 margin-bottom: 1rem;
 font-weight: bold;
`;

export const Subtitle = styled.div`
 height: 50%;
 width: 100%;
 margin: 0;
 font-size: 1rem;
 margin-bottom: 2rem;
`;

export const Text = styled.div`
 font-size: 1rem;
 height: 50%;
 width: 100%;
 margin: 0;
 margin-bottom: 1rem;
`;
export const TermText = styled.p`
 margin: 0;
 font-size: 0.8rem;
 a {
  color: var(--primary);
  font-size: 0.8rem;
 }
`;
