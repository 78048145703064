import styled from 'styled-components';

export const SpanStyled = styled.span`
 font-style: normal;
 font-weight: bold;
 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
 'Helvetica Neue', sans-serif;
 color: #6f6f6f;
 display: inline;
`;

export const SpanSub = styled.span`
 display: inline;
 font-size: 0.9rem;
 font-style: normal;
 font-weight: bold;
 color: #42c1c7;
 cursor: pointer;
`;
