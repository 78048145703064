/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { Container } from './styles';

const PasswordParams = ({ value }) => (
  <Container>
    <span>Sua senha deve conter:</span>
    <span className={value ? (value?.length > 7 ? 'text-success' : 'text-error') : ''}>
      Mínino de 8 dígitos
    </span>
    <span className={value ? (value?.toUpperCase() !== value ? 'text-success' : 'text-error') : ''}>
      Pelo menos 1 letra minúscula
    </span>
    <span className={value ? (value?.toLowerCase() !== value ? 'text-success' : 'text-error') : ''}>
      Pelo menos 1 letra maiúscula
    </span>
    <span className={value ? (/\d/g.test(value) ? 'text-success' : 'text-error') : ''}>
      Pelo menos 1 número
    </span>
    <span className={value ? (/\W+/g.test(value) ? 'text-success' : 'text-error') : ''}>
      Pelo menos 1 caractere especial
    </span>
  </Container>
);

export default PasswordParams;
