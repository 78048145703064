/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  Container, IconContainer, Title, Text, ButtonDisposition, TextContainer, Span,
} from './styles';
import { useRefresh } from '../../../hooks/refresh';
import { useSettings } from '../../../hooks/settings';
import ButtonN from '../../../components/ButtonN';
import { useRefreshPj } from '../../../hooks/refreshPj';

export function PartnerInfo() {
  const { basicData } = useRefresh();
  const { settings } = useSettings();
  const { name } = basicData;
  const [multiplePartners, setMultiplePartners] = React.useState(false);

  const { partner, getPartners, setStep } = useRefreshPj();

  React.useEffect(() => {
    const allPartners = getPartners();
    if (allPartners.partners.length > 1) {
      setMultiplePartners(true);
    }
  }, [getPartners]);

  const nextStep = () => {
    setStep('partners');
  };

  return (
    <>
      <Container>
        <IconContainer>
          <FontAwesomeIcon icon={faCheck} className="fa-lg" />
        </IconContainer>
        <TextContainer>
          <Title>
            Sócio registrado com sucesso!
          </Title>
          <Text>
            Você ainda precisa cadastrar outros
            <Span> {partner.partners.length} {multiplePartners ? 'sócios' : 'sócio'} </Span>
            para conseguir acessar a sua conta.
          </Text>
        </TextContainer>
        <ButtonDisposition>
          <ButtonN type="primary" className="botao" onClick={nextStep}>
            Convidar sócios
          </ButtonN>
        </ButtonDisposition>
      </Container>
    </>
  );
}
