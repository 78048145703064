/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import html2canvas from 'html2canvas';
import { Skeleton } from 'antd';

import { FaDownload } from 'react-icons/fa';

import {
  Container, Header,
} from './styles';

import api from '../../../services/api';

import TedIn from './types/tedIn';
import PixIn from './types/pixIn';
import PixOut from './types/pixOut';
import TedOut from './types/tedOut';
import Billet from './types/billet';
import Payment from './types/payment';
import AvulsePaymentNmPay from './types/avulsePaymentNmPay';
import ChargePaymentNmPay from './types/chargePaymentNmPay';

function Receipt({ item, onCancel, settings }) {
  const [loading, setLoading] = useState(true);
  const [clickIcon, setClickIcon] = useState(false);
  const [receiptData, setReceiptData] = useState({});
  const [type, setType] = useState('');
  const [credit, setCredit] = useState(false);

  const loadReceipt = useCallback(async () => {
    if (item.id) {
      setLoading(true);
      if (item.type === 'AVULSE-PAYMENT' || item.type === 'CHARGE-PAYMENT') {
        setType(item.type);
        setLoading(false);

        setReceiptData(item);
      } else {
        try {
          const { data } = await api.get(`transactions/${item.id}`);
          setCredit(data.credit);
          setType(data.type);
          setReceiptData(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      }
    }
  }, [item]);

  useEffect(() => {
    loadReceipt();
  }, [loadReceipt]);

  const downloadURI = (uri, name) => {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
  };

  async function handleImage() {
    await setClickIcon(() => true);
    html2canvas(document.getElementById('container-receipt')).then((canvas) => {
      const myImage = canvas.toDataURL();
      downloadURI(myImage, 'comprovante.png');
    });
    onCancel();
  }

  return (
    <Container id="container-receipt" style={{ padding: clickIcon ? '24px' : '0' }}>
      {loading ? (
        <>
          <Skeleton active loading={loading} />
          <Skeleton active loading={loading} />
          <Skeleton active loading={loading} />
        </>
      ) : (
        <>
          <Header iconcolor={settings.colors.primary}>
            <img
              src={settings.default_logo}
              style={{
                marginLeft: 0,
                maxWidth: 105,
                maxHeight: 40,
              }}
              alt="Logo"
            />

            {!clickIcon && (
              <div onClick={handleImage}>
                <FaDownload />
              </div>
            )}
          </Header>
          <>
            {receiptData && (type === 'PIX') && credit && (
            <PixIn item={receiptData} settings={settings} />
            )}

            {receiptData && (type === 'PIX') && !credit && (
            <PixOut item={receiptData} settings={settings} />
            )}

            {receiptData && (type === 'TED' || type === 'TEF') && credit && (
              <TedIn item={receiptData} settings={settings} />
            )}

            {receiptData && (type === 'TED' || type === 'TEF') && !credit && (
            <TedOut item={receiptData} settings={settings} />
            )}

            {receiptData && type === 'BILLET' && (
              <Billet item={receiptData} settings={settings} />
            )}

            {receiptData && type === 'PAYMENT' && (
            <Payment item={receiptData} settings={settings} />
            )}

            {receiptData && type === 'AVULSE-PAYMENT' && (
            <AvulsePaymentNmPay item={receiptData} settings={settings} />
            )}

            {receiptData && type === 'CHARGE-PAYMENT' && (
            <ChargePaymentNmPay item={receiptData} settings={settings} />
            )}
          </>
        </>
      )}

    </Container>
  );
}

export default Receipt;
