import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${(props) => props.width};
  height: 73px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 70%;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
`;

export const SolidText = styled.span`
  width: fit-content;
  height: 100%;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

export const EditibleDiv = styled.div`
  border-radius: 3px;
  min-width:35px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => (props.color ? '#000000' : '#6F6F6F')};
  :active{
    color: #000000;
  }
`;

export const DisplayUrl = styled.div`
  border-radius: 3px;
  width:fit-content;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: '#6F6F6F';

`;

export const TextHolder = styled.div`
  width: 100%;
  height: 35%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: 600;

  p {
    color: red;
    margin:0;
    padding:0;
  }
`;
