import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100%;
  width:fit-content;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;

`;

export const FileInput = styled.div`
  width: 24px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #6F6F6F;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
`;
