import styled from 'styled-components';

export const Wrapper = styled.div`
  height:73px;
  min-width: 136px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;


  span {
    font-size: 14px;
    font-weight:bold;
  }
`;

export const Label = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  p{
    color: red;
    margin:0;
    padding:0;
  }
`;

export const InputContainer = styled.div`
  height: 40px;
  width:100%;
  border-radius:5px;
  border: 1px solid;
  border-color:${(props) => (props.isValid ? '#E0E0E0' : '#DA1E28')};
  background-color:${(props) => (props.isDisabled ? '#F5F5F5' : '#FFFFFF')};
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

`;

export const Input = styled.input`
  height: 100%;
  width:100%;
  border-radius:5px;
  font-family: 'Roboto', sans-serif;
  color:#393939;
  outline: none;
  border: none;
  ::placeholder {
    color: #A8A8A8;
    font-family: 'Roboto', sans-serif;
  }
`;

export const Alert = styled.div`
  width:100%;
  padding: 0px 10px;
  height: 25px;
  overflow: hidden;
  display: flex;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: center;
  color: red;
  font-size:0.6rem;
  position: absolute;
  font-weight:bold;
  bottom: -30%;
  left:0;
`;
