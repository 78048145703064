import styled from 'styled-components';

export const Title = styled.span`
 font-size: 1rem;
 font-weight: 700;
 text-align: center;
`;
export const SubTitle = styled.div`
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 width: 100%;
 gap: 0.5rem;

 span:first-child {
  color: inherit;
  font-size: 0.85rem;
  font-weight: 700;
  text-align: center;
 }

 span {
  font-size: 0.75rem;
  color: #6f6f6f;
 }
`;
export const PepBoxStyled = styled.div`
 width: 100%;
 height: 5%;
 display: flex;
 justifycontent: flex-start;
 alignitems: flex-start;
 p {
  display: inline-block;
  maxheight: 100%;
  cursor: pointer;
  margin: 0;
 }
 svg {
  color: var(--secondary);
  cursor: pointer;
  width: 5%;
  height: 50%;
 }
`;
export const ModalStyle = styled.div`
 header {
  font-size: 1.8rem;
  font-weight: 800;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--label);
 }
`;
