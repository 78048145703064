/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/pt-br';
import {
  Divider, Table, notification, Pagination,
} from 'antd';
import { useHistory } from 'react-router-dom';

import 'react-day-picker/lib/style.css';
import {
  format, parseISO, startOfMonth, isValid,
} from 'date-fns';
import DayPicker from 'react-day-picker/DayPickerInput';
import { FaCalendarAlt } from 'react-icons/fa';
import {
  getVibeEcommerceSalePaymentType,
  getVibeEcommerceSaleStatus,
  HandleFormatMoney,
} from '../../../services/utils';
import { useAuth } from '../../../hooks/auth';
import {
  Content,
  TabsContainer,
  Tab,
  FilterContainer,
  FilterDate,
  ReactSelect,
} from './styles';
import Loading from '../../../components/Loading';
import vibeApi from '../../../services/vibe';

import { useSettings } from '../../../hooks/settings';
import useOnClickOutside from '../../../hooks/onClickOutside';

const { Column } = Table;
const clientIdVibe = '67d38776-d2ef-4e5f-a4db-112bd671c2a1';

const Evibe = () => {
  const { token, user } = useAuth();
  const { settings } = useSettings();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  //   const [totalItemsPage, setTotalItemsPage] = useState(0);
  const [currentTab, setCurrentTab] = useState('all');

  const paymentsSelectOptions = [
    {
      label: 'Todos',
      value: 'all',
    },
    {
      label: 'Crédito',
      value: 'credit',
    },
    {
      label: 'Débito',
      value: 'debit',
    },
    {
      label: 'Boleto',
      value: 'billet',
    },

  ];

  const [createdAtStart, setCreatedAtStart] = useState(
    format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  );
  const [createdAtEnd, setCreatedAtEnd] = useState(
    format(new Date(), 'yyyy-MM-dd'),
  );
  const [splittedAtStart, setSplittedAtStart] = useState(
    format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  );
  const [splittedAtEnd, setSplittedAtEnd] = useState(
    format(new Date(), 'yyyy-MM-dd'),
  );
  const [paymentType, setPaymentType] = useState('all');

  const createdAtStartRef = useRef(null);
  const createdAtEndRef = useRef(null);
  const splittedAtStartRef = useRef(null);
  const splittedAtEndRef = useRef(null);
  const refStartCalendar = useRef();
  const refEndCalendar = useRef();

  const [sales, setSales] = useState(null);

  useOnClickOutside(refStartCalendar, () => createdAtStartRef.current.handleInputBlur());
  useOnClickOutside(refEndCalendar, () => createdAtEndRef.current.handleInputBlur());
  useOnClickOutside(refStartCalendar, () => splittedAtStartRef.current.handleInputBlur());
  useOnClickOutside(refEndCalendar, () => splittedAtEndRef.current.handleInputBlur());

  const createdAtStartDate = useMemo(
    () => format(parseISO(createdAtStart), 'dd/MM/yyyy'),
    [createdAtStart],
  );
  const createdAtEndDate = useMemo(
    () => format(parseISO(createdAtEnd), 'dd/MM/yyyy'),
    [createdAtEnd],
  );

  const splittedAtStartDate = useMemo(
    () => format(parseISO(splittedAtStart), 'dd/MM/yyyy'),
    [splittedAtStart],
  );

  const splittedAtEndDate = useMemo(
    () => format(parseISO(splittedAtEnd), 'dd/MM/yyyy'),
    [splittedAtEnd],
  );

  function formatDate(date) {
    return format(date, 'dd/MM/yyyy');
  }

  const handleChangeCreatedAtDate = useCallback((day, type) => {
    if (isValid(day)) {
      const formatDay = format(day, 'yyyy-MM-dd');
      if (type === 'start') {
        setCreatedAtStart(formatDay);
      } else {
        setCreatedAtEnd(formatDay);
      }
    }
  }, []);

  const handleSplittedChangeDate = useCallback((day, type) => {
    if (isValid(day)) {
      const formatDay = format(day, 'yyyy-MM-dd');
      if (type === 'start') {
        setSplittedAtStart(formatDay);
      } else {
        setSplittedAtEnd(formatDay);
      }
    }
  }, []);

  const filterSales = useCallback(
    async ({
      page = 0,
      payment_type,
      splitted,
      splittedAtStartFilter,
      splittedAtEndFilter,
      createdAtStartFilter,
      createdAtEndFilter,
    }) => {
      const params = {
        ...(typeof splitted !== 'string' && { splitted }),
        ...(splittedAtStartFilter
          && splittedAtEndFilter && currentTab === 'splitted' && {
          splittedAtEnd: new Date(
            Number(splittedAtEndFilter.split('-')[0]),
            Number(splittedAtEndFilter.split('-')[1] - 1),
            Number(splittedAtEndFilter.split('-')[2]),
          ),
          splittedAtStart: new Date(
            Number(splittedAtStartFilter.split('-')[0]),
            Number(splittedAtStartFilter.split('-')[1] - 1),
            Number(splittedAtStartFilter.split('-')[2]),
          ),
        }),
        ...(payment_type !== 'all' && { payment_type }),
        ...(createdAtStartFilter
          && createdAtEndFilter && {
          createdAtStart: new Date(
            Number(createdAtStartFilter.split('-')[0]),
            Number(createdAtStartFilter.split('-')[1] - 1),
            Number(createdAtStartFilter.split('-')[2]),
          ),
          createdAtEnd: new Date(
            Number(createdAtEndFilter.split('-')[0]),
            Number(createdAtEndFilter.split('-')[1] - 1),
            Number(createdAtEndFilter.split('-')[2]),
          ),
        }),
      };

      try {
        setLoading(true);
        const { data } = await vibeApi.get('/sales', {
          headers: {
            authorization: `Bearer ${token}`,
          },
          params: {
            page,
            ...params,
          },
        });

        setSales(data.items);
        setCurrentPage(Number(data.current_page));
        setTotalPages(Number(data.total_pages));
        setTotalItems(Number(data.total_items));
        setLoading(false);
      } catch (err) {
        notification.error({ message: 'Não foi possível buscar os dados de venda.' });
      } finally {
        setLoading(false);
      }
    },

    [token, currentTab],
  );

  useEffect(() => {
    if (String(user.client_setting.client_id) !== clientIdVibe) history.push('/transaction');

    filterSales({
      page: currentPage,
      payment_type: paymentType,
      splitted: currentTab,
      splittedAtStartFilter: splittedAtStart,
      splittedAtEndFilter: splittedAtEnd,
      createdAtStartFilter: createdAtStart,
      createdAtEndFilter: createdAtEnd,
    });
  }, [
    createdAtEnd,
    createdAtStart,
    currentPage,
    currentTab,
    filterSales,
    history,
    paymentType,
    splittedAtEnd,
    splittedAtStart,
    user.client_setting.client_id,
  ]);

  const formattedSales = useMemo(
    () => sales?.map((sale) => ({
      id: sale.id,
      customer_name: sale.customer_firstname,
      value: sale.total_value ? Number(sale.total_value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }) : '-',
      seller_value: sale.seller_value ? Number(sale.seller_value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }) : '-',
      payment_type: getVibeEcommerceSalePaymentType(sale.payment_type),
      status: getVibeEcommerceSaleStatus(sale.status),
      created_at:
          sale.created_at && format(parseISO(String(sale.created_at)), 'dd/MM/yyyy'),
      transaction_date: sale.splitted
        ? format(parseISO(String(sale.transaction_date)), 'dd/MM/yyyy')
        : 'Pendente',
      installments: sale.installments,
    })),
    [sales],
  );

  return (
    <>
      <Loading hasLoading={loading} />
      <h1>e-Vibe</h1>

      <div style={{ display: 'flex' }}>
        <TabsContainer>
          <Tab
            onClick={() => {
              setCurrentTab('all');
            }}
            selected={currentTab === 'all'}
          >
            <span>Todos</span>
          </Tab>

          <Tab
            onClick={() => {
              setCurrentTab('splitted');
            }}
            selected={currentTab === 'splitted'}
          >
            <span>Repassados</span>
          </Tab>

          <Tab
            onClick={() => {
              setCurrentTab('not_splitted');
            }}
            selected={currentTab === 'not_splitted'}
          >
            <span>Pendentes</span>
          </Tab>
        </TabsContainer>

        <ReactSelect
          id="payment_type_select"
          placeholder="Tipo de pagamento"
          options={paymentsSelectOptions}
          onChange={(e) => setPaymentType(e.value)}
        />
      </div>

      <FilterContainer>
        <FilterDate>
          <span>Data da venda:</span>

          <div className="DayPickerContainer">
            <span>De:</span>

            <section ref={refStartCalendar}>
              <DayPicker
                ref={createdAtStartRef}
                isDisabled
                dayPickerProps={{
                  localeUtils: MomentLocaleUtils,
                  locale: 'pt-br',
                }}
                format="dd/MM/yyyy"
                formatDate={formatDate}
                value={createdAtStartDate}
                onDayChange={(day) => handleChangeCreatedAtDate(day, 'start')}
              />
              <FaCalendarAlt
                color={settings.colors.primary}
                onClick={() => createdAtStartRef.current.handleInputClick()}
              />
            </section>

            <span>Até:</span>

            <section ref={refEndCalendar}>
              <DayPicker
                ref={createdAtEndRef}
                dayPickerProps={{
                  localeUtils: MomentLocaleUtils,
                  locale: 'pt-br',
                }}
                format="dd/MM/yyyy"
                formatDate={formatDate}
                onDayChange={(day) => handleChangeCreatedAtDate(day, 'end')}
                value={createdAtEndDate}
              />
              <FaCalendarAlt
                color={settings.colors.primary}
                onClick={() => createdAtEndRef.current.handleInputClick()}
              />
            </section>
          </div>
        </FilterDate>

        <FilterDate>
          <span hidden={currentTab !== 'splitted'}>Data do repasse:</span>

          <div hidden={currentTab !== 'splitted'} className="DayPickerContainer">
            <span>De:</span>
            <section>
              <DayPicker
                ref={splittedAtStartRef}
                isDisabled
                dayPickerProps={{
                  localeUtils: MomentLocaleUtils,
                  locale: 'pt-br',
                }}
                format="dd/MM/yyyy"
                formatDate={formatDate}
                value={splittedAtStartDate}
                onDayChange={(day) => handleSplittedChangeDate(day, 'start')}
              />
              <FaCalendarAlt
                color={settings.colors.primary}
                onClick={() => splittedAtStartRef.current.handleInputClick()}
              />
            </section>

            <span hidden={currentTab !== 'splitted'}>Até:</span>

            <section ref={refEndCalendar} hidden={currentTab !== 'splitted'}>
              <DayPicker
                ref={splittedAtEndRef}
                dayPickerProps={{
                  localeUtils: MomentLocaleUtils,
                  locale: 'pt-br',
                }}
                format="dd/MM/yyyy"
                formatDate={formatDate}
                onDayChange={(day) => handleSplittedChangeDate(day, 'end')}
                value={splittedAtEndDate}
              />
              <FaCalendarAlt
                color={settings.colors.primary}
                onClick={() => splittedAtEndRef.current.handleInputClick()}
              />
            </section>
          </div>
        </FilterDate>
      </FilterContainer>

      <Divider />

      <Table
        dataSource={formattedSales}
        pagination={false}
        rowKey="id"
        loading={loading}
        locale={{ emptyText: 'Nenhuma venda para exibir.' }}
        scroll={{ x: true }}
      >
        <Column
          title="Nome do cliente"
          dataIndex="customer_name"
          key="customer_name"
          responsive={['xs', 'sm', 'md', 'xl']}
        />

        <Column
          title="Valor"
          dataIndex="value"
          key="value"
          responsive={['xs', 'sm', 'md', 'xl']}
        />

        <Column
          title="Valor Repassado"
          dataIndex="seller_value"
          key="seller_value"
          responsive={['xs', 'sm', 'md', 'xl']}
        />

        <Column
          title="Natureza da Venda"
          dataIndex="payment_type"
          key="payment_type"
          responsive={['xs', 'sm', 'md', 'xl']}
        />
        <Column
          title="Situação"
          dataIndex="status"
          key="status"
          responsive={['xs', 'sm', 'md', 'xl']}
        />

        <Column
          title="Parcelas"
          dataIndex="installments"
          key="installments"
          responsive={['xs', 'sm', 'md', 'xl']}
        />

        <Column
          title="Data da venda"
          dataIndex="created_at"
          key="created_at"
          responsive={['xs', 'sm', 'md', 'xl']}
        />

        <Column
          title="Data do repasse"
          dataIndex="transaction_date"
          key="transaction_date"
          responsive={['xs', 'sm', 'md', 'xl']}
        />

      </Table>
      {sales && (
      <Pagination
        current={currentPage + 1}
        onChange={(page) => { setCurrentPage(page - 1); }}
        total={totalItems}
        pageSize={20}
        style={{ textAlign: 'end', marginTop: '16px' }}
      />
      )}
      <Content />
    </>
  );
};

export default Evibe;
