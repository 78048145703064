import styled from 'styled-components';

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    
    svg{
        margin-bottom: 12px;
        margin-right: 12px;
        cursor: pointer;

        :hover{
            color: #707070;
        }
    }
`;

export const InfosContainer = styled.div`
    grid-area: 'card';
    background: #fafafa;
    border-radius: 8px;
    padding: 16px;

    h1 {
        color: #707070;
    }

    @media only screen and (max-width: 1380px){
        background: #fff;
    }

    div.info{
        margin-top: 10px;
        padding: 12px;
        border-top: 2px solid #808080;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #707070;

        div.title {
            font-size: 18px;
        }

        div.values {
            font-size: 18px;
            display: flex;
            justify-content: space-between;

            div{
                display: flex;
                flex-direction: column;

                & + div {
                    margin-left: 16px;
                }
            }
        }

        @media only screen and (max-width: 1380px){
            display: none;
        }
    }            
`;

export const GridContainer = styled.div`
    form {
        margin-top: 10px;
        grid-area: form;

        div.info{
            margin-top: 16px;
            background: #fafafa;
            padding: 16px;
            
            @media only screen and (max-width: 1380px){
                display: block;
            }

            div {
                font-size: 16px;
                display: flex;
                align-items: center;

                p + p {
                    margin-left: 36px;
                }
            }

            display: none;
            color: #707070
        }       
    }
    
    @media (min-width:1360px){
        display: grid;
        grid-template-columns: 70% 30%; 
        grid-template-areas: 'form card';
        gap: 16px
    }

    @media only screen and (max-width: 1380px){
        display: flex;
        flex-direction: column;

        @media (max-width: 768px){
            align-items: center;
        }
    }
`;
