import { useEffect, useState, React } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import InputN from '../../../../components/InputN';
import { useRefresh } from '../../../../hooks/refresh';
import { getAddress as getAddressService } from '../../../../services/getAddress';
import { federationUnitList } from '../../../../utilitys/federationUnitList';
import { Container, Title } from '../../styles';
import ButtonN from '../../../../components/ButtonN';
import { listOfNationality } from '../../../../utilitys/listOfNationality';
import { NumberContainer } from './styles';

export function FormAddress() {
  const {
    setStep, address, setAddress,
  } = useRefresh();
  const { getAddressWithCep, getAddressWithStateInitials } = getAddressService();
  const [citiesList, setCitiesList] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [zipCodeValid, setZipCodeValid] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [whithoutNumber, setWhithoutNumber] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const handleAddress = async () => {
      try {
        const {
          street,
          city,
          state: { initials: state },
          neighborhood,
        } = await getAddressWithCep(address.zipCode);

        setInputDisabled(street.length > 0 || neighborhood.length > 0);
        setAddress({
          ...address, zipCode: address.zipCode, street, city, state, neighborhood,
        });
        setWhithoutNumber(false);
        setZipCodeValid(true);
      } catch (error) {
        setZipCodeValid(false);
      }
    };

    if (address.zipCode.length < 8) {
      setAddress({
        zipCode: address.zipCode,
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      });
      setZipCodeValid(false);
      setWhithoutNumber(false);
      return;
    }

    handleAddress();
  }, [address.zipCode]);

  const searchAddressWithStateInitials = async (stateInitials) => {
    try {
      const response = await getAddressWithStateInitials(stateInitials);
      setCitiesList(response.map(({ id, name }) => ({ id, name })));
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (key, value) => {
    setAddress({ ...address, [key]: value });
  };

  const {
    zipCode, street, number, complement, neighborhood, city, state,
  } = address;

  const handleNext = async () => {
    setStep('address');
  };

  const buttonDisabled = [zipCode?.lenght < 8 && street && (number || whithoutNumber) && neighborhood && city && state].every((item) => item);

  return (
    <Container>
      <InputN.Cep
        id="cep"
        label={t('CEP')}
        delimiter="-"
        blocks={[5, 3]}
        onChange={({ target: { rawValue } }) => handleChange('zipCode', rawValue)}
        value={zipCode}
        placeholder="Insira o CEP"
        disabled={loading}
        className={zipCodeValid ? 'is-valid' : 'is-invalid'}
        ableClass={false}
        numericOnly
      />
      <InputN
        maxLength={100}
        id="street"
        label={t('Rua')}
        onChange={(
          { target: { value } },
        ) => (
          handleChange('street', value.replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ  ]/g, '')))}
        value={street}
        disabled={inputDisabled}
      />
      <NumberContainer>
        <InputN
          id="number"
          label={t('Número')}
          onChange={({ target: { value } }) => handleChange('number', value.replace(/[^0-9]/g, ''))}
          value={number}
          disabled={whithoutNumber}
        />
        <Checkbox
          style={{
            alignItems: 'baseline', paddingTop: '1rem', whiteSpace: 'nowrap', fontSize: '0.75rem',
          }}
          onChange={(e) => {
            if (e.target.checked) {
              handleChange('number', 'S/N');
              setWhithoutNumber(true);
            } else {
              handleChange('number', '');
              setWhithoutNumber(false);
            }
          }}
          checked={whithoutNumber}
          disabled={loading}
        >
          Sem Número
        </Checkbox>
      </NumberContainer>
      <InputN
        maxLength={100}
        id="complement"
        label={t('Complemento')}
        onChange={({ target: { value } }) => handleChange('complement', value)}
        value={complement}
      />
      <InputN
        maxLength={100}
        id="neighborhood"
        label={t('Bairro')}
        onChange={(
          { target: { value } },
        ) => handleChange('neighborhood', value.replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ  ]/g, ''))}
        value={neighborhood}
        disabled={inputDisabled}
      />
      <InputN.SelectandSearchModal
        maxLength={2}
        id="estado"
        label="Estado"
        readOnly
        disabled
        placeholder="Escolha uma opção"
        value={state}
        onChange={({ target: { value } }) => {
          searchAddressWithStateInitials(value);
          handleChange('state', value);
        }}
        list={federationUnitList}
        needSearchInput={false}
        title={<Title>{t('Escolha a Unidade Federativa a qual você pertence')}</Title>}
      />
      <InputN.SelectandSearchModal
        maxLength={100}
        id="cidade"
        label="Cidade"
        placeholder="Escolha uma opção"
        value={city}
        onChange={({ target: { value } }) => {
          handleChange('city', value);
        }}
        list={citiesList}
        disabled
        needSearchInput
        title={<Title>{t('Escolha a Unidade Federativa a qual você pertence')}</Title>}
      />
      <ButtonN style={{ marginBottom: '1rem' }} type="primary" onClick={handleNext} disabled={buttonDisabled}>
        Próximo
      </ButtonN>
    </Container>
  );
}
