import React, {
  useState, useCallback, useRef,
  useEffect,
} from 'react';

import { Form } from '@unform/web';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Container, Content } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { Row, Col } from '../../styles/components';
import formErrors from '../../services/formErrors';
import { useSettings } from '../../hooks/settings';
import Loading from '../../components/Loading';
import api from '../../services/api';
import Notification from '../../components/Notification';

function UpdatePassword() {
  const formRef = useRef(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [validation, setValidation] = useState(null);
  const [isUnblockActionEnable, setIsUnblockActionEnable] = useState();
  const { settings } = useSettings();
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const handleSubmit = useCallback(async (data) => {
    formRef.current.setErrors({});
    setButtonLoading(true);
    setError(false);

    try {
      const schema = Yup.object().shape({
        password: Yup.string().min(8, t('a senha deve conter no minimo 8 caracteres')),
        password_confirmation: Yup.string().required(t('a confirmacao de senha e obrigatoria'))
          .equals([data.password], t('a senha e a confirmacao nao conferem')),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('/forgot-password/change', {
        token: params.token,
        password: data.password,
      });

      setButtonLoading(false);
      setSuccess(isUnblockActionEnable
        ? t('a sua conta foi desbloqueada com sucesso e sua senha alterada')
        : t('sua senha foi alterada com sucesso'));
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        formRef.current.setErrors(errors);
        setButtonLoading(false);
        return;
      }

      setError(t('nao foi possivel alterar sua senha. tente novamente'));
    }
  }, [isUnblockActionEnable, params.token, t]);

  const verifyToken = useCallback(async () => {
    try {
      const { data: content } = await api.get(`/forgot-password/validate/${params.token}`);
      setIsUnblockActionEnable(content.is_unblock_action_enable);
      setValidation(content);
      setLoading(false);
    } catch (err) {
      setError(err.response.data.message);
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  if (loading) {
    return (
      <Loading hasLoading />
    );
  }

  return settings && settings.name ? (
    <Container>
      <Notification
        type="error"
        title={t('erro na validacao')}
        visible={!!error}
        hidden={() => { setError(false); history.replace('/'); }}
      >
        {error}
      </Notification>
      <Notification
        title={t('sucesso')}
        visible={!!success}
        hidden={() => { setSuccess(false); history.replace('/'); }}
      >
        {success}
      </Notification>
      <div className="content-logo">
        <img src={settings.default_logo} alt="Logo" />
      </div>

      <Content>
        <div>
          <h1 style={{ fontWeight: 'bold', textAlign: 'center' }}>
            {isUnblockActionEnable
              ? `${t('ola')} 
              ${validation?.user?.name}! \n ${t('altere sua senha abaixo para desbloquear sua conta')}`
              : `${t('ola')} 
              ${validation?.user?.name}! \n ${t('altere sua senha abaixo para desbloquear sua conta')}`}
          </h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <Input name="password" id="password" label={t('sua nova senha')} type="password" eye="true" />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} />
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <Input
                  name="password_confirmation"
                  id="password_confirmation"
                  label={t('confirme a nova senha')}
                  type="password"
                  eye="true"
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <Button center right={false}>
                  {buttonLoading ? (
                    <span><LoadingOutlined /></span>
                  ) : <span>{t('confirmar')}</span>}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>

      </Content>
    </Container>
  ) : (<></>);
}

export default UpdatePassword;
