/* eslint-disable camelcase */
const formater = (step) => {
  const {
    additional: {
      cnae,
      number_of_affiliates,
      number_of_employees,
      number_of_partners,
      opening_date,
      share_capital,
      trading_name,
    },
    document,
    id: pjUserId,
    name,
    status,
  } = step.companie;

  return {
    cnpjData: {
      categoriaDeNegocios: cnae,
      numberOfBranches: number_of_affiliates,
      numberOfEmployees: number_of_employees,
      numberOfPartners: { number: number_of_partners },
      openingDate: opening_date,
      shareCapital: share_capital,
      corporateName: name,
      fantasyName: trading_name,
      associacaoSFL: false,
    },
    cpfCnpj: document,
    pjUserId,
    status,
    steplist: true,
  };
};

export default formater;
