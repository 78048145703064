/* eslint-disable no-shadow */
import React from 'react';
import { useShop } from '../../hooks/shop';

import { hours, minutes } from '../../utilitys/timeDeclaration';
import {
  Input,
  LabelWrapper,
  Option,
  Options,
  OptionsWrapper,
  Wrapper,
} from './styles';

const TimeInput = ({
  label, name, isRequired = false, isDisabled = false, isDisplay = false,
}) => {
  const [time, setTime] = React.useState({
    hours: '',
    minutes: '',
  });

  const [displayOptions, setDisplayOptions] = React.useState(false);
  const { setShopInfo, shopInfo, isOpenAllDay } = useShop();

  function handleHourChange(
    time,
    key,
  ) {
    return (event) => {
      event.stopPropagation();

      setTime((prevState) => ({ ...prevState, [key]: time }));
    };
  }

  function getTimeToDisplay() {
    if (isDisplay && shopInfo[name]) {
      const date = new Date(Number(shopInfo[name]));
      const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
      const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

      return `${hour}:${minute}`;
    }

    if (time.hours && time.minutes) return `${time.hours}:${time.minutes}`;
    if (time.hours) return `${time.hours}:`;
    if (time.minutes) return `:${time.minutes}`;

    return ':';
  }

  const renderHours = React.useMemo(() => hours.map((hour) => (
    <Option
      key={hour}
      onClick={handleHourChange(hour, 'hours')}
    >
      {hour}
    </Option>
  )), []);

  const renderMinutes = React.useMemo(() => minutes.map((minute) => (
    <Option
      key={minute}
      onClick={time.hours === '' ? null : handleHourChange(minute, 'minutes')}
      isDisabled={time.hours === ''}
    >
      {minute}
    </Option>
  )), [time.hours]);

  React.useEffect(() => {
    if (time.hours && time.minutes) {
      setDisplayOptions(false);
      const updatedDateInMilliseconds = new Date().setHours(time.hours, time.minutes);
      const updatedDate = new Date(updatedDateInMilliseconds);
      setShopInfo((prevState) => ({ ...prevState, [name]: updatedDate }));
    }
  }, [time, setShopInfo, name]);

  React.useEffect(() => {
    if (isOpenAllDay) {
      setDisplayOptions(false);
    }
  }, [isOpenAllDay]);

  return (
    <Wrapper>
      <LabelWrapper>
        <span>{label}</span>
        {isRequired && <p>*</p>}
      </LabelWrapper>

      <Input
        onClick={(isDisabled || isOpenAllDay) ? null : () => setDisplayOptions(!displayOptions)}
        isDisabled={isDisabled}
      >
        {getTimeToDisplay()}

        {displayOptions && (
          <OptionsWrapper>
            <Options>{renderHours}</Options>
            <Options>
              {renderMinutes}
            </Options>
          </OptionsWrapper>
        )}
      </Input>
    </Wrapper>
  );
};

export default TimeInput;
