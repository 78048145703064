/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { message as messageAntd, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SpanSub, SpanStyled } from './styles';
import ButtonN from '../../../../../../components/ButtonN';
import LowerLeftMessage from '../../../../../../components/LowerLeftMessage';
import { SubTitle, Title } from '../../../../styles';
import { InputReceiverCode } from '../../../../../../components/InputReceiverCode';
import { useSteps } from '../../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../../hooks/checkpoint';
import { useSettings } from '../../../../../../hooks/settings';
// import { handleCodeSend } from '../../../../../../services/endPointRegister/codeReceiver'
import api from '../../../../../../services/api';

const CodeReceiver = () => {
  const [, setError] = useState(false);
  const [, setMessage] = useState('');
  const [, setErroReSend] = useState(false);
  const { t } = useTranslation();
  const { settings } = useSettings();
  const {
    nextStep, prevStep, stepsPf, step, exactStep,
  } = useSteps();
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [inputs, setInputs] = useState({
    ssn1: '',
    ssn2: '',
    ssn3: '',
    ssn4: '',
    ssn5: '',
  });

  useEffect(() => {
    if (checkpoint?.email !== undefined) {
      exactStep(5);
    } else {
      exactStep(4);
      history.replace('/register/cellphone');
    }
  }, []);

  const handleNextStep = async () => {
    await setCheckpoint({
      ...checkpoint,
      emailConfirmationCode: { ...inputs },
    });
    if (checkpoint.cpfCnpj.length === 11) {
      setLoading(false);
      nextStep();
      if (stepsPf.length >= step + 1) {
        history.push(`/register/${stepsPf[step + 1]}`);
      } else {
        history.push(`/register/${stepsPf[step]}`);
      }
    }
  };

  const reqReceiverCode = async () => {
    setLoading(true);
    setMessage('');
    const ssn = inputs.ssn1 + inputs.ssn2 + inputs.ssn3 + inputs.ssn4 + inputs.ssn5;
    if (ssn.length === 5) {
      await api
        .patch(`onboarding/validate/email/${ssn}`, {
          client_name: settings.name,
          document: checkpoint.cpfCnpj,
          contact: checkpoint.email,
        })
        .then((response) => {
          setError(false);
          setLoading(false);
          setMessage(response?.data?.message);
          messageAntd.success('O email informado foi validado com sucesso.', 10);
          handleNextStep();
        })
        .catch((error) => {
          setLoading(false);
          setMessage(error?.response?.data?.message);
          messageAntd.error(
            'Erro na validação do email, verifique o código ou solicite um novo código de verificação.',
            10,
          );
          setError(true);
          console.error('reqReceiverCode ', error.response.data);
        });
    }
  };

  const handleSend = async () => {
    setError(false);
    setMessage('');
    await api
      .post('onboarding/send', {
        client_name: settings.name,
        document: checkpoint.cpfCnpj,
        contact: checkpoint.email,
      })
      .then((response) => {
        setLoading(false);
        setMessage(response.data.message);
        setError(false);
        setErroReSend(false);
        messageAntd.success('Um email foi enviado para o email informado.', 3);
      })
      .catch((error) => {
        setLoading(false);
        setError(false);
        setErroReSend(true);
        setMessage(error.response.data.message);
        messageAntd.error(error.response.data.message, 3);
        console.error('smsRequisition reenviar: ', error.response.data);
      });
  };

  return (
    <>
      <Title>Só mais alguns passos...</Title>
      <SubTitle>
        <header>Confirme seu e-mail</header>
        <span>Para continuar, insira o código que nós acabamos de enviar para o seu e-mail de contato: </span>
      </SubTitle>
      <SpanStyled>{checkpoint.email}</SpanStyled>
      <InputReceiverCode inputs={inputs} setInputs={setInputs} />
      {/* {erroReSend ? <Span message={message} /> : null} */}
      {/* {error ? <Span message={messages.codeInvalid} style={{ color: 'var(--error)' }} /> : null} */}
      <SpanSub onClick={() => handleSend()}>{t('Reenviar código')}</SpanSub>
      <ButtonN
        onClick={() => {
          prevStep();
          history.push(`/register/${stepsPf[exactStep(step - 1)]}`);
        }}
        className="botao"
      >
        {t('Alterar endereço de e-mail')}
      </ButtonN>
      <ButtonN
        loading={loading}
        disabled={!inputs.ssn1 || !inputs.ssn2 || !inputs.ssn3 || !inputs.ssn4 || !inputs.ssn5}
        onClick={() => reqReceiverCode()}
        type="primary"
        icon={loading && <Spin indicator={<LoadingOutlined />} />}
      >
        {!loading && t('Confirmar')}
      </ButtonN>
      <LowerLeftMessage />
    </>
  );
};

export default CodeReceiver;
