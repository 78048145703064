import PropTypes from 'prop-types';

export const InputPropTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.node,
  disabled: PropTypes.bool,
  id: (props, propName) => !!props.label
&& (props[propName] === undefined || typeof props[propName] !== 'string')
&& new Error('Id necessário ao utilizar uma label!'),
  label: PropTypes.node,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  type: PropTypes.string,
  value: PropTypes.node,
};

export const DefaultPropTypes = {
  className: '',
  defaultValue: undefined,
  disabled: undefined,
  id: undefined,
  label: undefined,
  maxLength: undefined,
  onChange: undefined,
  placeholder: undefined,
  style: undefined,
  type: undefined,
  value: undefined,
};

export const AntProps = {
  ...InputPropTypes,
  addonAfter: PropTypes.node,
  addonBefore: PropTypes.node,
  allowClear: PropTypes.bool,
  bordered: PropTypes.bool,
  onPressEnter: PropTypes.func,
  prefix: PropTypes.node,
  size: PropTypes.string,
  sufix: PropTypes.node,
};

export const DefaultAntProps = {
  ...DefaultPropTypes,
  addonAfter: undefined,
  addonBefore: undefined,
  allowClear: undefined,
  bordered: undefined,
  onPressEnter: undefined,
  prefix: undefined,
  size: undefined,
  sufix: undefined,
};
