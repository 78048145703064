/* eslint-disable prefer-template */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Webcam from 'react-webcam';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import FormData from 'form-data';
import {
  Section,
  HeaderArea,
  CamStyled,
  Preview,
  ButtonPicture,
  ButtonPhoto,
  Container,
  Box,
} from './styles';
import ButtonN from '../../../../../../components/ButtonN';
import { validationIdentityCard } from '../../../../../../services/endPointRegister/identityPost';

const CamToValidate = ({handleNext}) => {
  const token = localStorage.getItem('@qesh:token');
  const [image, setImage] = useState('');
  const webcamRef = React.useRef(null);
  const [photoData, setPhotoData] = useState({ photo: '' });
  const [loading, setLoading] = useState(false);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    setPhotoData({ ...photoData, photo: imageSrc });
  }, [webcamRef, photoData]);

  const videoConstraints = {
    width: 300,
    height: 400,
    facingMode: 'user',
  };

  const handleApiSelfie = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('file', photoData.photo);
  
    fetch(photoData.photo)
      .then((res) => res.blob())
      .then(async (blob) => {
        const file = new File([blob], 'selfie.png');
        try {
          await validationIdentityCard(file, token);
          message.success('Documento enviado com sucesso.', 4);
          setLoading(false)
          await handleNext()
        } catch (err) {
          setLoading(false);
          message.error('Tivemos um problema ao enviar sua foto.')
        }
      });
  };

  return (
    <>
      {/* {!cameraActive && message.error('Por favor ative sua camera')} */}
      <Section>
        <CamStyled>
          <>
            {image === '' ? (
              <Box>
                <Container>
                  <Webcam
                    audio={false}
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/png"
                    ref={webcamRef}
                    screenshotQuality={1}
                    imageSmoothing
                    mirrored
                  />
                </Container>
              </Box>
            ) : (
              <Preview src={image} />
            )}
          </>

          {image !== '' ? (
            <ButtonPhoto>
              <ButtonN
                style={{ background: 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  setImage('');
                }}
                disabled={loading}
              >
                Tirar outra
              </ButtonN>
              <ButtonN
                onClick={() => {
                  handleApiSelfie();
                }}
                type="primary"
                loading={loading}
                icon={loading && <Spin indicator={<LoadingOutlined />} />}
              >
                {loading ? 'Enviando' : 'Ficou boa'}
              </ButtonN>
            </ButtonPhoto>
          ) : (
            <ButtonPicture
              onClick={(e) => {
                e.preventDefault();
                capture(() => {});
              }}
            >
              <FontAwesomeIcon icon={faCamera} size="2x" color="#ffff" />
            </ButtonPicture>
          )}
        </CamStyled>
      </Section>
    </>
  );
};

export default CamToValidate;
