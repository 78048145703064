export const helpInfo = {
  password: {
    title: "Como criar uma senha segura?",
    text: ["• Utilize pelo menos 1 letra maiúscula","• Utilize pelo menos 1 número","• Utilize pelo menos 1 caractere especial","• Utilize pelo menos 8 caracteres"],
  },
  colorPicker:{
    title: "Cores da marca",
    text: ["Vão ser usadas na visualização da sua loja no site"],
  },
  url:{
    title: "Como funciona o site?",
    text: ["• Seu site ficará hospedado em nosso domínio principal","• Não utilize caracteres especiais e espaço","• O site ficará igual mostrado abaixo e não poderá ser alterado depois"],
  },
  minimun_purchase:{
    title: "O que é compra mínima?",
    text: ["É o valor mínimo que um cliente deve atingir para efetuar a compra em sua loja"],
  },
  default:{
    title: "Default",
    text: ["Default is the default value for this field."],
  }
}