/* eslint-disable radix */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from 'react';
import { InputStyled } from './styles';

export const InputFourDigitsPassword = ({ inputs, setInputs }) => {
  const handleFocus = (value, fieldIndex, maxLength) => {
    const previoussibling = document.querySelector(`input[name=ssn-${parseInt(fieldIndex) - 1}]`);
    if (fieldIndex > 1 && value === 'Backspace' && inputs[`ssn${fieldIndex}`] === '') {
      previoussibling.focus();
    }
    if (value.length >= maxLength && value !== 'Backspace') {
      if (parseInt(fieldIndex) < 5) {
        const nextSibling = document.querySelector(`input[name=ssn-${parseInt(fieldIndex) + 1}]`);
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };
  const handleChange = (e) => {
    const { maxLength, name, minLength } = e.target;
    const [, fieldIndex] = name.split('-');
    const value = e.key.replace(/\[^A-Z]*/gi, '');
    const testForSpecialCaracter = /[^0-9]/.test(e.key);
    if ((value.length === 1 && !testForSpecialCaracter) || value === '') {
      setInputs({
        ...inputs,
        [`ssn${fieldIndex}`]: value,
      });
    }
    if (value === 'Backspace') {
      setInputs({
        ...inputs,
        [`ssn${fieldIndex}`]: '',
      });
    }
    if ((!testForSpecialCaracter && value.length <= 1) || value === 'Backspace') {
      handleFocus(value, fieldIndex, maxLength, minLength);
    }
  };

  return (
    <div>
      <InputStyled
        type="password"
        maxLength={1}
        value={inputs.ssn1}
        name="ssn-1"
        onKeyDown={(e) => handleChange(e)}
      />
      <InputStyled
        type="password"
        maxLength={1}
        value={inputs.ssn2}
        name="ssn-2"
        onKeyDown={(e) => handleChange(e)}
      />
      <InputStyled
        type="password"
        maxLength={1}
        value={inputs.ssn3}
        name="ssn-3"
        onKeyDown={(e) => handleChange(e)}
      />
      <InputStyled
        type="password"
        maxLength={1}
        value={inputs.ssn4}
        name="ssn-4"
        onKeyDown={(e) => handleChange(e)}
      />
    </div>
  );
};
