import React, { useCallback, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { notification, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Payment from './Payment';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';
import { Row, Col } from '../../../../../styles/components';
import chargesApi from '../../../../../services/charges';
import { TitleContainer } from './Payment/styles';
import { useAuth } from '../../../../../hooks/auth';

function DetachedPayment() {
  const history = useHistory();

  const digitableLineFormRef = useRef(null);
  const [charge, setCharge] = useState(null);
  const [loading, setLoading] = useState(false);

  const { user, account, token } = useAuth();
  const handleValidate = useCallback(async (data) => {
    setLoading(true);
    try {
      const response = await chargesApi.get(`nmpay/detached-charges/validate/${data.barcode}`, {
        headers: {
          authorization: `Bearer ${token}`,
          user: user.id,
          account: account.id,
        },
        params: {
          installments: 1,
        },
      });

      setCharge(response.data);
      setLoading(false);
    } catch (e) {
      notification.error({
        message: 'Código de barras inválido.',
        description: 'Esse código de barras é inválido ou não pertence a uma instituição parceira.',
      });
      setLoading(false);
    }
  }, [user, account, token]);

  return (
    <>
      {!charge
        ? (
          <>
            <TitleContainer>
              <Tooltip title="voltar">
                <FiArrowLeft size={24} onClick={() => history.push('/educational-payments')} />
              </Tooltip>
              <h1>Pagamento de boleto avulso</h1>
            </TitleContainer>
            <Form onSubmit={handleValidate} ref={digitableLineFormRef} style={{ marginTop: '26px' }}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14} className="bottom">
                  <Input id="barcode" name="barcode" label="Digite o código de barras:" type="text" />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col md={24}>
                  <Button disabled={loading} right={(false)} type="submit">
                    {loading ? <span><LoadingOutlined /></span> : <span>Continuar</span> }
                  </Button>
                </Col>
              </Row>

            </Form>
          </>
        )
        : (
          <Payment payment={charge} backToEducationalPayments={() => setCharge(false)} />
        )}

    </>
  );
}

export default DetachedPayment;
