/* eslint-disable class-methods-use-this */
import axios from 'axios';
import shopApi from './shop';

class ShopService {
  async sendIcons(images) {
    try {
      const { data } = await shopApi.post('/shop/upload/icons', images);

      return data;
    } catch (error) {
      return false;
    }
  }

  async getHasStore(id) {
    try {
      const { data } = await shopApi.get(`/shop/exists/${id}`);
      return data;
    } catch (error) {
      return false;
    }
  }

  async getShopAdress(cep) {
    try {
      const { data } = await axios({
        method: 'get',
        url: `https://viacep.com.br/ws/${cep}/json/`,
      });

      return data;
    } catch (error) {
      return error.erro;
    }
  }

  async registerShop(shopInfo) {
    try {
      const { data } = await shopApi.post('/shop', shopInfo);

      return data;
    } catch (error) {
      return {
        error: true,
        message: error.response.data.error.messages[0],
      };
    }
  }
}

export default new ShopService();
