/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';

import { InputPropTypes, DefaultPropTypes } from './Types';
import { Container, StyledInput } from './styles';

// Cep input usando Cleave.js => https://nosir.github.io/cleave.js/
const Cep = (props) => {
  // Todos os valores disponíveis no options => https://github.com/nosir/cleave.js/blob/master/doc/options.md
  const {
    error,
    ableClass,
    blocks,
    className,
    creditCard,
    creditCardStrictMode,
    date,
    value,
    dateMin,
    dateMax,
    datePattern,
    delimiter,
    delimiters,
    delimiterLazyShow,
    lowercase,
    noImmediatePrefix,
    numeral,
    numericOnly,
    numeralIntegerScale,
    numeralDecimalMark,
    numeralDecimalScale,
    numeralPositiveOnly,
    numeralThousandsGroupStyle,
    onCreditCardTypeChanged,
    onValueChanged,
    phone,
    phoneRegionCode,
    prefix,
    rawValueTrimPrefix,
    signBeforePrefix,
    stripLeadingZeroes,
    swapHiddenInput,
    tailPrefix,
    time,
    timeFormat,
    timePattern,
    uppercase,
    loading,
    isValid,
    ...rest
  } = props;
  const options = {
    error,
    blocks,
    creditCard,
    creditCardStrictMode,
    date,
    dateMin,
    dateMax,
    datePattern,
    delimiter,
    delimiters,
    delimiterLazyShow,
    lowercase,
    noImmediatePrefix,
    numeral,
    numericOnly,
    numeralIntegerScale,
    numeralDecimalMark,
    numeralDecimalScale,
    numeralPositiveOnly,
    numeralThousandsGroupStyle,
    onCreditCardTypeChanged,
    onValueChanged,
    phone,
    phoneRegionCode,
    prefix,
    rawValueTrimPrefix,
    signBeforePrefix,
    stripLeadingZeroes,
    swapHiddenInput,
    tailPrefix,
    time,
    timeFormat,
    timePattern,
    uppercase,
    loading,
    isValid
  };


  const validCep = value.length <= 0 || /\d{8}/g.test(value.split('-').join(''));


  return (
    <Container>
      {!!props.label && <label htmlFor={props.id}>{props.label}</label>}
      <StyledInput
      isValid={!validCep}
        className={`ant-input ${className} ${
          ableClass && (value?.length === 0
            ? null
            : props.error
              ? 'is-invalid'
              : value?.length >= 9
                ? 'is-valid'
                : 'is-invalid')
        }`}
      >
        <Cleave value={value ? value : ''} disabled={loading} {...rest} options={options} />
        <i />
      </StyledInput>
    </Container>
  );
};

export default Cep;

Cep.propTypes = {
  ...InputPropTypes,
  blocks: PropTypes.arrayOf(PropTypes.number),
  delimiters: PropTypes.arrayOf(PropTypes.string),
  numericOnly: PropTypes.bool,
};

Cep.defaultProps = {
  ...DefaultPropTypes,
  numericOnly: false,
  blocks: undefined,
  delimiters: undefined,
  ableClass: true
};
