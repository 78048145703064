import styled from 'styled-components';

export const InputStyled = styled.input`
 color: var(--primaty);
 height: 4.2rem;
 width: 2.2rem;
 border-radius: 8px;
 border-color: #ccc;
 margin: 5px;
 text-align: center;
 font-size: 1.5rem;
 border: 1px solid #e0e0e0;
 :hover {
 color: #42c1c7;
 border-color: #42c1c7;
 }
`;

export const InputStyledTwo = styled.input`
 height: 50px;
 width: 1.6rem;
 border-radius: 8px;
 border-color: #ccc;
 margin: 5px;
 text-align: center;
 font-size: 1.5rem;
 border: 1px solid #e0e0e0;
 :hover {
 color: #42c1c7;
 border-color: #42c1c7;
 }
`;
