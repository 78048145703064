import styled from 'styled-components';

export const Titulo = styled.p`
font-weight: bold;
font-size: 1.25rem;
color: var(--label);
text-align: center;
`;

export const TextStyled = styled.div`
color: #6f6f6f;
font-size: 1rem;
margin-bottom: 1.5rem;
text-align: justify;
text-align-last: left;
`;

export const Header = styled.div`
height: 40%;
`;

export const MainContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;
width: 100%;
`;
