import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 50px;
  margin-left: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: calc(100% - 100px);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

export const MenuItem = styled.li`
  margin-bottom: 5px;

  a {
    display: flex;
    align-items: center;
    color: ${(props) => (props.active ? props.textcolor : '#a6a6a6')};
    font-weight: 600;
    font-size: 15px;

    padding: 8px;

    ${(props) => props.active && css`
      background: ${props.menucolor};
      -webkit-border-top-left-radius: 18px;
      -webkit-border-bottom-left-radius: 18px;
      -moz-border-radius-topleft: 18px;
      -moz-border-radius-bottomleft: 18px;
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    `}

    svg {
      padding-right: 10px;
      font-size: 20px;
    }
}
`;

export const Hamburguer = styled.div`
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;

  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;

export const LinkToPj = styled.button`
 display: flex;
 text-align: center;
 justify-content: center;
 background: none;
 font-size: 85%;
 transition-duration: 0.4s;
 border-radius: 0.5rem;
 border: 0.1rem solid var(--primary);
 height: 2rem;
 width: 11rem;
 padding-top: 0.2rem;
 color: var(--primary);
 cursor: pointer;
 margin-top: 40px;
`;

export const Subtitle = styled.div`
 font-style: normal;
 font-weight: normal;
 font-size: 1rem;
 line-height: 150%;
 text-align: center;
 letter-spacing: 0.0025em;
 width: 80%;
 color: #393939;
`;
