/* eslint-disable max-len */
const messages = {
  cpfInvalid: 'CPF inválido. Confira os números digitados para podermos continuar.',
  cpfRegistred: 'Número de documento já registrado, faça login para prosseguir',
  cnpjRegistred: 'CNPJ já registrado.',
  birthDate:
'Será que você viajou através do tempo? A data de nascimento informada está inválida, confira para continuarmos seu cadastro.',
  emailInvalid: 'Endereço de e-mail inválido. Confira o e-mail informado para podermos continuar.',
  numberInvalid: 'número não pode ser 0 ou menor que 0',
  numberAddressLong: 'O número de endereço muito longo',
  celularInvalid: 'Número de telefone invalido, por favor confira o número informado',
  codeInvalid: 'Código inválido, por favor, confira os números ou tente reenviar o código.',
  confirmEmail: 'Os e-mails estão diferentes. Confira os e-mails para podermos continuar.',
  passwordDifferent: 'As senhas não são iguais. Tente novamente.',
  inputNull: 'Preencha o campo.',
  cnpjInvalid: 'CNPJ inválido. Confira os números digitados para podermos continuar.',
  employeesInvalid: 'Número de funcionários invalido!',
  incompleteName: 'Favor informar o nome completo',
  invalidZipCode: 'O CEP informado não foi encontrado',
};

export default messages;
