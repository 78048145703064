import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  background:#E6E6E6;

  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;

  div {
    img {
      width: 130px;
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }
  
`;

export const Content = styled.div`
  background-color: #fff;
  box-shadow: 0 0 2px 0 #414141;
  padding: 48px;
  border-radius: 5px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const BadgesContainer = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  padding: 20px;
  justify-content: space-around;
  flex-wrap: wrap;
`;
