/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  FaReceipt,
  FaDonate,
  FaHandHoldingUsd,
  FaExchangeAlt,
  FaBarcode,
  FaSignOutAlt,
  FaCog,
  FaShoppingCart,
  FaMoneyBill,
  FaHome,
} from 'react-icons/fa';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import PixIcon from '../../assets/PixIcon';
import ModalForTransition from '../ModalForTransition';

import { useAuth } from '../../hooks/auth';
import { useSettings } from '../../hooks/settings';

import {
  Container, MenuItem, Subtitle, LinkToPj,
} from './styles';
import { customOptions } from '../../services/client';
import { useSteps } from '../../hooks/steps';
import { useCheckpoint } from '../../hooks/checkpoint';
import { handleGetStepPjV2 } from '../../services/endPointRegister/PJ/statusPj';
import formater from '../../utilitys/formatStepToCheckpoint';
import ButtonN from '../ButtonN';

function Menu({ closeMenu }) {
  const {
    signOut, customMenus, user, setOnboarding, account,
  } = useAuth();
  const { pathname } = useLocation();
  const { settings } = useSettings();
  const history = useHistory();
  const { t } = useTranslation();
  const [statusPj, setStatusPj] = useState(false);
  const [currentPJStep, setCurrentPJStep] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const userData = {
    name: user.baas.user.name,
    document: user.baas.user.document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
  };
  const [loadingButton, setLoadingButton] = useState(true);
  const [buttonText, setButtonText] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({ zIndex: settings.name === 'diverse' ? -44 : null });
  const [isUserPj, setIsUserPj] = useState(false);

  const { stepsPj, stepsPjEnum } = useSteps();
  const { setCheckpoint, resetCheckpoint } = useCheckpoint();

  useEffect(() => {
  }, [pathname]);

  const isKexion = settings.name === 'kexion';
  const showPix = !(['bancomare',
    'bancovida',
    'braip',
    'brzbank',
    'cdlroo',
    'faemg',
    'sinapsecapital',
    'nmpay',
    'propagar',
    'seller',
    'vibe',
    // 'vistapay',
    'wibbank',
    'somosbank',
    'whin',
    'fxo_bank',
    'black_sig',
    'fast_bank',
    'zuch_tech',
  ].includes(settings.name));

  const checkPJStatus = useCallback(async () => {
    try {
      const { data } = await handleGetStepPjV2(null, account);
      await resetCheckpoint();
      const stepFormated = formater(data);
      setCheckpoint(stepFormated);
      localStorage.setItem('@qesh:accountPj', data.companie.id);
      const currentStep = data.companie.status;
      if (currentStep === 'ACTIVE') {
        throw Error();
      }
      setStatusPj(true);
      setLoadingButton(false);
      setCurrentPJStep(currentStep);
      const btnText = currentStep === 'WaitingApprove'
        ? 'Aguardando aprovação'
        : 'Continuar cadastro PJ!';
      setButtonText(btnText);
      const isBtnDisabled = currentStep === 'WaitingApprove';
      setButtonDisabled(isBtnDisabled);
      const btnStyle = isBtnDisabled ? { borderColor: 'grey', color: 'grey' } : {};
      setButtonStyle(btnStyle);
    } catch (err) {
      setButtonText(t('Crie sua conta PJ'));
      setLoadingButton(false);
    }
  }, [account, setCheckpoint, resetCheckpoint, t]);

  useEffect(() => {
    setIsUserPj(account.user.document.length > 11);
    checkPJStatus();
  }, [account.user.document, checkPJStatus]);

  const handleClick = useCallback(() => {
    if (statusPj) {
      const pjStep = stepsPjEnum[currentPJStep];
      localStorage.getItem('@qesh:step');
      sessionStorage.setItem('@qesh:step', pjStep);
      const stepURL = stepsPj[pjStep];
      sessionStorage.setItem('@qesh:steplist', 'PJ');
      history.push(`/register/${stepURL}`);
      return;
    }
    resetCheckpoint();
    setOnboarding(true);
    sessionStorage.setItem('@qesh:steplist', 'PJ');
    sessionStorage.setItem('@qesh:step', '0');
    history.push('/register/basicDataPj');
  }, [resetCheckpoint, history, setOnboarding, statusPj, stepsPjEnum, stepsPj, currentPJStep]);

  if (settings && settings.name && customMenus) {
    return (
      <Container>
        <ul key="1">

          {isKexion || (
          <>
            {customMenus.includes('Extrato') && (
            <MenuItem
              menucolor={settings.colors.primary}
              textcolor={settings.colors.text}
              active={pathname.includes('/transaction')}
              onClick={() => closeMenu()}
            >
              <Link to="/transaction">
                <FaReceipt size={23} />
                {t('extrato')}
              </Link>
            </MenuItem>
            )}

            {customMenus.includes('Depositar') && (
            <MenuItem
              menucolor={settings.colors.primary}
              textcolor={settings.colors.text}
              active={pathname.includes('/deposit')}
              onClick={() => closeMenu()}
            >
              <Link to="/deposit">
                <FaDonate size={23} />
                {t('depositar')}
              </Link>
            </MenuItem>
            )}

            {customMenus.includes('Transferir') && (
            <MenuItem
              menucolor={settings.colors.primary}
              textcolor={settings.colors.text}
              active={pathname.includes('/transfer')}
              onClick={() => closeMenu()}
            >
              <Link to="/transfer">
                <FaExchangeAlt size={23} />
                {t('transferir')}
              </Link>
            </MenuItem>
            )}
          </>
          )}

          {customMenus.includes('Pagar') && (
            <MenuItem
              menucolor={settings.colors.primary}
              textcolor={settings.colors.text}
              active={pathname.includes('/payment')}
              onClick={() => closeMenu()}
            >
              <Link to="/payment">
                <FaBarcode size={23} />
                {t('pagar')}
              </Link>
            </MenuItem>
          )}

          {customMenus.includes('Cobrar') && (
            <MenuItem
              menucolor={settings.colors.primary}
              active={pathname.includes('/charges')}
              textcolor={settings.colors.text}
              onClick={() => closeMenu()}
            >
              <Link to="/charges">
                <FaHandHoldingUsd size={23} />
                {t('cobrar')}
              </Link>
            </MenuItem>
          )}

          {customMenus.includes('Configurações') && (
            <MenuItem
              menucolor={settings.colors.primary}
              textcolor={settings.colors.text}
              active={pathname.includes('/settings')
              || pathname.includes('reset-4digit') || pathname.includes('documents')}
              onClick={() => closeMenu()}
            >
              <Link to="/settings">
                <FaCog size={23} />
                {t('configuracoes')}
              </Link>
            </MenuItem>
          )}

          {customMenus.includes('Loja') && (
            <MenuItem
              menucolor={settings.colors.primary}
              textcolor={settings.colors.text}
              active={pathname.includes('/store')}
              onClick={() => closeMenu()}
            >
              <Link to="/store">
                <FaShoppingCart size={23} />
                {t('loja online')}
              </Link>
            </MenuItem>
          )}

          {customMenus.includes('Saque') && (
          <MenuItem
            menucolor={settings.colors.primary}
            textcolor={settings.colors.text}
            active={pathname.includes('/withdraw')}
            onClick={() => closeMenu()}
          >
            <Link to="/withdraw">
              <FaMoneyBill size={23} />
              {t('saque')}
            </Link>
          </MenuItem>
          )}

          {customMenus.includes('Pix') && (
            <MenuItem
              menucolor={settings.colors.primary}
              textcolor={settings.colors.text}
              active={pathname.includes('/pix')}
              onClick={() => closeMenu()}
            >
              <Link to="/pix">
                <div
                  style={{
                    width: 23,
                    height: 23,
                    marginLeft: '-6px',
                    marginRight: '5px',
                  }}
                >
                  <PixIcon
                    color="#707070"
                  />
                </div>
                {t('Pix')}
              </Link>
            </MenuItem>
          )}

          {(settings.name !== 'diverse' && !isUserPj) && (
          <LinkToPj
            disabled={buttonDisabled}
            onClick={() => (!statusPj ? setShowModal(true) : handleClick())}
            style={buttonStyle}
          >
            {loadingButton ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fffff' }} spin />} />
            ) : (
              `${buttonText}`
            )}
          </LinkToPj>
          )}

          <ModalForTransition
            visibility={showModal}
            title={(
              <h1
                style={{
                  height: '30%',
                  textAlign: 'center',
                  alignItems: 'center',
                  fontSize: '1.5rem',
                  margin: '3rem 0',
                  width: '100%',
                  maxWidth: '100%',
                }}
              >
                {t('Você é sócio da empresa?')}
              </h1>
    )}
            style={{ height: '100%' }}
            setVisibility={() => setShowModal()}
          >
            <Subtitle>
              <text>Para criar uma conta PJ, você</text> <b> obrigatoriamente </b>
              <text>precisa ser sócio da empresa a ser cadastrada.</text>
            </Subtitle>
            <Subtitle>
              Você confirma que o CPF <b>{userData.document}</b> no nome de <b>{userData.name}</b> é sócio da
              empresa a ser cadastrada?
            </Subtitle>
            <div style={{
              display: 'flex', flexDirection: 'column', gap: '1rem', margin: '3rem 0',
            }}
            >
              <ButtonN
                onClick={() => handleClick()}
                style={{ width: '20rem', height: '3rem' }}
                type="primary"
              >
                Confirmo que sou sócio da empresa
              </ButtonN>
              <ButtonN onClick={() => setShowModal(false)} style={{ width: '20rem', height: '3rem' }}>
                Não sou sócio da empresa
              </ButtonN>
            </div>
          </ModalForTransition>

          {/* {customOptions[settings.name] && customOptions[settings.name].map((item) => (
            <MenuItem
              key={item.name}
              menucolor={settings.colors.primary}
              textcolor={settings.colors.text}
              active={pathname.includes(item.path)}
              onClick={() => closeMenu()}
            >
              {item.type === 'default' ? (
                <Link to={item.path}>
                  {item.icon}
                  {item.name}
                </Link>
              ) : (
                <a href={item.path} target="_blank" without rel="noreferrer">
                  {item.icon}
                  {item.name}
                </a>
              )}
            </MenuItem>
          ))} */}
        </ul>

        <ul key="2">
          <MenuItem>
            <Link
              to="/"
              onClick={() => {
                sessionStorage.removeItem('@qesh:token');
                localStorage.removeItem('@qesh:user');
                localStorage.removeItem('@qesh:account');
                localStorage.removeItem('@documents');
                localStorage.removeItem('@loginApproved');
                signOut();
              }}
            >
              <FaSignOutAlt color="#000000" size={23} />
              <div style={{ color: 'black' }}>
                {t('sair')}
              </div>
            </Link>
          </MenuItem>
        </ul>
      </Container>
    );
  }

  return settings && settings.name ? (
    <Container>
      <ul key="1">
        <>
          {settings.name !== 'kexion' && (
          <>
            {settings.name === 'wibbank' && (
            <MenuItem
              menucolor={settings.colors.primary}
              textcolor={settings.colors.text}
              active={pathname.includes('/homewib')}
              onClick={() => closeMenu()}
            >
              <Link to="/homewib">
                <FaHome size={23} />
                {t('homepage')}
              </Link>
            </MenuItem>
            )}

            <MenuItem
              menucolor={settings.colors.primary}
              textcolor={settings.colors.text}
              active={pathname.includes('/transaction')}
              onClick={() => closeMenu()}
            >
              <Link to="/transaction">
                <FaReceipt size={23} />
                {t('extrato')}
              </Link>
            </MenuItem>
          </>
          )}

          {showPix
            && (
              <MenuItem
                menucolor={settings.colors.primary}
                textcolor={settings.colors.text}
                active={pathname.includes('/pix')}
                onClick={() => closeMenu()}
              >
                <Link to="/pix">
                  <div
                    style={{
                      width: 23,
                      height: 23,
                      marginLeft: '-6px',
                      marginRight: '5px',
                    }}
                  >
                    <PixIcon color="#919294" />
                  </div>
                  {t('Pix')}
                </Link>
              </MenuItem>
            )}

          {settings.name !== 'kexion' && (

            <MenuItem
              menucolor={settings.colors.primary}
              textcolor={settings.colors.text}
              active={pathname.includes('/deposit')}
              onClick={() => closeMenu()}
            >
              <Link to="/deposit">
                <FaDonate size={23} />
                {t('depositar')}
              </Link>
            </MenuItem>

          )}

        </>

        <MenuItem
          menucolor={settings.colors.primary}
          textcolor={settings.colors.text}
          active={pathname.includes('/transfer')}
          onClick={() => closeMenu()}
        >
          <Link to="/transfer">
            <FaExchangeAlt size={23} />
            {t('transferir')}
          </Link>
        </MenuItem>

        <MenuItem
          menucolor={settings.colors.primary}
          textcolor={settings.colors.text}
          active={pathname.includes('/payment')}
          onClick={() => closeMenu()}
        >
          <Link to="/payment">
            <FaBarcode size={23} />
            {t('pagar')}
          </Link>
        </MenuItem>

        {settings.name !== 'kexion'
          && (
          <>
            {(settings.name !== 'somosbank' && settings.name !== 'nmpay') && (
            <MenuItem
              menucolor={settings.colors.primary}
              active={pathname.includes('/charges')}
              textcolor={settings.colors.text}
              onClick={() => closeMenu()}
            >
              <Link to="/charges">
                <FaHandHoldingUsd size={23} />
                {t('cobrar')}
              </Link>
            </MenuItem>
            )}
            {(settings.name === 'nmpay' && (user.document.length > 12 || account?.user.document.length > 12)) && (
            <MenuItem
              menucolor={settings.colors.primary}
              active={pathname.includes('/charges')}
              textcolor={settings.colors.text}
              onClick={() => closeMenu()}
            >
              <Link to="/charges">
                <FaHandHoldingUsd size={23} />
                {t('cobrar')}
              </Link>
            </MenuItem>
            )}

            {settings.name !== 'somosbank' && (user.document === '15219408607' || user.document === '26325135881') && (
            <MenuItem
              menucolor={settings.colors.primary}
              active={pathname.includes('/billet-beta')}
              textcolor={settings.colors.text}
              onClick={() => closeMenu()}
            >
              <Link to="/billet-beta">
                <FaHandHoldingUsd size={23} />
                Cobrar (Novo)
              </Link>
            </MenuItem>
            )}
          </>
          )}

        <MenuItem
          menucolor={settings.colors.primary}
          textcolor={settings.colors.text}
          active={pathname.includes('/settings') || pathname.includes('reset-4digit') || pathname.includes('documents')}
          onClick={() => closeMenu()}
        >
          <Link to="/settings">
            <FaCog size={23} />
            {t('configuracoes')}
          </Link>
        </MenuItem>

        {customOptions[settings.name] && customOptions[settings.name].map((item) => (
          <MenuItem
            key={item.name}
            menucolor={settings.colors.primary}
            textcolor={settings.colors.text}
            active={pathname.includes(item.path)}
            onClick={() => closeMenu()}
          >
            {item.type === 'default' ? (
              <Link to={item.path}>
                {item.icon}
                {item.name}
              </Link>
            ) : (
              <a href={item.path} target="_blank" without rel="noreferrer">
                {item.icon}
                {item.name}
              </a>
            )}
          </MenuItem>
        ))}
      </ul>

      {(settings.name !== 'diverse' && !isUserPj && !settings?.disabled) && (
      <LinkToPj
        disabled={buttonDisabled}
        onClick={() => (!statusPj ? setShowModal(true) : handleClick())}
        style={buttonStyle}
      >
        {loadingButton ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fffff' }} spin />} />
        ) : (
          `${buttonText}`
        )}
      </LinkToPj>
      )}

      <ModalForTransition
        visibility={showModal}
        title={(
          <h1
            style={{
              height: '30%',
              textAlign: 'center',
              alignItems: 'center',
              fontSize: '1.5rem',
              margin: '3rem 0',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            {t('Você é sócio da empresa?')}
          </h1>
    )}
        style={{ height: '100%' }}
        setVisibility={() => setShowModal()}
      >
        <Subtitle>
          <text>Para criar uma conta PJ, você</text> <b> obrigatoriamente </b>
          <text>precisa ser sócio da empresa a ser cadastrada.</text>
        </Subtitle>
        <Subtitle>
          Você confirma que o CPF <b>{userData.document}</b> no nome de <b>{userData.name}</b> é sócio da
          empresa a ser cadastrada?
        </Subtitle>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '1rem', margin: '3rem 0',
        }}
        >
          <ButtonN
            onClick={() => handleClick()}
            style={{ width: '20rem', height: '3rem' }}
            type="primary"
          >
            Confirmo que sou sócio da empresa
          </ButtonN>
          <ButtonN onClick={() => setShowModal(false)} style={{ width: '20rem', height: '3rem' }}>
            Não sou sócio da empresa
          </ButtonN>
        </div>
      </ModalForTransition>

      <ul key="2">
        <MenuItem>
          <Link
            to="/"
            onClick={() => {
              sessionStorage.removeItem('@qesh:token');
              localStorage.removeItem('@qesh:user');
              localStorage.removeItem('@qesh:account');
              localStorage.removeItem('@documents');
              localStorage.removeItem('@loginApproved');
              signOut();
            }}
          >
            <FaSignOutAlt color="#000000" size={23} />
            <div style={{ color: 'black' }}>
              {t('sair')}
            </div>
          </Link>
        </MenuItem>
      </ul>
    </Container>
  ) : (<></>);
}

export default Menu;
