import React from 'react';
import { IoMdHelp as HelpIcon } from 'react-icons/io';
import {
  DescriptionContainer,
  HelperModal, Wrapper,
} from './styles';
import { helpInfo } from '../../utilitys/helpTexts';
import { useSettings } from '../../hooks/settings';

const HelpModal = ({ type = 'default', size }) => {
  const sizes = {
    small: '150',
    medium: '250',
    large: '350',
  };

  const { settings } = useSettings();

  const renderDescription = React.useMemo(() => helpInfo[type]?.text.map((text) => (
    <h3 key={text}>{text}</h3>
  )), [type]);
  return (
    <Wrapper color={settings.colors.primary}>
      <HelpIcon color="white" size={10} />
      <HelperModal color={settings.colors.primary} size={sizes[size]}>
        <h2>
          {helpInfo[type]?.title}
        </h2>
        <DescriptionContainer>
          {renderDescription}
        </DescriptionContainer>

      </HelperModal>
    </Wrapper>
  );
};

export default HelpModal;
