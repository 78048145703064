/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, {
  useContext, createContext, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';

const checkpointContext = createContext({});

function CheckpointProvider({ children }) {
  const [checkpoint, setCheckpoint] = useState({

  });
  const resetCheckpoint = useCallback(() => {
    setCheckpoint({});
  }, []);
  return (
    <checkpointContext.Provider value={{ resetCheckpoint, checkpoint, setCheckpoint }}>
      {children}
    </checkpointContext.Provider>
  );
}

CheckpointProvider.propTypes = {
  children: PropTypes.any,
};

function useCheckpoint() {
  const context = useContext(checkpointContext);
  return context;
}

export { CheckpointProvider, useCheckpoint };
