import styled from 'styled-components';

import { Row as RolAntd, Col as ColAntd } from 'antd';

export const Row = styled(RolAntd)`
  @media(max-width: 1020px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

export const Col = styled(ColAntd)`
  @media(max-width: 1020px) {
    margin-top: 30px;
  }

  &.bottom {
    margin-bottom: 35px;

    @media(max-width: 1020px) {
      margin-bottom: 0;
    }
  }

  &.bottomLg {
    margin-bottom: 60px;
    @media(max-width: 1020px) {
      margin-bottom: 0;
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-end')};
  margin-top: 25px;
`;
