/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { LoadingOutlined, GlobalOutlined } from '@ant-design/icons';
import { cpf, cnpj } from 'cpf-cnpj-validator';
// import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import {
  Select,
} from 'antd';
import { Container, Content, CadastroBox } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { Row, Col } from '../../styles/components';
import { useAuth } from '../../hooks/auth';
import { useSettings } from '../../hooks/settings';
import { documentMask } from '../../services/utils';
import getErrors from '../../services/errors';
import Notification from '../../components/Notification';
import i18n from '../../i18n';

const { Option } = Select;

function Main() {
  const { signIn } = useAuth();
  const formRef = useRef(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { settings } = useSettings();
  const [document, setDocument] = useState('');
  // const [recaptcha, setRecaptcha] = useState(false);
  const { t } = useTranslation();

  const handleSelectedLanguage = useCallback((language) => {
    localStorage.setItem('@qesh:ib_language', `${language}`);
    i18n.changeLanguage(language);
  }, []);

  // const menu = (
  //   <Menu onClick={(key) => handleSelectedLanguage(key)}>
  //     <Menu.Item key="ptbr">Português</Menu.Item>
  //     <Menu.Item key="en">Chinês</Menu.Item>
  //   </Menu>
  // );

  const handleSubmit = useCallback(async (data) => {
    formRef.current.setErrors({});
    setLoading(true);
    setError('');

    const rawDocument = document.replace(/[./-]/g, '');

    if ((!cpf.isValid(rawDocument) && !cnpj.isValid(rawDocument)) || !data.password) {
      setError(t('credenciais invalidas'));
      setLoading(false);
      return;
    }

    // if (!recaptcha) {
    //   setError('Preencha o ReCAPTCHA abaixo.');
    //   setLoading(false);
    //   return;
    // }

    try {
      await signIn({
        document: rawDocument,
        password: data.password,
      });
      setLoading(false);
    } catch (err) {
      setError(getErrors(err));
      setLoading(false);
      formRef.current.setErrors({
        document: ' ',
        password: ' ',
      });
    }
  }, [signIn, document, t]);

  useEffect(() => () => setLoading(false), []);

  return settings && settings.name ? (
    <Container>
      <Notification
        type="error"
        visible={!!error}
        title={t('erro ao fazer login')}
        hidden={() => setError('')}
      >
        {error}
      </Notification>
      <div className="content-logo">
        <img src={settings.default_logo} alt={t('logo')} />
      </div>

      <Content>
        <div>
          <h1>{t('acesse sua conta')}</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <Input
                  name="document"
                  id="document"
                  label={t('cpf / cnpj')}
                  onChange={(e) => setDocument(e.target.value)}
                  value={documentMask(document)}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} />
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <Input name="password" id="password" label={t('senha')} type="password" eye="true" />
                {/* {error && (
                  <div style={{ marginTop: '5px' }}>
                    <span style={{ color: '#f00', fontWeight: 'bold' }}>Credenciais inválidas.</span>
                  </div>
                )} */}
              </Col>
            </Row>
            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Row gutter={16}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="bottom"
                >
                  <ReCAPTCHA
                    sitekey="6LejHKkbAAAAADUN4FkTi-au0xdxcKUEIDhIdmj2"
                    onChange={() => {
                      setRecaptcha(true);
                    }}
                  />
                </Col>
              </Row>
            </div> */}
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <Button center right={false}>
                  {loading ? (
                    <span><LoadingOutlined /></span>
                  ) : <span>{t('entrar')}</span>}
                </Button>
              </Col>
            </Row>
          </Form>

          <section>
            <Link to="/forgot">
              {t('esqueci minha senha')}
            </Link>
          </section>
        </div>

        <CadastroBox>
          <div>{t('nao possui cadastro')}</div>

          <Link to="/register-old">
            <Button right={false} style={{ background: '#f5f5f5', color: '#414141', border: '1px solid #414141' }}>
              <span>{t('cadastrar')}</span>
            </Button>
          </Link>
        </CadastroBox>
        {settings.name === 'wibbank' && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <GlobalOutlined style={{ fontSize: '17px', color: settings.colors.primary }} />
            <Select
              bordered={false}
              placeholder={t('alterar idioma')}
              style={{ fontWeight: 'bold', color: settings.colors.primary }}
              onChange={(value) => handleSelectedLanguage(value)}
            >
              <Option value="ptbr" style={{ width: 145 }}>Português</Option>
              <Option value="zh">{/* Chinês */}中文</Option>
            </Select>
          </div>
        )}
      </Content>
    </Container>
  ) : (<></>);
}

export default Main;
