import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { id } from 'date-fns/locale';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import ButtonN from '../../../components/ButtonN';
import { useRefresh } from '../../../hooks/refresh';
import {
  Container, QRCodeContainer, QRCodeArea, ButtonContainer, Title, TitleArea, Text, Icons, Icon,
} from './styles';
import android from '../../../assets/android.svg';
import apple from '../../../assets/apple.svg';
import { useSettings } from '../../../hooks/settings';

export const Validation = () => {
  const {
    getAdditional, setStep, token, user, resetRefresh,
  } = useRefresh();
  const { t } = useTranslation();
  const [personData, setPersonData] = useState({
    monthlyIncome: '',
    patrimony: '',
    relationship: '',
    occupation: '',
    education: '',
    isPEP: false,
  });
  const [value, setValue] = useState('');

  const [loading, setLoading] = useState(false);
  const { settings } = useSettings();
  const handleNext = async () => {};

  return (
    <Container>
      <TitleArea>
        <Title>Segurança é com a gente mesmo!</Title>
        <Text>
          Para deixarmos sua conta ainda mais segura vamos precisar de uma foto do seu rosto e outra de um
          documento com foto.
        </Text>
      </TitleArea>
      {(settings?.play_store_url && settings?.app_store_url) && (
        <QRCodeContainer>
          <Text>
            Aponte a câmera do seu celular para o código QR abaixo caso queira continuar a atualização do seu
            cadastro pelo app.
          </Text>
          <QRCodeArea>
            <Icons>
              <div onClick={() => setValue(settings.play_store_url)}>
                <Icon src={android} alt="android" />
              </div>
              <div onClick={() => setValue(settings.app_store_url)}>
                <Icon src={apple} alt="apple" />
              </div>
            </Icons>
          </QRCodeArea>
          {value !== '' && (
          <section style={{ display: 'flex', justifyContent: 'center', padding: '30px' }}>
            <QRCode size={200} value={value} />
          </section>
          )}
        </QRCodeContainer>
      )}
      <ButtonContainer>
        <ButtonN
          type="primary"
          className="botao"
          onClick={() => setStep('selfie')}
          loading={loading}
          icon={loading && <Spin indicator={<LoadingOutlined />} />}
        >
          {!loading && 'Continuar pelo site'}
        </ButtonN>
        <ButtonN
          className="botao"
          onClick={() => {
            resetRefresh();
          }}
        >
          <Link to="/">Continuar Depois</Link>
        </ButtonN>
      </ButtonContainer>
    </Container>
  );
};
