/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import QRCode from 'react-qr-code';
import ButtonN from '../../../../../components/ButtonN';
import {
  TextStyled, Title, Icons, Icon, Container, MainContainer,
} from './styles';
import { useSteps } from '../../../../../hooks/steps';
import android from '../../../../../assets/android.svg';
import apple from '../../../../../assets/apple.svg';

import { useCheckpoint } from '../../../../../hooks/checkpoint';
import { useSettings } from '../../../../../hooks/settings';

const SelfieOption = () => {
  const {
    resetStep, step, stepsPf, nextStep, exactStep,
  } = useSteps();
  const { resetCheckpoint } = useCheckpoint();
  const [value, setValue] = useState('');
  const history = useHistory();
  const {settings} = useSettings()
  useEffect(() => {
    if (localStorage.getItem('@qesh:token') && sessionStorage.getItem('@qesh:step') === '9') {
      exactStep(9);
    } else {
      resetStep();
      history.replace('/register/');
    }
  }, []);

  const handleAdvance = () => {
    nextStep();
    if (stepsPf.length >= step + 1) {
      history.push(`/register/${stepsPf[step + 1]}`);
    } else {
      history.push(`/register/${stepsPf[step]}`);
    }
  };
  return (
    <>
      <MainContainer>
        <Container>
          <Title>
            <h3> Segurança é com a gente mesmo!</h3>
          </Title>
          <TextStyled>
            Para deixarmos sua conta ainda mais segura vamos precisar de uma foto do seu rosto e outra de um
            documento com foto.
          </TextStyled>
        {(settings?.play_store_url && settings?.app_store_url) && (
          <TextStyled>
          Selecione o sistema operacional do seu celular e aponte a câmera do seu celular para o código QR
          abaixo e continue a abertura da sua conta pelo app.
        </TextStyled>
        )}
        </Container>
          
        {(settings?.play_store_url && settings?.app_store_url) && (
          <>
          <Icons>
            <div onClick={() => setValue(settings?.play_store_url)}>
              <Icon src={android} alt="android" />
            </div>
            <div onClick={() => setValue(settings?.app_store_url)}>
              <Icon src={apple} alt="apple" />
            </div>
          </Icons>
          {value !== '' && (
          <section style={{ display: 'flex', justifyContent: 'center', margin: '30px' }}>
            <QRCode size={200} value={value} />
          </section>
          )}
          </>
          
        )}
        
        {/* <QrCode value={value} /> */}
        <Container>
          <ButtonN
            type="primary"
            red
            className="botao"
            onClick={() => {
              handleAdvance();
            }}
          >
            Continuar pelo site
          </ButtonN>
          <ButtonN
            className="botao"
            onClick={() => {
              resetStep();
              resetCheckpoint();
            }}
          >
            <Link to="/">Continuar Depois</Link>
          </ButtonN>
        </Container>
      </MainContainer>
    </>
  );
};

export default SelfieOption;
