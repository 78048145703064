import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  margin: 5 auto 5 auto;
  padding-bottom: 250px;

  div.divider{
    width: 100%;
    display: flex;
    align-items: center;
    
    span{
      font-size: 16px;
      font-weight: bold;
      color: #767676;
    }

    div {
      margin-left: 8px;
      flex: 1;
      height: 2px;
      width: 100%;
      background: #dbdcde;
    }
  }

  h2 {
    color: #121212;
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  h3 {
    color: #121212;
    font-size: 17px;
    margin-top: 35px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  p {
    margin-bottom: 30px;
  }
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    
    svg{
        margin-bottom: 12px;
        margin-right: 12px;
        cursor: pointer;

        :hover{
            color: #707070;
        }
    }
`;

export const InfosContainer = styled.div`
    grid-area: 'card';
    background: #fafafa;
    border-radius: 8px;
    padding: 16px;

    h1 {
        color: #707070;
    }

    p {
        margin-bottom: 8px
    }

    @media only screen and (max-width: 1380px){
        background: #fff;
    }

    div.info{
        margin-top: 10px;
        padding: 12px;
        border-top: 2px solid #808080;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #707070;

        div.values {
            font-size: 18px;
            display: flex;
            justify-content: space-between;

            div{
                display: flex;
                flex-direction: column;

                & + div {
                    margin-left: 16px;
                }
            }
        }

        @media only screen and (max-width: 1380px){
            display: none;
        }
    }            
`;

export const GridContainer = styled.div`
    form {
        margin-top: 10px;
        grid-area: form;

        div.info{
            margin-top: 16px;
            background: #fafafa;
            padding: 16px;
            
            @media only screen and (max-width: 1380px){
                display: block;
            }

            p {
                margin-bottom: 8px
            }   

            display: none;
            color: #707070
        }       
    }
    
    @media (min-width:1360px){
        display: grid;
        grid-template-columns: 70% 30%; 
        grid-template-areas: 'form card';
        gap: 16px
    }

    @media only screen and (max-width: 1380px){
        display: flex;
        flex-direction: column;

        @media (max-width: 768px){
            align-items: center;
        }
    }
`;
