import api from '../../api';

/**
 * request type patch  to register new partner
 * @property {{}} value - obj is the data of the partner to be registered
 * @property {string} token - token bearer login account user PF
 * @property {string} user - O user is o id user the account PJ
 * @property {string} account - account_id to account the user PF
 * @returns {{status: boolean, res: {response or error}}}
 */
export const setQualification = async (token, userPj, accountPf) => {
  const headers = {
    user: userPj,
    account: accountPf,
  };
  const finalObject = {
    is_pool: true,
  };
  return api
    .post('onboarding/companies/account', finalObject, { headers })
    .then((response) => ({ status: true, res: response.data }))
    .catch((error) => {
      console.log('setQualification error: ', error.response);
      return { status: false, res: error?.response.data };
    });
};

export const declineInvite = async (token, userPj, accountPf) => {
  const headers = {
    Authorization: `bearer ${token}`,
    user: userPj,
    account: accountPf,
  };

  return api
    .post('onboarding/companies/account/reject', { headers })
    .then((response) => ({ status: true, res: response.data }))
    .catch((error) => {
      console.log('setQualification error: ', error.response);
      return { status: false, res: error?.response.data };
    });
};
