import React from 'react';
import { IoIosArrowBack as BackIcon } from 'react-icons/io';
import { FaCheck as SuccessIcon } from 'react-icons/fa';
import { Link, Redirect } from 'react-router-dom';
import Breaker from '../../components/Breaker';
import { Notification } from '../../components/NotificationModal';
import Steps from '../../components/StepsBar';
import {
  Button,
  ButtonWrapper,
  Form,
  FormWrapper, Header, LinkButton, LinkContainer, ReturnWrapper, StepWrapper, SuccessText, SuccessWrapper, TitleWrapper, Wrapper,
} from './styles';
import { ShopCheck, ShopData, ShopStyle } from '../../components/Steps';

import { useShop } from '../../hooks/shop';
import { useAuth } from '../../hooks/auth';
import { useSettings } from '../../hooks/settings';
import { passwordRegex } from '../../utilitys/Masks/passwordRegex';

function RegisterShop() {
  const {
    currentStep, setCurrentStep, shopInfo, setShopInfo, passwordConfirm, isShopCreated, isLoading, handleCreateShop, errorMessage, setErrorMessage, hasStore, hasAcceptedTerms,
  } = useShop();

  const { user } = useAuth();
  const { settings } = useSettings();

  const [displayError, setDisplayError] = React.useState(false);

  const isDisabled = {
    1: shopInfo.days_store_operation.length < 1
    || !shopInfo.name
    || !shopInfo.start_store_operation
    || !shopInfo.end_store_operation
    || !shopInfo.phone
    || !shopInfo.email
    || !shopInfo.address.zip_code
    || !shopInfo.address.street
    || !shopInfo.address.neighborhood
    || !shopInfo.address.city
    || !shopInfo.address.state,
    2: !shopInfo.primary_color
    || !shopInfo.secondary_color
    || !shopInfo.img_web
    || !shopInfo.img_mobile
    || !shopInfo.url,
    3: !shopInfo.password
    || !passwordConfirm
    || !(shopInfo.password === passwordConfirm)
    || !passwordRegex.test(shopInfo.password)
    || !hasAcceptedTerms,

  };

  const renderStep = {
    1: <ShopData />,
    2: <ShopStyle />,
    3: <ShopCheck />,
  };

  const StepLabel = {
    1: 'Dados da loja',
    2: 'Personalização da loja',
    3: 'Confira as informações da loja',
  };

  function handleNextStep() {
    setCurrentStep(currentStep + 1);
  }

  function handlePreviousStep() {
    setCurrentStep(currentStep - 1);
  }

  const toasty = (label, type) => (
    <Notification
      label={label}
      type={type}
      visible={displayError}
      onClick={() => setDisplayError(false)}
    />
  );

  const showModal = () => {
    setDisplayError(true);
    const closeModal = () => {
      setDisplayError(false);
      setErrorMessage('');
    };
    setTimeout(closeModal, 3500);
  };

  React.useEffect(() => {
    if (user) {
      setShopInfo((prevValue) => ({
        ...prevValue, client_id: user.id, document: user.document, userName: user.name,
      }));
    }
  }, [user]);

  React.useEffect(() => {
    if (errorMessage) {
      showModal();
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [errorMessage]);

  return (
    <>
      {hasStore ? (
        <Redirect to="/transaction" />
      ) : (

        <Wrapper>
          {toasty(errorMessage, 'error')}

          {isShopCreated ? (
            <>
              <SuccessWrapper>
                <SuccessIcon size={150} color="#24A148" />
                <SuccessText color={settings.colors.primary}>
                  <h1>Loja criada com sucesso!</h1>
                  <span>Acesse abaixo o E-commerce Admin da sua loja e comece a adicionar produtos!</span>
                  <a
                    href="https://develop.front-ecommerce-seller.pages.dev/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://develop.front-ecommerce-seller.pages.dev/
                  </a>
                  <LinkContainer>
                    <Link to="/transaction" style={{ width: 'fit-content' }}>
                      <LinkButton color={settings?.colors.primary}>Voltar</LinkButton>
                    </Link>
                  </LinkContainer>
                </SuccessText>
              </SuccessWrapper>
            </>
          ) : (
            <>
              <Header>
                <span>Configurações</span>

                <TitleWrapper>
                  <span>Criar loja online</span>
                  {currentStep !== 1 && (
                  <ReturnWrapper
                    onClick={handlePreviousStep}
                    color={settings.colors.primary}
                  >
                    <BackIcon
                      size={20}
                      color={settings.colors.primary}
                    />
                    <span>Voltar</span>
                  </ReturnWrapper>
                  )}
                </TitleWrapper>
              </Header>

              <FormWrapper>
                <StepWrapper>
                  <Steps currentStep={currentStep} />
                  <Breaker label={StepLabel[currentStep]} />
                </StepWrapper>
                <Form>
                  {renderStep[currentStep]}
                  <ButtonWrapper>
                    {currentStep !== 3 ? (
                      <Button
                        isDisabled={isDisabled[currentStep]}
                        onClick={handleNextStep}
                        color={settings?.colors.primary}
                      >
                        Proximo
                      </Button>
                    ) : (
                      <Button
                        onClick={handleCreateShop}
                        style={{ backgroundColor: isDisabled[currentStep] ? '#A8A8A8' : '#24A148' }}
                        disabled={isDisabled[currentStep] || isLoading}
                      >
                        Criar Loja
                      </Button>
                    ) }

                  </ButtonWrapper>
                </Form>

              </FormWrapper>
            </>
          )}

        </Wrapper>
      ) }
    </>
  );
}

export default RegisterShop;
