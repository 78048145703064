import { createGlobalStyle } from 'styled-components';

import 'antd/dist/antd.css';
import './antd.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  :root {
    /* Transições de viewport */
   --phone: 37.5rem;
   --tablet: 37.51rem;
   --landTablet: 48.1rem;
   --desktop: 62rem;
   --widescreen: 87.5rem;

   /* Cores */
   --primary: ${({ settings }) => settings?.colors.primary};
   --details: ${({ settings }) => settings?.colors.details};
   --secondary: ${({ settings }) => settings?.colors.secondary};
   --disabled: ${({ settings }) => settings?.colors.disabled};
   --text: ${({ settings }) => settings?.colors.text};
   --btn-text: ${({ settings }) => settings?.colors.text};
   // --error: ${({ settings }) => settings?.colors.error} : "#DA1E28";
   --error: #DA1E28;
   /* --success: ${({ settings }) => settings?.colors.success}; */
   --success: #24A148;
   --warning: ${({ settings }) => settings?.warning};
   --label: #393939;
   --white: #ffff;
 }

  body {
    -webkit-font-smoothing: antialiased;
    background: #ffffff;
    font-family: 'Open Sans', sans-serif;
  }

  h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: bold !important;
  }
  .text-success {
  color: var(--success) !important;
  }
  .text-error {
  color: var(--error) !important;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 6px;
    border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: ${(props) => props.color};
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: ${(props) => props.color};
}


  .ant-layout {
    background: #fff;
  }

  .ant-layout-header {
    background: #E6E6E6;
  }
  .ant-menu-horizontal {
    background: #E6E6E6;
  }

  .ant-layout-sider {
    background: #ffffff;
  }
  .ant-layout-sider-children {
    border-right: 1px solid #E6E6E6 !important;
    padding-top: 20px;
  }

  .ant-menu-inline .ant-menu-item {
    margin-top: 0;
  }

  .ant-dropdown-menu {
    border-radius: 4px;
    /* padding: 15px 5px; */
  }

  .ant-dropdown-menu-item {
    min-width: 250px;
    border-bottom: 1px solid #E6E6E6;
    padding: 10px;
  }

  .ant-dropdown-menu-item:last-child {
    border-bottom: 0;
  }

  .separator {
    position: relative;
    top: 1px;
  }

  .ant-breadcrumb-link {
    display: flex;
    align-items: center;
  }

  .ant-breadcrumb-link svg {
    margin-right: 6px;
    font-size: 18px;
  }

  .ant-breadcrumb-link span {
    font-size: 18px;
  }

  .ant-tabs-tab {
    font-size: 19px;
    border-radius: 8px 8px 0px 0px;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav 
    .ant-tabs-tab {
    // background: #f1f1f1;
    padding: 8px 30px;
    border-bottom: 0;

  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, 
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: #fff;
    border-radius: 8px 8px 0px 0px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type), 
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type), 
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type), 
  .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
    /* margin-right: 8px; */
    margin-right: 5px;
}

  .ant-tabs-tab-btn {
    color: rgba(0,0,0,0.7);
    font-weight: 500;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #42C0C7;
    font-weight: 500;
  }

  .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > 
  .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, 
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 0;
  }

  .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, 
  .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-tabpane {
    /* box-shadow: 0px 2px 3px #00000029; */
    background: #ffffff;
    border: 1px solid #F6F6F6;

    border-top: 0;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    padding: 24px;
  }

  .modal-password .ant-modal-content {
    border-radius: 8px;
  }

  .ant-divider-horizontal.ant-divider-with-text {
    border-top-color: #E6E6E6;
    margin-bottom: 30px;
  }

  .ant-divider-inner-text {
    position: relative;
    top: 13px;
    font-size: 16px;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 150px;
    height: 150px;
  }
  .ant-divider-horizontal{
    margin: 16px 0;
  }
  .anticon-search{
    color:#1890ff !important;
  }
  .ant-popover-title {
    border-bottom: 2px solid #1890ff !important;
  }
  .ant-popover-placement-rightBottom {
    padding-left: 32px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color:#3EC0C3 !important;
  }

  .file-uploader:hover {
    border: 1px dashed #3EC0C3 !important;

  }

  .ant-switch-checked {
    background-color: #3EC0C3;
 }
`;
