/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'moment/locale/pt-br';
import 'react-day-picker/lib/style.css';

import { Divider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  MainContainer,
  Content,
  TitleHeader,
  CenterContainer,
  ContainerButtom,
  HeaderArea,
  TitleArea,
  Title,
  Subtitle,
  ContentBody,
  LoadingContainer,
} from './styles';
import { useSettings } from '../../hooks/settings';
import ModernButton from '../../components/ModernButton';
import handleDataPj from '../../services/endPointRegister/PJ/endPointData';
import { acceptInvitation, declineInvitation } from '../../services/endPointRegister/PJ/Invitations';
import InviteAcepted from './InviteAcepted';
import ButtonN from '../../components/ButtonN';
import ModalForTransition from '../../components/ModalForTransition';

function NotifyInvitation() {
  const [dataPj, setDataPj] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [firstPage, setFirstPage] = useState(true);
  const [secondPage, setSecondPage] = useState(false);
  const [message, setMessage] = useState('');

  const { t } = useTranslation();
  const { settings } = useSettings();
  // const [error, setError] = useState('')
  // // const [loading, setLoading] = useState(false)
  // const references = localStorage.getItem('@qesh:reference')
  // const token = localStorage.getItem('@qesh:token')
  // const { account } = useAuth() //JSON.parse(localStorage.getItem('@qesh:account'))
  const references = localStorage.getItem('qesh:reference');
  const token = localStorage.getItem('@qesh:token');
  const account = JSON.parse(localStorage.getItem('@qesh:account'));

  useEffect(
    useCallback(async () => {
      setLoading(true);
      const { res, status } = await handleDataPj(references, token, account.id);
      if (status) {
        setError(false);
        setDataPj(res);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
        setShowErrorModal(true);
        setMessage('Opss, algo deu errado, tente novamente');
      }
    }),
    [references],
  );

  useEffect(() => {
    if (
      dataPj.document
   && dataPj.name
   && dataPj.additional.trading_name
   && dataPj.qualification_partner
   && dataPj.additional.number_of_partners !== ''
    ) {
      setLoadingInfo(false);
    } else {
      setLoading(true);
    }
  });

  const handleAccept = async () => {
    const response = await acceptInvitation(token, dataPj.id, account.id);
    if (response) {
      setMessage('Convite aceito com sucesso');
      setFirstPage(false);
      setSecondPage(true);
    } else {
      setMessage('Opss, ocorreu um erro, tente novamente!');
    }
  };

  const handleDecline = async () => {
    const { status } = await declineInvitation(token, dataPj.id, account.id);
    if (status) {
      setMessage('Convite recusado');
      window.location.href = '/transaction';
    } else {
      setMessage('Opss, ocorreu um erro, tente novamente!');
    }
  };

  return (
    <MainContainer>
      {/* modal aceitar: */}
      <ModalForTransition
        visibility={showConfirmationModal}
        setVisibility={() => setShowConfirmationModal()}
        title={(
          <h1
            style={{
              height: '30%',
              textAlign: 'center',
              alignItems: 'center',
              fontSize: '1.5rem',
              margin: '3rem 0',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            Quer aceitar o convite de sócio de {dataPj.name}?
          </h1>
   )}
        style={{ height: '100%' }}
      >
        <Subtitle>Você não terá outra chance de aceitar a não ser que seja convidado outra vez.</Subtitle>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '1rem', margin: '3rem 0',
        }}
        >
          <Link to="/notify-invitation/accept" style={{ textDecoration: 'none', color: 'white' }}>
            <ButtonN
              onClick={() => handleAccept()}
              style={{ width: '20rem', height: '3rem' }}
              type="primary"
            >
              Aceitar Convite
            </ButtonN>
          </Link>
          <ButtonN onClick={() => setShowConfirmationModal(false)} style={{ width: '20rem', height: '3rem' }}>
            Voltar
          </ButtonN>
        </div>
      </ModalForTransition>

      {/* modal recusar: */}
      <ModalForTransition
        visibility={showDeclineModal}
        title={(
          <h1
            style={{
              height: '30%',
              textAlign: 'center',
              alignItems: 'center',
              fontSize: '1.5rem',
              margin: '3rem 0',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            Quer recusar o convite de sócio de {dataPj.name}?
          </h1>
   )}
        style={{ height: '100%' }}
        setVisibility={() => setShowDeclineModal()}
      >
        <Subtitle>Você não terá outra chance de aceitar a não ser que seja convidado outra vez.</Subtitle>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '1rem', margin: '3rem 0',
        }}
        >
          <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
            <ButtonN
              onClick={() => handleDecline()}
              style={{ width: '20rem', height: '3rem' }}
              type="primary"
            >
              Recusar Convite
            </ButtonN>
          </Link>
          <ButtonN onClick={() => setShowDeclineModal(false)} style={{ width: '20rem', height: '3rem' }}>
            Voltar
          </ButtonN>
        </div>
      </ModalForTransition>

      {/* modal error: */}
      <ModalForTransition
        visibility={showErrorModal}
        title={(
          <h1
            style={{
              height: '30%',
              textAlign: 'center',
              alignItems: 'center',
              fontSize: '1.5rem',
              margin: '3rem 0',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            Ops!
          </h1>
   )}
        style={{ height: '100%' }}
        setVisibility={() => setShowConfirmationModal()}
      >
        <Subtitle>Ocorreu um erro ao solicitar acesso a esta conta PJ.</Subtitle>
        <Subtitle>Clique no botão abaixo para voltar para a sua página principal.</Subtitle>
        <div style={{
          display: 'flex', flexDirection: 'column', gap: '1rem', margin: '3rem 0',
        }}
        >
          <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
            <ButtonN type="primary" style={{ width: '20rem', height: '3rem' }}>
              Voltar para pagina principal
            </ButtonN>
          </Link>
        </div>
      </ModalForTransition>

      {loadingInfo && (
      <LoadingContainer>
        <LoadingOutlined style={{ fontSize: 80, color: 'var(--primary)' }} spin />
      </LoadingContainer>
      )}
      {!loadingInfo && firstPage && (
      <>
        <Content>
          <HeaderArea>
            <TitleHeader>
              {t(
                `Você foi convidado(a) para participar como ${dataPj.qualification_partner} na conta PJ de ${dataPj.name}`,
              )}
            </TitleHeader>
          </HeaderArea>
        </Content>
        <Divider style={{ borderColor: settings.colors.primary, marginBottom: 24 }} />
        <CenterContainer>
          <TitleArea>
            <Title>{t(dataPj.name)}</Title>
            <Subtitle style={{ fontSize: '17px' }}>
              {t('Você poderá alternar entre sua conta física e jurídica a qualquer momento.')}
            </Subtitle>
          </TitleArea>
          <ContentBody>
            <div>
              <span>CNPJ: </span>{' '}
              {dataPj.document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}
            </div>
            <div>
              <span>Razão Social: </span> {dataPj.name}
            </div>
            <div>
              <span>Nome Fantasia: </span> {dataPj.additional.trading_name}
            </div>
            <div>
              <span>Quadro Societário: </span> {dataPj.qualification_partner}
            </div>
            <div>
              <span>Número de Sócios: </span> {dataPj.additional.number_of_partners}
            </div>
          </ContentBody>
        </CenterContainer>
        <ContainerButtom>
          <ModernButton
            type="submit"
            style={{
              marginBottom: 10,
            }}
            onClick={() => setShowConfirmationModal(true)}
          >
            {t('Aceitar')}
          </ModernButton>

          <ModernButton colored={false} onClick={() => setShowDeclineModal(true)}>
            {t('Recusar')}
          </ModernButton>
        </ContainerButtom>
      </>
      )}
      {secondPage && <InviteAcepted />}
    </MainContainer>
  );
}

export default NotifyInvitation;
