/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { VscFile } from 'react-icons/vsc';
import PropTypes from 'prop-types';
import { Container, IconText } from './styles';

export default function CustomFileIcon(props) {
  return (
    <Container onClick={props.onClick}>
      <IconText style={{ fontSize: 7 }}>{props.text}</IconText>
      <VscFile color="rgb(99, 100, 102)" size={props.size} />
    </Container>
  );
}

CustomFileIcon.propTypes = {
  size: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.any,
};

CustomFileIcon.defaultProps = {
  onClick: () => {},
};
