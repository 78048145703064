import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100%;
  width: fit-content;
  max-height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Image = styled.div`
  background-color: #E0E0E0;
  border-radius: 4px;
  width: ${(props) => props.size[0]}px;
  height: ${(props) => props.size[1]}px;
`;

export const ImageDisplay = styled.img`
  background-image: url(${(props) => props.src});
  border-radius: 4px;
  width: ${(props) => props.size[0]}px;
  height: ${(props) => props.size[1]}px;
`;
