import styled from 'styled-components';
import Button from '../../components/Button';

export const Content = styled.div`
 max-width: 1060px;
 width: 100%;
 margin: 5 auto 5 auto;

 h2 {
  color: #121212;
  font-size: 21px;
  margin-top: 10px;
  margin-bottom: 5px;
 }

 h3 {
  color: #121212;
  font-size: 17px;
  margin-top: 35px;
  margin-bottom: 15px;
  font-weight: 600;
 }

 p {
  margin-bottom: 30px;
 }
`;

export const Title = styled.h1`
 font-size: 24px;
 font-weight: bold;
`;

export const DividerContainer = styled.div`
 display: flex;
 font-size: 18px;
 align-items: center;
 margin-bottom: 26px;

 div {
  width: 9px;
  height: 24px;
  background: #efefef;
 }

 span {
  color: #707070;
  margin-left: 4px;
  font-weight: bold;
 }
`;

export const InputsContainer = styled.div`
 display: flex;

 div {
  & + div {
   margin-left: 8px;
  }
 }
`;

export const SecurityItemsContainer = styled.div`
 cursor: pointer;
 width: 100%;
 height: 42px;

 margin-bottom: 8px;
 background: #fafafa;

 display: flex;
 justify-content: space-between;
 align-items: center;
 padding-right: 8px;
 padding-left: 8px;

 span {
  color: #707070;
 }

 svg {
  color: var(--primary);
  cursor: pointer;
 }
`;

export const AdjustLimitsButton = styled(Button)`
 background: #fff;
 color: var(--primary);
 border: 2px solid var(--primary);
 padding: 0;
 box-shadow: 0;
 min-width: 120px;
 font-size: 14px;
 margin-bottom: 0px;
`;

export const StatusLink = styled.span`
 color: var(--primary);
 text-decoration: underline;
 cursor: pointer;
`;
