import styled from 'styled-components';

export const Wrapper = styled.div`
  width:100%;
  min-height:100%;
  height:fit-content;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
  background-color:#F4F4F4;
  padding: 15px 0 30px;
  overflow: auto;
  position:relative;

`;

export const Header = styled.div`
  width:80%;
  height:80px;
  display:flex;
  flex-direction:column;
  justify-content: flex-end;
  gap:10px;
  align-items:flex-start;
  padding: 5px 10px;
  font-family: 'Fira Sans', sans-serif;


  span{
    font-size: 1.2rem;
    font-weight: bold;
  }
`;

export const TitleWrapper = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  justify-content:space-between;
  align-items:flex-start;

  span {
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

export const ReturnWrapper = styled.div`
  width:fit-content;
  display:flex;
  justify-content:space-between;
  align-items:center;
  gap:10px;
  cursor:pointer;
  
  span {
    font-size: 1rem;
    color:${(props) => props.color};
  }

`;

export const StepWrapper = styled.div`
  width:100%;
  height:155px;
  gap:20px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
`;

export const FormWrapper = styled.div`
  width:80%;
  height:fit-content;
  background-color:#FFFFFF;
  border-radius:24px;
  gap:30px;
  padding:15px;

`;

export const Form = styled.div`
  width:100%;
  height:fit-content;
  padding: 30px 25px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap:40px;
  align-items:center;
`;

export const CheckBoxWrapper = styled.div`
  min-width:90px;
  width:fit-content;
  height:fit-content;
  display:flex;
  gap:10px;
  justify-content:space-evenly;
  align-items:center;
  position:absolute;
  bottom:-30px;
  right:0;
`;

export const CheckBox = styled.div`
  width: ${(props) => props.size || 20}px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  align-items: center;
  border: 1px solid #D9D9D9;
  background-color: ${(props) => (props.isChecked ? props.color : 'transparent')};
  border-radius: 2px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  justify-content:flex-end;
  align-items:center;
  padding: 0 30px;
  margin-top:30px;

`;

export const Button = styled.button`
width:25%;
height:48px;
border-radius:6px;
border: none;
background-color:${(props) => (props.isDisabled ? '#A8A8A8' : props.color)};
text-align:center;
color:#FFFFFF;
font-weight:bold;
cursor: pointer;
box-shadow: 0px 0px 2px 0px #000000;
transition: all 0.2s ease-in-out;
`;

export const SuccessWrapper = styled.div`
  width:50%;
  height:fit-content;
  background-color:#FFFFFF;
  border-radius:24px;
  gap:50px;
  padding:100px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  margin-top:80px;
`;

export const SuccessText = styled.div`
  width:100%;
  text-align:center;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  gap:20px;

  h1{
    font-size: 1.2rem;
    font-weight: bold;
    margin:0;
    padding:0;
  }

  span {
    font-size: 1rem;
    max-width:60%;
  }

  a {
    width:100%;
    font-weight: bold;
    color:${(props) => props.color};;

  }

  @media (max-width: 1550px) {
    span {
    font-size: 1rem;
    max-width:100%;
  }
}
`;

export const LinkContainer = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  justify-content:center;
  align-items:center;
  
`;

export const LinkButton = styled.div`
  width:150px;
  height: 40px;
  border-radius:5px;
  background-color:${(props) => props.color};
  display:flex;
  justify-content:center;
  align-items:center;
  color:#FFFFFF;
  box-shadow: 0px 0px 3px -1px #000000;
  font-weight:bold;
    font-size:13px;
  cursor: pointer;

  :active{
    filter: brightness(0.8);
  }

`;
