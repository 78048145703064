/* eslint-disable max-len */
/* eslint-disable no-alert */
import React, {
  useState, useCallback, useEffect,
} from 'react';

import {
  Skeleton,
} from 'antd';

import 'moment/locale/pt-br';
import 'react-day-picker/lib/style.css';

import { Chart } from 'react-google-charts';
import { useTranslation } from 'react-i18next';
import { HandleFormatMoney } from '../../services/utils';
import api from '../../services/api';
import { useSettings } from '../../hooks/settings';

import {
  Content,
  TypesContainer,
  InfosContainer,
  SelectionButton,
  TypesContainertopright,
  TypesContainerlowerright,
  TypesContainerlowerleft,
  TypesContainertop,
  TypesContainerminisuperior,
} from './styles';
import img from '../../assets/wibbank1.png';
import imgicon from '../../assets/wiibank2.png';
import imgicard from '../../assets/wiibank3.png';

function HomeWibbank() {
  const [loadingGraph, setLoadingGraph] = useState(false);

  const [creditAmountFirst, setCreditAmountFirst] = useState(1);
  const [debitAmountFirst, setdebitamountFist] = useState(1);
  const [creditAmountSecond, setCreditAmountSecond] = useState(1);
  const [debitAmountSecond, setDebitAmountSecond] = useState(1);
  const [creditAmountThird, setCreditAmountThird] = useState(1);
  const [debitAmountThird, setDebitAmountThird] = useState(1);

  const { t } = useTranslation();

  const { settings } = useSettings();

  const loadGraphData = useCallback(async () => {
    setLoadingGraph(true);
    try {
      const { data } = await api.get('transactions/extractgraphic');
      console.log(data);
      setCreditAmountFirst(data.credit_amount[0]);
      setdebitamountFist(data.debit_amount[0]);
      setCreditAmountSecond(data.credit_amount[1]);
      setDebitAmountSecond(data.debit_amount[1]);
      setCreditAmountThird(data.credit_amount[2]);
      setDebitAmountThird(data.debit_amount[2]);
    } catch (err) {
      console.log(err.response.data);
    }
    setLoadingGraph(false);
  }, []);

  useEffect(() => {
    loadGraphData();
  }, [loadGraphData]);

  return settings && settings.name ? (
    <>
      <div style={{
        display: 'flex', alignItems: 'center', maxWidth: '1060px', justifyContent: 'space-between',
      }}
      />
      <Content>
        { loadingGraph ? (
          <>
            <Skeleton active title="Cobranças" loading paragraph={2} rows={2} />
          </>
        ) : (
          <>
            <InfosContainer>
              <TypesContainertop>
                <TypesContainerminisuperior>
                  <p style={{ fontWeight: 'bold' }}>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0)">
                        <path
                      // eslint-disable-next-line max-len
                          d="M9.53092 1.85732H4.19759C3.84397 1.85732 3.50483 1.9978 3.25478 2.24785C3.00473 2.49789 2.86426 2.83703 2.86426 3.19065V13.8573C2.86426 14.2109 3.00473 14.5501 3.25478 14.8001C3.50483 15.0502 3.84397 15.1907 4.19759 15.1907H12.1976C12.5512 15.1907 12.8904 15.0502 13.1404 14.8001C13.3904 14.5501 13.5309 14.2109 13.5309 13.8573V5.85732L9.53092 1.85732Z"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.53101 1.85732V5.85732H13.531"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.8643 9.19065H5.53101"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.8643 11.8573H5.53101"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.86434 6.52399H6.19767H5.53101"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="16" height="16" fill="white" transform="translate(0.197632 0.523987)" />
                        </clipPath>
                      </defs>
                    </svg>
                    {' '} {t('extrato')}
                  </p>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  >
                    <Chart
                      width="25%"
                      chartType="ColumnChart"
                      data={[
                        ['mes', 'Entrada', 'Saida'],
                        [creditAmountFirst?.mes_ano || 0,
                          debitAmountFirst?.soma_mes || 0,
                          creditAmountFirst?.soma_mes || 0],
                        [creditAmountSecond?.mes_ano || 0,
                          debitAmountSecond?.soma_mes || 0,
                          creditAmountSecond?.soma_mes || 0],
                        [creditAmountThird?.mes_ano || 0,
                          debitAmountThird?.soma_mes || 0,
                          creditAmountThird?.soma_mes || 0],
                      ]}
                      options={{
                        chartArea: { width: '100%', height: '65%' },
                        width: 300,
                        height: 160,
                        colors: ['#DA1E28', '#00C58A'],
                        bar: { groupWidth: '75%' },
                        isStacked: true,
                        backgroundColor: 'transparent',
                        legend: { position: 'none' },
                        vAxis: {
                          gridlines: {
                            color: 'transparent',
                          },
                        },
                      }}
                    />
                    <div style={{
                      marginLeft: 'auto',
                      marginRight: '10%',
                      alignSelf: 'center',
                    }}
                    >
                      <span style={{
                        fontSize: 15,
                      }}
                      >
                        {t('receita')}
                      </span><br />
                      <span style={{
                        color: 'green',
                        fontWeight: 'bold',
                      }}
                      >
                        {HandleFormatMoney(creditAmountFirst?.soma_mes || 0)}
                      </span><br /><br />
                      <span style={{
                        fontSize: 15,
                      }}
                      >
                        {t('despesas')}
                      </span><br />
                      <span style={{
                        fontWeight: 'bold',
                      }}
                      >
                        {HandleFormatMoney(debitAmountFirst?.soma_mes || 0)}
                      </span>
                    </div>
                  </div>
                </TypesContainerminisuperior>
                <TypesContainertopright>
                  <p style={{ fontWeight: 'bold' }}>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0)">
                        <path
                      // eslint-disable-next-line max-len
                          d="M9.53092 1.85732H4.19759C3.84397 1.85732 3.50483 1.9978 3.25478 2.24785C3.00473 2.49789 2.86426 2.83703 2.86426 3.19065V13.8573C2.86426 14.2109 3.00473 14.5501 3.25478 14.8001C3.50483 15.0502 3.84397 15.1907 4.19759 15.1907H12.1976C12.5512 15.1907 12.8904 15.0502 13.1404 14.8001C13.3904 14.5501 13.5309 14.2109 13.5309 13.8573V5.85732L9.53092 1.85732Z"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.53101 1.85732V5.85732H13.531"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.8643 9.19065H5.53101"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.8643 11.8573H5.53101"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.86434 6.52399H6.19767H5.53101"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="16" height="16" fill="white" transform="translate(0.197632 0.523987)" />
                        </clipPath>
                      </defs>
                    </svg>
                    {' '} {t('vendas')}
                  </p>
                  <div style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginBottom: 10,
                  }}
                  >
                    <img
                      src={imgicon}
                      alt="wiibank"
                      width="100px"
                      height="100px"
                      float="right"
                      alignItems="center"
                      justifyContent="space-between"
                    />
                    <span style={{ width: '70%' }}>

                      {t('ofereca diferentes formas')}
                    </span>
                  </div>
                  <SelectionButton>
                    <span>{t('conhecer solucoes')}
                    </span>
                  </SelectionButton>
                </TypesContainertopright>
              </TypesContainertop>
            </InfosContainer>
            <InfosContainer>
              <TypesContainer>
                <TypesContainerlowerleft>
                  <div style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                    flexWrap: 'wrap',

                  }}
                  >
                    <span style={{ width: '50%', textAlign: 'center', color: 'black' }}>
                      <span style={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>
                        {t('voce no controle')}
                      </span><br /><br />
                      {t('wibbank e uma plataforma completa')}
                    </span>
                    <img
                      src={img}
                      alt="wiibank"
                      width="50%"
                      alignItems="center"
                    />
                  </div>
                </TypesContainerlowerleft>
                <TypesContainerlowerright>
                  <p style={{ fontWeight: 'bold' }}>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0)">
                        <path
                      // eslint-disable-next-line max-len
                          d="M9.53092 1.85732H4.19759C3.84397 1.85732 3.50483 1.9978 3.25478 2.24785C3.00473 2.49789 2.86426 2.83703 2.86426 3.19065V13.8573C2.86426 14.2109 3.00473 14.5501 3.25478 14.8001C3.50483 15.0502 3.84397 15.1907 4.19759 15.1907H12.1976C12.5512 15.1907 12.8904 15.0502 13.1404 14.8001C13.3904 14.5501 13.5309 14.2109 13.5309 13.8573V5.85732L9.53092 1.85732Z"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.53101 1.85732V5.85732H13.531"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.8643 9.19065H5.53101"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.8643 11.8573H5.53101"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.86434 6.52399H6.19767H5.53101"
                          stroke="#393939"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="16" height="16" fill="white" transform="translate(0.197632 0.523987)" />
                        </clipPath>
                      </defs>
                    </svg>
                    {' '}  {t('cartao pre pago')}
                  </p>
                  <div style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '300px',
                    flexWrap: 'wrap',
                    marginLeft: '20px',
                    marginRight: '20px',
                  }}
                  >
                    <img
                      src={imgicard}
                      alt="wiibank"
                      width="100px"
                      height="100px"
                      float="right"
                      alignItems="center"
                    />
                    <span style={{ width: '70%', textAlign: 'center', fontSize: 14 }}>
                      {t('quer ter cartao')}
                    </span>
                    <SelectionButton>
                      <span>
                        {t('pedir agora')}
                      </span>
                    </SelectionButton>
                  </div>
                </TypesContainerlowerright>
              </TypesContainer>
            </InfosContainer>
          </>
        )}

      </Content>
    </>
  ) : (<></>);
}

export default HomeWibbank;
