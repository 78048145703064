/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSun,
  faHiking,
  faGlasses,
  faFaceLaugh,
  faPerson,
} from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom";
import { GoSaffeCapture } from "@go.saffe/go-saffe-react-js";
import { message } from "antd";
import { useSettings } from "../../../../../../hooks/settings";
import { useCheckpoint } from "../../../../../../hooks/checkpoint";
import { useSteps } from "../../../../../../hooks/steps";
import {
  TextStyled,
  InfoStyled,
  FalseModel,
  Box,
} from "./styles";
import api from "../../../../../../services/api";
import { CheckpointService } from "../../../../../../services/endPointRegister/CheckpointService";
import ModalForTransition from '../../../../../../components/ModalForTransition';
import Notification from '../../../../../../components/Notification';
import { useAuth } from "../../../../../../hooks/auth";

const Cam = () => {
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const {
    nextStep,
    step,
    stepsPf,
    exactStep,
    resetStep,
    setPhotoAttempts,
    photoAttempts,
  } = useSteps();
  const history = useHistory();
  const { getEmailSupportMessage, settings } = useSettings();
  const [image, setImage] = useState("");
  const [cameraActive, setCameraActive] = useState(false);
  const [photoData, setPhotoData] = useState({ photo: "" });
  const [loading, setLoading] = useState(true);
  const [randomNumber, setRandomNumber] = useState(5);
  const [error, setError] = useState("");
  const [notify, setNotify] = useState(false);
  const [shouldSendGeneralPendency, setShouldSendGeneralPendency] = useState(false);
  const [frontalSelfie, setFrontalSelfie] = useState(false);
  const [downSelfie, setDownSelfie] = useState(false);
  const [upSelfie, setUpSelfie] = useState(false);
  const [leftSelfie, setLeftSelfie] = useState(false);
  const [rightSelfie, setRightSelfie] = useState(false);
  const [frontalSelfieDone, setFrontalSelfieDone] = useState(false);
  const [downSelfieDone, setDownSelfieDone] = useState(false);
  const [upSelfieDone, setUpSelfieDone] = useState(false);
  const [leftSelfieDone, setLeftSelfieDone] = useState(false);
  const [rightSelfieDone, setRightSelfieDone] = useState(false);
  const [showBlackList, setShowBlackList] = useState(false);
  const token = localStorage.getItem("@qesh:token");
  const lastAttempt = photoAttempts === 1;
  const [selfieUnique, setSelfieUnique] = React.useState(false);
  const [infos, setInfos] = React.useState({
    apiKey: process.env.REACT_APP_API_KEY || '',
    user: '',
    type: 'onboarding',
    endToEndId: '',
  });

  const { signOut } = useAuth();

  React.useEffect(() => {
    if (settings.name === "nmpay" || settings.name === "qesh") {
      getCheckpoints();
    }
  }, [settings.name]);

  useEffect(() => {
    const random = frontalSelfieDone ? Math.floor(Math.random() * 4) : 5;
    setRandomNumber(random);
    if (!cameraActive) {
      message.success(`Hora da selfie! Por favor, ative sua câmera!`, 5);
    }
    if (
      localStorage.getItem("@qesh:token") &&
      sessionStorage.getItem("@qesh:step") === "10"
    ) {
      exactStep(10);
    }
  }, []);



  const getCheckpoints = async () => {
    try {
      const { res } = await CheckpointService(settings.name);

      const hasSelfieUnique = res.response.find(
        (checkpoint) => checkpoint === "SelfieUnique"
      );

      setSelfieUnique(hasSelfieUnique ? true : false);
    } catch (error) {
      console.log("Error getCheckpoints", error);
    }
  };

  const randomStep = () => {
    if (selfieUnique && !frontalSelfieDone) {
      setUpSelfie(false);
      setLeftSelfie(false);
      setRightSelfie(false);
      setDownSelfie(false);
      setFrontalSelfie(true);
    }

    if (randomNumber === 1 && !downSelfieDone) {
      setUpSelfie(false);
      setLeftSelfie(false);
      setRightSelfie(false);
      setFrontalSelfie(false);
      setDownSelfie(true);
    }
    if (randomNumber === 2 && !upSelfieDone) {
      setDownSelfie(false);
      setLeftSelfie(false);
      setRightSelfie(false);
      setFrontalSelfie(false);
      setUpSelfie(true);
    }
    if (randomNumber === 3 && !leftSelfieDone) {
      setUpSelfie(false);
      setRightSelfie(false);
      setFrontalSelfie(false);
      setDownSelfie(false);
      setLeftSelfie(true);
    }
    if (randomNumber === 4 && !rightSelfieDone) {
      setUpSelfie(false);
      setLeftSelfie(false);
      setFrontalSelfie(false);
      setDownSelfie(false);
      setRightSelfie(true);
    }
    if (randomNumber === 5 && !frontalSelfieDone) {
      setUpSelfie(false);
      setLeftSelfie(false);
      setRightSelfie(false);
      setDownSelfie(false);
      setFrontalSelfie(true);
    }
  };

  const handleAdvance = async () => {
    setCheckpoint({ ...checkpoint, photoData });
    nextStep();

    if (stepsPf.length >= step + 1) {
      history.replace(`/register/${stepsPf[step + 1]}`);
    } else {
      history.replace(`/register/${stepsPf[step]}`);
    }
  };

  // const handleValidateSelfie = async (data) => {
  //   alert(data)
  //   setLoading(true);

  //   const formData = new FormData();
  //   formData.append("file", photoData.photo);
  //   fetch(photoData.photo)
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       const formData = new FormData();
  //       const file = new File([blob], "selfie.png");
  //       formData.append("file", file);
  //       api
  //         .post("onboarding/documents/SELFIE", formData, {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             authorization: `bearer ${token}`,
  //           },
  //         })
  //         .then(() => {
  //           setLoading(false);
  //           setError(false);
  //           setImage("");
  //           handleAdvance()
  //           setFrontalSelfieDone(true);
  //         })
  //         .catch((error) => {
  //           setLoading(false);
  //           setError(true);
  //           setImage("");
  //           setPhotoAttempts(error?.response?.data?.message?.attempts);
  //           if (
  //             error?.response?.data?.message?.message ===
  //             "Could not detect a face. Try another image. "
  //           ) {
  //             message?.error(
  //               "Não foi possível detectar sua face. Tente novamente.",
  //               6
  //             );
  //           } else if (
  //             error.response.data.message.message ===
  //             "Please try to avoid excessive light in the background. "
  //           ) {
  //             return message?.error(
  //               "Evite excesso de luz no ambiente. Tente novamente. ",
  //               6
  //             );
  //           } else if (
  //             error?.response?.data?.message?.message ===
  //             "The attempt limit for the day has been exceeded"
  //           ) {
  //             return message?.error(
  //               `Tentativas excedidas. ${getEmailSupportMessage()} ou tente novamente amanhã.`,
  //               7
  //             );
  //           } else {
  //             return message?.error(
  //               `Erro ao validar a imagem, gentileza envie outra foto.`,
  //               7
  //             );
  //           }
  //         });
  //     });
  // };

  function handleValidateSelfie() {
    api
      .post('onboarding/recognition/validate', {}, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `bearer ${token}`,
        },
      })
      .then((data) => {
        setLoading(false);
        setError(false);
        setImage("");
        handleAdvance()
        setFrontalSelfieDone(true);
      }).catch((error) => {
        setLoading(false);
        setError(true);
        setImage("");
        setPhotoAttempts(error?.response?.data?.message);
        if (error?.response?.data?.message?.includes('BLACKLIST')) {
          setShowBlackList(true)
          setError(getEmailSupportMessage(_, true));
          setLoading(false);
          return;
        }
        if (
          error?.response?.data?.message ===
          "Internal Server Error"
        ) {
          message?.error(
            "Erro interno",
            6
          );
        } else if (error?.response?.data?.message.includes('connect ECONNREFUSED')) {
          message?.error(
            "Erro interno",
            6
          );
        }
      })
  }

  async function getUser() {
    const user = await localStorage.getItem("@qesh:userOnboarding");
    if (user) {
      const userData = JSON.parse(user)
      setInfos((infos) => {
        return {
          ...infos,
          user: userData.email,
          endToEndId: userData.id
        }
      })
      setLoading(false);
    }
  }

  React.useEffect(() => {
    getUser()

  }, []);

  function redirectRefresh() {
    signOut()
    history.replace("/")
    return
  }

  return (

    <div>
      <Box>
        {!loading && <GoSaffeCapture
          captureKey={infos.apiKey}
          endToEndId={infos.endToEndId}
          type="onboarding"
          user={infos.user}
          onClose={() => console.log("close")}
          onFinish={handleValidateSelfie}
        />}
      </Box>
      <Notification
        shouldSendGeneralPendency={shouldSendGeneralPendency}
        type="success"
        visible={error}
        isNotify={notify}
        setIsNotify={setNotify}
        title={""}
        hidden={() => redirectRefresh()}
      >
        {error}
      </Notification>
      <TextStyled>
        <FontAwesomeIcon icon={faFaceLaugh} />
        Lembre-se de encaixar o seu rosto no círculo para melhores resultados.
      </TextStyled>
      <TextStyled>
        <FontAwesomeIcon icon={faSun} />
        Encontre um lugar com boa iluminação.
      </TextStyled>
      <TextStyled>
        <FontAwesomeIcon icon={faPerson} />
        Essa é uma foto formal, então não faça poses ou caretas.
      </TextStyled>
      <TextStyled>
        <FontAwesomeIcon icon={faGlasses} />
        Retire os acessórios que cobrem seu rosto, como óculos e bonés.
      </TextStyled>
      <TextStyled>
        <FontAwesomeIcon icon={faHiking} />
        Siga as instruções apresentadas na tela.
      </TextStyled>
      <InfoStyled>
        Quando essa mensagem aparecer, clique em permitir para que possamos
        acessar a sua câmera.
      </InfoStyled>
      <FalseModel src="https://res.cloudinary.com/djbzvkmqm/image/upload/v1638384172/Platter_3_gqtbtn.png" />
    </div>

  )
};

export default Cam;
