import styled from 'styled-components';

export const Section = styled.section`
display: flex;
align-items: center;
position: absolute;
left: 0rem;
top: 0rem;
width: 65%;
height: 100vh;
background-color: rgba(15, 15, 15, 0.8);
overflow-y: scroll;
`;

export const HeaderArea = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
width: 60%;
margin: 30px 0px 30px 0px;
height: auto;
align-items: center;
`;

export const TextStyled = styled.text`
align-items: center;
font-size: 1rem;
padding-bottom: 1.4rem;
display: flex;
gap: 0.5rem;
`;

export const InfoStyled = styled.text`
align-items: center;
font-size: 1rem;
padding-top: 1.4rem;
display: flex;
gap: 0.5rem;
text-align: center;
`;

export const CamStyled = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
width: 90%;
height: auto;
align-items: center;
`;

export const Preview = styled.img`
margin: 0 !important;
max-height: 100% !important;
max-width: 100% !important;
background-repeat: no-repeat;
object-fit: cover;
background-image: ${(props) => props.src};
align-items: center;
`;

export const ButtonPicture = styled.path`
cursor: pointer;
display: flex;
align-self: center;
padding: 1rem;
border-radius: 50%;
margin: 1rem 0;
background: var(--primary);
`;

export const ButtonPhoto = styled.div`
cursor: pointer;
display: flex;
margin: 2rem;
gap: 2rem;
`;

export const FalseModel = styled.img`
margin-top: 25px;
min-width: 280px;
min-height: 150px;
box-shadow: 2px 2px #ccc;
border: 1px solid #ccc;
`;

export const WhiteTitle = styled.h1`
color: white;
align-items: center;
font-size: 1.1rem;
text-align: center;
font-weight: bold;
`;

export const WhiteText = styled.h1`
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 150%;
color: white;
text-align: center;
letter-spacing: 0.0025em;
margin-top: 10px;
@media screen and (max-width: 60rem) {
width: 100%;
}
`;
export const Container = styled.section`
height: fit-content !important;
width: fit-content !important;
@-moz-document url-prefix() {
height: 100% !important;
width: 100% !important;
}
position: relative;
align-self: center;
`;
export const OverlayContainer = styled.div`
display: flex;
position: absolute;
height: 100%;
width: 100%;
z-index: 1;
`;

export const Corner1 = styled.div`
position: absolute;
height: 3rem;
width: 3rem;
margin: -0.8rem;
border-bottom: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
border-left: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
bottom: 0;
`;
export const Corner2 = styled.div`
position: absolute;
height: 3rem;
width: 3rem;
margin: -0.8rem;
border-top: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
border-left: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
`;
export const Corner3 = styled.div`
position: absolute;
height: 3rem;
width: 3rem;
margin: -0.8rem;
border-bottom: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
border-right: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
bottom: 0;
right: 0;
`;
export const Corner4 = styled.div`
position: absolute;
height: 3rem;
width: 3rem;
margin: -0.8rem;
border-top: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
border-right: ${({ borderColor }) => (borderColor ? `0.2rem solid ${borderColor}` : '0.2rem solid var(--primary)')};
right: 0;
`;
export const Box = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0.3rem;
align-items: center;
position: relative;
width: fit-content;
height: 100%;
min-width: 400px;
min-height: 300px;
`;
