import React, { useState, useCallback, useRef } from 'react';

// import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Form } from '@unform/web';
import { useAuth } from '../../hooks/auth';
import { useSettings } from '../../hooks/settings';
import { Container, AccountsContainer, AccountItem } from './styles';
import RegisterHeader from '../../components/RegisterHeader';
import Loading from '../../components/Loading';
import api from '../../services/api';
import { formatAccount, formatBranch } from '../Transaction/Receipt/utils';
import Notification from '../../components/Notification';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Password from '../../components/Password';

function AccountSelection() {
  const { t } = useTranslation();
  const {
    user, accountsToChoose, selectAccount, account,
  } = useAuth();
  const { settings } = useSettings();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const formRef = useRef();

  const [codeSent, setCodeSent] = useState(null);
  const [showConfirmationInput, setShowConfirmationInput] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState(null);

  const handleValidateLoginConfirmation = useCallback(async () => {
    try {
      await api.get(`/login-confirmation/${confirmationCode}`);
      localStorage.setItem('@loginApproved', 'true');
      setLoading(true);
      await selectAccount({ id: selectedAccount });
      setLoading(false);
    } catch (err) {
      setError(t('código inválido'));
    }
  }, [confirmationCode, selectAccount, selectedAccount, t]);

  const handleClickAccount = useCallback(async (acc) => {
    setSelectedAccount(acc);
    if (settings.name === 'faemg') {
      try {
        setLoading(true);
        const { data } = await api.post('/login-confirmation/send-sms');
        setCodeSent(data.message);
        setShowConfirmationInput(true);
        setError(null);
        setLoading(false);
      } catch (er) {
        setError(er.response.data?.message);
        setLoading(false);
      }
    } else if (settings.name === 'qesh' && acc !== '1bcea955-f0d3-4514-9a84-a75298981bf1') {
      setShowPassword(true);
    } else {
      setLoading(true);
      await selectAccount({ id: acc });
      setLoading(false);
    }
  }, [selectAccount, settings]);

  const handleEnterAccount = useCallback(async (password) => {
    setLoading(true);
    setError(null);
    try {
      await api.post('/accounts/validate/password', {
        password,
      }, {
        headers: {
          account: selectedAccount,
        },
      });
      await selectAccount({ id: selectedAccount });
    } catch (err) {
      setError('A senha digitada é inválida.');
      console.log(err);
    }
    setLoading(false);
  }, [selectedAccount, selectAccount]);

  if (!user || !accountsToChoose) {
    return <Redirect to="/" />;
  }

  if (settings && settings.name) {
    return (
      <>
        <Loading hasLoading={loading} />
        <Notification
          type="success"
          visible={!!codeSent}
          title={t('código enviado')}
          con
          hidden={() => setCodeSent(null)}
        >
          {codeSent}
        </Notification>
        <Password
          visible={showPassword}
          hidden={() => setShowPassword(false)}
          afterPassword={handleEnterAccount}
          customDescription="Digite sua senha de 4 dígitos para acessar a conta"
        />
        <RegisterHeader noTitle />
        <Container>
          <h1 style={{ textAlign: 'center' }}>{t('bem-vindo')},<br /> {user.name}</h1>
          <h2 style={{ textAlign: 'center' }}>{t('escolha a conta que deseja acessar')}:</h2>
          <span style={{ color: 'red' }}>{error}</span>

          <br />
          {showConfirmationInput ? (
            <>
              <Form ref={formRef} onSubmit={() => {}}>
                <Input
                  onChange={(e) => setConfirmationCode(e.target.value)}
                  name="code"
                  id="code"
                  label={t('insira o código recebido por sms')}
                  style={{ minWidth: 300 }}
                />
                <br />
                <Button onClick={handleValidateLoginConfirmation}>{t('confirmar')}</Button>
              </Form>
            </>
          ) : (
            <AccountsContainer>
              {accountsToChoose.map((item) => (
                <AccountItem
                  key={item.id || item.account_id}
                  onClick={() => handleClickAccount(item.id || item.account_id)}
                >
                  {item.alias && (<>{item.alias} <br /></>)}
                  {item.bacen_status != null ? (
                    <>
                      {t('banco')}: {item.bank_code}
                      <br />
                      {t('agencia')}: {formatBranch(item.bank_branch)}
                      <br />
                    </>
                  ) : null}
                  {t('Conta')} {formatAccount(item.bank_account)}
                </AccountItem>
              ))}
            </AccountsContainer>
          )}
        </Container>
      </>

    );
  }

  return (<></>);
}

export default AccountSelection;
