import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 16px;
    display: inline;
    position: absolute;
    top: -12px;
    background: #ffffff;
    color: ${(props) => (props.error ? '#FA4E39' : '#707070')};
    font-weight: bold;
    margin-left: 1px;
  }

  textarea {
    resize: none;
    margin-top: 10px;
    flex: 1;
    font-size: 14px;
    color: #707070;
    padding: 6px 0px;
    height: auto;
    border: 0;
    border-bottom: 1px solid ${(props) => (props.error ? '#FA4E39' : 'rgba(0,0,0, 0.3)')};

    ${(props) => props.error && css`
      border-bottom: 1px solid #FA4E39;
    `}

    ${(props) => props.focus && css`
      border: 1px solid #2E6FC7;
    `}
  }

  div.error-box {
    display: block;
    font-size: 14px;
    color: #FA4E39;
    padding-left: 4px;
  }
`;

export const RemainigCharactersContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;

  span {
    color: #707070;
    font-weight: italic;
  }
`;
