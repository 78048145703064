/* eslint-disable react/jsx-no-bind */
import React, {
  useState, useCallback, useEffect, useMemo, useRef,
} from 'react';

import DayPicker from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/pt-br';
import 'react-day-picker/lib/style.css';
import { useTranslation } from 'react-i18next';
import {
  format, parseISO, startOfMonth, isValid, addHours,
} from 'date-fns';

import {
  Table, Skeleton, Spin, Popconfirm, Tooltip, notification,
} from 'antd';
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';

import {
  FaCalendarAlt, FaTrash,
} from 'react-icons/fa';

import api from '../../../services/api';
import { HandleFormatMoney } from '../../../services/utils';
import { useSettings } from '../../../hooks/settings';

import {
  HeaderTab, FilterDate,
} from '../styles';

import { Content } from './styles';
import useOnClickOutside from '../../../hooks/onClickOutside';

const { Column } = Table;

function Scheduled() {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [items, setItems] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [start, setStart] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [end, setEnd] = useState(format(new Date(), 'yyyy-MM-dd'));
  const endRef = useRef(null);
  const startRef = useRef(null);
  const { settings } = useSettings();
  const [cancelLoading, setCancelLoading] = useState(false);
  const refStartCalendar = useRef();
  const refEndCalendar = useRef();
  const { t } = useTranslation();
  useOnClickOutside(refStartCalendar, () => startRef.current.handleInputBlur());
  useOnClickOutside(refEndCalendar, () => endRef.current.handleInputBlur());

  const getTransactions = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/transactions/scheduled?page=0&start=${start}&end=${end}`);
      setItems(data.items);
      setMetaData({
        current_page: data.current_page,
        total_pages: data.total_pages,
        total_items: data.total_items,
        total_items_page: data.total_items_page,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [start, end]);

  const getMoreItems = useCallback(async () => {
    setLoadingMore(true);
    try {
      const { data } = await api.get(
        `/transactions/scheduled?page=${currentPage + 1}&start=${start}&end=${end}`,
      );
      setCurrentPage(currentPage + 1);
      setItems([...items, ...data.items]);
      setMetaData({
        current_page: data.current_page,
        total_pages: data.total_pages,
        total_items: data.total_items,
        total_items_page: data.total_items_page,
      });
      setLoading(false);
      setLoadingMore(false);
    } catch (err) {
      setLoading(false);
      setLoadingMore(false);
    }
  }, [items, currentPage, start, end]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const startDate = useMemo(() => format(parseISO(start), 'dd/MM/yyyy'), [start]);
  const endDate = useMemo(() => format(parseISO(end), 'dd/MM/yyyy'), [end]);

  function formatDate(date) {
    return format(date, 'dd/MM/yyyy');
  }

  const handleChangeDate = useCallback((day, type) => {
    if (isValid(day)) {
      const formatDay = format(addHours(day, 3), 'yyyy-MM-dd');
      if (type === 'start') {
        setStart(formatDay);
      } else {
        setEnd(formatDay);
      }
    }
  }, []);

  const handleCancel = useCallback(async (item) => {
    setCancelLoading(true);
    try {
      await api.patch(`/transactions/scheduled/cancel/${item}`);
      getTransactions();
      notification.success({ message: t('agendamento cancelado com sucesso') });
    } catch (err) {
      notification.error({ message: t('nao foi possivel cancelar o agendamento. tente novamente mais tarde') });
      console.log(err);
    }
    setCancelLoading(false);
  }, [getTransactions, t]);

  const getFormattedStatus = useCallback((st) => {
    if (st === 'PENDING') return <span>{t('pendente')}</span>;
    if (st === 'NOT_EXECUTED') return <span style={{ color: 'red' }}>{t('erro ao executar')}</span>;
    if (st === 'EXECUTED') return <span style={{ color: 'green' }}>{t('executado')}</span>;

    return '';
  }, [t]);

  return (
    <>
      <Content color={settings.colors.primary}>
        <div style={{ marginBottom: 20, textAlign: 'center' }}>
          {t('as transacoes agendadas sao realizadas no proximo dia util')}
        </div>
        <HeaderTab>
          <FilterDate>
            <span>{t('de:')}</span>
            <section ref={refStartCalendar}>
              <DayPicker
                ref={startRef}
                isDisabled
                dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                format="dd/MM/yyyy"
                formatDate={formatDate}
                value={startDate}
                onDayChange={(day) => handleChangeDate(day, 'start')}
              />
              <FaCalendarAlt color={settings.colors.primary} onClick={() => startRef.current.handleInputClick()} />
            </section>

            <span>{t('ate:')}</span>

            <section ref={refEndCalendar}>
              <DayPicker
                ref={endRef}
                dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                format="dd/MM/yyyy"
                formatDate={formatDate}
                onDayChange={(day) => handleChangeDate(day, 'end')}
                value={endDate}
              />
              <FaCalendarAlt color={settings.colors.primary} onClick={() => endRef.current.handleInputClick()} />
            </section>
          </FilterDate>
        </HeaderTab>
        {loading ? (
          <>
            <Skeleton active title={t('extrato')} loading={loading} paragraph={2} rows={2} />
            <Skeleton active title={t('extrato')} loading={loading} paragraph={2} rows={2} />
            <Skeleton active title={t('extrato')} loading={loading} paragraph={2} rows={2} />
          </>
        ) : (
          <>
            <Table
              dataSource={items}
              pagination={false}
              rowKey="id"
              loading={loading}
              locale={{
                emptyText: t('nenhum agendamento para exibir'),
              }}
              scroll={{ x: true }}
            >

              <Column
                title={t('nome do beneficiario')}
                dataIndex="name"
                key="name"
                responsive={['xs', 'sm', 'md', 'xl']}
              />

              <Column
                title={t('documento do beneficiario')}
                dataIndex="document"
                key="document"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title={t('banco')}
                dataIndex="bank"
                key="bank"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title={t('agencia')}
                dataIndex="agency"
                key="agency"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title={t('conta')}
                dataIndex="account"
                key="account"
                responsive={['xs', 'sm', 'md', 'xl']}
              />

              <Column
                title={t('valor')}
                dataIndex="amount"
                key="amount"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(text) => HandleFormatMoney(+text)}
              />

              <Column
                title={t('status')}
                dataIndex="status"
                key="status"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(text) => getFormattedStatus(text)}
              />

              <Column
                title={t('data de execucao')}
                dataIndex="executed_at"
                key="executed_at"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(text) => (text ? formatDate(new Date(text)) : '-')}
              />

              <Column
                title=""
                dataIndex="cancel"
                key="cancel"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(_text, it) => (it.status === 'PENDING' ? (
                  <Tooltip title={t('cancelar agendamento')}>
                    <Popconfirm
                      placement="left"
                      title={t('deseja mesmo cancelar esse agendamento')}
                      onConfirm={() => handleCancel(it.id)}
                      okText={t('sim')}
                      cancelText={t('nao')}
                    >{cancelLoading ? <Spin /> : (
                      <FaTrash
                        style={{ marginTop: 7, cursor: 'pointer', color: 'grey' }}
                        size={15}
                      />
                    )}
                    </Popconfirm>
                  </Tooltip>

                ) : '')}
              />

              {/* <Column
              title=""
              dataIndex="namex"
              key="namex"
              responsive={['xs', 'sm', 'md', 'xl']}
              render={(text, record) => (
                <div>
                  {record.type === 'OPEN_ACCOUNT' || record.type === 'FEE' ? null
                    : (
                      <FaReceipt
                        style={{ cursor: 'pointer' }}
                        size={19}
                        color="#636466"
                        onClick={() => setReceipt(record)}
                      />
                    )}
                </div>
              )}
            /> */}

            </Table>

            <div style={{
              cursor: 'pointer',
              margin: '15px auto',
              display: 'flex',
              width: '200px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              {loadingMore ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#121212' }} spin />} />
              ) : (
                <>
                  {metaData.total_items_page >= 50 && metaData.current_page < metaData.total_pages && (
                  <div
                    onClick={() => getMoreItems()}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '18px',
                    }}
                  >
                    <span style={{ paddingRight: '5px' }}>{t('carregar mais')}</span> <PlusCircleOutlined />
                  </div>
                  )}
                </>
              )}
            </div>

          </>
        )}

        {/* <Modal
        centered
        visible={!!receipt}
        onOk={() => setReceipt('')}
        closable={false}
        onCancel={() => setReceipt('')}
        footer={false}
        destroyOnClose
        style={{ padding: 0, margin: 0 }}
      >
        <Receipt item={receipt} onCancel={() => setReceipt('')} settings={settings} />
      </Modal> */}
      </Content>
    </>
  );
}

export default Scheduled;
