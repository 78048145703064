/* eslint import/no-cycle: [2, { ignoreExternal: true }] */
import { useTranslation } from 'react-i18next';
import { useState, React } from 'react';
import {
  Checkbox, message, Modal, Spin,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  InputContainer,
  LightText,
  Text,
  TextContainer,
  Title,
} from '../styles';
import InputN from '../../../components/InputN';
import {
  annualRevenue,
  incomeMonth,
  professionsList,
} from '../../../utilitys/annualRevenue';
import { educationLevel } from '../../../utilitys/educationLevel';
import { maritalStatus } from '../../../utilitys/maritalStatus';
import { PepBoxStyled } from '../../Register/Steps/PF/AdditionalData/styles';
import { ModalStyle } from '../../../components/CnpjInfo/styles';
import ButtonN from '../../../components/ButtonN';
import { useRefresh } from '../../../hooks/refresh';
import { useSettings } from '../../../hooks/settings';
import { TextInfoContainer } from './styles';

export const Additional = () => {
  const {
    setStep, additional, setAdditional, refreshApi,
  } = useRefresh();
  const { t } = useTranslation();
  const [modalPpe, setModalPpe] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getEmailSupportMessage } = useSettings();

  const modalContentPpe = (
    <ModalStyle>
      <header>{t('Pessoa Politicamente Exposta (PPE)')}</header>
      <p style={{ textAlign: 'justify', marginBottom: '1.5rem' }}>
        {t(
          `Ser uma Pessoa Politicamente Exposta significa você, 
pessoas de seu relacionamento e familiares têm ou tiveram, 
nos últimos 5 (cinco) anos, no Brasil ou em outros países, cargos, 
empregos ou funções públicas relevantes (Circular 3.461, de 24/07/2009).`,
        )}
      </p>
      <p
        style={{
          textAlign: 'justify',
          marginBottom: '1.8rem',
          fontWeight: 'bold',
        }}
      >
        {t(
          'Ser uma Pessoa Politicamente Exposta não te impede de abrir uma conta aqui com a gente.',
        )}
      </p>
      <ButtonN type="primary" onClick={() => setModalPpe(false)}>
        {t('Entendido')}
      </ButtonN>
    </ModalStyle>
  );

  const handleChange = (key, value) => {
    setAdditional({ ...additional, [key]: value });
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      const {
        monthlyIncome,
        patrimony,
        relationship,
        occupation,
        education,
        isPEP,
      } = additional;
      refreshApi.additional({
        monthly_income: monthlyIncome,
        patrimony,
        relationship,
        occupation,
        education,
        isPEP,
      });
      setLoading(false);
      setStep('validation');
    } catch (error) {
      if (error.message === 'jwt expired') {
        message.error('Login expirado, favor fazer login novamente');
        return;
      }
      if (error.message.includes('BLACKLIST')) {
        message.error(`Infelizmente não vamos dar continuidade ao seu cadastro no momento. 
${getEmailSupportMessage()}`);
        return;
      }
      message.error(error.message);
      setLoading(false);
    }
  };

  const buttonAble = () => Object.keys(additional).some((key) => (
    key === 'isPEP' || key === 'nationality' ? false : additional[key] === '' || !additional[key]
  ));

  return (
    <Container>
      <InputContainer>
        <TextContainer>
          <Title>Para fecharmos essa parte!</Title>
          <Text>Precisamos dessas informações para conhecer melhor você!</Text>
        </TextContainer>
        <InputN.SelectandSearchModal
          id="escolaridade"
          label={t('Escolaridade')}
          placeholder={t('Insira sua escolaridade')}
          value={additional.education || ''}
          onChange={(value) => handleChange('education', value[0].name)}
          list={educationLevel}
          needSearchInput
          title={<Title>Escolaridade</Title>}
        />
        <InputN.SelectandSearchModal
          id="profissao"
          label={t('Profissão')}
          placeholder={t('Insira sua profissão')}
          value={additional.occupation || ''}
          onChange={(value) => handleChange('occupation', value[0].name)}
          list={professionsList}
          needSearchInput
          title={<Title>Profissão</Title>}
        />
        <TextInfoContainer>
          <InputN.SelectandSearchModal
            id="monthlyRevenue"
            label={t('Renda Mensal')}
            placeholder={t('Insira sua renda mensal')}
            value={additional.monthlyIncome || ''}
            onChange={(value) => handleChange('monthlyIncome', value[0].name)}
            list={incomeMonth()}
            needSearchInput={false}
            title={<Title>Renda Mensal</Title>}
          />
          <LightText style={{ fontSize: '0.75rem' }}>
            Somatória de todas as suas rendas obtidas por mes.
          </LightText>
        </TextInfoContainer>
        <InputN.SelectandSearchModal
          id="patrimonio"
          label={t('Patrimônio')}
          placeholder={t('Insira seu patrimônio')}
          value={additional.patrimony || ''}
          onChange={(value) => handleChange('patrimony', value[0].name)}
          list={annualRevenue}
          needSearchInput={false}
          title={<Title>Patrimônio</Title>}
          subTitle="De acordo com o Banco Central, precisamos saber o total do seu patrimônio"
        />
        <InputN.SelectandSearchModal
          id="estadoCivil"
          label={t('Estado Civil')}
          placeholder={t('Insira seu estado civil')}
          value={additional.relationship || ''}
          onChange={(value) => handleChange('relationship', value[0].name)}
          list={maritalStatus}
          needSearchInput={false}
          title={<Title>Estado Civil</Title>}
        />
        <PepBoxStyled>
          <Checkbox
            checked={additional.isPEP}
            onChange={(e) => setAdditional({ ...additional, isPEP: e.target.checked })}
            style={{ display: 'flex', alignItems: 'flex-start' }}
          >
            <p>{t('Pessoa Politicamente Exposta (PPE) ')}</p>
          </Checkbox>

          <FontAwesomeIcon
            icon={faQuestionCircle}
            style={{ color: 'var(--primary)' }}
            onClick={() => {
              setModalPpe(true);
            }}
          />
        </PepBoxStyled>
      </InputContainer>
      <ButtonN
        disabled={
          buttonAble()
        }
        type="primary"
        onClick={handleNext}
        style={{ margin: '1rem 0rem' }}
      >
        Continuar
      </ButtonN>
      <Modal
        visible={modalPpe}
        footer={null}
        closable={false}
        destroyOnClose
        focusTriggerAfterClose={false}
        onCancel={() => {
          setModalPpe(false);
        }}
        bodyStyle={{ padding: '2.5rem' }}
      >
        {modalContentPpe}
      </Modal>
    </Container>
  );
};
