/* eslint-disable react/jsx-no-bind */
import React, {
  useState, useCallback, useEffect, useRef, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaFilePdf, FaCalendarAlt,
} from 'react-icons/fa';
import {
  format, isAfter, addDays, parseISO, setHours, setMinutes, startOfMonth,
  isValid, addHours,
} from 'date-fns';
import * as Yup from 'yup';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Form } from '@unform/web';
import DayPicker from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/pt-br';
import 'react-day-picker/lib/style.css';
import {
  Table, Skeleton, notification,
} from 'antd';
import { Row, Col } from '../../styles/components';
import Input from '../../components/Input';
import Button from '../../components/Button';
import formErrors from '../../services/formErrors';
import getErrors from '../../services/errors';
import api from '../../services/api';
import { HandleFormatMoney } from '../../services/utils';
import {
  Content, Depositing, FilterDate, Title,
} from './styles';
import { useAuth } from '../../hooks/auth';
import { useSettings } from '../../hooks/settings';
import Notification from '../../components/Notification';
import Loading from '../../components/Loading';
import ButtonText from '../../components/ButtonText';
import useOnClickOutside from '../../hooks/onClickOutside';

const { Column } = Table;

function Deposit() {
  const { user } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingBillet, setLoadingBillet] = useState(false);
  const [deposit, setDeposit] = useState(false);
  const [billets, setBillets] = useState([]);
  const [billet, setBillet] = useState(false);
  const formRef = useRef(null);
  const [amount, setAmount] = useState(null);
  const { settings } = useSettings();
  const { t } = useTranslation();

  const endRef = useRef(null);
  const startRef = useRef(null);
  const [start, setStart] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [end, setEnd] = useState(format(new Date(), 'yyyy-MM-dd'));
  const refStartCalendar = useRef();
  const refEndCalendar = useRef();
  useOnClickOutside(refStartCalendar, () => startRef.current.handleInputBlur());
  useOnClickOutside(refEndCalendar, () => endRef.current.handleInputBlur());

  const startDate = useMemo(() => format(parseISO(start), 'dd/MM/yyyy'), [start]);
  const endDate = useMemo(() => format(parseISO(end), 'dd/MM/yyyy'), [end]);

  function formatDate(date) {
    return format(parseISO(date), 'dd/MM/yyyy');
  }

  function formatPickerData(date) {
    return format(date, 'dd/MM/yyyy');
  }

  const handleChangeDate = useCallback((day, type) => {
    if (isValid(day)) {
      const formatDay = format(day, 'yyyy-MM-dd');
      if (type === 'start') {
        setStart(formatDay);
      } else {
        setEnd(formatDay);
      }
    }
  }, []);

  const { address, user: baasUser } = user.baas;

  async function handleSubmit(data) {
    formRef.current.setErrors({});
    setLoadingBillet(true);
    setError(false);

    try {
      if (!amount || amount === 0) {
        formRef.current.setErrors({
          amount: t('o valor e obrigatorio'),
        });
        setLoadingBillet(false);
        return;
      }

      if (amount < 0.10) {
        formRef.current.setErrors({
          amount: t('valor minimo...'),
        });
        setLoadingBillet(false);
        return;
      }

      const schema = Yup.object().shape({
        id: Yup.string().required(t('o identificador e obrigatorio')),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { data: response } = await api.post('billets', {
        type: 'RECHARGE',
        description: data.id,
        name: baasUser.name,
        document: baasUser.document,
        due_at: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
        address: address.street,
        number: address.number || '',
        city: address.city,
        state: address.state,
        zipcode: address.zip_code,
        amount,
      });

      setLoadingBillet(false);
      setBillet(response);
    } catch (err) {
      setLoadingBillet(false);
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        formRef.current.setErrors(errors);
        return;
      }

      setError(getErrors(err));
    }
  }

  const loadBillets = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`billets?start=${start}&end=${end}&page=0&filter=type&value=RECHARGE`);
      setBillets(data.items);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [start, end]);

  useEffect(() => {
    setBillets([]);
    setBillet(false);
    setLoading(false);
    setLoadingBillet(false);
  }, []);

  useEffect(() => () => {
    setBillets([]);
    setBillet(false);
    setLoading(false);
    setLoadingBillet(false);
  }, []);

  useEffect(() => {
    loadBillets();
  }, [loadBillets]);

  const getStatus = useCallback((item) => {
    const now = setMinutes(setHours(new Date(), 8), 10);
    const dueAt = setMinutes(setHours(addHours(parseISO(item.due_at), 3), 8), 11);

    if (item.paid_at) {
      return <span style={{ color: '#38c77e', fontWeight: 600 }}>{t('pago')}</span>;
    }

    if (isAfter(now, dueAt)) {
      return <span style={{ color: 'red', fontWeight: 600 }}>{t('vencido')}</span>;
    }

    return <span style={{ color: 'inherit', fontWeight: 600 }}>{t('em aberto')}</span>;
  }, [t]);

  const changeValue = useCallback((event, maskedvalue, floatvalue) => {
    setAmount(floatvalue);
  }, []);

  return (
    <section>
      <Title>
        <h1>{t('depositos')}</h1>
      </Title>

      <Row gutter={16}>
        <Col md={24}>
          {!deposit ? (
            <Button
              right={(false)}
              type="button"
              onClick={() => {
                setDeposit(true);
                setError(false);
              }}
            >
              <span>{t('gerar boleto')}</span>
            </Button>
          ) : (
            <>

              <Depositing>
                <h2>{t('boleto bancario')}</h2>
                <p>{t('o dinheiro entrara em sua conta em ate...')}
                </p>
              </Depositing>

              {!billet ? (
                <Form ref={formRef} onSubmit={handleSubmit} initialData={{ amount: 1 }}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="depositvalue">
                      <Input
                        mask="currency"
                        id="amount"
                        name="amount"
                        label={t('quanto deseja depositar')}
                        value={amount}
                        onChangeEvent={changeValue}
                        type="text"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Input
                        id="id"
                        name="id"
                        label={t('identificador no comprovante')}
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col md={24}>
                      <Button
                        right={(false)}
                        style={{ marginTop: '30px', marginBottom: '-5px' }}
                        type="submit"
                      >
                        <span>{t('gerar boleto')}</span>
                      </Button>
                    </Col>
                  </Row>

                </Form>
              ) : (
                <div style={{
                  maxWidth: '600px',
                  background: '#f5f5f5',
                  borderRadius: '4px',
                }}
                >
                  <div style={{
                    background: settings.colors.primary,
                    padding: '5px',
                    borderRadius: '4px',
                    color: '#F9F9F9',
                    textAlign: 'center',
                    fontWeight: 600,
                    fontSize: 17,
                  }}
                  >
                    {t('dados do boleto')}
                  </div>

                  <div style={{ padding: '24px' }}>
                    <div style={{
                      fontSize: 18,
                      fontWeight: 600,
                      textAlign: 'center',
                      letterSpacing: '1px',
                    }}
                    >
                      {billet.digitable_line}
                    </div>

                    <div style={{ textAlign: 'center' }}>
                      <div style={{ fontSize: 16, margin: '15px 0' }}>
                        <CopyToClipboard
                          text={billet.digitable_line}
                          onCopy={() => {
                            notification.success({
                              message: t('linha digitavel'),
                              description:
                                t('linha digitavel copiada com sucesso'),
                            });
                          }}
                        >
                          <ButtonText>
                            {t('toque aqui para copiar')}
                          </ButtonText>
                        </CopyToClipboard>
                      </div>

                      <div style={{ marginBottom: '15px' }}>
                        <div>{t('valor')}{' '}
                          <span style={{ fontWeight: 600 }}>{HandleFormatMoney(billet.value)}</span>
                        </div>
                        <div>{t('vencimento')}{' '}
                          <span style={{ fontWeight: 600 }}>
                            {formatDate(addHours(new Date(billet.due_at), 3).toISOString())}
                          </span>
                        </div>
                      </div>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ButtonText>
                        <a href={billet.file_url} target="_blank" rel="noreferrer">
                          {t('imprimir boleto')}
                        </a>
                      </ButtonText>
                    </div>

                  </div>
                </div>
              )}
            </>
          )}

          {!deposit && (
          <Content>
            {loading ? (
              <>
                <Skeleton active title={t('deposito')} loading={loading} paragraph={2} rows={2} />
                <Skeleton active title={t('deposito')} loading={loading} paragraph={2} rows={2} />
                <Skeleton active title={t('deposito')} loading={loading} paragraph={2} rows={2} />
              </>
            ) : (
              <>
                <FilterDate>
                  <span>{t('de:')}</span>
                  <section ref={refStartCalendar}>
                    <DayPicker
                      ref={startRef}
                      dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                      format="dd/MM/yyyy"
                      formatDate={formatPickerData}
                      value={startDate}
                      onDayChange={(day) => handleChangeDate(day, 'start')}
                    />
                    <FaCalendarAlt
                      color={settings.colors.primary}
                      onClick={() => startRef.current.handleInputClick()}
                    />
                  </section>

                  <span>{t('ate:')}</span>

                  <section ref={refEndCalendar}>
                    <DayPicker
                      ref={endRef}
                      dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                      format="dd/MM/yyyy"
                      formatDate={formatPickerData}
                      onDayChange={(day) => handleChangeDate(day, 'end')}
                      value={endDate}
                    />
                    <FaCalendarAlt color={settings.colors.primary} onClick={() => endRef.current.handleInputClick()} />
                  </section>
                </FilterDate>

                <Table
                  dataSource={billets}
                  pagination={false}
                  rowKey="id"
                  loading={loading}
                  locale={{
                    emptyText: t('nenhum boleto para exibir'),
                  }}
                  scroll={{ x: true }}
                >

                  <Column
                    title={t('identificacao no comprovante')}
                    dataIndex="description"
                    key="description"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />

                  <Column
                    style={{ fontWeight: '700' }}
                    title={t('data de emissao')}
                    dataIndex="inclusion_at"
                    key="inclusion_at"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text) => (
                      <>
                        {formatDate(text)}
                      </>
                    )}
                  />

                  <Column
                    title={t('vencimento')}
                    dataIndex="due_at"
                    key="due_at"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text) => (
                      <>
                        {formatDate(addHours(new Date(text), 3).toISOString())}
                      </>
                    )}
                  />

                  <Column
                    title={t('valor')}
                    dataIndex="value"
                    key="value"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text) => (
                      <>
                        {HandleFormatMoney(text)}
                      </>
                    )}
                  />

                  <Column
                    title={t('status')}
                    dataIndex="paid_at"
                    key="paid_at"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text, record) => (
                      <>
                        {getStatus(record)}
                      </>
                    )}
                  />

                  <Column
                    title={t('boleto')}
                    dataIndex="paid_at"
                    key="paid_at"
                    align="center"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text, record) => (
                      <>
                        <a
                          style={{ color: '#333' }}
                          href={record.file_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaFilePdf size={21} />
                        </a>
                      </>
                    )}
                  />
                </Table>

                <div style={{
                  cursor: 'pointer',
                  margin: '15px auto',
                  display: 'flex',
                  width: '80px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                />
              </>
            )}
          </Content>
          )}
        </Col>
      </Row>
      <Notification
        type="error"
        visible={!!error}
        title={t('erro ao gerar boleto')}
        hidden={() => setError('')}
      >
        {error}
      </Notification>
      <Loading hasLoading={loadingBillet} />
    </section>
  );
}

export default Deposit;
