import { cepMask } from "./cepMask"
import { phoneMask } from "./phoneMask"
import { numberMask } from "./numberMask"
import { emailRegex } from "./emailRegex"

export const masks = {
  cep: cepMask,
  phone: phoneMask,
  cash: numberMask,
}

export const inputValidator = {
  cep: (value) => value.length <= 0 || /\d{8}/g.test(value),
  phone: (value) => value.length <= 0 || /\d{11}/g.test(value),
  email: (value) => value.length <= 0 || emailRegex.test(value),
  password: (value) => value.length <= 0 || /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/g.test(value),
  name: (value) => value.length <= 0 || value.length >= 3,
}