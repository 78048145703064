/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import { Layout } from 'antd';
import { GoThreeBars } from 'react-icons/go';
import { useAuth } from '../hooks/auth';

import Balance from '../components/Balance';
import Menu from '../components/Menu';
import Header from '../components/Header';

import Loading from '../components/Loading';
import {
  Hamburguer,
} from '../components/Menu/styles';
import { useSettings } from '../hooks/settings';
import { GlobalRoutes } from './GlobalRoutes';
import ButtonShop from '../components/ButtonShop';

const { Content, Sider } = Layout;

function Route({ isPrivate = false, component: Component, ...rest }) {
  const {
    user, updateAccount, account, getAccount, accountsToChoose,
  } = useAuth();
  const [isOpen, setIsOpen] = useState(true);
  const { settings } = useSettings();

  useEffect(() => {
    if (isPrivate && getAccount) {
      updateAccount();
    }
  }, [isPrivate, updateAccount, getAccount]);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const shouldCollapse = useCallback(() => {
    if (window.innerWidth > 800) {
      return false;
    }
    return isOpen;
  }, [isOpen]);

  const isOldIbUrl = useCallback(() => {
    const ibUrl = window.location.host;
    return ibUrl.endsWith('.ai');
  }, []);
  return (
    <ReactDOMRoute
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) => (isPrivate === !!user ? (
        <Layout style={{ height: '100vh', background: '#fff' }}>
          {isPrivate ? (
            <>
              {!account ? (
                accountsToChoose ? <Component />
                  : (
                    <div style={{
                      height: '100vh',
                      width: '100wh',
                      display: 'flex',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    >
                      <Loading hasLoading />
                    </div>
                  )
              ) : GlobalRoutes.includes(location.pathname) ? (
                <Component />
              ) : (
                <>
                  <Hamburguer onClick={handleToggle}>
                    <GoThreeBars size={23} />
                  </Hamburguer>

                  <Header />
                  <Layout style={{ marginTop: '0' }}>
                    <Sider
                      width={200}
                      collapsedWidth={0}
                      collapsed={shouldCollapse()}
                    >
                      <Balance />
                      {/* <ButtonShop path="/register-shop" /> */}
                      <Menu closeMenu={handleToggle} />
                    </Sider>
                    <Layout>
                      <Content
                        style={{
                          minHeight: '200px',
                          background: '#ffffff',
                          padding: '24px',
                        }}
                      >
                        <Component />
                      </Content>
                    </Layout>
                  </Layout>
                </>
              )}
            </>
          ) : (
            <>
              <Component />
            </>
          )}
        </Layout>
      ) : (
        GlobalRoutes.includes(location.pathname)
          ? (<Component />) : (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : (settings?.name === 'wibbank' ? '/homewib' : '/transaction'),
                state: { from: location },
              }}
            />
          )
      ))}
    />
  );
}

Route.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
};

Route.defaultProps = {
  isPrivate: false,
};

export default Route;
