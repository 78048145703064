import api from './api';

export class HandleRefreshData {
  constructor(token, userId, additionalId, addressId, accountId) {
    this.userId = userId;
    this.additionalId = additionalId;
    this.addressId = addressId;
    this.accountId = accountId;
    this.validateURL = 'onboarding/validate';
    this.sendCodeURL = '/onboarding/send';
  }

  async basicData(body) {
    try {
      const response = await api.put(`onboarding/basic-data/${this.userId}`, body, {
        headers: { additional: this.additionalId },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async address(body) {
    try {
      const response = await api.put(`onboarding/address/${this.addressId}`, body, {
        headers: { address_id: this.addressId },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async additional(body) {
    try {
      const response = await api.put(`onboarding/additional/${this.additionalId}`, body, {
        headers: { additional_id: this.additionalId },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async sendCode(body) {
    try {
      const response = await api.post(this.sendCodeURL, body);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async validate(body, type, code) {
    try {
      const response = await api.patch(`${this.validateURL}/${type}/${code}`, body);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async classify() {
    try {
      const response = await api.post(
        'onboarding/classify',
        {},
        {
          headers: { account: this.accountId, user: this.userId },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
}
