import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
gap: 1rem;
align-items: center;
width: 75%;
height: 100%;
`;

export const IconContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 40%;
label {
font-size: 0.75rem;
font-weight: bold;
}
svg {
color: var(--primary);
font-size: 12rem;
margin-bottom: 2rem;
}
`;

export const TextContainer = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;
align-items: center;
width: 100%;
height: 70%;
`;

export const ButtonDisposition = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
gap: 1rem;
align-items: center;
width: 100%;
`;

export const Title = styled.span`
width: 60%;
font-size: 1.5rem;
font-weight: bold;
text-align: center;
text-align-last: center;
margin-bottom: 1rem;
`;

export const Text = styled.span`
width: 70%;
font-size: 1rem;
text-align: center;
`;

export const Span = styled.span`
color: var(--primary);
font-size: 1rem;
font-weight: bold;
`;
