import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FaFilePdf /* , FaArrowLeft */ } from 'react-icons/fa';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Lottie from 'react-lottie';
import { cpf } from 'cpf-cnpj-validator';
import formErrors from '../../services/formErrors';
import getErrors from '../../services/errors';

import animationData from '../../assets/open_account.json';
import { Row, Col } from '../../styles/components';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Loading from '../../components/Loading';
import Notification from '../../components/Notification';
import {
  Content,
  FormContainer,
  IUpload,
  Title,
} from './styles';
import { useAuth } from '../../hooks/auth';

const SendDocuments = () => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const [error, setError] = useState('');
  const { user } = useAuth();
  const [createUserData, setCreateUserData] = useState({});

  const [enablePreview, setEnablePreview] = useState(true);
  const [currentStep, setCurrentStep] = useState('SELFIE');
  const [currentFile, setCurrentFile] = useState(false);
  const [loadingSelfie, setLoadingSelfie] = useState(false);

  const beforeUpload = useCallback((file) => {
    setEnablePreview(true);
    if (file.type === 'application/pdf') {
      setEnablePreview(false);

      if (file.size > 33096064) {
        // message.error('No máximo 3MB por arquivo.');
        return false;
      }
    }

    return true;
  }, []);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = useCallback((info, _type) => {
    if (info.file.status === 'uploading') {
      setLoadingSelfie(true);
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setCurrentFile(imageUrl);
      });

      setLoadingSelfie(false);
    } else if (info.file.status === 'error') {
      setError([
        t('nao foi possivel validar o documento enviado'),
        t('para continuar envie outro documento'),
      ]);

      // ERRORR
      console.log('erro');
      setLoadingSelfie(false);
    }
  }, [t]);

  const formContent = useMemo(() => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    switch (currentStep) {
      case 'SELFIE':
      default:
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 0 }}>
              <h1>{t('envie sua selfie')}</h1>
            </Row>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
              <h2>{t('a foto precisa mostrar seu rosto inteiro e de olhos abertos')}</h2>
            </Row>
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
              <IUpload
                action="https://bank.qesh.ai/users/documents?type=SELFIE"
                headers={{
                  authorization: `Bearer ${localStorage.getItem('@qesh:token')}`,
                }}
                name="file"
                multiple={false}
                accept="image/jpg,image/jpeg,image/png"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(info) => handleChange(info, 'selfie')}
              >
                {currentFile
                  ? (
                    <>
                      {enablePreview ? (
                        <img
                          src={currentFile}
                          alt="Selfie"
                          style={{ width: '100%', marginTop: '60px', marginBottom: '60px' }}
                        />
                      ) : <FaFilePdf size={125} />}
                    </>
                  )
                  : (
                    <div>
                      <div style={{ marginBottom: '5px' }}>
                        {t('clique para fazer upload')}
                      </div>
                      {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                    </div>
                  )}

              </IUpload>
            </div>
            {!!currentFile && (
            <>
              <div style={{
                marginBottom: '40px',
                marginTop: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                <Button
                  type="button"
                  default
                  style={{ marginRight: '50px' }}
                  onClick={() => {
                    setCurrentFile(false);
                    setLoadingSelfie(false);
                  }}
                >
                  <span>{t('excluir')}</span>
                </Button>

                <Button
                  type="submit"
                  onClick={() => {
                    setCurrentFile(false);
                    setLoadingSelfie(false);
                    setCurrentStep('DOC_TYPE');
                  }}
                >
                  <span>{t('proximo')}</span>
                </Button>
              </div>
            </>
            )}
          </>
        );
      case 'DOC_TYPE':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
              <h1>{t('selecione um documento')}</h1>
            </Row>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                <Input
                  select
                  name="document_type"
                  id="document_type"
                  label={t('documento')}
                  options={[
                    { value: 'rg', label: 'Carteira de Identidade' },
                    { value: 'cnh', label: 'Carteira de Motorista' }]}
                />
              </Col>
            </Row>
            <div style={{
              marginBottom: '40px',
              marginTop: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <Button type="submit">
                <span>{t('proximo')}</span>
              </Button>
            </div>
          </>
        );

      case 'FRONT':
        // eslint-disable-next-line no-case-declarations
        const category = createUserData.document_type === 'cnh' ? 'DRIVER_LICENSE_FRONT'
          : 'IDENTITY_CARD_FRONT';
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
              {createUserData.document_type === 'cnh' ? (
                <h1>{t('envie foto da frente da sua cnh')}</h1>
              ) : (
                <h1>{t('envie foto da frente do seu rg')}</h1>
              )}
            </Row>
            <>
              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <div>
                  <IUpload
                    action={`https://bank.qesh.ai/users/documents?type=${category}`}
                    headers={{
                      authorization: `Bearer ${localStorage.getItem('@qesh:token')}`,
                    }}
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleChange(info, 'selfie')}
                  >
                    {currentFile
                      ? (
                        <>
                          {enablePreview ? (
                            <img
                              src={currentFile}
                              alt="Selfie"
                              style={{ width: '100%', marginTop: '60px', marginBottom: '60px' }}
                            />
                          ) : <FaFilePdf size={125} />}
                        </>
                      )
                      : (
                        <div>
                          <div style={{ marginBottom: '5px' }}>
                            {t('clique para fazer upload')}
                          </div>
                          {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                        </div>
                      )}

                  </IUpload>
                </div>

              </div>
            </>
            {!!currentFile && (
            <>
              <div style={{
                marginBottom: '40px',
                marginTop: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

              }}
              >
                <Button
                  type="button"
                  default
                  style={{ marginRight: '50px' }}
                  onClick={() => {
                    setCurrentFile(false);
                    setLoadingSelfie(false);
                  }}
                >
                  <span>{t('excluir')}</span>
                </Button>

                <Button
                  type="submit"
                  onClick={() => {
                    setCurrentFile(false);
                    setLoadingSelfie(false);
                    setCurrentStep('VERSE');
                  }}
                >
                  <span>{t('proximo')}</span>
                </Button>
              </div>
            </>
            )}
          </>
        );

      case 'VERSE':
        // eslint-disable-next-line no-case-declarations
        const categoryVerse = createUserData.document_type === 'cnh' ? 'DRIVER_LICENSE_VERSE'
          : 'IDENTITY_CARD_VERSE';
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
              {createUserData.document_type === 'cnh' ? (
                <h1>{t('envie foto do verso da sua cnh')}</h1>
              ) : (
                <h1>{t('envie foto do verso do seu rg')}</h1>
              )}
            </Row>
            <>
              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <div>
                  <IUpload
                    action={`https://bank.qesh.ai/users/documents?type=${categoryVerse}`}
                    headers={{
                      authorization: `Bearer ${localStorage.getItem('@qesh:token')}`,
                    }}
                    name="file"
                    accept="image/jpg,image/jpeg,image/png"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleChange(info, 'selfie')}
                  >
                    {currentFile
                      ? (
                        <>
                          {enablePreview ? (
                            <img
                              src={currentFile}
                              alt="Selfie"
                              style={{ width: '100%', marginTop: '60px', marginBottom: '60px' }}
                            />
                          ) : <FaFilePdf size={125} />}
                        </>
                      )
                      : (
                        <div>
                          <div style={{ marginBottom: '5px' }}>
                            {t('clique para fazer upload')}
                          </div>
                          {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                        </div>
                      )}

                  </IUpload>
                </div>

              </div>
            </>
            {!!currentFile && (
              <>
                <div style={{
                  marginBottom: '40px',
                  marginTop: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',

                }}
                >
                  <Button
                    type="button"
                    default
                    style={{ marginRight: '50px' }}
                    onClick={() => {
                      setCurrentFile(false);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>{t('excluir')}</span>
                  </Button>

                  <Button
                    type="submit"
                    onClick={() => {
                      setCurrentFile(false);
                      setLoadingSelfie(false);
                      if (cpf.isValid(user.document)) {
                        setCurrentStep('SUCCESS');
                      } else {
                        setCurrentStep('COMPANY');
                      }
                    }}
                  >
                    <span>{t('proximo')}</span>
                  </Button>
                </div>
              </>
            )}
          </>
        );

      case 'COMPANY':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 0 }}>
              <h1 style={{ textAlign: 'center' }}>{t('envie o documento principal de identificacao...')}
              </h1>
            </Row>
            <>
              <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              >
                <div>
                  <IUpload
                    action="https://bank.qesh.ai/users/documents?type=COMPANY"
                    headers={{
                      authorization: `Bearer ${localStorage.getItem('@qesh:token')}`,
                    }}
                    name="file"
                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleChange(info, 'selfie')}
                  >
                    {currentFile
                      ? (
                        <>
                          {enablePreview ? (
                            <img
                              src={currentFile}
                              alt="Documento"
                              style={{ width: '100%', marginTop: '60px', marginBottom: '60px' }}
                            />
                          ) : <FaFilePdf size={125} />}
                        </>
                      )
                      : (
                        <div>
                          <div style={{ marginBottom: '5px' }}>
                            {t('clique para fazer upload')}
                          </div>
                          {loadingSelfie ? <LoadingOutlined /> : <PlusOutlined />}
                        </div>
                      )}

                  </IUpload>
                </div>

              </div>
            </>
            {!!currentFile && (
              <>
                <div style={{
                  marginBottom: '40px',
                  marginTop: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',

                }}
                >
                  <Button
                    type="button"
                    default
                    style={{ marginRight: '50px' }}
                    onClick={() => {
                      setCurrentFile(false);
                      setLoadingSelfie(false);
                    }}
                  >
                    <span>{t('excluir')}</span>
                  </Button>

                  <Button
                    type="submit"
                    onClick={() => {
                      setCurrentFile(false);
                      setLoadingSelfie(false);
                      setCurrentStep('SUCCESS');
                    }}
                  >
                    <span>{t('finalizar')}</span>
                  </Button>
                </div>
              </>
            )}
          </>
        );

      case 'SUCCESS':
        return (
          <>
            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <h1>{t('parabens, os arquivos foram salvos com sucesso')}</h1>
            </Row>
            {/* <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20, textAlign: 'center' }}>
              <h2>use apenas números</h2>
            </Row> */}

            <Row gutter={16} style={{ justifyContent: 'center' }}>
              <Lottie options={defaultOptions} height={314} width={348} />
            </Row>
            <Row style={{ marginBottom: '40px', marginTop: 40 }}>
              <Button
                type="button"
                style={{ margin: 'auto' }}
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                <span>Ok</span>
              </Button>
            </Row>
          </>
        );
    }
  },
  [
    currentStep,
    beforeUpload,
    currentFile,
    enablePreview,
    loadingSelfie,
    createUserData.document_type,
    handleChange,
    user,
    t,
  ]);

  const handleSubmit = useCallback(async (data) => {
    formRef.current.setErrors({});
    try {
      const schema = Yup.object().shape({
        document_type: Yup.string().required(t('tipo de documento e obrigatorio')),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setCreateUserData({ ...createUserData, ...data });
      setCurrentStep('FRONT');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        formRef.current.setErrors(errors);
        return;
      }

      setError(getErrors(err));
    }
  }, [createUserData, t]);

  return (
    <Content>
      <FormContainer>
        <Notification
          type="error"
          title={t('erro ao enviar documentos')}
          visible={!!error}
          hidden={() => setError(false)}
        >
          {Array.isArray(error) && error.length > 0 ? (
            <>
              {error.map((e) => (
                <div key={e}>
                  {e}
                </div>
              ))}
            </>
          ) : (
            <>
              {error}
            </>
          )}
        </Notification>
        <Loading />
        <Title>{t('atualizacao de documentos')}</Title>
        <Form ref={formRef} onSubmit={handleSubmit}>
          {formContent}
        </Form>
      </FormContainer>
      {/* <Stepper numPag={numPag} /> */}

    </Content>
  );
};

export default SendDocuments;
