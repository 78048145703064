/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'antd';

import { Container, Header, Content } from './styles';

import Button from '../Button';

import { useSettings } from '../../hooks/settings';

function Password({
  visible, hidden, afterPassword, customDescription,
}) {
  const { settings } = useSettings();
  const formRef = useRef(null);
  const password1 = useRef(null);
  const password2 = useRef(null);
  const password3 = useRef(null);
  const password4 = useRef(null);

  const [password1D, setPassword1D] = useState('');
  const [password2D, setPassword2D] = useState('');
  const [password3D, setPassword3D] = useState('');
  const [password4D, setPassword4D] = useState('');

  function handleForm(e) {
    e.preventDefault();
    hidden();
    afterPassword(`${password1D}${password2D}${password3D}${password4D}`);
    setPassword1D('');
    setPassword2D('');
    setPassword3D('');
    setPassword4D('');
  }

  useEffect(() => () => {
    setPassword1D('');
    setPassword2D('');
    setPassword3D('');
    setPassword4D('');
  }, []);

  return settings && settings.name ? (
    <Modal
      wrapClassName="modal-password"
      centered
      visible={visible}
      onOk={hidden}
      closable={false}
      onCancel={hidden}
      footer={false}
      destroyOnClose
      style={{ padding: 0, margin: 0, borderRadius: '8px' }}
      bodyStyle={{ padding: 0 }}
    >
      <Container>
        <Header backgroundcolor={settings.colors.primary}>
          DIGITE A SENHA DO SEU CARTÃO
        </Header>
        <Content>
          {customDescription ? <h1>{customDescription}</h1>
            : <h1>Para confirmar a transação digite sua senha de 4 dígitos utilizada no cartão.</h1>}
          <form ref={formRef} onSubmit={handleForm}>
            <div gutter={16}>
              <input
                type="password"
                placeholder="0"
                ref={password1}
                value={password1D}
                onChange={(e) => {
                  setPassword1D(e.target.value);
                  password2.current.focus();
                }}
                maxLength={1}
              />
              <input
                type="password"
                placeholder="0"
                ref={password2}
                value={password2D}
                onKeyUp={(e) => {
                  if (e.keyCode === 8 || e.keyCode === 46) {
                    setPassword1D('');
                    setPassword2D('');
                    password1.current.focus();
                  }
                }}
                onChange={(e) => {
                  setPassword2D(e.target.value);
                  password3.current.focus();
                }}
                maxLength={1}
              />
              <input
                type="password"
                ref={password3}
                value={password3D}
                placeholder="0"
                onKeyUp={(e) => {
                  if (e.keyCode === 8 || e.keyCode === 46) {
                    setPassword2D('');
                    setPassword3D('');
                    password2.current.focus();
                  }
                }}
                onChange={(e) => {
                  setPassword3D(e.target.value);
                  password4.current.focus();
                }}
                maxLength={1}
              />
              <input
                type="password"
                ref={password4}
                value={password4D}
                placeholder="0"
                onKeyUp={(e) => {
                  if (e.keyCode === 8 || e.keyCode === 46) {
                    setPassword3D('');
                    setPassword4D('');
                    password3.current.focus();
                  }
                }}
                onChange={(e) => {
                  setPassword4D(e.target.value);
                }}
                maxLength={1}
              />
            </div>

            <div style={{ display: 'flex', flex: 1 }}>
              <Button type="submit" style={{ marginTop: '30px' }}>
                <span>Concluir</span>
              </Button>
            </div>
          </form>
        </Content>
      </Container>
    </Modal>
  ) : (<></>);
}

Password.propTypes = {
  visible: PropTypes.bool.isRequired,
  afterPassword: PropTypes.func.isRequired,
  hidden: PropTypes.func.isRequired,
  customDescription: PropTypes.string,
};

Password.defaultProps = {
  customDescription: null,
};

export default Password;
