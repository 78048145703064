import styled from 'styled-components';

export const LowerMessageContainerStye = styled.h1`
 position: absolute;
 bottom: 10%;
 left: 50px;
 color: var(--primary);
 line-height: 2.5rem;
 font-size: 2.5rem;
 @media screen and (max-width: 48rem) {
 display: none;
 }
`;
