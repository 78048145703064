export const educationLevel = [
  { id: '01', name: 'Fundamental - Incompleto' },
  { id: '02', name: 'Fundamental - Completo' },
  { id: '03', name: 'Médio - Incompleto' },
  { id: '04', name: 'Médio - Completo' },
  { id: '05', name: 'Superior - Incompleto' },
  { id: '06', name: 'Superior - Completo' },
  { id: '07', name: 'Pós-graduação (Lato senso) - Incompleto' },
  { id: '08', name: 'Pós-graduação (Lato senso) - Completo' },
  { id: '09', name: 'Pós-graduação (Stricto sensu, nível mestrado) - Incompleto' },
  { id: '10', name: 'Pós-graduação (Stricto sensu, nível mestrado) - Completo' },
  { id: '11', name: 'Pós-graduação (Stricto sensu, nível doutor) - Incompleto' },
  { id: '12', name: 'Pós-graduação (Stricto sensu, nível doutor) - Completo' },
];
