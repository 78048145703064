import React from 'react';
import { Image, ImageDisplay, Wrapper } from './styles';

const ImagePlaceholder = ({
  size,
  hasDisplaySize = true,
  src,
  handleClick,
}) => (
  <Wrapper onClick={handleClick}>
    {src ? <ImageDisplay size={size} src={src} /> : <Image size={size} />}
    {hasDisplaySize && (
    <span>
      {`${size[0]}px`} x {`${size[1]}px`}
    </span>
    )}
  </Wrapper>
);

export default ImagePlaceholder;
