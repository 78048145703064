import api from './api';

/**
 * @description Create the four digits password
 * @param {string} password transaction password
 * @returns {Promise<object | boolean>}
 */

export const Password4DigitsService = async (token, account, password) => {
  try {
    const response = await api.post('/accounts/validate/password', { password });
    return response.data;
  } catch (error) {
    console.log('Password4DigitsService', error.response.data);
    return false;
  }
};
