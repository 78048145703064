import React from 'react';
import { useTranslation } from 'react-i18next';
import { LowerMessageContainerStye } from './styles';

const LowerLeftMessage = () => {
  const { t } = useTranslation();

  return (
    <LowerMessageContainerStye>
      <p>
        {t('Ser digital')} <br /> {t('muda tudo!')}
      </p>
    </LowerMessageContainerStye>
  );
};

export default LowerLeftMessage;
