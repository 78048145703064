/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Button as ButtonATD } from 'antd';
import { Container } from './styles';
import { useSettings } from '../../hooks/settings';

const ButtonN = ({
  block,
  children,
  danger,
  disabled,
  ghost,
  href,
  htmlType,
  icon,
  loading,
  shape,
  size,
  target,
  type,
  onClick,
  style,
  ...rest
}) => {
  const { settings } = useSettings();
  return (
    <Container {...rest} settings={settings}>
      <ButtonATD
        block={block}
        danger={danger}
        disabled={disabled}
        ghost={ghost}
        href={href}
        htmlType={htmlType}
        icon={icon}
        loading={loading}
        shape={shape}
        size={size}
        target={target}
        type={type}
        onClick={onClick}
        style={style}
      >
        {children}
      </ButtonATD>
    </Container>
  );
};

export const DefaultPropTypes = {
  block: undefined,
  children: undefined,
  danger: undefined,
  disabled: undefined,
  ghost: undefined,
  href: undefined,
  htmlType: undefined,
  icon: undefined,
  loading: undefined,
  shape: undefined,
  size: undefined,
  target: undefined,
  type: undefined,
  onClick: undefined,
};

ButtonN.defaultProps = DefaultPropTypes;

export default ButtonN;
