export const GlobalRoutes = [
  '/invite',
  '/support',
  '/account-selection',
  '/terms',

  // Onboarding
  '/register',
  '/register/',
  '/register/basicDataPj',
  '/register/partners',
  '/register/dataPj',
  '/register/onboarding',
  '/register/basicData',
  '/register/cellphone',
  '/register/cellphoneconfirmation',
  '/register/email',
  '/register/emailconfirmation',
  '/register/password',
  '/register/address',
  '/register/edducation',
  '/register/selfieOption',
  '/register/proofoflife',
  '/register/socialContract',
  '/register/mainPartnerQualification',
  '/register/documentation',
  '/register/operationalpassword',
  '/register/accountstatus',
  '/register/socialContract',
  '/register/partners',
  '/register/partnerRegisterPage',
];
