/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useCallback, useRef } from 'react';
import { Popover, Divider, Input } from 'antd';
import { FaSortDown } from 'react-icons/fa';
import {
  ArticleContainer, AccountOwner, CommonText, SelectionContainer,
} from './styles';

// feito pelo rafael almeida
// previous vai chegar via props e aí eu carrego o componente.
const PopOverPrevious = (props) => {
  // const [previous] = useState(props.data);
  const [search, setSearch] = useState(null);
  const popover = useRef();
  const [selected, setSelected] = useState(true);

  const { Search } = Input; // fazer função de filter com vários parâmetros (usando ||) e rerenderizar componente
  const onSearch = useCallback((value) => {
    if (!value || value.length === 0) {
      setTimeout(() => setSearch(null), 1000);
      return;
    }
    const busca = props.data.filter((item) => item.charged_name.toLowerCase().includes(value.toLowerCase())
    || item.charged_document.toLowerCase().includes(value.toLowerCase())
    || item.charged_email.toLowerCase().includes(value.toLowerCase())
    || item.charged_phone.toLowerCase().includes(value.toLowerCase()));
    setSearch(busca);
  }, [props.data]);

  const searchComponent = (
    <Search
      placeholder="Pesquisar cobranças anteriores"
      onChange={(event) => onSearch(event.target.value)}
      style={{ width: '100%' }}
    />
  );
  const content = useCallback(() => (
    <>
      <ArticleContainer>
        {search ? (search.length ? (search.map((item) => (
          <SelectionContainer
            key={item.charged_document}
            onClick={() => {
              props.handleSelection(item);
              setSelected(true);
            }}
          >
            <AccountOwner>{item.charged_name}</AccountOwner>
            <CommonText>{item.charged_document}</CommonText>
            <Divider />
          </SelectionContainer>
        ))) : <p>Não foi encontrado nenhum resultado</p>) : (props.data.map((item) => (
          <SelectionContainer
            key={item.charged_document}
            onClick={() => {
              props.handleSelection(item);
              setSelected(true);
            }}
          >
            <AccountOwner>{item.charged_name}</AccountOwner>
            <CommonText>{item.charged_document}</CommonText>
            <Divider />
          </SelectionContainer>
        )))}
      </ArticleContainer>
    </>
  ), [props, search]);

  return (
    <>
      <div style={{
        display: 'flex', justifyContent: 'start',
      }}
      >
        <Popover
          visible={!selected}
          onVisibleChange={(e) => setSelected(!e)}
          ref={popover}
          placement="rightTop"
          title={searchComponent}
          content={content()}
          trigger="click"
        >
          <p style={{
            cursor: 'pointer',
            color: props.primaryColor,
            textDecoration: 'underline',
            marginLeft: 10,
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          >Ver anteriores
            <FaSortDown size={15} />
          </p>
        </Popover>
      </div>
    </>
  );
};

export default PopOverPrevious;
