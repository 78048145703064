import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';
import { useSettings } from '../../hooks/settings';

function ModernButton(props) {
  const { settings } = useSettings();
  if (settings && settings.colors) {
    return (
      <Container
        textColor={props.colored ? props.textColor : props.color}
        backgroundColor={props.color}
        colored={props.colored}
        {...props}
      >
        {props.children}
      </Container>
    );
  }
  return (
    <Container backgroundcolor="white" {...props}>
      {props.children}
    </Container>
  );
}

ModernButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  color: PropTypes.string,
  right: PropTypes.bool,
  center: PropTypes.bool,
  default: PropTypes.bool,
  colored: PropTypes.bool,
  textColor: PropTypes.string,
};

ModernButton.defaultProps = {
  right: false,
  center: false,
  default: false,
  colored: true,
  color: 'var(--primary)',
  textColor: 'var(--btn-text)',
};

export default ModernButton;
