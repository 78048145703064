import styled from 'styled-components';

export const Body = styled.div`
padding: 10px;
width: 100%;
height: 100%;
display: flex;
justify-content: center
`;

export const Main = styled.div`
width: 190mm;
padding: 3mm 10mm;
`;

export const DateText = styled.div`
  font-size: 10px;
  margin-top: 5px;
  text-align: center;
  font-family: 'Helvetica';
  color: #6F6F6F;
`;

export const DateTitle = styled.div`
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  font-weight: bold;
  font-family: 'Helvetica';
  color: #6F6F6F;
`;

export const Image = styled.img`
  margin-vertical: 10px;
  width: 100px;
  height: 40px;
`;

export const AmountPositive = styled.span`
  color: #24A148;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Helvetica';
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const AmountNegative = styled.span`
  color: #DA1E28;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Helvetica';
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Description = styled.div`
  margin-top: 15px;
  padding-horizontal: 5px;
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.span`
  font-size: 12px;
  font-family: 'Helvetica';
  color: #6F6F6F;
  flex-wrap: wrap;
`;

export const Span = styled.span`
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: semibold;
  font-family: 'Helvetica';
  color: #393939;
`;

export const Margin = styled.div`
  margin-vertical: 24px;
  width: 100%;
  background-color: #F4F4F4;
  height: 1px;
`;

export const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 18.2mm;
`;

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: bold;
  font-family: 'Helvetica';
  color: #393939;
  width: 150px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const SectionTitleLabel = styled.span`
  font-size: 12px;
  margin-left: 10px;
  font-family: 'Helvetica';
  color: #6F6F6F;
  flex-wrap: wrap;
`;

export const HeaderSpan = styled.span`
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: semibold;
  font-family: 'Helvetica';
  color: #393939;
`;

export const SectionSpan = styled.span`
  font-size: 12px;
  font-weight: semibold;
  font-family: 'Helvetica';
  color: #393939;
  width: 150px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: silver;
  margin: 5px 0 10px 0;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  font-family: 'Helvetica';
  color: #000;
`;

export const RightText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const LeftText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PageHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Button = styled.div`
  cursor: pointer;
  width: fit-content;
  background-color: gray;
  padding: 2px 5px;
  border-radius: 5px;
  :hover {
    color: white;
  }
`;

export const ContentTab = styled.div`
 width: 210mm;
 padding: 10px;
`;
