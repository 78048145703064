import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Tabs,
} from 'antd';

import { Redirect } from 'react-router-dom';
import Extract from './extract';
import { useSettings } from '../../hooks/settings';

import {
  Content,
} from './styles';
import { useAuth } from '../../hooks/auth';
import Scheduled from './Scheduled';

const { TabPane } = Tabs;

function Main() {
  const handleTab = useCallback((_key) => {
  }, []);
  const { settings } = useSettings();
  const { account } = useAuth();
  const { t } = useTranslation();

  if (!account) {
    return <Redirect to="/account-selection" />;
  }

  return settings && settings.name ? (
    <>
      <Content color={settings.colors.primary}>
        <Tabs onChange={handleTab} type="card">
          <TabPane tab={t('extrato')} key="1">
            <Extract />
          </TabPane>
          <TabPane tab={t('agendamentos')} key="2">
            <Scheduled />
          </TabPane>
          {/* <TabPane tab="PIX" key="2">
            PIX
          </TabPane>
          <TabPane tab="TED" key="3">
            TED
          </TabPane>
          <TabPane tab="PAGAMENTOS" key="4">
            PAGAMENTOS
          </TabPane>
          <TabPane tab="RECEBIMENTOS" key="5">
            RECEBIMENTOS
          </TabPane> */}
        </Tabs>
      </Content>

    </>

  ) : (<></>);
}

export default Main;
