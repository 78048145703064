import styled from 'styled-components';
import Select from 'react-select';

export const Content = styled.div`
  width: 100%;
  margin: 5 auto 5 auto;
  padding-bottom: 250px;

  div.divider{
    width: 100%;
    display: flex;
    align-items: center;
    
    span{
      font-size: 16px;
      font-weight: bold;
      color: #767676;
    }

    div {
      margin-left: 8px;
      flex: 1;
      height: 2px;
      width: 100%;
      background: #dbdcde;
    }
  }

  h2 {
    color: #121212;
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  h3 {
    color: #121212;
    font-size: 17px;
    margin-top: 35px;
    margin-bottom: 15px;
    font-weight: 600;
  }


`;

export const FilterContainer = styled.div`
  display: flex;
`;

export const TabsContainer = styled.div`
  border-right: 1px solid #dbdcde;
  padding-right: 6px; 
  margin-right: 12px;
  height: 40px;
  display: flex;

  div {
    & + div {
      margin-left: 16px;
    }
  }
`;

export const Tab = styled.div`
  cursor: pointer;
  padding-right: 6px;
  border-bottom: ${(props) => (props.selected ? '2px solid #02f400' : '0px')};
  color: ${(props) => (props.selected ? '#02f400' : '#767676')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  transition: 400ms ease-in-out;

  svg {
    margin-right: 4px;
  }

  :hover {
    border-bottom: ${(props) => (props.selected ? '2px solid #02f400' : '2px solid  #dbdcde')};
  }
`;

export const FilterDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  padding-left: 16px;
  padding-top: 4px;
  padding-right: 16px;
  padding-bottom: 4px;
  flex-wrap: wrap;

  border-radius: 8px;

  span {
    font-weight: bold;
    margin-bottom: 2px;
  }

  & + div {
    margin-left: 32px;
  }

  .DayPickerContainer {
    display: flex;

    section {
      display: flex;
      align-items: center; 
    }

    svg {
      color: #46cdd4;
      margin-right: 5px;
      cursor: pointer;
    }

    span {
      font-weight: 600;
      padding: 0px 5px;
    }

    .DayPickerInput {
      input {
        border: 0;
        width: 82px;
        cursor: pointer;
      }
    }
  }
`;

export const ReactSelect = styled(Select)`
  width: 220px;
  margin-left: 8px;
`;
