export const shopErrors = {
  "password should not be empty": "Senha não pode ser vazia",
  "name should not be empty": "Nome não pode ser vazio",
  "email should not be empty": "Email não pode ser vazio",
  "phone must be a valid phone number": "Telefone deve ser um número válido",
  "phone should not be empty": "Telefone não pode ser vazio",
  "start_store_operation should not be empty": "Horário de abertura não pode ser vazio",
  "end_store_operation should not be empty": "Horário de fechamento não pode ser vazio",
  "primary_color should not be empty": "Cor primária não pode ser vazia",
  "primary_color must be a hexadecimal color": "Cor primária deve ser uma cor hexadecimal",
  "secondary_color should not be empty": "Cor secundária não pode ser vazia",
  "secondary_color must be a hexadecimal color": "Cor secundária deve ser uma cor hexadecimal",
  "img_mobile must be an URL address": "Imagem mobile deve ser um endereço de URL",
  "img_mobile should not be empty": "Imagem mobile não pode ser vazia",
  "img_web must be an URL address": "Imagem web deve ser um endereço de URL",
  "img_web should not be empty": "Imagem web não pode ser vazia",
  "address.neighborhood should not be empty": "Bairro não pode ser vazio",
  "address.city should not be empty": "Cidade não pode ser vazia",
  "address.state should not be empty": "Estado não pode ser vazio",
  "address.zip_code should not be empty": "CEP não pode ser vazio",
  "error: customer id already registered": "Erro: ID do cliente já registrado",
  "error: email or url are unavailable": "Erro: Email ou URL indisponível",
}

