import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #919294;
  border-radius: 20px;
  color: #fff;
  padding: 10px;
  font-weight: 600;

  padding-top: 0 10px;
  width: 169px;

  margin: 0 15px;

  transition: width 0.5s;

  ${(props) => !props.active && css`
    width: 100px;
  `}


  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 42px;

  svg {
    cursor: pointer;
  }
`;
