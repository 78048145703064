import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Cards from 'react-credit-cards';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { FiArrowLeft } from 'react-icons/fi';
import * as Yup from 'yup';
import { notification, Tooltip } from 'antd';
import { Form } from '@unform/web';
import {
  format, parseISO, isBefore, isPast,
} from 'date-fns';
import lodash from 'lodash';
import formErrors from '../../../../../../services/formErrors';
import chargesApi from '../../../../../../services/charges';
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import 'react-credit-cards/es/styles-compiled.css';
import { HandleFormatMoney, getBrandCard } from '../../../../../../services/utils';
import { Row, Col } from '../../../../../../styles/components';
import { useAuth } from '../../../../../../hooks/auth';

import {
  Content, TitleContainer, InfosContainer, GridContainer,
} from './styles';

const Payment = ({ payment, backToEducationalPayments }) => {
  const { account, user } = useAuth();

  const [cvc, setCvc] = useState('');
  const [monthState, setMonthState] = useState('');
  const [yearState, setYearState] = useState('');
  const [focus, setFocus] = useState('');
  const [holderNameState, setHolderNameState] = useState('');
  const [cardNumberState, setCardNumberState] = useState('');
  const [plotsOptions, setPlotsOptions] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [infoBoxLoading, setInfoBoxLoading] = useState(false);
  const [selectedPlots, setSelectedPlots] = useState();
  const [values, setValues] = useState(false);
  const { token } = useAuth();

  const formRef = useRef(null);
  const cardRef = useRef(null);

  useEffect(() => {
    chargesApi.get(`nmpay/partners/${payment.partner_document}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const numberOfOptions = lodash.range(1, response.data.max_installments + 1);

      const formattedPlotsOptions = numberOfOptions.map((n) => ({
        value: n,
        label: n < 10 ? `0${n}` : n.toString(),
      }));

      setPlotsOptions(formattedPlotsOptions);
    });
  }, [payment, token]);

  useEffect(() => {
    setInfoBoxLoading(true);
    setButtonLoading(true);

    chargesApi.get(`nmpay/detached-charges/validate/${payment.boletoPagamento.numeroLinhaDigitavel}`, {
      headers: {
        authorization: `Bearer ${token}`,
        user: user.id,
        account: account.id,
      },
      params: {
        installments: selectedPlots?.value || 1,
      },
    }).then((response) => {
      setValues(response.data);

      setInfoBoxLoading(false);
      setButtonLoading(false);
    });
  }, [account.id, user.id, selectedPlots, payment.boletoPagamento.numeroLinhaDigitavel, token]);

  const handleSubmit = useCallback(async (data) => {
    setButtonLoading(true);
    formRef.current.setErrors({});

    try {
      const schema = Yup.object().shape({
        cardNumber: Yup.string().required('Digite o número do cartão')
          .length(16, 'O número do cartão deve ter 16 dígitos'),
        holderName: Yup.string().required('Digite o nome'),
        cpfCardOwner: Yup.string().required('Digite o cpf'),
        month: Yup.number('Digite o mês de vencimento').required('Digite o mês de vencimento'),
        year: Yup.number('Digite o ano de vencimento').required('Digite o ano de vencimento'),
        cvv: Yup.string().required('Digite o código de segurança'),
        plots: Yup.number('Digite a quantidade de parcelas').required('Digite a quantidade de parcelas'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const {
        cardNumber, holderName, cpfCardOwner, month, year, cvv, plots,
      } = data;

      const cardBrand = getBrandCard(cardNumber);

      if (!cardBrand) {
        formRef.current.setErrors({ cardNumber: 'Cartão inválido' });

        return;
      }

      const formData = {
        bar_code: payment?.boletoPagamento?.numeroLinhaDigitavel,
        card_owner_document: cpfCardOwner,
        installments: plots,
        holder_name: holderName,
        card_number: cardNumber,
        card_expiration: month < 10 ? `0${month}/${year}` : `${month}/${year}`,
        card_brand: cardBrand,
        cvv,
      };

      if (isPast(new Date(data.year, data.month, 1))) {
        formRef.current.setErrors({ year: 'Cartão vencido' });
        setButtonLoading(false);
        return;
      }

      await chargesApi.post('nmpay/detached-charges/pay', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setButtonLoading(false);
      notification.success({ message: 'Cobrança paga com sucesso.' });
      backToEducationalPayments();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        formRef.current.setErrors(errors);
        setButtonLoading(false);
        return;
      }

      notification.error({
        message: err.response?.data?.message || 'Ocorreu um erro, tente novamente mais tarde.',
      });
      setButtonLoading(false);
    }
  }, [
    backToEducationalPayments,
    payment?.boletoPagamento?.numeroLinhaDigitavel,
    token,
  ]);

  return (
    <Content>
      <TitleContainer>
        <Tooltip title="voltar">
          <FiArrowLeft size={24} onClick={(backToEducationalPayments)} />
        </Tooltip>
        <h1>Pagamento por cartão</h1>
      </TitleContainer>

      <GridContainer>

        <InfosContainer>
          <Cards
            cvc={cvc}
            expiry={`${monthState}/${yearState}`}
            focused={focus}
            name={holderNameState}
            number={cardNumberState}
            ref={cardRef}
          />

          <div>
            {infoBoxLoading
              ? (
                <LoadingOutlined style={{ marginTop: '26px' }} />
              ) : (
                <>
                  <h1>Informações da cobrança:</h1>
                  { payment.boletoPagamento.sacadorAvalista && (
                  <p>
                    <strong>Beneficiário: </strong>
                    {payment.boletoPagamento.sacadorAvalista.nome}
                  </p>
                  )}
                  <p>
                    <strong>Aluno/Responsável: </strong>
                    {payment.boletoPagamento.pessoaPagador.nome}
                  </p>
                  <p><strong>Valor:</strong> {HandleFormatMoney(Number(values?.amount))}</p>
                  <p>
                    <strong>Parcelas:</strong>
                    {` ${values?.installments_number} parcelas de 
                    ${HandleFormatMoney(Number(values?.installment_value))}` }
                  </p>
                  <p><strong>Vencimento:</strong>
                    {format(parseISO(payment.boletoPagamento.dataVencimentoTitulo), 'dd/MM/yyyy')}
                  </p>
                </>
              )}
          </div>
        </InfosContainer>

        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ amount: 1 }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="bottom">
              <Input
                id="card_number"
                name="cardNumber"
                onFocus={() => setFocus('number')}
                label="Número do cartão"
                maxLength={16}
                type="text"
                onChange={(e) => setCardNumberState(e.target.value)}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input
                id="card_owner_name"
                onFocus={() => setFocus('name')}
                name="holderName"
                label="Nome escrito no cartão"
                type="text"
                onChange={(e) => setHolderNameState(e.target.value)}
              />
            </Col>
          </Row>

          <Row gutter={16}>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Input
                id="cpf_card_owner"
                name="cpfCardOwner"
                label="CPF do dono do cartão"
                type="text"
                maxLength={11}
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
              <Input
                id="cvv"
                name="cvv"
                maxLength="3"
                label="CVV"
                type="text"
                onFocus={() => setFocus('cvc')}
                onChange={(e) => {
                  setCvc(e.target.value);
                }}
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
              <Input
                select
                id="month"
                name="month"
                label="Mês de vencimento"
                onChange={(e) => setMonthState(e.label)}
                options={[
                  { label: '01', value: 1 },
                  { label: '02', value: 2 },
                  { label: '03', value: 3 },
                  { label: '04', value: 4 },
                  { label: '05', value: 5 },
                  { label: '06', value: 6 },
                  { label: '07', value: 7 },
                  { label: '08', value: 8 },
                  { label: '09', value: 9 },
                  { label: '10', value: 10 },
                  { label: '11', value: 11 },
                  { label: '12', value: 12 },
                ]}
                type="text"
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
              <Input
                select
                id="year"
                name="year"
                label="Ano de vencimento"
                onChange={(e) => setYearState(e.value)}
                options={[
                  { label: '21', value: 2021 },
                  { label: '22', value: 2022 },
                  { label: '23', value: 2023 },
                  { label: '24', value: 2024 },
                  { label: '25', value: 2025 },
                  { label: '26', value: 2026 },
                  { label: '27', value: 2027 },
                  { label: '28', value: 2028 },
                  { label: '29', value: 2029 },
                  { label: '30', value: 2030 },
                  { label: '31', value: 2031 },
                  { label: '32', value: 2032 },
                ]}
                type="text"
              />
            </Col>

          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Input
                select
                id="plots"
                name="plots"
                label="Parcelas"
                defaultValue={plotsOptions[0]}
                value={selectedPlots}
                options={plotsOptions}
                onChange={(option) => { setSelectedPlots(option); }}
                type="text"
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={24} />
          </Row>

          <div style={{
            width: '100%', marginTop: '16px', display: 'flex', justifyContent: 'flex-end',
          }}
          >
            <Button disabled={!!buttonLoading} type="submit">
              {buttonLoading ? <span><LoadingOutlined /></span> : <span>Pagar</span> }
            </Button>
          </div>
        </Form>
      </GridContainer>

    </Content>
  );
};

Payment.propTypes = {
  backToEducationalPayments: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    installments_number: PropTypes.string.isRequired,
    installment_value: PropTypes.number.isRequired,
    dataLimitePagamentoTitulo: PropTypes.string.isRequired,
    partner_id: PropTypes.string.isRequired,
    partner_document: PropTypes.string.isRequired,
    boletoPagamento: PropTypes.shape({
      pessoaPagador: PropTypes.shape({
        nome: PropTypes.string,
        tipoDocumento: PropTypes.string,
        numeroDocumento: PropTypes.string,
      }).isRequired,
      pessoaBeneficiarioOriginal: PropTypes.shape({
        nome: PropTypes.string,
        tipoDocumento: PropTypes.string,
        numeroDocumento: PropTypes.string,
      }).isRequired,
      sacadorAvalista: PropTypes.shape({
        nome: PropTypes.string,
        tipoDocumento: PropTypes.string,
        numeroDocumento: PropTypes.string,
      }),
      valorTitulo: PropTypes.number.isRequired,
      numeroLinhaDigitavel: PropTypes.string.isRequired,
      dataVencimentoTitulo: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

Payment.defaultProps = {

};

export default Payment;
