import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import { Container, RemainigCharactersContainer } from './styles';

const Textarea = ({
  name, rows, cols, id, label, maxLength, showRemainingChar, ...rest
}) => {
  const textareaRef = useRef(null);

  const [charactersRemaining, setCharactersRemaining] = useState(maxLength);

  const {
    fieldName, error, registerField,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInput = useCallback((e) => {
    if (e.target.scrollHeight > e.target.offsetHeight) {
      e.target.rows += 1;
    }

    const typedCharacters = e.target.value.split('').length;

    setCharactersRemaining(maxLength - typedCharacters);
  }, [maxLength]);

  return (
    <Container error={error}>
      <label htmlFor={id}>{label}</label>

      <textarea
        onInput={handleInput}
        ref={textareaRef}
        id={id}
        name={name}
        cols={cols}
        rows={rows}
        maxLength={maxLength}
        {...rest}
      />
      {showRemainingChar
      && (
      <RemainigCharactersContainer>
        <span style={{ color: '#FA4E39' }}>
          {error}
        </span>
        <span>{`Caracteres restantes: ${charactersRemaining}`}</span>
      </RemainigCharactersContainer>
      )}
    </Container>
  );
};
export default Textarea;

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  cols: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  showRemainingChar: PropTypes.bool,
};

Textarea.defaultProps = {
  showRemainingChar: false,
};
