import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useSettings } from '../../hooks/settings';
import {
  Content,
} from './styles';

import Button from '../../components/Button';

function Transfer() {
  const { settings } = useSettings();

  const { t } = useTranslation();

  if (settings && settings.name) {
    return (
      <>
        <h1>{t('nova transferencia')}</h1>
        <Content>
          <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            <Link to="/transfer/tef">
              <Button style={{ margin: 20, marginLeft: 0 }}>
                Entre contas {settings.display_name}
              </Button>
            </Link>
            <Link to="/transfer/ted">
              <Button style={{ margin: 20, marginLeft: 0 }}>
                Outros Bancos / TED
              </Button>
            </Link>
          </div>
        </Content>
      </>
    );
  }
  return <></>;
}

export default Transfer;
