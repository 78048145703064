/* eslint-disable react/prop-types */
import React from 'react';
import { BsPaperclip as Clip } from 'react-icons/bs';
import { FileInput, Wrapper } from './styles';

const FileUpload = ({
  fileName, handleChange, accept = 'image/*', isDisabled = false,
}) => {
  const fileInputRef = React.useRef(null);

  return (
    <Wrapper>
      <FileInput
        onClick={isDisabled ? () => {} : () => fileInputRef.current?.click()}
        isDisabled={isDisabled}
      >
        <Clip color="white" size={17} style={{ rotate: '90deg' }} />
        <input
          ref={fileInputRef}
          accept={accept}
          style={{ display: 'none' }}
          type="file"
          onChange={handleChange}
          readOnly={isDisabled}
        />
      </FileInput>
      <span>{fileName || 'Nenhum arquivo selecionado'}</span>
    </Wrapper>
  );
};

export default FileUpload;
