import styled from 'styled-components';

export const Content = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  
  margin-top: 40px;
  max-width: 1060px;
  width: 100%;

  max-height: 360px;
  min-height: 350px;
  overflow: auto;


 input {
  background: #ffffff;
 }

 .ant-table-thead > tr > th {
  font-weight: 600;
 }
`;

export const Header = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 0px -10px 15px 0px;
  max-width: 1060px;
  width: 100%;

  h2 {
    margin-bottom: 5px;
  }
`;

export const FilterDate = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  section {
    display: flex;
    align-items: center; 
  }

  svg {
    color: #46cdd4;
    margin-right: 5px;
    cursor: pointer;
  }

  span {
    font-weight: 600;
    padding: 0px 5px;
  }

  .DayPickerInput {
    input {
      border: 0;
      width: 82px;
      cursor: pointer;
    }
  }
`;

export const Title = styled.div`

    @media(max-width: 1020px) {
      margin-bottom: -20px;

  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  div.ant-row + div.ant-row {
    margin-left: 8px;
    flex: 1;
    min-width: 100px;
    background-color: 'palevioletred';
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
