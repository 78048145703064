import React from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';

import { InputPropTypes, DefaultPropTypes } from './Types';
import { Container, StyledInput } from './styles';

// Mask input usando Cleave.js => https://nosir.github.io/cleave.js/
const Mask = (props) => {
  // Todos os valores disponíveis no options => https://github.com/nosir/cleave.js/blob/master/doc/options.md
  const {
    blocks,
    className,
    creditCard,
    creditCardStrictMode,
    date,
    value,
    dateMin,
    dateMax,
    datePattern,
    delimiter,
    delimiters,
    delimiterLazyShow,
    lowercase,
    noImmediatePrefix,
    numeral,
    numericOnly,
    numeralIntegerScale,
    numeralDecimalMark,
    numeralDecimalScale,
    numeralPositiveOnly,
    numeralThousandsGroupStyle,
    onCreditCardTypeChanged,
    onValueChanged,
    phone,
    phoneRegionCode,
    prefix,
    rawValueTrimPrefix,
    signBeforePrefix,
    stripLeadingZeroes,
    swapHiddenInput,
    tailPrefix,
    time,
    timeFormat,
    timePattern,
    uppercase,
    ...rest
  } = props;
  const options = {
    blocks,
    creditCard,
    creditCardStrictMode,
    date,
    dateMin,
    dateMax,
    datePattern,
    delimiter,
    delimiters,
    delimiterLazyShow,
    lowercase,
    noImmediatePrefix,
    numeral,
    numericOnly,
    numeralIntegerScale,
    numeralDecimalMark,
    numeralDecimalScale,
    numeralPositiveOnly,
    numeralThousandsGroupStyle,
    onCreditCardTypeChanged,
    onValueChanged,
    phone,
    phoneRegionCode,
    prefix,
    rawValueTrimPrefix,
    signBeforePrefix,
    stripLeadingZeroes,
    swapHiddenInput,
    tailPrefix,
    time,
    timeFormat,
    timePattern,
    uppercase,
  };
  return (
    <Container>
      {!!props.label && <label htmlFor={props.id}>{props.label}</label>}
      <StyledInput
// className={`ant-input ${className} ${
// value === '' ? '' : value?.length >= 11 ? 'is-valid' : 'is-invalid'
// }`}
        className={`ant-input ${className}`}
      >
        <Cleave {...rest} options={options} />
        <i />
      </StyledInput>
    </Container>
  );
};

export default Mask;

Mask.propTypes = {
  ...InputPropTypes,
  blocks: PropTypes.arrayOf(PropTypes.number),
  delimiters: PropTypes.arrayOf(PropTypes.string),
  numericOnly: PropTypes.bool,
};

Mask.defaultProps = {
  ...DefaultPropTypes,
  numericOnly: false,
  blocks: undefined,
  delimiters: undefined,
};
