import React, { useCallback } from 'react';
import { useSettings } from '../../hooks/settings';

import {
  Bar, Step, StepWrapper, Wrapper,
} from './styles';

const StepBar = ({ steps = [1, 2, 3], currentStep }) => {
  const { settings } = useSettings();

  const renderSteps = useCallback(() => steps?.map((step, index) => {
    const isActive = step <= currentStep;
    const isBarFilled = step < currentStep;
    const isLastBar = index === steps.length - 1;
    return (
      <StepWrapper key={step} data-testid="steps">
        <Step key={step} isLast={!isActive} color={settings.colors.primary}>
          {step}
        </Step>
        {!isLastBar && <Bar isLast={!isBarFilled} color={settings.colors.primary} />}
      </StepWrapper>
    );
  }), [steps, currentStep]);

  return <Wrapper>{renderSteps()}</Wrapper>;
};

export default StepBar;
