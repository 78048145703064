import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import {
  Container, Header, Content, Footer,
} from './styles';

import Button from '../Button';
import { useSettings } from '../../hooks/settings';
import api from '../../services/api';
import { useRefreshPj } from '../../hooks/refreshPj';

function Notification({
  title, visible, hidden, type, children,shouldSendGeneralPendency,isNotify,setIsNotify
}) {
  const { settings } = useSettings();
  function handleForm(e) {
    e.preventDefault();
    hidden();
  }
  const { userPj, accountId} = useRefreshPj()
  return settings && settings.name ? (
    <Modal
      wrapClassName="modal-password"
      centered
      visible={visible}
      onOk={hidden}
      closable={false}
      maskClosable={false}
      onCancel={hidden}
      footer={false}
      destroyOnClose
      style={{ padding: 0, margin: 0, borderRadius: '8px' }}
      bodyStyle={{ padding: 0 }}
    >
      <Container>
        {!isNotify ? <>
          <Header backgroundcolor={settings.colors.primary}>
            {title}
            {!shouldSendGeneralPendency && 
              <CloseOutlined onClick={!shouldSendGeneralPendency ? hidden : ''} />
            }
          </Header>
        <Content type={type}>
          <i>
            {type === 'error' && <CloseOutlined />}
            {type === 'success' && <CheckOutlined />}
          </i>
          <div>
            {children}
          </div>
          <i>
            {type === 'error' && <CloseOutlined />}
            {type === 'success' && <CheckOutlined />}
          </i>
        </Content>
        <Footer>
          <Button type="button" onClick={async () => {
            if (shouldSendGeneralPendency) {
              await api.post('onboarding/companies/pending_account', null, {
                headers: {
                  user: userPj.id,
                  account: accountId
                }
              })
            }
            hidden()
          }}>
            <span>OK</span>
          </Button>
        </Footer>
        </>
        :
        <>
         <Header backgroundcolor={settings.colors.primary}>
        {title}
        {!shouldSendGeneralPendency && 
          <CloseOutlined onClick={!shouldSendGeneralPendency ? hidden : ''} />
        }
      </Header>
          <Content type={type}>
      
          <div>
            {children}
          </div>

          </Content>
          <Footer>

          <Button 
              center={true}
                style={{
                        backgroundColor:`${settings.colors.primary}`,
                        width: '11rem'     
                      }}
                onClick={hidden}      
                      >
                <span>Ok!</span>
              </Button>
              </Footer>
        </>
        }
      </Container>
    </Modal>
  ) : (<></>);
}

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  hidden: PropTypes.func.isRequired,
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Notification.defaultProps = {
  type: 'success',
};

export default Notification;
