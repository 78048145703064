import React from 'react';
import { AiOutlineShoppingCart as CartIcon } from 'react-icons/ai';
import { FaBars as Bars, FaSearch as Search } from 'react-icons/fa';

import qeshLogoColor from '../../assets/qeshLogoColor.png';
import { useShop } from '../../hooks/shop';
import { useSettings } from '../../hooks/settings';
import {
  Category,
  Content,
  GreenStatus,
  HomeButton,
  SearchInput,
  Logo,
  SideBar,
  StoreStatus,
  Wrapper,
  LoginButton,
  Cart,
} from './styles';

const HeaderPreview = ({
  primaryColor = '#42C1C7',
  secondaryColor = '#521AA3',
}) => {
  const { shopLogo } = useShop();
  const { settings } = useSettings();
  return (
    <Wrapper
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
    >
      <Content>
        <SideBar>
          <Bars color={settings.colors.details} size={10} />
          <Logo src={shopLogo?.img_mobile?.url || qeshLogoColor} />
          <StoreStatus>
            <GreenStatus />
            <span>Aberto</span>
          </StoreStatus>
        </SideBar>
        <HomeButton>Home</HomeButton>
        <Category>Categorias</Category>
        <SearchInput>
          <Search color="#6F6F6F" size={10} />
          <span>Digite aqui o que você procura</span>
        </SearchInput>
        <LoginButton>
          <span>Entre ou cadastre-se</span>
        </LoginButton>
        <Cart>
          <CartIcon color="#FFFFFF" size={17} />
          <span>Carrinho</span>
        </Cart>
      </Content>
    </Wrapper>
  );
};

export default HeaderPreview;
