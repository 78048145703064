import styled from 'styled-components';

import { Radio as AntdRadio } from 'antd';

export const Radio = styled(AntdRadio)`
    /*.ant-radio > .ant-radio-inner {
        border-radius: 0px;
    }*/

    .ant-radio-checked .ant-radio-inner {
      border-color: ${(props) => props.color};
    }

    .ant-radio-inner::after {
      background-color: ${(props) => props.color};
    }
`;
