/* eslint-disable react/prop-types */
import React from 'react';
import { format, parseISO, addDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { HandleFormatMoney } from '../../../../services/utils';

import {
  Title, Content,
} from '../styles';

function Receipt({ item, settings }) {
  const { t } = useTranslation();
  return (
    <>
      {item && item.id && (
      <>
        <Title separatorcolor={settings.colors.primary}>
          <h3>{t('comprovante de pagamento recebido')}</h3>
          <span>{format(parseISO(item.date), 'dd/MM/yyyy HH:mm')}</span>
        </Title>
        <Content separatorcolor={settings.colors.primary}>
          <section>
            <h3>{t('informacoes do pagamento')}</h3>
            <div>
              <span>{t('nosso numero')}</span>
              <span>{item.detail.doc_number}</span>
            </div>
            <div>
              <span>{t('linha digitavel')}</span>
              <span>{item.detail.digitable_line}</span>
            </div>
            <div>
              <span>{t('vencimento')}</span>
              <span>{format(addDays(parseISO(item.detail.due_at), 1), 'dd/MM/yyyy')}</span>
            </div>
            <div>
              <span>{t('descricao')}</span>
              <span>{item.detail.description}</span>
            </div>
            <div>
              <span>{t('pago em')}</span>
              <span>{format(parseISO(item.detail.paid_at), 'dd/MM/yyyy')}</span>
            </div>
            <div>
              <span>{t('credito em')}</span>
              <span>{format(parseISO(item.detail.credit_at), 'dd/MM/yyyy HH:mm')}</span>
            </div>
          </section>

        </Content>

        <Content separatorcolor={settings.colors.primary}>
          <h3>{t('valor')}</h3>
          <h4>{HandleFormatMoney(item.amount)}</h4>
        </Content>

        <div style={{ textAlign: 'center', borderTop: '1px solid #f1f1f1', paddingTop: '10px' }}>
          <h5 style={{ fontWeight: 600, fontSize: '13px' }}>{t('codigo de autenticacao')}</h5>
          <span>{item.id}</span>
        </div>
      </>
      )}
    </>
  );
}

export default Receipt;
