import styled from 'styled-components';

export const TextStyled = styled.text`
 align-items: center;
 font-size: 1rem;
 margin-bottom: max(50px, 1rem);
 display: flex;
 gap: 0.5rem;
 text-align: justify;
 text-align-last: center;
 height: 25%;
 width: 90%;
`;

export const Title = styled.span`
 font-size: 1rem;
 font-weight: 700;
 text-align: center;
 text-align-last: center;
 margin-bottom: max(50px, 1rem);
 width: 100%;
`;
export const Icons = styled.div`
 display: flex;
 flex-direction: row;
 gap: 3rem;
 margin-bottom: 15px;
`;
export const Icon = styled.img`
 height: 4rem;
 cursor: pointer;
`;
export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  width: 100%;
`
export const MainContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`