/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { Container } from './styles';

const PasswordParamsOP = ({ value }) => (
  <Container>
    <span>Sua senha deve conter:</span>
    <span className={value ? (value?.length === 4 ? 'text-success' : 'text-error') : ''}>4 dígitos</span>
    <span className={value ? (/^\d+$/gm.test(value) ? 'text-success' : 'text-error') : ''}>Apenas números</span>
  </Container>
);

export default PasswordParamsOP;
