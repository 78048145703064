import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-flow: column nowrap;
padding: 2rem 6rem;
`;
export const Board = styled.div`
display: flex;
flex-flow: column nowrap;
border: 0.15rem solid #3C4035;
padding:0.5rem;
p {
  font-weight: 700;
}
`;
export const Paragraph = styled.p``;
export const Clause = styled.p``;
export const ClauseTitle = styled.p`
font-weight: 700;
`;
export const List = styled.ol`
display: flex;
flex-flow: column nowrap;
gap: 0.3rem;
padding: 0rem 2rem;
list-style-type: lower-alpha;
font-weight: 700;
`;
export const Point = styled.li``;
export const Table = styled.table`
`;
export const Row = styled.tr`

`;
export const Cell = styled.td`
border: 0.15rem solid #3C4035;
vertical-align: top;
padding: 0.5rem;
width: 33%;
  p {
    margin: 0rem;
  }
`;
export const Header = styled.th`
border: 0.15rem solid #3C4035;
text-align: center;
`;
