import styled from 'styled-components';

export const Wrapper = styled.div`
  width:100%;
  height:210px;
  display:flex;
  flex-direction:column;
  justify-content:space-evenly;
  align-items:center;
`;

export const TitleWrapper = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:space-between; 
  align-items:flex-start;
`;

export const Title = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  gap:5px;

  p {
    margin:0;
    padding:0;
    font-size:14px;
    font-weight:bold;
  }
  span {
    color:red;
  }
`;

export const FileSelectWrapper = styled.div`
  width:70%;
  height:fit-content;
  display:flex;
  justify-content:space-between;
  align-items:flex-end;
`;

export const FileUploadWrapper = styled.div`
  width:fit-content;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
`;

export const MarkdownButton = styled.div`
  width:fit-content;
  height:fit-content;
  font-size:1.1rem;
  font-weight:bold;
  color: ${(props) => props.color};
  cursor: pointer;
`;
