import styled from 'styled-components';

export const Content = styled.div`
  max-width: 1060px;
  width: 100%;
  margin: 5 auto 5 auto;

  h2 {
    color: #121212;
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  h3 {
    color: #121212;
    font-size: 17px;
    margin-top: 35px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  div {
    margin-bottom: 14px;
  }

  a {
    color: inherit; 
  }
  
  p {
    margin-bottom: 30px;
  }

`;
