import React from 'react';
import { Alert as AlertANTD } from 'antd';
import { AntProps, DefaultAntProps } from './Types';
import './style.css';

function Alert({ message, description, type }) {
  return (
    <AlertANTD
      style={{
        background: 'rgba(76, 175, 80, 0)',
        border: 'none',
        width: '100%',
        margin: '0.1rem',
        padding: '0.1rem',
      }}
      message={message}
      description={description}
      type={type}
    />
  );
}

Alert.propTypes = AntProps;

Alert.defaultProps = DefaultAntProps;

export default Alert;
