import styled from 'styled-components';

export const Container = styled.div`
 width: 100%;

 button {
  width: 100%;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  height: 2.3rem;
  color: var(--primary);
 }

 .ant-btn {
  color: var(--primary);
  :hover:enabled {
   border-color: var(--primary);
  }
 }
 .ant-btn-primary {
  :enabled {
    color: var(--btn-text);
  }
  :focus {
   background-color: var(--primary);
  }
  :hover:enabled {
   background-color: var(--primary);
   opacity: 0.9;
   border-color: var(--primary);
  }
 }
 @media screen and (min-width: 48.1rem) {
  button {
   font-size: 0.8rem;
   span {
    font-size: 0.8rem;
   }
  }
 }

 @media screen and (min-width: 87.5rem) {
  button {
   font-size: 1rem;
   span {
    font-size: 1rem;
   }
  }
 }
`;
