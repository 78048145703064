/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, {
  useState, useCallback, useEffect,
} from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { HandleFormatMoney, documentMask } from '../../../../services/utils';
import api from '../../../../services/api';

import { formatBranch, formatAccount } from '../utils';

import {
  Title, Content,
} from '../styles';
import { useAuth } from '../../../../hooks/auth';

function Receipt({ item, settings }) {
  const [banks, setBanks] = useState([]);
  const {
    account,
  } = useAuth();
  const { t } = useTranslation();

  const loadBanks = useCallback(() => {
    api.get('/banks').then(({ data }) => {
      const { banks: b = [] } = data;
      setBanks(b.map((bank) => ({ value: bank.code, label: `${bank.code} - ${bank.name}` })));
    });
  }, []);

  const getNameBankByCode = useCallback((code) => {
    const bank = banks.find((b) => b.value === Number(code));
    if (bank) {
      return bank.label;
    }

    return code;
  }, [banks]);

  useEffect(() => {
    loadBanks();
  }, []);

  return (
    <>
      {item && item.id && (
      <>
        <Title separatorcolor={settings.colors.primary}>
          <h3>{t('comprovante de transferencia recebida')}</h3>
          <span>{format(parseISO(item.date), 'dd/MM/yyyy HH:mm')}</span>
        </Title>
        <Content separatorcolor={settings.colors.primary}>
          <section>
            <h3>{t('origem')}</h3>
            <div>
              <span>{t('nome')}</span>
              <span>{String(item.detail.consignor_name).toUpperCase()}</span>
            </div>
            <div>
              <span>{t('documento')}</span>
              <span>{documentMask(item.detail.consignor_document)}</span>
            </div>
            {item.type !== 'TEF'
            && (
            <>
              <div>
                <span>{t('banco')}</span>
                <span>{getNameBankByCode(item.detail.consignor_bank).toUpperCase()}</span>
              </div>

              <div>
                <span>{t('agencia')}</span>
                <span>{formatBranch(item.detail.consignor_branch)}</span>
              </div>
            </>
            )}
            <div>
              <span>{t('conta')}</span>
              <span>{formatAccount(item.detail.consignor_account)}</span>
            </div>
          </section>

          <section>
            <h3>{t('destino')}</h3>
            <div>
              <span>{t('nome')}</span>
              <span>{String(item.detail.beneficiary_name).toUpperCase()}</span>
            </div>
            <div>
              <span>{t('documento')}</span>
              <span>{documentMask(item.detail.beneficiary_document)}</span>
            </div>
            {account.bacen_status != null ? (
              <>
                <div>
                  <span>{t('banco')}</span>
                  <span>{getNameBankByCode(item.detail.beneficiary_bank).toUpperCase()}</span>
                </div>
                <div>
                  <span>{t('agencia')}</span>
                  <span>{formatBranch(item.detail.beneficiary_branch)}</span>
                </div>
              </>
            ) : null}
            <div>
              <span>{t('conta')}</span>
              <span>{formatAccount(item.detail.beneficiary_account)}</span>
            </div>
          </section>
        </Content>

        <Content separatorcolor={settings.colors.primary}>
          <h3>{t('valor')}</h3>
          <h4>{HandleFormatMoney(item.amount)}</h4>
        </Content>

        <div style={{ textAlign: 'center', borderTop: '1px solid #f1f1f1', paddingTop: '10px' }}>
          <h5 style={{ fontWeight: 600, fontSize: '13px' }}>{t('codigo de autenticacao')}</h5>
          <span>{item.id}</span>
        </div>
      </>
      )}
    </>
  );
}

export default Receipt;
