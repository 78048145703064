/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, {
  createContext, useCallback, useState, useContext,
} from 'react';

import { cpf } from 'cpf-cnpj-validator';
import { useHistory } from 'react-router-dom';
import api from '../services/api';
import { useSettings } from './settings';
import { useRefreshPj } from './refreshPj';

const AuthContext = createContext({});

// eslint-disable-next-line react/prop-types
function AuthProvider({ children }) {
  const { updateSettings } = useSettings();
  const [account, setAccount] = useState(null);
  const [customMenus, setCustomMenus] = useState(null);
  const [accountsToChoose, setAccountsToChoose] = useState(null);
  const [getAccount, setGetAccount] = useState(false);
  const [onboarding, setOnboarding] = useState(false);
  const history = useHistory();
  const {
    setUserPj, setTokenPj, resetRefreshPj, setAccountId,
  } = useRefreshPj();
  const [data, setData] = useState(() => {
    const token = sessionStorage.getItem('@qesh:token');
    // const token = localStorage.getItem('@qesh:token');
    const user = localStorage.getItem('@qesh:user');
    const acc = localStorage.getItem('@qesh:account');

    if (token && user) {
      const parsedUser = JSON.parse(user);
      const parsedAccount = JSON.parse(acc);
      api.defaults.headers.authorization = `bearer ${token}`;

      if (parsedUser && parsedUser.baas.accounts.length === 1) {
        api.defaults.headers.account = parsedUser.baas.accounts[0].id;
      }

      if (parsedUser.baas.accounts.length > 1 && parsedAccount) {
        api.defaults.headers.account = parsedAccount.id;
      }
      setGetAccount(true);

      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const translator = (status) => {
    switch (status) {
      case 'User': {
        return ['basicData', '1'];
      }
      case 'AdditionalData': {
        return ['edducation', '8'];
      }
      case 'Address': {
        return ['address', '7'];
      }
      case 'Documents': {
        return ['documentation', '11'];
      }
      case 'Accounts': {
        return ['accountstatus', '13'];
      }
      case 'Selfie': {
        return ['proofoflife', '10'];
      }
      case 'SelfieUnique': {
        return ['proofoflife', '10'];
      }
      case 'PasswordFourDigits': {
        return ['operationalpassword', '12'];
      }
      // Steps PJ
      case 'CompanyData': {
        return ['dataPj', '0'];
      }
      case 'CompanyAddress': {
        return ['address', '1'];
      }
      case 'SocialContract': {
        return ['socialContract', '2'];
      }
      case 'PartnerData': {
        return ['partners', '3'];
      }
      case 'RegisterPartners': {
        return ['partnerRegisterPage', '4'];
      }
      case 'WaitingApprove': {
        return ['accountstatus', 13];
      }
      default:
    }
  };

  const signOut = useCallback(() => {
    sessionStorage.removeItem('@qesh:token');
    localStorage.removeItem('@qesh:user');
    localStorage.removeItem('@qesh:account');
    localStorage.removeItem('@documents');
    localStorage.removeItem('@loginApproved');
    setAccountsToChoose(null);
    setGetAccount(false);
    setData({});
    setAccount(null);
  }, []);

  const updateAccount = useCallback(async () => {
    let interval = null;

    async function getDataAccount() {
      const token = sessionStorage.getItem('@qesh:token');

      if (!token) {
        clearInterval(interval);
        return;
      }

      try {
        if (data?.user?.client_setting?.name === 'faemg') {
          if (localStorage.getItem('@loginApproved')) {
            const { data: response } = await api.get('/accounts');
            localStorage.setItem('@qesh:account', JSON.stringify(response));
            if (response.profile) {
              setCustomMenus(response.profile.ib_menus);
            } else {
              setCustomMenus(null);
            }
            setAccount(response);
          } else signOut();
        } else {
          const { data: response } = await api.get('/accounts');
          localStorage.setItem('@qesh:account', JSON.stringify(response));
          if (response.profile) {
            setCustomMenus(response.profile.ib_menus);
          } else {
            setCustomMenus(null);
          }
          setAccount(response);
        }
      } catch (err) {
        signOut();
      }
    }

    getDataAccount();
    interval = setInterval(getDataAccount, 60000);
  }, [signOut]);

  const selectAccount = useCallback(async ({ id }) => {
    if (id) {
      api.defaults.headers.account = id;
      const { data: response } = await api.get('/accounts');
      localStorage.setItem('@qesh:account', JSON.stringify(response));
      setGetAccount(true);
      setAccountsToChoose(null);
    }
  }, []);

  const signIn = useCallback(async ({ document, password, userToken = null }) => {
    const sett = await updateSettings();

    let user;
    let token;
    if (!userToken) {
      const response = await api.post('/login', {
        client_name: sett.name || 'qesh',
        document,
        password,
        origin: 'ib',
      });

      token = response.data.token;
      user = response.data.user;
    } else {
      token = userToken;
      const resp = await api.get('/users', {
        headers: {
          Authorization: `bearer ${userToken}`,
        },
      });
      user = resp.data.user;
      setData({ token, user });
    }

    localStorage.setItem('@ib:register', token);

    if (!user.baas.user.migration && (!user.baas.address || !user.baas.address.id)) {
      if (cpf.isValid(document)) {
        window.location.href = `/register/pf/${user.document}?step=CEP`;
        return;
      }
      window.location.href = `/register/pj/${user.document}?step=CEP`;
      return;
    }

    if (!user.baas.user.migration && user.baas.address && user.baas.user.status.name === 'WAITING_DOCUMENTS') {
      if (cpf.isValid(document)) {
        window.location.href = `/register/pf/${user.document}?step=SELFIE`;
        return;
      }
      window.location.href = `/register/pj/${user.document}?step=SELFIE`;
      return;
    }

    if (user.baas.accounts && user.baas.accounts.length > 0) {
      // Cenario que ele a pessoa tem conta.

      if (user.client_setting.name === 'faemg') {
        sessionStorage.setItem('@qesh:token', token);
        localStorage.setItem('@qesh:user', JSON.stringify(user));

        api.defaults.headers.authorization = `bearer ${token}`;

        localStorage.removeItem('@ib:register');
        setData({ token, user });

        setAccountsToChoose(user.baas.accounts);
      } else {
        sessionStorage.setItem('@qesh:token', token);
        localStorage.setItem('@qesh:user', JSON.stringify(user));

        api.defaults.headers.authorization = `bearer ${token}`;

        localStorage.removeItem('@ib:register');
        setData({ token, user });

        if (user.baas.accounts.length === 1) {
          api.defaults.headers.account = user.baas.accounts[0].id;
          setGetAccount(true);
          return;
        }

        if (user.baas.accounts.length > 1) {
          setAccountsToChoose(user.baas.accounts);
        }
      }
    } else if (user.baas.accounts && user.baas.accounts.length === 0) {
      api.defaults.headers.authorization = `bearer ${token}`;

      if (cpf.isValid(document)) {
        window.location.href = `/register/pf/${user.document}?step=PASSW`;
        return;
      }
      window.location.href = `/register/pj/${user.document}?step=PASSW`;
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signInNewOnboarding = useCallback(async ({ document, password, userToken = null }) => {
    const sett = await updateSettings();

    let user;
    let token;
    let userStatus;
    let accounts;
    if (!userToken) {
      const response = await api.post('/login', {
        client_name: sett.name || 'qesh',
        document,
        password,
        origin: 'ib',
      });

      token = response.data.token;
      user = response.data.user;
      userStatus = response.data.user.status;
      accounts = response.data.accounts.filter((i) => i.pj_access !== 'PENDING' && i.pj_access !== 'DENIED');
      const atOnboarding = !['DENIED', 'ACTIVE', 'WaitingApprove', '', undefined].includes(user.status);
      const clientPending = user?.baas?.accounts.some((acc) => acc.client_approval_pending);

      // if (!user?.baas?.user?.pending_approval
      //   && !user?.baas?.accounts.some((acc) => !!acc.client_approval_pending)
      //   && user?.status === 'ACTIVE'
      //   && user?.document.length === 14) {
      //   throw new Error('Acesso PJ');
      // }
      if (userStatus === 'RegisterPartners' && user?.document?.length === 14) {
        setTokenPj(token);
        setUserPj(user);
        setAccountId(accounts);
        throw new Error('Registro de Socios');
      }
      if (!atOnboarding && (user.baas.user.pending_approval || clientPending || user.status === 'WaitingApprove')) {
        throw new Error('Pendente de aprovação');
      }
      localStorage.setItem('@qesh:AccountsList', JSON.stringify(response.data.accounts));
    } else {
      token = userToken;
      const resp = await api.get('/users', {
        headers: {
          Authorization: `bearer ${userToken}`,
        },
      });
      user = resp.data.user;
    }
    const baasName = user.status;
    const baasAccounts = user.baas.accounts;
    if (['ACTIVE'].includes(baasName) && baasAccounts.length < 1) {
      throw new Error('Conta PF cancelada');
    }

    localStorage.setItem('@ib:register', token);
    if (accounts && accounts.length > 0) {
      // Cenario que ele a pessoa tem conta.
      if (user.client_setting.name === 'faemg') {
        sessionStorage.setItem('@qesh:token', token);
        localStorage.setItem('@qesh:user', JSON.stringify(user));

        api.defaults.headers.authorization = `bearer ${token}`;

        localStorage.removeItem('@ib:register');
        setData({ token, user });

        setAccountsToChoose(accounts);
      } else {
        sessionStorage.setItem('@qesh:token', token);
        localStorage.setItem('@qesh:user', JSON.stringify(user));

        api.defaults.headers.authorization = `bearer ${token}`;

        localStorage.removeItem('@ib:register');
        setData({ token, user });

        if (accounts.length === 1) {
          api.defaults.headers.account = accounts[0].account_id;
          setGetAccount(true);
          return;
        }

        if (accounts.length > 1) {
          setAccountsToChoose(accounts);
        }
      }
    } else if (accounts && accounts.length === 0) {
      setOnboarding(true);
      api.defaults.headers.authorization = `bearer ${token}`;
      if (user.status) {
        localStorage.setItem('@qesh:token', token);
        api.defaults.headers.Authorization = `bearer ${token}`;
        sessionStorage.setItem('@qesh:document', document);
        // eslint-disable-next-line no-unused-expressions
        user.baas.additional.nationality
  && sessionStorage.setItem('@qesh:nationality', user.baas.additional.nationality);

        // sessionStorage.setItem('@qesh:tokenOnboarding', token)
        localStorage.setItem('@qesh:userOnboarding', JSON.stringify(user));
        sessionStorage.setItem('@qesh:step', translator(user.status)[1]);
        window.location.pathname = `/register/${translator(user.status)[0]}`;
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data?.token,
        signIn,
        signOut,
        updateAccount,
        account,
        accountsToChoose,
        getAccount,
        selectAccount,
        customMenus,
        signInNewOnboarding,
        setOnboarding,
      }}
    >
      {children}
    </AuthContext.Provider>

  );
}

function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth };
