import styled from 'styled-components';

export const Wrapper = styled.div`
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position:absolute;
  background-color:rgba(0,0,0,0.5);
  left:0;
  right:0;
  margin: 0 auto;

`;

export const Editor = styled.div`
  width:230px;
  height:95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius:10px;
  padding:5px;
  align-items: center;
  overflow: hidden;
  background-color:#FFFFFF;
  
`;

export const AvatarEditorWrapper = styled.div`
  width:80%;
  height:60%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color:#6F6F6F;
`;

export const SliderWrapper = styled.div`
  width:80%;
  height:25%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Slider = styled.input`
  width: 50%;
  -webkit-appearance: none;
  height: 4px;
  border-radius: 5px;  
  background: #A8A8A8;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 8px;
    height: 8px;
    border-radius: 50%; 
    border: 1px solid #04AA6D;
    background-color:white;
    cursor: pointer;
  }

  ::-moz-range-thumb {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #04AA6D;
    background-color:white;
    cursor: pointer;
  }

`;

export const ButtonsWrapper = styled.div`
  width:90%;
  height:15%;
  display: flex;
  justify-content: space-between;

`;

export const Button = styled.div`
  width: 35px;
  height: 29px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:${(props) => props.color};
  cursor: pointer;
`;
