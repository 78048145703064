import styled from 'styled-components';

import { Row, Col } from '../../../styles/components';

export const Content = styled.div`
  max-width: 1060px;
  width: 100%;
  margin: 5 auto 5 auto;
  padding-bottom: 250px;

  h2 {
    color: #121212;
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  h3 {
    color: #121212;
    font-size: 17px;
    margin-top: 35px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  p {
    margin-bottom: 30px;
  }
`;

export const ContentTab = styled.div`
 max-height: 290px;
 margin-top: 35px;

 .ant-table-thead > tr > th {
  font-weight: 600;
 }
`;

export const MyAccounts = styled(Row).attrs({
  gutter: 16,
})`
  padding: 5px 5px;
  margin-bottom: 15px;
`;

export const Account = styled(Col).attrs({
  xs: 24, sm: 12, md: 5, lg: 5,
})`
  margin-bottom: 5px;

  span {
    cursor: pointer;
  }

  span:last-child {
    color: ${(props) => props.color};
    margin-top: 5px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
`;

export const ModalHeader = styled.div`
  background: ${(props) => props.backgroundcolor};
  color: #F9F9F9;
  text-align: center;
  padding: 8px 0;
  font-weight: bold;
  border-radius: 8px;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
