import api from '../../api';

class updatePartnerPj {
  /**
   * @description register a new partner
   * @param {string} name name of the partner
   * @param {string} email email of the partner
   * @param {string} qualification qualification of the partner
   * @param {string} document cpf of the partner
   * @returns {Promise<object | boolean>} returns the response of the api if success or false if fails
   */
  async newPartner({
    name,
    email,
    qualification,
    document,
    account,
    user,
  }){
    const body = {
      partners: {
        name,
        email,
        document,
        qualification,
      },
    };
    try {
      const response = await api.patch(
        'onboarding/companies/partners/ADD',
        body,
        {
          headers: {
            account,
            user,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.log('newPartner', error.response.data);
      const {message} = error.response.data;
      const errorMessages = [
        {
          message: 'Partner already exist.',
          key: 'EXISTED_PARTNER',
        },
        {
          message: 'You exceeded attemtps today',
          key: 'EXCEEDED_ATTEMPTS',
        },
      ];

      const result = errorMessages.find(({message: innerMessage}) =>
        message.includes(innerMessage),
      );

      return result ? result.key : false;
    }
  }

  /**
   * @description update a partner's data
   * @param {string} name name of the partner
   * @param {string} email email of the partner
   * @param {string} qualification qualification of the partner
   * @param {string} document cpf of the partner
   * @returns {Promise<object | boolean>} returns the response of the api if success or false if fails
   */
  async updatePartner({
    name,
    email,
    qualification,
    document,
  }) {
    const body = {
      name,
      email,
      document,
      qualification,
    };
    try {
      const response = await api.patch(
        'onboarding/companies/partners/UPDATE',
        body,
      );
      return response.data;
    } catch (error) {
      console.log('updatePartner', error.response.data);
      return false;
    }
  }

  /**
   * @description update a partner's data
   *
   * @param {string} name name of the partner
   * @param {string} email email of the partner
   * @param {string} qualification qualification of the partner
   * @param {string} document cpf of the partner
   * @returns {Promise<object | boolean>} returns the response of the api if success or false if fails
   */
  async deletePartner({
    name,
    email,
    qualification,
    document,
    account,
    user,
  }){
    const body = {
      partners: {
        name,
        email,
        document,
        qualification,
      },
    };
    try {
      const response = await api.patch(
        'onboarding/companies/partners/REMOVE',
        body,
        {
          headers: {
            account,
            user,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.log('deletePartner', error.response.data);
      return false;
    }
  }

  /**
   * @description get all partners
   * @returns {Promise<object | boolean>} returns the response of the api if success or false if fails
   */
  async getPartners({
    account,
    user,
  }) {
    try {
      const response = await api.get('/onboarding/companies/partners', {
        headers: {
          account,
          user,
        },
      });

      return response.data.partners;
    } catch (error) {
      console.log('getPartners', error.response.data);
      return false;
    }
  }

  /**
   * @description register a new partner
   * @param {string} name name of the partner
   * @param {string} email email of the partner
   * @param {string} qualification qualification of the partner
   * @param {string} document cpf of the partner
   * @returns {Promise<object | boolean>} returns the response of the api if success or false if fails
   */
  async resendPartner({
    name,
    email,
    qualification,
    document,
    account,
    user,
  }){
    const body = {
      partners: {
        document,
        name,
        email,
        qualification,
      },
    };
    try {
      const response = await api.post(
        'onboarding/companies/partners/resend',
        body,
        {
          headers: {
            account,
            user,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.log('resendPartner', error.response.data);
      return false;
    }
  }

  /**
   * @description update a partner's data
   * @param {string} name name of the partner
   * @param {string} email email of the partner
   * @param {string} qualification qualification of the partner
   * @param {string} document cpf of the partner
   * @returns {Promise<object | boolean>} returns the response of the api if success or false if fails
   */
  async updatePartnerPJ({
    name,
    email,
    qualification,
    document,
    account,
    user,
  })  {
    const body = {
      partners: {
        name,
        email,
        document,
        qualification,
      },
      newPartner: {
        name,
        email,
        document,
        qualification,
      },
    };
    try {
      const response = await api.patch(
        'onboarding/companies/partners/UPDATE',
        body,
        {
          headers: {
            account,
            user,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.log('updatePartnerPJ', error.response.data);
      return false;
    }
  }
}

export default new updatePartnerPj();