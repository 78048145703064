import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${(props) => props.size || 20}px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  align-items: center;
  border: 1px solid #D9D9D9;
  background-color: ${(props) => (props.isChecked ? props.color : 'transparent')};
  border-radius: 2px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;
