/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faEllipsis, faMinus, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSteps } from '../../../hooks/steps';
import { useCheckpoint } from '../../../hooks/checkpoint';
import {
  ResolvedAssociate,
  AssociateArea,
  Associate,
  Title,
  Subtitle,
  Text,
  DataInfo,
  List,
  EditButtonContainer,
  DeleteButtonContainer,
  SectionContainer,
  ButtonArea,
} from './styles';
import { handleGetPartners } from '../../../services/endPointRegister/PJ/getPartners';
import { removePartner } from '../../../services/endPointRegister/PJ/updatePartners';
import ButtonN from '../../../components/ButtonN';
import ModalForTransition from '../../../components/ModalForTransition';
import { handleGetStepPj } from '../../../services/endPointRegister/PJ/statusPj';
import { useRefreshPj } from '../../../hooks/refreshPj';

const Partners = () => {
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const {
    list, exactStep,
  } = useSteps();
  const {
    additional, accountId, userPj, partner, setStep, setPartner, basicData, token,
  } = useRefreshPj();
  const history = useHistory();
  const [numPartners, setNumPartners] = useState(0);
  const { t } = useTranslation();
  const resolved = 'PARTNER ACCOUNT ACCEPT';
  const denied = 'PARTNER ACCOUNT DENIED';
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedPartnerRemove, setSelectedPartnerRemove] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [partners, setPartners] = useState(partner.partners);

  const {
    legalNature,
    numberOfAffiliates,
    numberOfEmployees,
    numberOfPartners,
    openingDate,
    revenues,
    shareCapital,
    tradingName,
  } = additional;

  useEffect(() => {
    const request = async () => {
      const { res } = await handleGetPartners(accountId, userPj.id);
      const partnerList = res.partners || [];
      setPartners(partnerList);
      setNumPartners(numberOfPartners);
      setIsLoading(false);
    };
    request();
  }, []);
  const getUser = ({ document, name }) => {
    const cpf = document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    return `${name} / ${cpf}`;
  };

  const handlePlus = () => {
    setStep('registerPartner');
  };

  const handleEditButton = (index) => {
    setSelectedPartnerRemove(partners[index]);
    setShowEditModal(true);
  };

  const modalRemove = async (index) => {
    setShowConfirmationModal(false);
    setIsLoading(true);
    await removePartner(selectedPartnerRemove, token, userPj.id, accountId);
    // const { res } = handleGetPartners(userPj?.baas?.user?.id, accountId);
    // setPartners(res.partners || []);
    // setSelectedPartnerRemove(partners[index]);
    const { res } = await handleGetPartners(accountId, userPj.id);
    const partnerList = res.partners || [];
    setPartners(partnerList);
    setIsLoading(false);
  };

  const deleteParnerModal = () => {
    setShowEditModal(false);
    setShowConfirmationModal(true);
  };

  const components = Array.from({ length: numPartners }).map((_, index) => {
    const partner = partners[index];
    return partner ? (
      partner.status === resolved ? (
        <ResolvedAssociate>
          {' '}
          {getUser(partner)}
          <FontAwesomeIcon
            icon={faCheck}
            color="var(--success)"
            style={{
              fontSize: '130%',
              cursor: ' pointer',
              marginLeft: '0.5rem',
            }}
          />
        </ResolvedAssociate>
      ) : (
        <AssociateArea>
          <Associate
            key={partner.document}
            style={{ color: 'grey', gap: '10px', width: '100%' }}
            onClick={() => handleEditButton(index)}
          >
            <Text>
              {partner.document.length < 12 ? (
                <span style={{ fontSize: '0.7rem', padding: '0' }}>
                  {partner.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')} /{' '}
                  {partner.name}
                </span>
              ) : partner.document.length > 11 ? (
                <span style={{ fontSize: '0.7rem', padding: '0' }}>
                  {partner.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3.$4-$5')} /{' '}
                  {partner.name}
                </span>
              ) : null}
              {partner.status === denied ? (
                <DataInfo style={{ color: 'var(--error)', fontWeight: 'bold' }}>
                  Convite foi recusado pelo sócio / representante legal
                </DataInfo>
              ) : (
                <DataInfo>
                  {/* Link enviado em {partner.partner_mails_contact.sender.date} às{' '} */}
                  {/* {partner.partner_mails_contact.sender.hour} */}
                </DataInfo>
              )}
            </Text>
            <FontAwesomeIcon
              style={{
                fontSize: '130%',
                cursor: ' pointer',
                marginLeft: '0.3rem',
              }}
              icon={faMinus}
              color="var(--error)"
              onClick={() => deleteParnerModal()}
            />
          </Associate>
        </AssociateArea>
      )
    ) : (
      <AssociateArea>
        <Associate key={index} style={{ color: 'grey', gap: '5px', width: '100%' }} onClick={() => handlePlus()}>
          <Text>{t('Adicionar sócio')}</Text>
          <FontAwesomeIcon
            style={{
              fontSize: '130%',
              cursor: ' pointer',
              marginLeft: '0.3rem',
            }}
            icon={faPlus}
            color="var(--success)"
          />
        </Associate>
      </AssociateArea>
    );
  });

  useEffect(() => {
    setCheckpoint({ ...checkpoint, list, steplist: true });
  }, []);

  return isLoading ? (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#121212' }} spin />} />
  ) : (
    <SectionContainer>
      <Title>{t('Dados de cadastro')}</Title>
      <Subtitle style={{ color: 'grey' }}>
        {t(
          'Insira as informações dos sócios, representantes, mandatários ou prepostos autorizados da empresa.',
        )}
      </Subtitle>
      <List>
        {/* <MainAssociate>
     {getUser()}
     <FontAwesomeIcon icon={faCheck} color='var(--success)' style={{ marginLeft: '0.3rem' }} />
    </MainAssociate> */}
        {components}
      </List>
      <ButtonArea>
        <ButtonN type="primary" onClick={() => history.replace('/')}>
          Voltar
        </ButtonN>
      </ButtonArea>

      {/* <ModalForTransition
        visibility={showEditModal}
        setVisibility={setShowEditModal}
        title={(
          <h2
            style={{
              height: '30%',
              textAlign: 'center',
              alignItems: 'center',
              marginBottom: '1rem',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            O que deseja fazer?
          </h2>
   )}
        style={{ height: '100%' }}
      >
        <p
          style={{
            height: '30%',
            width: '100%',
            marginBottom: '1rem',
          }}
        />
        <p style={{ fontSize: '16px' }}>Você pode excluir o sócio caso ele não faça mais parte do quadro societário ou editar as informações se necessário.</p>
        <EditButtonContainer>
          <ButtonN
            size="large"
            className="botao"
            type="primary"
            onClick={modalEdit}
            style={{ width: '100%', color: 'var(--label)', fontSize: '16px' }}
          >
            Editar informações
          </ButtonN>
          <ButtonN
            size="large"
            className="botao"
            onClick={deleteParnerModal}
            style={{ width: '100%', fontSize: '16px' }}
          >
            Excluir sócio
          </ButtonN>
        </EditButtonContainer>
      </ModalForTransition> */}

      <ModalForTransition
        visibility={showConfirmationModal}
        setVisibility={setShowConfirmationModal}
        title={(
          <h2
            style={{
              height: '30%',
              textAlign: 'center',
              alignItems: 'center',
              marginBottom: '1rem',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            Quer mesmo excluir esse sócio?
          </h2>
   )}
        style={{ height: '100%' }}
      >
        <p
          style={{
            height: '30%',
            width: '100%',
            marginBottom: '1rem',
          }}
        />
        <p style={{ fontSize: '16px' }}>Se você excluir esse sócio, poderá convidá-lo novamente a qualquer momento.</p>
        <DeleteButtonContainer>
          <ButtonN
            size="large"
            className="botao"
            onClick={modalRemove}
            style={{ width: '70%', color: 'var(--error)', fontSize: '16px' }}
            danger
          >
            Excluir
          </ButtonN>
          <ButtonN
            size="large"
            className="botao"
            type="danger"
            onClick={() => setShowConfirmationModal(false)}
            style={{ width: '70%', color: '#ffffff', fontSize: '16px' }}
          >
            Não
          </ButtonN>
        </DeleteButtonContainer>
      </ModalForTransition>
    </SectionContainer>
  );
};

export default Partners;
