import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import {
  Table, Skeleton, Tooltip, Pagination, notification, Modal,
} from 'antd';
import {
  FiCopy,
} from 'react-icons/fi';
import {
  FaReceipt,
} from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdAttachMoney, MdPayment } from 'react-icons/md';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { addHours, format, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import Password from '../../../../components/Password';
import Button from '../../../../components/Button';
import { useAuth } from '../../../../hooks/auth';
import chargesApi from '../../../../services/charges';
import { HandleFormatMoney } from '../../../../services/utils';
import Payment from './Payment';
import getErrors from '../../../../services/errors';

import {
  Content, TabsContainer, Tab, FilterContainer,
} from './styles';
import api from '../../../../services/api';
import Loading from '../../../../components/Loading';
import Receipt from '../../../Transaction/Receipt';
import { useSettings } from '../../../../hooks/settings';

const { Column } = Table;

const StudentPage = () => {
  const { user, token, account } = useAuth();
  const { settings } = useSettings();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [payments, setPayments] = useState();
  const [paymentType, setPaymentType] = useState(null);
  const [showPaymentContent, setShowPaymentContent] = useState(false);
  const [chargeToPay, setChargeToPay] = useState(null);
  const [showPassword, setShowPassword] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [, setTotalItemsPage] = useState(0);

  const [currentTab, setCurrentTab] = useState('ALL');

  const [receipt, setReceipt] = useState('');

  const getData = useCallback((page) => {
    setTableLoading(true);

    let filters = { page: page - 1 };
    let formattedData = {};

    if (currentTab === 'DETACHED') {
      chargesApi.get(`nmpay/detached-charges?document=${user.document}&page=0`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        // eslint-disable-next-line no-param-reassign
        formattedData = { ...response.data, type: 'AVULSE-PAYMENT' };

        setPayments(formattedData);
        setLoading(false);
        setTableLoading(false);

        setCurrentPage(page ? page - 1 : 0);
        setTotalPages(response.data.total_pages + 1);
        setTotalItems(response.data.total_items);
        setTotalItemsPage(response.data.total_items_page);
      });
    } else {
      if (currentTab && currentTab !== 'ALL') {
        filters = { ...filters, status: currentTab };
      }

      if (paymentType && paymentType !== 'all') {
        filters = { ...filters, payment_type: paymentType };
      }

      if (user.document) {
        filters = { ...filters, document: user.document };
      }

      chargesApi.get('nmpay/charges', {
        params: filters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        response.data = { ...response.data, type: 'CHARGE-PAYMENT' };
        setPayments(response.data);
        setLoading(false);
        setTableLoading(false);

        setCurrentPage(page ? page - 1 : 0);
        setTotalPages(response.data.total_pages + 1);
        setTotalItems(response.data.total_items);
        setTotalItemsPage(response.data.total_items_page);
      });
    }
  }, [currentTab, paymentType, user.document, token]);

  useEffect(() => {
    getData(1);
  }, [getData]);

  const handlePayWithBalance = useCallback(async (password) => {
    setLoadingPage(true);
    try {
      await api.post('accounts/validate/password', {
        password,
      });

      await chargesApi.post(`nmpay/charges/pay-with-balance/${showPassword?.id}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          account: account.id,
        },
      });

      notification.error({ message: 'Mensalidade paga com sucesso utilizando o saldo da sua conta.' });
      getData(1);
    } catch (err) {
      notification.error({ message: `Ocorreu um erro ao pagar utilizando o saldo da conta. ${getErrors(err)}` });
    }
    setLoadingPage(false);
  }, [account.id, showPassword, token, getData]);

  const handleGoToBilletFile = useCallback((id) => {
    window.open(`https://qesh-storage.s3.amazonaws.com/billets/${id}/bill_file.pdf`);
  }, []);

  const formattedPayments = useMemo(() => (currentTab === 'DETACHED'
    ? payments?.items.map((payment) => (
      {
        ...payment,
        partner_name: payment.partner?.name_1,
        bar_code: (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {payment.bar_code.substring(0, 4)}...
            <Tooltip title="Copiar código de barras">
              <CopyToClipboard
                text={payment.bar_code}
                onCopy={() => {
                  notification.success({
                    message: 'Linha Digitável',
                    description: 'Linha digitável copiada com sucesso.',
                  });
                }}
              >
                <FiCopy style={{ marginLeft: '12px' }} />
              </CopyToClipboard>
            </Tooltip>
          </span>
        ),
        receipt_payment_avulse_button: (
          <Tooltip title="Visualizar o recibo do pagamento">
            <Button
              style={{
                fontSize: 10, padding: 5, marginBottom: 0, minWidth: 86,
              }}
              onClick={() => setReceipt({ ...payment, type: 'AVULSE-PAYMENT' })}
            >
              <div style={{
                padding: '4px', display: 'flex', justifyContent: 'space-around', alignItems: 'center',
              }}
              >
                <FaReceipt
                  size={16}
                  style={{ cursor: 'pointer' }}
                />
                <span style={{ marginLeft: 5 }}>Visualizar recibo</span>
              </div>
            </Button>
          </Tooltip>
        ),
      }))
    : payments?.items.map((payment) => ({
      id: payment.id,
      description: payment.description,
      due_at: format(parseISO(payment.due_at), 'dd/MM/yyyy'),
      paid_at: payment.paid_at
        ? <span style={{ fontWeight: 'bold' }}>{format(parseISO(payment.paid_at), 'dd/MM/yyyy')}</span>
        : <span style={{ fontWeight: 'bold' }}>NÃO PAGO</span>,
      base_value: HandleFormatMoney(Number(payment.base_value)),
      current_value: payment.paid_at ? 'R$ 0,00' : HandleFormatMoney(Number(payment.current_value)),
      paid_value: payment.paid_value ? (
        <span style={{ fontWeight: 'bold', color: 'green' }}>
          {HandleFormatMoney(Number(payment.paid_value))}
        </span>
      ) : '-',
      credit_payment_button: payment.paid_at ? null : (
        <Tooltip title="Realizar pagamento com cartão de crédito">
          <Button
            style={{ fontSize: 10, padding: 5, minWidth: 86 }}
            onClick={() => {
              setChargeToPay(payment);
              setShowPaymentContent(true);
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <MdPayment
                size={24}
                style={{ cursor: 'pointer' }}
              />
              <span style={{ marginLeft: 5 }}>Pagar com cartão</span>
            </div>
          </Button>
        </Tooltip>
      ),
      account_payment_button: payment.paid_at ? null : (
        <Tooltip title="Realizar pagamento com saldo da sua conta NMPay">
          <Button
            style={{ fontSize: 10, padding: 5, minWidth: 86 }}
            onClick={() => setShowPassword({ id: payment.id })}
          >
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <MdAttachMoney
                size={24}
                style={{ cursor: 'pointer' }}
              />
              <span style={{ marginLeft: 5 }}>Pagar com saldo</span>
            </div>
          </Button>
        </Tooltip>
      ),
      billet_button: (
        <Tooltip title="Visualizar o boleto para pagamento">
          <Button
            style={{ fontSize: 10, padding: 5, minWidth: 86 }}
            onClick={() => handleGoToBilletFile(payment.billet_id)}
          >
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <AiOutlineFilePdf
                size={24}
                style={{ cursor: 'pointer' }}
              />
              <span style={{ marginLeft: 5 }}>Visualizar boleto</span>
            </div>
          </Button>
        </Tooltip>
      ),
      receipt_payment_charge_button: payment.paid_at && (
        <Tooltip title="Visualizar o recibo do pagamento">
          <Button
            style={{ fontSize: 10, padding: 5, minWidth: 86 }}
            onClick={() => setReceipt({ ...payment, type: 'CHARGE-PAYMENT' })}
          >
            <div style={{
              padding: '4px', display: 'flex', justifyContent: 'space-around', alignItems: 'center',
            }}
            >
              <FaReceipt
                size={16}
                style={{ cursor: 'pointer' }}
              />
              <span style={{ marginLeft: 5 }}>Visualizar recibo</span>
            </div>
          </Button>
        </Tooltip>
      ),
    }))),
  [handleGoToBilletFile, payments?.items, currentTab]);

  return (
    <>
      <Password
        visible={!!showPassword}
        hidden={() => setShowPassword(null)}
        afterPassword={handlePayWithBalance}
      />
      <Loading hasLoading={loadingPage} />

      {!showPaymentContent ? (
        <Content>
          <h1>Mensalidades</h1>

          <FilterContainer>
            <TabsContainer>
              <Tab
                onClick={() => {
                  setCurrentTab('ALL');
                }}
                selected={currentTab === 'ALL'}
              >
                <span>Todos</span>
              </Tab>

              <Tab
                onClick={() => {
                  setCurrentTab('PAID');
                }}
                selected={currentTab === 'PAID'}
              >
                <span>Pagos</span>
              </Tab>

              <Tab
                onClick={() => {
                  setCurrentTab('PENDING');
                }}
                selected={currentTab === 'PENDING'}
              >
                <span>Pendentes</span>
              </Tab>

              <Tab
                onClick={() => {
                  setCurrentTab('OVERDUE');
                }}
                selected={currentTab === 'OVERDUE'}
              >
                <span>Atrasados</span>
              </Tab>
              <Tab
                onClick={() => {
                  setCurrentTab('DETACHED');
                }}
                selected={currentTab === 'DETACHED'}
              >
                <span>Avulsos</span>
              </Tab>
            </TabsContainer>

            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Realizar pagamento avulso de boleto">
                <Button
                  onClick={() => history.push('/educational-payments/detached')}
                  style={{ marginLeft: '8px' }}
                ><span>Pagar boleto avulso</span>
                </Button>
              </Tooltip>
            </div>
          </FilterContainer>

          {loading ? (
            <>
              <Skeleton active title="Depósito" loading={loading} paragraph={2} rows={2} />
              <Skeleton active title="Depósito" loading={loading} paragraph={2} rows={2} />
              <Skeleton active title="Depósito" loading={loading} paragraph={2} rows={2} />
            </>
          ) : (

            <>
              {currentTab === 'DETACHED' ? (
                <>
                  <Table
                    dataSource={formattedPayments}
                    pagination={false}
                    rowKey="id"
                    loading={tableLoading}
                    locale={{
                      emptyText: 'Nenhum pagamento para exibir.',
                    }}
                    scroll={{ x: true }}
                  >

                    <Column
                      title="Nome do pagador"
                      dataIndex="payer_name"
                      key="payer_name"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />
                    <Column
                      title="Faculdade"
                      dataIndex="partner_name"
                      key="partner_name"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title="Data de vencimento"
                      dataIndex="due_at"
                      key="due_at"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text) => (text ? format(addHours(new Date(text), 3), 'dd/MM/yyyy') : '-')}
                    />

                    <Column
                      title="Data de pagamento"
                      dataIndex="paid_at"
                      key="paid_at"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text) => (text ? format(addHours(new Date(text), 3), 'dd/MM/yyyy') : '-')}
                    />

                    <Column
                      title="Valor"
                      dataIndex="amount"
                      key="amount"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text) => HandleFormatMoney(+(text || 0))}
                    />

                    <Column
                      title="Parcelas"
                      dataIndex="installments"
                      key="installments"
                      responsive={['xs', 'sm', 'md', 'xl']}
                      render={(text) => text || '-'}
                    />
                    <Column
                      title="Linha digitável"
                      dataIndex="bar_code"
                      key="bar_code"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title=""
                      dataIndex="receipt_payment_avulse_button"
                      key="receipt_payment_avulse_button"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                  </Table>
                  <Pagination
                    current={currentPage + 1}
                    onChange={getData}
                    total={totalItems}
                    pageSize={20}
                    style={{ alignSelf: 'flex-end', marginTop: '6px' }}
                  />

                  <Modal
                    centered
                    visible={!!receipt}
                    onOk={() => setReceipt('')}
                    closable={false}
                    onCancel={() => setReceipt('')}
                    footer={false}
                    destroyOnClose
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Receipt item={receipt} onCancel={() => setReceipt('')} settings={settings} />
                  </Modal>
                </>
              ) : (
                <>
                  <Table
                    dataSource={formattedPayments}
                    pagination={false}
                    rowKey="id"
                    loading={tableLoading}
                    locale={{
                      emptyText: 'Nenhum pagamento para exibir.',
                    }}
                    scroll={{ x: true }}
                  >

                    <Column
                      title="Descrição"
                      dataIndex="description"
                      key="description"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title="Data de vencimento"
                      dataIndex="due_at"
                      key="due_at"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title="Data de pagamento"
                      dataIndex="paid_at"
                      key="paid_at"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title="Valor base"
                      dataIndex="base_value"
                      key="base_value"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title="Valor de pagamento"
                      dataIndex="current_value"
                      key="current_value"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title="Valor pago"
                      dataIndex="paid_value"
                      key="paid_value"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title=""
                      dataIndex="credit_payment_button"
                      key="credit_payment_button"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title=""
                      dataIndex="account_payment_button"
                      key="account_payment_button"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title=""
                      dataIndex="billet_button"
                      key="billet_button"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                    <Column
                      title=""
                      dataIndex="receipt_payment_charge_button"
                      key="receipt_payment_charge_button"
                      responsive={['xs', 'sm', 'md', 'xl']}
                    />

                  </Table>
                  <Pagination
                    current={currentPage + 1}
                    onChange={getData}
                    total={totalItems}
                    pageSize={20}
                    style={{ alignSelf: 'flex-end', marginTop: '6px' }}
                  />

                  <Modal
                    centered
                    visible={!!receipt}
                    onOk={() => setReceipt('')}
                    closable={false}
                    onCancel={() => setReceipt('')}
                    footer={false}
                    destroyOnClose
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Receipt item={receipt} onCancel={() => setReceipt('')} settings={settings} />
                  </Modal>
                </>
              )}
            </>
          )}
        </Content>
      )
        : (
          <Payment
            backToStudentPage={() => {
              setShowPaymentContent(false);
              setChargeToPay(null);
              getData(1);
            }}
            payment={chargeToPay}
          />
        )}
    </>

  );
};

export default StudentPage;
