import React from 'react';
import { useShop } from '../../../hooks/shop';
import FileUpload from '../../FileInput';
import InputUrl from '../../InputUrl';
import UploadImage from '../../UploadImage';
import CheckBox from '../../Checkbox';
import { ShopData } from '../ShopData';
import { FormInput as Input } from '../../FormInput';
import {
  TextWrapper, ShopImageSelect, Title, ImagePlaceholderWrapper, FooterInputsWrapper, InputColumn, UploadFileWrapper, FileUploadWrapper, TermsContainer, Anchor, AnchorContainer,
} from './styles';
import { inputValidator } from '../../../utilitys/Masks';
import { useSettings } from '../../../hooks/settings';

export const ShopCheck = () => {
  const {
    usageTerms, shopInfo, shopLogo, handleChange, passwordConfirm, setPasswordConfirm, handleTermsCheckboxChange, hasAcceptedTerms,
  } = useShop();

  const isValidPassword = passwordConfirm.length <= 0 || shopInfo.password === passwordConfirm;
  const { settings } = useSettings();

  function handlePasswordConfirmChange(e) {
    setPasswordConfirm(e.target.value);
  }

  return (
    <>
      <ShopImageSelect>
        <TextWrapper>
          <Title>
            <span>Imagem da loja</span>
            <p>*</p>
          </Title>
          <span>Formato PNG ou JPG de no máximo 5MB</span>
        </TextWrapper>
        <ImagePlaceholderWrapper>
          <UploadImage
            format="desktop"
            name="img_web"
            imgSrc={shopLogo}
            isDisabled
          />
          <UploadImage
            format="mobile"
            size={[140, 50]}
            name="img_mobile"
            imgSrc={shopLogo}
            isDisabled
          />
        </ImagePlaceholderWrapper>
      </ShopImageSelect>
      <ShopData hasDefaultFooter={false} allInputDisabled />
      <FooterInputsWrapper>
        <InputColumn>
          <UploadFileWrapper>
            <span>Termos de Uso/Política de Privacidade</span>
            <FileUploadWrapper>
              <span>Formato .md</span>
              <FileUpload file={usageTerms} isDisabled />
            </FileUploadWrapper>
          </UploadFileWrapper>
          <InputUrl width="55%" isDisabled isDisplay />
        </InputColumn>
        <InputColumn>
          <Input
            label="Senha"
            placeholder="Digite uma senha"
            width="47%"
            value={shopInfo?.password}
            name="password"
            handleChange={handleChange}
            isValid={inputValidator.password(shopInfo?.password)}
            isRequired
            readOnly
            hasHelp
            errorMessage="A senha não atende aos requisitos mínimos"
            type="password"
          />
          <Input
            width="47%"
            isRequired
            label="Confirme a senha"
            placeholder="Digite uma senha"
            type="password"
            handleChange={handlePasswordConfirmChange}
            isValid={isValidPassword}
            errorMessage="As senhas não conferem"
            value={passwordConfirm}
          />
        </InputColumn>
        <TermsContainer>
          <CheckBox isChecked={hasAcceptedTerms} onCheck={handleTermsCheckboxChange} />
          <AnchorContainer>
            Ao criar sua loja, você concorda com nossos
            <Anchor color={settings.colors.primary}>Termos de Uso</Anchor>,
            <Anchor color={settings.colors.primary}>Política de Privacidade</Anchor> e
            <Anchor color={settings.colors.primary}>Política de Cookies</Anchor>
          </AnchorContainer>
        </TermsContainer>
      </FooterInputsWrapper>
    </>
  );
};
