/* eslint-disable react/prop-types */
import React from 'react';
import { format, parseISO } from 'date-fns';
import { useAuth } from '../../../../hooks/auth';
import { HandleFormatMoney } from '../../../../services/utils';

import {
  Title, Content,
} from '../styles';

function Receipt({ item, settings }) {
  const { account } = useAuth();

  return (
    <>
      {item && item.id && (
        <>
          <Title separatorcolor={settings.colors.primary}>
            <h3>Comprovante de pagamento de boleto avulso </h3>
          </Title>
          <Content separatorcolor={settings.colors.primary}>
            <section>
              <h3>Informações do pagamento</h3>

              <div>
                <span>Linha Digitável: </span>
                <span>{item.bar_code}</span>
              </div>

              <div>
                <span>Pagamento para: </span>
                <span>{item.partner.name_1}</span>
              </div>

              <div>
                <span>Pago por: </span>
                <span>{account.user.name}</span>
              </div>

              <div>
                <span>Data da transação: </span>
                <span>{format(parseISO(item.paid_at), 'dd/MM/yyyy')}</span>
              </div>

            </section>

          </Content>

          <Content separatorcolor={settings.colors.primary}>
            <h3>Valor</h3>
            <h4>{HandleFormatMoney(+item.amount)}</h4>
          </Content>

          <div style={{ textAlign: 'center', borderTop: '1px solid #f1f1f1', paddingTop: '10px' }}>
            <h5 style={{ fontWeight: 600, fontSize: '13px' }}>Código de autenticação</h5>
            <span>{item.id}</span>
          </div>
        </>
      )}
    </>
  );
}

export default Receipt;
