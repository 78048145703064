import styled from 'styled-components';

export const SpanSub = styled.p`
 margin-top: -10px;
 font-size: 0.8rem;
`;

export const DualButtonsDisposition = styled.nav`
 box-sizing: border-box;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 height: 70%;
 min-height: 7vh;
 div {
 width: 45%;
 }
 button {
 min-width: 100%;
 }
`;
