/* eslint-disable no-shadow */
import React, { useState } from 'react';

import MarkdownIt from 'markdown-it';
import FileSaver from 'file-saver';
import { AiOutlineOrderedList as OrderedList, AiOutlineUnorderedList as UnorderedList } from 'react-icons/ai';
import { FaQuoteLeft as Quote } from 'react-icons/fa';
import { FormInput as Input } from '../../components/FormInput';
import {
  ContentWrapper,
  ContentLabel,
  EditorWrapper, Icon, IconsWrapper, InfoBox, MdBox, MdBoxWrapper, SaveFileWrapper, Wrapper, RenderedMdBox, Button,
} from './styles';

const MarkdownGenerator = () => {
  const [markdownText, setMarkDownText] = useState('');

  const [renderedHTML, setRenderedHTML] = useState('');

  const [fileName, setFileName] = useState('untitled-note');

  const modifiers = ['H1', 'H2', 'H3', 'B', '/', 'Nlist', 'Blist', 'quotes'];

  function getModifierDisplayText(modifier, size = 15) {
    if (modifier === 'Nlist') {
      return <OrderedList size={size} />;
    }

    if (modifier === 'Blist') {
      return <UnorderedList size={size} />;
    }

    if (modifier === 'quotes') {
      return <Quote size={size} />;
    }

    return modifier;
  }

  function handleTextInput(e) {
    setMarkDownText(e.target.value);

    const md = new MarkdownIt();

    const renderedHTML = md.render(e.target.value);

    setRenderedHTML(renderedHTML);
  }

  function handleFileNameChange(e) {
    setFileName(e.target.value);
  }

  function saveHTML() {
    const blobFile = new Blob([renderedHTML], {
      type: 'text/html',
    });

    FileSaver.saveAs(blobFile, `${fileName}.md`);
  }

  function saveMarkdown() {
    const blobFile = new Blob([markdownText], {
      type: 'markdown',
    });

    FileSaver.saveAs(blobFile, `${fileName}.md`);
  }

  const renderModifiers = modifiers.map((modifier) => {
    const label = getModifierDisplayText(modifier);
    return (
      <Icon
        key={modifier}
      >
        {label}
      </Icon>
    );
  });

  return (
    <Wrapper>
      <InfoBox>
        <h4>Gerador de Markdown</h4>
        <p>Digite ou cole o texto no campo á direita para que a visualização seja gerada automaticamente no campo á esquerda. Utilize as formatações como achar necessário.</p>
      </InfoBox>
      <EditorWrapper>
        <MdBoxWrapper>
          <MdBox>
            <ContentLabel>Texto</ContentLabel>
            <ContentWrapper>
              <IconsWrapper>
                {renderModifiers}
              </IconsWrapper>
              <textarea
                value={markdownText}
                onChange={handleTextInput}
              />
            </ContentWrapper>
          </MdBox>
          <MdBox>
            <RenderedMdBox>
              <ContentLabel>Visualização</ContentLabel>
              <div
                style={{
                  alignSelf: 'flex-end', width: 'fit-content', maxWidth: '100%', height: '85%', lineBreak: 'anywhere', margin: '0 30',
                }}
                dangerouslySetInnerHTML={{ __html: renderedHTML }}
              />
            </RenderedMdBox>
          </MdBox>
        </MdBoxWrapper>
        <SaveFileWrapper>
          <Input
            label="Nome do arquivo"
            width="50%"
            handleChange={handleFileNameChange}
          />
          <Button onClick={saveMarkdown}>Salvar Markdown</Button>
          <Button onClick={saveHTML}>Salvar HTML</Button>
        </SaveFileWrapper>

      </EditorWrapper>
    </Wrapper>
  );
};

export default MarkdownGenerator;
