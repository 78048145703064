import styled from 'styled-components';

export const Content = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 0px -10px 15px 0px;
  max-width: 1060px;
  width: 100%;

  button {
    @media(max-width: 1020px) {
      margin-top: 30px;
    }
  }

`;
