/* eslint-disable no-unused-expressions */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import {
  Titulo, Header, TextStyled, MainContainer,
} from './styles';

import InputN from '../../../../../components/InputN';

import ButtonN from '../../../../../components/ButtonN';
import { useCheckpoint } from '../../../../../hooks/checkpoint';

import { useSteps } from '../../../../../hooks/steps';
import { setQualification } from '../../../../../services/endPointRegister/PJ/postQualification';
import { addPartner } from '../../../../../services/endPointRegister/PJ/updatePartners';
import { handleGetStepPj } from '../../../../../services/endPointRegister/PJ/statusPj';
import { useAuth } from '../../../../../hooks/auth';

const MainPartnerQualification = () => {
  const {
    step, stepsPj, exactStep, resetStep, nextStep, stepsPjEnum,
  } = useSteps();
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const history = useHistory();
  const [mainPartnerQualification, setMainPartnerQualification] = useState('');
  const [numberOfPartners, setNumberOfPartners] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();

  useEffect(() => {
    const getNumberOfPartners = async () => {
      const accountObject = JSON.parse(localStorage.getItem('@qesh:account')).id;
      const {
        res: {
          companie: {
            additional: { number_of_partners },
          },
        },  
      } = await handleGetStepPj(token, accountObject);
      setNumberOfPartners(parseInt(number_of_partners));
    };

//     const isTheRightPage = localStorage.getItem('@qesh:token')
// && (stepsPjEnum[localStorage.getItem('@qesh:step')] === 4 || sessionStorage.getItem('@qesh:step') === '4');
// if (isTheRightPage) {
//       exactStep(4);
//     } else {
//       resetStep();
//       history.replace('/transaction');
//     }
exactStep(4);
    getNumberOfPartners();
  }, [exactStep, history, resetStep, stepsPjEnum]);

  const handleAdvance = async () => {
    setLoading(true)
    const token = localStorage.getItem('@qesh:token');
    const userPj = localStorage.getItem('@qesh:accountPj');
    const { id: userPf } = JSON.parse(localStorage.getItem('@qesh:account'));
    const { document, email, name } = JSON.parse(localStorage.getItem('@qesh:user'));
    const partner = {
      qualification: mainPartnerQualification,
      document,
      email,
      name,
    };
    await addPartner(partner, token, userPj, userPf);
    const responseQualification = await setQualification(token, userPj, userPf);
    if (responseQualification.status) {
      setCheckpoint({ ...checkpoint, mainPartnerQualification });
      nextStep();
      numberOfPartners === 1
        ? history.push(`/register/${stepsPj[7]}`)
        : history.push(`/register/${stepsPj[step + 1]}`);
    }
    setLoading(false)
  };

  return (
    <MainContainer>
      <Header>
        <Titulo>Dados de Cadastro do sócio</Titulo>
        <TextStyled>
          Insira sua qualificação como sócio para prosseguirmos com o cadastros e convidar os outros sócios da
          empresa.
        </TextStyled>
        <InputN
          maxLength={100}
          id="qualificação"
          label="Qualificação do Sócio"
          placeholder="Informe sua qualificação"
          value={mainPartnerQualification}
          onChange={(e) => setMainPartnerQualification(e.target.value)}
        />
      </Header>
      <ButtonN type="primary" className="botao" onClick={handleAdvance} disabled={loading}>
        Convidar Sócios
      </ButtonN>
    </MainContainer>
  );
};

export default MainPartnerQualification;
