import styled from 'styled-components';

export const Content = styled.div`
 max-width: 999px;
 margin: 0 auto;

 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: ${(props) => props.color};
  font-weight: 500;
 }

 .ant-tabs-tab {
  &:hover {
   color: ${(props) => props.color};
  }
 }
`;

export const ContentTab = styled.div`
 max-height: 480px;
 min-height: 300px;
 overflow-y: auto;

 .ant-table-thead {
  display: none;
 }

 .ant-table-thead > tr > th,
 .ant-table-tbody > tr > td,
 .ant-table tfoot > tr > th,
 .ant-table tfoot > tr > td {
  padding: 8px 16px;
 }

 .ant-table-row:nth-child(odd) {
  background: ${(props) => props.backgroundcolor};
 }

 .ant-table-row:hover {
  background: inherit;
 }

 .ant-table-cell {
  color: #1a1818;
  font-weight: 500;
 }

 .ant-table-cell:first-child {
  .icon {
   position: relative;
   svg {
    color: ${(props) => props.color};
   }

   div {
    position: absolute;
    width: 1px;
    height: 25px;
    background: #d5d5d5;
    top: 16px;
    left: 6px;
   }
  }
 }
`;

export const HeaderTab = styled.div`
 padding-bottom: 10px;
 margin-bottom: 10px;
 border-bottom: 2px solid #f1f1f1;
 display: flex;
 justify-content: space-between;
 flex-wrap: wrap;
`;

export const DownloadButtonsContainer = styled.div`
 margin-right: 10px;
 display: flex;
 justify-content: space-between;
 min-width: 100px;
`;

export const ContainerListTab = styled.div`
 display: flex;
`;

export const ListItem = styled.div``;

export const FilterDate = styled.div`
 display: flex;
 align-items: center;

 section {
  display: flex;
  align-items: center;
 }

 svg {
  color: #46cdd4;
  margin-right: 5px;
  cursor: pointer;
 }

 span {
  font-weight: 600;
  padding: 0px 5px;
 }

 .DayPickerInput {
  input {
   border: 0;
   width: 82px;
   cursor: pointer;
  }
 }
`;
