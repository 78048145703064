import styled from 'styled-components';
import Select from 'react-select';

export const Content = styled.div`
  width: 100%;
  margin: 5 auto 5 auto;
  padding-bottom: 250px;

  div.divider{
    width: 100%;
    display: flex;
    align-items: center;
    
    span{
      font-size: 16px;
      font-weight: bold;
      color: #767676;
    }

    div {
      margin-left: 8px;
      flex: 1;
      height: 2px;
      width: 100%;
      background: #dbdcde;
    }
  }

  h2 {
    color: #121212;
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  h3 {
    color: #121212;
    font-size: 17px;
    margin-top: 35px;
    margin-bottom: 15px;
    font-weight: 600;
  }

  p {
    margin-bottom: 30px;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 36px;
`;

export const TabsContainer = styled.div`
  border-right: 1px solid #dbdcde;
  padding-right: 6px;
  height: 40px;
  display: flex;

  div {
    & + div {
      margin-left: 16px;
    }
  }
`;

export const Tab = styled.div`
  cursor: pointer;
  padding-right: 6px;
  border-bottom: ${(props) => (props.selected ? '2px solid #5eb0fc' : '0px')};
  color: ${(props) => (props.selected ? '#5eb0fc' : '#767676')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;

  svg {
    margin-right: 4px;
  }

  :hover {
    border-bottom: ${(props) => (props.selected ? '2px solid #5eb0fc' : '2px solid  #dbdcde')};
  }
`;

export const ReactSelect = styled(Select)`
  width: 190px;
  margin-left: 12px;
`;
