/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

function TokenLogin() {
  const { signIn } = useAuth();
  const params = useParams();
  const [canRedirect, setCanRedirect] = useState(false);

  const signInWithToken = async () => {
    await signIn({ document: null, password: null, userToken: params.token });
    setCanRedirect(true);
  };

  useEffect(() => {
    signInWithToken();
  }, []);

  if (canRedirect) { return (<Redirect to="/" />); }

  return (<div />);
}

export default TokenLogin;
