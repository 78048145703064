import React, {
  useEffect, useState, useMemo, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Table, Skeleton, Tooltip, Pagination,
} from 'antd';
import { AiOutlineFilePdf } from 'react-icons/ai';
import {
  addHours,
  endOfYear,
  format, isValid, parseISO, startOfYear,
} from 'date-fns';
import DayPicker from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { FaCalendarAlt } from 'react-icons/fa';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../../hooks/auth';
import chargesApi from '../../../../services/charges';
import { HandleFormatMoney } from '../../../../services/utils';
import {
  Content, TabsContainer, Tab, FilterContainer,
} from './styles';
import Button from '../../../../components/Button';
import { FilterDate } from '../../../Charge/styles';
import useOnClickOutside from '../../../../hooks/onClickOutside';
import { useSettings } from '../../../../hooks/settings';

const { Column } = Table;

const PartnerPage = ({ partnerId }) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [payments, setPayments] = useState();
  const [paymentType, setPaymentType] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [, setTotalItemsPage] = useState(0);
  const [currentTab, setCurrentTab] = useState('ALL');
  const history = useHistory();
  const { settings } = useSettings();
  const [dueAtStart, setDueAtStart] = useState(format(startOfYear(new Date()), 'yyyy-MM-dd'));
  const [dueAtEnd, setDueAtEnd] = useState(format(endOfYear(new Date()), 'yyyy-MM-dd'));
  const dueAtEndRef = useRef(null);
  const dueAtStartRef = useRef(null);
  const refDueAtStartCalendar = useRef();
  const refDueAtEndCalendar = useRef();
  useOnClickOutside(refDueAtStartCalendar, () => dueAtStartRef.current.handleInputBlur());
  useOnClickOutside(refDueAtEndCalendar, () => dueAtEndRef.current.handleInputBlur());
  const dueAtStartDate = useMemo(() => format(parseISO(dueAtStart), 'dd/MM/yyyy'), [dueAtStart]);
  const dueAtEndDate = useMemo(() => format(parseISO(dueAtEnd), 'dd/MM/yyyy'), [dueAtEnd]);
  const [paymentStart, setPaymentStart] = useState(format(startOfYear(new Date()), 'yyyy-MM-dd'));
  const [paymentEnd, setPaymentEnd] = useState(format(endOfYear(new Date()), 'yyyy-MM-dd'));
  const paymentEndRef = useRef(null);
  const paymentStartRef = useRef(null);
  const refPaymentStartCalendar = useRef();
  const refPaymentEndCalendar = useRef();
  useOnClickOutside(refPaymentStartCalendar, () => paymentStartRef.current.handleInputBlur());
  useOnClickOutside(refPaymentEndCalendar, () => paymentEndRef.current.handleInputBlur());
  const paymentStartDate = useMemo(() => format(parseISO(paymentStart), 'dd/MM/yyyy'), [paymentStart]);
  const paymentEndDate = useMemo(() => format(parseISO(paymentEnd), 'dd/MM/yyyy'), [paymentEnd]);

  const handleChangeDueAtDate = useCallback((day, type) => {
    if (isValid(day)) {
      const formatDay = format(day, 'yyyy-MM-dd');
      if (type === 'start') {
        setDueAtStart(formatDay);
      } else {
        setDueAtEnd(formatDay);
      }
    }
  }, []);

  const handleChangePaymentDate = useCallback((day, type) => {
    if (isValid(day)) {
      const formatDay = format(day, 'yyyy-MM-dd');
      if (type === 'start') {
        setPaymentStart(formatDay);
      } else {
        setPaymentEnd(formatDay);
      }
    }
  }, []);

  const getData = useCallback((page = 1) => {
    setTableLoading(true);

    let filters = { page: page - 1 };

    if (currentTab && currentTab !== 'ALL') {
      filters = { ...filters, status: currentTab };
    }

    if (paymentType && paymentType !== 'all') {
      filters = { ...filters, payment_type: paymentType };
    }

    filters = { ...filters, partner_id: partnerId };

    chargesApi.get(`nmpay/charges?dueAtStart=${dueAtStart}&dueAtEnd=${
      format(addHours(new Date(dueAtEnd), 3), 'yyyy-MM-dd HH:mm')}${
      currentTab === 'PAID' ? `&paymentStart=${
        paymentStart}&paymentEnd=${
        format(addHours(new Date(paymentEnd), 3), 'yyyy-MM-dd HH:mm')}` : ''}`, {
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setPayments(response.data);
      setLoading(false);
      setTableLoading(false);

      setCurrentPage(page ? page - 1 : 0);
      setTotalPages(response.data.total_pages + 1);
      setTotalItems(response.data.total_items);
      setTotalItemsPage(response.data.total_items_page);
    });
  }, [currentTab, paymentType, token, dueAtStart, dueAtEnd, paymentStart, paymentEnd, partnerId]);

  useEffect(() => {
    setTableLoading(true);
    getData(1);
  }, [getData]);

  const handleGoToBilletFile = useCallback((id) => {
    window.open(`https://qesh-storage.s3.amazonaws.com/billets/${id}/bill_file.pdf`);
  }, []);

  const getBilletUrl = useCallback((id) => `https://qesh-storage.s3.amazonaws.com/billets/${id}/bill_file.pdf`, []);

  const getPaymentMethod = useCallback((paymentTypee) => {
    if (paymentTypee === 'credit_card') return 'Cartão';

    if (paymentTypee === 'billet') return 'Boleto';

    if (paymentTypee === 'card_avulse') return 'Boleto avulso';

    return '-';
  }, []);

  const handleCancelCharge = useCallback(async (obj) => {
    await chargesApi.patch(`nmpay/charges/cancel/${obj.id}`);
    getData(1);
  }, [getData]);

  const formattedPayments = useMemo(() => payments?.items.map((payment) => ({
    id: payment.id,
    student_name: payment.student_name,
    description: payment.description,
    due_at: format(parseISO(payment.due_at), 'dd/MM/yyyy'),
    paid_at: payment.paid_at
      ? <span style={{ fontWeight: 'bold' }}>{format(parseISO(payment.paid_at), 'dd/MM/yyyy')}</span>
      : <span style={{ fontWeight: 'bold' }}>NÃO PAGO</span>,
    base_value: HandleFormatMoney(Number(payment.base_value)),
    current_value: HandleFormatMoney(Number(payment.current_value)),
    payment_type: getPaymentMethod(payment.payment_type),
    installments: payment.installments,
    options: (
      <div style={{
        display: 'flex', alignItems: 'center', width: 60, justifyContent: 'space-between',
      }}
      >
        {/* <Tooltip title="Deletar mensalidade">
          <Popconfirm
            placement="left"
            title="Deseja mesmo deletar essa mensalidade?"
            onConfirm={() => handleCancelCharge(payment)}
            okText="Sim"
            cancelText="Não"
          ><FaTrashAlt size={20} style={{ cursor: 'pointer' }} />
          </Popconfirm>
        </Tooltip> */}

        <Tooltip title="Visualizar boleto">
          <AiOutlineFilePdf
            onClick={() => handleGoToBilletFile(payment.billet_id)}
            size={24}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      </div>
    ),
  })),
  [handleGoToBilletFile, payments?.items, getPaymentMethod]);

  const generateSheet = useCallback(async () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'mensalidades';

    const sheetData = payments?.items?.map((payment) => (
      {
        PARCEIRO: payment.partner.name_1,
        ESTUDANTE: payment.student_name || '',
        EMAIL: payment.student_email || '',
        'DATA DO VENCIMENTO': format(parseISO(payment.due_at), 'dd/MM/yyyy'),
        'DATA DO PAGAMENTO': payment.paid_at ? format(parseISO(payment.paid_at), 'dd/MM/yyyy') : '',
        'VALOR BASE': payment.base_value,
        'VALOR PAGAMENTO': payment.current_value,
        'METODO DE PAGAMENTO': getPaymentMethod(payment.payment_type),
        PARCELAS: payment.installments || '',
        BOLETO: getBilletUrl(payment.billet_id),
      }));

    const ws = XLSX.utils.json_to_sheet(sheetData);
    const wb = { Sheets: { mensalidades: ws }, SheetNames: ['mensalidades'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }, [payments, getBilletUrl, getPaymentMethod]);

  return (
    <>

      <Content>
        <h1>Mensalidades</h1>
        <FilterContainer>
          <TabsContainer>
            <Tab
              onClick={() => {
                setCurrentTab('ALL');
              }}
              selected={currentTab === 'ALL'}
            >
              <span>Todos</span>
            </Tab>

            <Tab
              onClick={() => {
                setCurrentTab('PAID');
              }}
              selected={currentTab === 'PAID'}
            >
              <span>Pagos</span>
            </Tab>

            <Tab
              onClick={() => {
                setCurrentTab('PENDING');
              }}
              selected={currentTab === 'PENDING'}
            >
              <span>Pendentes</span>
            </Tab>

            <Tab
              onClick={() => {
                setCurrentTab('OVERDUE');
              }}
              selected={currentTab === 'OVERDUE'}
            >
              <span>Atrasados</span>
            </Tab>
          </TabsContainer>

          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => history.push('/educational-payments/import-charges')}
              style={{ marginLeft: '8px' }}
            ><span>Gerar mensalidades</span>
            </Button>
            <Tooltip title="Exportar excel dessa tabela">
              <Button style={{ marginLeft: '12px' }} onClick={generateSheet}>
                <span>Exportar excel</span>
              </Button>
            </Tooltip>
          </div>
        </FilterContainer>

        {loading ? (
          <>
            <Skeleton active title="Depósito" loading={loading} paragraph={2} rows={2} />
            <Skeleton active title="Depósito" loading={loading} paragraph={2} rows={2} />
            <Skeleton active title="Depósito" loading={loading} paragraph={2} rows={2} />
          </>
        ) : (
          <>
            <div style={{ width: '100%', display: 'flex' }}>
              <FilterDate style={{ marginRight: 50 }}>
                <span>Vencimento</span>
                <span>de:</span>
                <section ref={refDueAtStartCalendar}>
                  <DayPicker
                    ref={dueAtStartRef}
                    dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                    format="dd/MM/yyyy"
                    formatDate={(date) => format(date, 'dd/MM/yyyy')}
                    value={dueAtStartDate}
                    onDayChange={(day) => handleChangeDueAtDate(day, 'start')}
                  />
                  <FaCalendarAlt
                    color={settings.colors.primary}
                    onClick={() => dueAtStartRef.current.handleInputClick()}
                  />
                </section>
                <span>até:</span>
                <section ref={refDueAtEndCalendar}>
                  <DayPicker
                    ref={dueAtEndRef}
                    dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                    format="dd/MM/yyyy"
                    formatDate={(date) => format(date, 'dd/MM/yyyy')}
                    onDayChange={(day) => handleChangeDueAtDate(day, 'end')}
                    value={dueAtEndDate}
                  />
                  <FaCalendarAlt
                    color={settings.colors.primary}
                    onClick={() => dueAtEndRef.current.handleInputClick()}
                  />
                </section>
              </FilterDate>
              {currentTab === 'PAID' && (
              <FilterDate style={{ marginRight: 50 }}>
                <span>Pagamento</span>
                <span>de:</span>
                <section ref={refPaymentStartCalendar}>
                  <DayPicker
                    ref={paymentStartRef}
                    dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                    format="dd/MM/yyyy"
                    formatDate={(date) => format(date, 'dd/MM/yyyy')}
                    value={paymentStartDate}
                    onDayChange={(day) => handleChangePaymentDate(day, 'start')}
                  />
                  <FaCalendarAlt
                    color={settings.colors.primary}
                    onClick={() => paymentStartRef.current.handleInputClick()}
                  />
                </section>
                <span>até:</span>
                <section ref={refPaymentEndCalendar}>
                  <DayPicker
                    ref={paymentEndRef}
                    dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                    format="dd/MM/yyyy"
                    formatDate={(date) => format(date, 'dd/MM/yyyy')}
                    onDayChange={(day) => handleChangePaymentDate(day, 'end')}
                    value={paymentEndDate}
                  />
                  <FaCalendarAlt
                    color={settings.colors.primary}
                    onClick={() => paymentEndRef.current.handleInputClick()}
                  />
                </section>
              </FilterDate>
              )}
            </div>
            <Table
              dataSource={formattedPayments}
              pagination={false}
              rowKey="id"
              loading={tableLoading}
              locale={{
                emptyText: 'Nenhum pagamento para exibir.',
              }}
              scroll={{ x: true }}
            >
              <Column
                title="Estudante"
                dataIndex="student_name"
                key="student_name"
                responsive={['xs', 'sm', 'md', 'xl']}
              />

              <Column
                title="Descrição"
                dataIndex="description"
                key="description"
                responsive={['xs', 'sm', 'md', 'xl']}
              />

              <Column
                title="Data de vencimento"
                dataIndex="due_at"
                key="due_at"
                responsive={['xs', 'sm', 'md', 'xl']}
              />

              <Column
                title="Data de pagamento"
                dataIndex="paid_at"
                key="paid_at"
                responsive={['xs', 'sm', 'md', 'xl']}
              />

              <Column
                title="Valor base"
                dataIndex="base_value"
                key="base_value"
                responsive={['xs', 'sm', 'md', 'xl']}
              />

              <Column
                title="Valor de pagamento"
                dataIndex="current_value"
                key="current_value"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Método de pagamento"
                dataIndex="payment_type"
                key="payment_type"
                responsive={['xs', 'sm', 'md', 'xl']}
              />
              <Column
                title="Parcelas"
                dataIndex="installments"
                key="installments"
                responsive={['xs', 'sm', 'md', 'xl']}
              />

              <Column
                title=""
                dataIndex="options"
                key="options"
                responsive={['xs', 'sm', 'md', 'xl']}
              />

            </Table>
            <Pagination
              current={currentPage + 1}
              onChange={getData}
              total={totalItems}
              pageSize={20}
              style={{ alignSelf: 'flex-end', marginTop: '6px' }}
            />
          </>
        )}
      </Content>
    </>

  );
};

export default PartnerPage;

PartnerPage.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
