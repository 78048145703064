import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Header = styled.div`
  background: ${(props) => props.backgroundcolor};
  color: #F9F9F9;
  text-align: center;
  padding: 8px 0;
  font-weight: bold;
  border-radius: 8px;
`;

export const Content = styled.div`

  padding: 24px;

  h1 {
    text-align: center; 
    color: #121212;
    font-weight: 500 !important;
    font-size: 18px;
    margin-bottom: 30px;
  }

  form > div {
    display: flex;
    justify-content: center;
  }

  input {
    font-size: 36px;
    text-align: center;
    margin-right: 10px;
    width: 59px;
    height: 75px;
    border: 1px solid #707070;
    border-radius: 8px;
  }
`;
