import React, {
  useEffect, useCallback, useState,
} from 'react';
import {
  Table,
  Skeleton,
  Tooltip,
} from 'antd';
import Column from 'antd/lib/table/Column';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import { FaPenAlt } from 'react-icons/fa';
import Button from '../../../../../components/Button';
import chargesApi from '../../../../../services/charges';
import Loading from '../../../../../components/Loading';
import { Header } from './styles';
import { useAuth } from '../../../../../hooks/auth';
import { NM_DOCUMENT } from '../..';

const Partners = () => {
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState(undefined);
  const history = useHistory();
  const { token, user } = useAuth();

//  useEffect(() => {
//    if (!GOD_DOCUMENTS.includes(user.document) && user.document !== NM_DOCUMENT) {
//      history.push('/educational-payments');
//    }
//  }, [user, history]);

  const loadPartners = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await chargesApi.get('nmpay/partners', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setPartners(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    loadPartners();
  }, [loadPartners]);

  return (
    <>
      <Loading hasLoading={loading} />
      <Tooltip title="voltar">
        <FiArrowLeft size={24} onClick={() => { history.push('/educational-payments'); }} />
      </Tooltip>
      <Header>
        <h1>Parceiros</h1>

        <Button
          type="button"
          onClick={() => history.push('/educational-payments/partners/create')}
        >Criar novo parceiro
        </Button>
      </Header>

      <section>
        {loading ? (
          <Skeleton />
        ) : (
          <Table
            dataSource={partners}
            pagination={false}
            loading={false}
            locale={{ emptyText: 'Nenhum parceiro foi cadastrado.' }}
            scroll={{ x: true }}
          >
            <Column
              title="Nome"
              dataIndex="name_1"
              key="name_1"
              responsive={['xs', 'sm', 'md', 'xl']}
            />
            <Column
              title="Documento(CNPJ)"
              dataIndex="document"
              key="document"
              responsive={['xs', 'sm', 'md', 'xl']}
            />

            <Column
              title=""
              dataIndex="edit"
              key="edit"
              responsive={['xs', 'sm', 'md', 'xl']}
              render={(_text, record) => (
                <FaPenAlt
                  onClick={() => history.push(`/educational-payments/partners/${record.document}`)}
                  size={15}
                  style={{ cursor: 'pointer' }}
                />
              )}
            />
          </Table>
        )}
      </section>
    </>
  );
};

export default Partners;
