/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Checkbox, message, Modal, Spin,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import ButtonN from '../../../../../components/ButtonN';
import InputN from '../../../../../components/InputN';
import { SubTitle, Title, PepBoxStyled } from './styles';
import { useSteps } from '../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import { annualRevenue, incomeMonth, professionsList } from '../../../../../utilitys/annualRevenue';
import { educationLevel } from '../../../../../utilitys/educationLevel';
import { maritalStatus } from '../../../../../utilitys/maritalStatus';
import { ModalStyle } from '../../../../../components/CnpjInfo/styles';
import { handleAdicionalData } from '../../../../../services/endPointRegister/adicionalData';

const AdditionalData = () => {
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const { t } = useTranslation();
  const {
    nextStep, step, stepsPf, stepsPj, exactStep,
  } = useSteps();
  const history = useHistory();


  const [modalPpe, setModalPpe] = useState(false);
  const [loading, setLoading] = useState(false);

  const [personData, setPersonData] = useState({
    education: '',
    occupation: '',
    monthly_income: '',
    patrimony: '',
    relationship: '',
    isPEP: false,
  });

  useEffect(() => {
    if (
      checkpoint?.addressData !== undefined
   || (localStorage.getItem('@qesh:token') && sessionStorage.getItem('@qesh:step') === '8')
    ) {
      exactStep(8);
    }
  }, []);

  const handleAdvance = async () => {
    setLoading(true);
    setCheckpoint({ ...checkpoint, educationData: personData });
    let error = false;
    try {
      const requisition = await handleAdicionalData(personData);

      error = requisition;
    } catch (err) {
      setLoading(false);
      message.error('Ops, parece que ocorreu um erro. Tente novamente.', 4);
    } finally {
      if (!error) {
        message.error('Ops, parece que ocorreu um erro. Tente novamente.', 4);
        setLoading(false);
      } else if (error) {
        setLoading(false);
        if (checkpoint.cpfCnpj?.length === 11) {
          nextStep();
          if (stepsPf.length >= step + 1) {
            history.push(`/register/${stepsPf[step + 1]}`);
          } else {
            history.push(`/register/${stepsPf[step]}`);
          }
        }
        if (checkpoint.cpfCnpj?.length === 14) {
          nextStep();
          if (stepsPj.length >= step + 1) {
            history.push(`/register/${stepsPj[step + 1]}`);
          } else {
            history.push(`/register/${stepsPj[step]}`);
          }
        }
      }
    }
  };

  const modalContentPpe = (
    <ModalStyle>
      <header>{t('Pessoa Politicamente Exposta (PPE)')}</header>
      <p style={{ textAlign: 'justify', marginBottom: '1.5rem' }}>
        {t(
          'Ser uma Pessoa Politicamente Exposta significa você, pessoas de seu relacionamento e familiares têm ou tiveram, nos últimos 5 (cinco) anos, no Brasil ou em outros países, cargos, empregos ou funções públicas relevantes (Circular 3.461, de 24/07/2009).',
        )}
      </p>
      <p style={{ textAlign: 'justify', marginBottom: '1.8rem', fontWeight: 'bold' }}>
        {t('Ser uma Pessoa Politicamente Exposta não te impede de abrir uma conta aqui com a gente.')}
      </p>
      <ButtonN type="primary" onClick={() => setModalPpe(false)}>
        {t('Entendido')}
      </ButtonN>
    </ModalStyle>
  );

  return (
    <>
      <Title>{t('Para fecharmos essa parte!')}</Title>
      <SubTitle>{t('Precisamos dessas informações para conhecer melhor você!')}</SubTitle>
      <InputN.SelectandSearchModal
        id="escolaridade"
        label={t('Escolaridade')}
        placeholder={t('Insira sua escolaridade')}
        value={personData.education ? `${personData.education[0].name}` : ''}
        onChange={(value) => {
          setPersonData({ ...personData, education: value });
        }}
        list={educationLevel}
        needSearchInput
        title={(
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              width: '100%',
              margin: '0',
              fontWeight: '700',
              color: 'var(--label)',
              height: '4rem',
              padding: '0 3rem',
            }}
          >
            Escolaridade
          </p>
   )}
      />
      <InputN.SelectandSearchModal
        id="profissao"
        label={t('Profissão')}
        placeholder={t('Insira sua profissão')}
        value={personData.occupation ? `${personData.occupation[0].name}` : ''}
        onChange={(value) => {
          setPersonData({ ...personData, occupation: value });
        }}
        list={professionsList}
        needSearchInput
        title={(
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              width: '100%',
              margin: '0',
              fontWeight: '700',
              color: 'var(--label)',
              height: '2rem',
              padding: '0 2rem',
            }}
          >
            {t('Profissão')}
          </p>
   )}
      />
      <InputN.SelectandSearchModal
        id="monthlyRevenue"
        label={t('Renda Mensal')}
        placeholder={t('Insira sua renda mensal')}
        value={personData.monthly_income ? `${personData.monthly_income[0].name}` : ''}
        onChange={(value) => setPersonData({ ...personData, monthly_income: value })}
        list={incomeMonth()}
        needSearchInput={false}
        title={(
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              width: '100%',
              margin: '0',
              fontWeight: '700',
              color: 'var(--label)',
              height: '4rem',
              padding: '0 3rem',
            }}
          >
            Renda Mensal
          </p>
   )}
      />
      <InputN.SelectandSearchModal
        id="patrimonio"
        label={t('Patrimônio')}
        placeholder={t('Insira seu patrimônio')}
        value={personData.patrimony ? `${personData.patrimony[0].name}` : ''}
        onChange={(value) => {
          setPersonData({ ...personData, patrimony: value });
        }}
        list={annualRevenue}
        needSearchInput={false}
        title={(
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              width: '100%',
              margin: '0',
              fontWeight: '700',
              color: 'var(--label)',
              height: '4rem',
              padding: '0 3rem',
            }}
          >
            Patrimônio
          </p>
   )}
        subTitle={(
          <p style={{ fontSize: '0.875rem', padding: '0 3rem' }}>
            {t('De acordo com o Banco Central, precisamos saber o total do seu patrimônio')}
          </p>
   )}
      />
      <InputN.SelectandSearchModal
        id="estadoCivil"
        label={t('Estado Civil')}
        placeholder={t('Insira seu estado civil')}
        value={personData.relationship ? `${personData.relationship[0].name}` : ''}
        onChange={(value) => {
          setPersonData({ ...personData, relationship: value });
        }}
        list={maritalStatus}
        needSearchInput={false}
        title={(
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              width: '100%',
              margin: '0',
              fontWeight: '700',
              color: 'var(--label)',
              height: '2rem',
              padding: '0 3rem',
            }}
          >
            {t('Estado Civil')}
          </p>
   )}
      />
      <PepBoxStyled>
        <Checkbox
          checked={personData.isPEP}
          onChange={(e) => setPersonData({ ...personData, isPEP: e.target.checked })}
          style={{ display: 'flex', alignItems: 'flex-start' }}
        >
          <p>{t('Pessoa Politicamente Exposta (PPE) ')}</p>
        </Checkbox>

        <FontAwesomeIcon
          icon={faQuestionCircle}
          style={{ color: 'var(--primary)' }}
          onClick={() => {
            setModalPpe(true);
          }}
        />
      </PepBoxStyled>
      <ButtonN
        disabled={
     personData.education === ''
     || personData.relationship === ''
     || personData.patrimony === ''
     || personData.occupation === ''
     || personData.monthly_income === ''
    }
        type="primary"
        className="botao"
        onClick={() => handleAdvance()}
        style={{ margin: '0.7rem' }}
        loading={loading}
        icon={loading && <Spin indicator={<LoadingOutlined />} />}
      >
        {!loading && 'Continuar'}
      </ButtonN>
      <Modal
        visible={modalPpe}
        footer={null}
        closable={false}
        destroyOnClose
        focusTriggerAfterClose={false}
        onCancel={() => {
          setModalPpe(false);
        }}
        bodyStyle={{ padding: '2.5rem' }}
      >
        {modalContentPpe}
      </Modal>
    </>
  );
};

export default AdditionalData;
