import Chrome from '@uiw/react-color-chrome';
import React from 'react';
import { useShop } from '../../hooks/shop';

import { ColorSelection, Label, Wrapper } from './styles';

const ColorInput = ({
  label = 'Selecione uma cor',
  value,
  name,
}) => {
  const { setShopInfo } = useShop();

  const [isActive, setIsActive] = React.useState(false);

  const ref = React.useRef(null);

  return (
    <Wrapper>
      <Label>{label}</Label>
      <ColorSelection
        color={value}
        onClick={() => setIsActive(!isActive)}
      >
        <span>
          HEX:
          {value}
        </span>
      </ColorSelection>
      <Chrome
        ref={ref}
        color={value}
        style={{
          display: `${isActive ? 'flex' : 'none'}`,
          position: 'absolute',
          zIndex: 999,
          top: '100%',
          right: 0,
        }}
        onChange={(color) => setShopInfo((prevValue) => ({ ...prevValue, [name]: color.hex }))}

      />
    </Wrapper>
  );
};

export default ColorInput;
