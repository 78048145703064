import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faIdBadge, faCompress } from '@fortawesome/free-solid-svg-icons';
import {
  Container, Title, Text, Tips,
} from './styles';

const Documents = () => (
  <Container>
    <Title>Confira algumas dicas para você tirar uma boa foto</Title>
    <Tips>
      <Text>
        <FontAwesomeIcon icon={faSun} className="text-icon" />
        Encontre um lugar com boa iluminação
      </Text>
      <Text>
        <FontAwesomeIcon icon={faIdBadge} className="text-icon" />
        Evite reflexos. Tire o documento do plástico
      </Text>
      <Text>
        <FontAwesomeIcon icon={faCompress} className="text-icon" />
        Centralize o documento no modelo indicado.
      </Text>
    </Tips>
  </Container>
);

export default Documents;
