import styled from 'styled-components';
import backGroundImage from '../../assets/female-blogger.png';

export const LoginSection = styled.section`
 display: flex;
 flex-flow: column nowrap;
 justify-content: space-around;
 height: 100%;
 width: 25rem;
 max-height: 800px;
`;

export const Container = styled.div`
 --url-bg-image: url(${({ settings }) => settings.ib_background_img
  || backGroundImage});

 display: flex;
 position: relative;
 background: ${({ showGradient }) => (showGradient
    ? 'linear-gradient(0deg, #191a1ad9 19.09%, rgba(2, 3, 3, 0) 68.1%)'
    : 'none')}, var(--url-bg-image);
 background-size: cover;
 background-repeat: no-repeat;
 align-items: flex-start;
 justify-content: center;
 width: 100vw;
 height: 100%;
 padding: 2% 8% 0 0;

 @media (min-width: 48.1rem) {
  justify-content: flex-end;
 }

 @media (min-width: 160rem) {
  width: 100vw;
  height: 150vh;
  section {
   width: 30%;
  }
 }

 section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
 }

`;

export const Content = styled.div`
 display: flex;
 justify-content: space-around;
 background-color: var(--white);
 box-shadow: 0 0 0.125rem 0 var(--primary);
 border-radius: 1rem;
 flex-direction: column;
 align-items: center;
 padding: 0.5rem 1.5rem 0.5rem 1.5rem;
 width: 100%;
 height: 85%;
 ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
 }
`;

export const SpanLink = styled.span`
 color: var(--primary);
 font-size: 1rem;
 cursor: pointer;
`;
export const HelpSection = styled.div`
 display: flex;
 flex-flow: column nowrap;
 gap: 1rem;
`;

export const InputContainer = styled.div`
 display: flex;
 flex-flow: column nowrap;
 gap: 0.5rem;
`;

export const HeaderContainer = styled.div`
 display: flex;
 flex-flow: column nowrap;
 gap: 0.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 80%;
  gap: 1rem;
`;

export const IconButton = styled.img`
  max-width: 100%;
  height: auto;
`;
