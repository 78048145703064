import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function Loading({ hasLoading }) {
  return (
    <Container hasLoading={hasLoading}>
      <section />
      {/* <img
        src="https://app.qesh.ai/static/media/qesh-logo-color-vtc.85e4545b.svg"
        alt="Qesh"
      /> */}
      <span />
      <div />
    </Container>
  );
}

Loading.propTypes = {
  hasLoading: PropTypes.bool,
};

Loading.defaultProps = {
  hasLoading: false,
};

export default Loading;
