import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'moment/locale/pt-br';
import 'react-day-picker/lib/style.css';

import { Divider, message as messageAntd } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  MainContainer,
  Content,
  TitleHeader,
  CenterContainer,
  ContainerButtom,
  HeaderArea,
  TitleArea,
  Title,
  ContentBody,
  LoadingContainer,
} from './styles';
import { useSettings } from '../../hooks/settings';
import ModernButton from '../../components/ModernButton';
import { useAuth } from '../../hooks/auth';
import { InputFourDigitsPassword } from '../../components/InputFourDigitsPassword';
import { Password4DigitsService } from '../../services/password4Digits';

function ChangeAccount() {
  const account = localStorage.getItem('@qesh:account_id');
  const token = localStorage.getItem('@qesh:token');
  const [loadingInfo] = useState(false);

  const { t } = useTranslation();
  const { settings } = useSettings();
  const { selectAccount } = useAuth();
  const history = useHistory();

  const [inputs, setInputs] = useState({
    ssn1: '',
    ssn2: '',
    ssn3: '',
    ssn4: '',
  });
  const password = inputs.ssn1 + inputs.ssn2 + inputs.ssn3 + inputs.ssn4;

  const handleClick = useCallback(async () => {
    const result = await Password4DigitsService(token, account, password);
    if (!result) {
      messageAntd.error('Senha inválida.', 3);
      // eslint-disable-next-line no-useless-return
      return;
    }
    await selectAccount({ id: account });
    messageAntd.success('Troca de conta concluída com sucesso.', 1);
    history.push('/');
  }, [account, password, selectAccount, token, history]);
  return (
    <MainContainer>
      {loadingInfo && (
      <LoadingContainer>
        <LoadingOutlined style={{ fontSize: 80, color: 'var(--primary)' }} spin />
      </LoadingContainer>
      )}
      {!loadingInfo && (
      <>
        <Content>
          <HeaderArea>
            <TitleHeader>{t('Trocar de conta')}</TitleHeader>
          </HeaderArea>
        </Content>
        <Divider style={{ borderColor: settings.colors.primary, marginBottom: 15 }} />
        <CenterContainer>
          <TitleArea>
            <Title>{t('Digite sua senha de 4 digitos para finalizar')}</Title>
          </TitleArea>
          <ContentBody>
            <InputFourDigitsPassword inputs={inputs} setInputs={setInputs} />
          </ContentBody>
        </CenterContainer>
        <ContainerButtom>
          <ModernButton type="submit" onClick={() => handleClick()}>
            {t('Continuar')}
          </ModernButton>
        </ContainerButtom>
      </>
      )}
    </MainContainer>
  );
}

export default ChangeAccount;
