import api from '../../api';

/**
 * request to recover all the registered partners
 * @property {string} token - token bearer login account user PF
 * @property {string} account - account_id to account the user PF
 * @returns {{status: boolean, res: {response or error}}}
 */
export const handleGetPartners = (userPf, accountPj, token) => {
  const headers = {
    user: accountPj ,
    account: userPf,
  };

  return api
    .get('onboarding/companies/partners', { headers: {
      ...headers
    } })
    .then((response) => ({ status: true, res: response.data }))
    .catch((error) => {
      console.error(error.message || error);
      return { status: false, res: error.response?.data };
    });
};
