import React, { useEffect, useMemo, useState } from 'react';
import StudentPage from './StudentPage';
import PartnerPage from './PartnerPage';
import NmPage from './NmPage';
import chargesApi from '../../../services/charges';
import { Container } from './styles';
import { useAuth } from '../../../hooks/auth';

export const NM_DOCUMENTS = ['22466925000165', '50762311649'];

const EducationalPayments = () => {
  const { user, token } = useAuth();
  const [pageContent, setPageContent] = useState();
  const [partnerId, setPartnerId] = useState();

  useEffect(() => {
    if (NM_DOCUMENTS.includes(user.document) ) {
      setPageContent('NmPage');
    } else {
      chargesApi.get(`nmpay/partners/${user.document}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setPartnerId(response.data.id);
        setPageContent('PartnerPage');
      }).catch(() => {
        setPageContent('StudentPage');
      });
    }
  }, [user.document, token]);

  const Content = useMemo(() => {
    if (pageContent === 'StudentPage') {
      return <StudentPage />;
    }

    if (pageContent === 'PartnerPage') {
      return <PartnerPage partnerId={partnerId} />;
    }

    if (pageContent === 'NmPage') {
      return <NmPage />;
    }

    return (<></>);
  }, [pageContent, partnerId]);

  return (
    <Container>
      {Content}
    </Container>
  );
};

export default EducationalPayments;
