import styled from 'styled-components';

export const ResolvedAssociate = styled.span`
 display: flex;
 width: 100%;
 font-weight: bold;
 justify-content: space-between;
 border-bottom: 0.1rem solid var(--primary);
 align-items: center;
 margin-bottom: 1rem;
 padding: 0.5rem;
`;
export const List = styled.div`
 font-size: 0.8em;
`;
export const Associate = styled.span`
 display: flex;
 width: 100%;
 font-weight: bold;
 justify-content: space-between;
 border-bottom: 0.1rem solid var(--primary);
 align-items: center;
 margin-bottom: 1rem;
 padding: 0.5rem;
`;
export const Text = styled.div`
 display: flex;
 flex-direction: column;
`;
export const Title = styled.h3`
 display: flex;
 color: var(--label);
`;
export const Subtitle = styled.span`
 color: grey;
 text-align: center;
`;
export const DataInfo = styled.span`
 font-weight: lighter;
`;
export const ButtonContainer = styled.div`
 display: flex;
 flex-flow: row nowrap;
 width: 100%;
`;
export const SectionContainer = styled.section`
 display: flex;
 flex-flow: column;
 gap: 100px;
`;
