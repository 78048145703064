import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';
import { useSettings } from '../../hooks/settings';

function Button(props) {
  const { settings } = useSettings();
  if (settings && settings.colors) {
    return (
      <Container
        textcolor={settings.colors.text}
        backgroundcolor={settings.colors.primary}
        shadowcolor={settings.colors.primary}
        {...props}
      >{props.children}
      </Container>
    );
  }
  return (
    <Container backgroundcolor="white" {...props}>{props.children}</Container>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  right: PropTypes.bool,
  center: PropTypes.bool,
};

Button.defaultProps = {
  right: false,
  center: false,
};

export default Button;
