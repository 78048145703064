import { React } from 'react';
import { Redirect } from 'react-router-dom';
import LowerLeftMessage from '../../components/LowerLeftMessage';
import { useRefreshPj } from '../../hooks/refreshPj';
import {
  Content, ImgContainer, Logo, MainContainer,
} from './styles';
import Cam from './Components/Cam';
import DocumentsCam from './Components/DocumentsCam';
import { BasicData } from './BasicData';
import { Additional } from './Additional';
import { Contact } from './Contact';
import { Phone } from './Contact/Phone';
import { Email } from './Contact/Email';
import { Code } from './Contact/Code';
import { Address } from './Address';
import { FormAddress } from './Address/Form';
import { Validation } from './Validation';
import Selfie from './Validation/Selfie';
import Documents from './Validation/Documents';
import { UpdateDone } from './UpdateDone';
import { useSettings } from '../../hooks/settings';
import Partners from './Partners';
import RegisterPartner from './RegisterPartner';
import { PartnerInfo } from './PartnerInfo';
import UpdatePartner from './UpdatePartner';

function RefreshDataPJ() {
  const { step, token } = useRefreshPj();
  const { settings } = useSettings();
  const refreshSteps = {
    basicData: <BasicData />,
    additional: <Additional />,
    contact: <Contact />,
    phone: <Phone />,
    email: <Email />,
    codePhone: <Code type="phone" />,
    codeEmail: <Code type="email" />,
    address: <Address />,
    partnerInfo: <PartnerInfo />,
    partners: <Partners />,
    registerPartner: <RegisterPartner />,
    updatePartner: <UpdatePartner />,
    formAddress: <FormAddress />,
    validation: <Validation />,
    selfie: <Selfie />,
    documents: <Documents />,
    done: <UpdateDone />,
  };
  const getCurrentStep = () => refreshSteps[step];
  return (
    <MainContainer settings={settings}>
      <ImgContainer
        settings={settings}
        showGradient={settings.name !== 'nmpay'}
      />
      <Content>
        <Logo src={settings?.small_logo} />
        {getCurrentStep()}

      </Content>

      {step === 'selfie' && <Cam />}
      {step === 'documents' && <DocumentsCam />}
      {!['selfie', 'documents'].includes(step) && <LowerLeftMessage />}
    </MainContainer>
  );
}

export default RefreshDataPJ;
