import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 250px;
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
`;
