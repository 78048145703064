/* eslint-disable react/prop-types */
/* eslint-disable new-cap */
import React from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { format, parseISO } from 'date-fns';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import * as styles from './styles';
import { HandleFormatMoney } from '../../../services/utils';
import { formatAccount, formatBranch } from '../Receipt/utils';
import { useAuth } from '../../../hooks/auth';

const GenericPdfDownloader = ({ rootElementId, downloadFileName }) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId);
    const a4Width = 210;
    const a4Height = 297;
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: [a4Width, a4Height],
    });

    html2canvas(input).then((canvas) => {
      const imgWidth = a4Width;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let position = 0;
      let heightLeft = imgHeight;

      while (heightLeft > 0) {
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);

        if (heightLeft - a4Height > 0) {
          pdf.addPage();
        }

        position -= a4Height;
        heightLeft -= a4Height;
      }

      pdf.save(`${downloadFileName}.pdf`);
    });
  };

  return (
    <styles.PageHeader>
      <styles.Button onClick={downloadPdfDocument}>Baixar PDF</styles.Button>
    </styles.PageHeader>
  );
};

function ExtractPdf() {
  const { account } = useAuth();
  const today = format(new Date(), 'dd/MM/yyyy');
  const formData = JSON.parse(localStorage.getItem('pdfData'));
  const extracts = formData.transactions;
  return (
    <styles.Body>
      {formData ? (
        <styles.ContentTab>
          <GenericPdfDownloader
            downloadFileName={`extract-from-${formData.start}-to-${formData.end}`}
            rootElementId="testId"
          />

          <styles.Main id="testId">
            <styles.Header>
              <styles.RightText>
                <styles.HeaderSpan>Documento: {account.user.document}</styles.HeaderSpan>
                <styles.HeaderSpan>Banco: {account.bank_code} / Agência: {formatBranch(account.bank_branch)}</styles.HeaderSpan>
                <styles.HeaderSpan>Conta: {formatAccount(account.bank_account)}</styles.HeaderSpan>
                <styles.HeaderSpan>Período de {format(parseISO(formData.start), 'dd/MM/yyyy')} a {format(parseISO(formData.end), 'dd/MM/yyyy')}</styles.HeaderSpan>
              </styles.RightText>
              <styles.LeftText>
                <styles.Title>{account.user.bank.name}</styles.Title>
                <styles.HeaderSpan>Extrato gerado em {today}</styles.HeaderSpan>
                <styles.HeaderSpan>Saldo disponível: {HandleFormatMoney(Number(account.balance.balance_available))}</styles.HeaderSpan>
              </styles.LeftText>
            </styles.Header>
            <styles.Divider />
            <styles.Row>
              <styles.SectionSpan>Data</styles.SectionSpan>
              <styles.SectionSpan>Descrição</styles.SectionSpan>
              <styles.SectionSpan>Valor</styles.SectionSpan>
              <styles.SectionSpan>Saldo após transação</styles.SectionSpan>
            </styles.Row>
            <styles.Divider />
            {extracts.length < 0 ? (
              <div>
                ------
              </div>
            ) : extracts.map((extract) => (
              <div key={extract.id}>
                <styles.SectionRow>
                  <styles.SectionTitle>
                    <span>{ format(parseISO(extract.date), 'dd/MM/yyyy')}</span>
                    <styles.SectionSpan>{format(parseISO(extract.date), 'HH:mm')}</styles.SectionSpan>
                  </styles.SectionTitle>
                  <styles.SectionSpan>{extract.description}</styles.SectionSpan>
                  {extract.credit
                    ? (
                      <styles.AmountPositive>
                        {extract.credit ? '+' : '-'}{HandleFormatMoney(extract.amount)}
                      </styles.AmountPositive>
                    )
                    : (
                      <styles.AmountNegative>
                        {extract.credit ? '+' : '-'}{HandleFormatMoney(extract.amount)}
                      </styles.AmountNegative>
                    )}
                  <styles.SectionSpan>{HandleFormatMoney(extract.balance_available)}</styles.SectionSpan>
                </styles.SectionRow>
                <styles.Divider />
              </div>
            ))}
          </styles.Main>

        </styles.ContentTab>
      ) : <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#121212' }} spin />} />}
    </styles.Body>
  );
}

export default ExtractPdf;
