import api from '../../api';

/**
 * request type patch  to register new partner=
 * @property {string} token - token bearer login account user PF
 * @property {string} user - O user is o id user the account PJ
 * @property {string} account - account_id to account the user PF
 * @returns {{status: boolean, res: {response or error}}}
 */
export const acceptInvitation = async (token, userPj, accountPf) => {
  const headers = {
    user: userPj,
    account: accountPf,
  };
  return api
    .post('onboarding/companies/account', {}, { headers })
    .then((response) => response.data)
    .catch((err) => console.log('accepInvitation: ', err?.response?.data));
};

export const declineInvitation = async (token, userPj, accountPf) => {
  const headers = {
    user: userPj,
    account: accountPf,
  };
  return api
    .post('onboarding/companies/account/reject', {}, { headers })
    .then((response) => response.data)
    .catch((err) => console.log('declineInvitation error: ', err.response.data));
};
