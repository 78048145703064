import styled from 'styled-components';

export const Wrapper = styled.div`
  width:14px;
  height:14px;
  border-radius:50%;
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:${(props) => props.color};

  &:hover{
    div {
      visibility: visible;
    }
  }
`;

export const HelperModal = styled.div`
  padding: 10px;
  width:150px;
  max-height:250px;
  background-color:${(props) => props.color};
  border-radius:5px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  gap:5px;
  position:absolute;
  top:0;
  left:200%;
  color: #FFFFFF;
  z-index:998;
  visibility: hidden;

  h2 {
    margin:0;
    padding:0;
    font-weight:bold;
    font-size:13px;
    color: #FFFFFF;
    text-align:center;
  }

  h3 {
    margin:0;
    padding:0;
    font-size:12px;
    color: #FFFFFF;
    font-weight:normal;
  }
`;

export const DescriptionContainer = styled.div`
  width:100%;
  min-height:50px;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  gap:5px;
`;
