import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import React, { useState } from 'react';
import {
  Container, InputContainer, Subtitle, Text, TextContainer, Title,
} from '../styles';
import ButtonN from '../../../components/ButtonN';
import { useRefresh } from '../../../hooks/refresh';
import { EditPen } from '../Components/EditPen';
import { useSettings } from '../../../hooks/settings';

export function Contact() {
  const { t } = useTranslation();
  const { setStep, basicData, refreshApi } = useRefresh();
  const { settings, getEmailSupportMessage } = useSettings();
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    try {
      setLoading(true);
      const {
        name, email, phone, motherName, nationality,
      } = basicData;
      await refreshApi.basicData({
        name, email, phone, mother_name: motherName, nationality,
      });
      setLoading(false);
      setStep('address');
    } catch (error) {
      setLoading(false);
      if (error.message === 'User not found.' || error.message === 'invalid token') {
        message.error(`Ocorreu um erro, favor entrar em contato com o suporte ${settings.support_email}`);
        return;
      }
      if (error.message === 'jwt expired') {
        message.error('Login expirado, favor fazer login novamente');
        return;
      }
      if (error.message.includes('BLACKLIST')) {
        message.error(`Infelizmente não vamos dar continuidade ao seu cadastro no momento. 
${getEmailSupportMessage()}`);
        return;
      }
      message.error(error.message);
    }
  };
  const { email, phone } = basicData;
  const buttonDisabled = () => Object.keys(basicData).some((key) => (
    ['name', 'nationality', 'motherName'].includes(key)
      ? false
      : basicData[key] === '' || !basicData[key]));
  return (
    <Container>
      <InputContainer>
        <TextContainer>
          <Title>{t('Atualização de contato')}</Title>
          <Subtitle>{t('Estes dados continuam os mesmos?')}</Subtitle>
          <Text>
            {t(
              `Caso tenha trocado, selecione o e-mail ou o celular para alterar. 
Clique em continuar se os dados ainda forem os mesmos.`,
            )}
          </Text>
        </TextContainer>
        <EditPen
          title="Celular:"
          text={phone === '' || !phone ? 'Nenhum celular cadastrado' : phone}
          onClick={() => setStep('phone')}
          maskType="phone"
        />
        <EditPen
          title="Email:"
          text={email === '' || !email ? 'Nenhum email cadastrado' : email}
          onClick={() => setStep('email')}
        />
      </InputContainer>
      <ButtonN type="primary" onClick={handleNext} disabled={buttonDisabled()}>
        Continuar
      </ButtonN>
    </Container>
  );
}
