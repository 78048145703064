import api from "../api";

export const CheckpointService = async (clientName) => {
  const params = {
    client_name: clientName,
  };

  return api
    .get("onboarding/screens/checkpoint/pf", { params })
    .then((response) => {
      return { status: true, res: response.data };
    })
    .catch((error) => {
      console.log("CheckpointService error: ", error.response);
      return { status: false, res: error?.response.data };
    });
};
