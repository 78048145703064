import styled from 'styled-components';

export const Container = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: end;
 gap: 1rem;
 align-items: center;
 width: 100%;
 height: 100%;
`;

export const ButtonDisposition = styled.div`
 display: flex;
 flex-direction: column;
 /* justify-content: space-between; */
 gap: 0.5rem;
 align-items: center;
 width: 100%;
`;

export const Title = styled.span`
 width: 100%;
 font-size: 1.5rem;
 font-weight: bold;
 text-align: center;
 text-align-last: center;
 /* margin-bottom: 1rem; */
`;
export const Text = styled.span`
 width: 100%;
 font-size: 1rem;
 text-align: justify;
 text-align-last: center;
 margin-bottom: 4rem;
`;
