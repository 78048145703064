import styled from 'styled-components';

export const Title = styled.h3`
 display: flex;
 color: var(--label);
 text-align: center;
 font-size: 1rem;
`;

export const DualButtonsDisposition = styled.nav`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 height: 70%;
 min-height: 7vh;
 div {
 width: 45%;
 height: 150%;
 }
 button {
 height: 150%;
 }
`;
