/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSteps } from '../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import {
  ResolvedAssociate,
  Associate,
  Title,
  Subtitle,
  Text,
  DataInfo,
  List,
  ButtonContainer,
  SectionContainer,
} from './styles';
import { handleGetPartners } from '../../../../../services/endPointRegister/PJ/getPartners';
import { removePartner } from '../../../../../services/endPointRegister/PJ/updatePartners';
import ButtonN from '../../../../../components/ButtonN';
import ModalForTransition from '../../../../../components/ModalForTransition';
import { handleGetStepPj } from '../../../../../services/endPointRegister/PJ/statusPj';

const Partners = () => {
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const {
    list, stepsPj, exactStep, step, resetStep, stepsPjEnum,
  } = useSteps();
  const history = useHistory();
  const userPf = JSON.parse(localStorage.getItem('@qesh:account')).id;
  const accountPj = localStorage.getItem('@qesh:accountPj');
  const token = localStorage.getItem('@qesh:token');
  const accountObject = JSON.parse(localStorage.getItem('@qesh:account'));
  const [numPartners, setNumPartners] = useState(0);
  const { t } = useTranslation();
  const resolved = 'PARTNER ACCOUNT ACCEPT';
  const denied = 'PARTNER ACCOUNT DENIED';
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedPartnerRemove, setSelectedPartnerRemove] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const request = async () => {
      const { res } = await handleGetPartners(userPf, accountPj, token);
      const partnerList = res.partners || [];
      setPartners(partnerList);
      setNumPartners(parseInt(checkpoint.cnpjData?.numberOfPartners.number));
      setIsLoading(false);
    };
    const verifyPage = async () => {
      const { res } = await handleGetStepPj(token, accountObject);
      // const isTheRightPage = stepsPjEnum[status] === 5 && localStorage.getItem('@qesh:token') && !!checkpoint.cnpjData;
      // if (isTheRightPage) {
        exactStep(5);
      // } else {
      //   resetStep();
      //   history.replace('/transaction');
      //   return;
      // }
      request();
    };

    verifyPage();
  }, []);

  const getUser = ({ document, name }) => {
    const cpf = document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    return `${name} / ${cpf}`;
  };

  const handlePlus = () => {
    history.push(`/register/${stepsPj[step + 1]}`);
  };

  const handleMinus = async (index) => {
    setShowConfirmationModal(true);
    setSelectedPartnerRemove(partners[index]);
  };

  const modalRemove = async () => {
    setShowConfirmationModal(false);
    setIsLoading(true);
    const userPf = JSON.parse(localStorage.getItem('@qesh:account')).id;
    const accountPj = localStorage.getItem('@qesh:accountPj');
    const token = localStorage.getItem('@qesh:token');
    await removePartner(selectedPartnerRemove, token, accountPj, userPf);
    const { res } = await handleGetPartners(userPf, accountPj, token);
    setPartners(res.partners || []);
    setIsLoading(false);
  };

  const components = Array.from({ length: numPartners }).map((_, index) => {
    const partner = partners[index];
    return partner ? (
      partner.status === resolved ? (
        <ResolvedAssociate>
          {' '}
          {getUser(partner)}
          <FontAwesomeIcon
            icon={faCheck}
            color="var(--success)"
            style={{
              fontSize: '130%',
              cursor: ' pointer',
              marginLeft: '0.5rem',
            }}
          />
        </ResolvedAssociate>
      ) : (
        <Associate key={partner.document} style={{ color: 'grey', gap: '10px', width: '100%' }}>
          <Text>
            {partner.document.length < 12 ? (
              <span style={{ fontSize: '0.8rem', padding: '0' }}>
                {partner.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')} /{' '}
                {partner.name}
              </span>
            ) : partner.document.length > 11 ? (
              <span style={{ fontSize: '0.8rem', padding: '0' }}>
                {partner.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3.$4-$5')} /{' '}
                {partner.name}
              </span>
            ) : null}
            {partner.status === denied ? (
              <DataInfo style={{ color: 'var(--error)', fontWeight: 'bold' }}>
                Convite foi recusado pelo sócio / representante legal
              </DataInfo>
            ) : (
              <DataInfo>
                Link enviado em {partner.partner_mails_contact.sender.date} às{' '}
                {partner.partner_mails_contact.sender.hour}
              </DataInfo>
            )}
          </Text>
          <FontAwesomeIcon
            style={{
              fontSize: '130%',
              cursor: ' pointer',
              marginLeft: '0.3rem',
            }}
            icon={faMinus}
            color="var(--error)"
            onClick={() => handleMinus(index)}
          />
        </Associate>
      )
    ) : (
      <Associate key={index} style={{ color: 'grey', gap: '5px', width: '100%' }}>
        <Text>{t('Adicionar sócio')}</Text>
        <FontAwesomeIcon
          style={{
            fontSize: '130%',
            cursor: ' pointer',
            marginLeft: '0.3rem',
          }}
          icon={faPlus}
          color="var(--success)"
          onClick={() => handlePlus()}
        />
      </Associate>
    );
  });

  useEffect(() => {
    setCheckpoint({ ...checkpoint, list, steplist: true });
  }, []);

  return isLoading ? (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#121212' }} spin />} />
  ) : (
    <SectionContainer>
      <Title>{t('Dados de cadastro')}</Title>
      <Subtitle style={{ color: 'grey' }}>
        {t(
          'Insira as informações dos sócios, representantes, mandatários ou prepostos autorizados da empresa.',
        )}
      </Subtitle>
      <List>
        {/* <MainAssociate>
     {getUser()}
     <FontAwesomeIcon icon={faCheck} color='var(--success)' style={{ marginLeft: '0.3rem' }} />
    </MainAssociate> */}
        {components}
      </List>
      <ButtonN type="primary" onClick={() => history.replace('/')}>
        Voltar
      </ButtonN>
      <ModalForTransition
        visibility={showConfirmationModal}
        setVisibility={setShowConfirmationModal}
        title={(
          <h2
            style={{
              height: '30%',
              textAlign: 'center',
              alignItems: 'center',
              marginBottom: '1rem',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            Quer mesmo excluir esse perfil?
          </h2>
   )}
        style={{ height: '100%' }}
      >
        <p
          style={{
            height: '30%',
            width: '100%',
            marginBottom: '1rem',
          }}
        />
        <p>Se você excluir esse perfil terá que refazer todo o processo para adicioná-lo novamente.</p>
        <ButtonContainer>
          <ButtonN
            size="large"
            className="botao"
            type="danger"
            onClick={() => setShowConfirmationModal(false)}
            style={{ width: '70%', color: '#ffffff' }}
          >
            Não
          </ButtonN>
          <ButtonN
            size="large"
            className="botao"
            onClick={modalRemove}
            style={{ width: '70%', color: 'var(--error)' }}
            danger
          >
            Excluir
          </ButtonN>
        </ButtonContainer>
      </ModalForTransition>
    </SectionContainer>
  );
};

export default Partners;
