/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-cycle */
import React, { useContext, createContext, useState, useCallback } from "react";
import PropTypes from "prop-types";

import BasicDataPj from "../pages/Register/Steps/PJ/BasicDataPj";
import BasicDataPf from "../pages/Register/Steps/PF/BasicDataPf";
import Address from "../pages/Register/Steps/Geral/Address";
import AdicionalData from "../pages/Register/Steps/PF/AdditionalData";
import Password from "../pages/Register/Steps/Geral/Password";
import OperationalPassword from "../pages/Register/Steps/Geral/OperationalPassword";
import AccountStatus from "../pages/Register/Steps/Geral/AccountStatus";
import CodeReceiverEmail from "../pages/Register/Steps/PF/Email/CodeReceiverEmail";
import Cellphone from "../pages/Register/Steps/PF/Cellphone";
import CodeReceiver from "../pages/Register/Steps/PF/Cellphone/CodeReceiver";
import Email from "../pages/Register/Steps/PF/Email";
import SelfieOption from "../pages/Register/Steps/PF/Selfie";
import ContratoS from "../pages/Register/Steps/PJ/SocialContract";
import Documents from "../pages/Register/Steps/PF/Documents";
import Partners from "../pages/Register/Steps/Geral/Partners";
import RegisterPartner from "../pages/Register/Steps/Geral/RegisterPartner";
import Onboarding from "../pages/Register/Steps/Geral/Onboarding";
import Cam from "../pages/Register/Steps/PF/Selfie/Cam";
import MainPartnerQualification from "../pages/Register/Steps/PJ/MainPartnerQualification";

const StepsContext = createContext({});

function StepsProvider({ children }) {
  const [step, setStep] = useState(0);
  const [choosedSteps, setChoosedSteps] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [count, setCount] = useState(0);
  const [numPartners, setNumPartners] = useState();
  const [list, setList] = useState([]);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [photoAttempts, setPhotoAttempts] = useState(5);
  const [user, setUser] = useState([]);

  const stepsPjEnum = {
    Onboarding: 0,
    DataPj: 1,
    Address: 2,
    SocialContract: 3,
    MainPartnerQualification: 4,
    RegisterPartners: 5,
    WaitingApprove: 7,
  };

  const stepsPj = [
    "onboarding",
    "dataPj",
    "address",
    "socialContract",
    "mainPartnerQualification",
    "partners",
    "partnerRegisterPage",
    "accountstatus",
  ];

  const stepsPf = [
    "onboarding",
    "basicData",
    "cellphone",
    "cellphoneconfirmation",
    "email",
    "emailconfirmation",
    "password",
    "address",
    "edducation",
    "selfieOption",
    "proofoflife",
    "operationalpassword",
    "accountstatus",
  ];

  const pageList = {
    onboarding: <Onboarding />,
    basicData: <BasicDataPf />,
    dataPj: <BasicDataPj />,
    cellphone: <Cellphone />,
    cellphoneconfirmation: <CodeReceiver />,
    email: <Email />,
    emailconfirmation: <CodeReceiverEmail />,
    password: <Password />,
    address: <Address />,
    edducation: <AdicionalData />,
    documentation: <Documents />,
    selfieOption: <SelfieOption />,
    proofoflife: <Cam />,
    operationalpassword: <OperationalPassword />,
    accountstatus: <AccountStatus />,
    socialContract: <ContratoS />,
    partners: <Partners />,
    partnerRegisterPage: <RegisterPartner />,
    mainPartnerQualification: <MainPartnerQualification />,
  };

  const nextStep = useCallback(
    (value) => {
      const numberOfSteps = value || 1;
      setStep(step + numberOfSteps);
      sessionStorage.setItem("@qesh:step", step + numberOfSteps);
    },
    [step]
  );

  const prevStep = useCallback(
    (value) => {
      const numberOfSteps = value || 1;
      if (step > 0) {
        setStep(step - numberOfSteps);
        sessionStorage.setItem("@qesh:step", step - numberOfSteps);
      }
    },
    [step]
  );

  const exactStep = useCallback(
    (exact) => {
      sessionStorage.setItem("@qesh:step", exact);
      setStep(exact);
      return exact;
    },
    [step]
  );

  const resetStep = useCallback(() => {
    if (sessionStorage.getItem("@qesh:steplist") === "PJ") {
      setStep(0);
      return;
    }
    setStep(0);
    sessionStorage.clear();
  }, []);

  React.useEffect(() => {
    const user = localStorage.getItem("@qesh:AccountsList");
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  React.useEffect(() => {
    let GoSaffe = document.createElement('script');
    GoSaffe.setAttribute('src', 'https://go.saffe.ai/cdn/latest');
    document.head.appendChild(GoSaffe);
  });


  return (
    <StepsContext.Provider
      value={{
        choosedSteps,
        setChoosedSteps,
        pageList,
        nextStep,
        prevStep,
        exactStep,
        stepsPj,
        stepsPf,
        step,
        resetStep,
        disableButton,
        setDisableButton,
        count,
        setCount,
        list,
        setList,
        token,
        setToken,
        numPartners,
        setNumPartners,
        loading,
        setLoading,
        stepsPjEnum,
        photoAttempts,
        setPhotoAttempts,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
}

StepsProvider.propTypes = {
  children: PropTypes.any,
};

function useSteps() {
  const context = useContext(StepsContext);
  return context;
}

export { StepsProvider, useSteps };
