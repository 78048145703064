/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Input as InputANTD } from 'antd';
import { AntProps, DefaultAntProps } from './Types';

import { Container, StyledInput } from './styles';
import { emailValidator } from '../../pages/RefreshDataPJ/utils/emailValidator';

const Email = (props) => {
  const {
    value, setIsValid,isValid,isCorrect, className, ...rest
  } = props;
  const emailCheck = /^[a-z0-9.+_]{1,61}@[a-z0-9]{2,}\.[a-z]{2,}(.[a-z]{1,2})?$/
  const validEmail = value?.length <= 0 || emailValidator.test(value);
  useEffect(() => {
    if (typeof setIsValid === 'function') {
      if (emailCheck.test(value)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [value, setIsValid]);
  return (
    <Container>
      {!!props.label && <label htmlFor={props.id}>{props.label}</label>}
      <StyledInput
      isValid={!validEmail || isCorrect}
        className={`ant-input ${className} ${
          value === '' ? '' : emailCheck.test(value) ? 'is-valid' : 'is-invalid'
        }`}
      >
        <InputANTD {...rest} type="email" value={value} />
        <i />
      </StyledInput>
    </Container>
  );
};

Email.propTypes = {
  ...AntProps,
  setIsValid: (props) => (props.setIsValid === undefined && new Error('setIsValid necessário para validação!'))
|| (typeof props.setIsValid !== 'function' && new Error('setIsValid deve ser uma função!')),
};

Email.defaultProps = {
  ...DefaultAntProps,
  setIsValid: undefined,
};

export default Email;
