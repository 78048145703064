import api from '../../api';
/**
 * Request type post, to register account PJ with basic data
 * @property {string} reference - value reference the companie invite for notify
 * @property {string} token - token bearer login account user PF
 * @property {string} account - account_id to account the user PF
 * @returns {{status: boolean, res: {response or error}}}
 */

const handleDataPj = async (reference, token, account) => {
  const headers = {
    account,
    user: reference,
  };

  return api
    .get('/onboarding/companies/', { headers })
    .then((response) => {
      const responseData = response.data;
      return { status: true, res: responseData };
    })
    .catch((error) => {
      console.error(error.message || error);
      console.log('handleDataPj', error.response.data);
      return { status: false, res: error.response.data };
    });
};

export default handleDataPj;
