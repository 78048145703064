import styled from 'styled-components';

export const Container = styled.section`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
align-self: center;
`;

export const TitleArea = styled.div`
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
justify-content: center;
width: 80%;
height: fit-content;
`;

export const Title = styled.h1`
font-size: 18px;
font-weight: bold;
`;

export const TipsArea = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 67%;
height: fit-content;
`;

export const TextStyled = styled.text`
align-items: center;
font-size: 15px;
margin: 30px 0px 0px 0px;
display: flex;
gap: 0.5rem;
`;

export const InfoArea = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
height: fit-content;
`;

export const InfoStyled = styled.text`
align-items: center;
font-size: 14px;
margin: 30px 0px 0px 0px;
display: flex;
gap: 0.5rem;
width: 65%;
`;

export const FalseModel = styled.img`
margin-top: 25px;
min-width: 280px;
min-height: 150px;
box-shadow: 2px 2px #ccc;
border: 1px solid #ccc;
`;

export const RedText = styled.p`
color: #e22012;
align-items: center;
font-size: 15px;
margin: 30px 0px 0px 0px;
display: flex;
gap: 0.5rem;
`;
