/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Divider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  ButtonContainer, Content, ContentBold, HeaderContainer, InfoContainer, MainContainer, Title,
} from './style';
import handleDataPj from '../../services/endPointRegister/PJ/endPointData';
import ButtonN from '../ButtonN';
import { useSettings } from '../../hooks/settings';

const NotifyInvitation = ({ reference, handleAccept, handleDecline }) => {
  const [dataPj, setDataPj] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  const { settings } = useSettings();
  const [token, setToken] = useState(null);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const getDataPj = async () => {
      const reqToken = localStorage.getItem('@qesh:token');
      const reqAccount = JSON.parse(localStorage.getItem('@qesh:account'));
      setToken(reqToken);
      setAccount(reqAccount);
      const { res, status } = await handleDataPj(reference, reqToken, reqAccount.id);
      if (!status) {
        setDataPj(res);
        setHasError(true);
        setIsLoading(false);
        return;
      }
      setDataPj(res);
      setHasError(false);
      setIsLoading(false);
    };
    setIsLoading(true);
    getDataPj();
  }, [reference]);

  if (isLoading) {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fffff' }} spin />}
      />
    );
  }

  return (
    <MainContainer>
      {hasError ? (
        <>
          <Title>ERRO</Title>
        </>
      ) : (
        <>
          <HeaderContainer>
            <Title>{t(`Você foi convidado(a) para participar como ${dataPj.qualification_partner}`)}</Title>
            <Divider style={{ borderColor: settings.colors.primary, marginBottom: 5, marginTop: 10 }} />
          </HeaderContainer>
          <Title>{dataPj.name}</Title>
          <Content
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '90%',
              textAlign: 'center',
              alignSelf: 'center',
              marginBottom: '15px',
            }}
          >
            <span>Você poderá alternar entre sua conta física e jurídica a qualquer momento.</span>
            <b>Ao aceitar você sera deslogado de sua conta.</b>
          </Content>
          <InfoContainer>
            <Content>
              <ContentBold>CNPJ: </ContentBold>
              {dataPj.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}
            </Content>
            <Content>
              <ContentBold>Razão social: </ContentBold>
              {dataPj.name}
            </Content>
            <Content>
              <ContentBold>Nome Fantasia: </ContentBold>
              {dataPj.additional.trading_name}
            </Content>
            <Content>
              <ContentBold>Sócios / representates legais convidados: </ContentBold>
              {dataPj.additional.partners.map((partner, index) => (
                <Content key={index}>{`${partner.name}${
                  index === dataPj.additional.partners.length - 1 ? '.' : ', '
                }`}
                </Content>
              ))}
            </Content>
            <Content>
              <ContentBold>Número de sócios \ representates legais: </ContentBold>
              {dataPj.additional.number_of_partners}
            </Content>
          </InfoContainer>
          <ButtonContainer>
            <ButtonN
              size="large"
              className="botao"
              type="primary"
              style={{ width: '70%', color: 'var(--label)' }}
              onClick={() => handleAccept(token, dataPj.id, account.id)}
            >
              Aceitar
            </ButtonN>
            <ButtonN
              size="large"
              className="botao"
              style={{ width: '70%', color: 'var(--primary)', borderColor: 'var(--primary)' }}
              onClick={() => handleDecline(token, dataPj.id, account.id)}
            >
              Recusar
            </ButtonN>
          </ButtonContainer>
        </>
      )}
    </MainContainer>
  );
};

NotifyInvitation.propTypes = {
  reference: PropTypes.string.isRequired,
  handleAccept: PropTypes.func.isRequired,
  handleDecline: PropTypes.func.isRequired,
};

export default NotifyInvitation;
