import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory, useParams } from 'react-router-dom';
import {
  Container, Content, Logo, ImgContainer,
} from './styles';

import { useSteps } from '../../hooks/steps';
import { useCheckpoint } from '../../hooks/checkpoint';
import LowerLeftMessage from '../../components/LowerLeftMessage';
import { useSettings } from '../../hooks/settings';

// para homologação
const Register = () => {
  const pathParams = useParams();
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const history = useHistory();
  const { resetStep } = useSteps();
  // User Settings
  const { settings } = useSettings();
  const { pageList } = useSteps();
  // Tradução
  const { t } = useTranslation();
  const listOfPagesToDisableText = ['socialContract', 'documentation', 'proofoflife'];
  useEffect(() => {
    const document = checkpoint?.cpfCnpj ? checkpoint?.cpfCnpj : sessionStorage.getItem('@qesh:document');
    const steplistForPj = sessionStorage.getItem('@qesh:steplist') === 'PJ';
    if (setCheckpoint) { setCheckpoint({ ...checkpoint, cpfCnpj: document, steplist: steplistForPj }); }
  }, [setCheckpoint]);

  // useEffect(() => {
  //   const tokenFromStorage = localStorage.getItem('@qesh:token');
  //   if (
  //     !tokenFromStorage && window.location.pathname !== '/register/'
  //       && window.location.pathname !== '/register'
  //       && window.location.pathname !== '/register/basicDataPj'
  //       && window.location.pathname !== '/register/dataPj'
  //       && window.location.pathname !== '/register/basicData'
  //       && window.location.pathname !== '/register/cellphone'
  //       && window.location.pathname !== '/register/cellphoneconfirmation'
  //       && window.location.pathname !== '/register/email'
  //       && window.location.pathname !== '/register/emailconfirmation'
  //       && window.location.pathname !== '/register/password'
  //       && window.location.pathname !== '/register/address'
  //   ) {
  //     // resetStep();
  //     if (checkpoint.steplist) {
  //       history.replace('/');
  //     }
  //     history.replace('/register');
  //   }
  // });

  const renderActualPage = () => {
    if (pathParams.step && pathParams.step !== 'basicDataPj') {
      return pageList?.[pathParams.step];
    }

    return pageList?.onboarding;
  };

  return (
    <>
      <Container settings={settings}>
        <ImgContainer settings={settings} showGradient={settings.name !== 'nmpay'}/>
        <Content>
          <Logo src={settings.default_logo} alt={t('logo')} />
          {renderActualPage()}
        </Content>

        {!listOfPagesToDisableText.some((page) => window.location.pathname.includes(page))
        && ['qeshdev', 'qesh'].includes(settings.name) ? (
          <LowerLeftMessage />
          ) : null}
      </Container>
    </>
  );
};

export default Register;
