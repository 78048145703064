import React from 'react';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { LightText, TextBold } from '../../styles';
import { RowContainer, InfoContainer } from './styles';
import getMasks from '../../utils/getMasks';

export function EditPen({
  title, text, onClick, maskType,
}) {
  return (
    <RowContainer>
      <InfoContainer>
        <TextBold>{title}</TextBold>
        <LightText>{maskType ? getMasks(maskType, text) : text}</LightText>
      </InfoContainer>
      <FontAwesomeIcon style={{ color: 'var(--label)', cursor: 'pointer' }} icon={faPen} onClick={onClick} />
    </RowContainer>
  );
}

EditPen.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  maskType: PropTypes.string,
};

EditPen.defaultProps = {
  maskType: null,
};
