/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { message as messageAntd } from 'antd';
import { SpanSub, SpanStyled } from './styles';
import ButtonN from '../../../../../../components/ButtonN';
import { SubTitle, Title } from '../../../../styles';
import { InputReceiverCode } from '../../../../../../components/InputReceiverCode';
import { useSteps } from '../../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../../hooks/checkpoint';
import { useSettings } from '../../../../../../hooks/settings';
import messages from '../../../../../../components/MessageError';
import api from '../../../../../../services/api';

const CodeReceiver = () => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);
  const [, setErroReSend] = useState(false);
  const [, setMessage] = useState('');
  const {
    nextStep, prevStep, step, stepsPf, exactStep,
  } = useSteps();
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const { t } = useTranslation();
  const { settings } = useSettings();
  const history = useHistory();

  const [inputs, setInputs] = useState({
    ssn1: '',
    ssn2: '',
    ssn3: '',
    ssn4: '',
    ssn5: '',
  });

  useEffect(() => {
    if (checkpoint.cellNumber !== undefined) {
      exactStep(3);
    } else {
      exactStep(2);
      history.replace('/register/cellphone');
    }
  }, []);

  const ssn = inputs.ssn1 + inputs.ssn2 + inputs.ssn3 + inputs.ssn4 + inputs.ssn5;

  const handleAdvance = () => {
    setCheckpoint({
      ...checkpoint,
      cellphoneConfirmationCode: {
        ...inputs,
      },
    });
    setInputs({
      ssn1: '', ssn2: '', ssn3: '', ssn4: '',
    });
    if (checkpoint.cpfCnpj.length === 11) {
      nextStep();
      if (stepsPf.length >= step + 1) {
        history.push(`/register/${stepsPf[step + 1]}`);
      } else {
        history.push(`/register/${stepsPf[step]}`);
      }
    }
  };

  const formatNumberReceiverCode = () => {
    const dddNumber = checkpoint.cellNumber.slice(0, 2);
    const numberPhone = checkpoint.cellNumber.slice(2);
    return `${dddNumber} ${numberPhone}`;
  };

  const reqReceiverCode = async () => {
    setLoading(true);
    // const obj = encodeURIComponent(formatNumberReceiverCode())
    if (ssn.length > 4) {
      api.patch(`onboarding/validate/cellphone/${ssn}`, {
        client_name: settings.name,
        document: checkpoint.cpfCnpj,
        contact: formatNumberReceiverCode(),
      })
        .then((response) => {
          setError(false);
          setLoading(false);
          setMessage(response?.data?.message);
          messageAntd.success('Seu número de telefone foi validado com sucesso.', 10);
          setErroReSend(false);
          handleAdvance();
        })
        .catch((error) => {
          console.error('reqReceiverCode: ', error);
          setLoading(false);
          setMessage(error?.response?.data?.message);
          messageAntd.error(messages.codeInvalid, 10);
          setError(true);
          setErroReSend(true);
        });
    }
  };

  const formatNumber = () => {
    const dddNumber = checkpoint.cellNumber.slice(0, 2);
    const firstNumber = checkpoint.cellNumber.slice(2, 3);
    const firstBlockOfFour = checkpoint.cellNumber.slice(3, 7);
    const secondblockOfFour = checkpoint.cellNumber.slice(7);
    return `(${dddNumber}) ${firstNumber} ${firstBlockOfFour}-${secondblockOfFour}`;
  };

  const getSendCodeSuccess = (message) => {
    const formatedPhone = message.slice(-11).replace(/^(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
    return `Um SMS contendo um código de verificação foi enviado para o número ${formatedPhone}.`;
  };

  const handleSend = () => {
    setLoading(true);
    setError(false);
    api.post('onboarding/send', {
      client_name: settings.name,
      document: checkpoint.cpfCnpj,
      contact: formatNumberReceiverCode(),
    })
      .then((response) => {
        setLoading(false);
        setMessage('');
        setMessage(response?.data?.message);
        setError(false);
        messageAntd.success(getSendCodeSuccess(response.data.message), 10);
        setErroReSend(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(false);
        setMessage('');
        setErroReSend(true);
        setMessage(error.response.data.message);
        messageAntd.error(error.response.data.message, 10);
        console.error('smsRequisition reenviar: ', error.response.data);
      });
  };

  return (
    <>
      <Title>{t('Só mais alguns passos...')}</Title>
      <SubTitle>
        <header>{t('Confirme seu celular')}</header>
        <span>Para continuar, insira o código que nós acabamos de enviar para o seu número de telefone: </span>
        <SpanStyled>{checkpoint.cellNumber ? formatNumber() : ''}</SpanStyled>
      </SubTitle>

      <InputReceiverCode inputs={inputs} setInputs={setInputs} />
      <SpanSub onClick={handleSend}>{t('Reenviar código')}</SpanSub>
      <ButtonN
        onClick={() => {
          prevStep();
          history.replace(`/register/${stepsPf[exactStep(step - 1)]}`);
        }}
        className="botao"
      >
        {t('Alterar número de celular')}
      </ButtonN>
      <ButtonN
        loading={loading}
        disabled={!inputs.ssn1 || !inputs.ssn2 || !inputs.ssn3 || !inputs.ssn4 || !inputs.ssn5}
        onClick={() => reqReceiverCode()}
        type="primary"
      >
        {!loading && t('Confirmar')}
      </ButtonN>
    </>
  );
};

export default CodeReceiver;
