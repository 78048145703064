import api from '../../api';

/**
 * Request type post, to register account PJ with basic data
 * @property {string} contract - social contract to send
 * @property {string} token - token bearer login account user PF
 * @returns {{status: boolean, res: response or error}}
 */

export const documentPost = async (contract, token, userPj) => {
  const formData = new FormData();
  formData.append('file', contract);
  return api
    .post('onboarding/documents/COMPANY', formData, {
      headers: {
        user: userPj,
        'Content-Type': 'multipart/form-data',
        Accept: 'multipart/form-data',
      },
    })
    .then((response) => {
      const responseData = response.data;
      return { status: false, res: responseData };
    })
    .catch((error) => {
      console.log('documentPost error: ', error.response.data.message);
      return { status: true, res: error.response.data.message };
    });
};
