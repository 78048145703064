/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input as InputANTD } from 'antd';
import { AntProps, DefaultAntProps } from './Types';

import { Container, StyledInput } from './styles';

const Password = (props) => {
  const {
    value, setIsValid, isValid,isCorrect ,isCadastro, isOperacional, className, ...rest
  } = props;

  useEffect(() => {
    if (typeof setIsValid === 'function') {
      if (
        isCadastro
&& value?.length > 7
&& value?.toLowerCase() !== value
&& value?.toUpperCase() !== value
&& /\d/g.test(value)
&& /\W+/g.test(value)
      ) {
        return setIsValid(true);
      }
      if (!isCadastro && !isOperacional && value?.length > 7) {
        return setIsValid(true);
      }
      if (isOperacional && value?.length === 4) {
        return setIsValid(true);
      }
      setIsValid(false);
    }
  }, [value, setIsValid, isCadastro]);
  return (
    <Container>
      {!!props.label && <label htmlFor={props.id}>{props.label}</label>}
      <StyledInput isValid={isCorrect} className={`ant-input ${className} ${value ? (!isValid ? 'is-invalid' : 'is-valid') : ''}`}>
        <InputANTD.Password {...rest} style={{ ...rest?.style, height: 35, padding: 0 }} value={value} />
      </StyledInput>
    </Container>
  );
};

Password.propTypes = {
  ...AntProps,
  setIsValid: (props, propName) => (props[propName] === undefined || typeof props[propName] !== 'function')
&& new Error('setIsValid deve ser uma função!'),
  isValid: (props, propName) => !!props.isValid
&& (props[propName] === undefined || typeof props[propName] !== 'boolean')
&& new 'State de validação necessário'(),
  isOperacional: PropTypes.bool,
  isCadastro: PropTypes.bool,
};

Password.defaultProps = {
  ...DefaultAntProps,
  isOperacional: undefined,
  isCadastro: undefined,
};

export default Password;
