/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '@unform/web';
import QRCode from 'react-qr-code';
import { Divider, Switch, Modal } from 'antd';
import { isBefore } from 'date-fns';
import * as Yup from 'yup';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Input from '../../components/Input';
import { Row, Col } from '../../styles/components';
import Button from '../../components/Button';
import { useAuth } from '../../hooks/auth';
import formErrors from '../../services/formErrors';
import api from '../../services/api';
import Loading from '../../components/Loading';

function Withdraw() {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [maxWithdrawAmount, setMaxWithdrawAmount] = useState(0);
  const [withdrawFee, setWithdrawFee] = useState(0);
  const [startTimeWithdraw, setStartTimeWithdraw] = useState(null);
  const [endTimeWithdraw, setEndTimeWithdraw] = useState(null);
  const [, setError] = useState('');
  const [qrValue, setQrValue] = useState(null);
  const [isWithdrawable, setIsWithdrawable] = useState();
  const [checked, setChecked] = useState(false);
  const formRef = useRef();
  const { account, user } = useAuth();
  const { t } = useTranslation();

  const checkExistenceWithdrawPoint = useCallback(async (accountId) => {
    setLoading(true);

    try {
      const { data } = await api.get('withdraw-locations/', {
        headers: {
          account: accountId,
        },
      });

      setIsWithdrawable(data);
      setChecked(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    checkExistenceWithdrawPoint(account.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkExistenceWithdrawPoint]);

  const deleteWithdrawPoint = useCallback(async (withdrawLocationId) => {
    Modal.confirm({
      title: t('desabilitar ponto de saque'),
      icon: <ExclamationCircleOutlined />,
      content: t('deseja prosseguir com a desativacao do ponto de saque'),
      okText: t('desativar ponto de saque'),
      cancelText: t('cancelar'),
      centered: true,
      onOk: async () => {
        setLoading(true);
        setChecked(false);

        try {
          await api.delete(`withdraw-locations/${withdrawLocationId}`);

          checkExistenceWithdrawPoint(account.id);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
        }
      },
      onCancel: async () => {
        setChecked(true);
      },
    });
  }, [account.id, checkExistenceWithdrawPoint, t]);

  const handleSubmit = useCallback(() => {
    formRef.current.setErrors({});

    if (!amount || amount < 0.1) {
      formRef.current.setErrors({
        amount: t('o valor deve ser maior ou igual a...'),
      });
      setQrValue(null);
      return;
    }
    const obj = {
      type: 'withdraw',
      amount,
      withdraw_location_id: isWithdrawable.id,
      account_id: account.id,
      account: account.bank_account,
      user_id: user.id,
    };
    setQrValue(JSON.stringify(obj));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, account, isWithdrawable]);

  async function handleSubmitWithdrawPoint(data) {
    formRef.current.setErrors({});
    setError('');

    try {
      const schema = Yup.object().shape({
        pointName: Yup.string().required(t('o nome do ponto de saque e obrigatorio')),

        pointStreet: Yup.string().required(t('o logradouro do ponto de saque e obrigatorio')),

        pointNumber: Yup.string().required(t('o n e obrigatorio')),

        pointNeighborhood: Yup.string().required(t('o bairro do ponto de saque e obrigatorio')),

        maxWithdrawAmount: Yup.string().required(t('o valor maximo de saque e obrigatorio')),

        startTimeWithdraw: Yup.string().required(t('o horario de inicio e obrigatorio')),

        endTimeWithdraw: Yup.string().required(t('o horario final e obrigatorio')),

      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const hourStart = startTimeWithdraw.split(':');
      const hourEnd = endTimeWithdraw.split(':');

      if (+hourStart[0] > 23 || isNaN(+hourStart[0])) {
        formRef.current.setErrors({
          startTimeWithdraw: t('hora digitada invalida'),
        });
        return;
      } if (+hourStart[1] > 59 || isNaN(+hourStart[1])) {
        formRef.current.setErrors({
          startTimeWithdraw: t('minuto digitado invalido'),
        });
        return;
      }

      if (+hourEnd[0] > 23 || isNaN(+hourEnd[0])) {
        formRef.current.setErrors({
          endTimeWithdraw: t('hora digitada invalida'),
        });
        return;
      } if (+hourEnd[1] > 59 || isNaN(+hourEnd[1])) {
        formRef.current.setErrors({
          endTimeWithdraw: t('minuto digitado invalido'),
        });
        return;
      }

      const start = new Date().setHours(hourStart[0], hourStart[1]);
      const end = new Date().setHours(hourEnd[0], hourEnd[1]);

      const isHourBefore = isBefore(start, end);
      if (isHourBefore === false) {
        formRef.current.setErrors({
          startTimeWithdraw: t('hora inicial digitada e maior que a hora final'),
        });
        return;
      }

      if (!maxWithdrawAmount || maxWithdrawAmount < 0.1) {
        formRef.current.setErrors({
          maxWithdrawAmount: t('o valor deve ser maior ou igual a...'),
        });
        return;
      }

      try {
        await api.post('withdraw-locations/', {
          client_settings_id: user.client_setting.id,
          withdraw_point_name: data.pointName,
          withdraw_point_street: data.pointStreet,
          withdraw_point_number: data.pointNumber,
          withdraw_point_neighborhood: data.pointNeighborhood,
          max_withdraw_amount: maxWithdrawAmount,
          withdraw_fee: withdrawFee,
          start_withdraw_time: data.startTimeWithdraw,
          end_withdraw_time: data.endTimeWithdraw,
        });

        checkExistenceWithdrawPoint(account.id);
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        formRef.current.setErrors(errors);
      }
    }
  }

  function onChangeSwitch(data) {
    if (data) {
      deleteWithdrawPoint(data.id);
    }
  }

  return (

    !isWithdrawable ? (
      <>
        <Loading hasLoading={loading} />
        <h1>{t('saques')}</h1>
        <h3>{t('habilitar-se como ponto de saque')}</h3>

        <Form
          ref={formRef}
          onSubmit={handleSubmitWithdrawPoint}
          style={{ marginTop: '32px' }}
        >

          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
              <Input
                id="pointName"
                name="pointName"
                label={t('nome de identificacao do ponto de saque')}
                type="text"
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="bottom">
              <Input
                id="pointStreet"
                name="pointStreet"
                label={t('logradouro ponto de saque')}
                type="text"
              />
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} className="bottom">
              <Input
                id="pointNumber"
                name="pointNumber"
                label={t('numero')}
                type="text"
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
              <Input
                id="pointNeighborhood"
                name="pointNeighborhood"
                label={t('bairro')}
                type="text"
              />
            </Col>
          </Row>

          <Row gutter={16}>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="bottom">
              <Input
                mask="currency"
                onChangeEvent={(_event, _maskedvalue, floatvalue) => {
                  setMaxWithdrawAmount(floatvalue);
                }}
                id="maxWithdrawAmount"
                name="maxWithdrawAmount"
                value={maxWithdrawAmount}
                label={t('valor maximo para saques')}
                type="text"
              />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="bottom">

              <Input
                mask="currency"
                onChangeEvent={(_event, _maskedvalue, floatvalue) => {
                  setWithdrawFee(floatvalue);
                }}
                id="withdrawFee"
                name="withdrawFee"
                value={withdrawFee}
                label={t('taxa cobrada por saque')}
                type="text"
              />
            </Col>

          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ alignSelf: 'center' }}>
              <Divider style={{ top: 0 }}>{t('horarios para saques')}</Divider>
              <Row gutter={16}>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="bottom">
                  <Input
                    mask="99:99"
                    id="startTimeWithdraw"
                    name="startTimeWithdraw"
                    value={startTimeWithdraw}
                    onChange={(event) => {
                      setStartTimeWithdraw(event.target.value);
                    }}
                    label={t('horario inicio para saque')}
                    type="text"
                  />

                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="bottom">

                  <Input
                    mask="99:99"
                    id="endTimeWithdraw"
                    name="endTimeWithdraw"
                    value={endTimeWithdraw}
                    onChange={(event) => {
                      setEndTimeWithdraw(event.target.value);
                    }}
                    label={t('horario final para saque')}
                    type="text"
                  />

                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
              <Button>{t('habilitar ponto de saque')}</Button>
            </Col>
          </Row>
        </Form>

      </>
    ) : (
      <>
        <Loading hasLoading={loading} />
        <h1>{t('saque')}</h1>
        <h3>{t('digite o valor abaixo. o codigo pode ser lido no aplicativo...')}</h3>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ amount: 1 }}
          style={{ marginTop: '32px' }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
              <Input
                mask="currency"
                onChangeEvent={(_event, _maskedvalue, floatvalue) => {
                  setAmount(floatvalue);
                }}
                id="amount"
                name="amount"
                value={amount}
                label={t('valor')}
                type="text"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
              <Button center>{t('gerar qrcode')}</Button>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
              <div style={{ paddingBottom: 0, marginLeft: 40 }}>
                {qrValue && <QRCode size={200} value={qrValue} />}
              </div>
            </Col>
          </Row>
        </Form>

        <Divider />
        <Row gutter={16}>

          <h3>{t('desabilitar ponto de saqjue')}</h3>
          <Switch
            defaultChecked
            checked={checked}
            onChange={
              () => {
                if (isWithdrawable) {
                  onChangeSwitch(isWithdrawable);
                }
              }
            }
            style={{ marginLeft: 15 }}
          />
        </Row>
      </>
    )
  );
}

export default Withdraw;
