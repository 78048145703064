import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faUpload, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Container, Title, Text, Tips,
} from './styles';

const Documents = () => (
  <Container>
    <Title>Confira algumas dicas para o upload od Contrato Social</Title>
    <Tips>
      <Text>
        <FontAwesomeIcon icon={faFilePdf} className="text-icon" />
        Se atente ao formato do arquivo, ele deve estar em PDF.
      </Text>
      <Text>
        <FontAwesomeIcon icon={faUpload} className="text-icon" />
        O tamanho máximo aceito do arquivo é de 5 MB.
      </Text>
      <Text>
        <FontAwesomeIcon icon={faExclamationCircle} className="text-icon" style={{ color: '#DA1E28' }} />
        Todas as informações presentes no documento serão checadas e,
        caso haja incoerências, sua solicitação de conta será negada.
      </Text>
    </Tips>
  </Container>
);

export default Documents;
