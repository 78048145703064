import React from 'react';
import { BiErrorAlt as ErrorIcon } from 'react-icons/bi';
import { BsFillEyeSlashFill as Invisible, BsFillEyeFill as Visible } from 'react-icons/bs';
import { masks } from '../../utilitys/Masks';

import HelpModal from '../Help';
import {
  Alert, Input, InputContainer, Label, Wrapper,
} from './styles';

export const FormInput = ({
  label,
  width,
  placeholder,
  isRequired = false,
  value,
  name,
  type = 'text',
  mask,
  isValid = true,
  isDisabled = false,
  handleChange,
  hasHelp = false,
  errorMessage = 'Valor Inválido',
  showErrorMessage = true,
  maxLength = 999,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  function handleTogglePassword() {
    setShowPassword((pravValue) => !pravValue);
  }

  function handleIconToDisplay() {
    if (type === 'password') {
      return showPassword ? <Visible onClick={handleTogglePassword} size={20} color={isValid ? '#2D2D2D' : '#FF0000'} /> : <Invisible onClick={handleTogglePassword} size={20} color={isValid ? '#2D2D2D' : '#FF0000'} />;
    }
    if (!isValid && showErrorMessage && !(type === 'password')) {
      return <ErrorIcon size={20} color="#DA1E28" />;
    }
    return null;
  }
  return (
    <Wrapper style={{ width }}>
      <Label>
        <span>{label}</span>
        {isRequired && <p>*</p>}
        {hasHelp && !isDisabled && <HelpModal size="large" type={name} />}

      </Label>
      <InputContainer isValid={isValid}>
        <Input
          placeholder={placeholder}
          value={mask ? masks[mask](value) : value}
          name={name}
          onChange={handleChange}
          type={showPassword ? 'text' : type}
          disabled={isDisabled}
          maxLength={maxLength}
          isDisabled={isDisabled}
        />
        {handleIconToDisplay()}

      </InputContainer>

      {(!isValid && showErrorMessage) && <Alert>{errorMessage}</Alert>}
    </Wrapper>
  );
};
