/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { Skeleton, notification } from 'antd';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { parseISO, addHours, format } from 'date-fns';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import Axios from 'axios';
import { Row, Col } from '../../../../../styles/components';
import Input from '../../../../../components/Input';
import { ChargeSubtitle, CenterContent } from '../../../styles';
import { useSettings } from '../../../../../hooks/settings';
import { TopicMarker } from '../../styles';
import { documentMask, HandleFormatMoney } from '../../../../../services/utils';
import Button from '../../../../../components/Button';
import api from '../../../../../services/api';
import PopOverPrevious from '../../../../../components/PopOverPrevious';
import ButtonText from '../../../../../components/ButtonText';

const BilletFields = ({
  amount,
  setAmount,
  juros,
  setJuros,
  multa,
  setMulta,
  split = [],
  setSplit,
  billet,
  formRef,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [latestCharged, setLatestCharged] = useState([]);
  const [selectedCharged, setSelectedCharged] = useState(null);
  const { settings } = useSettings();
  const [document, setDocument] = useState('');
  const [cep, setCep] = useState('');
  const [banks, setBanks] = useState([]);
  const [rerender, setRerender] = useState(false);

  function formatDate(date) {
    return format(parseISO(date), 'dd/MM/yyyy');
  }

  const changeValue = useCallback(
    (event, maskedvalue, floatvalue) => {
      if (event.target.name === 'juros') {
        setJuros(floatvalue);
        return;
      }
      if (event.target.name === 'multa') {
        setMulta(floatvalue);
        return;
      }
      setAmount(floatvalue);
    },
    [setAmount, setJuros, setMulta],
  );

  const loadCpfData = useCallback(async () => {
    try {
      const { data } = await api.get(
        `https://bank.qesh.ai/api-id/${document}`,
        {
          headers: {
            client: settings.client_id,
          },
        },
      );
      setTimeout(() => {
        // setCep('');
        const form = formRef.current;
        form.setData({
          name: data.name || data.trading_name,
          // cep: '',
        });
      }, 500);
    } catch (err) {
      console.log(err);
    }
    setShowSkeleton(false);
  }, [document, settings, formRef]);

  const loadCepData = useCallback(async () => {
    try {
      const { data } = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      setTimeout(() => {
        const {
          logradouro, bairro, localidade, uf,
        } = data;
        const form = formRef.current;
        form.setData({
          logradouro,
          neighborhood: bairro || '',
          city: localidade || '',
          state: uf || '',
        });
      }, 500);
    } catch (err) {
      console.log(err);
    }
  }, [cep, formRef]);

  const handleLastChargedSelection = useCallback(
    (item) => {
      setSelectedCharged(item);
      setDocument(item.charged_document);
      if (item.charged_address) {
        setCep(item.charged_address.zip_code);
      }
      if (formRef && formRef.current) {
        const form = formRef.current;
        setTimeout(() => {
          if (item.charged_address) {
            const {
              street,
              number,
              neighborhood,
              complement,
              city,
              state,
              zip_code,
            } = item.charged_address;
            form.setData({
              cep: zip_code,
              logradouro: street,
              number,
              neighborhood,
              complement,
              city,
              state,
            });
          }
          form.setData({
            name: item.charged_name,
            email: item.charged_email,
            phone: item.charged_phone,
          });
        }, 500);
      }
    },
    [formRef],
  );

  useEffect(() => {
    if (
      document
      && ((document.length === 11 && cpf.isValid(document))
        || (document.length === 14 && cnpj.isValid(document)))
      && !selectedCharged
    ) {
      loadCpfData();
    }
  }, [loadCpfData, document, selectedCharged]);

  useEffect(() => {
    if (cep && (cep.length === 8 || cep.length === 9) && !selectedCharged) {
      loadCepData();
    } else if (!cep || !cep.length) {
      setSplit([]);
    }
  }, [cep, loadCepData, selectedCharged, setSplit]);

  const loadBanks = useCallback(() => {
    api.get('/banks').then(({ data }) => {
      const { banks: b = [] } = data;
      setBanks(
        b.map((bank) => ({
          value: bank.code,
          label: `${bank.code} - ${bank.name}`,
        })),
      );
    });
  }, []);

  const loadLatestCharged = useCallback(async () => {
    try {
      const { data: latest } = await api.get('charges/last-charged');
      setLatestCharged(latest);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    loadBanks();
    loadLatestCharged();
  }, [loadBanks, loadLatestCharged]);

  const handleAddSplit = useCallback(() => {
    const id = Math.random();
    setSplit([
      ...split,
      {
        id: `${id}`,
        document: '',
        name: '',
        bank: { value: 633, label: '633 - Rendimento' },
        agency: '0001',
        account: '6180',
        percent: 0,
      },
    ]);
  }, [split, setSplit]);

  const searchSplitDoc = useCallback(
    async (doc, currentSplit, index) => {
      try {
        const { data } = await api.get(`https://bank.qesh.ai/api-id/${doc}`, {
          headers: {
            client: settings.client_id,
          },
        });
        // eslint-disable-next-line no-param-reassign
        currentSplit.name = data.name || data.trading_name;
        const newSplit = [...split];
        newSplit[index] = currentSplit;
        setSplit(newSplit);
      } catch (err) {
        console.log(err);
      }
    },
    [setSplit, settings, split],
  );

  const handleChangeSplitData = useCallback(
    async (event, dataType, index, _floatValue = null) => {
      const currentSplit = { ...split[index] };

      if (!event) return;

      if (event.value && event.label && !_floatValue) {
        currentSplit[dataType] = { label: event.label, value: event.value };
      } else if (event.target && !_floatValue) {
        currentSplit[dataType] = event.target.value;

        if (event.target.name.includes('document')) {
          const doc = event.target.value;
          if (
            (doc.length === 11 && cpf.isValid(doc))
            || (doc.length === 14 && cnpj.isValid(doc))
          ) {
            searchSplitDoc(doc, currentSplit, index);
          }
        }
      } else if (_floatValue && _floatValue <= 100) {
        currentSplit[dataType] = _floatValue;
      }
      const newSplit = [...split];
      newSplit[index] = currentSplit;
      setSplit(newSplit);
    },
    [split, setSplit, searchSplitDoc],
  );

  const splitsContent = useMemo(() => {
    if (split && split.length) {
      return (
        <>
          {split.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={item.id} style={{ marginBottom: 50 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 30,
                }}
              >
                <TopicMarker />
                <h3 style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  {t('insira os dados da pessoa/empresa recebedora')}
                </h3>
              </div>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                  <Input
                    id={`splits[${item.id}].document`}
                    name={`splits[${item.id}].document`}
                    value={documentMask(item.document)}
                    label={t('cpf / cnpj')}
                    type="text"
                    onChange={(e) => handleChangeSplitData(e, 'document', index)}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={20} lg={20} xl={20} className="bottom">
                  <Input
                    id={`splits[${item.id}].name`}
                    name={`splits[${item.id}].name`}
                    label={t('nome completo')}
                    value={item.name}
                    type="text"
                    onChange={(e) => handleChangeSplitData(e, 'name', index)}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                  <Input
                    select
                    id={`splits[${item.id}].bank`}
                    name={`splits[${item.id}].bank`}
                    label={t('banco')}
                    value={item.bank}
                    options={[
                      { value: 633, label: '633 - Rendimento' },
                      { value: 999, label: '999 - Interno' },
                    ]}
                    type="text"
                    onChange={(e) => handleChangeSplitData(e, 'bank', index)}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Input
                    id={`splits[${item.id}].agency`}
                    name={`splits[${item.id}].agency`}
                    value={item.agency}
                    maxLength="4"
                    label={t('agencia')}
                    type="text"
                    onChange={(e) => handleChangeSplitData(e, 'agency', index)}
                  />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Input
                    id={`splits[${item.id}].account`}
                    value={item.account}
                    name={`splits[${item.id}].account`}
                    label={t('conta')}
                    type="text"
                    onChange={(e) => handleChangeSplitData(e, 'account', index)}
                  />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
                  <Input
                    id={`splits[${item.id}].digit`}
                    name={`splits[${item.id}].digit`}
                    maxLength="1"
                    label={t('digito')}
                    type="text"
                    onChange={(e) => handleChangeSplitData(e, 'digit', index)}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                  <Input
                    mask="currency"
                    id={`splits[${item.id}].percent`}
                    name={`splits[${item.id}].percent`}
                    value={item.percent}
                    maxValue={100}
                    label={t('porcentagem de recebimento')}
                    type="text"
                    onChangeEvent={(e, _a, _floatValue) => handleChangeSplitData(e, 'percent', index, _floatValue)}
                    // onChange={(e) => handleChangeSplitData(e, 'percent', index, floatValue)}
                  />
                </Col>
              </Row>
              <span
                onClick={() => {
                  const splitIndex = split.findIndex((i) => i.id === item.id);
                  split.splice(splitIndex, 1);
                  const newSplit = [...split];
                  setSplit(newSplit);
                }}
                style={{
                  color: settings.colors.primary,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {t('remover esse split')}
              </span>
            </div>
          ))}
          <CenterContent
            onClick={handleAddSplit}
            className="bottom"
            primaryColor={settings.colors.primary}
          >
            <FaPlus size={15} style={{ marginRight: 10 }} />
            {t('adicionar split de pagamento')}
          </CenterContent>
        </>
      );
    }
    return (
      <>
        <CenterContent
          onClick={handleAddSplit}
          className="bottom"
          primaryColor={settings.colors.primary}
        >
          <FaPlus size={15} style={{ marginRight: 10 }} />
          {t('adicionar split de pagamento')}
        </CenterContent>
      </>
    );
  }, [t, split, settings, handleAddSplit, banks, setSplit, handleChangeSplitData]);

  return billet ? (
    <>
      <div
        style={{
          maxWidth: '600px',
          background: '#f5f5f5',
          borderRadius: '4px',
        }}
      >
        <div
          style={{
            background: settings.colors.primary,
            padding: '5px',
            borderRadius: '4px',
            color: '#F9F9F9',
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 17,
          }}
        >
          {t('dados do boleto')}
        </div>

        <div style={{ padding: '24px' }}>
          <div
            style={{
              fontSize: 18,
              fontWeight: 600,
              textAlign: 'center',
              letterSpacing: '1px',
            }}
          >
            {billet.digitable_line}
          </div>

          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: 16, margin: '15px 0' }}>
              <CopyToClipboard
                text={billet.digitable_line}
                onCopy={() => {
                  notification.success({
                    message: t('linha digitavel'),
                    description: t('linha digitavel copiada com sucesso'),
                  });
                }}
              >
                <ButtonText>{t('toque aqui para copiar')}</ButtonText>
              </CopyToClipboard>
            </div>

            <div style={{ marginBottom: '15px' }}>
              <div>
                {t('valor')}{' '}
                <span style={{ fontWeight: 600 }}>
                  {HandleFormatMoney(billet.value)}
                </span>
              </div>
              <div>
                {t('vencimento')}{' '}
                <span style={{ fontWeight: 600 }}>
                  {formatDate(
                    addHours(new Date(billet.due_at), 3).toISOString(),
                  )}
                </span>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonText>
              <a href={billet.file_url} target="_blank" rel="noreferrer">
                {t('imprimir boleto')}
              </a>
            </ButtonText>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
          <Input
            mask="currency"
            id="value"
            name="value"
            value={amount}
            onChangeEvent={changeValue}
            label={t('valor da cobrança')}
            type="text"
          />
        </Col>
      </Row>

      <>
        <Row hidden={amount === null} gutter={16}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
            <Input
              mask="currency"
              id="juros"
              name="juros"
              label={t('juros ao dia')}
              value={juros}
              onChangeEvent={changeValue}
              type="text"
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
            <Input
              mask="currency"
              id="multa"
              name="multa"
              value={multa}
              onChangeEvent={changeValue}
              label={t('multa')}
              type="text"
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          hidden={amount === null || juros === null || multa === null}
        >
          <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
            <Input
              id="date"
              name="date"
              label={t('data de vencimento')}
              type="date"
              className="bottom"
              onChange={() => {
                setRerender(!rerender);
              }}
            />
          </Col>
        </Row>
        <Row
          hidden={!formRef.current || !formRef.current.getFieldValue('date')}
          gutter={16}
        >
          <Col xs={20} sm={20} md={20} lg={20} xl={20} className="bottom">
            <Input
              id="description"
              name="description"
              label={t('descricao da cobranca')}
              className="bottom"
              onChange={() => {
                setRerender(!rerender);
              }}
            />
          </Col>
        </Row>

        <Col
          hidden={
            !formRef.current || !formRef.current.getFieldValue('description')
          }
          xs={20}
          sm={20}
          md={20}
          lg={20}
          xl={20}
          className="bottom"
        >
          <ChargeSubtitle primaryColor={settings.colors.primary}>
            {t('quem sera cobrado')}
          </ChargeSubtitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 30,
            }}
          >
            <TopicMarker />
            <h3 style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              {t('insira os dados da pessoa que sera cobrada')}
            </h3>
            <PopOverPrevious
              handleSelection={handleLastChargedSelection}
              primaryColor={settings.colors.primary}
              data={latestCharged}
            />
          </div>
        </Col>

        <Row
          hidden={
            !formRef.current || !formRef.current.getFieldValue('description')
          }
          gutter={16}
        >
          <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
            <Input
              id="document"
              name="document"
              label={t('cpf / cnpj')}
              type="text"
              value={documentMask(document)}
              onChange={(e) => {
                setSelectedCharged(null);
                setDocument(e.target.value?.replace(/[^0-9]/g, ''));
              }}
            />
          </Col>
        </Row>
        {document
        && document.length >= 11
        && !showSkeleton
        && formRef.current.getFieldValue('description') ? (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={20} lg={20} xl={20} className="bottom">
                <Input
                  id="name"
                  name="name"
                  label={t('nome completo')}
                  type="text"
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={7} lg={7} xl={7} className="bottom">
                <Input
                  id="cep"
                  value={cep}
                  name="cep"
                  onChange={(e) => {
                    setSelectedCharged(null);
                    setCep(e.target.value);
                  }}
                  label={t('cep')}
                  type="text"
                />
              </Col>
            </Row>
            {cep && (cep.length === 8 || cep.length === 9) && !showSkeleton && (
              <>
                <Row gutter={16}>
                  <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={20}
                    xl={20}
                    className="bottom"
                  >
                    <Input
                      id="logradouro"
                      name="logradouro"
                      label={t('logradouro')}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={5} lg={5} xl={5} className="bottom">
                    <Input
                      id="number"
                      name="number"
                      label={t('numero')}
                      type="text"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                    <Input
                      id="complement"
                      name="complement"
                      label={t('complemento')}
                      type="text"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={7} lg={7} xl={7} className="bottom">
                    <Input
                      id="neighborhood"
                      name="neighborhood"
                      label={t('bairro')}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col
                    xs={24}
                    sm={24}
                    md={10}
                    lg={10}
                    xl={10}
                    className="bottom"
                  >
                    <Input
                      id="city"
                      name="city"
                      label={t('cidade')}
                      type="text"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={5} lg={5} xl={5} className="bottom">
                    <Input
                      id="state"
                      name="state"
                      label={t('estado')}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col
                    xs={24}
                    sm={24}
                    md={10}
                    lg={10}
                    xl={10}
                    className="bottom"
                  >
                    <Input
                      id="email"
                      name="email"
                      label={t('email')}
                      type="text"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                    <Input
                      mask="99 999999999"
                      name="phone"
                      id="phone"
                      label={t('celular')}
                      type="phone"
                    />
                  </Col>
                </Row>
                <ChargeSubtitle
                  primaryColor={settings.colors.primary}
                  className="bottom"
                >
                  {t('alem de voce...')}
                </ChargeSubtitle>
                {splitsContent}
                <Row gutter={16}>
                  <Col md={24}>
                    <Button
                      disabled={isLoading}
                      right
                      type="submit"
                    >
                      <span>{t('gerar cobranca')}</span>
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
          ) : (
            <></>
          )}
      </>

      <Skeleton
        active
        title={t('carregando')}
        loading={showSkeleton}
        paragraph={2}
        rows={2}
      />
      <Skeleton
        active
        title={t('carregando')}
        loading={showSkeleton}
        paragraph={2}
        rows={2}
      />
      <Skeleton
        active
        title={t('carregando')}
        loading={showSkeleton}
        paragraph={2}
        rows={2}
      />
    </>
  );
};

export default BilletFields;
