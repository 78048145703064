import React from 'react';
import { MdCheck as Check } from 'react-icons/md';
import { useShop } from '../../../hooks/shop';
import {
  InputColumn, InputWrapper, TimeInputWrapper, CheckBoxWrapper,
} from './styles';
import { FormInput as Input } from '../../FormInput';
import { inputValidator } from '../../../utilitys/Masks';
import TimeInput from '../../TimeInput';
import WorkDaysInput from '../../workDays';
import CheckBox from '../../Checkbox';
import Footer from '../../Footer';
import shopService from '../../../services/shopService';

export const ShopData = ({ hasDefaultFooter = true, allInputDisabled = false, displayTime = false }) => {
  const {
    setShopInfo,
    shopInfo,
    handleCheckboxChange,
    handleBooleanCheckBoxChange,
    handleChange,
    handleAddressChange,
    isOpenAllDay,
    setIsOpenAllDay,
  } = useShop();
  const [addressFound, setAddressFound] = React.useState(false);

  function handleOpenAllDay() {
    const date = new Date();
    const todayDate = date.setDate(date.getDate());
    const todayDateFormated = new Date(todayDate).setHours(0, 0);
    const tomorrowDateFormated = new Date(todayDate).setHours(0, 0);

    setShopInfo((prevValue) => ({ ...prevValue, start_store_operation: String(todayDateFormated), end_store_operation: String(tomorrowDateFormated) }));
  }

  async function handleGetShopAddress(cep) {
    const response = await shopService.getShopAdress(cep);
    if (response.erro) {
      setAddressFound(true);
      setShopInfo((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          street: '',
          neighborhood: '',
          city: '',
          state: '',
        },
      }));
      return;
    }
    setAddressFound(false);
    setShopInfo((prevValue) => ({
      ...prevValue,
      address: {
        ...prevValue.address,
        street: response.logradouro,
        neighborhood: response.bairro,
        city: response.localidade,
        state: response.uf,
      },
    }));
  }
  React.useEffect(() => {
    if (shopInfo?.address?.zip_code?.length <= 1 && !allInputDisabled) {
      setAddressFound(false);
      setShopInfo((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          street: '',
          neighborhood: '',
          city: '',
          state: '',
        },
      }));
    }
    if (shopInfo?.address?.zip_code?.length === 8 && !allInputDisabled) {
      handleGetShopAddress(shopInfo?.address?.zip_code);
    }
  }, [shopInfo?.address?.zip_code]);

  React.useEffect(() => {
    if (isOpenAllDay) {
      handleOpenAllDay();
    }
  }, [isOpenAllDay]);

  return (
    <>
      <InputColumn>
        <Input
          label="Nome da loja"
          errorMessage="Nome deve ter no mínimo 3 caracteres"
          placeholder="Insira o nome da loja"
          width="25%"
          value={shopInfo?.name}
          name="name"
          isDisabled={allInputDisabled}
          isValid={inputValidator.name(shopInfo?.name)}
          handleChange={handleChange}
          readOnly={allInputDisabled}
          isRequired
        />
        <WorkDaysInput
          handleCheckboxChange={handleCheckboxChange}
          shopInfo={shopInfo}
          isDisabled={allInputDisabled}
        />
        <TimeInputWrapper>
          <TimeInput
            label="De"
            name="start_store_operation"
            isRequired
            isDisabled={allInputDisabled}
            isDisplay={allInputDisabled || displayTime || isOpenAllDay}

          />
          <TimeInput
            label="Até"
            name="end_store_operation"
            isRequired
            isDisabled={allInputDisabled}
            isDisplay={allInputDisabled || displayTime || isOpenAllDay}

          />
          <CheckBoxWrapper>
            <CheckBox
              onCheck={allInputDisabled ? null : () => setIsOpenAllDay(!isOpenAllDay)}
              isChecked={isOpenAllDay}
              isDisabled={allInputDisabled}
            >
              {isOpenAllDay && <Check color="white" size={15} />}
            </CheckBox>
            <span>24 horas</span>
          </CheckBoxWrapper>
        </TimeInputWrapper>
      </InputColumn>
      <InputColumn>
        <Input
          label="Compra mínima"
          placeholder="R$"
          width="15%"
          value={shopInfo?.minimun_purchase}
          name="minimun_purchase"
          maxLength={12}
          mask="cash"
          isDisabled={allInputDisabled}
          handleChange={handleChange}
          readOnly={allInputDisabled}
          hasHelp
        />
        <InputWrapper size="35%">
          <Input
            label="Telefone"
            placeholder="(DDD) 00000-0000"
            width="100%"
            value={shopInfo?.phone}
            isValid={inputValidator.phone(shopInfo?.phone)}
            maxLength={15}
            name="phone"
            mask="phone"
            isRequired
            isDisabled={allInputDisabled}
            handleChange={handleChange}
            readOnly={allInputDisabled}
            errorMessage="Telefone inválido"
          />
          <CheckBoxWrapper>
            <CheckBox
              onCheck={allInputDisabled ? null : () => handleBooleanCheckBoxChange('show_phone')}
              isChecked={shopInfo?.show_phone}
              isDisabled={allInputDisabled}
            >
              {shopInfo.is_closed && <Check color="white" size={15} />}
            </CheckBox>
            <span>Não exibir no perfil</span>
          </CheckBoxWrapper>
        </InputWrapper>
        <InputWrapper size="45%">
          <Input
            label="E-mail "
            placeholder="admin@email.com"
            width="100%"
            value={shopInfo?.email}
            name="email"
            isRequired
            isValid={inputValidator.email(shopInfo?.email)}
            isDisabled={allInputDisabled}
            handleChange={handleChange}
            readOnly={allInputDisabled}
            errorMessage="E-mail inválido"
          />
          <CheckBoxWrapper>
            <CheckBox
              onCheck={allInputDisabled ? null : () => handleBooleanCheckBoxChange('show_email')}
              isChecked={shopInfo?.show_email}
              isDisabled={allInputDisabled}
            >
              {shopInfo?.show_email && <Check color="white" size={15} />}
            </CheckBox>
            <span>Não exibir no perfil</span>
          </CheckBoxWrapper>
        </InputWrapper>

      </InputColumn>
      <InputColumn>
        <Input
          label="CEP"
          width="23%"
          placeholder="00000-000"
          value={shopInfo?.address?.zip_code}
          isValid={!addressFound}
          errorMessage="CEP não encontrado"
          maxLength={8}
          name="zip_code"
          mask="cep"
          isAddress
          isRequired
          isDisabled={allInputDisabled}
          handleChange={handleAddressChange}
          readOnly={allInputDisabled}

        />
        <Input
          label="Estado"
          isRequired
          width="23%"
          placeholder="Insira o estado"
          value={shopInfo?.address?.state}
          name="state"
          isAddress
          isDisabled={allInputDisabled || (shopInfo?.address?.zip_code?.length === 8 && !addressFound)}
          handleChange={handleAddressChange}
          readOnly={allInputDisabled}
        />
        <Input
          label="Cidade"
          isRequired
          width="23%"
          placeholder="Insira a cidade"
          value={shopInfo?.address?.city}
          name="city"
          isAddress
          isDisabled={allInputDisabled || (shopInfo?.address?.zip_code?.length === 8 && !addressFound)}
          handleChange={handleAddressChange}
          readOnly={allInputDisabled}
        />
        <Input
          label="Bairro"
          isRequired
          width="23%"
          placeholder="Insira o bairro"
          value={shopInfo?.address?.neighborhood}
          name="neighborhood"
          isAddress
          isDisabled={allInputDisabled}
          handleChange={handleAddressChange}
          readOnly={allInputDisabled}
        />
      </InputColumn>
      <InputColumn>
        <Input
          label="Rua"
          isRequired
          placeholder="Nome da rua"
          width="50%"
          value={shopInfo?.address?.street}
          name="street"
          isAddress
          isDisabled={allInputDisabled}
          handleChange={handleAddressChange}
          readOnly={allInputDisabled}
        />

        <InputWrapper size="35%">
          <Input
            label="Número"
            placeholder="Digite o número"
            width="10%"
            value={shopInfo?.address?.number}
            name="number"
            isAddress
            isDisabled={allInputDisabled}
            handleChange={handleAddressChange}
            readOnly={allInputDisabled}
            maxLength={10}
          />
          <Input
            label="Complemento"
            placeholder="E: ap 102"
            width="10%"
            value={shopInfo?.address?.complementary_address}
            name="complementary_address"
            isAddress
            isDisabled={allInputDisabled}
            handleChange={handleAddressChange}
            readOnly={allInputDisabled}
          />
          <CheckBoxWrapper>
            <CheckBox
              onCheck={allInputDisabled ? null : () => handleBooleanCheckBoxChange('show_address')}
              isChecked={shopInfo?.show_address}
              isDisabled={allInputDisabled}
            >
              {shopInfo?.show_email && <Check color="white" size={15} />}
            </CheckBox>
            <span>Não exibir no perfil</span>
          </CheckBoxWrapper>
        </InputWrapper>
      </InputColumn>
      {hasDefaultFooter && <Footer />}
    </>
  );
};
