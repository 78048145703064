import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 50px;
`;

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap:5px;
  p {
    margin:0;
    padding:0;
    color:red;

  }
`;

export const ColorInputWrapper = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
