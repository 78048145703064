import styled from 'styled-components';

export const NumberContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
 `;
