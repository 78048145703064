export const federationUnitList = [
  {
    id: 12, sigla: 'AC', name: 'Acre', regiao: { id: 1, sigla: 'N', name: 'Norte' },
  },
  {
    id: 27, sigla: 'AL', name: 'Alagoas', regiao: { id: 2, sigla: 'NE', name: 'Nordeste' },
  },
  {
    id: 16, sigla: 'AP', name: 'Amapá', regiao: { id: 1, sigla: 'N', name: 'Norte' },
  },
  {
    id: 13, sigla: 'AM', name: 'Amazonas', regiao: { id: 1, sigla: 'N', name: 'Norte' },
  },
  {
    id: 29, sigla: 'BA', name: 'Bahia', regiao: { id: 2, sigla: 'NE', name: 'Nordeste' },
  },
  {
    id: 23, sigla: 'CE', name: 'Ceará', regiao: { id: 2, sigla: 'NE', name: 'Nordeste' },
  },
  {
    id: 53, sigla: 'DF', name: 'Distrito Federal', regiao: { id: 5, sigla: 'CO', name: 'Centro-Oeste' },
  },
  {
    id: 32, sigla: 'ES', name: 'Espírito Santo', regiao: { id: 3, sigla: 'SE', name: 'Sudeste' },
  },
  {
    id: 52, sigla: 'GO', name: 'Goiás', regiao: { id: 5, sigla: 'CO', name: 'Centro-Oeste' },
  },
  {
    id: 21, sigla: 'MA', name: 'Maranhão', regiao: { id: 2, sigla: 'NE', name: 'Nordeste' },
  },
  {
    id: 51, sigla: 'MT', name: 'Mato Grosso', regiao: { id: 5, sigla: 'CO', name: 'Centro-Oeste' },
  },
  {
    id: 50, sigla: 'MS', name: 'Mato Grosso do Sul', regiao: { id: 5, sigla: 'CO', name: 'Centro-Oeste' },
  },
  {
    id: 31, sigla: 'MG', name: 'Minas Gerais', regiao: { id: 3, sigla: 'SE', name: 'Sudeste' },
  },
  {
    id: 15, sigla: 'PA', name: 'Pará', regiao: { id: 1, sigla: 'N', name: 'Norte' },
  },
  {
    id: 25, sigla: 'PB', name: 'Paraíba', regiao: { id: 2, sigla: 'NE', name: 'Nordeste' },
  },
  {
    id: 41, sigla: 'PR', name: 'Paraná', regiao: { id: 4, sigla: 'S', name: 'Sul' },
  },
  {
    id: 26, sigla: 'PE', name: 'Pernambuco', regiao: { id: 2, sigla: 'NE', name: 'Nordeste' },
  },
  {
    id: 22, sigla: 'PI', name: 'Piauí', regiao: { id: 2, sigla: 'NE', name: 'Nordeste' },
  },
  {
    id: 33, sigla: 'RJ', name: 'Rio de Janeiro', regiao: { id: 3, sigla: 'SE', name: 'Sudeste' },
  },
  {
    id: 24, sigla: 'RN', name: 'Rio Grande do Norte', regiao: { id: 2, sigla: 'NE', name: 'Nordeste' },
  },
  {
    id: 43, sigla: 'RS', name: 'Rio Grande do Sul', regiao: { id: 4, sigla: 'S', name: 'Sul' },
  },
  {
    id: 11, sigla: 'RO', name: 'Rondônia', regiao: { id: 1, sigla: 'N', name: 'Norte' },
  },
  {
    id: 14, sigla: 'RR', name: 'Roraima', regiao: { id: 1, sigla: 'N', name: 'Norte' },
  },
  {
    id: 42, sigla: 'SC', name: 'Santa Catarina', regiao: { id: 4, sigla: 'S', name: 'Sul' },
  },
  {
    id: 35, sigla: 'SP', name: 'São Paulo', regiao: { id: 3, sigla: 'SE', name: 'Sudeste' },
  },
  {
    id: 28, sigla: 'SE', name: 'Sergipe', regiao: { id: 2, sigla: 'NE', name: 'Nordeste' },
  },
  {
    id: 17, sigla: 'TO', name: 'Tocantins', regiao: { id: 1, sigla: 'N', name: 'Norte' },
  },
];
