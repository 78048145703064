/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import { Checkbox, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import ButtonN from '../../../../../components/ButtonN';
import InputN from '../../../../../components/InputN';
import Alert from '../../../../../components/SpanError';
import messages from '../../../../../components/MessageError';
import { useSteps } from '../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import { SubTitle, Title } from '../../../styles';
import { federationUnitList } from '../../../../../utilitys/federationUnitList';
import { getAddress } from '../../../../../services/getAddress';
import { handleAddress } from '../../../../../services/endPointRegister/address';
import { useSettings } from '../../../../../hooks/settings';
import { validNumber } from '../../../../RefreshDataPJ/utils/numberValidator';

const Address = () => {
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const { t } = useTranslation();
  const [finallist, setFinalList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [validBotton, setValidBotton] = useState(true);
  const [withoutNumber, setWithoutNumber] = useState(false);

  const { getAddressWithCep, getAddressWithStateInitials } = getAddress();
  const { settings } = useSettings();
  const {
    nextStep, step, stepsPj, stepsPf, exactStep, resetStep, stepsPjEnum,
  } = useSteps();
  const history = useHistory();
  const [addressObject, setAddressObject] = useState({
    zip_code: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
  });
  
  const validTextInput = /[A-Za-z]/g;
  useEffect(() => {
    // const isTheRightPage = checkpoint.steplist
    //   ? localStorage.getItem('@qesh:token')
    //  && (stepsPjEnum[localStorage.getItem('@qesh:step')] === 2 || sessionStorage.getItem('@qesh:step') === '2')
    //   : localStorage.getItem('@qesh:token') && sessionStorage.getItem('@qesh:step') === '7';
    // if (checkpoint?.password !== undefined /*|| isTheRightPage*/) {
      if (checkpoint.steplist) {
        exactStep(2);
      } else {
        exactStep(7);
      }
    // } else {
    //   resetStep();
    //   history.replace('/transaction');
    // }
  }, []);

  const generateListOfCities = async () => {
    const arrayOfCities = [];
    cityList.map((city) => {
      arrayOfCities.push({ id: city.id, name: city.nome });
    });
    setFinalList(arrayOfCities);
  };

  const searchAddressCep = async (zip_code, addressObject) => {
    setLoading(true);
    try {
      const get = await getAddressWithCep(zip_code, addressObject);
      if (!get.error) {
        setAddressObject(get);
        setError(false);
        const objectArray = [get.neighborhood, get.street];
        const validTest = await objectArray.every((elem) => elem.length > 1);
        setDisable(validTest);
      } else {
        setError(true);
        setAddressObject({
          zip_code: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.error('erro: ', error);
    }
  };
  const searchAddressWithStateInitials = async (stateInitials) => {
    try {
      const get = await getAddressWithStateInitials(stateInitials);
      setCityList(get);
    } catch (error) {
      console.error('erro: ', error);
    }
  };
  useEffect(() => {
    if (addressObject?.zip_code?.length === 9) {
      searchAddressCep(addressObject?.zip_code, addressObject);
    } else {
      setError(true);
    }
    if (addressObject?.zip_code.length < 1) {
      setAddressObject({
        ...addressObject,
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      });
    }
  }, [addressObject?.zip_code]);

  useEffect(() => {
    generateListOfCities();
  }, [cityList]);

  useEffect(() => {
    withoutNumber === undefined
  || +addressObject.number === 0
  || addressObject?.zip_code.length < 9
  || addressObject.number === ''
  || !validTextInput.test(addressObject.neighborhood)
  || addressObject.city === ''
  || addressObject.state === ''
  || !validTextInput.test(addressObject.street)
      ? setValidBotton(true)
      : setValidBotton(false);
  }, [
    addressObject?.zip_code,
    addressObject.street,
    addressObject.number,
    addressObject.complement,
    addressObject.neighborhood,
    addressObject.city,
    addressObject.state,
  ]);

  const handleComplement = () => (addressObject.complement === '' ? 'N/A' : addressObject.complement);
  const validHouseNumber = addressObject?.number?.length <= 0 || !validNumber.test(addressObject?.number);
  const handleAdvance = async () => {
    setLoading(true);
    let error = false;
    setCheckpoint({ ...checkpoint, addressData: addressObject });

    const finalObject = {
      ...addressObject,
      zip_code: addressObject.zip_code.replace('-', ''),
      country: 'Brasil',
      state: addressObject.state.initials,
      complement: handleComplement(),
    };
    try {
      if (checkpoint.steplist) {
        const requisition = await handleAddress(
          finalObject,
          checkpoint.cpfCnpj,
          checkpoint.password,
          settings.name,
          checkpoint.pjUserId,
        );
        error = requisition;
      } else {
        const requisition = await handleAddress(
          finalObject,
          checkpoint.cpfCnpj,
          checkpoint.password,
          settings.name,
        );
        error = requisition;
      }
    } catch (err) {
      setLoading(false);
      message.error('Ops, parece que ocorreu um erro. Tente novamente.', 4);
      setError(true);
    } finally {
      if (error) {
        setLoading(false);
        message.error('Ops, parece que ocorreu um erro. Tente novamente.', 4);
        setError(true);
      } else {
        setError(false);
        setLoading(false);
        if (checkpoint.steplist) {
          nextStep();
          if (stepsPj.length >= step + 1) {
            history.push(`/register/${stepsPj[step + 1]}`);
          } else {
            history.push(`/register/${stepsPj[step]}`);
          }
        }
        if (!checkpoint.steplist) {
          nextStep();
          if (stepsPf.length >= step + 1) {
            history.push(`/register/${stepsPf[step + 1]}`);
          } else {
            history.push(`/register/${stepsPf[step]}`);
          }
        }
      }
    }
  };


  return (
    <>
      {checkpoint.cpfCnpj?.length === 11 ? (
        <Title>Onde você mora? </Title>
      ) : (
        <Title>Qual o endereço da empresa? </Title>
      )}
      {checkpoint.cpfCnpj?.length === 11 ? (
        <SubTitle>
          <header>Nos informe seu endereço </header>
          <span>Agora precisamos que você nos passe suas informações residenciais.</span>
        </SubTitle>
      ) : (
        <SubTitle>
          <header>Nos informe o endereço da empresa </header>
          <span>Agora precisamos que você nos passe as informações sobre a localização da matriz.</span>
        </SubTitle>
      )}
      <InputN.Cep
        id="cep"
        label="CEP"
        numericOnly
        delimiter="-"
        blocks={[5, 3]}
        onChange={(e) => {
          setAddressObject({ ...addressObject, zip_code: e.target.value });
        }}
        value={addressObject.zip_code}
        error={error}
        placeholder="Insira o CEP"
        disabled={loading}
      />
      {!addressObject?.zip_code && addressObject?.zip_code.length < 1
     || addressObject?.zip_code.length < 9
     || error ? (
       <Alert message={messages.inputNull} type="error" />
        ) : null}
      {addressObject.zip_code === '' ? null : error ? (
        <Alert message={messages.invalidZipCode} type="error" />
      ) : null}
      <InputN
        maxLength={100}
        id="rua"
        label="Endereço"
        placeholder="Insira a rua"
        onChange={(e) => {
          let string = e.target.value;
          if (string.substring(0, 1) === ' ') {
            string = string.slice(1);
          }
          const inputText = string
            .replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ  ]/g, ' ')
            .replace('  ', ' ');
          const arrayOfWords = inputText.split(' ');
          for (let i = 0; i < arrayOfWords.length; i += 1) {
            arrayOfWords[i] = arrayOfWords[i].charAt(0).toUpperCase() + arrayOfWords[i].slice(1);
          }
          const finalString = arrayOfWords.join(' ');
          setAddressObject({ ...addressObject, street: finalString });
        }}
        value={addressObject.street}
        disabled={
     loading
     || (error && addressObject.state === '')
     || (addressObject.street && error === true)
     || disable
    }
      />
      <InputN
        maxLength={100}
        id="bairro"
        label="Bairro"
        placeholder="Insira o bairro"
        onChange={(e) => {
          let string = e.target.value;
          if (string.substring(0, 1) === ' ') {
            string = string.slice(1);
          }
          const inputText = string
            .replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ  ]/g, ' ')
            .replace('  ', ' ');
          const arrayOfWords = inputText.split(' ');
          for (let i = 0; i < arrayOfWords.length; i += 1) {
            arrayOfWords[i] = arrayOfWords[i].charAt(0).toUpperCase() + arrayOfWords[i].slice(1);
          }
          const finalString = arrayOfWords.join(' ');
          setAddressObject({ ...addressObject, neighborhood: finalString });
        }}
        value={addressObject.neighborhood}
        disabled={
     loading
     || (error && addressObject.state === '')
     || (addressObject.street && error === true)
     || disable
    }
      />
      <section style={{ flexDirection: 'row', gap: '1rem' }}>
        <InputN
          id="numero"
          label="Número"
          isValid={!validHouseNumber}
          maxLength={10}
          style={{ color: addressObject.number === 'Insira o número' ? 'gray' : '#000' }}
          placeholder="Insira o número"
          defaultValue="Insira o número"
          onChange={(e) => {
            const inputZeroOf = e.target.value.replace(/[^a-z1-9]/gi, '');
            if (e.target.value[0] === '0') {
              setAddressObject({ ...addressObject, number: inputZeroOf });
            }
            const inputText = e.target.value.replace(/[^a-z0-9]/gi, '');
            setAddressObject({ ...addressObject, number: inputText });
          }}
          value={addressObject.number}
          disabled={withoutNumber || loading}
        />
        <Checkbox
          style={{
            alignItems: 'baseline', paddingTop: '1rem', whiteSpace: 'nowrap', fontSize: '0.75rem',
          }}
          onChange={(e) => {
            if (e.target.checked) {
              setAddressObject({ ...addressObject, number: 'S/N' });
              setWithoutNumber(!withoutNumber);
            } else {
              setWithoutNumber(false);
              setAddressObject({ ...addressObject, number: '' });
            }
          }}
          disabled={loading}
        >
          Sem Número
        </Checkbox>
      </section>
      {addressObject.number.length > 10 ? <Alert message={messages.numberAddressLong} type="error" /> : null}
      {addressObject.number === '' ? null : withoutNumber === false
     && (!addressObject.number || +addressObject.number === 0) ? (
       <Alert message={messages.inputNull} type="error" />
        ) : null}
      <InputN
        maxLength={100}
        id="complemento"
        label="Complemento"
        placeholder="Apartamento, quadra, bloco"
        onChange={(e) => {
          let string = e.target.value;
          if (string.substring(0, 1) === ' ') {
            string = string.slice(1);
          }
          const inputText = string
            .replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ0-9 ]/g, '')
            .replace('  ', ' ');
          const arrayOfWords = inputText.split(' ');
          for (let i = 0; i < arrayOfWords.length; i += 1) {
            arrayOfWords[i] = arrayOfWords[i].charAt(0).toUpperCase() + arrayOfWords[i].slice(1);
          }
          const finalString = arrayOfWords.join(' ');

          setAddressObject({ ...addressObject, complement: finalString });
        }}
        value={addressObject.complement}
        disabled={loading}
      />
      <InputN.SelectandSearchModal
        id="estado"
        label="Estado"
        readOnly
        disabled
        placeholder="Escolha uma opção"
        value={addressObject.state ? `${addressObject.state.name}` : ''}
        onChange={(value) => {
          searchAddressWithStateInitials(value[0].sigla);
          setAddressObject({
            ...addressObject,
            state: { name: value[0].name, initials: value[0].sigla },
            city: '',
          });
        }}
        list={federationUnitList}
        needSearchInput={false}
        title={(
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              width: '100%',
              marginBottom: '1rem',
              fontWeight: '700',
              color: 'var(--label)',
              height: '4rem',
              padding: '0 3rem',
            }}
          >
            {t('Escolha a Unidade Federativa a qual você pertence')}
          </p>
   )}
      />
      <InputN.SelectandSearchModal
        id="cidade"
        label="Cidade"
        placeholder={addressObject.state === '' ? 'Escolha uma estado' : 'Escolha uma opção'}
        value={finallist ? `${addressObject.city}` : ''}
        onChange={(value) => {
          setAddressObject({ ...addressObject, city: value[0].name });
        }}
        list={finallist}
        disabled={addressObject.state === '' || loading || addressObject.city.length > 0}
        needSearchInput
        title={(
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.25rem',
              width: '100%',
              marginBottom: '1rem',
              fontWeight: '700',
              color: 'var(--label)',
              height: '4rem',
              padding: '0 3rem',
            }}
          >
            {t('Escolha a Unidade Federativa a qual você pertence')}
          </p>
   )}
      />
      <ButtonN
        disabled={validBotton === true || error === true}
        type="primary"
        className="botao"
        onClick={() => handleAdvance()}
        icon={loading && <Spin indicator={<LoadingOutlined />} />}
        loading={loading}
      >
        {!loading && 'Continuar'}
      </ButtonN>
    </>
  );
};

export default Address;
