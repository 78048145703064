/* eslint-disable no-tabs */
/* eslint-disable react/prop-types */
/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';

import { Container } from './styles';
import { useSettings } from '../../hooks/settings';

function RegisterHeader(props) {
  const { settings } = useSettings();
  const { title, noTitle } = props;

  const setTitle = (titulo) => {
    switch (titulo) {
      case 'EMAIL':
        return 'Dados de Contato';
      case 'DOC_TYPE':
        return 'Envio de documentos';
      case 'PHONE':
        return 'Dados de Contato';
      case 'SELFIE':
      case 'FRONT':
      case 'VERSE':
      case 'COMPANY':
        return 'Envio de documentos';
      default:
        return 'Dados de Cadastro';
    }
  };

  return settings && settings.name ? (
    <Container style={{ backgroundColor: settings?.colors?.header }}>
      <div
        style={{
				  display: 'flex',
				  justifyContent: 'center',
				  alignItems: 'center',
        }}
      >
        <img
          style={{ width: 130, backgroundColor: 'transparent' }}
          src={settings.default_logo}
          alt={settings.display_name}
        />
        {noTitle ? (
          <></>
        ) : (
          <div
            style={{
						  display: 'flex',
						  alignItems: 'center',
						  fontSize: '25px',
						  borderLeft: '2px solid gray',
						  opacity: '0.3',
						  minHeight: 50,
						  padding: 10,
            }}
          >
            <span>{setTitle(title)}</span>
          </div>
        )}
      </div>
    </Container>
  ) : (
    <></>
  );
}

export default RegisterHeader;
