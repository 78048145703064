/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Container } from './styles';

function Input(props) {
  const inputRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [focus, setFocus] = useState(false);

  const {
    fieldName, defaultValue, error, registerField,
  } = useField(props.name);

  useEffect(() => {
    if (props.select) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
        getValue: (ref, _value) => {
          if (props.select) {
            if (props.isMulti) {
              if (!ref.state.value) {
                return [];
              }
              return ref.state.value.map((option) => option.value);
            }

            if (!ref.state.value) {
              return '';
            }

            return ref.state.value.value;
          }

          return ref.value;
        },
        setValue: (ref, value) => {
          ref.select.setValue(value || null);
        },
        clearValue: (ref) => {
          ref.select.clearValue();
        },
      });
    } else if (props.mask && props.mask !== 'currency') {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
        // setValue(ref, value) {
        //   ref.setInputValue(value);
        // },
        // clearValue(ref) {
        //   ref.setInputValue('');
        // },
      });
    } else if (props.mask === 'currency') {
      registerField({
        name: fieldName,
        ref: inputRef.current.state,
        path: 'value',
      });
    } else {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      });
    }
  }, [fieldName, registerField, props.isMulti, props.selectm, props.mask, props.masked, props.select]);

  return (
    <Container error={error} eye={props.eye} focus={focus} disabled={props.disabled || props.isDisabled}>
      <>
        {props.eye && (
        <i>
          {!visible ? (
            <EyeOutlined style={{ fontSize: '30px' }} onClick={() => setVisible(!visible)} />
          )
            : (
              <EyeInvisibleOutlined style={{ fontSize: '30px' }} onClick={() => setVisible(!visible)} />
            )}
        </i>
        )}
        <label className={props.select ? 'select' : null} htmlFor={props.id}>{props.label}</label>
        {props.mask ? (
          <>
            {props.mask === 'currency' ? (
              <CurrencyInput
                autoComplete="off"
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                decimalSeparator=","
                thousandSeparator="."
                ref={inputRef}
                {...props}
              />
            ) : (
              <InputMask
                autoComplete="off"
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                defaultValue={defaultValue}
                ref={inputRef}
                {...props}
              />
            )}
          </>
        ) : (
          <>
            {props.eye ? (
              <input
                autoComplete="off"
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                defaultValue={defaultValue}
                ref={inputRef}
                {...props}
                type={visible ? 'text' : 'password'}
              />
            ) : (
              <>
                {props.select ? (
                  <Select
                    defaultValue={defaultValue}
                    ref={inputRef}
                    classNamePrefix="react-select"
                    {...props}
                  />
                ) : (
                  <input
                    autoComplete="off"
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    defaultValue={defaultValue}
                    ref={inputRef}
                    {...props}
                  />
                )}
              </>

            )}
          </>
        )}
      </>

      {error && <div className="error-box">{error}</div>}

    </Container>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  eye: PropTypes.string,
  error: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

Input.defaultProps = {
  eye: undefined,
  error: undefined,
  mask: undefined,
};

export default Input;
