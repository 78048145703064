import React, { useState } from 'react';

import { message } from 'antd';
import { GoSaffeCapture } from '@go.saffe/go-saffe-react-js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSettings } from '../../../../hooks/settings';
import {
  Box,
} from './styles';
import api from '../../../../services/api';
import { useRefresh } from '../../../../hooks/refresh';
import { CheckpointService } from '../../../../services/endPointRegister/CheckpointService';
import Notification from '../../../../components/Notification';
import { useAuth } from "../../../../hooks/auth";

const Cam = () => {
  const {
    token, setStep, setPhotoAttempts,
  } = useRefresh();
  const [image, setImage] = useState('');
  const [shouldSendGeneralPendency, _] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selfieUnique, setSelfieUnique] = React.useState(false);
  const [error, setError] = useState("");
  const [notify, setNotify] = useState(false);
  const [infos, setInfos] = React.useState({
    apiKey: process.env.REACT_APP_API_KEY || '',
    user: '',
    type: 'onboarding',
    endToEndId: '',
  });
  const { getEmailSupportMessage, settings } = useSettings();
  const { signOut } = useAuth();

  const history = useHistory();

  React.useEffect(() => {
    if (settings.name === 'nmpay' || settings.name === 'qesh') {
      const getCheckpoints = async () => {
        try {
          const { res } = await CheckpointService(settings.name);

          const hasSelfieUnique = res.response.find(
            (checkpoint) => checkpoint === 'SelfieUnique',
          );

          // eslint-disable-next-line no-unneeded-ternary
          setSelfieUnique(hasSelfieUnique ? true : false);
        } catch (error) {
          console.log('Error getCheckpoints', error);
        }
      };

      getCheckpoints();
    }
  }, [settings.name]);

  function handleValidateSelfie() {
    api
      .post('onboarding/recognition/validate', {}, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `bearer ${token}`,
        },
      })
      .then((data) => {
        setLoading(false);
        setError(false);
        setImage("");
        setStep('done');
      }).catch((error) => {
        setLoading(false);
        setError(true);
        setImage("");
        setPhotoAttempts(error?.response?.data?.message);
        if (error?.response?.data?.message?.includes('BLACKLIST')) {
          setError(getEmailSupportMessage(_, true));
          setLoading(false);
          return;
        }
        if (
          error?.response?.data?.message
          === "Internal Server Error"
        ) {
          message?.error(
            "Erro interno",
            6,
          );
        } else if (error?.response?.data?.message
          === "connect ECONNREFUSED 172.20.0.10:3043 - Search Person") {
          message?.error(
            "Erro interno",
            6,
          );
        }
      });
  }

  function getUser() {
    const userRefreshData = localStorage.getItem("@qesh:userRefresh");
    const userData = localStorage.getItem("@qesh:UserData");

    if (userRefreshData && userData) {
      const userRefresh = JSON.parse(userRefreshData);
      const user = JSON.parse(userData);

      setInfos((infos) => ({
        ...infos,
        user: userRefresh.user.email,
        endToEndId: user.id,
      }));
    }

    setLoading(false);
  }

  React.useEffect(() => {
    getUser();
  }, []);

  function redirectRefresh() {
    signOut();
    history.replace("/");
  }

  return (
    <div>
      <Box>
        {!loading && (
        <GoSaffeCapture
          captureKey={infos.apiKey}
          endToEndId={infos.endToEndId}
          type="onboarding"
          user={infos.user}
          onClose={() => console.log("close")}
          onFinish={handleValidateSelfie}
        />
        )}
      </Box>
      <Notification
        shouldSendGeneralPendency={shouldSendGeneralPendency}
        type="success"
        visible={error}
        isNotify={notify}
        setIsNotify={setNotify}
        title=""
        hidden={() => redirectRefresh()}
      >
        {error}
      </Notification>
    </div>
  );
};

export default Cam;
