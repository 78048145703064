/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container, Title, Text, ButtonDisposition,
} from './styles';
import ButtonN from '../../../../../../components/ButtonN';
import LowerLeftMessage from '../../../../../../components/LowerLeftMessage';
import { useSteps } from '../../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../../hooks/checkpoint';

const FailureInProofOfLife = () => {
  const { exactStep, stepsPf } = useSteps();
  const { checkpoint } = useCheckpoint();
  const history = useHistory();

  const returnToSelfie = () => {
    if (checkpoint.cpfCnpj.length === 11) {
      history.push(`/register/${stepsPf[exactStep(stepsPf.length - 4)]}`);
    }
  };
  const returnToDoccument = () => {
    if (checkpoint.cpfCnpj.length === 11) {
      history.push(`/register/${stepsPf[exactStep(stepsPf.length - 3)]}`);
    }
  };
  return (
    <>
      <Container>
        <Title>Ops!</Title>
        <Text>
          Não conseguimos associar a foto de rosto com a foto do documento enviado. Mas relaxa! Escolha uma
          das opções abaixo para continuarmos.
        </Text>
        <ButtonDisposition>
          <ButtonN
            onClick={() => {
              returnToDoccument();
            }}
            type="primary"
            className="botao"
            style={{ width: '130%', minWidth: '100%' }}
          >
            enviar outra foto dos documentos
          </ButtonN>
          <ButtonN
            onClick={() => {
              returnToSelfie();
            }}
            className="botao"
            style={{ width: '130%', minWidth: '100%' }}
          >
            tirar outra foto de rosto
          </ButtonN>
        </ButtonDisposition>
        <LowerLeftMessage />
      </Container>
    </>
  );
};

export default FailureInProofOfLife;
