import styled from 'styled-components';

export const Container = styled.div`
display: flex;
width: 70%;
height: 100%;
align-items: center;
flex-direction: column;
`;

export const Title = styled.span`
font-size: 20px;
font-weight: 700;
text-align: center;
margin-bottom: 3rem;
`;

export const Text = styled.span`
align-items: center;
font-size: 16px;
margin-bottom: 1rem;
display: flex;
gap: 0.5rem;

.text-icon {
margin-right: 15px;
}
`;

export const Tips = styled.div`
display: flex;
gap: 0.5rem;
align-items: left;
flex-direction: column;
`;
