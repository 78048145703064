import styled from 'styled-components';

export const Content = styled.div`
 display: flex;
 flex-direction: row;
 gap: 10px;
 align-items: center;
 justify-content: center;
 width: 100%;

 @media (max-width: 500px) {
  width: 300px;
  padding: 0 20px;
 }
`;

export const Input = styled.input`
 font-size: 36px;
 text-align: center;
 margin: 0 8px;
 width: 48px;
 height: 72px;
 border: 1px solid #42c1c7;
 border-radius: 8px;
 color: #42c1c7;
 background-color: transparent;
 text-shadow: 0px 0px 1px '#6F6F6F';
 ::-webkit-outer-spin-button,
 ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 :hover,
 :focus {
  border-color: var(--primary);
 }
`;
