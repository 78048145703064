/* eslint-disable react/prop-types */
import React, {
  useState, useCallback, useEffect,
} from 'react';
import { format, parseISO } from 'date-fns';

import { HandleFormatMoney, documentMask, hideDocument } from '../../../../services/utils';
import api from '../../../../services/api';

import { formatBranch, formatAccount } from '../utils';

import {
  Title, Content,
} from '../styles';
import { useAuth } from '../../../../hooks/auth';

function Receipt({ item, settings }) {
  const { account } = useAuth();

  const [participants, setParticipants] = useState([]);

  const loadParticipants = useCallback(() => {
    api.get('/pix/participants').then(({ data }) => {
      const p = data;
      setParticipants(p.map((partic) => ({ value: partic.ispb, label: `${partic.ispb} - ${partic.name}` })));
    });
  }, []);

  const getNameParticipantsByIspb = useCallback((ispb) => {
    const partic = participants.find((p) => p.value === ispb);
    if (partic) {
      return partic.label;
    }

    return ispb;
  }, [participants]);

  useEffect(() => {
    loadParticipants();
  }, [loadParticipants]);

  return (
    <>
      {item && item.id && (
      <>
        <Title separatorcolor={settings.colors.primary}>
          <h3>Comprovante de Pix</h3>
          <span>{format(parseISO(item.date), 'dd/MM/yyyy HH:mm')}</span>
        </Title>
        <Content separatorcolor={settings.colors.primary}>
          <section>
            <h3>Origem</h3>
            <div>
              <span>NOME:</span>
              <span>{String(account.user.name).toUpperCase()}</span>
            </div>
            <div>
              <span>PROCESSADORA:</span>
              <span>Qesh Payments</span>
            </div>
            <div>
              <span>DOCUMENTO:</span>
              <span>{documentMask('31818873000130')}</span>
            </div>
            <div>
              <span>INSTITUIÇÃO:</span>
              <span>CELCOIN PAGAMENTOS S.A.</span>
            </div>
          </section>

          <section>
            <h3>Destino</h3>
            <div>
              <span>NOME:</span>
              <span>{String(item.detail.beneficiary_name).toUpperCase()}</span>
            </div>
            <div>
              <span>DOCUMENTO:</span>
              <span>{hideDocument(documentMask(item.detail.beneficiary_document))}</span>
            </div>
            <div>
              <span>BANCO:</span>
              <span>{getNameParticipantsByIspb(item.detail.beneficiary_bank).toUpperCase()}</span>
            </div>
            <div>
              <span>AGÊNCIA:</span>
              <span>{formatBranch(item.detail.beneficiary_branch)}</span>
            </div>
            <div>
              <span>CONTA:</span>
              <span>{formatAccount(item.detail.beneficiary_account)}</span>
            </div>
          </section>
        </Content>

        <Content separatorcolor={settings.colors.primary}>
          <h3>Valor</h3>
          <h4>{HandleFormatMoney(item.amount)}</h4>
        </Content>

        <div style={{ textAlign: 'center', borderTop: '1px solid #f1f1f1', paddingTop: '10px' }}>
          <h5 style={{ fontWeight: 600, fontSize: '13px' }}>ID da transação</h5>
          <span>{item.detail.end_to_end_id}</span>
        </div>

        <div style={{ textAlign: 'center', borderTop: '1px solid #f1f1f1', paddingTop: '10px' }}>
          <h5 style={{ fontWeight: 600, fontSize: '13px' }}>Código de autenticação</h5>
          <span>{item.id}</span>
        </div>
      </>
      )}
    </>
  );
}

export default Receipt;
