import React, { useState, useEffect } from 'react';
import { message, Modal } from 'antd';
import {
  ButtonContainer,
  Container,
  InputContainer,
  LightText,
  ModalStyle,
  Subtitle,
  Text,
  TextBold,
  TextContainer,
  Title,
} from '../../styles';
import InputN from '../../../../components/InputN';
import ButtonN from '../../../../components/ButtonN';
import { useRefresh } from '../../../../hooks/refresh';
import getMasks from '../../utils/getMasks';
import { useSettings } from '../../../../hooks/settings';

export function Phone() {
  const {
    setStep, basicData, setBasicData, refreshApi, user,
  } = useRefresh();
  const [newPhone, setNewPhone] = useState('');
  const { settings } = useSettings();
  const [showModal, setShowModal] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);

  useEffect(() => {
    if (newPhone.length === 11) {
      setPhoneValid(true);
    } else {
      setPhoneValid(false);
    }
  }, [newPhone]);

  const handleNext = async () => {
    try {
      const body = {
        client_name: settings.name,
        document: user.document,
        contact: `${newPhone.substring(0, 2)} ${newPhone.substring(2)}`,
      };
      await refreshApi.sendCode(body);
      message.success(`Código enviado com sucesso para o número de telefone ${getMasks('phone', newPhone)}`);
      setBasicData({ ...basicData, phone: newPhone });
      setStep('codePhone');
    } catch (error) {
      if (error.message.includes('already validate')) {
        message.success('Número de telefone já validado!');
        setStep('contact');
        return;
      }
      if (error.message.includes('already registered')) {
        message.error('Número de telefone já cadastrado!');
        return;
      }
      if (error.message.includes('many trys')) {
        message.error('Número máximo de tentativas excecionadas, tente novamente amanhã');
        setShowModal(false);
        return;
      }
      console.log('error: ', error.message);
    }
  };

  const { phone } = basicData;
  return (
    <Container>
      <InputContainer>
        <TextContainer>
          <Title>Só mais alguns passos...</Title>
          <Subtitle>Qual o número do seu celular?</Subtitle>
          <Text>Agora vamos garantir que somente você terá acesso à sua conta.</Text>
        </TextContainer>
        <InputN.Mask
          id="actual-celular"
          label="Número de celular atual"
          numericOnly
          placeholder={getMasks('phone', phone)}
          blocks={[0, 2, 0, 1, 4, 4]}
          delimiters={['(', ')', ' ', ' ', '-']}
          disabled
        />
        <InputN.Mask
          id="conf-celular"
          label="Novo número de celular"
          numericOnly
          blocks={[0, 2, 0, 1, 4, 4]}
          delimiters={['(', ')', ' ', ' ', '-']}
          placeholder="(00) 0 0000-0000"
          onChange={(e) => setNewPhone(e.target.rawValue)}
          value={newPhone}
        />
        <LightText style={{ fontSize: '0.7rem' }}>Não se esqueça do DDD</LightText>
      </InputContainer>
      <ButtonN
        type="primary"
        disabled={!phoneValid}
        onClick={() => {
          setShowModal(true);
        }}
      >
        Receber código via SMS
      </ButtonN>
      <Modal
        visible={showModal}
        footer={null}
        closable={false}
        destroyOnClose
        focusTriggerAfterClose={false}
        onCancel={() => {
          setShowModal(false);
        }}
        bodyStyle={{ padding: '2.5rem' }}
      >
        <ModalStyle>
          <TextBold style={{ textAlign: 'center' }}>Seu número de celular</TextBold>
          <Text style={{ textAlign: 'center' }}>Confirme seu telefone de contato</Text>
          <ButtonContainer style={{ flexFlow: 'row nowrap' }}>
            <ButtonN
              type="default"
              className="botao"
              onClick={() => {
                setShowModal(false);
              }}
              style={{ marginTop: '1rem' }}
            >
              Alterar
            </ButtonN>
            <ButtonN type="primary" className="botao" onClick={handleNext} style={{ marginTop: '1rem' }}>
              Confirmar
            </ButtonN>
          </ButtonContainer>
        </ModalStyle>
      </Modal>
    </Container>
  );
}
