import { message, Spin } from 'antd';
import { useState, React } from 'react';
import ButtonN from '../../../components/ButtonN';
import { useRefresh } from '../../../hooks/refresh';
import { useSettings } from '../../../hooks/settings';
import { EditPen } from '../Components/EditPen';
import {
  Container, InputContainer, Subtitle, Text, TextContainer, Title,
} from '../styles';
import formatFullName from '../utils/formatFullName';
import getMasks from '../utils/getMasks';

export function Address() {
  const { setStep, address, refreshApi } = useRefresh();
  const [loading, setLoading] = useState(false);
  const { getEmailSupportMessage } = useSettings();
  const handleNext = async () => {
    try {
      setLoading(true);
      const {
        street, number, neighborhood, city, state, zipCode, country,
      } = address;
      await refreshApi.address({
        street, number, neighborhood, city, state, zip_code: zipCode, country,
      });
      setLoading(false);
      setStep('additional');
    } catch (error) {
      if (error.message === 'jwt expired') {
        message.error('Login expirado, favor fazer login novamente');
        return;
      }
      if (error.message.includes('BLACKLIST')) {
        message.error(`Infelizmente não vamos dar continuidade ao seu cadastro no momento. 
${getEmailSupportMessage()}`);
        return;
      }
      message.error(error.message);
      setLoading(false);
    }
  };

  const getFormatedFullAddress = () => {
    const {
      street, number, neighborhood, city, state, zipCode,
    } = address;
    return `${formatFullName(street)}, ${formatFullName(number)} - ${formatFullName(
      neighborhood,
    )}, ${formatFullName(city)} - ${state} - ${getMasks('zipCode', zipCode)}`;
  };

  const buttonDisabled = () => Object.keys(address).some((key) => (
    key === 'complement' ? false : address[key] === '' || !address[key]
  ));

  return (
    <Container>
      <InputContainer>
        <TextContainer>
          <Title>Endereço residencial</Title>
          <Subtitle>Qual o seu endereço?</Subtitle>
          <Text>Você pode alterar o endereço caso não seja mais o seu endereço atual.</Text>
        </TextContainer>
        <EditPen
          title="Endereço atual"
          text={buttonDisabled() ? 'Nenhum endereço cadastrado' : getFormatedFullAddress()}
          onClick={() => setStep('formAddress')}
        />
      </InputContainer>
      <ButtonN type="primary" onClick={handleNext} disabled={buttonDisabled()}>
        Continuar
      </ButtonN>
    </Container>
  );
}
