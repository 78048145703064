import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import { BiZoomIn as ZoomIn, BiZoomOut as ZoomOut, BiCheck as Check } from 'react-icons/bi';
import { FaExchangeAlt as ChangeIcon } from 'react-icons/fa';
import { useShop } from '../../hooks/shop';
import { useSettings } from '../../hooks/settings';
import {
  AvatarEditorWrapper, Button, ButtonsWrapper, Editor, Slider, SliderWrapper, Wrapper,
} from './styles';

const AvatarModal = ({
  // eslint-disable-next-line react/prop-types
  imgSrc, name, size, setDisplayAvatarEditor, handleChange, accept = 'image/*', isDisabled = false,
}) => {
  const [scale, setScale] = React.useState(1);

  const { setShopLogo } = useShop();
  const { settings } = useSettings();
  const fileInputRef = React.useRef(null);
  const displayRef = React.useRef(null);

  async function handleClick() {
    if (displayRef) {
      const canvas = displayRef.current.getImageScaledToCanvas();
      const dataURL = canvas.toDataURL('image/png');

      const blobImg = await fetch(dataURL).then((res) => res.blob());
      const imgFile = new File([blobImg], 'avatar.png', { type: 'image/png' });

      setShopLogo((prevValue) => ({ ...prevValue, [name]: { file: imgFile, url: dataURL } }));
      setDisplayAvatarEditor(false);
    }
  }

  return (
    <Wrapper>

      <Editor>
        <AvatarEditorWrapper>
          <AvatarEditor
            image={imgSrc}
            ref={displayRef}
            width={size[0]}
            height={size[1]}
            border={50}
            color={[255, 255, 255, 0.6]}
            scale={scale}
            rotate={0}
          />
        </AvatarEditorWrapper>
        <SliderWrapper>
          <ZoomOut
            size={20}
            color="#A8A8A8"
          />
          <Slider
            type="range"
            min="1"
            max="10"
            step="0.1"
            onChange={(e) => setScale(e.target.value)}
          />
          <ZoomIn
            size={20}
            color="#A8A8A8"
          />
        </SliderWrapper>
        <ButtonsWrapper>
          <Button color={settings?.colors.primary} onClick={isDisabled ? () => {} : () => fileInputRef.current?.click()}>
            <ChangeIcon
              size={15}
              color="white"
            />
            <input
              ref={fileInputRef}
              accept={accept}
              style={{ display: 'none' }}
              type="file"
              onChange={handleChange}
              readOnly={isDisabled}
            />
          </Button>

          <Button color={settings?.colors.primary}>
            <Check
              size={25}
              color="white"
              onClick={handleClick}
            />
          </Button>
        </ButtonsWrapper>
      </Editor>
    </Wrapper>
  );
};

export default AvatarModal;
