import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Content, BadgesContainer } from './styles';

import { useSettings } from '../../hooks/settings';

function Invite() {
  const { settings } = useSettings();
  const { t } = useTranslation();

  const isMobile = {
    Android() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod|Mac/i);
    },
    Opera() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows() {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    },
  };

  useEffect(() => {
    if (settings && settings.name) {
      if (isMobile.Android() && settings.play_store_url) {
        window.location.href = settings.play_store_url;
      } else if (isMobile.iOS() && settings.app_store_url) {
        window.location.href = settings.app_store_url;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return settings && settings.name ? (
    <Container>
      <div className="content-logo">
        <img src={settings.default_logo} alt="Logo" />
      </div>

      <Content>
        <h2 style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {t('baixe o app')} {settings.display_name}:
        </h2>
        <BadgesContainer>
          {!!settings.play_store_url
        && (
        <a
          style={{ marginTop: 10 }}
          target="_blank"
          rel="noreferrer"
          href={settings.play_store_url}
        >
          <img
            alt={t('disponivel no google play')}
            src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
          />
        </a>
        )}
          {!!settings.app_store_url && (
          <a
            style={{ marginTop: 10 }}
            target="_blank"
            rel="noreferrer"
            href={settings.app_store_url}
          >
            <img
              style={{ width: 110, paddingTop: 7 }}
              alt={t('disponivel na app store')}
              src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-download-on-the-app-store.svg"
            />
          </a>
          )}
        </BadgesContainer>
      </Content>

    </Container>
  ) : (<></>);
}

export default Invite;
