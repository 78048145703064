/* eslint-disable react/jsx-no-bind */
import React, {
  useEffect, useCallback, useRef, useState,
} from 'react';

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  Table, Dropdown, Menu, Modal, Skeleton, notification, Checkbox, Spin,
} from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import { FaEllipsisH } from 'react-icons/fa';

import { Form } from '@unform/web';

import formErrors from '../../../services/formErrors';
import getErrors from '../../../services/errors';

import { Row, Col } from '../../../styles/components';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';

import Password from '../../../components/Password';
import Notification from '../../../components/Notification';

import api from '../../../services/api';
import { useSettings } from '../../../hooks/settings';
import { documentMask } from '../../../services/utils';
import {
  Content, ContentTab,
} from './styles';
import { ButtonTypeTransfer } from '../styles';
import { formatAccount } from '../../Transaction/Receipt/utils';

const { Column } = Table;

function Ted() {
  const formRef = useRef(null);
  const { settings } = useSettings();
  const [favorities, setFavorities] = useState([]);
  const [loadFetchFavorities, setLoadFetchFavorities] = useState([]);
  const [transfer, setTransfer] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [amount, setAmount] = useState(null);
  const [dataTransfer, setDataTransfer] = useState({});
  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [contact, setContact] = useState(false);
  const [document, setDocument] = useState('');
  const [accountList, setAccountList] = useState(null);
  const [loadingAccountList, setLoadingAccountList] = useState(false);
  const [save, setSave] = useState(false);
  const { t } = useTranslation();

  const loadFavorities = useCallback(() => {
    setLoadFetchFavorities(true);
    api.get('/favorites?filter=TEF').then(({ data }) => {
      setFavorities(data);
      setLoadFetchFavorities(false);
    }).catch(() => {
      setLoadFetchFavorities(false);
    });
  }, []);

  useEffect(() => {
    loadFavorities();
  }, [loadFavorities]);

  const handleSearchDocumentAccounts = useCallback(async () => {
    setLoadingAccountList(true);
    setAccountList([]);
    try {
      const { data } = await api.get(`/accounts/find/document/${document.replace(/[^0-9]/g, '')}`);
      if (data?.length === 1) {
        setTransfer(true);
        setContact({
          ...data[0],
          account: data[0].bank_account,
        });
      } else {
        setAccountList(data);
      }
    } catch (err) {
      console.log(err.response?.data);
    }
    setLoadingAccountList(false);
  }, [document]);

  const handleSubmit = useCallback(async (data) => {
    formRef.current.setErrors({});

    if (transfer) {
      try {
        if (!amount || amount === 0) {
          formRef.current.setErrors({
            amount: t('o valor e obrigatorio'),
          });
          return;
        }

        const schema = Yup.object().shape({
          beneficiary: Yup.object().shape({
            document: Yup.string()
              .required(t('o documento é obrigatório.'))
              .matches(
                /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2})$/,
                t('o documento deve ter 11 ou 14 digitos'),
              ),
            name: Yup.string()
              .max(100, t('o nome deve ter, no maximo, 100 caracteres'))
              .required(t('o nome e obrigatorio')),
            account: Yup.string()
              .matches(/^\d+$/, t('apenas numeros sao aceitos'))
              .required(t('a conta e obrigatoria')),
            digit: Yup.string()
              .matches(/^\d+$/, t('apenas numeros sao aceitos'))
              .required(t('o digito e obrigatorio')),
          }).required(),
        }).required();

        await schema.validate(data, {
          abortEarly: false,
        });

        setShowPassword(true);
        setDataTransfer({ ...data, amount });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = formErrors(err);
          formRef.current.setErrors(errors);
        }
      }
    } else {
      handleSearchDocumentAccounts();
    }
  }, [amount, t, transfer, handleSearchDocumentAccounts]);

  async function handleTransfer(password) {
    setLoadingTransfer(true);
    try {
      await api.post('transactions/tef', {
        beneficiary: {
          name: dataTransfer.beneficiary.name,
          document: dataTransfer.beneficiary.document,
          account: `${dataTransfer.beneficiary.account}${dataTransfer.beneficiary.digit}`,
        },
        amount,
        password,
        save,
      });

      setLoadingTransfer(false);
      setAmount(0);
      setTransfer(false);
      setContact(false);
      setDataTransfer({});
      setDocument('');
      setAccountList(null);
      setSuccess(t('transferencia realizada com sucesso'));
    } catch (err) {
      const errorMessage = getErrors(err);

      setError(errorMessage);
      setLoadingTransfer(false);
    }
  }

  const changeValue = useCallback((event, maskedvalue, floatvalue) => {
    setAmount(floatvalue);
  }, []);

  const handleDeleteContact = useCallback((_selectContact) => {
    Modal.confirm({
      title: t('excluir favorito'),
      icon: <ExclamationCircleOutlined />,
      content: t('tem certeza que deseja excluir esse favorito'),
      okText: t('sim'),
      cancelText: t('nao'),
      centered: true,
      onOk: async () => {
        try {
          await api.delete(`/favorites/${_selectContact.id}`);
          loadFavorities();
          notification.success({
            message: t('favorito removido'),
            description:
              t('favorito removido com sucesso'),
          });
        } catch (err) {
          setLoadingTransfer(false);
          setError(getErrors(err));
        }
      },
    });
  }, [loadFavorities, t]);

  const handleOptionsFavorities = useCallback(async (e, selectContact) => {
    if (e.key === '1') {
      setTransfer(true);
      setContact(selectContact);
      return;
    }

    handleDeleteContact(selectContact);
  }, [handleDeleteContact]);

  useEffect(() => {
    if (transfer && contact) {
      formRef.current.setData({
        beneficiary: {
          document: contact.document,
          name: contact.name,
          account: contact.account.substr(0, contact.account.length - 1),
          digit: contact.account[contact.account.length - 1],
        },
      });
    }
  }, [transfer, contact]);

  if (settings && settings.name) {
    return (
      <>
        <h1>Transferência entre contas {settings.display_name}</h1>
        <br />
        <Loading hasLoading={loadingTransfer} />
        <Notification
          type="error"
          title={t('erro na transferencia')}
          visible={!!error}
          hidden={() => setError(false)}
        >
          {error}
        </Notification>
        <Notification
          title={t('transferencia efetuada')}
          visible={!!success}
          hidden={() => setSuccess(false)}
        >
          {success}
        </Notification>
        <Content>
          <Password
            visible={showPassword}
            hidden={() => setShowPassword(false)}
            afterPassword={handleTransfer}
          />
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
          >
            {!transfer ? (
              <>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Input
                      id="doc"
                      name="doc"
                      label={t('cpf / cnpj')}
                      type="text"
                      value={documentMask(document)}
                      onChange={(e) => setDocument(e.target.value?.replace(/[^0-9]/g, ''))}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Button
                      type="submit"
                      style={{ height: 45 }}
                    >
                      <span>{t('continuar')}</span>
                    </Button>
                  </Col>
                </Row>

                {(accountList || loadingAccountList) ? (
                  <div style={{
                    width: '100%',
                    backgroundColor: 'rgba(200, 200, 200, 0.3)',
                    borderRadius: 10,
                    padding: 30,
                  }}
                  >

                    {loadingAccountList ? (
                      <div style={{
                        display: 'flex', justifyContent: 'center',
                      }}
                      >
                        <Spin size={30} color="black" />
                      </div>
                    ) : (
                      <>
                        {!accountList?.length && (
                        <>
                          Não foram encontradas contas para o documento informado.
                        </>
                        )}

                        {!!accountList?.[0] && !!accountList?.[0]?.name && (
                          <>
                            <h3 style={{ textAlign: 'center' }}>
                              Para qual conta de {accountList[0].name}?
                            </h3>
                          </>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                          {accountList?.map((item) => (
                            <>
                              <ButtonTypeTransfer
                                onClick={() => {
                                  setTransfer(true);
                                  setContact({
                                    ...item,
                                    account: item.bank_account,
                                  });
                                }}
                                borderColor={settings.colors.primary}
                                color={settings.colors.text}
                              >
                                {formatAccount(item.bank_account)}
                              </ButtonTypeTransfer>
                            </>
                          ))}
                        </div>
                      </>
                    )}

                  </div>
                ) : (
                  <></>
                )}

                <ContentTab>
                  <h3>{t('favoritos')}</h3>
                  {loadFetchFavorities ? (
                    <>
                      <Skeleton />
                    </>
                  ) : (
                    <Table
                      dataSource={favorities}
                      pagination={false}
                      rowKey="id"
                      loading={false}
                      locale={{
                        emptyText: t('nenhum favorito para exibir'),
                      }}
                      scroll={{ x: true }}
                      style={{ height: '400px', overflow: 'scroll', display: 'nowrap' }}
                    >
                      <Column
                        title={t('nome')}
                        dataIndex="name"
                        key="name"
                        responsive={['xs', 'sm', 'md', 'xl']}
                      />
                      <Column
                        title={t('conta')}
                        dataIndex="account"
                        key="account"
                        responsive={['xs', 'sm', 'md', 'xl']}
                      />
                      <Column
                        title={t('acoes')}
                        align="center"
                        dataIndex="name"
                        key="name"
                        responsive={['xs', 'sm', 'md', 'xl']}
                        render={(_text, record) => (
                          <Dropdown
                            trigger={['click']}
                            overlay={(
                              <Menu onClick={(e) => handleOptionsFavorities(e, record)}>
                                <Menu.Item key="1">{t('transferir para este contato')}</Menu.Item>
                                <Menu.Item key="2">
                                  <span>{t('excluir favorito')}</span>
                                </Menu.Item>
                              </Menu>
                          )}
                          >
                            <FaEllipsisH style={{ cursor: 'pointer' }} />
                          </Dropdown>

                        )}
                      />
                    </Table>

                  )}
                </ContentTab>
              </>
            ) : (
              <>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                    <Input
                      disabled={!!contact}
                      id="beneficiary.document"
                      name="beneficiary.document"
                      label={t('cpf / cnpj')}
                      type="text"
                      value={(contact ? documentMask(contact.document) : documentMask(document))}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Input
                      disabled={!!contact}
                      id="beneficiary.name"
                      name="beneficiary.name"
                      label={t('nome completo')}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Input
                      disabled={!!contact}
                      id="beneficiary.account"
                      name="beneficiary.account"
                      label={t('conta')}
                      type="text"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
                    <Input
                      disabled={!!contact}
                      id="beneficiary.digit"
                      name="beneficiary.digit"
                      maxLength="1"
                      label={t('digito')}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                    <Input
                      mask="currency"
                      onChangeEvent={changeValue}
                      id="amount"
                      name="amount"
                      value={amount}
                      label={t('valor')}
                      type="text"
                    />
                  </Col>
                </Row>
                {!contact && (
                <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                  <Checkbox
                    checked={save}
                    disabled={contact}
                    style={{ fontSize: '16px' }}
                    onChange={(e) => {
                      setSave(e.target.checked);
                    }}
                  >{t('adicional aos favoritos')}
                  </Checkbox>
                </Col>
                )}

                <Row gutter={16}>
                  <Col md={24}>
                    <Button type="submit">
                      <span>{t('transferir')}</span>
                    </Button>
                  </Col>
                </Row>

              </>

            )}
          </Form>
        </Content>
      </>
    );
  }
  return <></>;
}

export default Ted;
