/* eslint-disable max-len */
/* eslint-disable no-alert */
import React, {
  useEffect, useState, useMemo, useCallback, useRef,
} from 'react';
import {
  Table, Skeleton, Tooltip, Dropdown, Menu, Pagination, Spin, notification,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { AiOutlineFilePdf } from 'react-icons/ai';
import XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {
  addHours, endOfYear, format, isValid, parseISO, startOfYear,
} from 'date-fns';
import {
  FaCalendarAlt,
} from 'react-icons/fa';
import {
  FiCopy,
} from 'react-icons/fi';
import DayPicker from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { addZeros, HandleFormatMoney, manageSpaces } from '../../../../services/utils';
import Button from '../../../../components/Button';
import { useAuth } from '../../../../hooks/auth';
import chargesApi from '../../../../services/charges';

import {
  Content, TabsContainer, Tab, FilterContainer, ReactSelect,
} from './styles';
import useOnClickOutside from '../../../../hooks/onClickOutside';
import { useSettings } from '../../../../hooks/settings';
import { FilterDate } from '../../../Charge/styles';
import api from '../../../../services/api';

const { Column } = Table;

const NmUserPage = (props) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [cnabLoading, setCnabLoading] = useState(false);
  const [sheetLoading, setSheetLoading] = useState(false);
  const [payments, setPayments] = useState();
  const [paymentType, setPaymentType] = useState(null);
  const [partners, setPartners] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [, setTotalItemsPage] = useState(0);
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('ALL');
  const { settings } = useSettings();
  const [dueAtStart, setDueAtStart] = useState(format(startOfYear(new Date()), 'yyyy-MM-dd'));
  const [dueAtEnd, setDueAtEnd] = useState(format(endOfYear(new Date()), 'yyyy-MM-dd'));
  const dueAtEndRef = useRef(null);
  const dueAtStartRef = useRef(null);
  const refDueAtStartCalendar = useRef();
  const refDueAtEndCalendar = useRef();
  useOnClickOutside(refDueAtStartCalendar, () => dueAtStartRef.current.handleInputBlur());
  useOnClickOutside(refDueAtEndCalendar, () => dueAtEndRef.current.handleInputBlur());
  const dueAtStartDate = useMemo(() => format(parseISO(dueAtStart), 'dd/MM/yyyy'), [dueAtStart]);
  const dueAtEndDate = useMemo(() => format(parseISO(dueAtEnd), 'dd/MM/yyyy'), [dueAtEnd]);
  const [paymentStart, setPaymentStart] = useState(format(startOfYear(new Date()), 'yyyy-MM-dd'));
  const [paymentEnd, setPaymentEnd] = useState(format(endOfYear(new Date()), 'yyyy-MM-dd'));
  const paymentEndRef = useRef(null);
  const paymentStartRef = useRef(null);
  const refPaymentStartCalendar = useRef();
  const refPaymentEndCalendar = useRef();
  useOnClickOutside(refPaymentStartCalendar, () => paymentStartRef.current.handleInputBlur());
  useOnClickOutside(refPaymentEndCalendar, () => paymentEndRef.current.handleInputBlur());
  const paymentStartDate = useMemo(() => format(parseISO(paymentStart), 'dd/MM/yyyy'), [paymentStart]);
  const paymentEndDate = useMemo(() => format(parseISO(paymentEnd), 'dd/MM/yyyy'), [paymentEnd]);

  const handleChangeDueAtDate = useCallback((day, type) => {
    if (isValid(day)) {
      const formatDay = format(day, 'yyyy-MM-dd');
      if (type === 'start') {
        setDueAtStart(formatDay);
      } else {
        setDueAtEnd(formatDay);
      }
    }
  }, []);

  const handleChangePaymentDate = useCallback((day, type) => {
    if (isValid(day)) {
      const formatDay = format(day, 'yyyy-MM-dd');
      if (type === 'start') {
        setPaymentStart(formatDay);
      } else {
        setPaymentEnd(formatDay);
      }
    }
  }, []);

  const getPaymentMethod = useCallback((paymentTypee) => {
    if (paymentTypee === 'credit_card') return 'Cartão';

    if (paymentTypee === 'billet') return 'Boleto';

    if (paymentTypee === 'card_avulse') return 'Boleto avulso';

    return '-';
  }, []);

  const getBilletUrl = useCallback((id) => `https://qesh-storage.s3.amazonaws.com/billets/${id}/bill_file.pdf`, []);

  const generateSheet = useCallback(async () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'mensalidades';
    setSheetLoading(true);

    let filters = { pagination: false };
    let sheetData = [];

    if (currentTab === 'DETACHED') {
      chargesApi.get('nmpay/detached-charges', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        const allPayments = response.data.items;
        sheetData = allPayments.map((payment) => (
          {
            PARCEIRO: payment.partner.name_1,
            ESTUDANTE: payment.student_name || '',
            EMAIL: payment.student_email || '',
            'DATA DO VENCIMENTO': format(parseISO(payment.due_at), 'dd/MM/yyyy'),
            'DATA DO PAGAMENTO': payment.paid_at ? format(parseISO(payment.paid_at), 'dd/MM/yyyy') : '',
            'VALOR BASE': payment.base_value,
            'VALOR PAGAMENTO': payment.current_value,
            'VALOR OPERAÇÃO': payment.paid_value,
            'METODO DE PAGAMENTO': getPaymentMethod(payment.payment_type),
            PARCELAS: payment.installments || '',
            BOLETO: payment.billet_id ? getBilletUrl(payment.billet_id) : '-',
          }));
        const ws = XLSX.utils.json_to_sheet(sheetData);
        const wb = { Sheets: { mensalidades: ws }, SheetNames: ['mensalidades'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        setSheetLoading(false);
      })
        .catch((err) => {
          setSheetLoading(false);
          notification.error({ message: 'Erro ao gerar planilha.' });
          console.log(err);
        });
    } else {
      if (currentTab && currentTab !== 'ALL') {
        filters = { ...filters, status: currentTab };
      }

      if (paymentType && paymentType !== 'all') {
        filters = { ...filters, payment_type: paymentType };
      }

      if (selectedPartner) {
        filters = { ...filters, partner_id: selectedPartner };
      }

      chargesApi.get(`nmpay/charges?dueAtStart=${dueAtStart}&dueAtEnd=${
        format(addHours(new Date(dueAtEnd), 3), 'yyyy-MM-dd HH:mm')}${
        currentTab === 'PAID' ? `&paymentStart=${
          paymentStart}&paymentEnd=${
          format(addHours(new Date(paymentEnd), 3), 'yyyy-MM-dd HH:mm')}` : ''}`, {
        params: filters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        const allPayments = response.data.items;
        sheetData = allPayments.map((payment) => (
          {
            PARCEIRO: payment.partner.name_1,
            ESTUDANTE: payment.student_name || '',
            EMAIL: payment.student_email || '',
            'DATA DO VENCIMENTO': format(parseISO(payment.due_at), 'dd/MM/yyyy'),
            'DATA DO PAGAMENTO': payment.paid_at ? format(parseISO(payment.paid_at), 'dd/MM/yyyy') : '',
            'VALOR BASE': payment.base_value,
            'VALOR PAGAMENTO': payment.current_value,
            'VALOR OPERAÇÃO': payment.paid_value,
            'METODO DE PAGAMENTO': getPaymentMethod(payment.payment_type),
            PARCELAS: payment.installments || '',
            BOLETO: payment.billet_id ? getBilletUrl(payment.billet_id) : '-',
          }));
        const ws = XLSX.utils.json_to_sheet(sheetData);
        const wb = { Sheets: { mensalidades: ws }, SheetNames: ['mensalidades'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        setSheetLoading(false);
      })
        .catch((err) => {
          setSheetLoading(false);
          notification.error({ message: 'Erro ao gerar planilha.' });
          console.log(err);
        });
    }
  }, [getBilletUrl, getPaymentMethod, currentTab, dueAtStart, dueAtEnd, paymentEnd, paymentStart, paymentType, selectedPartner, token]);

  useEffect(() => {
    chargesApi.get('nmpay/partners', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(({ data }) => {
      const options = data.map((partner) => ({
        value: partner.id,
        label: partner.name_1,
      }));

      setPartners(options);
    });
  }, [generateSheet, token]);

  const getData = useCallback((page = 1) => {
    setTableLoading(true);

    let filters = { page: page - 1 };

    if (currentTab === 'DETACHED') {
      chargesApi.get('nmpay/detached-charges', {
        params: filters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setPayments(response.data);
        setLoading(false);
        setTableLoading(false);

        setCurrentPage(page ? page - 1 : 0);
        setTotalPages(response.data.total_pages + 1);
        setTotalItems(response.data.total_items);
        setTotalItemsPage(response.data.total_items_page);
      });
    } else {
      if (currentTab && currentTab !== 'ALL') {
        filters = { ...filters, status: currentTab };
      }

      if (paymentType && paymentType !== 'all') {
        filters = { ...filters, payment_type: paymentType };
      }

      if (selectedPartner) {
        filters = { ...filters, partner_id: selectedPartner };
      }

      chargesApi.get(`nmpay/charges?dueAtStart=${dueAtStart}&dueAtEnd=${
        format(addHours(new Date(dueAtEnd), 3), 'yyyy-MM-dd HH:mm')}${
        currentTab === 'PAID' ? `&paymentStart=${
          paymentStart}&paymentEnd=${
          format(addHours(new Date(paymentEnd), 3), 'yyyy-MM-dd HH:mm')}` : ''}`, {
        params: filters,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setPayments(response.data);
        setLoading(false);
        setTableLoading(false);

        setCurrentPage(page ? page - 1 : 0);
        setTotalPages(response.data.total_pages + 1);
        setTotalItems(response.data.total_items);
        setTotalItemsPage(response.data.total_items_page);
      });
    }
  }, [currentTab, paymentType, selectedPartner, token, dueAtStart, dueAtEnd, paymentStart, paymentEnd]);

  useEffect(() => {
    setTableLoading(true);
    getData();
  }, [getData]);

  const generateCnab = useCallback(() => {
    setCnabLoading(true);
    let filters = { };

    if (currentTab && currentTab !== 'ALL') {
      filters = { ...filters, status: currentTab };
    }

    if (paymentType && paymentType !== 'all') {
      filters = { ...filters, payment_type: paymentType };
    }

    if (selectedPartner) {
      filters = { ...filters, partner_id: selectedPartner };
    }

    chargesApi.get(`nmpay/charges?dueAtStart=${dueAtStart}&dueAtEnd=${
      format(addHours(new Date(dueAtEnd), 3), 'yyyy-MM-dd HH:mm')}&paymentStart=${
      paymentStart}&paymentEnd=${
      format(addHours(new Date(paymentEnd), 3), 'yyyy-MM-dd HH:mm')}&pagination=false`, {
      params: filters,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      const allPayments = response.data?.items;
      const docFacul = allPayments[0].partner.document;
      api.get('banks').then((res) => {
        const bank = res.data?.banks?.find((it) => addZeros(3, String(it.code)) === addZeros(3, String(allPayments[0].partner.bank_number)));
        if (!bank) {
          console.log('bank not found.');
          return;
        }
        const bankName = bank.name;
        let content = `${addZeros(3, `${allPayments[0].partner.bank_number || ''}`)}00000        20${docFacul}${addZeros(4, (allPayments[0].partner.agency || ''))}0${addZeros(10, (allPayments[0].partner.account || ''))}     000000000           ${manageSpaces(30, allPayments[0].partner.name_1)}${manageSpaces(30, bankName || '')}          2${format(new Date(), 'ddMMyyyy')}      000000040                                                                          \n`;
        content += `${addZeros(3, `${allPayments[0].partner.bank_number}`)}00011T01  040 20${docFacul}000000000           ${addZeros(4, (allPayments[0].partner.agency || ''))}0${addZeros(10, (allPayments[0].partner.account || ''))}     ${manageSpaces(30, allPayments[0].partner.name_1)}${manageSpaces(80, '')}00000000${format(new Date(), 'ddMMyyyy')}${manageSpaces(41, '')}\n`;
        let count = 1;
        let valorTotal = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const payment of allPayments) {
          valorTotal += Number(payment.paid_value);
          content += `${addZeros(3, `${bank.code}`)}00013${addZeros(5, `${count}`)}T 06${addZeros(4, (payment.partner.agency || ''))}0${addZeros(10, (payment.partner.account || ''))}        ${addZeros(13, (payment.nosso_numero || ''))}2${addZeros(15, (payment.nosso_numero || ''))}${format(new Date(), 'ddMMyyyy')}${addZeros(15, `${Number(payment.paid_value).toFixed(2)}`.replace(/[.,-]/g, ''))}03347376                         001${addZeros(15, payment.student_responsible_document)}${manageSpaces(40, payment.student_responsible_name)}${addZeros(10, (allPayments[0].partner.account || ''))}${addZeros(15, '')}                                \n`;
          count += 1;
          content += `${addZeros(3, `${bank.code}`)}00003${addZeros(5, `${count}`)}U 06${addZeros(75, '')}${addZeros(15, `${Number(payment.paid_value).toFixed(2)}`.replace(/[.,-]/g, ''))}${addZeros(30, '')}${format(addHours(new Date(payment.paid_at), 3), 'ddMMyyyy')}${format(addHours(new Date(payment.paid_at), 3), 'ddMMyyyy')}0000${addZeros(23, '')}${manageSpaces(30, '')}000${manageSpaces(27, '')}\n`;
          count += 1;
        }
        content += `${addZeros(3, `${bank.code}`)}00005         ${addZeros(6, `${count + 2}`)}${addZeros(6, `${count + 2}`)}${addZeros(17, `${Number(valorTotal).toFixed(2)}`.replace(/[.,-]/g, ''))}${addZeros(6, '')}${addZeros(17, '')}${addZeros(6, '')}${addZeros(17, '')}${addZeros(6, '')}${addZeros(17, '')}00000000${manageSpaces(117, '')}\n`;
        content += `${addZeros(3, `${bank.code}`)}00009         000001${addZeros(6, `${count + 2}`)}${manageSpaces(211, '')}`;
        const data = new Blob([content], { type: 'text/plain' });
        FileSaver.saveAs(data, `${format(addHours(new Date(paymentEnd), 3), 'ddMMyyyy')}cnab.txt`);
        setCnabLoading(false);
      }).catch((er) => { console.log(er, 'Erro ao consultar bancos.'); setCnabLoading(false); });
    }).catch(() => { console.log('Erro ao buscar mensalidades.'); setCnabLoading(false); });
  }, [currentTab, dueAtEnd, dueAtStart, paymentEnd, paymentStart, paymentType, selectedPartner, token]);

  const handleGoToBilletFile = useCallback((id) => {
    window.open(`https://qesh-storage.s3.amazonaws.com/billets/${id}/bill_file.pdf`);
  }, []);

  const handleCancelCharge = useCallback(async (obj) => {
    await chargesApi.patch(`nmpay/charges/cancel/${obj.id}`);
    getData();
  }, [getData]);

  const formattedPayments = useMemo(() => (currentTab === 'DETACHED'
    ? payments?.items.map((payment) => ({
      ...payment,
      partner_name: payment.partner?.name_1,
      bar_code: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {payment.bar_code.substring(0, 4)}...
          <Tooltip title="Copiar código de barras">
            <CopyToClipboard
              text={payment.bar_code}
              onCopy={() => {
                notification.success({
                  message: 'Linha Digitável',
                  description: 'Linha digitável copiada com sucesso.',
                });
              }}
            >
              <FiCopy style={{ marginLeft: '12px' }} />
            </CopyToClipboard>
          </Tooltip>
        </span>
      ),
    }))
    : payments?.items.map((payment) => ({
      id: payment.id,
      student_name: payment.student_name,
      partner_name: payment.partner.name_1,
      due_at: format(parseISO(payment.due_at), 'dd/MM/yyyy'),
      paid_at: payment.paid_at
        ? <span style={{ fontWeight: 'bold' }}>{format(parseISO(payment.paid_at), 'dd/MM/yyyy')}</span>
        : <span style={{ fontWeight: 'bold' }}>NÃO PAGO</span>,
      base_value: HandleFormatMoney(Number(payment.base_value)),
      current_value: HandleFormatMoney(Number(payment.current_value)),
      paid_value: payment.paid_value ? (
        <span style={{ fontWeight: 'bold', color: 'green' }}>
          {HandleFormatMoney(Number(payment.paid_value))}
        </span>
      ) : '-',
      payment_type: getPaymentMethod(payment.payment_type),
      installments: payment.installments || '-',
      options: (
        <div style={{
          display: 'flex', alignItems: 'center', width: 60, justifyContent: 'space-between',
        }}
        >
          {/* <Tooltip title="Deletar mensalidade">
          <Popconfirm
            placement="left"
            title="Deseja mesmo deletar essa mensalidade?"
            onConfirm={() => handleCancelCharge(payment)}
            okText="Sim"
            cancelText="Não"
          ><FaTrashAlt size={20} style={{ cursor: 'pointer' }} />
          </Popconfirm>
        </Tooltip> */}
          { !!payment.billet_id && (
          <Tooltip title="Visualizar boleto">
            <AiOutlineFilePdf
              onClick={() => handleGoToBilletFile(payment.billet_id)}
              size={24}
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>
          )}
        </div>
      ),
    }))),
  [payments?.items, getPaymentMethod, handleGoToBilletFile, currentTab]);

  return (
    <>

      <Content>
        <h1>Mensalidades</h1>

        <FilterContainer>
          <TabsContainer>
            <Tab
              onClick={() => {
                setCurrentTab('ALL');
              }}
              selected={currentTab === 'ALL'}
            >
              <span>Todos</span>
            </Tab>
            <Tab
              onClick={() => {
                setCurrentTab('PAID');
              }}
              selected={currentTab === 'PAID'}
            >
              <span>Pagos</span>
            </Tab>

            <Tab
              onClick={() => {
                setCurrentTab('PENDING');
              }}
              selected={currentTab === 'PENDING'}
            >
              <span>Pendentes</span>
            </Tab>

            <Tab
              onClick={() => {
                setCurrentTab('OVERDUE');
              }}
              selected={currentTab === 'OVERDUE'}
            >
              <span>Atrasados</span>
            </Tab>
            <Tab
              onClick={() => {
                setCurrentTab('DETACHED');
              }}
              selected={currentTab === 'DETACHED'}
            >
              <span>Avulsos</span>
            </Tab>
          </TabsContainer>

          <div style={{
            flex: 1, display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap',
          }}
          >
            <Dropdown
              overlay={(
                <Menu>
                  <Menu.Item>
                    <Link to="/educational-payments/partners/create">
                      Criar novo parceiro
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/educational-payments/partners">
                      Lista de parceiros
                    </Link>
                  </Menu.Item>
                </Menu>
                  )}
              placement="bottomLeft"
              arrow
            >
              <Button>
                <span>Parceiros</span>
              </Button>
            </Dropdown>

            <Tooltip title="Cadastrar mensalidades a partir de uma planilha">
              <Button
                style={{ marginLeft: '12px' }}
                onClick={() => history.push('/educational-payments/import-charges')}
              >
                <span>Cadastrar mensalidades</span>
              </Button>
            </Tooltip>
            <Tooltip title="Exportar excel dessa tabela">
              <Button style={{ marginLeft: '12px' }} onClick={generateSheet}>
                { sheetLoading ? <Spin /> : <span>Exportar excel</span>}
              </Button>
            </Tooltip>
          </div>
        </FilterContainer>

        {loading ? (
          <>
            <Skeleton active title="Depósito" loading={loading} paragraph={2} rows={2} />
            <Skeleton active title="Depósito" loading={loading} paragraph={2} rows={2} />
            <Skeleton active title="Depósito" loading={loading} paragraph={2} rows={2} />
          </>
        ) : (
          <>
            {currentTab === 'DETACHED' ? (
              <>
                <Table
                  dataSource={formattedPayments}
                  pagination={false}
                  rowKey="id"
                  loading={tableLoading}
                  locale={{
                    emptyText: 'Nenhum pagamento para exibir.',
                  }}
                  scroll={{ x: true }}
                >

                  <Column
                    title="Nome do pagador"
                    dataIndex="payer_name"
                    key="payer_name"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />
                  <Column
                    title="Documento do pagador"
                    dataIndex="payer_document"
                    key="payer_document"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />
                  <Column
                    title="Faculdade"
                    dataIndex="partner_name"
                    key="partner_name"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />

                  <Column
                    title="Data de vencimento"
                    dataIndex="due_at"
                    key="due_at"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text) => (text ? format(addHours(new Date(text), 3), 'dd/MM/yyyy') : '-')}
                  />

                  <Column
                    title="Data de pagamento"
                    dataIndex="paid_at"
                    key="paid_at"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text) => (text ? format(addHours(new Date(text), 3), 'dd/MM/yyyy') : '-')}
                  />

                  <Column
                    title="Valor"
                    dataIndex="amount"
                    key="amount"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text) => HandleFormatMoney(+(text || 0))}
                  />

                  <Column
                    title="Parcelas"
                    dataIndex="installments"
                    key="installments"
                    responsive={['xs', 'sm', 'md', 'xl']}
                    render={(text) => text || '-'}
                  />
                  <Column
                    title="Linha digitável"
                    dataIndex="bar_code"
                    key="bar_code"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />

                  <Column
                    title=""
                    dataIndex="options"
                    key="options"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />
                </Table>
                <Pagination
                  current={currentPage + 1}
                  onChange={getData}
                  total={totalItems}
                  pageSize={20}
                  style={{ alignSelf: 'flex-end', marginTop: '6px' }}
                />
              </>
            ) : (
              <>
                <div style={{
                  width: '100%', display: 'flex', alignItems: 'center', padding: 10,
                }}
                >
                  <FilterDate style={{ marginRight: 50 }}>
                    <span>Vencimento</span>
                    <span>de:</span>
                    <section ref={refDueAtStartCalendar}>
                      <DayPicker
                        ref={dueAtStartRef}
                        dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                        format="dd/MM/yyyy"
                        formatDate={(date) => format(date, 'dd/MM/yyyy')}
                        value={dueAtStartDate}
                        onDayChange={(day) => handleChangeDueAtDate(day, 'start')}
                      />
                      <FaCalendarAlt
                        color={settings.colors.primary}
                        onClick={() => dueAtStartRef.current.handleInputClick()}
                      />
                    </section>
                    <span>até:</span>
                    <section ref={refDueAtEndCalendar}>
                      <DayPicker
                        ref={dueAtEndRef}
                        dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                        format="dd/MM/yyyy"
                        formatDate={(date) => format(date, 'dd/MM/yyyy')}
                        onDayChange={(day) => handleChangeDueAtDate(day, 'end')}
                        value={dueAtEndDate}
                      />
                      <FaCalendarAlt
                        color={settings.colors.primary}
                        onClick={() => dueAtEndRef.current.handleInputClick()}
                      />
                    </section>
                  </FilterDate>
                  {currentTab === 'PAID' && (
                  <>
                    <FilterDate style={{ marginRight: 20 }}>
                      <span>Pagamento</span>
                      <span>de:</span>
                      <section ref={refPaymentStartCalendar}>
                        <DayPicker
                          ref={paymentStartRef}
                          dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                          format="dd/MM/yyyy"
                          formatDate={(date) => format(date, 'dd/MM/yyyy')}
                          value={paymentStartDate}
                          onDayChange={(day) => handleChangePaymentDate(day, 'start')}
                        />
                        <FaCalendarAlt
                          color={settings.colors.primary}
                          onClick={() => paymentStartRef.current.handleInputClick()}
                        />
                      </section>
                      <span>até:</span>
                      <section ref={refPaymentEndCalendar}>
                        <DayPicker
                          ref={paymentEndRef}
                          dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
                          format="dd/MM/yyyy"
                          formatDate={(date) => format(date, 'dd/MM/yyyy')}
                          onDayChange={(day) => handleChangePaymentDate(day, 'end')}
                          value={paymentEndDate}
                        />
                        <FaCalendarAlt
                          color={settings.colors.primary}
                          onClick={() => paymentEndRef.current.handleInputClick()}
                        />
                      </section>
                    </FilterDate>
                    <Button
                      style={{
                        fontSize: 12, maxHeight: 30, marginBottom: 'auto',
                      }}
                      onClick={() => generateCnab()}
                    >{cnabLoading ? <Spin size={2} /> : <span>Gerar CNAB</span>}
                    </Button>
                  </>
                  )}
                </div>
                <ReactSelect
                  id="partner_select"
                  placeholder="Parceiros"
                  options={partners}
                  onChange={(e) => setSelectedPartner(e.value)}
                />
                <Table
                  dataSource={formattedPayments}
                  pagination={false}
                  rowKey="id"
                  loading={tableLoading}
                  locale={{
                    emptyText: 'Nenhum pagamento para exibir.',
                  }}
                  scroll={{ x: true }}
                >

                  <Column
                    title="Estudante"
                    dataIndex="student_name"
                    key="student_name"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />

                  <Column
                    title="Faculdade"
                    dataIndex="partner_name"
                    key="partner_name"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />

                  <Column
                    title="Data de vencimento"
                    dataIndex="due_at"
                    key="due_at"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />

                  <Column
                    title="Data de pagamento"
                    dataIndex="paid_at"
                    key="paid_at"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />

                  <Column
                    title="Valor base"
                    dataIndex="base_value"
                    key="base_value"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />

                  <Column
                    title="Valor de pagamento"
                    dataIndex="current_value"
                    key="current_value"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />
                  <Column
                    title="Valor operação"
                    dataIndex="paid_value"
                    key="paid_value"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />
                  <Column
                    title="Método de pagamento"
                    dataIndex="payment_type"
                    key="payment_type"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />
                  <Column
                    title="Parcelas"
                    dataIndex="installments"
                    key="installments"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />

                  <Column
                    title=""
                    dataIndex="options"
                    key="options"
                    responsive={['xs', 'sm', 'md', 'xl']}
                  />
                </Table>
                <Pagination
                  current={currentPage + 1}
                  onChange={getData}
                  total={totalItems}
                  pageSize={20}
                  style={{ alignSelf: 'flex-end', marginTop: '6px' }}
                />
              </>
            )}
          </>
        )}

      </Content>
      )

    </>

  );
};

export default NmUserPage;
