import styled from 'styled-components';

export const Content = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 0px -10px 15px 0px;
  max-width: 1060px;
  width: 100%;

  h1 {
    margin-bottom: 32px;
  }

  button {
    @media (max-width: 1020px) {
      margin-top: 30px;
    }
  }
`;

export const Subtitle = styled.h2`
   margin-top: 8px;
   margin-bottom: 16px;
   border-bottom: 1px solid #d5d3d3;
`;
