import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import 'moment/locale/pt-br';
import 'react-day-picker/lib/style.css';
import { notification, Modal } from 'antd';
import Select from 'react-select';
import { Form } from '@unform/web';
import QRCode from 'react-qr-code';
import { format } from 'date-fns';
import Loading from '../../components/Loading';
import Receipt from '../Transaction/Receipt';
import Password from '../../components/Password';
import { Row, Col } from '../../styles/components';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getErrors from '../../services/errors';
import api from '../../services/api';
import {
  HandleFormatMoney,
  documentMask,
  localeParseFloat,
} from '../../services/utils';
import { Title } from './styles';
import { useAuth } from '../../hooks/auth';
import { useSettings } from '../../hooks/settings';

function Pix() {
  const { user, account, token } = useAuth();
  const { t } = useTranslation();
  const { settings } = useSettings();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [pay, setPay] = useState(false);
  const [receive, setReceive] = useState(false);
  const [btCorfirmed, setBtCorfirmed] = useState(false);

  const [amount, setAmount] = useState('');
  const [key, setKey] = useState('');

  const [selectTK, setSelectTK] = useState('');
  const [sKey, setsKey] = useState('');

  const [generateQrcode, setgenerateQrcode] = useState(false);
  const [payConfi, setPayConfi] = useState(false);
  const [payReceipt, setpayReceipt] = useState('');
  const [pamount, setPamount] = useState(false);

  const [description, setDescription] = useState('');
  const [outOfTimeInfo, setOutOfTimeInfo] = useState(null);

  const isKexion = settings.name === 'kexion';

  const [QRCODE, setQRCODE] = useState([]);
  const options = [
    { value: 'cpf/cnpj', label: 'CPF/CNPJ' },
    { value: 'celular', label: 'Celular' },
    { value: 'email', label: 'E-mail' },
    { value: 'aleatoria', label: 'Aleatória' },
    { value: 'emv', label: 'Pix Copia e Cola' },
  ];

  const [showPassword, setShowPassword] = useState(false);
  const [receipt, setReceipt] = useState(false);

  const changeValue = (v) => {
    setAmount(v);
    if (v.split(',')[0].replace(/\./g, '').length > 6) {
      notification.error({ message: t('Valor máximo para receber é R$ 999.999,99') });
      setAmount('999.999,99');
    }
  };
  const handleLimitDesc = () => {
    if (description.length > 99) {
      notification.error({ message: t('Preenchimento máximo permitido de 100 caracteres.') });
    }
  };

  const getKey = useCallback(async (k) => {
    setLoading(true);
    try {
      if (k.match(/[A-Z]|az|ü|é]/i) === null) {
        if ((k.replace(/[-./()]/g, '').length === 11 || k.replace(/[-./()]/g, '').length === 14)
          && !k.includes('+')) {
          setSelectTK('CPF/CNPJ');
          k.replace(/[-./]/g, '');
        } else if (k.replace(/[-\s()]/g, '').length === 14) {
          setSelectTK('Celular');// +55 (31) 984224508-03704234613-09021458000100
          k.replace(/[-\s()]/g, '');
        }
      } else if (k.includes('@')) {
        setSelectTK('E-mail');
      } else {
        setSelectTK('Aleatória');
      }

      const { data } = await api.get(
        `/pix/key/${k}`,
        {
          headers: {
            token,
            account: account.id,
          },
        },
      );

      await setKey(data);
      setLoading(false);
      setPayConfi(true);
    } catch (err) {
      setLoading(false);
      notification.error({ message: t('Não foi encontrado nenhuma chave.') });
    }
  }, []);
  const getEmv = useCallback(async (e) => {
    setLoading(true);
    try {
      const { data } = await api.post(
        '/pix/emv',
        {
          emv: e,
        },
        {
          headers: {
            token,
            account: account.id,
          },
        },
      );

      if (data.transactionAmount === 0) {
        throw Error('QRCode invalid');
      }

      await setKey(data);
      setLoading(false);
      setPayConfi(true);
      setPamount(true);
    } catch (err) {
      setLoading(false);
      notification.error({ message: t('Não foi encontrado nenhuma chave ou código inválido.') });
    }
  }, []);

  function handleSelectTK() {
    switch (selectTK) {
      case 'CPF/CNPJ':
        return (
          <Input
            name="sKey"
            id="sKey"
            value={!sKey ? '' : documentMask(sKey).substring(0, 18).replace(/[a-z]/gi, '')}
            onChange={(e) => {
              setsKey(e.target.value);
            }}
          />
        );
      case 'Celular':
        return (
          <Input
            mask="+99 (99) 99999-9999"
            name="sKey"
            id="sKey"
            placeholder="+XX (XX) XXXXX-XXXX"
            value={!sKey ? '' : sKey.substring(0, 20)}
            onChange={(e) => {
              setsKey(e.target.value.replace(/[-\s()]/g, ''));
            }}
          />
        );
      case 'E-mail':
        return (
          <Input
            name="sKey"
            id="sKey"
            placeholder="example@example.com"
            value={!sKey ? '' : sKey}
            onChange={(e) => {
              setsKey(e.target.value);
            }}
          />
        );
      default:
        return (
          <Input
            name="sKey"
            id="sKey"
            value={!sKey ? '' : sKey}
            onChange={(e) => {
              setsKey(e.target.value);
            }}
          />
        );
    }
  }

  const recordAmount = useCallback(() => {
    setAmount(amount);
  }, [amount]);

  const createQRcode = useCallback(async (desc, val) => {
    setLoading(true);
    try {
      const data = await api.post(
        '/pix/qr-code/dynamic',
        {
          amount: localeParseFloat(val),
          charged_name: user.name,
          charged_document: user.document,
          description: !desc ? ' ' : desc,
        },
        {
          headers: {
            token,
            account: account.id,
          },
        },
      );

      setLoading(false);
      setQRCODE(data);
      setgenerateQrcode(true);
      return data;
    } catch (err) {
      notification.error({
        message: t('Infelizmente não foi possível criar o QR Code. Favor tente mais tarde.'),
      });
      setLoading(false);
      return (false);
    }
  }, []);

  function pagReceiveConfirmation() {
    return (
      !btCorfirmed ? (
        <>
          <Form
            layout="vertical"
            onSubmit={() => { }}
          >
            <Row
              style={{
                marginBottom: 10,
              }}
            >
              <h3>Quanto você quer Receber ?</h3>
            </Row>
            <Row
              style={{
                marginBottom: 10,
              }}
            >
              <Input
                mask="currency"
                onChangeEvent={(e) => {
                  changeValue(e.target.value);
                }}
                id="amount"
                name="amount"
                value={amount}
                label={t('valor')}
                type="text"
              />
            </Row>
            <Row>
              <Button
                key="confirmed"
                type="text"
                style={{
                  padding: 10,
                  margin: 5,
                  borderRadius: 7,
                }}
                onClick={() => {
                  if (
                    (amount !== '' || amount === undefined || Number(amount) > 0)
                    && amount !== '0,00' && amount !== 0) {
                    if (Number(localeParseFloat(amount)) < 1000000) {
                      setBtCorfirmed(true);
                    } else {
                      notification.info({ message: t('Acima do Valor máximo para receber ') });
                    }
                  } else {
                    setBtCorfirmed(false);
                    notification.info({ message: t('Favor informar um valor no mínimo de R$ 0,01') });
                  }
                }}
              >
                <span>{t('Confirmar')}</span>
              </Button>
            </Row>
          </Form>
        </>
      ) : (
        <>
          <h1>Revise as informações</h1>
          <Form
            layout="vertical"
            onSubmit={() => { }}
          >
            <Row style={{
              marginBottom: 20, 
              paddingTop: '10px'
            }}>
              <Col>
                <Input
                  mask="currency"
                  id="amount"
                  name="amount"
                  value={amount}
                  label={t('valor')}
                  disabled
                />
              </Col>
            </Row>
            <Row
              style={{
                marginBottom: 20,
                flexWrap: 'wrap',
              }}
            >
              <Col
                style={{
                  marginRight: 20,
                  width: 400,
                }}
              >
                <Input
                  id="name"
                  name="name"
                  value={account.user.name}
                  label={t('Nome')}
                  disabled
                />
              </Col>
              <Col>
                <Input
                  mask={account.user.document.length === 11 ? '999.999.999-99' : '99.999.999/9999-9'}
                  id="document"
                  name="document"
                  value={account.user.document}
                  label={t('Documento')}
                  disabled
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
              <Col>
                <Input
                  onChange={(e) => {
                    setDescription(e.target.value);
                    handleLimitDesc();
                  }}
                  id="description"
                  name="description"
                  value={description}
                  label={t('Descrição (opcional)')}
                  type="text"
                  maxLength="100"
                />
              </Col>
            </Row>
          </Form>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginRight: 20,
            }}
          >
            <Button
              right={(false)}
              type="button"
              onClick={() => {
                setBtCorfirmed(false);
                setAmount(0);
                setDescription('');
              }}
              style={{
                marginRight: 20,
              }}
            >
              <span>{t('Cancelar')}</span>
            </Button>
            <Button
              right={(false)}
              type="button"
              onClick={() => {
                createQRcode(description, amount);
              }}
              style={{
                marginRight: 20,
              }}
            >
              <span>{t('Criar QR Code')}</span>
            </Button>
          </div>
        </>
      )
    );
  }

  function pagGenerateQrcode() {
    return (
      <>
        <h2
          style={{
            marginTop: '-20px',
          }}
        >
          Receber Pagamento
        </h2>

        <div
          style={{
            textAlign: 'center',
            width: 550,
          }}
        >
          <h3
            style={{
              marginTop: '-10px',
            }}
          >
            Mostre o QR Code ou envie para a pessoa cobrada
          </h3>
          <h3
            style={{
              marginTop: '-10px',
            }}
          >
            Vai Receber
          </h3>
          {!!QRCODE
            && (
              <>
                <h1>{HandleFormatMoney(QRCODE.data.amount)}</h1>
                <QRCode size={200} value={QRCODE.data.emv} />
              </>
            )}
          <h3>Ao escanear o QR Code, os dados preenchidos serão visto por quem for pagar</h3>
          <Button
            right={(false)}
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(QRCODE.data.emv);
            }}
          >
            <span>{t('Copiar Pix copia e cola')}</span>
          </Button>
        </div>
      </>
    );
  }

  function pagReceive() {
    return (
      generateQrcode === false
        ? (
          pagReceiveConfirmation()
        )
        : (
          pagGenerateQrcode()
        )
    );
  }

  const payKey = useCallback(async (password, k, a) => {
    setLoading(true);
    try {
      const data = await api.post('/pix/pay/key', {
        key: k,
        amount: localeParseFloat(a),
        password,
      },
        {
          headers: {
            token,
            account: account.id,
          },
        });
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      notification.error({ message: err.response?.data?.message || 'Ocorreu um erro inesperado.' });
      return false;
    }
  }, []);

  const payEmv = useCallback(async (p, e, a) => {
    setLoading(true);
    try {
      const data = await api.post(
        '/pix/pay/qr-code',
        {
          emv: e,
          amount: a,
          password: p,
        },
        {
          headers: {
            token,
            account: account.id,
          },
        },
      );
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      notification.error({ message: err.response?.data?.message || 'Ocorreu um erro inesperado.' });
      return false;
    }
  }, []);

  async function handleTransfer(password) {
    let data = {};
    try {
      if (selectTK !== 'Pix Copia e Cola') {
        data = await payKey(password, sKey, amount);
      } else {
        data = await payEmv(password, sKey, key.transactionAmount);
      }

      if (!data) {
        throw Error('QRCode invalid');
      }
      setReceipt(true);

      // setAmount(0);
    } catch (err) {
      const errorMessage = getErrors(err);
      if (errorMessage?.includes(t('operacao permitida'))) {
        setOutOfTimeInfo({ message: errorMessage, password });
      } else {
        setError(errorMessage);
      }
    }
  }

  function payConfirmed() {
    return (
      receipt
        ? (
          <>
            <div
              style={{
                display: 'table',
                textAlign: 'center',
                width: 500,
              }}
            >
              <h1>Tudo certo, transferência enviada!</h1>
              <h1>R$
                {!amount
                  ? HandleFormatMoney(key.transactionAmount).replace('R$', '')
                  : amount}
              </h1>
              <h4>Para {key.key.owner.name} no {key.participant.name} as {format(new Date(), 'HH:mm:ss')}</h4>
              <h2>Para conferir o recibo</h2>
              <h2
                style={{
                  marginTop: '-15px',
                }}
              >
                Favor ir ao Extrato
              </h2>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: 'table',
                textAlign: 'center',
                borderWidth: 1,
                width: 475,
              }}
            >
              {!!payConfi
                && (
                  <>
                    <Row
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Col>Chave( {selectTK} )</Col>
                      <Col>{key?.key.key || key?.merchantAccountInformation?.key}</Col>
                    </Row>
                    <Row
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Col>Nome</Col>
                      <Col>{key.key.owner.name || key?.merchantName}</Col>
                    </Row>
                    <Row
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Col>CPF/CNPJ</Col>
                      <Col>{documentMask(key.key.owner.taxIdNumber)}</Col>
                    </Row>
                    <Row
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Col>Instituição</Col>
                      <Col>{key.participant.ispb} - {key.participant.name}</Col>
                    </Row>
                    <Row
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Col>Valor</Col>
                      <Col>R$
                        {!amount
                          ? HandleFormatMoney(key.transactionAmount).replace('R$', '')
                          : amount}
                      </Col>
                    </Row>
                  </>
                )}
              <Button
                type="button"
                style={{ marginRight: 20 }}
                onClick={() => {
                  setPayConfi(false);
                  setPamount(false);
                  // setSelectTK('');
                  setAmount(0);
                }}
              >
                <span>{t('Voltar')}</span>
              </Button>
              <Button
                type="button"
                style={{ marginRight: 20 }}
                onClick={() => {
                  setShowPassword(true);
                }}
              >
                <span>{t('Continuar')}</span>
              </Button>
            </div>
            <div>
              <h2
                style={{
                  fontSize: 10,
                  fontWeight: 'bold',
                }}
              >
                Importante Lembrar
              </h2>
              <h3
                style={{
                  fontSize: 10,
                }}
              >
                Confira as informações de quem vai receber antes de efetuar o pagamento
              </h3>
            </div>
          </>
        )
    );
  }

  function payAmount() {
    return (
      !pamount
        ? (
          <>
            <Form
              layout="vertical"
              onSubmit={() => { }}
            >
              <Row
                style={{
                  marginBottom: 10,
                }}
              >
                <h3>Quanto você ira Pagar ?</h3>
              </Row>
              <Row
                style={{
                  marginBottom: 10,
                }}
              >
                <Input
                  mask="currency"
                  onChangeEvent={(e) => {
                    changeValue(e.target.value);
                  }}
                  id="amount"
                  name="amount"
                  value={amount}
                  label={t('valor')}
                  type="text"
                />
              </Row>
              <Row>
                <Button
                  key="confirmed"
                  type="text"
                  style={{
                    padding: 10,
                    margin: 5,
                    borderRadius: 7,
                  }}
                  onClick={() => {
                    if (
                      (amount !== '' || amount === undefined || Number(amount) > 0)
                      && amount !== '0,00' && amount !== 0) {
                      setPamount(true);
                      recordAmount();
                    } else {
                      notification.info({ message: t('Favor informar um valor no mínimo de R$ 0,01') });
                    }
                  }}
                >
                  <span>{t('Confirmar')}</span>
                </Button>
              </Row>
            </Form>
          </>
        ) : (
          payConfirmed()
        )
    );
  }

  function pagPay() {
    return (
      payConfi
        ? (
          payAmount()
        ) : (
          <>
            <div
              style={{
                marginLeft: 10,
              }}
            >
              <Form
                onSubmit={() => { }}
              >
                <Row
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <Col
                    style={{
                      width: '200px',
                    }}
                  >
                    <h3>Selecione a chave Pix</h3>
                    <Select
                      options={options}
                      defaultInputValue={selectTK}
                      onChange={(e) => {
                        setSelectTK(e.label);
                        setsKey('');
                      }}
                    />
                  </Col>
                </Row>
                {selectTK
                  && (
                    <Row
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      <Col>
                        <h3>Escreva a chave Pix</h3>
                        {handleSelectTK()}
                      </Col>
                    </Row>
                  )}
              </Form>
              {selectTK
                && (
                  <div>
                    <Button
                      type="button"
                      style={{ marginRight: 20 }}
                      onClick={() => {
                        if (sKey !== '') {
                          if (selectTK !== 'Pix Copia e Cola') {
                            getKey(sKey);
                          } else {
                            getEmv(sKey);
                          }
                        } else {
                          notification.error({
                            message: t('Campo "Escrceva a Chave Pix" é de preenchimento obrigatório.'),
                          });
                        }
                      }}
                    >
                      <span>{t('Continuar')}</span>
                    </Button>
                  </div>
                )}
            </div>
          </>
        )
    );
  }

  return (
    <div>
      <Title>
        <h1>{t('Pix')}</h1>
      </Title>
      <Row gutter={16}>
        <Col>
          {!!pay && (<h1>{t('Pagar')}</h1>)}
          {!!receive && (<h1>{t('Receber')}</h1>)}
        </Col>
        <Col
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          {!!receive || !!pay ? (
            <Button
              right={(false)}
              type="button"
              onClick={() => {
                setReceive(false);
                setPay(false);
                setBtCorfirmed(false);
                setKey(false);
                setgenerateQrcode(false);
                setError(false);
                setpayReceipt(false);
                setDescription('');
                setPayConfi(false);
                setPamount(false);
                setReceipt(false);
                setAmount(0);
                setSelectTK('');
              }}
            >
              <span>{t('Voltar')}</span>
            </Button>

          ) : (<></>
          )}
        </Col>
        <Col md={24}>
          {!pay && !receive ? (
            <Button
              right={(false)}
              type="button"
              onClick={() => {
                setPay(true);
                setError(false);
              }}
              style={{ marginRight: 20 }}
            >
              <span>{t('Pagar')}</span>
            </Button>

          ) : (!!pay
            && (
              pagPay()
            )
          )}

          {!isKexion && !receive && !pay ? (
            <Button
              right={(false)}
              type="button"
              onClick={() => {
                setReceive(true);
                setError(false);
              }}
            >
              <span>{t('Receber')}</span>
            </Button>

          ) : (
            !!receive
            && (
              pagReceive()
            )
          )}
        </Col>
      </Row>
      <Password
        visible={showPassword}
        hidden={() => setShowPassword(false)}
        afterPassword={handleTransfer}
      />
      <Modal
        centered
        visible={!!payReceipt}
        onOk={() => setpayReceipt('')}
        closable={false}
        onCancel={() => setpayReceipt('')}
        footer={false}
        destroyOnClose
        style={{ padding: 0, margin: 0 }}
      >
        <Receipt item={payReceipt} onCancel={() => setpayReceipt('')} settings={settings} />
      </Modal>
      <Loading hasLoading={loading} />
    </div>
  );
}

export default Pix;
