/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
import React from 'react';
import RegisterHeader from '../../components/RegisterHeader';
import { useSettings } from '../../hooks/settings';

const Terms = () => {
  const { settings } = useSettings();

  return (
    <>
      <RegisterHeader noTitle />
      <div
        style={{
          width: '100%',
          padding: 20,
          whiteSpace: 'pre-wrap',
        }}
      >
        <p align="center">
          <strong>Termos e Condições de Uso da Plataforma da </strong>
          <strong>{settings?.display_name}</strong>
        </p>
        <p align="justify">
          Por este instrumento, a pessoa identificada e qualificada no Cadastro,
          que é parte integrante deste Termo (“<strong>CORRENTISTA</strong>”); e
          “{settings?.display_name}”, têm entre si justo e acordado estes Termos
          e Condições de Uso da Plataforma, nos termos e condições abaixo.
        </p>
        <p align="justify">
          Os serviços relacionados com soluções de pagamento por meios
          eletrônicos serão prestados em parceria com QESH INSTITUIÇÃO DE
          PAGAMENTO LTDA. inscrita no CNPJ sob o nº 31.818.873/0001-30, com sede
          na Alameda do Ingá, nº 16, 1º Andar, Vale do Sereno, Nova Lima/MG, CEP
          34.006-042, que, na qualidade de{' '}
          <strong>instituição de pagamento</strong>, realizará a gestão e
          custódia de recursos e outros serviços financeiros (“
          <strong>Instituição de Pagamento</strong>”).
        </p>
        <p align="justify">
          Ao aceitar eletronicamente os Termos e Condições de Uso e a Política
          de Privacidade, com a marcação da caixa de diálogo “Li e concordo com
          os Termos e Condições de Uso e Política de Privacidade”, o{' '}
          <strong>CORRENTISTA</strong>
          estará automaticamente aderindo e concordando com todas as disposições
          deste Termo e da Política de Privacidade. Caso o{' '}
          <strong>CORRENTISTA</strong> tenha alguma dúvida, deverá entrar em
          contato com suporte por meio de um de seus canais de atendimento antes
          do aceite.
        </p>
        <p align="justify">
          <strong>
            A realização de uma Transação ou utilização de qualquer
            Funcionalidade será interpretada como o aceite pleno a este Termo.
          </strong>
        </p>
        <p align="justify">
          A Plataforma também poderá ser utilizada para que fornecedores
          ofereçam produtos e serviços. Neste caso, a “{settings?.display_name}”
          e a <strong>Instituição de Pagamento</strong> atuarão como meras
          intermediadoras, disponibilizando tecnologia para aproximação entre os
          Fornecedores e o <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          <strong>
            Este Termo poderá ser periodicamente revisto e alterado, podendo o
            <strong> CORRENTISTA</strong>, caso não concorde com a modificação,
            denunciá-lo sem quaisquer ônus ou penalidades.
          </strong>
        </p>
        <p align="justify">
          <strong>1. Definições</strong>
        </p>
        <p align="justify">
          As palavras e expressões abaixo, indicadas neste Termo pela primeira
          letra maiúscula, terão as seguintes definições:
        </p>
        <p align="justify">
          “<u>Bacen</u>”: Banco Central do Brasil.
        </p>
        <p align="justify">
          “<u>Cadastro</u>”: formulário preenchido pelo{' '}
          <strong>CORRENTISTA</strong> na Plataforma, contendo seus dados
          pessoais e demais informações necessárias para credenciamento ao
          Sistema e criação da Conta de Pagamento.
        </p>
        <p align="justify">
          “<u>Cartão Pré-Pago</u>”: cartão plástico que, caso solicitado pelo
          <strong> CORRENTISTA</strong>, poderá ser utilizado mediante recursos
          previamente aportados na Conta de Pagamento, para aquisição de bens ou
          serviços, em território nacional, perante os estabelecimentos filiados
          à bandeira indicada no respectivo Cartão, cuja emissão será realizada
          de acordo com os Termos e Condições de Uso do Cartão.
        </p>
        <p align="justify">
          “<u>Cashback</u>”: programa de benefícios, pelo qual o{' '}
          <strong>CORRENTISTA </strong>
          recebe de volta parte do valor pago em determinadas Transações, ou em
          razão da aquisição de produtos ou serviços específicos.
        </p>
        <p align="justify">
          “<u>Conta de Pagamento</u>”: conta de titularidade do{' '}
          <strong>CORRENTISTA</strong>, destinada ao carregamento, transferência
          e resgate de recursos, cujos valores, convertidos em moeda eletrônica,
          serão geridos e custodiados pela{' '}
          <strong>Instituição de Pagamento</strong>.
        </p>
        <p align="justify">
          “<u>Fornecedores</u>”: terceiros que oferecem produtos e/ou serviços
          ao <strong>CORRENTISTA</strong> por meio da Plataforma.
        </p>
        <p align="justify">
          “<u>Funcionalidades</u>”: tecnologias <strong>CORRENTISTA</strong> na
          Plataforma, para a realização de Transações pelo{' '}
          <strong>CORRENTISTA</strong> e aquisição de produtos e/ou serviços
          perante os Fornecedores.
        </p>
        <p align="justify">
          “<u>Plataforma</u>”:<strong> </strong>site na internet disponível em{' '}
          <a href={settings?.ib_url}>
            <u>{settings?.ib_url}</u>
          </a>
          , e aplicativo para dispositivos móveis, disponibilizado ao
          <strong> CORRENTISTA</strong> para a utilização das Funcionalidades e
          realização de Transações.
        </p>
        <p align="justify">
          <a name="_heading=h.gjdgxs" />“<u>Política de Privacidade</u>”:
          política disponível em{' '}
          <a href="https://app.qesh.ai/politica-de-privacidade">
            <u>https://app.qesh.ai/politica-de-privacidade</u>
          </a>
          , que é integrante deste Termo, a qual dispõe sobre a coleta,
          utilização, armazenamento, tratamento, compartilhamento, proteção e
          eliminação das informações do <strong>CORRENTISTA</strong>, em
          decorrência da utilização do Sistema.
        </p>
        <p align="justify">
          <a name="_heading=h.2et92p0" />“<u>Pix</u>”: arranjo de pagamentos
          instituído pelo Banco Central do Brasil, que disciplina a prestação de
          serviços de pagamento relacionados com as Transações de pagamentos
          instantâneos no âmbito do arranjo.
        </p>
        <p align="justify">
          <u>“Serviços de Terceiros</u>
          ”: produtos e serviços disponíveis ao <strong>CORRENTISTA</strong> na
          Plataforma, e que serão fornecidos pelos Fornecedores credenciados.
        </p>
        <p align="justify">
          “<u>Sistema</u>”: serviços relacionados com a abertura de Conta de
          Pagamento e realização de Transações pelo <strong>CORRENTISTA</strong>
          .
        </p>
        <p align="justify">
          “<u>Transação</u>”: operação em que o <strong>CORRENTISTA</strong>{' '}
          realiza a movimentação de sua Conta de Pagamento, mediante o
          carregamento, transferência ou resgate de recursos, de acordo com as
          modalidades disponíveis na Plataforma.
        </p>
        <p align="justify">
          “<u>Termo</u>”: este Termos e Condições de Uso da Plataforma.
        </p>
        <p align="justify">
          “
          <u>
            <strong>CORRENTISTA</strong>
          </u>
          ”: pessoa jurídica ou pessoa física que, ao aderir ao presente Termo,
          está habilitada a realizar Transações por meio do Sistema.
        </p>
        <p align="justify">
          <strong>2. Objeto</strong>
        </p>
        <p align="justify">
          2.1. Este Termo regula a prestação de serviços de tecnologia, pelo “
          {settings?.display_name}”, diretamente ou por intermédio da
          <strong> Instituição de Pagamento</strong>, para: (i) cadastro e
          credenciamento do
          <strong> CORRENTISTA</strong> ao Sistema; (ii) criação de Conta de
          Pagamento, habilitando o <strong>CORRENTISTA</strong> a realizar
          Transações; e (iii) gestão e custódia dos recursos mantidos na Conta
          de Pagamento de titularidade do <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          2.1.1. Ainda, os serviços prestados incluem o fornecimento de
          tecnologia, para que o <strong>CORRENTISTA</strong> possa contratar
          Serviços de Terceiros na Plataforma.
        </p>
        <p align="justify">
          2.2. Por meio da Plataforma, o <strong>CORRENTISTA</strong> poderá
          usufruir de alguns ou todos os serviços a seguir: (i) realizar o
          carregamento e resgate dos recursos na Conta de Pagamento; (ii)
          verificar o saldo e o extrato das movimentações na Conta de
          Pagamento,; (iii) realizar transferências para outros{' '}
          <strong>CORRENTISTAS</strong> detentores de Conta de Pagamento; (iv)
          efetuar ordem de transferência para conta bancária, de sua
          titularidade ou de terceiros (por TEF, TED ou DOC); (v) realizar e
          receber pagamentos instantâneos pelo Pix; (vi) emitir boletos
          bancários; (vii) realizar pagamentos de boletos bancários e contas de
          consumo; (viii) realizar recargas de serviços pré-pagos, incluindo,
          mas não se limitando, à telefonia móvel, lojas de aplicativos, dentre
          outros; (ix) realizar saques em caixas eletrônicos; (x) solicitar
          emissão de Cartão Pré-Pago, conforme previsto em anexo específico;
          (xi) utilizar de outras Funcionalidades disponíveis.
        </p>
        <p align="justify">
          2.2.1. As Funcionalidades disponíveis na Plataforma poderão, a
          qualquer momento e sem necessidade de aviso prévio, ser excluídas,
          alteradas ou modificadas.
        </p>
        <p align="justify">
          2.2.2. As Funcionalidades disponíveis para os{' '}
          <strong>CORRENTISTA</strong> poderão ser consultadas no endereço:{' '}
          <a href="https://qesh.ai">
            <u>https://qesh.ai</u>
          </a>
        </p>
        <p align="justify">
          2.2.3. As condições para a realização de Transações pelo Pix se
          encontram previstas no Anexo I – Pagamentos Instantâneos pelo Pix, que
          é parte integrante deste Termo.
        </p>
        <p align="justify">
          2.3. Os serviços serão prestados de forma remota, mediante a licença
          de uso das Funcionalidades disponibilizadas ao{' '}
          <strong>CORRENTISTA</strong> na Plataforma.
        </p>
        <p align="justify">
          2.4. Em razão deste Termo, não se garante a ininterrupção e a
          velocidade do Sistema e/ou da Plataforma, que poderá apresentar
          indisponibilidade, lentidão e erros de processamento, em casos de
          manutenção preventiva ou corretiva, falha de operação, erro de
          sistema, falhas de outros prestadores de serviços e eventos casos
          fortuitos ou força maior.
        </p>
        <p align="justify">
          2.4.1. O “{settings?.display_name}” e a{' '}
          <strong>Instituição de Pagamento</strong> não serão responsáveis pela:
          (i) intermitência ou indisponibilidade de conexão à internet adotada
          pelo <strong>CORRENTISTA</strong>; (ii) incapacidade técnica do
          dispositivo móvel ou sistema operacional; (iii) indisponibilidade da
          Plataforma na loja de aplicativos ou navegador de internet utilizados
          pelo <strong>CORRENTISTA</strong>; e/ou (iv) atividades de pessoas não
          autorizadas a utilizar o Sistemas ou a Plataforma.
        </p>
        <p align="justify">
          <strong>3. Credenciamento ao Sistema</strong>
        </p>
        <p align="justify">
          3.1. O credenciamento ao Sistema será realizado pela adesão do
          <strong> CORRENTISTA</strong> a este Termo, que se efetivará pelo: (i)
          preenchimento do Cadastro; e (ii) pelo aceite eletrônico expressamente
          manifestado na Plataforma.
        </p>
        <p align="justify">
          3.2. Para utilização do Sistema, o <strong>CORRENTISTA</strong> deverá
          obrigatoriamente preencher o Cadastro, fornecendo seus dados pessoais
          e informações que venham a ser solicitados na Plataforma, que podem
          incluir, mas não se limitam a:
        </p>
        <p align="justify">
          (I) Para pessoa física: número de inscrição no CPF válido e ativo,
          data de nascimento, nome completo, nome da mãe, nacionalidade, número
          de celular, e-mail, endereço residencial, escolaridade, profissão,
          renda mensal, patrimônio e estado civil.
        </p>
        <p align="justify">
          (II) Para pessoa jurídica: número de inscrição no CNPJ válido e ativo,
          data de abertura da empresa, razão social, nome fantasia, endereço da
          empresa, capital social, natureza jurídica, faturamento anual, número
          de funcionário, número de sócios, categoria de negócio, quantidade de
          filiais e as informações acima indicadas para seus representantes,
          mandatários ou prepostos autorizados.
        </p>
        <p align="justify">
          3.2.1. A <strong>Instituição de Pagamento</strong> poderá limitar a
          utilização do Sistema, de acordo com o valor e a quantidade de
          Transações.
        </p>
        <p align="justify">
          3.2.2. Sempre que necessário, inclusive para possibilitar a utilização
          do Sistema para realização de Transações em valor e quantidade aos
          limites estabelecidos, o “{settings?.display_name}” e/ou a Instituição
          de Pagamento poderão solicitar que o <strong>CORRENTISTA</strong>{' '}
          forneça informações complementares àquelas indicadas no Cadastro, bem
          como poderá, a qualquer momento, e ao seu exclusivo critério,
          solicitar cópias de documentos para averiguar a veracidade das
          informações prestadas pelo
          <strong> CORRENTISTA</strong>.
        </p>
        <p align="justify">
          3.2.3. Na hipótese de se verificar dados incorretos ou inverídicos
          fornecidos pelo <strong>CORRENTISTA</strong> ou, ainda, caso o{' '}
          <strong>CORRENTISTA</strong> se recuse ou se omita a enviar as
          informações e documentos solicitados, o {settings?.display_name} e/ou
          a <strong>Instituição de Pagamento</strong> poderão temporariamente
          bloquear o acesso ao Sistema e impedir a utilização das
          Funcionalidades até que haja a regularização.
        </p>
        <p align="justify">
          3.2.4. Com o objetivo de manter os dados atualizados, atualizações
          cadastrais serão exigidas aos <strong>CORRENTISTA</strong>S
          periodicamente. O não fornecimento das informações solicitadas poderá
          implicar na interrupção do acesso do <strong>CORRENTISTA</strong> à
          plataforma.
        </p>
        <p align="justify">
          3.2.5. O <strong>CORRENTISTA</strong> autoriza a realização de
          pesquisas, em base de dados públicos ou privados, com a finalidade de
          verificar a veracidade dos dados e informações indicadas no Cadastro.
        </p>
        <p align="justify">
          3.3. O <strong>CORRENTISTA</strong> se responsabiliza civil e
          criminalmente pela veracidade das informações prestadas, inclusive
          perante terceiros, obrigando-se a manter seus dados atualizados.
        </p>
        <p align="justify">
          3.3.1. O “{settings?.display_name}” e a{' '}
          <strong>Instituição de Pagamento</strong> não serão responsáveis por
          erros ou pela inexecução dos serviços que integram o Sistema caso o{' '}
          <strong>CORRENTISTA</strong> preste informações inexatas, inverídicas
          ou desatualizadas.
        </p>
        <p align="justify">
          3.4. O “{settings?.display_name}” e a{' '}
          <strong>Instituição de Pagamento</strong> não serão responsáveis por
          erros ou pela inexecução dos serviços que integram o Sistema caso o{' '}
          <strong>CORRENTISTA</strong> preste informações inexatas, inverídicas
          ou desatualizadas.
        </p>
        <p align="justify">
          3.4.1. O login e senha são de uso pessoal, exclusivo e intransferível
          pelo <strong>CORRENTISTA</strong>, que deverá mantê-los confidenciais
          e não permitir seu acesso por terceiros.
        </p>
        <p align="justify">
          3.4.2. O <strong>CORRENTISTA</strong>, na qualidade de pessoa
          jurídica, se compromete a somente dar acesso ao login e senha para
          seus representantes legais, sócios, administradores e/ou prepostos com
          poderes para celebrar negócios jurídicos em seu nome; sendo
          responsável, perante ao “{settings?.display_name}”, outros{' '}
          <strong>CORRENTISTAS</strong>, a{' '}
          <strong>Instituição de Pagamento</strong> e terceiros, por todos os
          atos e negócios realizados por meio da utilização do Sistema.
        </p>
        <p align="justify">
          3.4.3. O <strong>CORRENTISTA</strong> deverá informar um e-mail válido
          para comunicação com o “{settings?.display_name}”; sendo que qualquer
          comunicação ou notificação enviada por e-mail será considerada válida
          e eficaz entre as Partes.
        </p>
        <p align="justify">
          3.5. Para utilizar os serviços, o <strong>CORRENTISTA</strong>, dentre
          outras obrigações previstas neste Termo, deve ser: (i) pessoa física,
          maior de 18 (dezoito) anos e possuir inscrição válida e regular
          perante a Receita Federal do Brasil; ou (ii) pessoa jurídica
          devidamente constituída, com sede ou escritório no Brasil e possuir
          inscrição válida e regular perante a Receita Federal do Brasil.
        </p>
        <p align="justify">
          3.5.1. Em razão da natureza dos serviços prestados, o “{settings?.display_name}” e a <strong>Instituição de Pagamento</strong> não possuem
          condições de verificar a capacidade civil e regularidade do{' '}
          <strong>CORRENTISTA</strong>, de forma que não responderá por
          quaisquer prejuízos que venham a ser causados em razão da
          inobservância das condições acima indicadas.
        </p>
        <p align="justify">
          3.6. É vedada a utilização do Sistema e a realização das Transações
          para a celebração de negócios: (i) considerados ilícitos, nos termos
          da legislação brasileira; (ii) que importem em violação ao Sistema
          Financeiro Nacional e às normas do Bacen; (iii) considerados como
          crimes financeiros, com o intuito de lavagem de dinheiro,
          financiamento ao terrorismo e corrupção, dentre outros crimes
          correlatos, ainda que indiretamente; (iv) que não representem um
          negócio jurídico regular e tenham por intenção a prática de fraudes;
          ou (v) que, de qualquer modo, venham a causar prejuízos ao “{settings?.display_name}”, à <strong>Instituição de Pagamento</strong>,
          demais <strong>CORRENTISTA</strong> Sou terceiros.
        </p>
        <p align="justify">
          3.7. As Transações com indícios ou suspeitas de fraude estarão
          sujeitas ao não processamento ou ao cancelamento, ainda que realizada
          de forma conivente ou não pelo <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          3.8. A <strong>Instituição de Pagamento</strong> poderá suspender o
          acesso às Funcionalidades e deixar de realizar as Transações pelo
          Sistema sempre que identificar ou entender que a atividade do{' '}
          <strong>CORRENTISTA</strong> ou natureza das Transações viola qualquer
          dispositivo deste Termo ou da legislação vigente; podendo sujeitar o{' '}
          <strong>CORRENTISTA</strong> ao cancelamento do seu credenciamento e
          sua exclusão imediata do Sistema, independentemente de qualquer aviso
          ou notificação prévia, não gerando ao <strong>CORRENTISTA </strong>
          qualquer tipo de indenização ou ressarcimento.
        </p>
        <p align="justify">
          <strong>4. Conta de Pagamento</strong>
        </p>
        <p align="justify">
          4.1. Ao realizar o Cadastro na Plataforma e aderir a este Termo, o
          <strong> CORRENTISTA</strong> concorda com a abertura de Conta de
          Pagamento individual, de sua exclusiva titularidade, a qual poderá ser
          movimentada por meio das Funcionalidades.
        </p>
        <p align="justify">
          4.1.1. O simples Cadastro na Plataforma não implica na automática
          abertura da Conta de Pagamento, o que somente ocorrerá após a inserção
          e aprovação dos documentos exigidos.
        </p>
        <p align="justify">
          4.2. O carregamento da Conta de Pagamento se dará por um dos meios
          disponíveis no Sistema, de livre escolha do{' '}
          <strong>CORRENTISTA</strong>, dentre os quais:
        </p>
        <p align="justify">
          I. Pagamento de boleto bancário, pelo próprio{' '}
          <strong>CORRENTISTA</strong> - ou terceiros em favor do{' '}
          <strong>CORRENTISTA</strong> -, com identificação única que
          permita o carregamento do valor pago na Conta de Pagamento.
        </p>
        <p align="justify">
          I. Transferência bancária realizada pelo próprio{' '}
          <strong>CORRENTISTA</strong> - ou terceiros em favor do{' '}
          <strong>CORRENTISTA</strong> -, mediante operações de TEF, DOC ou
          TED.
        </p>
        <p align="justify">
          III. Recebimento por meio de transferências realizadas por outros
          <strong> CORRENTISTAS</strong>, no âmbito do Sistema ou por outros
          meios de pagamento disponíveis.
        </p>
        <p align="justify">
          IV. Transferência de recursos em razão do Cashback.
        </p>
        <p align="justify">
          V. Recebimento de recursos por meio de pagamentos instantâneos
          realizados no âmbito do Pix.
        </p>
        <p align="justify">
          4.2.1. A <strong>Instituição de Pagamento</strong> poderá, a qualquer
          momento, acrescentar, restringir ou excluir as formas de carregamento
          disponíveis, mediante alteração deste Termo e disponibilidade das
          Funcionalidades.
        </p>
        <p align="justify">
          4.2.2. Com o carregamento da Conta de Pagamento, por uma das
          modalidades permitidas, os recursos estarão disponíveis no Sistema em
          até 03 (três) dias úteis; sendo possível ao USUÁRIO, a partir de
          então, realizar as Transações por meio das Funcionalidades.
        </p>
        <p align="justify">
          4.3. Os recursos depositados na Conta de Pagamento poderão ser
          utilizados para transferência ou resgate, por um dos meios disponíveis
          no Sistema, dentre os quais:
        </p>
        <p align="justify">
          I. Realização de Transações de transferência para a Conta de Pagamento
          de outros <strong>CORRENTISTAS</strong> credenciados no Sistema.
        </p>
        <p align="justify">
          II. Pagamento de débitos do <strong>CORRENTISTA</strong> em razão da
          compra de produtos ou serviços contratados na Plataforma.
        </p>
        <p align="justify">
          III. Pagamento de boletos bancários e contas de consumo.
        </p>
        <p align="justify">
          IV. Realização de recargas de serviços pré-pagos, incluindo, mas não
          se limitando à telefonia móvel, lojas de aplicativos, dentre outras.
        </p>
        <p align="justify">V. Realização de saques em caixas eletrônicos.</p>
        <p align="justify">
          VI. Resgate de recursos, mediante transferência para a conta bancária
          de titularidade do <strong>CORRENTISTA</strong>; ou, caso disponível,
          para a conta bancária de terceiros que não se encontram cadastrados no
          Sistema.
        </p>
        <p align="justify">VII. Carregamento de Cartão Pré-Pago.</p>
        <p align="justify">
          VIII. Resgate de recursos, mediante transferência para a conta
          bancária de titularidade do <strong>CORRENTISTA</strong>; ou, caso
          disponível, para a conta bancária de terceiros que não se encontram
          cadastrados no Sistema.
        </p>
        <p align="justify">
          4.3.1. A transferência dos recursos entre Contas de Pagamento no
          âmbito do Sistema será realizada no mesmo dia da realização da
          Transação.
        </p>
        <p align="justify">
          4.3.2. Os resgates de recursos e ordens de pagamento serão realizados
          em até 02 (dois) dias úteis contado da realização da Transação.
        </p>
        <p align="justify">
          4.3.3. As Transações realizadas por meio das Funcionalidades deixarão
          de ser acatadas pela <strong>Instituição de Pagamento</strong> quando:
          (i) não houver recursos suficientes na Conta de Pagamento; (ii) o{' '}
          <strong>CORRENTISTA</strong> deixar de fornecer as informações
          suficientes ou fornecer informações incorretas para realização da
          Transação; e/ou (iii) houver indícios de fraude ou suspeita ou ato
          ilícito, de acordo com os termos previstos neste Termo e na legislação
          vigente.
        </p>
        <p align="justify">
          4.4. A <strong>Instituição de Pagamento</strong> poderá determinar
          limites de valor mínimo e máximo para o carregamento das Contas de
          Pagamento e para a realização das Transações, que poderá variar de
          acordo com as informações de Cadastro do <strong>CORRENTISTA</strong>,
          o tipo de Transação, ou outro critério definido pela{' '}
          <strong>Instituição de Pagamento</strong>.
        </p>
        <p align="justify">
          4.4.1. Os critérios acima indicados poderão ser alterados a qualquer
          momento pela <strong>Instituição de Pagamento</strong>, com
          antecedência de 05 (cinco) dias, sem necessidade de aviso prévio ou
          posterior.
        </p>
        <p align="justify">
          4.5. Os recursos creditados na Conta de Pagamento do{' '}
          <strong>CORRENTISTA</strong> serão mantidos em conta bancária de
          titularidade da <strong>Instituição de Pagamento</strong>, em
          instituição financeira de primeira linha, e, nos termos do art. 12 da
          Lei 12.865/2013, (i) constituem patrimônio separado, que não se
          confunde com o da
          <strong> Instituição de Pagamento</strong>; (ii) não respondem direta
          ou indiretamente por nenhuma obrigação da{' '}
          <strong>Instituição de Pagamento</strong>, nem podem ser objeto de
          arresto, sequestro, busca e apreensão ou qualquer outro ato de
          constrição judicial em função de débitos de responsabilidade da
          <strong> Instituição de Pagamento</strong>; (iii) não podem ser dados
          em garantia de débitos assumidos pela{' '}
          <strong>Instituição de Pagamento</strong>; e (iv) não compõem o ativo
          da <strong>Instituição de Pagamento</strong>, para efeito de falência
          ou liquidação judicial ou extrajudicial.
        </p>
        <p align="justify">
          4.6. Os ônus ou bônus decorrentes da manutenção dos recursos em conta
          bancária de titularidade da <strong>Instituição de Pagamento</strong>{' '}
          não poderão ser imputáveis ao <strong>CORRENTISTA</strong>, assim como
          por ele reclamados.
        </p>
        <p align="justify">
          4.6.1. Os recursos mantidos na Conta de Pagamento, salvo se
          expressamente pactuado de modo diverso, não sofrerão qualquer tipo de
          acréscimo ou alteração, tais como correção monetária e juros; e nem
          haverá o pagamento de qualquer remuneração ao{' '}
          <strong>CORRENTISTA</strong>, independentemente do período que ficarem
          depositados.
        </p>
        <p align="justify">
          <a name="_heading=h.30j0zll" />
          4.7. Os valores depositados na Conta de Pagamento devem ser utilizados
          para pagamentos e transferências, sendo considerados pela Instituição
          de Pagamento recursos em trânsito de titularidade do{' '}
          <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          4.8. O <strong>CORRENTISTA</strong> não poderá ceder ou onerar, a
          qualquer título, os direitos sobre os recursos depositados em sua
          Conta de Pagamento, sem a prévia e escrita autorização da Instituição
          de Pagamento, sob pena de ineficácia da cessão
        </p>
        <p align="justify">
          4.9. O <strong>CORRENTISTA</strong> terá acesso às Transações
          realizadas ou pendentes de pagamento pelo acesso ao extrato de sua
          Conta de Pagamento, podendo visualizar no Sistema, o saldo e histórico
          das movimentações. A disponibilização do saldo e do extrato das
          movimentações caracteriza-se como prestação de contas, para todos os
          fins legais.
        </p>
        <p align="justify">
          4.9.1. Apenas será disponibilizado o acesso às Transações realizadas
          nos últimos 12 (doze) meses, cabendo ao <strong>CORRENTISTA</strong> o
          controle e arquivo, inclusive com a possibilidade de impressão do
          extrato disponibilizado. Após este período, o{' '}
          <strong>CORRENTISTA</strong> deverá solicitar o acesso às Transações
          por meio do canal de comunicação disponibilizado pelo “{settings?.display_name}”.
        </p>
        <p align="justify">
          4.10. O <strong>CORRENTISTA</strong> declara-se ciente de que os
          serviços previstos neste Termo se destinam tão somente a efetivar
          pagamentos e recebimentos em moeda nacional, bem como assegurar que
          todos os recursos movimentados em sua Conta de Pagamento serão
          oriundos de fontes lícitas e declaradas, isentando o “{settings?.display_name}
          ” e a <strong>Instituição de Pagamento</strong> de qualquer
          responsabilidade.
        </p>
        <p align="justify">
          4.11. O <strong>CORRENTISTA</strong> poderá ser beneficiário de um
          programa de Cashback caso realize aquisição de determinados produtos
          e/ou serviços oferecidos na Plataforma.
        </p>
        <p align="justify">
          4.11.1. O valor, critérios de elegibilidade e as condições
          relacionadas ao Cashback serão definidos por determinados
          <strong> CORRENTISTA</strong>S, na qualidade de vendedores de produtos
          e/ou serviços na Plataforma.
        </p>
        <p align="justify">
          4.11.2. Em razão do Cashback, o <strong>CORRENTISTA</strong> que
          adquirir determinados produtos e/ou serviços receberá de volta parte
          do valor pago, mediante o carregamento de sua Conta de Pagamento, para
          a realização de outras Transações no Sistema.
        </p>
        <p align="justify">
          <strong>5. Cancelamento das Transações</strong>
        </p>
        <p align="justify">
          5.1. As Transações realizadas entre os <strong>CORRENTISTA</strong>{' '}
          credenciados no Sistema, mediante a transferência de recursos em moeda
          eletrônica, será automaticamente cancelada sempre que: (i) tiver sido
          processada incorretamente pelo <strong>CORRENTISTA</strong>, em razão
          de informações errôneas indicadas pelo <strong>CORRENTISTA</strong> no
          momento da realização da Transação; (ii) foi recusada pelo{' '}
          <strong>CORRENTISTA</strong> destinatário dos recursos; (iii) foi
          realizada em desconformidade com as disposições deste Termo, ou (v)
          haja suspeita de fraude, ato ilícito ou qualquer irregularidade.
        </p>
        <p align="justify">
          5.1.1. Não será possível o cancelamento de Transações realizadas fora
          do âmbito do Sistema, ou seja, antes da conversão dos recursos em
          moeda eletrônica ou após o repasse de valores pela Instituição de
          Pagamento.
        </p>
        <p align="justify">
          5.2. O <strong>CORRENTISTA</strong> declara e garante que será
          integralmente responsável pela veracidade, precisão e conformidade das
          informações e valores das relações comerciais relacionadas com as
          Transações; respondendo, se o caso, pela qualidade, quantidade,
          segurança, adequação, preço, prazo, entrega, Funcionalidade e
          garantias dos produtos ou serviços que deram origem às Transações.
        </p>
        <p align="justify">
          5.3. Todas reclamações e contestações decorrentes de quaisquer
          Transações realizadas no âmbito do Sistema deverão ser dirimidas
          diretamente entre os <strong>CORRENTISTAS</strong>; de modo que o “
          {settings?.display_name}” e a{' '}
          <strong>Instituição de Pagamento</strong> estarão isentas de qualquer
          responsabilidade, e sem prejuízo da possibilidade de retenção e/ou
          compensação de recursos, na forma prevista neste Termo.
        </p>
        <p align="justify">
          5.4. Caso se identifique níveis excessivos de Transações canceladas, a
          <strong> Instituição de Pagamento</strong> poderá: (i) realizar a
          retenção, total ou parcialmente, dos valores existentes na Conta de
          Pagamento, como garantia para cobrir potenciais danos; e (ii)
          suspender ou inabilitar permanentemente o acesso do{' '}
          <strong>CORRENTISTA</strong> à Plataforma.
        </p>
        <p align="justify">
          <strong>6. Hipóteses de Retenção e Compensação de Recursos</strong>
        </p>
        <p align="justify">
          6.1. O <strong>CORRENTISTA</strong> reconhece e concorda que a
          <strong> Instituição de Pagamento</strong>, em conformidade com as
          disposições deste Termo, terá o direito de: (i) reter os valores
          mantidos na Conta de Pagamento do
          <strong> CORRENTISTA</strong> para garantir, de forma integral,
          quaisquer pagamentos que sejam devidos ou para o resguardo contra
          riscos financeiros relacionados às obrigações do{' '}
          <strong>CORRENTISTA</strong>; e (ii) compensar, com os valores
          mantidos na Conta de Pagamento, os débitos do{' '}
          <strong>CORRENTISTA</strong>, de qualquer natureza.
        </p>
        <p align="justify">
          6.2. Será realizada a retenção e compensação dos valores, existentes
          ou futuros, mantidos na Conta de Pagamento do{' '}
          <strong>CORRENTISTA</strong>, nas seguintes hipóteses:
        </p>
        <p align="justify">
          I. Quando se verificar um alto nível de risco operacional ou
          <strong> CORRENTISTA</strong> suspeita de fraude, associado ao
          histórico de Transações realizadas pelo <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          II. Havendo indícios de irregularidade ou risco de cancelamento da
          Transação, em razão de denúncias, cancelamentos ou pelo uso inadequado
          do Sistema.
        </p>
        <p align="justify">
          III. Nos casos em que se verificar a iliquidez, insolvência, pedido de
          recuperação judicial ou extrajudicial, pedido de falência,
          encerramento de atividades ou qualquer outra hipótese em que ficar
          caracterizada a dificuldade do <strong>CORRENTISTA</strong> em cumprir
          suas obrigações contratuais e/ou legais.
        </p>
        <p align="justify">
          IV. Sempre que houver o descumprimento das obrigações previstas neste
          Termo ou na legislação vigente; ou
        </p>
        <p align="justify">
          V. Para cumprimento de ordens judiciais ou administrativas.
        </p>
        <p align="justify">
          6.3. Caso não haja saldo suficiente para arcar com o pagamento dos
          débitos devidos, o <strong>CORRENTISTA</strong> será comunicado pelo “
          {settings?.display_name}” para que proceda o carregamento imediato de
          sua Conta de Pagamento, sob pena de caracterização de sua mora,
          automaticamente e sem a necessidade de aviso ou qualquer formalidade.
        </p>
        <p align="justify">
          6.4. O <strong>CORRENTISTA</strong> terá o prazo de 90 (noventa) dias
          para apontar eventual divergência ou incorreção em relação aos valores
          lançados na Conta de Pagamento, a contar da realização da Transação,
          lançamento do débito ou compensação. Após esse prazo, o{' '}
          <strong>CORRENTISTA</strong> não mais poderá reclamar dos lançamentos
          realizados, concedendo plena e definitiva quitação.
        </p>
        <p align="justify">
          <strong>
            7. Resgate de Recursos e Encerramento da Conta de Pagamento
          </strong>
        </p>
        <p align="justify">
          7.1. O <strong>CORRENTISTA</strong> poderá, a qualquer momento, desde
          que possua saldo suficiente para arcar com a tarifa de saque, as
          tarifas bancárias aplicáveis e o pagamento de eventuais débitos
          contraídos em razão deste Termo, efetuar o resgate integral dos
          recursos mantidos na Conta de Pagamento, bem como encerrá-la, mediante
          solicitação ao “{settings?.display_name}” via Sistema.
        </p>
        <p align="justify">
          7.2. O resgate de recursos será realizado a pedido do{' '}
          <strong>CORRENTISTA</strong>, mediante o repasse do valor líquido e em
          moeda nacional, de acordo com as formas estabelecidas para utilização
          dos recursos mantidos em Conta de Pagamento.
        </p>
        <p align="justify">
          7.3. Caso disponível, o <strong>CORRENTISTA</strong> também poderá
          solicitar que o crédito decorrente das Transações seja transferido
          para a conta corrente de mesma titularidade em outra instituição
          financeira, por conta e ordem do <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          7.4. O <strong>CORRENTISTA</strong> se responsabiliza pela exatidão dos
          dados informados sobre a conta bancária, de sua titularidade ou de
          terceiros; isentando o “{settings?.display_name}” e a Instituição de
          Pagamento de qualquer responsabilidade pelas transferências realizadas
          em razão de informações imprecisas ou inexatas que venham a ser
          informadas pelo
          <strong> CORRENTISTA</strong>.
        </p>
        <p align="justify">
          7.4.1. Caso não seja possível o resgate de recursos por irregularidade
          na conta bancária indicada, os respectivos valores permanecerão
          retidos e serão mantidos na Conta de Pagamento até que haja a
          regularização pelo <strong>CORRENTISTA</strong>, sem a incidência de
          quaisquer ônus, penalidades ou encargos.
        </p>
        <p align="justify">
          7.5. Quando decorrente de falha técnica e/ou operacional no Sistema ou
          no sistema bancário, a <strong>Instituição de Pagamento</strong>{' '}
          poderá, sem incorrer em qualquer ônus ou penalidade, exceder, em até
          01 (um) dia útil, o prazo estabelecido para efetuar o resgate da Conta
          de Pagamento.
        </p>
        <p align="justify">
          7.6. Na hipótese de a data prevista para o resgate de recursos ser
          considerada feriado ou em dia de não funcionamento bancário, o
          pagamento será realizado no 1º (primeiro) dia útil subsequente.
        </p>
        <p align="justify">
          7.7. A não utilização da Conta de Pagamento, definida como a ausência
          de qualquer utilização pelo prazo de 03 (três) meses, ensejará na
          cobrança de tarifa de inatividade, para o ressarcimento das despesas
          com manutenção, e que será descontada do saldo existente na Conta de
          Pagamento.
        </p>
        <p align="justify">
          <strong>8. Remuneração</strong>
        </p>
        <p align="justify">
          8.1. Em contrapartida à prestação dos serviços de tecnologia que
          integram o Sistema, custódia e gestão de recurso na Conta de Pagamento
          e licença de uso das Funcionalidades, o <strong>CORRENTISTA</strong>{' '}
          pagará ao “{settings?.display_name}” ou à{' '}
          <strong>Instituição de Pagamento </strong>
          (conforme aplicável), às tarifas, fixas ou percentuais, incidentes
          sobre cada Transação realizada.
        </p>
        <p align="justify">
          8.2. O <strong>CORRENTISTA</strong> pagará: (i) tarifa por cada
          Transação realizada no Sistema; (ii) tarifa de saque dos recursos;
          (iii) tarifa por inatividade da Conta de Pagamento; (iv) tarifa para a
          transferência de recursos para a conta corrente de terceiros (caso
          disponível); (v) mensalidade pelo uso da Plataforma ou plataforma do “
          {settings?.display_name}”, se aplicável; e (vi) tarifas adicionais por
          outros serviços a serem contratados, de forma cumulativa com as demais
          tarifas.
        </p>
        <p align="justify">
          8.2.1. O valor das tarifas será informado ao{' '}
          <strong>CORRENTISTA</strong> no Cadastro e/ou divulgado na Plataforma
          no momento da realização da Transação.
        </p>
        <p align="justify">
          8.3. Os valores das tarifas cobradas são variáveis de acordo com a
          natureza de cada Transação, encontrando-se sempre disponíveis para
          consulta pelo <strong>CORRENTISTA</strong> na Plataforma ou mediante
          solicitação por meio dos canais de atendimento disponíveis.
        </p>
        <p align="justify">
          8.4. Para a cobrança das tarifas, inclusive por serviços adicionais
          que vierem a ser contratados pelo <strong>CORRENTISTA</strong>, a
          <strong> Instituição de Pagamento</strong> poderá, alternativamente:
          (i) realizar lançamentos de débitos na Conta de Pagamento; ou (ii)
          compensar o valor dos débitos com quaisquer outros créditos, presentes
          ou futuros, devidos ao
          <strong> CORRENTISTA</strong>.
        </p>
        <p align="justify">
          8.4.1. Caso não haja recursos suficientes para o pagamento das
          tarifas, o “{settings?.display_name}” solicitará ao{' '}
          <strong>CORRENTISTA</strong> o crédito imediato de recursos em sua
          Conta de Pagamento. Tão logo haja recursos na Conta de Pagamento, os
          valores serão debitados automaticamente e sem prévio aviso.
        </p>
        <p align="justify">
          8.4.2. Sem prejuízo da suspensão dos serviços prestados por meio do
          Sistema, caso o <strong>CORRENTISTA</strong> deixe de realizar o
          crédito em sua Conta de Pagamento, haverá a incidência dos encargos
          moratórios estipulados neste Termo.
        </p>
        <p align="justify">
          8.5. O “{settings?.display_name}” ou a{' '}
          <strong>Instituição de Pagamento </strong>
          (conforme aplicável) poderá efetuar o reajuste ou alteração do valor
          das tarifas cobradas, informando previamente o{' '}
          <strong>CORRENTISTA</strong>, por e-mail ou divulgação prévia na
          Plataforma.
        </p>
        <p align="justify">
          8.5.1. Caso o <strong>CORRENTISTA</strong> não concorde com as novas
          condições de remuneração, poderá encerrar este Termo, sem a incidência
          de quaisquer ônus ou penalidades. O não encerramento será interpretado
          como anuência com relação aos novos valores das tarifas cobradas.
        </p>
        <p align="justify">
          8.5.2. Caso sejam criados novos tributos ou alteradas as condições de
          cálculo e/ou cobrança de tributos incidentes sobre a remuneração
          vigente, o “{settings?.display_name}” ou a{' '}
          <strong>Instituição de Pagamento </strong>
          (conforme aplicável), mediante aviso prévio de 10 (dez) dias, irá
          automaticamente reajustar os valores cobrados de forma a restabelecer
          o equilíbrio econômico-financeiro.
        </p>
        <p align="justify">
          8.6. A <strong>Instituição de Pagamento</strong> poderá instituir
          outras modalidades de remuneração, inclusive sobre os serviços
          adicionais que vierem a ser pactuados em instrumentos contratuais
          próprios, mediante prévia comunicação ao <strong>CORRENTISTA</strong>,
          com antecedência de 10 (dez) dias.
        </p>
        <p align="justify">
          8.7. Após o credenciamento no Sistema, o <strong>CORRENTISTA</strong>{' '}
          poderá, a qualquer momento, solicitar o cancelamento de sua adesão e
          encerramento de sua Conta de Pagamento; mediante prévia comunicação
          formal ao “{settings?.display_name}”, com antecedência de 30 (trinta)
          dias.
        </p>
        <p align="justify">
          <strong>9. Prazo de Vigência e Término</strong>
        </p>
        <p align="justify">
          9.1. Este Termo é celebrado por prazo indeterminado, e passa a vigorar
          a partir do momento que o <strong>CORRENTISTA</strong> estiver apto e
          habilitado a realizar Transações pelo Sistema, independentemente de
          realizá-las.
        </p>
        <p align="justify">
          9.1.1 O <strong>CORRENTISTA</strong> será considerado apto e
          habilitado a partir do envio de comunicação, pela Instituição de
          Pagamento ao <strong>CORRENTISTA</strong>, informando o credenciamento
          ao Sistema.
        </p>
        <p align="justify">
          9.2. Este Termo será extinto, a qualquer momento, mediante notificação
          com antecedência de 30 (trinta) dias, por qualquer das Partes.
        </p>
        <p align="justify">
          9.2.1. Salvo quanto às hipóteses abaixo, a extinção deste Termo se
          dará sem a incidência de quaisquer ônus, encargos ou penalidades;
          ressalvadas as obrigações pendentes e que deverão ser devidamente
          cumpridas pelo prazo necessário.
        </p>
        <p align="justify">
          9.3. Haverá a rescisão imediata e motivada deste Termo, nas hipóteses
          de: (i) decretação de falência, pedido de recuperação judicial ou
          extrajudicial ou encerramento das atividades de qualquer das Partes,
          ao exclusivo critério da outra Parte; ou (ii) o descumprimento de
          qualquer obrigação estabelecida neste Termo que não seja sanada no
          prazo estipulado ou, na omissão, no prazo de 10 (dez) dias contados da
          notificação ou aviso.
        </p>
        <p align="justify">
          9.4. Caso a rescisão do Termo ocorra por culpa do{' '}
          <strong>CORRENTISTA</strong>, fica desde já estabelecido que seu
          acesso à Plataforma e ao Sistema será imediatamente bloqueado, com a
          suspensão de sua Conta de Pagamento e retenção dos créditos do{' '}
          <strong>CORRENTISTA</strong> pelo prazo necessário para que possam ser
          resguardados os direitos do “{settings?.display_name}”, da Instituição
          de Pagamento, de outros <strong>CORRENTISTA</strong> e de terceiros;
          sem prejuízo da adoção de outras medidas legais necessárias e da
          apuração e ressarcimento de eventuais danos complementares.
        </p>
        <p align="justify">
          <strong>
            10. Isenção De Responsabilidade do “{settings?.display_name}”
          </strong>
        </p>
        <p align="justify">
          Devido à existência de alguns fatores que não podem ser controlados ou
          evitados pelo “{settings?.display_name}” e/ou pela Instituição de
          Pagamento, que podem resultar em uma aparente falha em nossos
          serviços, não nos responsabilizamos por:
        </p>
        <p align="justify">
          10.1. Falha de operação, operação por pessoas não autorizadas ou
          qualquer outra causa em que não exista culpa do “{settings?.display_name}”.
        </p>
        <p align="justify">
          10.2. Não cumprimento dos prazos legais do{' '}
          <strong>CORRENTISTA</strong> para entrega de documentos solicitados
          pela “{settings?.display_name}”, pagamentos de impostos e/ou guias de
          pagamento;
        </p>
        <p align="justify">
          10.2.1 Danos ou prejuízos decorrentes de decisões administrativas,
          gerenciais, comerciais ou judiciais tomadas com base nas informações
          fornecidas pelo software;
        </p>
        <p align="justify">
          10.3. Problemas definidos como “caso fortuito“ ou “força maior“
          contemplados pelo art. 393, do Código Civil Brasileiro;
        </p>
        <p align="justify">
          10.4. Eventuais problemas oriundos de ações de terceiros que possam
          interferir na qualidade dos serviços;
        </p>
        <p align="justify">
          10.5. Revisão das informações de conta, tampouco pelo conteúdo
          fornecidos pelo <strong>CORRENTISTA</strong>, sejam fiscais ou pelas
          informações financeiras e obtidas junto aos sites e/ou meios virtuais
          das instituições financeiras, seja no que tange à precisão dos dados,
          seja quanto à legalidade ou ameaça de violação em função do
          fornecimento destas informações. O acesso às informações de conta e às
          informações financeiras do <strong>CORRENTISTA</strong> depende de
          serviços prestados pelas instituições financeiras. Sendo assim, a
          <strong> Instituição de Pagamento</strong> não assume qualquer
          responsabilidade quanto à qualidade, precisão, pontualidade, entrega
          ou falha na obtenção das referidas informações junto aos sites e/ou
          meios virtuais das instituições financeiras;
        </p>
        <p align="justify">
          10.6. Quaisquer produtos e/ou serviços oferecidos por meio dos sites
          e/ou meios virtuais das instituições financeiras, ainda que de maneira
          solidária ou subsidiária, e
        </p>
        <p align="justify">
          10.7. Falta de envio de informações, informações incompletas,
          equivocadas ou omissas do <strong>CORRENTISTA</strong> ou da sua
          contabilidade, que possam prejudicar a correta execução dos serviços.
        </p>
        <p align="justify">
          <strong>
            11. Responsabilidades Adicionais do <strong>CORRENTISTA</strong>
          </strong>
        </p>
        <p align="justify">
          11.1. Todos os tributos incidentes na prestação dos serviços e na
          licença de uso das Funcionalidades disponibilizadas no Sistema são de
          exclusiva responsabilidade do <strong>CORRENTISTA</strong>, podendo o
          “{settings?.display_name}” ou a{' '}
          <strong>Instituição de Pagamento</strong> (conforme aplicável)
          descontar os respectivos valores dos créditos do{' '}
          <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          11.2. A realização da transferência bancária de recursos, pagamento do
          boleto bancário, dentre outras operações, poderão estar sujeitas à
          cobrança de tarifas, taxas ou encargos, de acordo com os critérios e
          valores estabelecidos pelas instituições financeiras; sendo que o “
          {settings?.display_name}” não possui qualquer ingerência sobre os
          valores cobrados do <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          11.3. O <strong>CORRENTISTA</strong> reconhece e concorda que a
          realização das Transações pelo Sistema está sujeita a aplicação da
          legislação vigente, inclusive de prevenção a lavagem de dinheiro,
          financiamento ao terrorismo e combate à corrupção; estando os repasses
          dos valores das Transações sujeitos ao estrito cumprimento da
          legislação aplicável.
        </p>
        <p align="justify">
          11.4. Para utilização das Funcionalidades e acesso ao Sistema, o
          <strong> CORRENTISTA</strong> deverá possuir equipamentos (computador,
          smartphone, tablet ou outros dispositivos similares) com acesso à
          internet e em condições compatíveis para seu uso, sendo de exclusiva
          responsabilidade do <strong>CORRENTISTA</strong> a obtenção,
          manutenção e custeio de tal acesso e dos equipamentos necessários
          (incluindo tributos, tarifas ou encargos cobrados pelos fornecedores
          de serviços).
        </p>
        <p align="justify">
          11.4.1 O “{settings?.display_name}” e a{' '}
          <strong>Instituição de Pagamento</strong> não serão responsáveis pela
          não realização da Transação em razão da incompatibilidade dos
          equipamentos ou pela ausência ou falha no acesso à internet.
        </p>
        <p align="justify">
          11.5. O <strong>CORRENTISTA</strong> compromete-se a isentar o “{settings?.display_name}” e a <strong>Instituição de Pagamento</strong> de
          toda e qualquer reclamação ou litígio judicial ou extrajudicial
          decorrente da utilização do Sistema, inclusive no que se refere às
          próprias atividades do <strong>CORRENTISTA</strong> e às questões
          relacionadas com os negócios jurídicos celebrados pelo
          <strong> CORRENTISTA</strong> fora do Sistema.
        </p>
        <p align="justify">
          11.6. O <strong>CORRENTISTA</strong> obriga-se a ressarcir o “{settings?.display_name}” e a <strong>Instituição de Pagamento</strong> de
          todos os valores comprovadamente despendidos em ações judiciais ou
          processos administrativos que tenham sido ajuizados contra o “{settings?.display_name}” ou a <strong>Instituição de Pagamento</strong> em
          razão do descumprimento de obrigações imputáveis ao
          <strong> CORRENTISTA</strong>.
        </p>
        <p align="justify">
          11.6.1 A <strong>Instituição de Pagamento</strong> poderá reter os
          créditos a serem pagos ao <strong>CORRENTISTA</strong> e compensá-los
          para o pagamento de débitos decorrentes de condenações, custas e
          despesas processuais, além de honorários advocatícios.
        </p>
        <p align="justify">
          11.7. O “{settings?.display_name}” e a{' '}
          <strong>Instituição de Pagamento </strong>
          possuem uma Política de Privacidade que indica como as informações do
          <strong> CORRENTISTA</strong> são coletadas, utilizadas, armazenadas,
          tratadas, compartilhadas, divulgadas e protegidas. O{' '}
          <strong>CORRENTISTA</strong> deve ler atentamente a Política de
          Privacidade, que é parte integrante deste Termo.
        </p>
        <p align="justify">
          11.7.1 O “{settings?.display_name}” e a{' '}
          <strong>Instituição de Pagamento</strong> irão adotar todas as medidas
          necessárias e se utilizar de tecnologias adequadas para proteger a
          coleta, processamento e armazenamento das informações do{' '}
          <strong>CORRENTISTA</strong>; porém não tem como assegurar que
          terceiros não autorizados se utilizem de meios fraudulentos para
          furto, uso indevido, alteração ou acesso não autorizado às informações
          do
          <strong> CORRENTISTA</strong>.
        </p>
        <p align="justify">
          <strong>12. Publicidade</strong>
        </p>
        <p align="justify">
          12.1. A <strong>Instituição de Pagamento</strong> poderá divulgar ou
          disponibilizar em seu Site anúncios, logos, links, endereços virtuais
          ou demais formas de direcionamento do <strong>Correntista</strong>{' '}
          para sites, serviços, aplicativos, ambientes virtuais ou softwares de
          terceiros.
        </p>
        <p align="justify">
          12.2. A <strong>Instituição de Pagamento</strong> não atua como
          prestadora de serviços de consultoria, intermediária ou participante
          em nenhum negócio jurídico entre seus <strong>CORRENTISTA</strong> e
          seus anunciantes. Sendo assim, o Sistema pode incluir ou direcionar o{' '}
          <strong>CORRENTISTA</strong> para conteúdo, ambientes virtuais,
          informações ou dados que não são de seu controle ou responsabilidade.
          Desta forma, a <strong>Instituição de Pagamento</strong> não se
          responsabiliza, a qualquer título e a qualquer tempo, por referidos
          sites, serviços, aplicativos, ambientes virtuais ou softwares de
          terceiros ou por eles disponibilizados, sendo a responsabilidade de
          seu respectivo controlador.
        </p>
        <p align="justify">
          12.3. É dever do <strong>CORRENTISTA</strong> a leitura e o
          cumprimento dos Termos de Uso e das Políticas de Privacidade dos
          ambientes de terceiros acessados a partir do uso do Sistema. O {settings?.display_name} e a <strong>Instituição de Pagamento</strong> não
          verificam, controlam, aprovam ou garantem a adequação ou exatidão das
          informações ou dados disponibilizados em tais links, não sendo,
          portanto, responsáveis por prejuízos, perdas ou danos ocasionados por
          atrasos, defeitos, erros ou omissões que possam existir nos sistemas
          ou serviços disponibilizados pelos sites referidos acima ou ocorridos
          pela visita de tais sites, cabendo ao
          <strong> CORRENTISTA</strong> verificar a confiabilidade das
          informações e dados ali exibidas antes de utilizá-los.
        </p>
        <p align="justify">
          12.4. Desse modo, o <strong>CORRENTISTA</strong> deve sempre verificar
          a veracidade das informações obtidas por meio eletrônico e tomar todas
          as medidas necessárias para se proteger de danos, fraudes ou
          estelionatos, online ou off-line.
        </p>
        <p align="justify">
          <strong>13. Propriedade Intelectual</strong>
        </p>
        <p align="justify">
          13.1. O “{settings?.display_name}”, na qualidade de licenciada,
          autoriza o uso pelo <strong>CORRENTISTA</strong> das Funcionalidades
          que integram o Sistema, durante o prazo de vigência deste Termo,
          mediante os termos e condições ora estabelecidos.
        </p>
        <p align="justify">
          13.2. O <strong>CORRENTISTA</strong> reconhece e concorda que a
          propriedade intelectual da Plataforma e das Funcionalidades é de
          integral e exclusiva titularidade da{' '}
          <strong>Instituição de Pagamento</strong>, na qualidade de licenciada.
        </p>
        <p align="justify">
          13.2.1 É vedado ao <strong>CORRENTISTA</strong>: (i) copiar ou
          transferir de qualquer forma, total ou parcialmente, quaisquer
          Funcionalidades ou informações relativas às Funcionalidades; (ii)
          modificar as características das Funcionalidades ou realizar sua
          integração com outros sistemas ou softwares; (iii) copiar os dados
          extraídos do Sistema, exceto aqueles relativos às movimentações da
          Conta de Pagamento.
        </p>
        <p align="justify">
          13.3. O <strong>CORRENTISTA</strong> compromete-se a não infringir
          quaisquer direitos relativos às marcas, patentes, software, domínio na
          internet, segredo industrial ou, ainda, direito de propriedade
          industrial ou direito autoral de quaisquer serviços ou Funcionalidades
          disponibilizados no âmbito deste Termo, bem como a não usar o nome,
          marca, logomarca ou qualquer tipo de sinal distintivo do “{settings?.display_name}” , da <strong>Instituição de Pagamento</strong> ou de seus
          parceiros, sem o consentimento prévio e escrito.
        </p>
        <p align="justify">
          <strong>14. Serviços de Terceiros</strong>
        </p>
        <p align="justify">
          14.1. A <strong>Instituição de Pagamento</strong> e o “
          {settings?.display_name}”, conforme aplicável, poderão celebrar
          parcerias com Fornecedores, permitindo ao <strong>CORRENTISTA</strong>{' '}
          a possibilidade de contratação de serviços e produtos disponíveis na
          Plataforma.
        </p>
        <p align="justify">
          14.2. No que se refere aos Serviços de Terceiros, a Instituição de
          Pagamento e o “{settings?.display_name}” são meras intermediadoras da
          relação entre o <strong>CORRENTISTA</strong> e o Fornecedor, uma vez
          que a tecnologia disponível na Plataforma tem a finalidade de
          aproximar as partes.
        </p>
        <p align="justify">
          14.3. Serão disponibilizados na Plataforma informações sobre os
          Fornecedores, seus produtos e serviços; possibilitando ao{' '}
          <strong>CORRENTISTA </strong>
          realizar a contratação direta com tais Fornecedores.
        </p>
        <p align="justify">
          14.3.1. Para utilização dos Serviços de Terceiros, o{' '}
          <strong>CORRENTISTA </strong>
          poderá ou não ser direcionado a um site ou outra plataforma. Além
          disso, poderá ser exigido pelos fornecedores novas informações,
          documentos e/ou dados relacionados ao <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          14.4. Por se tratar de uma atividade de intermediação, a Instituição
          de Pagamento e o “{settings?.display_name}” não possuem qualquer
          interferência nas condições, preços e execução dos Serviços de
          Terceiros, inclusive sobre as condições de contratação, solicitação de
          documentos e demais atos pertinentes. Os Fornecedores serão única e
          exclusivamente responsáveis por todas as questões relacionadas aos
          Serviços de Terceiros oferecidos na Plataforma.
        </p>
        <p align="justify">
          14.5. O “{settings?.display_name}” e a{' '}
          <strong>Instituição de Pagamento</strong> não poderá, em nenhuma
          hipótese, ser responsabilizada por quaisquer reclamações decorrentes
          da contratação dos Serviços de Terceiros, cabendo ao{' '}
          <strong>CORRENTISTA</strong> contatar diretamente o Fornecedor
          responsável pela sua prestação.
        </p>
        <p align="justify">
          14.6. O Fornecedor será exclusivamente responsável por todas as
          informações divulgadas na Plataforma acerca dos Serviços de Terceiros,
          inclusive as imagens, suas características e respectivos preços, assim
          como pela qualidade, existência, quantidade, segurança, entrega e
          garantia do quanto prometido; de forma que o “{settings?.display_name}
          ” e a <strong>Instituição de Pagamento</strong> não exercerão qualquer
          controle ou fiscalização e não terá qualquer responsabilidade sobre os
          Serviços de Terceiros e/ou a veracidade das informações
          disponibilizadas na Plataforma.
        </p>
        <p align="justify">
          14.7. Além disso, o “{settings?.display_name}” e a Instituição de
          Pagamento não se responsabilizam pela idoneidade, capacidade
          técnico-operacional e financeira dos Fornecedores, atuando apenas como
          meras intermediadoras da sua relação com o{' '}
          <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          <strong>15. Modificações e Revisões do Termo</strong>
        </p>
        <p align="justify">
          15.1. Este Termo e seus Anexos serão periodicamente revistos e
          alterados pela <strong>Instituição de Pagamento</strong> para adequar
          a prestação dos Serviços. A <strong>Instituição de Pagamento</strong>{' '}
          poderá alterar este Termo e seus Anexos, excluindo, modificando ou
          inserindo cláusulas ou condições, sempre que necessário.
        </p>
        <p align="justify">
          15.2. A <strong>Instituição de Pagamento</strong> e/ou o “
          {settings?.display_name}” poderão alterar, suspender ou cancelar, ao
          seu critério, tanto em forma como em conteúdo, a qualquer tempo,
          quaisquer das funcionalidades disponíveis na Plataforma.
        </p>
        <p align="justify">
          15.3. As alterações do Termo deverão ser comunicadas pela Instituição
          de Pagamento e/ou pelo “{settings?.display_name}” ao{' '}
          <strong>CORRENTISTA</strong>, por e-mail ou por meio de comunicado na
          Plataforma, passando a vigorar após 10 (dez) dias da comunicação ou
          divulgação.
        </p>
        <p align="justify">
          15.4. Caso o <strong>CORRENTISTA</strong> não concorde com as
          alterações, poderá denunciar este Termo sem qualquer ônus ou
          penalidade, desde que não se encontre em débito perante a Instituição
          de Pagamento.
        </p>
        <p align="justify">
          15.5. A realização de novas Transações e utilização das
          Funcionalidades pelo <strong>CORRENTISTA</strong> será interpretada
          como concordância e aceitação das alterações realizadas.
        </p>
        <p align="justify">
          <strong>16. Disposições Gerais</strong>
        </p>
        <p align="justify">
          16.1. O <strong>CORRENTISTA</strong> declara-se ciente e concorda que,
          independentemente do local de onde esteja utilizando os serviços que
          integram o Sistema, a relação entre as Partes será sempre regida pela
          legislação brasileira.
        </p>
        <p align="justify">
          16.2. Nos termos da legislação aplicável e da Política de Privacidade,
          o <strong>CORRENTISTA</strong> concorda que o “{settings?.display_name}” ou qualquer de seus parceiros enviem mensagens de caráter
          informativo ou publicitário, bem como que seus dados sejam utilizados
          de forma anonimizada, para a criação de banco de dados.
        </p>
        <p align="justify">
          16.3. A <strong>Instituição de Pagamento</strong> irá auxiliar e
          cooperar com qualquer autoridade judicial, reguladora ou órgão público
          que venha a solicitar informações, podendo, neste caso, fornecer
          quaisquer informações sobre o <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          16.4. As Partes elegem o Foro da Cidade de Belo Horizonte / MG como
          único competente para dirimir as questões decorrentes deste Termo, com
          renúncia expressa a qualquer outro, por mais privilegiado que seja.
        </p>
        <p align="justify">
          <strong>17. Canais de atendimento</strong>
        </p>
        <p align="justify">
          Consultas, sugestões, reclamações, críticas e elogios podem ser feitas
          através de nossos canais de atendimento: suporte online direto no
          aplicativo ou por e-mail no endereço
          <a href="suporte@qesh.ai">
            <u> suporte@qesh.ai</u>
          </a>
          .
        </p>
        <p align="center">
          <strong>ANEXO I – PAGAMENTOS INSTANT NEOS PELO PIX</strong>
        </p>
        <p align="center">
          Termos e Condições de Uso da Plataforma do “{settings?.display_name}”
        </p>
        <p align="justify">
          Este anexo (“Anexo”) é parte integrante e inseparável dos Termos e
          Condições de Uso da Plataforma do “{settings?.display_name}” (“Termo”)
          e tem por objetivo estabelecer as condições para realização de
          Transações de Pagamentos Instantâneos, pela QESH INSTITUIÇÃO DE
          PAGAMENTO LTDA. (“<strong>Instituição de Pagamento</strong>”), no
          âmbito do Pix.
        </p>
        <p align="justify">
          1. O <strong>CORRENTISTA</strong> poderá realizar Transações de
          transferência ou recebimento de recursos, durante 24 (vinte e quatro)
          horas por dia e em todos os dias do ano, por meio de pagamentos
          instantâneos realizados no âmbito do Pix, caso disponível no Sistema
          (“Pagamentos Instantâneos”).
        </p>
        <p align="justify">
          2. Os Pagamentos Instantâneos serão realizados por meio do arranjo de
          pagamentos Pix, instituído pelo Bacen, cujas regras e condições o
          <strong> CORRENTISTA</strong> declara conhecer e aceitar.
        </p>
        <p align="justify">
          3. Para que o <strong>CORRENTISTA</strong> possa realizar uma
          Transação de transferência ou recebimento de recursos no Pix, deve
          possuir Conta de Pagamento ativa na{' '}
          <strong>Instituição de Pagamento</strong>, por intermédio do “
          {settings?.display_name}.
        </p>
        <p align="justify">
          3.1. A depender da parceria celebrada entre o {settings?.display_name}
          e a <strong>Instituição de Pagamento</strong>, o{' '}
          <strong>CORRENTISTA</strong> poderá realizar Pagamento Instantâneos
          por duas modalidades: (i) “Solução Simples”, na qual será
          disponibilizado uma Chave Pix, por QR Code, para que o{' '}
          <strong>CORRENTISTA </strong>
          possa receber pagamentos pelo Pix; ou (ii) “Solução Completa”, na qual
          a Conta de Pagamento estará vinculada a uma ou mais Chaves Pix
          cadastradas pelo do <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          3.2. Na Solução Simples, o QR Code indicará a{' '}
          <strong>Instituição de Pagamento </strong>
          como beneficiária, mas possibilitará a identificação do{' '}
          <strong>CORRENTISTA</strong>, de modo a possibilitar o repasse dos
          valores decorrentes das Transações realizadas. Para possibilitar o
          recebimento de pagamentos pelo Pix, o <strong>CORRENTISTA</strong>{' '}
          expressamente autoriza a <strong>Instituição de Pagamento</strong> a,
          por sua conta e ordem, receber os recursos na conta transacional
          aberta perante um ou mais parceiros contratados para esta finalidade;
          concedendo-lhe, neste ato, todos os poderes necessários para tanto.
        </p>
        <p align="justify">
          3.3. Caso seja possível realização de Pagamentos Instantâneos pela
          Solução Completa, o <strong>CORRENTISTA</strong> gerar QR Code,
          cadastrar suas Chaves Pix e receber pagamentos em nome próprio.
        </p>
        <p align="justify">
          3.4. A Solução Completa poderá não estar disponível no momento da
          adesão ao Termo, e ser disponibilizada posteriormente pelo {settings?.display_name}, mediante comunicação ao{' '}
          <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          4. O <strong>CORRENTISTA</strong> poderá, de acordo com as regras
          instituídas pelo Bacen: (i) solicitar a devolução do valor de uma
          Transação de Pagamento Instantâneo através do Mecanismo Especial de
          Devolução; ou (ii) ter bloqueado o valor mantido na Conta de
          Pagamento, em razão de uma devolução solicitada por outro usuário no
          âmbito do Pix, nos casos em que verificar falha técnica ou operacional
          no sistema da <strong>Instituição de Pagamento</strong> ou a prática
          de fraude pelo usuário recebedor da Transação de Pagamento
          Instantâneo; sendo vedada a solicitação em razão de eventual
          descumprimento das obrigações que deram origem à Transação (nas
          hipóteses de desacordo comercial, ausência ou atraso na entrega dos
          produtos ou serviços, divergências com relação ao preço ou condições
          de pagamento, dentre outras).
        </p>
        <p align="justify">
          4.1. A devolução da Transação de Pagamento Instantâneo pelo Mecanismo
          Especial de Devolução deve ser solicitada pelo{' '}
          <strong>CORRENTISTA</strong> até o prazo de 90 (noventa) dias corridos
          – ou outro prazo que for instituído pelo Bacen - contados da data em
          que a Transação de Pagamento Instantâneo foi realizada. As
          solicitações de devolução serão realizadas na Plataforma ou mediante o
          contato por meio dos canais de atendimento do {settings?.display_name}
          ; devendo o <strong>CORRENTISTA</strong> encaminhar todas as
          informações necessárias (tais como, solicitação de devolução, motivo e
          valor).
        </p>
        <p align="justify">
          4.2. Em razão da solicitação do <strong>CORRENTISTA</strong>, a
          <strong> Instituição de Pagamento</strong> realizará a notificação da
          instituição bancária ou de pagamento que possui vínculo com o usuário
          recebedor, comunicando-a da falha sistêmica ou da suspeita de fraude
          (conforme aplicável). Caberá à referida instituição avaliar a
          solicitação, por critérios próprios e de acordo com as orientações do
          Bacen; podendo ou não e realizar o bloqueio dos recursos (desde que
          haja disponibilidade de recursos na conta bancária ou de pagamento do
          usuário recebedor).
        </p>
        <p align="justify">
          4.3. A solicitação de devolução dos recursos será realizada de acordo
          com os procedimentos, prazos e limites aplicáveis, nos termos das
          normas instituídas pelo Bacen, de modo que o{' '}
          <strong>CORRENTISTA</strong> declara ciente e concorda que:
        </p>
        <p align="justify">
          I. A solicitação de devolução não garante a restituição do valor de
          uma Transação de Pagamento Instantâneo, posto que o Mecanismo Especial
          de Devolução apenas possibilita a abertura de disputa no âmbito do
          Pix;
        </p>
        <p align="justify">
          II. A solicitação poderá ser rejeitada caso não haja a efetiva
          comprovação da falha sistêmica ou fraude, de acordo com a apuração que
          será realizada preliminarmente, pela{' '}
          <strong>Instituição de Pagamento</strong> e, posteriormente, pela
          instituição que possui relacionamento com o usuário recebedor;
        </p>
        <p align="justify">
          III. Não será possível a solicitação de devolução após o decurso do
          prazo previsto nas normas aplicáveis;
        </p>
        <p align="justify">
          IV. A solicitação de devolução não poderá ser realizada para
          determinadas modalidades de Pagamentos Instantâneos; e
        </p>
        <p align="justify">
          V. A ausência de envio das informações e documentos solicitados
          ensejará no cancelamento da solicitação.
        </p>
        <p align="justify">
          VI. Concluída a avaliação, caso a instituição vinculada com o usuário
          recebedor proceda a devolução dos recursos, a{' '}
          <strong>Instituição de Pagamento </strong>
          realizará o crédito na Conta de Pagamento do{' '}
          <strong>CORRENTISTA</strong>.
        </p>
        <p align="justify">
          4.4. O <strong>CORRENTISTA</strong> poderá solicitar o cancelamento no
          prazo de até 30 (trinta) dias contados da solicitação da devolução.
        </p>
        <p align="justify">
          4.5. O <strong>CORRENTISTA</strong> será responsável pela veracidade
          das informações prestadas, comprometendo-se a apenas solicitar a
          devolução de um Pagamento Instantâneo quando houver comprovação da
          prática de fraude ou falha sistêmica. O <strong>CORRENTISTA</strong>{' '}
          assume integral responsabilidade pelas solicitações realizadas no
          âmbito do Mecanismo Especial de Devolução, compromete-se a ressarcir o
          {settings?.display_name}, a <strong>Instituição de Pagamento</strong>{' '}
          e os participantes e usuários do Pix pelos prejuízos que venham a ser
          causados em razão de solicitações infundadas ou do envio incorreto de
          informações.
        </p>
        <p align="justify">
          <strong>
            4.6. O <strong>CORRENTISTA</strong> declara-se ciente e concorda que
            a <strong>Instituição de Pagamento</strong> poderá realizar a
            devolução de valores decorrentes dos Pagamentos Instantâneos por
            meio do Mecanismo Especial de Devolução, para os casos em que: (i)
            houver fundada suspeita do uso do Pix para a prática de fraudes, de
            acordo com a apuração realizada pela{' '}
            <strong>Instituição de Pagamento</strong>, por iniciativa própria ou
            em razão do recebimento de denúncias de outros usuários do Pix; ou
            (ii) forem constatadas falhas operacionais no sistema de quaisquer
            participantes envolvidos na Transação.
          </strong>
        </p>
        <p align="justify">
          <strong>
            4.6.1. Para possibilitar a apuração da conduta fraudulenta ou da
            falha sistêmica, a <strong>Instituição de Pagamento</strong> poderá
            realizar o bloqueio cautelar, mediante a retenção dos recursos
            mantidos na Conta de Pagamento do <strong>CORRENTISTA</strong>, de
            acordo com o prazo e as regras instituídas pelo Bacen e das
            políticas instituídas pela <strong>Instituição de Pagamento</strong>
            .
          </strong>
        </p>
        <p align="justify">
          <strong>
            4.6.2. O bloqueio será realizado pelo valor da Transação objeto da
            devolução, podendo recair sobre o saldo existente ou que seja
            decorrente de outras Transações futuras realizadas pelo{' '}
            <strong>CORRENTISTA</strong>.
          </strong>
        </p>
        <p align="justify">
          <strong>
            4.6.3. Havendo evidências da prática de fraude ou sendo constatada a
            falha sistêmica, a Transação será automaticamente cancelada e os
            recursos serão restituídos ao usuário pagador. Caso a Instituição de
            Pagamento verifique a regularidade da Transação, os recursos
            bloqueados serão liberados, mediante a comunicação ao{' '}
            <strong>CORRENTISTA</strong>.
          </strong>
        </p>
        <p align="justify">
          5. A realização de Transações de Pagamentos Instantâneos poderá
          ensejar na cobrança de Tarifa adicional, em valor previamente
          informado; observadas as condições estabelecidas pelo Bacen para
          isenção de pagamento.
        </p>
        <p align="justify">
          5.1. Para a realização das Transações de Pagamentos Instantâneos,
          devolução das Transações e cadastro das Chaves Pix, o{' '}
          <strong>CORRENTISTA</strong>, desde já, manifesta seu expresso
          consentimento, para fins de coleta, tratamento e transmissão das
          informações ao prestador de serviços que será responsável pela
          liquidação das Transações e realização dos demais atos necessários
          perante o Pix, inclusive o acesso das Chaves Pix.
        </p>
        <p align="justify">
          6. O <strong>CORRENTISTA</strong>, deverá utilizar da marca “Pix” de
          acordo com as condições e limites estabelecidos pelo Bacen,
          declarando-se ciente de que:
        </p>
        <p align="justify">
          I. A marca Pix é de titularidade exclusiva do Bacen e sua utilização
          deverá ser realizada em conformidade com os termos do regulamento Pix
          e do manual de uso relacionado com a marca.
        </p>
        <p align="justify">
          II. Não deverá veicular a marca Pix em dimensão inferior às marcas,
          símbolos ou logotipos dos demais Instrumentos de Pagamento aceitos.
        </p>
        <p align="justify">
          III. Não poderá transmitir a impressão de que o Pix possui aceitação
          mais restrita ou menos vantajosa perante outros Instrumentos de
          Pagamento aceitos.
        </p>
        <p align="justify">
          IV. Não poderá: (i) reivindicar quaisquer direitos sobre a marca Pix;
          (ii) questionar a titularidade do Bacen sobre a marca Pix; (iii)
          registrar ou tentar registrar razão social, nome fantasia, logotipo ou
          qualquer nome de domínio de internet contendo referência à marca Pix;
          (iv) associar a marca Pix a quaisquer produtos não relacionados ao
          arranjo Pix; ou (v) utilizar a marca Pix além dos limites previstos no
          regulamento do Pix e respectivos manuais instituídos pelo Bacen.
        </p>
        <p align="justify">
          V. Não poderá utilizar a Marca Pix de modo a acarretar prejuízos ao
          Bacen ou ao arranjo Pix.
        </p>
        <p align="justify">
          VI. Deverá comunicar à <strong>Instituição de Pagamento</strong>,
          imediatamente, através do e-mail ouvidoria@qesh.ai, sempre que tomar
          conhecimento do uso indevido, tentativa de cópia ou infração aos
          direitos decorrentes da marca Pix; e.
        </p>
        <p align="justify">
          VII. O uso da marca Pix não confere ao <strong>CORRENTISTA</strong>{' '}
          qualquer direito de titularidade ou outro benefício referente à marca.
        </p>
        <p align="justify">
          6.1. O <strong>CORRENTISTA</strong> poderá solicitar à Instituição de
          Pagamento o envio da arte final apropriada, disponibilizada pelo Bacen
          para o uso da marca Pix.
        </p>
        <p align="justify">
          6.2. O <strong>CORRENTISTA</strong> declara-se ciente de que, nos
          termos do regulamento do Pix e demais normas instituídas pelo Bacen:
        </p>
        <p align="justify">
          I. A <strong>Instituição de Pagamento</strong> será responsável pela
          realização das Transações de Pagamentos Instantâneos, mediante a
          transmissão dos dados ao prestador de serviços responsável pela
          liquidação no âmbito do Pix.
        </p>
        <p align="justify">
          II. As Transações de Pagamentos instantâneos apenas poderão ser
          realizadas caso haja recursos disponíveis na Conta de Pagamento.
        </p>
        <p align="justify">
          III. A liquidação das Transações de transferência, recebimento ou
          devolução de Pagamentos Instantâneos, o cadastro ou reivindicação de
          Chaves Pix e os demais serviços relacionados com o Pix, serão
          prestados por prestador de serviços contratado pela Instituição de
          Pagamento, na qualidade de responsável pela prática dos atos no âmbito
          do Pix; e
        </p>
        <p align="justify">
          IV. Os sistemas do Pix e/ou do prestador de serviços responsável pela
          liquidação poderão estar indisponíveis em determinados períodos,
          independente de aviso prévio, inclusive em caso de manutenção
          programada (realizada entre as 20hs00 e 08hs00), impossibilitando a
          realização de Transações.
        </p>
        <p align="justify">
          7. A <strong>Instituição de Pagamento</strong> poderá rescindir o
          Termo em caso de infração praticada pelo <strong>CORRENTISTA</strong>,
          que importe na prática de fraude, ato ilícito ou utilização indevida
          do arranjo Pix.
        </p>
        <p align="justify">
          7.1 A <strong>Instituição de Pagamento</strong> poderá rescindir este
          Termo, automaticamente e de pleno direito, em caso de infração grave,
          praticada pelo <strong>CORRENTISTA</strong>, quanto à violação das
          regras de uso da marca Pix.
        </p>
      </div>
    </>
  );
};

export default Terms;
