import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 49%;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
`;

export const Label = styled.span`
  font-size: 0.8rem;
`;

export const ColorSelection = styled.div`
  width: 50%;
  height: 31px;
  border-radius: 8px;
  background-color: ${(props) => (props.color ? props.color : '#521aa3')};
  display: flex;
  justify-content: flex-start;
  padding: 0 15px;
  align-items: center;

  span {
    font-size: 0.8rem;
    color: #ffffff;
  }
`;
