/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileUpload,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { message, Spin, Modal as ModalAntd } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  Card,
  Container,
  DropButton,
  DropContainer,
  Buttons,
  MainTitle,
  Options,
  Option,
  Subtitle,
  Section,
  Box,
  Texts,
  Displayer,
  Conditions,
  ModalTitle,
  SubtitleModal,
  TextModal,
  DocumentsTextContainer,
  Overlay,
} from './styles';
import ButtonN from '../../../../components/ButtonN';
import { useSettings } from '../../../../hooks/settings';
import ModalForTransition from '../../../../components/ModalForTransition';
import { useRefreshPj } from '../../../../hooks/refreshPj';
import api from '../../../../services/api';
import { documentPost } from '../../../../services/endPointRegister/PJ/documentPost';

const Documents = () => {
  const { getEmailSupportMessage } = useSettings();

  const history = useHistory();
  const [cont, setCont] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [hasAFile, setHasAFile] = React.useState(false);
  const [blacklistTest, setBlackListTest] = React.useState(false);
  const [typeError, setTypeError] = React.useState('');
  const [nationality, setNationality] = React.useState('Brasil');
  const {
    address, token, refreshApi, setStep, userPj,
  } = useRefreshPj();
  const [documentsData, setDocumentsData] = React.useState({
    documentsType: '',
    documentsFront: '',
    documentsBack: '',
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const nat = address.country;
    if (nat) {
      setNationality(nat);
    } else {
      setNationality('Brasil');
    }
  });
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,

    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      let receivedFile;
      // eslint-disable-next-line array-callback-return
      acceptedFiles.map((file) => {
        reader.readAsDataURL(file);
        reader.onload = () => {
          setDocumentsData(file);
        };
        receivedFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      if (receivedFile.size <= 5000000 && receivedFile.type === 'application/pdf') {
        setFiles(receivedFile);
        setHasAFile(true);
      }
      if (receivedFile.type !== 'application/pdf') {
        message.error('O arquivo não é um arquivo PDF');
      }
      if (receivedFile.size > 5000000) {
        message.error('O formato do arquivo a ser enviado deve ser PDF com no máximo 5MB', 4);
      }
    },
  });
  const handleDelete = () => {
    setFiles();
  };

  const handleHome = async () => {
    setLoading(false);
    setBlackListTest(false);
    history.replace('/');
  };

  const modalText = () => {
    if (typeError === 'blacklist') {
      return getEmailSupportMessage();
    }
    if (typeError === 'attempts') {
      return `Tentativas excedidas. ${getEmailSupportMessage()} ou tente novamente amanhã.`;
    }
    if (typeError === 'error') {
      return 'Tente tirar uma nova foto e reenviar';
    }
  };
  const handleSocialContractRequisition = async () => {
    const formData = new FormData();
    formData.append('file', files);
    try {
      const response = await api
        .post('onboarding/documents/COMPANY', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'multipart/form-data',
          },
        });

      setStep('partners');
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <Section>
        <Texts>
          <MainTitle>Agora é hora de enviar o Contrato Social da empresa</MainTitle>
        </Texts>
        <Card minHeight={hasAFile ? '30rem' : '20rem'}>
          <>
            <Container>
              <Box>
                <DocumentsTextContainer>
                  <h2>
                    Envie o PDF do documento escaneado
                  </h2>
                </DocumentsTextContainer>
                {!hasAFile && (
                <DropContainer {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <div>
                    <DropButton>
                      <FontAwesomeIcon icon={faFileUpload} size="5x" />
                    </DropButton>
                    <h2>Fazer o upload</h2>
                  </div>
                </DropContainer>
                )}
                {hasAFile && (
                <Displayer onClick={() => setExpanded(true)}>
                  <iframe
                    src={files?.preview}
                    title="Pdf preview"
                    allowFullScreen
                    style={{ width: '100%', height: '23rem', border: 'none' }}
                  />
                  <Overlay>
                    <span>clique aqui para ter uma visualização maior do arquivo</span>
                  </Overlay>
                </Displayer>
                )}
                <ModalAntd
                  visible={expanded}
                  footer={null}
                  closable={false}
                  destroyOnClose
                  focusTriggerAfterClose={false}
                  onCancel={() => {
                    setExpanded(false);
                  }}
                >
                  <iframe
                    style={{ width: '100%', height: '550px', border: 'none' }}
                    src={files?.preview}
                    title="Pdf preview"
                    allowFullScreen
                  />
                </ModalAntd>
              </Box>
            </Container>
          </>
        </Card>
        {hasAFile && (
        <Buttons>
          <ButtonN
            onClick={() => {
              handleDelete();
              setHasAFile(false);
            }}
            disabled={loading}
          >
            Excluir
          </ButtonN>
          <ButtonN
            type="primary"
            className="botao"
            onClick={() => {
              handleSocialContractRequisition();
            }}
            icon={loading && <Spin indicator={<LoadingOutlined />} />}
            loading={loading}
          >
            {!loading && 'Confirmar'}
          </ButtonN>
        </Buttons>
        )}
      </Section>
      <ModalForTransition visibility={blacklistTest} setVisibility={setBlackListTest} style={{ height: '100%' }}>
        <>
          <ModalTitle>Sentimos muito!</ModalTitle>
          <SubtitleModal>
            {typeError === 'blacklist' ? 'Sentimos muito, não será possível prosseguir' : ''}
          </SubtitleModal>
          <TextModal>{modalText()}</TextModal>
          <ButtonN
            type="primary"
            className="botao"
            onClick={() => {
              if (typeError === 'blacklist') {
                handleHome();
              } else {
                setBlackListTest(false);
                setTypeError('');
              }
            }}
          >
            Confirmar
          </ButtonN>
        </>
      </ModalForTransition>
    </>
  );
};

export default Documents;
