import styled from 'styled-components';
import femaleBlogger from '../../assets/female-blogger.png';

export const Logo = styled.img`
 max-width: 8.125rem;
 max-height: 5.625rem;
`;

export const Container = styled.div`
 display: flex;
 position: fixed;
 justify-content: center;
 width: 100vw;
 height: 100vh;

 section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
 }
`;

export const ImgContainer = styled.div`
 --url-teste: url(${({ settings }) => settings.ib_background_img || femaleBlogger});
 background: ${({ showGradient }) => (showGradient
    ? 'linear-gradient(0deg, #191a1ad9 19.09%, rgba(2, 3, 3, 0) 68.1%)'
    : 'none')}, var(--url-teste);
 background-size: cover;
 background-repeat: no-repeat;
 width: 65%;
 height: 100%;
 flex: 1;
`;

export const Content = styled.div`
 display: flex;
 background-color: var(--white);
 box-shadow: 0 0 0.125rem 0 var(--primary);

 flex-direction: column;
 align-items: center;
 padding: 2rem 10%;
 gap: 1rem;
 width: 35%;
 height: 100%;
 overflow-y: auto;

 @media (min-width: 37.51rem) {
  padding: 2rem 20%;
 }
 @media (min-width: 48.1rem) {
  margin: 0;
  width: 35%;
  padding: 2rem 4%;
 }
 @media (min-width: 62rem) {
  margin: 0;
  width: 35%;
  padding: 1.5rem 6%;
 }

 section {
  width: 100%;
  gap: 0.5rem;
 }
 a {
  font-size: 0.875rem;
  font-weight: bold;
 }

 .botao {
  margin-top: auto;
 }
`;

export const Title = styled.span`
 font-size: 1rem;
 font-weight: 700;
 text-align: center;
 margin-bottom: 1rem;
`;
export const SubTitle = styled.div`
 display: inline;
 width: 100%;
 gap: 0rem;

 header {
  color: inherit;
  font-size: 0.85rem;
  font-weight: 700;
  text-align: center;
  text-align-last: left;
 }

 span {
  font-size: 0.75rem;
  color: #6f6f6f;
  display: inline;
 }
`;
