export const formatBranch = (branch) => {
  const branchFormatter = `0000${branch}`;
  return branchFormatter.substr(branchFormatter.length - 4, 4);
};

export const formatAccount = (account) => {
  const accountToString = String(account);

  const acc = accountToString.substr(0, accountToString.length - 1);
  const digit = accountToString[accountToString.length - 1];

  return `${acc}-${digit}`;
};
