import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 35%;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const WeekDays = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
`;

export const WeekDay = styled.div`
  width: 45px;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  p {
    margin: 0;
    font-weight: 500;
  }
`;
