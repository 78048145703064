/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';

import { useDropzone } from 'react-dropzone';

import { Modal as ModalAntd, message, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf, faUpload, faFileUpload, faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import {
  TextStyled,
  Card,
  Container,
  DropButton,
  DropContainer,
  Buttons,
  MainTitle,
  Section,
  Box,
  Texts,
  Displayer,
  Overlay,
  ButtonsDisposition,
} from './styles';

import { Title } from '../../../styles';
import ButtonN from '../../../../../components/ButtonN';
import ModalForTransition from '../../../../../components/ModalForTransition';
import { useSteps } from '../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import { documentPost } from '../../../../../services/endPointRegister/PJ/documentPost';

const ContratoS = () => {
  const [expanded, setExpanded] = useState(false);
  const [files, setFiles] = useState([]);
  const [documentsData, setDocumentsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [displayWarning, setDisplayWarning] = useState('');
  const [hasAFile, setHasAFile] = useState(false);
  const token = localStorage.getItem('@qesh:token');

  const {
    nextStep, step, stepsPj, resetStep, exactStep, stepsPjEnum,
  } = useSteps();
  const history = useHistory();
  const { checkpoint, setCheckpoint } = useCheckpoint();

  const handleDelete = () => {
    setFiles();
    setFiles((file) => file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    // accept: '.pdf',
    maxFiles: 1,
    multiple: false,

    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      let receivedFile;
      // eslint-disable-next-line array-callback-return
      acceptedFiles.map((file) => {
        reader.readAsDataURL(file);
        reader.onload = () => {
          setDocumentsData(file);
        };
        receivedFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      if (receivedFile.size <= 5000000) {
        setFiles(receivedFile);
        setHasAFile(true);
      } else {
        message.error('O formato do arquivo a ser enviado deve ser PDF com no máximo 5MB', 4);
      }
    },
  });
  useEffect(() => {
  //   const isTheRightPage = localStorage.getItem('@qesh:token')
  //  && stepsPjEnum[localStorage.getItem('@qesh:step')] === 3
  //  && checkpoint.steplist;
    if (checkpoint?.addressData !== undefined /*|| isTheRightPage*/) {
      exactStep(3);
    // } else {
    //   resetStep();
    //   history.replace('/transaction');
    }
  }, []);

  useEffect(() => {
    const testType = () => {
      const nameDocument = documentsData.name;
      const searchPdf = nameDocument.includes('.pdf');
      return searchPdf;
    };
    if (documentsData.name) {
      const test = testType();
      if (!test) {
        handleDelete();
        setHasAFile(false);
        message.error('O formato do arquivo a ser enviado deve ser PDF com no máximo 5MB', 4);
      }
    }
  }, [documentsData]);

  const handleAdvance = () => {
    nextStep();
    setLoading(false);
    sessionStorage.setItem('@qesh:step', '4');
    history.push(`/register/${stepsPj[step + 1]}`);
  };
  const handleSocialContractRequisition = async () => {
    setCheckpoint({ ...checkpoint, socialContract: documentsData });
    setLoading(true);
    let error;
    try {
      const requisition = await documentPost(documentsData, token, checkpoint.pjUserId);
      error = requisition;
    // eslint-disable-next-line no-shadow
    } catch (error) {
      message.error(error.message, 5);
      setLoading(false);
    } finally {
      if (error && error.status) {
        setLoading(false);
        if (error.res === 'Refused by validator, check your attempts') {
          message.error(error.message, 6);
        } else {
          message.error(error.message, 6);
        }
      } else {
        handleAdvance();
      }
    }
  };

  return (
    <>
      <ModalForTransition
        centered
        title={[
          <h3 key={1} style={{ marginBottom: '1rem', width: '100%' }}>
            {' '}
            Agora vamos finalizar o cadastro do{' '}
            <span style={{ display: 'block', color: 'var(--primary)' }}>usuário principal</span>
          </h3>,
        ]}
        bodyText="Cada um dos sócios terá seu cadastro próprio e deverá estar presente no Contrato Social"
        visibility={displayWarning}
        setVisibility={() => setDisplayWarning()}
        style={{ height: '60%' }}
      >
        <p style={{ height: '50%', marginBottom: '1rem' }}>
          Cada um dos sócios terá seu cadastro próprio e deverá estar presente no Contrato Social
        </p>
        <ButtonsDisposition>
          <ButtonN
            type="primary"
            className="botao"
            onClick={() => {
              // nextStep()
            }}
            style={{ height: '70%' }}
            loading={loading}
            icon={loading && <Spin indicator={<LoadingOutlined />} />}
          >
            {!loading && 'Confirmar'}
          </ButtonN>
        </ButtonsDisposition>
      </ModalForTransition>
      <Section>
        <Texts>
          {!checkpoint?.cnpjData?.associacaoSFL ? (
            !hasAFile ? (
              <MainTitle>Agora é hora de enviar o Contrato Social da empresa</MainTitle>
            ) : (
              <MainTitle>Confira se as páginas estão legíveis</MainTitle>
            )
          ) : hasAFile ? (
            <MainTitle>Agora é hora de enviar o Estatuto Social da empresa</MainTitle>
          ) : (
            <MainTitle>Confira se as páginas estão legíveis</MainTitle>
          )}
        </Texts>
        <Card>
          <>
            <Container>
              <Box>
                {!hasAFile && <h2>Envie o PDF do documento escaneado</h2>}
                {!hasAFile && (
                <DropContainer {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <div>
                    <DropButton>
                      <FontAwesomeIcon icon={faFileUpload} size="5x" />
                    </DropButton>
                    <h2>Fazer o upload</h2>
                  </div>
                </DropContainer>
                )}
                {hasAFile && (
                <Displayer onClick={() => setExpanded(true)}>
                  <iframe src={files?.preview} title="Pdf preview" allowFullScreen />
                  <Overlay>
                    <span>clique aqui para ter uma visualização maior do arquivo</span>
                  </Overlay>
                </Displayer>
                )}
                <ModalAntd
                  visible={expanded}
                  footer={null}
                  closable={false}
                  destroyOnClose
                  focusTriggerAfterClose={false}
                  onCancel={() => {
                    setExpanded(false);
                  }}
                >
                  <iframe
                    style={{ width: '100%', height: '30rem', border: 'none' }}
                    src={files?.preview}
                    title="Pdf preview"
                    allowFullScreen
                  />
                </ModalAntd>
              </Box>
            </Container>
          </>
        </Card>
        {hasAFile && (
        <Buttons>
          <ButtonN
            onClick={() => {
              handleDelete();
              setHasAFile(false);
            }}
            disabled={loading}
          >
            Excluir
          </ButtonN>
          <ButtonN
            type="primary"
            className="botao"
            onClick={() => {
              handleSocialContractRequisition();
            }}
            icon={loading && <Spin indicator={<LoadingOutlined />} />}
            loading={loading}
          >
            {!loading && 'Confirmar'}
          </ButtonN>
        </Buttons>
        )}
      </Section>
      {!checkpoint?.cnpjData.associacaoSFL ? (
        <Title>Confira algumas dicas para o upload do Contrato Social</Title>
      ) : (
        <Title>Confira algumas dicas para o upload do Estatuto Social</Title>
      )}
      <TextStyled>
        {' '}
        <FontAwesomeIcon icon={faFilePdf} />
        Se atente ao formato do arquivo, ele deve estar em PDF.
      </TextStyled>
      <TextStyled>
        {' '}
        <FontAwesomeIcon icon={faUpload} />
        <p>
          O tamanho máximo aceito do arquivo é de
          <strong> 5MB</strong>.
        </p>
      </TextStyled>
      <TextStyled>
        <FontAwesomeIcon icon={faExclamationCircle} color="red" />
        Todas as informações presentes no documento serão checadas e, caso haja incoerências, sua solicitação de
        conta será negada.
      </TextStyled>
    </>
  );
};

export default ContratoS;
