import api from '../../api';

/**
 * request type patch  to register new partner
 * @property {{}} obj - obj is the data of the partner to be registered
 * @property {string} token - token bearer login account user PF
 * @property {string} user - O user is o id user the account PJ
 * @property {string} account - account_id to account the user PF
 * @returns {{status: boolean, res: {response or error}}}
 */
export const addPartner = async (obj, token, userPj, accountPf) => {
  const headers = {
    user: userPj,
    account: accountPf,
  };
  const finalObject = {
    partners: {
      document: obj.document,
      email: obj.email,
      name: obj.name,
      qualification: obj.qualification,
    },
  };
  return api
    .patch('onboarding/companies/partners/ADD', finalObject, { headers })
    .then((response) => {
      const responseData = response.data;
      console.log('addPartner response: ', responseData);
      return response.data;
    })
    .catch((error) => {
      console.log('addPartner error: ', error.response);
      return error.response.data || error;
    });
};

export const removePartner = async (obj, _selectedPartnerRemove , userPj, accountPf) => {

  const finalObject = {
    partners: {
      document: obj.document,
      email: obj.email,
      name: obj.name,
      qualification: obj.qualification,
    },
  };
  return api
    .patch('onboarding/companies/partners/REMOVE', finalObject, { headers: {
      user: userPj,
      account: accountPf,
    } })
    .then((response) => {
      const responseData = response.data;
      console.log('removePartner response: ', responseData);
      return response.data;
    })
    .catch((error) => {
      console.log('removePartner error: ', error.response);
      return error.response.data || error;
    });
};
