/* eslint-disable consistent-return */
import api from '../api';

export const handleAddress = async (obj, cpfCnpj, pass, clientName, pjUserId) => {
  const tokenReq = {
    document: cpfCnpj,
    password: pass,
    client_name: clientName,
    origin: 'ib',
  };

  let token = localStorage.getItem('@qesh:token');
  let user;
  let headers = {};
  if (!sessionStorage.getItem('@qesh:steplist')) {
    if (!token) {
      return api
        .post('login', tokenReq)
        .then((response) => {
          token = response.data.token;
          user = response.data.user;
          localStorage.setItem('@qesh:token', response.data.token);
          localStorage.setItem('@qesh:userOnboarding', JSON.stringify(user));
          headers = { authorization: `bearer ${token}` };
        })
        .catch((error) => error.response.data)
        .then(async () => api
          .post('onboarding/address', obj, { headers })
          .then((a) => false)
          .catch((error) => true));
    }
    api.post('onboarding/address', obj, { headers: { authorization: `bearer ${token}` } })
      .then((a) => false)
      .catch((error) => {
        console.log('HandleAddress 1: ', error.response);
        return true;
      });
  } else {
    return api
      .post('onboarding/address', obj, {
        headers: { user: pjUserId },
      })
      .then((a) => false)
      .catch((error) => {
        console.log('handleAddress 2: ', error.response);
        return true;
      });
  }
};
