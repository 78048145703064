import React from 'react';
import { FaCheck, FaTimes, FaExclamationCircle } from 'react-icons/fa';

import {
  Container, IconArea, LabelArea, ToastContainer,
} from './styles';

export const Notification = ({
  label,
  type = 'error',
  visible = true,
  onClick,
}) => {
  const HashmapContainer = {
    success: (
      <Container color={type} onClick={onClick}>
        <IconArea>
          <FaCheck />
        </IconArea>
        <LabelArea>{label}</LabelArea>
      </Container>
    ),
    error: (
      <Container color={type} onClick={onClick}>
        <IconArea>
          <FaTimes />
        </IconArea>
        <LabelArea>{label}</LabelArea>
      </Container>
    ),
    warning: (
      <Container color={type} onClick={onClick}>
        <IconArea>
          <FaExclamationCircle />
        </IconArea>
        <LabelArea>{label}</LabelArea>
      </Container>
    ),
  };

  return visible ? (
    <Container color={type} onClick={onClick}>
      <IconArea>
        <FaTimes />
      </IconArea>
      <LabelArea>{label}</LabelArea>
    </Container>
  ) : null;
};
