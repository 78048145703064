/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext, useContext, useState, useEffect, React,
} from 'react';
// import { listOfNationality } from '../utilitys/listOfNationality';
import { HandleRefreshData } from '../services/refreshData';
import api from '../services/api';

const RefreshContext = createContext({});

// eslint-disable-next-line react/prop-types
export function RefreshProviderPJ({ children }) {
  const [userPj, setUserPj] = useState({});
  const [accountId, setAccountId] = useState('');
  const [step, setStep] = useState('basicData');
  const [tokenPj, setTokenPj] = useState(null);
  const [photoAttempts, setPhotoAttempts] = useState(5);
  const [basicData, setBasicData] = useState({
    blocked: false,
    createdAt: '',
    document: '',
    email: '',
    id: '',
    migration: false,
    name: '',
    pendingApproval: true,
    phone: '',
    pre: false,
    socialName: null,
  });
  const [additional, setAdditional] = useState({
    additionalImage: '',
    cnae: '',
    declarationNumber: '',
    detail: '',
    id: '',
    lastYearChange: false,
    legalNature: '',
    nonProfit: '',
    numberOfAffiliates: '',
    numberOfEmployees: '',
    numberOfPartners: '',
    openingDate: '',
    pfUserCreator: '',
    revenues: '',
    shareCapital: '',
    stateRegistration: '',
    statesDocuments: '',
    tradingName: '',
  });
  const [partner, setPartner] = useState({
    birthDate: '',
    document: '',
    motherName: '',
    name: '',
  });
  const [address, setAddress] = useState({
    city: '',
    complement: '',
    country: '',
    neighborhood: '',
    number: '',
    state: '',
    street: '',
    zipCode: '',
  });
  const [documents, setDocuments] = useState({
    category: '',
    createdAt: '',
    id: '',
    name: '',
    originalName: '',
    updatedAt: '',
    urlDoc: '',
    userId: '',
  });
  const [refreshApi, setRefreshApi] = useState(null);

  // const formatFullName = (name) => {
  //   const nameArray = name.split(' ');
  //   const newNameArray = nameArray.map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
  //   return newNameArray.join(' ');
  // };

  const resetRefreshPj = () => {
    setBasicData({
      blocked: false,
      createdAt: '',
      document: '',
      email: '',
      id: '',
      migration: false,
      name: '',
      pendingApproval: true,
      phone: '',
      pre: false,
      socialName: null,
    });
    setAdditional({
      additionalImage: '',
      cnae: '',
      declarationNumber: '',
      detail: '',
      id: '',
      lastYearChange: false,
      legalNature: '',
      nonProfit: '',
      numberOfAffiliates: '',
      numberOfEmployees: '',
      numberOfPartners: '',
      openingDate: '',
      pfUserCreator: '',
      revenues: '',
      shareCapital: '',
      stateRegistration: '',
      statesDocuments: '',
      tradingName: '',
    });
    setPartner({
      birthDate: '',
      document: '',
      motherName: '',
      name: '',
    });
    setAddress({
      city: '',
      complement: '',
      country: '',
      neighborhood: '',
      number: '',
      state: '',
      street: '',
      zipCode: '',
    });
    setDocuments({
      category: '',
      createdAt: '',
      id: '',
      name: '',
      originalName: '',
      updatedAt: '',
      urlDoc: '',
      userId: '',
    });
    setAccountId('');
    setStep('basicData');
    setTokenPj(null);
    setUserPj({});
  };

  const getBasicData = () => {
    const {
      baas: {
        user: {
          blocked,
          created_at: createdAt,
          document,
          email,
          id,
          migration,
          pending_approval: pendingApproval,
          phone,
          pre,
          social_name: socialName,
        },
      },
      name,
    } = userPj;
    return {
      blocked,
      createdAt,
      document,
      email,
      id,
      migration,
      name,
      pendingApproval,
      phone,
      pre,
      socialName,
    };
  };
  const getAdditional = () => {
    const {
      baas: {
        additional: {
          additional_image: additionalImage,
          cnae,
          declaration_number: declarationNumber,
          detail,
          id,
          last_year_change: lastYearChange,
          legal_nature: legalNature,
          non_profit: nonProfit,
          number_of_affiliates: numberOfAffiliates,
          number_of_employees: numberOfEmployees,
          number_of_partners: numberOfPartners,
          opening_date: openingDate,
          pf_user_creator: pfUserCreator,
          revenues,
          share_capital: shareCapital,
          state_registration: stateRegistration,
          states_documents: statesDocuments,
          trading_name: tradingName,
        },
      },
    } = userPj;
    let formatedDate = openingDate;

    if (openingDate) {
      const [year, month, day] = openingDate.split('T')[0].split('-');
      formatedDate = `${day}/${month}/${year}`;
    }

    return {
      additionalImage,
      cnae,
      declarationNumber,
      detail,
      id,
      lastYearChange,
      legalNature,
      nonProfit,
      numberOfAffiliates,
      numberOfEmployees,
      numberOfPartners,
      openingDate: formatedDate,
      pfUserCreator,
      revenues,
      shareCapital,
      stateRegistration,
      statesDocuments,
      tradingName,
    };
  };
  const getPartners = () => {
    const {
      baas: {
        additional: {
          partners,
        },
      },
    } = userPj;

    return {
      partners,
    };
  };
  const getAddress = () => {
    const {
      baas: {
        address: {
          id,
          city,
          complement,
          country,
          neighborhood,
          number,
          state,
          street,
          zip_code: zipCode,
        },
      },
    } = userPj;

    return {
      id,
      city,
      complement,
      country,
      neighborhood,
      number,
      state,
      street,
      zipCode,
    };
  };
  const getDocuments = () => {
    const {
      baas: {
        documents: {
          category,
          created_at: createdAt,
          id,
          name,
          original_name: originalName,
          updated_at: updatedAt,
          urlDoc,
          user_id: userId,
        },
      },
    } = userPj;

    return {
      category,
      createdAt,
      id,
      name,
      originalName,
      updatedAt,
      urlDoc,
      userId,
    };
  };

  useEffect(() => {
    if (userPj.baas) {
      setBasicData(getBasicData());
      setAdditional(getAdditional());
      setPartner(getPartners());
      setAddress(getAddress());
      setDocuments(getDocuments());
      const {
        baas: {
          additional: { id: additionalId },
          address: { id: addressId },
          accounts,
        },
        id: userId,
      } = userPj;
      setAccountId(accounts[0].id);
      setRefreshApi(new HandleRefreshData(tokenPj, userId, additionalId, addressId, accountId));
      if (userPj.status === 'RegisterPartners') {
        setStep('partners');
      }
    }
    if (tokenPj) {
      api.defaults.headers.authorization = `bearer ${tokenPj}`;
    }
  }, [userPj, tokenPj]);

  return (
    <RefreshContext.Provider
      value={{
        userPj,
        setUserPj,
        accountId,
        setAccountId,
        tokenPj,
        setTokenPj,
        refreshApi,
        basicData,
        setBasicData,
        additional,
        setAdditional,
        partner,
        setPartner,
        getPartners,
        address,
        setAddress,
        documents,
        setDocuments,
        step,
        setStep,
        resetRefreshPj,
        photoAttempts,
        setPhotoAttempts,
      }}
    >
      {children}
    </RefreshContext.Provider>
  );
}

export function useRefreshPj() {
  const context = useContext(RefreshContext);
  return context;
}
