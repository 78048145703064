import React from 'react';
import { useShop } from '../../hooks/shop';
import { specialCharacters } from '../../utilitys/specialCharacters';
import HelpModal from '../Help';
import {
  EditibleDiv, DisplayUrl, InputWrapper, SolidText, TextHolder, Wrapper,
} from './styles';

const InputUrl = ({
  isDisabled = false, width = '70%', maxLength = 30, isDisplay = false,
}) => {
  const { setShopInfo, shopInfo } = useShop();
  const [innerHtml, setInnerHtml] = React.useState('sualojaaqui');
  const [isOnFocus, setIsOnFocus] = React.useState(false);
  const editibleDiv = React.useRef(null);

  function handleDivChange(event) {
    setShopInfo((prevValue) => ({ ...prevValue, url: event.target.innerHTML.replace(/[^a-zA-Z0-9]/g, '') }));
  }

  function handleDivFocus() {
    if (!shopInfo.url) {
      return isOnFocus ? setInnerHtml('sualojaaqui') : setInnerHtml('');
    }

    return null;
  }

  const handleDivFocusOn = () => {
    editibleDiv?.current?.focus();
  };

  function handleKeyDown(event) {
    const currentTextLength = event.target.outerText.length;

    if (event.key === ' ' || specialCharacters.includes(event.key)) {
      event.preventDefault();
      return false;
    }
    if (currentTextLength === maxLength && event.keyCode !== 8) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  React.useEffect(() => {
    if (shopInfo?.url) {
      setInnerHtml(shopInfo.url);
    }
  }, []);

  return (
    <Wrapper width={width}>
      <TextHolder>
        Site da loja
        <p>*</p>
        {!isDisabled && <HelpModal type="url" />}
      </TextHolder>
      <InputWrapper>
        <SolidText onClick={handleDivFocusOn}>https://</SolidText>

        {isDisplay ? (
          <DisplayUrl>{shopInfo?.url}</DisplayUrl>
        ) : (
          <EditibleDiv
            ref={editibleDiv}
            contentEditable
            suppressContentEditableWarning
            dangerouslySetInnerHTML={{ __html: innerHtml }}
            onInput={handleDivChange}
            disabled={isDisabled}
            readOnly={isDisabled}
            spellCheck={false}
            color={isOnFocus}
            onFocus={() => {
              setIsOnFocus(true);
              handleDivFocus();
            }}
            onBlur={() => {
              setIsOnFocus(false);
              handleDivFocus();
            }}
            onKeyDown={handleKeyDown}
          />
        )}

        <SolidText onClick={handleDivFocusOn}>.lojascdl.com.br/</SolidText>
      </InputWrapper>
    </Wrapper>
  );
};

export default InputUrl;
