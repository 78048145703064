import React, { useCallback, useState, useRef } from 'react';

import { Form } from '@unform/web';
import {
  format, parseISO, addDays,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import formErrors from '../../services/formErrors';

import { Content } from './styles';
import { Row, Col } from '../../styles/components';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import Loading from '../../components/Loading';
import Password from '../../components/Password';

import { useAuth } from '../../hooks/auth';

import { HandleFormatMoney } from '../../services/utils';

import getErrors from '../../services/errors';

import api from '../../services/api';

function Payment() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const formRef = useRef(null);
  const digitableLineFormRef = useRef(null);
  const [amount, setAmount] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [payment, setPayment] = useState(false);
  const [formData, setFormData] = useState({});

  const handleValidate = useCallback(async (form) => {
    try {
      digitableLineFormRef.current.setErrors({});

      const schema = Yup.object().shape({
        barcode: Yup.string().min(44, t('a linha digitavel deve ter no minimo 44 numeros')),
      });

      await schema.validate(form, {
        abortEarly: false,
      });

      setLoading(true);
      const { data } = await api.get(`payments/validate?digitable_line=${form.barcode}`);
      setLoading(false);
      if (data.type === 'boleto') {
        setAmount(data.TotalAmountToCharge || 0);
        if (data.situacaoTituloPagamento !== '12') {
          setError(t('nao e possivel realizar o pagamento. titulo invalido ou ja pago'));
          return;
        }
      }

      if (data.type === 'tributo') {
        setAmount(data.pagamento.valorConta);

        if (data.codigoErro !== '000') {
          setError(t('nao e possivel realizar o pagamento. titulo invalido ou ja pago'));
          return;
        }
      }

      setPayment(data);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        digitableLineFormRef.current.setErrors(errors);
        setLoading(false);
        return;
      }

      setLoading(false);
      setError(getErrors(err));
    }
  }, [t]);

  const handlePaymentPassword = useCallback(async (form) => {
    try {
      if (payment.type === 'tributo') {
        formRef.current.setErrors({});

        if (!amount || amount <= 0) {
          formRef.current.setErrors({
            totalValueTributo: t('o valor e obrigatorio'),
          });
          return;
        }

        const schema = Yup.object().shape({
          vencimento: Yup.string().required(t('vencimento e obrigatorio')),
        });

        await schema.validate(form, {
          abortEarly: false,
        });
      }

      if (payment.type === 'boleto') {
        if (!amount || amount <= 0) {
          formRef.current.setErrors({
            totalValue: t('o valor e obrigatorio'),
          });

          return;
        }
      }

      setFormData(form);
      setShowPassword(true);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        formRef.current.setErrors(errors);
      }
    }
  }, [payment.type, amount, t]);

  const handlePayment = useCallback(async (password) => {
    // localeParseFloat(formData.totalValue),
    try {
      setLoading(true);

      if (payment.type === 'boleto') {
        await api.post('payments', {
          digitable_line: formData.boletoPagamento.numeroLinhaDigitavel,
          due_at: formData.vencimento,
          amount,
          password,
        });
      } else {
        await api.post('payments', {
          digitable_line: formData.pagamento.linhaDigitavel,
          due_at: formData.vencimento,
          amount,
          password,
        });
      }
      setLoading(false);
      setPayment(false);
      setFormData({});
      setAmount(0);
      setSuccess(t('pagamento efetuado com sucesso'));
    } catch (err) {
      setLoading(false);
      setError(getErrors(err));
    }
  }, [formData, amount, payment.type, t]);

  const changeValue = useCallback((event, maskedvalue, floatvalue) => {
    setAmount(floatvalue);
  }, []);

  return (
    <>
      <Content>
        <Notification
          type="error"
          visible={!!error}
          title={t('erro no pagamento')}
          hidden={() => setError('')}
        >
          {error}
        </Notification>
        <Notification
          visible={!!success}
          title={t('pagamento efetuado')}
          hidden={() => setSuccess('')}
        >
          {success}
        </Notification>
        <Password
          visible={showPassword}
          hidden={() => setShowPassword(false)}
          afterPassword={handlePayment}
        />
        <Loading hasLoading={loading} />
        <h1>{t('pagamentos')}</h1>

        {!payment ? (
          <>
            <h2>{t('digite o codigo de barras')}</h2>
            <br />
            <Form onSubmit={handleValidate} ref={digitableLineFormRef}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14} className="bottom">
                  <Input id="barcode" name="barcode" label={t('linha digitavel')} type="text" />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col md={24}>
                  <Button right={(false)} type="submit">
                    <span>{t('continuar')}</span>
                  </Button>
                </Col>
              </Row>

            </Form>
          </>
        ) : (
          <>
            <h2>{t('confira os dados do pagamento')}</h2>
            {payment.type === 'boleto' ? (
              <div>
                <Form
                  onSubmit={handlePaymentPassword}
                  initialData={{
                    ...payment,
                    vencimento: payment.boletoPagamento.dataVencimentoTitulo,
                    due_at: format(parseISO(payment.boletoPagamento.dataVencimentoTitulo), 'dd/MM/yyyy'),
                    value: HandleFormatMoney(payment.boletoPagamento.valorTitulo || 0),
                    fine: HandleFormatMoney(payment.CalculatedFineValue || 0),
                    interest: HandleFormatMoney(payment.CalculatedInterestAmount || 0),
                    totalValue: HandleFormatMoney(payment.TotalAmountToCharge || 0),
                    pagador: payment.boletoPagamento?.pessoaPagador?.nome || user.name,
                  }}
                >
                  <Row gutter={16}>
                    {payment && payment.boletoPagamento
                      && payment.boletoPagamento.sacadorAvalista
                      && payment.boletoPagamento.sacadorAvalista.nome && (
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} className="bottom">
                        <Input
                          disabled
                          name="boletoPagamento.sacadorAvalista.nome"
                          id="boletoPagamento.sacadorAvalista.nome"
                          label={t('beneficiario')}
                        />
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="bottom">
                      <Input
                        disabled
                        name="boletoPagamento.pessoaBeneficiarioOriginal.nome"
                        id="boletoPagamento.pessoaBeneficiarioOriginal.nome"
                        label={t('beneficiario final')}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                      <Input
                        disabled
                        name="pagador"
                        id="pagador"
                        label={t('cobranca para')}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
                      <Input
                        disabled
                        name="due_at"
                        id="due_at"
                        label={t('vence em')}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
                      <Input
                        disabled
                        name="value"
                        id="value"
                        label={t('valor do documento')}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                      <Input
                        disabled
                        name="boletoPagamento.numeroLinhaDigitavel"
                        id="boletoPagamento.numeroLinhaDigitavel"
                        label={t('linha digitavel')}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
                      <Input
                        disabled
                        name="fine"
                        id="fine"
                        label={t('multa')}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
                      <Input
                        disabled
                        name="interest"
                        id="interest"
                        label={t('juros')}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
                      <Input
                        mask="currency"
                        name="totalValue"
                        id="totalValue"
                        value={amount}
                        onChangeEvent={changeValue}
                        label={t('valor final')}
                      />
                    </Col>
                  </Row>

                  <Row style={{ display: 'none' }}>
                    <Input
                      style={{ display: 'none' }}
                      disabled
                      name="vencimento"
                      id="vencimento"
                      label={t('vencimento')}
                    />
                  </Row>

                  <Row style={{ marginBottom: '40px' }}>
                    <Button type="submit">
                      <span>{t('pagar')}</span>
                    </Button>
                  </Row>
                </Form>
              </div>
            ) : (
              <div>
                <Form
                  ref={formRef}
                  onSubmit={handlePaymentPassword}
                  initialData={{
                    ...payment,
                    vencimento: payment.vencimento ? (format(addDays(parseISO(payment.vencimento), 1), 'yyyy-MM-dd'))
                      : null,
                    pagador: payment.boletoPagamento?.pessoaPagador?.nome || user.name,
                  }}
                >
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="bottom">
                      <Input
                        disabled
                        name="pagamento.nomeCedente"
                        id="pagamento.nomeCedente"
                        label={t('beneficiario')}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                      <Input
                        disabled
                        name="pagador"
                        id="pagador"
                        label={t('cobranca para')}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
                      <Input
                        type="date"
                        name="vencimento"
                        id="vencimento"
                        label={t('vence em')}
                        defaultValue={null}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                      <Input
                        disabled
                        name="pagamento.linhaDigitavel"
                        id="pagamento.linhaDigitavel"
                        label={t('linha digitavel')}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
                      <Input
                        mask="currency"
                        name="totalValueTributo"
                        id="totalValueTributo"
                        value={amount}
                        onChangeEvent={changeValue}
                        label={t('valor final')}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: '40px' }}>
                    <Button type="submit">
                      <span>{t('pagar')}</span>
                    </Button>
                  </Row>
                </Form>
              </div>
            )}
          </>
        )}

      </Content>
    </>
  );
}

export default Payment;
