import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  /* min-width: 220px; */
  height: 50px;

  i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  label {
    font-size: 16px;
    display: inline;
    position: absolute;
    /* min-width: 250px; */
    top: -12px;
    left: 15px;
    background: #ffffff;
    color: ${(props) => (props.error ? '#FA4E39' : '000000')};

    ${(props) => props.focus && css`
      color:  #2E6FC7;
    `}

    letter-spacing: 0.67px;

    ${(props) => props.disabled && css`
      border-radius: 4px;
      padding: 0 4px;
      background: #fafafa;
    `}

    &.select {
      z-index: 3;
    }
  }

  input {
    flex: 1;
    height: 87px; 
    border-radius: 4px;
    font-size: 18px;
    color: #000000DE;
    letter-spacing: 0.28px;
    padding: 6px 15px;
    border: 1px solid ${(props) => (props.error ? '#FA4E39' : 'rgba(0,0,0, 0.3)')};

    ${(props) => props.error && css`
      border: 2px solid #FA4E39;
    `}

    ${(props) => props.focus && css`
      border: 2px solid #2E6FC7;
    `}

    padding-right: ${(props) => (props.eye ? '65px' : '15px')};

    &[type="date"] {
      flex: 1;
      width: 100%;
      padding: 5px !important;
      height: 50px;
      background: transparent;
    }
  }

  .react-select__control {
    z-index: 2;
  }

  .react-select__value-container {
    font-size: 18px;
    color: #000000DE;
    letter-spacing: 0.28px;
  }

  .react-select__input {
    input {
      height: 37px !important;
      
    }
  }

  div.error-box {
    display: block;
    font-size: 14px;
    color: #FA4E39;
    padding-left: 4px;
  }
`;
