import React from 'react';
import ShopService from '../services/shopService';
import { onlyNumber } from '../utilitys/handleChangeValidator';
import { shopErrors } from '../utilitys/shopErrorsTranslate';

const ShopContext = React.createContext({});

function ShopProvider({ children }) {
  const [shopInfo, setShopInfo] = React.useState({
    userName: '',
    document: '',
    client_id: '',
    password: '',
    name: '',
    email: '',
    phone: '',
    minimun_purchase: '',
    start_store_operation: '',
    end_store_operation: '',
    days_store_operation: [],
    show_address: false,
    show_email: false,
    show_phone: false,
    is_closed: false,
    primary_color: '',
    secondary_color: '',
    url: '',
    address: {
      street: '',
      number: '',
      neighborhood: '',
      complementary_address: null,
      city: '',
      state: '',
      zip_code: '',
      address_owner: 'SHOP',
      is_active: false,
    },
    img_web: '',
    img_mobile: '',
  });

  const [usageTerms, setUsageTerms] = React.useState();
  const [currentStep, setCurrentStep] = React.useState(1);
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [isOpenAllDay, setIsOpenAllDay] = React.useState(false);
  const [isShopCreated, setIsShopCreated] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [hasStore, setHasStore] = React.useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = React.useState(false);

  const [shopLogo, setShopLogo] = React.useState({
    img_web: {
      file: '',
      url: '',
    },
    img_mobile: {
      file: '',
      url: '',
    },
  });

  const handleChangeHashmap = {
    minimun_purchase: (value) => {
      const newValue = value.replace(/\D/g, '');
      const cents = newValue.slice(-2);
      const dollars = newValue.slice(0, -2);
      const newValueFormated = `${dollars}.${cents}`;

      return setShopInfo((prevValue) => ({ ...prevValue, minimun_purchase: newValueFormated }));
    },
    url: (value) => {
      const newValue = value.replace(/[^a-z]/g, '');

      return setShopInfo((prevValue) => ({ ...prevValue, url: newValue }));
    },
    name: (value) => {
      const newValue = value.replace(/[^A-Za-z\s]/g, '');

      return setShopInfo((prevValue) => ({ ...prevValue, name: newValue }));
    },
    phone: (value) => setShopInfo((prevValue) => ({
      ...prevValue,
      phone: value.replace(/\D/g, ''),
    })),
    number: (value) => setShopInfo((prevValue) => ({
      ...prevValue,
      number: value.replace(/\D/g, ''),
    })),
    zip_code: (value) => setShopInfo((prevValue) => ({
      ...prevValue,
      zip_code: value.replace(/\D/g, ''),
    })),
  };

  async function handleGetShopIcons(images) {
    const response = await ShopService.sendIcons(images);
    if (!response) {
      setShopInfo((prevState) => ({ ...prevState, img_mobile: '', img_web: '' }));
      setShopLogo((prevValue) => ({ ...prevValue, img_mobile: { file: '', url: '' }, img_web: { file: '', url: '' } }));
      return;
    }
    for (const [key, value] of Object.entries(response)) {
      setShopInfo((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;

    if (handleChangeHashmap[name]) {
      return handleChangeHashmap[name](value);
    }

    return setShopInfo((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }

  function handleAddressChange(e) {
    const { name, value } = e.target;

    if (onlyNumber.includes(name)) {
      setShopInfo((prevValue) => ({
        ...prevValue,
        address: {
          ...prevValue.address,
          [name]: value.replace(/\D/g, ''),
        },
      }));
      return;
    }

    if (name === 'street') {
      setShopInfo((prevValue) => ({
        ...prevValue,
        address: {
          ...prevValue.address,
          [name]: value.replace(/[^\w\s]/g, ''),
        },
      }));
      return;
    }

    setShopInfo((prevValue) => ({
      ...prevValue,
      address: {
        ...prevValue.address,
        [name]: value,
      },
    }));
  }

  function handleBooleanCheckBoxChange(key) {
    setShopInfo((prevValue) => ({
      ...prevValue,
      [key]: !prevValue[key],
    }));
  }

  function handleTermsCheckboxChange() {
    setHasAcceptedTerms(!hasAcceptedTerms);
  }

  function handleCheckboxChange(day, isChecked) {
    if (isChecked) {
      setShopInfo((prevValue) => ({
        ...prevValue,
        days_store_operation: [...prevValue?.days_store_operation, day],
      }));
      return;
    }

    const newWorkDays = shopInfo?.days_store_operation?.filter((workDay) => workDay !== day);
    setShopInfo((prevValue) => ({ ...prevValue, days_store_operation: newWorkDays }));
  }

  async function handleCreateShop() {
    setIsLoading(true);
    const tempObj = { ...shopInfo };

    if (tempObj.address.complementary_address === '') {
      tempObj.address.complementary_address = null;
    }

    const newMinimunPurchase = Number(tempObj.minimun_purchase.replace('.', ''));

    tempObj.minimun_purchase = newMinimunPurchase < 10
      ? Number(tempObj.minimun_purchase.replace('.', ''))
      : Number(tempObj.minimun_purchase);

    const newUrl = `https://${tempObj.url}.lojascdl.com.br/`;

    tempObj.url = newUrl;

    const response = await ShopService.registerShop(tempObj);

    if (response.error) {
      setIsLoading(false);
      setIsShopCreated(false);
      if (shopErrors[response.message]) {
        setErrorMessage(shopErrors[response.message]);
        return;
      }
      setErrorMessage(response.message);
      return;
    }

    setIsShopCreated(true);
    setIsLoading(false);
  }

  React.useEffect(() => {
    if (shopLogo.img_web.file && shopLogo.img_mobile.file) {
      const formData = new FormData();
      formData.append('web', shopLogo.img_web.file);
      formData.append('mobile', shopLogo.img_mobile.file);
      handleGetShopIcons(formData);
    }
  }, [shopLogo?.img_web, shopLogo?.img_mobile]);

  return (
    <ShopContext.Provider value={{
      shopInfo,
      setShopInfo,
      handleChange,
      handleAddressChange,
      handleBooleanCheckBoxChange,
      handleCheckboxChange,
      shopLogo,
      setShopLogo,
      currentStep,
      setCurrentStep,
      setUsageTerms,
      usageTerms,
      isOpenAllDay,
      setIsOpenAllDay,
      passwordConfirm,
      setPasswordConfirm,
      handleGetShopIcons,
      isShopCreated,
      isLoading,
      handleCreateShop,
      errorMessage,
      setErrorMessage,
      setHasStore,
      hasStore,
      handleTermsCheckboxChange,
      hasAcceptedTerms,
    }}
    >
      {children}
    </ShopContext.Provider>
  );
}

function useShop() {
  const context = React.useContext(ShopContext);
  return context;
}

export { ShopProvider, ShopContext, useShop };
