/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, {
  useState, useRef, useCallback,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { FormContainer } from './styles';
import { Row, Col } from '../../styles/components';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Notification from '../../components/Notification';

import formErrors from '../../services/formErrors';
import { useAuth } from '../../hooks/auth';

import api from '../../services/api';
import getErrors from '../../services/errors';

import { useSettings } from '../../hooks/settings';

const ResetPassword = () => {
  const formRef = useRef();
  const [error, setError] = useState('');
  const [codeConfirmation, setCodeConfirmation] = useState(false);
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const { settings } = useSettings();
  const history = useHistory();
  const { user, account } = useAuth();
  const { t } = useTranslation();

  async function handleForm(data) {
    formRef.current.setErrors({});
    setError('');
    try {
      const schema = Yup.object().shape({
        code: Yup.string().required(t('o codigo e obrigatorio')),
        newpassword: Yup.string().matches(/\d{4}/, t('a senha e obrigatoria e deve conter 4 numeros'))
          .typeError(t('a senha deve conter apenas numeros e quatro digitos')),
        confnewpassword: Yup.string().oneOf([Yup.ref('newpassword'), null], t('as senhas devem ser identicas')),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);
      const { data: response } = await api.post('/forgot-4digit/',
        {
          token: data.code,
          newPassword: data.newpassword,
        }, {
          headers: {
            account: account.id,
          },
        });
      setSuccess(response.message);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        formRef.current.setErrors(errors);
        return;
      }
      setError(getErrors(err));
    }
  }

  const sendSmsToUser = useCallback(async () => {
    if (user && user.baas) {
      try {
        await api.post('/forgot-4digit/send-sms', {}, {
          headers: {
            account: account.id,
          },
        });
        setCodeConfirmation(true);
      } catch (err) {
        setError(getErrors(err));
        console.log(err);
      }
    }
  }, [account.id, user]);

  return settings && settings.name ? (
    <>
      <Notification
        type="error"
        visible={!!error}
        title={t('erro ao resetar senha')}
        hidden={() => setError('')}
      >
        {error}
      </Notification>
      <Notification
        visible={!!success}
        title={t('operacao concluida')}
        hidden={() => { setSuccess(''); history.replace('/'); }}
      >
        {success}
      </Notification>
      <FormContainer>
        <h1>
          {t('resete a sua senha de quatro digitos')}
        </h1>
        {!codeConfirmation
          ? (
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <Button center type="button" onClick={sendSmsToUser} style={{ marginTop: '32px', maxWidth: '400px' }}>
                  {loading ? (
                    <span><LoadingOutlined /></span>) : (
                      <span>{t('enviar sms')}</span>)}
                </Button>
              </Col>
            </Row>
          )
          : (
            <>
              <h2>{t('um codigo foi enviado via sms para o celular...')}</h2>
              <Form ref={formRef} onSubmit={handleForm}>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                    <Input
                      type="text"
                      name="code"
                      id="code"
                      label={t('insira o codigo enviado por sms')}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} />
                </Row>
                <Row style={{ marginBottom: '16px' }} gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                    <Input
                      maxLength="4"
                      type="password"
                      name="newpassword"
                      id="newpassword"
                      label={t('insira sua nova senha')}
                      eye
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} />
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                    <Input
                      maxLength="4"
                      type="password"
                      name="confnewpassword"
                      id="confnewpassword"
                      label={t('confirme a nova senha')}
                      eye
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} />
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                    <Button center right={false} type="submit">
                      {loading ? (
                        <span><LoadingOutlined /></span>
                      ) : (
                        <span>{t('resetar senha')}</span>
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
      </FormContainer>
    </>
  ) : (<></>);
};

export default ResetPassword;
