import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Content, SupportItemContainer } from './styles';

import { useSettings } from '../../hooks/settings';

function Support() {
  const { settings } = useSettings();
  const [widgetInterval, setWidgetInterval] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const atend = document.getElementById('atendimento');
    if (atend) {
      atend.innerHTML = '';
    }
    if (widgetInterval) {
      clearInterval(widgetInterval);
    }
    setWidgetInterval(
      setInterval(() => {
        const frame = document.getElementById('launcher');
        if (frame) {
          const content = frame.contentDocument;
          const button = content.getElementsByClassName('wrapper-AtBcr')[0];
          if (button) {
            const evObj = document.createEvent('Events');
            evObj.initEvent('click', true, false);
            button.dispatchEvent(evObj);
          }
        } else {
          const framePz = document.getElementsByClassName(
            'powerzap_open_button_standardType_iframe',
          )[0];
          if (framePz) {
            const content = framePz.contentDocument;
            const button = content.getElementsByClassName(
              'powerzap_open_button_standardType',
            )[0];
            if (button && !/\bopened\b/.test(button.className)) {
              const evObj = document.createEvent('Events');
              evObj.initEvent('click', true, false);
              button.dispatchEvent(evObj);
            }
          }
        }
      }, 1000),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return settings && settings.name ? (
    <Container>
      <div className="content-logo">
        <img src={settings.default_logo} alt={t('logo')} />
      </div>

      <Content>
        <span style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center' }}>
          {t('para entrar em contato, utilize...')}
        </span>

        {!!settings.support_email && (
          <SupportItemContainer>
            <span className="titulo">{t('email')}</span>
            <a href={`mailto:${settings.support_email}`}>
              <span>{settings.support_email}</span>
            </a>
          </SupportItemContainer>
        )}

        {!!settings.support_phone && (
          <SupportItemContainer>
            <span className="titulo">{t('telefone')}</span>
            <a href={`tel:${settings.support_phone}`}>
              <span>{settings.support_phone}</span>
            </a>
          </SupportItemContainer>
        )}

        {!!settings.support_whatsapp && (
          <SupportItemContainer>
            <span className="titulo">{t('whatsapp')}</span>
            {settings.support_whatsapp}
            <a
              href={`https://api.whatsapp.com/send?phone=${settings.support_whatsapp}`}
            >
              <span>{t('clique aqui para enviar uma mensagem')}</span>
            </a>
          </SupportItemContainer>
        )}
      </Content>
    </Container>
  ) : (
    <></>
  );
}

export default Support;
