import styled from 'styled-components';

export const ResolvedAssociate = styled.span`
 display: flex;
 width: 100%;
 font-weight: bold;
 justify-content: space-between;
 border-bottom: 0.1rem solid var(--primary);
 align-items: center;
 margin-bottom: 1rem;
 padding: 0.5rem;
`;
export const List = styled.div`
 font-size: 0.8em;
`;

export const AssociateArea = styled.div`
display: flex;
justify-content: center !important;
width: 100%;
`;

export const Associate = styled.span`
 display: flex;
 width: 85% !important;
 font-weight: bold;
 justify-content: space-between;
 border-bottom: 0.1rem solid var(--primary);
 align-items: center;
 margin-bottom: 1rem;
 padding: 0.5rem;
 cursor: pointer;
`;
export const Text = styled.div`
 display: flex;
 flex-direction: column;
`;
export const Title = styled.h3`
 display: flex;
 justify-content: center;
 color: var(--label);
 margin-top: 5%;
 margin-bottom: 5%;
`;
export const Subtitle = styled.span`
 display: flex;
 justify-content: center;
 align-items: center;
 color: grey;
 text-align: center;
 margin-bottom: 25%;
`;
export const DataInfo = styled.span`
 font-weight: lighter;
`;
export const EditButtonContainer = styled.div`
 display: flex;
 flex-flow: row nowrap;
 flex-direction: column;
 justify-content: space-around;
 width: 100%;
 height: 6rem;
`;
export const DeleteButtonContainer = styled.div`
 display: flex;
 flex-flow: row nowrap;
 width: 100%;
`;
export const SectionContainer = styled.section`
 display: flex;
 flex-flow: column;
 height: 100%;
 width: 90%;
`;
export const ButtonArea = styled.div`
height: 35%;
display: flex;
align-items: flex-end;
`;
