import api from './api';

/**
 * request to recover all the registered partners
 * @property {string} token - token bearer login account user PF
 * @property {string} account - account_id to account the user PF
 * @returns {{status: boolean, res: {response or error}}}
 */
export const getNotifications = (token, user) => {
  const headers = {
    user,
  };

  return api
    .get('notification', { headers })
    .then((response) => {
      const responseData = response.data;
      return { status: true, res: responseData };
    })
    .catch((error) => {
      console.error(error.message || error);
      return { status: false, res: error.response.data };
    });
};
