/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { SubTitle, Title } from "../../../styles";
import InputN from "../../../../../components/InputN";
import ButtonN from "../../../../../components/ButtonN";
import Alert from "../../../../../components/SpanError";
import messages from "../../../../../components/MessageError";
import PasswordParams from "../../../../../components/PasswordParams";
import { useSteps } from "../../../../../hooks/steps";
import { useCheckpoint } from "../../../../../hooks/checkpoint";
import LowerLeftMessage from "../../../../../components/LowerLeftMessage";
import { useSettings } from "../../../../../hooks/settings";
import api from "../../../../../services/api";
import ModalForTransition from '../../../../../components/ModalForTransition';
import { ModalTitle, SubtitleModal, TextModal } from "../../PF/Documents/style";
import { passwordRegex } from "../../../../../utilitys/Masks/passwordRegex";

const Password = () => {
  const [password, setPassword] = useState({
    passwordValid: "",
    passwordConfirm: "",
  });
  const {
    nextStep,
    prevStep,
    step,
    stepsPj,
    stepsPf,
    loading,
    setLoading,
    exactStep,
    resetStep,
  } = useSteps();
  const { t } = useTranslation();
  const { checkpoint, setCheckpoint } = useCheckpoint();
  const { settings, getEmailSupportMessage } = useSettings();
  const history = useHistory();

  const [passwordValid, setPassworValid] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [, setError] = useState(true);
  const [ showBlackList, setShowBlackList ] = useState(false);
  const validPasswordConfirm = password?.passwordConfirm?.length <= 0 || password?.passwordConfirm === password.passwordValid
  const validPassword = password?.passwordValid?.length <= 0 || passwordRegex.test(password?.passwordValid)
  useEffect(() => {
    exactStep(6);
  }, []);

  const formatNumber = () => {
    const dddNumber = checkpoint.cellNumber.slice(0, 2);
    const numberPhone = checkpoint.cellNumber.slice(2);
    return `${dddNumber} ${numberPhone}`;
  };

  const handleAdvance = () => {
    setCheckpoint({ ...checkpoint, password: password.passwordValid });
    setLoading(false);
    if (checkpoint.cpfCnpj.length === 11) {
      nextStep();
      if (stepsPf.length >= step + 1) {
        history.replace(`/register/${stepsPf[step + 1]}`);
      } else {
        history.replace(`/register/${stepsPf[step]}`);
      }
    }
  };

  const converteDate = () => {
    const dateBr = checkpoint.cpfData.birthday;
    const dateDefault = dateBr.split("/").reverse().join("-");
    return dateDefault;
  };

  const handleBasicData = async () => {
    setLoading(true);
    // objeto que sera enviado parra API no endpoint

    const data = settings.indication_analytics
      ? {
          client_name: settings.name,
          name: checkpoint.cpfData.name,
          document: checkpoint.cpfCnpj,
          email: checkpoint.email,
          phone: formatNumber(),
          birth_date: converteDate(),
          mother_name: checkpoint.cpfData.motherName,
          password: password.passwordValid,
          nationality: checkpoint.cpfData.nationality.id,
          indication: checkpoint.indication,
        }
      : {
          client_name: settings.name,
          name: checkpoint.cpfData.name,
          document: checkpoint.cpfCnpj,
          email: checkpoint.email,
          phone: formatNumber(),
          birth_date: converteDate(),
          mother_name: checkpoint.cpfData.motherName,
          password: password.passwordValid,
          nationality: checkpoint.cpfData.nationality.id,
        };
    return api
      .post("onboarding/basic-data", data)
      .then(() => {
        message.success("Informações enviadas com sucesso.");
        setError(false);
        handleAdvance();
      })
      .catch((error) => {
        if (error?.response?.data?.message?.includes('BLACKLIST')) {
          setShowBlackList(true);
          return
        }
        message.error(
          `Erro ao cadastrar a senha. ${getEmailSupportMessage()}`,
          4
        );
        setLoading(false);
        setError(true);
      });
  };
  useEffect(() => {
    const passwordC = password.passwordValid;
    if (
      passwordC.length >= 8 &&
      passwordC.toUpperCase() !== passwordC &&
      passwordC.toLowerCase() !== passwordC &&
      /\d/g.test(passwordC) &&
      /\W+/g.test(passwordC)
    ) {
      setPassworValid(true);
    } else {
      setPassworValid(false);
    }
  }, [password.passwordValid]);

  const handleHome = async () => {
    setLoading(false);
    setShowBlackList(false);
    resetStep();
    history.replace("/");
  };

  return (
    <>
      <Title>{t("Crie uma senha de acesso")}</Title>
      <SubTitle>
        <header style={{ fontSize: "1rem" }}>Hora de criar sua senha!</header>
        <span>
          Ela será usada para acessar sua conta onde e quando você quiser.
        </span>
      </SubTitle>
      <PasswordParams value={password.passwordValid} />
      <InputN.Password
        id="senha"
        label="Insira sua senha"
        isCorrect={!validPassword}
        type="password"
        placeholder="Insira sua senha"
        isCadastro
        maxLength={30}
        isValid={isValid}
        setIsValid={setIsValid}
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}
        onCopy={(e) => {
          e.preventDefault();
          return false;
        }}
        onChange={(e) =>
          setPassword({
            ...password,
            passwordValid: e.target.value.replace(" ", ""),
          })
        }
        value={password.passwordValid}
      />
      {!validPassword &&        <Alert message={'Insira uma senha contendo os requisitos minimos'} type="error" /> }
      <InputN.Password
        id="conf-senha"
        isCorrect={!validPasswordConfirm}
        label="Confirme sua senha"
        value={password.passwordConfirm}
        placeholder="Confirme sua senha"
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}
        maxLength={30}
        onCopy={(e) => {
          e.preventDefault();
          return false;
        }}
        isValid={!validPasswordConfirm}
        onChange={(e) =>
          setPassword({ ...password, passwordConfirm: e.target.value })
        }
      />
      {!password.passwordConfirm ? null : password.passwordConfirm !==
        password.passwordValid ? (
        <Alert message={messages.passwordDifferent} type="error" />
      ) : null}
      <ButtonN
        type="primary"
        className="botao"
        onClick={() => {
          handleBasicData();
        }}
        disabled={
          password.passwordConfirm !== password.passwordValid ||
          password.passwordConfirm === "" ||
          !passwordValid
        }
        icon={loading && <Spin indicator={<LoadingOutlined />} />}
        loading={loading}
      >
        {!loading && "Continuar"}
      </ButtonN>
      <ButtonN
        onClick={() => {
          prevStep(2);
          history.go(-2);
        }}
      >
        Voltar
      </ButtonN>
      <ModalForTransition
        visibility={showBlackList}
        setVisibility={setShowBlackList}
        style={{ height: "100%" }}
      >
        <>
          <ModalTitle>Sentimos muito!</ModalTitle>
          <SubtitleModal>
              Sentimos muito, não será possível prosseguir
          </SubtitleModal>
          <TextModal>{getEmailSupportMessage()}</TextModal>
          <ButtonN
            type="primary"
            className="botao"
            onClick={() => {
                handleHome();
                setShowBlackList(false);
            }}
          >
            Confirmar
          </ButtonN>
        </>
      </ModalForTransition>
      <LowerLeftMessage />
    </>
  );
};

export default Password;
