import styled from 'styled-components';

export const Container = styled.div`

`;

export const Header = styled.div`
  color: #000000;
  text-align: center;
  padding: 8px 0;
  font-weight: bold;
  border-radius: 8px;
  font-size: 16px;
  position: relative;

  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 600;
    font-size: 21px;
  }
`;

export const Content = styled.div`
  padding: 26px;
  text-align: center; 
  font-size: 18px;

  display: flex;
  align-items: center;
  flex-direction: column;
  
  color: #707070;
 
  svg{
    margin-bottom: 26px;
  }

  button{
    margin-top: 36px;
  }
`;
