/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';

import { Alert } from 'antd';
import { InputPropTypes, DefaultPropTypes } from './Types';
import { Container, StyledInput } from './styles';
import { AssociateDate } from '../CurrentDate';
import { dateRegex } from '../../utilitys/Masks/dateRegex';

const DateInput = ({
  setIsValid, value, className, isValid ,...rest
}) => {
  const options = { blocks: [2, 2, 4], delimiter: '/', numericOnly: true };
  const [hasError, setHasError] = useState(true);
  const [errorMessage] = useState('Formato de data inválida ou fora de alcance.');
  const validDate =  value?.length <= 0 || dateRegex.test(value);

  useEffect(() => {
    if (typeof setIsValid === 'function') {
      if (value.length === 8) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [value, setIsValid]);

  useEffect(() => {
    const [currentDay, currentMonth, currentYear] = AssociateDate.split('/').map((str) => parseInt(str));
    const currentDate = new Date(`${currentMonth}/${currentDay}/${currentYear}`);

    const [day, month, year] = value?.split('/').map((str) => parseInt(str));
    const date = new Date(`${month}/${day}/${year}`);

    const isValidYear = year > currentYear || year < 1900;
    const isValidMonth = month > 12 || month < 1;
    const isValidDay = day > 31 || day < 1;
    const isThirtyFirstDaysMonth = [1, 3, 5, 7, 8, 10, 12].includes(month) && day > 31;
    const isThirtyDaysMonth = [4, 6, 9, 11].includes(month) && day > 30;
    const isTodayOrOlder = currentDate < date;
    const isLeapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    const isValidFebruary = month === 2 ? (isLeapYear ? day > 29 : day > 28) : false;

    const rulesTest = [
      isValidYear,
      isValidMonth,
      isValidDay,
      isThirtyFirstDaysMonth,
      isThirtyDaysMonth,
      isTodayOrOlder,
      isValidFebruary,
    ];
    setHasError(rulesTest.some((item) => item));
  }, [setHasError, value]);

  return (
    <Container>
      {!!rest.label && <label htmlFor={rest.id}>{rest.label}</label>}
      <StyledInput
      isValid={!validDate || hasError && value.length >= 10}
        className={`ant-input ${className} ${
          value !== ''
&& (hasError && value.length >= 10 ? 'is-invalid' : value.length === 10 ? 'is-valid' : '')
        }`}
      >
        <Cleave {...rest} value={value} className="ant-input" options={options} />
        <i />
      </StyledInput>
      {hasError && value.length >= 10 && (
      <Alert
        style={{ border: 'none', backgroundColor: 'transparent', padding: '8px 0px' }}
        type="error"
        message={errorMessage}
      />
      )}
    </Container>
  );
};

export default DateInput;

DateInput.propTypes = {
  ...InputPropTypes,
  value: (props, propName) => (props[propName] === undefined || typeof props[propName] !== 'string')
&& new Error('Value necessário para validação!'),
  setIsValid: PropTypes.func,
};

DateInput.defaultProps = {
  ...DefaultPropTypes,
  value: null,
  setIsValid: undefined,
};
