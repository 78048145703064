export const annualRevenue = [
  { id: '1', name: 'R$ 0 a R$ 10.000' },
  { id: '2', name: 'R$ 10.001 a R$ 100.000' },
  { id: '3', name: 'R$ 100.001 a R$ 500.000' },
  { id: '4', name: 'R$ 500.001 a R$ 1.000.000' },
  { id: '5', name: 'Acima de R$ 1.000.000' },
];
export const legalNature = [
  { id: '1', name: 'MEI – Microempreendedor Individual' },
  { id: '2', name: 'Empresário Individual -EI' },
  { id: '3', name: 'EIRELI – Empresa Individual  de Responsabilidade Limitada' },
  { id: '4', name: 'Ltda - Sociedade Empresária Limitada' },
  { id: '5', name: 'SS – Sociedade Simples' },
  { id: '6', name: 'SA – Sociedade Anônima' },
  { id: '7', name: 'SLU - Sociedade Limitada Unipessoal' },
  { id: '8', name: 'Associação/Organização sem fins lucrativos' },
  { id: '9', name: 'Outros' },
];
export const incomeMonth = () => {
  const listIncome = [
    { id: '1', name: 'Até R$ 2.424,00' },
    { id: '2', name: 'R$ 2.424,01 a R$ 4.848.00' },
    { id: '3', name: 'R$ 4.848,01 a R$ 12.120,00' },
    { id: '4', name: 'R$ 12.120,01 a R$ 22.240,00' },
    { id: '5', name: 'Acima de R$ 22.240,01' },
  ];

  return listIncome;
};

export const professionsList = [
  { id: '1', name: 'Administrador' },
  { id: '2', name: 'Administrador público' },
  { id: '3', name: 'Advogado' },
  { id: '4', name: 'Agricultor' },
  { id: '5', name: 'Agrônomo' },
  { id: '6', name: 'Agropecuarista' },
  { id: '7', name: 'Analista e desenvolvedor de sistemas' },
  { id: '8', name: 'Animador' },
  { id: '9', name: 'Arqueólogo' },
  { id: '10', name: 'Arquiteto' },
  { id: '11', name: 'Arquivologista' },
  { id: '12', name: 'Artes e Design' },
  { id: '13', name: 'Artista plástico' },
  { id: '14', name: 'Astrônomo' },
  { id: '15', name: 'Ator' },
  { id: '16', name: 'Biblioteconomista' },
  { id: '17', name: 'Biomédico' },
  { id: '18', name: 'Bioquímico' },
  { id: '19', name: 'Biotecnólogo' },
  { id: '20', name: 'Chef' },
  { id: '21', name: 'Ciências Biológicas e da Terra' },
  { id: '22', name: 'Ciências Sociais e Humanas' },
  { id: '23', name: 'Cientista da computação' },
  { id: '24', name: 'Comunicação e Informação' },
  { id: '25', name: 'Construtor civil' },
  { id: '26', name: 'Construtor naval' },
  { id: '27', name: 'Contabilista' },
  { id: '28', name: 'Cooperativista' },
  { id: '29', name: 'Dançarino' },
  { id: '30', name: 'Dentista' },
  { id: '31', name: 'Designer' },
  { id: '32', name: 'Designer de games' },
  { id: '33', name: 'Designer de interiores' },
  { id: '34', name: 'Designer de moda' },
  { id: '35', name: 'Ecologista' },
  { id: '36', name: 'Economista' },
  { id: '37', name: 'Educador físico' },
  { id: '38', name: 'Educomunicador' },
  { id: '39', name: 'Enfermeiro' },
  { id: '40', name: 'Engenharia e Produção' },
  { id: '41', name: 'Engenheiro acústico' },
  { id: '42', name: 'Engenheiro aeronáutico' },
  { id: '43', name: 'Engenheiro agrícola' },
  { id: '44', name: 'Engenheiro ambiental e sanitário' },
  { id: '45', name: 'Engenheiro biomédico' },
  { id: '46', name: 'Engenheiro civil' },
  { id: '47', name: 'Engenheiro da computação' },
  { id: '48', name: 'Engenheiro de alimentos' },
  { id: '49', name: 'Engenheiro de biossistemas' },
  { id: '50', name: 'Engenheiro de controle e automação' },
  { id: '51', name: 'Engenheiro de energia' },
  { id: '52', name: 'Engenheiro de inovação' },
  { id: '53', name: 'Engenheiro de materiais' },
  { id: '54', name: 'Engenheiro de minas' },
  { id: '55', name: 'Engenheiro de pesca' },
  { id: '56', name: 'Engenheiro de petróleo' },
  { id: '57', name: 'Engenheiro de produção' },
  { id: '58', name: 'Engenheiro de segurança do trabalho' },
  { id: '59', name: 'Engenheiro de sistemas' },
  { id: '60', name: 'Engenheiro de software' },
  { id: '61', name: 'Engenheiro de telecomunicações' },
  { id: '62', name: 'Engenheiro de transporte e mobilidade' },
  { id: '63', name: 'Engenheiro elétrico' },
  { id: '64', name: 'Engenheiro eletrônico' },
  { id: '65', name: 'Engenheiro físico' },
  { id: '66', name: 'Engenheiro florestal' },
  { id: '67', name: 'Engenheiro hídrico' },
  { id: '68', name: 'Engenheiro mecânico' },
  { id: '69', name: 'Engenheiro mecatrônico' },
  { id: '70', name: 'Engenheiro naval' },
  { id: '71', name: 'Engenheiro químico' },
  { id: '72', name: 'Especialista em comércio exterior' },
  { id: '73', name: 'Estatístico' },
  { id: '74', name: 'Esteticista' },
  { id: '75', name: 'Farmacêutico' },
  { id: '76', name: 'Filósofo' },
  { id: '77', name: 'Físico' },
  { id: '78', name: 'Fisioterapeuta' },
  { id: '79', name: 'Fonoaudiólogo' },
  { id: '80', name: 'Fotógrafo' },
  { id: '81', name: 'Geofísico' },
  { id: '82', name: 'Geógrafo' },
  { id: '83', name: 'Geólogo' },
  { id: '84', name: 'Gerente comercial' },
  { id: '85', name: 'Gerontólogo' },
  { id: '86', name: 'Gestor ambiental' },
  { id: '87', name: 'Gestor da qualidade' },
  { id: '88', name: 'Gestor da tecnologia da informação' },
  { id: '89', name: 'Gestor de recursos humanos' },
  { id: '90', name: 'Gestor de turismo' },
  { id: '91', name: 'Gestor em saúde' },
  { id: '92', name: 'Gestor hospitalar' },
  { id: '93', name: 'Gestor público' },
  { id: '94', name: 'Historiador' },
  { id: '95', name: 'Historiador da arte' },
  { id: '96', name: 'Hoteleiro' },
  { id: '97', name: 'Jornalista' },
  { id: '98', name: 'Linguista' },
  { id: '99', name: 'Matemático' },
  { id: '100', name: 'Médico' },
  { id: '101', name: 'Meteorologista' },
  { id: '102', name: 'Minerador' },
  { id: '103', name: 'Museologista' },
  { id: '104', name: 'Músico' },
  { id: '105', name: 'Musicoterapeuta' },
  { id: '106', name: 'Nanotecnólogo' },
  { id: '107', name: 'Nutricionista' },
  { id: '108', name: 'Oceanógrafo' },
  { id: '109', name: 'Pedagogo' },
  { id: '110', name: 'Piloto de avião' },
  { id: '111', name: 'Produtor audiovisual' },
  { id: '112', name: 'Produtor cênico' },
  { id: '113', name: 'Produtor cultural' },
  { id: '114', name: 'Produtor editorial' },
  { id: '115', name: 'Produtor fonográfico' },
  { id: '116', name: 'Produtor multimídia' },
  { id: '117', name: 'Produtor publicitário' },
  { id: '118', name: 'Professor' },
  { id: '119', name: 'Psicólogo' },
  { id: '120', name: 'Psicopedagogo' },
  { id: '121', name: 'Publicitário' },
  { id: '122', name: 'Químico' },
  { id: '123', name: 'Radialista' },
  { id: '124', name: 'Radiologista' },
  { id: '125', name: 'Relações internacionais' },
  { id: '126', name: 'Relações públicas' },
  { id: '127', name: 'Saúde e Bem-Estar' },
  { id: '128', name: 'Secretária' },
  { id: '129', name: 'Secretária executiva' },
  { id: '130', name: 'Silvicultor' },
  { id: '131', name: 'Sociólogo' },
  { id: '132', name: 'Teólogo' },
  { id: '133', name: 'Terapeuta ocupacional' },
  { id: '134', name: 'Tradutor e intérprete' },
  { id: '135', name: 'Turismólogo' },
  { id: '136', name: 'Veterinário' },
  { id: '137', name: 'Zootecnólogo' },
  { id: '138', name: 'Outros' },
];
