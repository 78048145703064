import styled from 'styled-components';

export const Container = styled.div`
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 width: 100%;

 span:first-child {
 color: inherit;
 font-size: 0.75rem;
 font-weight: 700;
 margin-bottom: 0.5rem;
 }

 span {
 color: #a8a8a8;
 font-size: 0.7rem;
 }
`;

export const PasswordContainer = styled.div`
 display: flex;
 flex-flow: column nowrap;
 margin-top: 40px;
`;
