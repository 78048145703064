import React from 'react';
import {
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { FiSmile } from 'react-icons/fi';
import Button from '../../../components/Button';
import {
  Container,
  Header,
  Content,
} from './styles';

function ApprovedRequestModal({ visible, hidden }) {
  const { t } = useTranslation();
  return (
    <Modal
      wrapClassName="adjust-limit-modal"
      centered
      visible={visible}
      onOk={hidden}
      closable={false}
      onCancel={hidden}
      footer={false}
      destroyOnClose
      style={{ padding: 0, margin: 0, borderRadius: '8px' }}
      bodyStyle={{ padding: 0 }}
      width={660}
    >
      <Container>
        <Header>
          <CloseOutlined onClick={hidden} />
        </Header>
        <Content>
          <FiSmile color="#707070" size={60} />
          <span><strong>{t('seu ajuste de limite foi aprovado')}</strong></span>
          <Button onClick={hidden}><span>{t('entendi')}</span></Button>
        </Content>
      </Container>
    </Modal>
  );
}

ApprovedRequestModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  hidden: PropTypes.func.isRequired,
};

export default ApprovedRequestModal;
