/* eslint-disable import/no-dynamic-require */

import * as bcrypt from './bcrypt';

export function HandleFormatMoney(amount) {
  try {
    return amount.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  } catch (e) {
    return amount.toString();
  }
}

export function localeParseFloat(stringValue) {
  const str = String(stringValue).replace('R$', '').replace(' ', '');
  let out = [];
  str.split(',').map((x) => {
    const value = x.replace('.', '');
    out.push(value);
    return {};
  });
  out = out.join('.');
  return parseFloat(out);
}

export function documentMask(val) {
  try {
    if (val && val.length) {
      const docVal = val.replace(/[/,.,-]/g, '');
      // MASK CPF / CNPJ
      if (docVal.length <= 14) {
        if (docVal.length > 12) {
          // cnpj  `##.###.###/####-##`
          const cnpj = /^([\d]{2})\.*([\d]{3})\.*([\d]{3})\/*([\d]{4})\.*([\d]{2})/;
          return docVal.replace(cnpj, '$1.$2.$3/$4-$5');
        }
        // cpf `###.###.###-##`
        const cpf = /([\d]{3})\.*([\d]{3})\.*([\d]{3})-*([\d]{2})/;
        return docVal.replace(cpf, '$1.$2.$3-$4');
      }
      return val;
    }

    return val;
  } catch {
    return val;
  }
}

export function dateToISO(dateObj) {
  const date = dateObj.toLocaleString('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).replace(/\//g, '-');

  const [dd, MM, yyyy] = date.split('-');
  return `${yyyy}-${MM}-${dd}`;
}

export function findGetParameter(parameterName) {
  let result = null;
  let tmp = [];
  const items = window.location.search.substr(1).split('&');
  for (let index = 0; index < items.length; index += 1) {
    tmp = items[index].split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}

const cards = {
  Visa: /^4[0-9]{12}(?:[0-9]{3})/,
  // eslint-disable-next-line max-len
  Master: /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
  Amex: /^3[47][0-9]{13}/,
  Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
  Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
  JCB: /^(?:2131|1800|35\d{3})\d{11}/,
  Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
  Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
  Aura: /^(5078\d{2})(\d{2})(\d{11})$/,
};

export const getBrandCard = (nr) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const card in cards) {
    if (nr.match(cards[card])) {
      return card;
    }
  }

  return false;
};

export const getPaymentMethod = (paymentType) => {
  if (paymentType === 'credit') return 'Cartão';

  if (paymentType === 'billet') return 'Boleto';

  if (paymentType === 'card_avulse') return 'Boleto avulso';

  return '-';
};

export const addZeros = (size, str) => {
  let resp = '';
  if (str.length > size) {
    resp = str.substring(0, size);
    return resp;
  } if (str.length < size) {
    resp = str;
    while (resp.length < size) {
      resp = `0${resp}`;
    }
    return resp;
  }
  return str;
};

export const manageSpaces = (size, str) => {
  let resp = '';
  if (str.length > size) {
    resp = str.substring(0, size);
    return resp;
  } if (str.length < size) {
    resp = str;
    while (resp.length < size) {
      resp = `${resp} `;
    }
    return resp;
  }
  return str;
};

export const getVibeEcommerceSaleStatus = (status) => {
  if (status === 'DENIED') {
    return 'Negado';
  }

  if (status === 'PENDING') {
    return 'Pendente';
  }

  if (status === 'CONFIRMED') {
    return 'Confimado';
  }

  if (status === 'APPROVED') {
    return 'Aprovado';
  }

  return '-';
};

export const getVibeEcommerceSalePaymentType = (type) => {
  if (type === 'credit') {
    return 'Crédito';
  }

  if (type === 'debit') {
    return 'Débito';
  }

  if (type === 'billet') {
    return 'Boleto';
  }

  return '-';
};

export const hideDocument = (doc) => `***${doc?.slice(3, doc?.length - 2 || undefined) || ''}**`;

export const generateHash = (str) => bcrypt.hashSync(str, 9);
