/* eslint-disable prefer-template */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Webcam from 'react-webcam';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import FormData from 'form-data';
import ButtonN from '../../../../components/ButtonN';
import { useSettings } from '../../../../hooks/settings';
import {
  Section,
  HeaderArea,
  CamStyled,
  Preview,
  ButtonPicture,
  ButtonPhoto,
  WhiteText,
  WhiteTitle,
  OverlayContainer,
  Container,
  Box,
  Corner1,
  Corner2,
  Corner3,
  Corner4,
} from './styles';
import api from '../../../../services/api';
import { useRefresh } from '../../../../hooks/refresh';
import eyesMask from '../../../../assets/eyes-mask.png';

const Cam = () => {
  const {
    token, setStep, setPhotoAttempts, photoAttempts,
  } = useRefresh();

  const [image, setImage] = useState('');
  const webcamRef = React.useRef(null);
  const [photoData, setPhotoData] = useState({ photo: '' });
  const [loading, setLoading] = useState(false);
  const { settings, getEmailSupportMessage } = useSettings();
  const lastAttempt = photoAttempts === 1;
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    setPhotoData({ ...photoData, photo: imageSrc });
  }, [webcamRef, photoData]);

  const videoConstraints = {
    width: 300,
    height: 400,
    facingMode: 'user',
  };

  const eyesMaskSvg = () => (
    <img
      style={{ width: '300px', height: '400px' }}
      src={eyesMask}
      alt="eyes-mask"
    />
  );

  const handleApiSelfie = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('file', photoData.photo);

    fetch(photoData.photo)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'selfie.png');
        formData.append('file', file);
        api.post('onboarding/documents/SELFIE', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(() => {
            setLoading(false);
            setStep('documents');
          })
          .catch((error) => {
            setLoading(false);
            setPhotoAttempts(error?.response?.data?.message?.attempts);
            if (error.response.data.message.message === 'Could not detect a face. Try another image. ') {
              message.error('Não foi possível detectar sua face. Tente novamente.', 6);
            } else if (
              error.response.data.message.message === 'Please try to avoid excessive light in the background. '
            ) {
              message.error('Evite excesso de luz no ambiente. Tente novamente. ', 6);
            } else if (error.response.data.message.attempts < 1) {
              message.error(
                `Quantidade de tentativas excedidas, tente novamente amanhã. ${getEmailSupportMessage()}`,
                7,
              );
            } else {
              message.error(
                'Erro ao validar a imagem, gentileza envie outra foto.',
                7,
              );
            }
          });
      });
  };
  console.log('photo attempts', photoAttempts, lastAttempt);
  return (
    <>
      {/* {!cameraActive && message.error('Por favor ative sua camera')} */}
      <Section>
        <CamStyled>
          <HeaderArea>
            {lastAttempt ? (
              <>
                <WhiteTitle>Parece que tivemos algum problema com a sua foto</WhiteTitle>
                <WhiteTitle>Você só tem mais <span style={{ color: '#e22012' }}>1 tentativa</span>!</WhiteTitle>
              </>
            ) : (
              <>
                <WhiteTitle>Hora da foto!</WhiteTitle>
                <WhiteText>
                  Para garantir que ninguém esteja se passando por você, precisamos que nos envie uma foto do
                  seu rosto. Você terá apenas <span style={{ color: '#e22012' }}>5 tentativas </span>
                  para tirar a foto, então leia bem e siga todas as
                  as instruções. Vamos lá?
                </WhiteText>
              </>
            )}
          </HeaderArea>
          <>
            {image === '' ? (
              <Box>
                <Container>
                  <OverlayContainer>
                    <Corner1 borderColor={lastAttempt && '#e22012'} />
                    <Corner2 borderColor={lastAttempt && '#e22012'} />
                    <Corner3 borderColor={lastAttempt && '#e22012'} />
                    <Corner4 borderColor={lastAttempt && '#e22012'} />
                    {eyesMaskSvg()}
                  </OverlayContainer>
                  <Webcam
                    audio={false}
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/png"
                    ref={webcamRef}
                    screenshotQuality={1}
                    imageSmoothing
                    mirrored
                  />
                </Container>
              </Box>
            ) : (
              <Preview src={image} />
            )}
          </>

          {image !== '' ? (
            <ButtonPhoto>
              <ButtonN
                style={{ background: 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  setImage('');
                }}
                disabled={loading}
              >
                Tirar outra
              </ButtonN>
              <ButtonN
                onClick={() => {
                  handleApiSelfie();
                }}
                type="primary"
                loading={loading}
                icon={loading && <Spin indicator={<LoadingOutlined />} />}
              >
                {loading ? 'Enviando' : 'Ficou boa'}
              </ButtonN>
            </ButtonPhoto>
          ) : (
            <ButtonPicture
              onClick={(e) => {
                e.preventDefault();
                capture(() => {});
              }}
            >
              <FontAwesomeIcon icon={faCamera} size="2x" color="#ffff" />
            </ButtonPicture>
          )}
        </CamStyled>
      </Section>
    </>
  );
};

export default Cam;
