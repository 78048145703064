import React from 'react';
import { MdCheck as Check } from 'react-icons/md';
import { Wrapper } from './styles';
import { useSettings } from '../../hooks/settings';

const CheckBox = ({
  size = 20, isChecked, onCheck, isDisabled = false,
}) => {
  const { settings } = useSettings();
  return (
    <Wrapper
      isChecked={isChecked}
      size={size}
      onClick={isDisabled ? null : () => onCheck(!isChecked)}
      isDisabled={isDisabled}
      color={settings?.colors.details}
    >
      {isChecked && <Check color="white" size={15} />}
    </Wrapper>
  );
};

export default CheckBox;
