import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal as ModalAntd } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { Container, StyledInput } from './styles';
import { AntProps, DefaultAntProps } from './Types';

const Modal = (props) => {
  const {
    className, content, label, id, placeholder, value,isValid
  } = props;
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Container>
        {label && <label htmlFor={id}>{label}</label>}
        <StyledInput
        isValid={isValid}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setVisible(true);
          }}
          className={`ant-input ${className}`}
        >
          <input
            style={{ pointerEvents: 'none' }}
            value={value}
            placeholder={placeholder}
            onChange={(e) => e.preventDefault()}
            onFocus={() => {
              setVisible(true);
            }}
          />
          <FontAwesomeIcon icon={faAngleDown} />
        </StyledInput>
      </Container>
      <ModalAntd
        visible={visible}
        footer={null}
        closable={false}
        destroyOnClose
        focusTriggerAfterClose={false}
        onCancel={() => {
          setVisible(false);
        }}
      >
        {content}
      </ModalAntd>
    </>
  );
};
Modal.propTypes = {
  ...AntProps,
  content: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  ...DefaultAntProps,
};

export default Modal;
