/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSun, faIdBadge, faCompress, faFileUpload, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Alert, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  identityCardFront,
  identityCardVerse,
  driverLicenseFront,
  driverLicenseVerse,
  documentsForeign,
  newIdentityCardVerse,
  newIdentityCardFront,
} from '../../../../../services/endPointRegister/identityPost';

import {
  Text,
  Tips,
  Card,
  Container,
  DropButton,
  DropContainer,
  Preview,
  Buttons,
  MainTitle,
  Options,
  Option,
  Subtitle,
  Section,
  Box,
  Texts,
  Displayer,
  Conditions,
  ModalTitle,
  SubtitleModal,
  TextModal,
  DocumentsTextContainer,
} from './style';
import { Title } from '../../../styles';
import ButtonN from '../../../../../components/ButtonN';
import { useSteps } from '../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import { useSettings } from '../../../../../hooks/settings';
import ModalForTransition from '../../../../../components/ModalForTransition';
import RGFront from '../../../../../assets/RG_front.png';
import RGVerse from '../../../../../assets/RG_verse.png';
import CNHFront from '../../../../../assets/CNH_front.png';
import CNHVerse from '../../../../../assets/CNH_verse.png';
import CamToValidate from './CamToValidate';

const Documents = () => {
  const { checkpoint, setCheckpoint } = useCheckpoint();

  const {
    nextStep, prevStep, step, stepsPf, exactStep, resetStep,
  } = useSteps();
  const { getEmailSupportMessage } = useSettings();
  const [shouldSendValidation, setShouldSendValidation] = useState(false);
  const history = useHistory();
  const [cont, setCont] = useState(0);
  const [files, setFiles] = useState([]);
  const [blacklistTest, setBlackListTest] = useState(false);
  const [typeError, setTypeError] = useState('');
  const [nationality, setNationality] = useState('Brasil');
  const [frente, setFrente] = useState(true);
  const [frontalDone, setFrontalDone] = useState(false);
  const [documentsData, setDocumentsData] = useState({
    documentsType: '',
    documentsFront: '',
    documentsBack: '',
    documentsValidate: ''
  });
  const [loading, setLoading] = useState(false);
  const [backButton, setBackButton] = useState(true);

  const token = localStorage.getItem('@qesh:token');

  const documentContainerText = {
    title: cont === 0
    ? `Exemplo ${frente ? 'da frente' : 'do verso'} do ${documentsData.documentsType}`
    : `Envie a foto ${frente ? 'da frente' : 'do verso'} do seu ${documentsData.documentsType}`,

    subtitle: cont === 0
      ? `Siga o exemplo abaixo e tire foto ${frente ? 'da frente' : 'do verso'} do documento`
      : '',
  };

  const getDocumentContainerPhoto = useCallback(
    () => {
      const documentsImage = documentsData.documentsType === 'RG'
        ? { front: RGFront, verse: RGVerse }
        : { front: CNHFront, verse: CNHVerse };
      return frente ? documentsImage.front : documentsImage.verse;
    },
    [frente, documentsData.documentsType],
  );

  useEffect(() => {
    if (
      (checkpoint?.cpfData?.nationality !== undefined || sessionStorage.getItem('@qesh:nationality') !== null)
   && localStorage.getItem('@qesh:token')
   && sessionStorage.getItem('@qesh:step') === '11'
    ) {
      exactStep(11);
    } else {
      resetStep();
      history.replace('/register/');
    }
  }, []);

  useEffect(() => {
    let nat;
    // const sessionNationality = sessionStorage.getItem('@qesh:nationality')
    sessionStorage.getItem('@qesh:nationality') !== null
      ? (nat = sessionStorage.getItem('@qesh:nationality'))
      : (nat = checkpoint.cpfData.nationality.id);
    if (nat) {
      setNationality(nat);
    } else {
      setNationality('Brasil');
    }
  });
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      const array = files;
      acceptedFiles.map((file) => {
        if (['png', 'jpg'].includes(file?.name?.slice(-3).toLowerCase()) || file?.name?.slice(-4).toLowerCase() === 'jpeg') {
          reader.readAsDataURL(file);
          reader.onloadend = function () {
            if (documentsData.documentsFront === '') {
              setDocumentsData({ ...documentsData, documentsFront: files[0] });
            } else if (documentsData.documentsBack === ''
            ) {
              setDocumentsData({
                ...documentsData,
                documentsBack: !files[1] ? files[0] : files[1],
              });
            } else {
              setDocumentsData({ ...documentsData, documentsValidate: files[2] });
              
            }
          };
          array.push(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          );
        } else {
        files.pop();
          setFiles((file) => file);
          message.error('Tipo de arquivo inválido, favor enviar nos seguintes formatos: png, jpg ou jpeg')
        }
      });
      if (files[files.length - 1].size <= 5000000
        &&
        ['png', 'jpg'].includes(files[files.length - 1]?.name?.slice(-3).toLowerCase()) || files[files.length - 1]?.name?.slice(-4).toLowerCase() === 'jpeg'
        ) {
        setFiles(array);
        if (documentsData.documentsType === 'PAS') {
          setCont(cont + 1);
        } else {
          setCont(cont + 1);
        }
      } else {
        files.pop();
        setFiles((file) => file);
        setCont(0);
        message.error('O Arquivo tem que ser menor ou igual que 5MB. Por favor nos envie um menor!', 6);
      }
    },
  });
  const handleDelete = () => {
    files.pop();
    setFiles((file) => file);
  };
  const resetDocuments = () => {  
    if (documentsData.documentsFront !== '' && documentsData.documentsBack === ''){
      setDocumentsData({...documentsData, documentsFront: ''})
    }else if (documentsData.documentsFront !== '' && documentsData.documentsBack !== '') {
      setDocumentsData({...documentsData,  documentsBack: ''})
    }
  }

  const handleError = (error, advanced) => {
    const errorValidator = error[1]?.message;
    if (error && error[0]) {
      if (errorValidator?.includes('trying to validate')) {
        setBlackListTest(true);
        setTypeError('error_validate');
      } else if (errorValidator?.includes('license is expired')) {
        setBlackListTest(true);
        setTypeError('error_expired');
      } else if (errorValidator === 'Document side does not match') {
        setBlackListTest(true);
        setTypeError('error_side');
      } else if (error[1] === 'Person blocked - BLACKLIST' || errorValidator?.includes('Refused by validator ')) {
        setBlackListTest(true);
        setTypeError('error_blacklist');
      } else if (errorValidator?.includes('The attempt limit')) {
        setBlackListTest(true);
        setTypeError('error_attempts');
      } else if (errorValidator?.includes('type does not match')) {
        setBlackListTest(true);
        setTypeError('error_type_not_match');
      } else {
        setBlackListTest(true);
        setTypeError('error');
      }
    } else {
      setFrente(false);
      !advanced && setFrontalDone(true);
      setCont(cont - 1);
    }
  };

  const handleFrontImageUpload = async () => {
    setLoading(true);
    let error;
    try {
      const requisition = async () => {
        switch (documentsData.documentsType) {
          case 'CNH':
            return driverLicenseFront(documentsData.documentsFront, token);
          case 'RG':
            return identityCardFront(documentsData.documentsFront, token);
          case 'NEW-RG':
            return newIdentityCardFront(documentsData.documentsFront, token);
          case 'PAS':
            return documentsForeign(documentsData.documentsFront, token, 'PASSPORT_CARD');
          case 'RNE':
            return documentsForeign(documentsData.documentsFront, token, 'RNE_FRONT');
          case 'RNM':
            return documentsForeign(documentsData.documentsFront, token, 'CRNM_FRONT');
          default:
        }
      };
      error = await requisition();
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
      handleError(error, false);
    }
  };

  const handleHome = async () => {
    setLoading(false);
    setBlackListTest(false);
    resetStep();
    history.replace('/');
  };
  const handleAdvance = async () => {
    setLoading(true);
    let error;
    try {
      const requisition = async () => {
        switch (documentsData.documentsType) {
          case 'CNH':
            return driverLicenseVerse(documentsData.documentsBack, token);
          case 'RG':
            localStorage.setItem('oldRG', true)
            return identityCardVerse(documentsData.documentsBack, token);
          case 'NEW-RG':
            return newIdentityCardVerse(documentsData.documentsBack, token);
          case 'RNE':
            return documentsForeign(documentsData.documentsBack, token, 'RNE_VERSE');
          case 'RNM':
            return documentsForeign(documentsData.documentsBack, token, 'CRNM_VERSE');
          default:
        }
      };
      error = await requisition();
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);

      if (error[0]) {
        handleError(error, true);
      } else {
        if (documentsData.documentsType !== 'RG') {
          nextStep();
        }
        if (stepsPf.length >= step + 1 && documentsData.documentsType !== 'RG') {
          message.success('Documento enviado com sucesso', 4);
          history.replace(`/register/${stepsPf[step + 1]}`);
        } else {
          setCont(cont - 1);
          setShouldSendValidation(true);
          history.replace(`/register/${stepsPf[step]}`);
        }
      }
    }

    setCheckpoint({ ...checkpoint, documentsData });
  };

  const handleNext = async () => {
    nextStep();
    message.success('Documento enviado com sucesso', 4);
    history.replace(`/register/${stepsPf[step + 1]}`);

    setCheckpoint({ ...checkpoint, documentsData });
  };

  const modalText = () => {
    if (typeError === 'error_side') {
      return 'O lado do documento está errado, por favor envie outro.';
    }
    if (typeError === 'error_validate') {
      return 'As informações cadastradas anteriormente não correspondem ao documento enviado.';
    }
    if (typeError === 'error_blacklist') {
      return getEmailSupportMessage();
    }
    if (typeError === 'error_attempts') {
      return `Tentativas excedidas. ${getEmailSupportMessage()} ou tente novamente amanhã.`;
    }
    if (typeError === 'error') {
      return 'Tente tirar uma nova foto e reenviar';
    }
    if (typeError === 'error_expired') {
      return 'Seu documento apresenta validade expirada. Gentileza, utilizar um documento valido ou forneça outro documento de identificação.';
    }
    if (typeError === 'error_type_not_match') {
      return `Tipo de ${documentsData.documentsType === 'NEW-RG' ? 'novo RG' : documentsData.documentsType} selecionada está incorreta. Tente com outro documento.`;
    }
  };
  
  return (
    <>
      <Section>
        <Texts>
          <MainTitle>Agora é hora de enviar as fotos do seu documento</MainTitle>
          {!documentsData.documentsType && (
          <Subtitle>
            Escolha qual documento você deseja nos enviar e siga as instruções para tirar uma foto da
            frente e outra do verso.
          </Subtitle>
          )}
        </Texts>
        <Card>
          {(!documentsData.documentsType && (
          <>
            <MainTitle style={{ color: 'var(--label)' }}>Qual documento quer nos enviar?</MainTitle>
            <h5 style={{ padding: '.4rem', textAlign: 'center' }}>
              Escolha uma das opções abaixo e siga as instruções de envio.
            </h5>
            <Conditions>Envie apenas arquivos de imagem com no máximo 5MB</Conditions>
            <Options>
              {(['Brasil', 'Brasileiro(a)'].includes(nationality) && (
              <>
                <Option
                  onClick={() => setDocumentsData({ ...documentsData, documentsType: 'RG' })}
                >
                  Carteira de identidade (RG - Antiga)
                  <FontAwesomeIcon icon={faChevronRight} />
                </Option>
                <Option
                  onClick={() => setDocumentsData({ ...documentsData, documentsType: 'NEW-RG' })}
                >
                  Carteira de identidade (RG - Nova)
                  <FontAwesomeIcon icon={faChevronRight} />
                </Option>
                <Option
                  onClick={() => setDocumentsData({ ...documentsData, documentsType: 'CNH' })}
                >
                  Carteira de motorista (CNH)
                  <FontAwesomeIcon icon={faChevronRight} />
                </Option>
              </>
              )) || (
              <>
                <Option
                  onClick={() => setDocumentsData({ ...documentsData, documentsType: 'RNM' })}
                >
                  Carteira Nacional Migratório (CRNM)
                  <FontAwesomeIcon icon={faChevronRight} />
                </Option>
                <Option
                  onClick={() => setDocumentsData({ ...documentsData, documentsType: 'RNE' })}
                >
                  Registro Nacional Estrangeiros (RNE)
                  <FontAwesomeIcon icon={faChevronRight} />
                </Option>

                <Option
                  onClick={() => setDocumentsData({ ...documentsData, documentsType: 'PAS' })}
                >
                  Passaporte
                  <FontAwesomeIcon icon={faChevronRight} />
                </Option>
              </>
              )}
            </Options>
          </>
          )) || (
          <>
            <Container>
              <h1>{shouldSendValidation ? 'Hora da foto!' : 'Agora é hora de enviar as fotos do seu documento'}</h1>
              <Box>
                {documentsData.documentsType === 'PAS' ? (
                  <h2>Envie foto do seu PASSAPORTE</h2>
                ) : (
                  <DocumentsTextContainer>
                    <h2>
                    {shouldSendValidation ? 'Para garantir que ninguém esteja se passando por você, precisamos que nos envie uma foto do verso do seu RG junto do seu rosto' : documentContainerText.title}
                    </h2>
                    <p>{!shouldSendValidation && documentContainerText.subtitle}</p>
                  </DocumentsTextContainer>
                )}
                {(cont === 0 && !shouldSendValidation) && (
                  <>
                    <Preview
                      style={{ width: '45%' }}
                      src={getDocumentContainerPhoto()}
                      alt="img"
                    />
                    <DropContainer {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <div style={{ display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' }}>
                        <DropButton>
                          <FontAwesomeIcon icon={faFileUpload} size="5x" />
                        </DropButton>
                        <h2>Fazer o upload</h2>
                      </div>
                    </DropContainer>
                  </>
                )}
                {(cont === 1 && !shouldSendValidation) && (
                <Displayer>
                  <Preview src={files[files.length - 1]?.preview} alt="img" />
                </Displayer>
                )}
                {
                  shouldSendValidation && (
                    <CamToValidate handleNext={handleNext} />
                  )
                }
              </Box>
            </Container>
          </>
          )}
        </Card>

        {(cont === 1 && !shouldSendValidation) && (
        <Buttons>
          <ButtonN
            onClick={() => {
              handleDelete();
              resetDocuments()
              setCont(0);
            }}
            disabled={loading}
          >
            Excluir
          </ButtonN>
          <ButtonN
            type="primary"
            className="botao"
            loading={loading}
            onClick={() => {
              if (files.length < 2) {
                if (documentsData.documentsType === 'PAS') {
                  handleAdvance();
                }
                if (!frontalDone) {
                  handleFrontImageUpload();
                } else {
                  handleAdvance();
                }
              } else {
                handleAdvance();
              }  
            }}
            icon={loading && <Spin indicator={<LoadingOutlined />} />}
          >
            {!loading && 'Próximo'}
          </ButtonN>
        </Buttons>
        )}
      </Section>
      <Title>Confira algumas dicas para você tirar uma boa foto</Title>
      <Tips>
        <Text>
          <FontAwesomeIcon icon={faSun} />
          Encontre um lugar com boa iluminação
        </Text>
        <Text>
          <FontAwesomeIcon icon={faIdBadge} />
          Evite reflexos. Tire o documento do plástico
        </Text>
        <Text>
          <FontAwesomeIcon icon={faCompress} />
          Centralize o documento no modelo indicado.
        </Text>
      </Tips>
      <ButtonN
        onClick={() => {
          setDocumentsData({
            documentsType: '',
            documentsFront: '',
            documentsBack: '',
            documentsValidate: ''
          })
          setShouldSendValidation(false)
          setFiles([])
          setFrente(true)
          setFrontalDone(false)
          setCont(0)
        }}
        disabled={!backButton}
      >
        Voltar
      </ButtonN>
      <ModalForTransition visibility={blacklistTest} setVisibility={setBlackListTest} style={{ height: '100%' }}>
        <>
          <ModalTitle>{documentsData.documentsType === 'CNH' ? 'Parece que tivemos um problema com seu documento.' : 'Sentimos muito!'}</ModalTitle>
          <SubtitleModal>
            {typeError === 'blacklist' ? 'Sentimos muito, não será possível prosseguir' : ''}
          </SubtitleModal>
          <TextModal>{modalText()}</TextModal>
          <ButtonN
            type="primary"
            className="botao"
            onClick={() => {
              if (typeError === 'blacklist') {
                handleHome();
              } else {
                setBlackListTest(false);
                setTypeError('');
              }
            }}
          >
            Confirmar
          </ButtonN>
        </>
      </ModalForTransition>
    </>
  );
};

export default Documents;
