import React from 'react';
import { Link } from 'react-router-dom';
import { useShop } from '../../hooks/shop';
import { useSettings } from '../../hooks/settings';
import FileUpload from '../FileInput';
import {
  FileSelectWrapper, FileUploadWrapper, MarkdownButton, Title, TitleWrapper, Wrapper,
} from './styles';

const Footer = ({ accept = '.md' }) => {
  const { setUsageTerms, usageTerms } = useShop();
  const { settings } = useSettings();

  function handleFileChange(event) {
    if (event.target.files[0]) {
      setUsageTerms(event.target.files[0]);
    }
  }

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>
          <p>Termos de Uso/Política de Privacidade</p>
          <span>*</span>
        </Title>
        <span>Faça upload dos seus termos de Uso/Política de privacidade em formato de markdown (.md) ou use o nosso gerador no link abaixo</span>
      </TitleWrapper>
      <FileSelectWrapper>
        <FileUploadWrapper>
          <span>Formato .md</span>
          <FileUpload
            accept={accept}
            handleChange={handleFileChange}
            file={usageTerms}
          />
        </FileUploadWrapper>

        <Link to="markdown" target="_blank">
          <MarkdownButton
            color={settings.colors.primary}
          >Gerador de arquivo markdown
          </MarkdownButton>
        </Link>

      </FileSelectWrapper>
    </Wrapper>
  );
};

export default Footer;
