import styled from 'styled-components';

export const Wrapper = styled.div`
  width: fit-content;
  max-width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepWrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Step = styled.div`
  width: 47px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: ${(props) => (props.isLast ? '#D9D9D9' : props.color)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  color: #f3fbfb;
`;

export const Bar = styled.div`
  width: 250px;
  height: 4px;
  background-color: ${(props) => (props.isLast ? '#D9D9D9' : props.color)};
  left: 100%;
`;
