/* eslint-disable react/no-unused-prop-types */
import React, {
  useCallback,
  useRef,
  useEffect,
} from 'react';
import PropTypes, { string } from 'prop-types';
import { useField } from '@unform/core';
import { Radio } from './styles';

const IRadio = ({
  name,
  defaultChecked,
  radios,
}) => {
  const radioRef = useRef(null);

  const {
    fieldName, registerField,
  } = useField(name);

  const [beneficiaryTypeAccount, setBeneficiaryTypeAccount] = React
    .useState(defaultChecked ? { type: defaultChecked } : { type: null });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: beneficiaryTypeAccount,
      path: 'type',
    });
  }, [beneficiaryTypeAccount, fieldName, radioRef, registerField]);

  const onBeneficiaryTypeAccountChange = useCallback((e) => {
    setBeneficiaryTypeAccount({ type: e.target.value });
  }, []);

  return (
    <Radio.Group
      onChange={onBeneficiaryTypeAccountChange}
      value={beneficiaryTypeAccount.type}
    >
      {radios.map((radio) => (
        <Radio color={radio.color} value={radio.value}>{radio.label}</Radio>
      ))}
    </Radio.Group>
  );
};

IRadio.propTypes = {
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.string,
  children: PropTypes.element.isRequired,
  radios: PropTypes.arrayOf({ color: string, value: string, label: string }).isRequired,
};

IRadio.defaultProps = {
  defaultChecked: '',
};

export default IRadio;
