import styled from 'styled-components';

export const Container = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative; 
  height: auto;
`;

export const IconText = styled.span`
  position: absolute;
  text-align: center;
  margin-top: 15%;
  margin-bottom: auto;
`;
