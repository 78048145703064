/* eslint-disable max-len */
import React, { useState } from 'react';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Container, ModalStyle } from './styles';
import ButtonN from '../ButtonN';
import { useSettings } from '../../hooks/settings';

const LinkToApp = () => {
  const [modalCnpj, setModalCnpj] = useState(false);
  const { settings } = useSettings();
  const { t } = useTranslation();
  return (
    <Container style={{ zIndex: settings.name === 'diverse' ? -44 : null }}>
      <span>{t('Como cadastrar CNPJ?')}</span>

      <FontAwesomeIcon
        style={{ color: 'var(--primary)', cursor: 'pointer' }}
        icon={faQuestionCircle}
        onClick={() => setModalCnpj(true)}
      />

      <Modal
        visible={modalCnpj}
        footer={null}
        closable={false}
        destroyOnClose
        focusTriggerAfterClose={false}
        onCancel={() => {
          setModalCnpj(false);
        }}
        bodyStyle={{ padding: '2.5rem' }}
      >
        <ModalStyle>
          <header>{t('Como cadastrar CNPJ?')}</header>
          <p>
            {t(
              'Você poderá solicitar a análise de abertura de conta CNPJ depois que tiver criado sua conta de Pessoa Física.',
            )}
          </p>
          <p style={{ marginBottom: '2rem' }}>
            {t('Ao acessar sua conta, basta clicar no botão ')}
            <strong>{t('"Crie sua conta PJ"')}</strong>
            {t(' e seguir o passo a passo.')}
          </p>
          <ButtonN
            type="primary"
            className="botao"
            onClick={() => {
              setModalCnpj(false);
            }}
            style={{ marginTop: '1rem' }}
          >
            {t('Entendido')}
          </ButtonN>
        </ModalStyle>
      </Modal>
    </Container>
  );
};

export default LinkToApp;
