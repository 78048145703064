import styled from 'styled-components';

export const ArticleContainer = styled.section`
  height: 160px; 
  overflow-y: auto;
  width: 300px;


`;

export const AccountOwner = styled.h3`
  font-weight: 700;
  color: #263238;
  font-size: 14px;
  line-height: 16px;

`;

export const CommonText = styled.p`
  color: #868686;
  font-size: 10px;
  line-height: 8px;
`;

export const SelectionContainer = styled.div`
  cursor: pointer;
`;
