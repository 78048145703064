import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;

  /* img {
    width: 100px;
  } */

  h3 {
    font-size: 23px;
    color: #636466;
    font-weight: 600;
  }
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    background: ${(props) => props.iconcolor};
    box-shadow: 0px 3px 6px #3FBFC329;

    svg {
      color: #fff;
    }
  }
`;

export const Title = styled.div`
  border-bottom: 2px solid ${(props) => props.separatorcolor};
  padding-bottom: 10px;

  span {
    color: #636466;
    font-weight: 600;
    font-size: 19px;
  }
`;

export const Content = styled.div`
  h4 {
    font-size: 25px;
    color: #636466;
    font-weight: 600;
    text-align: center;
  }
  section {
    margin-top: 10px;

    div {
      span {
        display: inline-block;
        text-align: left;
        margin-bottom: 5px;
        color: #636466;

        &:first-child {
          width: 30%;
          font-weight: 600;
        }
      }
    }

    &:last-child {
      border-bottom: 2px solid ${(props) => props.separatorcolor};
      padding-bottom: 10px;
    }
  }

  &:last-child {
    margin-top: 15px;
  }
`;
