import React from 'react';
import { useShop } from '../../hooks/shop';
import ColorInput from '../ColorInput';
import HeaderPreview from '../HeaderPreview';
import HelpModal from '../Help';
import {
  ColorInputWrapper, Container, Label, LabelWrapper, Wrapper,
} from './styles';

const ShopConfigColor = () => {
  const { shopInfo } = useShop();
  return (
    <Wrapper>
      <Container>
        <LabelWrapper>
          <Label>Cores da marca</Label>
          <p>*</p>
          <HelpModal type="colorPicker" />
        </LabelWrapper>
        <ColorInputWrapper>
          <ColorInput
            label="Cor primária da marca"
            name="primary_color"
            value={shopInfo.primary_color}
          />
          <ColorInput
            label="Cor secundária da marca"
            name="secondary_color"
            value={shopInfo.secondary_color}
          />
        </ColorInputWrapper>
      </Container>
      <Container>
        <Label>Preview da loja</Label>
        <HeaderPreview
          primaryColor={shopInfo.primary_color || '#521AA3'}
          secondaryColor={shopInfo.secondary_color || '#42C1C7'}
        />
      </Container>
    </Wrapper>
  );
};

export default ShopConfigColor;
