import styled from 'styled-components';

export const RowContainer = styled.div`
display: flex;
flex-flow: row nowrap;
justify-content: space-between;
width: 100%;
padding: 1rem;
border: solid #e8e8e8 0.5px;
border-radius: 10px;
margin-top: 0.5rem;
`;

export const InfoContainer = styled.div`
display: flex;
flex-flow: column nowrap;
justify-content: space-between;
`;
