import { Slider, Upload } from 'antd';
import styled from 'styled-components';
import Input from '../../../components/Input';

const { Dragger } = Upload;

export const Container = styled.div`

`;

export const Header = styled.div`
  color: #000000;
  text-align: center;
  padding: 8px 0;
  font-weight: bold;
  border-radius: 8px;
  font-size: 16px;
  position: relative;

  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 600;
    font-size: 21px;
  }
`;

export const Content = styled.div`
  padding: 26px;
  text-align: center; 
  font-weight: 400 !important;
  font-size: 15px;
  min-height: 110px;

  display: flex;
  align-items: center;
  flex-direction: column;
  
  p {
    font-size: 18px;
    font-weight: bold;
    color: #707070;
    margin-bottom: 0;
  }

  .value{
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #000;

    span {
      font-size: 26px;
      font-weight: bold;
      color: #3EBFC3;
    }

    div {
      height: 36px;
    }
  }
`;

export const InputNumber = styled(Input)`
  border: 0 !important;
  color: #707070 !important;
  font-weight: bold !important;
  font-size: 26px !important;
  padding: 0 !important;
  padding-left: 6px !important;
  width: 180px;
  height: 30px;
`;

export const ISlider = styled(Slider)`
  flex: 1;
  display: flex;

  .ant-slider-track{
    background-color: #3EBFC3;
  }

  .ant-slider-handle {
    margin-left: -9px;
    display: inline-block;
    border-color: #3EBFC3;
    background: #3EBFC3;
    margin-top: 8px;
    height: 18px;
    width: 18px;  
    border-radius: 0% 50% 50% 50%;
    transform: rotate(45deg) !important;
  }

  .ant-slider-handle:focus {
    box-shadow: 0 0 0 0 rgb(24 144 255 / 12%) !important;
  }

  :hover{
    .ant-slider-track{
      background-color: #3EBFC3;
    }

    .ant-slider-handle {
      margin-left: -9px;
      display: inline-block;
      border-color: #3EBFC3;
      background: #3EBFC3;
      margin-top: 8px;
      height: 18px;
      width: 18px;  
      border-radius: 0% 50% 50% 50%;
      transform: rotate(45deg) !important;
    }
  }
`;

export const DragAndDropContainer = styled.div`
  width: 100%;
  margin-top: 26px;
  margin-bottom: 26px;

  span > div.ant-upload.ant-upload-drag  {
      margin-top: 8px;
  }

  span > .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover{
    border-color: ${(props) => props.color};
  }
`;

export const IUpload = styled(Dragger)`
  width: 100%;
`;
