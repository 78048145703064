const addZero = (number) => {
  if (number.length <= 1) {
    return '0'.concat(number);
  }
  return number;
};
export const Day = addZero(new Date().getDate().toLocaleString());
export const RawMonth = new Date().getMonth() + 1;
export const Month = addZero(RawMonth.toLocaleString());
export const Year = new Date().getFullYear().toString().replace('.', '');
export const YearAge = new Date().getFullYear();
export const Hour = addZero(new Date().getHours().toLocaleString());
export const Min = addZero(new Date().getMinutes().toLocaleString());
export const CurrentDate = Year.concat('-', Month, '-', Day);
export const AssociateDate = Day.concat('/', Month, '/', Year);
export const CurrentTime = Hour.concat(':', Min);

const MinYear = (YearAge - 18).toLocaleString().replace('.', '');
export const CurrentDateAge = MinYear.concat('-', Month, '-', Day);

export const ageCalc = (dateBirtday) => {
  const dateNow = new Date();

  const anoAtual = dateNow.getFullYear();
  const mesAtual = dateNow.getMonth() + 1;
  const diaAtual = dateNow.getDate();

  const anoNascParts = dateBirtday.split('-');
  const diaNasc = +anoNascParts[2];
  const mesNasc = +anoNascParts[1];
  const anoNasc = +anoNascParts[0];

  let idade = anoAtual - anoNasc;

  const calculo = mesAtual < mesNasc || (mesAtual === mesNasc && diaAtual < diaNasc) ? (idade -= 1) : idade;

  return calculo;
};

export const yearBirtday = (dateBirtday) => {
  const anoNascParts = dateBirtday.split('-');
  return +anoNascParts[0];
};

export const monthBirtday = (dateBirtday) => {
  const anoNascParts = dateBirtday.split('-');
  return +anoNascParts[1];
};

export const dayBirtday = (dateBirtday) => {
  const anoNascParts = dateBirtday.split('-');
  return +anoNascParts[2];
};
