import styled from 'styled-components';

export const MainContainer = styled.div`
 display: flex;
 flex-flow: column nowrap;
 width: 100%;
 gap: 15px;
`;
export const HeaderContainer = styled.div`
 display: flex;
 flex-flow: column nowrap;
`;
export const InfoContainer = styled.div`
 display: flex;
 flex-flow: column nowrap;
 gap: 5px;
 align-items: flex-start;
`;
export const Title = styled.h4``;
export const Content = styled.span`
 text-align: left;
`;
export const ContentBold = styled.span`
 font-weight: bold;
`;
export const ButtonContainer = styled.div`
 margin-top: 15px;
 display: flex;
 flex-flow: row nowrap;
`;
