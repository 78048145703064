import styled from 'styled-components';

export const Section = styled.section`
display: flex;
flex-flow: column nowrap;
width: 65%;
gap: 2rem;
align-items: center;
@media screen and (min-width: 48.1rem) {
display: flex;
position: absolute;
left: 0rem;
top: 0rem;
height: 100vh;
background-color: rgba(15, 15, 15, 0.5);
overflow-y: scroll;
}
`;
export const Conditions = styled.span`
font-size: 0.8rem;
text-align: center;
margin-bottom: 0.5rem;
`;
export const Card = styled.section`
display: flex;
flex-flow: column nowrap;
gap: 1rem;
padding: 1rem;
margin-bottom: 1rem;
background: var(--white);
width: 100%;
min-height: ${(props) => props.minHeight};
max-width: 25rem;
border-radius: 0.7rem;
margin-top: 5%;
`;

export const Texts = styled.div`
@media screen and (min-width: 48.1rem) {
display: flex;
flex-flow: column nowrap;
align-items: center;
margin-top: 1rem;
}
`;

export const MainTitle = styled.span`
font-size: 1.2rem;
font-weight: bold;
width: 60%;
text-align: center;
display: flex;

@media screen and (min-width: 48.1rem) {
color: var(--white);
padding-top: 1rem;
}
`;

export const Subtitle = styled.div`
padding: 1rem;
text-align: center;
display: flex;
margin-bottom: 1rem;
max-width: 30rem;

@media screen and (min-width: 48.1rem) {
color: var(--white);
padding-top: 1.5rem;
margin-bottom: 0;
position: absolute;
margin-top: 3%;
}
`;

export const Options = styled.section`
display: flex;
gap: 2rem;
flex-flow: column nowrap;
justify-content: center;
height: 100%;
`;

export const Option = styled.span`
display: flex;
width: 100%;
font-weight: bold;
justify-content: space-between;
border-bottom: 0.16rem solid var(--primary);
align-items: center;
margin-bottom: 1rem;
cursor: pointer;
`;

export const Container = styled.div`
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
height: 100%;
h1 {
color: #393939;
font-size: 1rem;
margin: 20px;
width: 300px;
text-align: center;
@media screen and (min-width: 48.1rem) {
display: none;
}
}
`;

export const Box = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
gap: 2rem;
align-items: center;
width: 100%;
height: 100%;

@media screen and (max-width: 48.1rem) {
background: var(--label);
width: 15rem;

align-items: center;
border-radius: 10px;
}

h2 {
display: flex;
font-size: 0.9rem;
font-weight: bold;
justify-content: center;

@media screen and (max-width: 48.1rem) {
color: var(--white);
margin: 0.5rem;
}
}
`;

export const DropContainer = styled.div`
display: flex;
align-items: end;
justify-content: center;
`;

export const DropButton = styled.div`
display: flex;
justify-content: center;
height: 5rem;
width: 5rem;
margin-left: 10px;
border-radius: 50%;
font-size: 80%;
align-items: center;
background: var(--primary);
color: var(--white);
cursor: pointer;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
'Helvetica Neue', sans-serif;
font-style: normal;
font-weight: bold;
`;

export const Displayer = styled.section`
position: relative;
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
justify-content: center;
`;
export const Preview = styled.img`
margin: 0 !important;
max-height: 100% !important;
max-width: 100% !important;
background-repeat: no-repeat;
object-fit: cover;
background-image: ${(props) => props.src};
`;
export const Buttons = styled.div`
display: flex;
align-items: center;
flex-direction: row;
gap: 0.5rem;
width: 100%;
margin-top: 1rem;
@media screen and (min-width: 48.1rem) {
width: 50%;
margin-bottom: 1rem;
}
`;

export const Text = styled.span`
align-items: center;
font-size: 1rem;
padding-bottom: 1.4rem;
display: flex;
gap: 0.5rem;
`;

export const Hide = styled.section`
@media screen and (max-width: 48.1rem) {
}
`;

export const Tips = styled.div`
display: flex;
gap: 0.5rem;
align-items: left;
flex-direction: column;
`;

export const Cartao = styled.div`
position: absolute;
left: 0rem;
top: 0px;
padding: 1rem;
width: 100%;
`;
export const Title = styled.span`
font-size: 1rem;
font-weight: 700;
text-align: center;
margin-bottom: 1rem;
`;

export const ModalTitle = styled.div`
text-align: center;
align-items: center;
padding: 0 auto;
font-size: 1.5rem;
margin-bottom: 1rem;
font-weight: bold;
`;

export const SubtitleModal = styled.div`
height: 50%;
width: 100%;
margin: 0;
font-size: 1rem;
margin-bottom: 2rem;
`;

export const TextModal = styled.div`
font-size: 1rem;
height: 50%;
width: 100%;
margin: 0;
margin-bottom: 1rem;
`;

export const DocumentsTextContainer = styled.div`
display: flex;
gap: 1rem;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
`;

export const Overlay = styled.section`
 position: absolute;
 background-color: #000000ab;
 height: 100%;
 width: 100%;
 opacity: 0 !important;
 cursor: pointer;
 &:hover {
 opacity: 1 !important;
 display: flex;
 flex-direction: row;
 align-items: center;

 span {
 margin: 1rem;
 text-align: center;
 color: white;
 font-weight: bold;
 }
 }
`;
