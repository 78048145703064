/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect } from 'react';
import Cleave from 'cleave.js/react';
import { cpf, cnpj } from 'cpf-cnpj-validator';

import PropTypes from 'prop-types';
import { InputPropTypes, DefaultPropTypes } from './Types';
import { Container, StyledInput } from './styles';

const CpfCnpj = ({
  setIsValid, value, className, nullClass, isValid ,...rest
}) => {
  const optionsCPF = { blocks: [3, 3, 3, 3], delimiters: ['.', '.', '-'], numericOnly: true };
  const optionsCNPJ = { blocks: [2, 3, 3, 4, 2], delimiters: ['.', '.', '/', '-'], numericOnly: true };
  const validCpfCnpj = value?.length <= 0 || /\d{11}/g.test(value) || /\d{14}/g.test(value);
  useEffect(() => {
    if (typeof setIsValid === 'function') {
      if (cpf.isValid(value) || cnpj.isValid(value)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [value, setIsValid]);
  return (
    <Container>
      {!!rest.label && <label htmlFor={rest.id}>{rest.label}</label>}
      <StyledInput
      isValid={!validCpfCnpj || isValid}
        className={`ant-input ${className} ${
          !nullClass
&& (value?.length === 11 || value?.length === 14
  ? cpf.isValid(value) || cnpj.isValid(value)
    ? 'is-valid'
    : 'is-invalid'
  : '')
        }`}
      >
        {(!value || value?.length < 12) && (
        <Cleave {...rest} value={value} className="ant-input" options={optionsCPF} autoFocus />
        )}
        {value?.length > 11 && (
        <Cleave {...rest} value={value} className="ant-input" options={optionsCNPJ} autoFocus />
        )}
        <i />
      </StyledInput>
    </Container>
  );
};

export default CpfCnpj;

CpfCnpj.propTypes = {
  ...InputPropTypes,
  value: (props) => props.value === undefined && new Error('Value necessário para validação!'),
  setIsValid: (props) => props.setIsValid && typeof props.setIsValid !== 'function' && new Error('setIsValid deve ser uma função!'),
  nullClass: PropTypes.bool,
};

CpfCnpj.defaultProps = {
  ...DefaultPropTypes,
  value: null,
  setIsValid: undefined,
  nullClass: false,
};
