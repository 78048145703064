/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  Container, Title, Text, ButtonDisposition, TextContainer,
} from './styles';
import { useRefresh } from '../../../hooks/refresh';
import { useSettings } from '../../../hooks/settings';
import ButtonN from '../../../components/ButtonN';
import { useSteps } from '../../../hooks/steps';
import { useCheckpoint } from '../../../hooks/checkpoint';

export function UpdateDone() {
  const { resetStep } = useSteps();
  const { resetCheckpoint } = useCheckpoint();
  const { basicData } = useRefresh();
  const { settings } = useSettings();
  const { name } = basicData;
  return (
    <>
      <Container>
        <TextContainer>
          <FontAwesomeIcon icon={faCheck} className="fa-lg" />
          <Title>
            <span style={{ color: 'var(--primary)' }}>{name || 'Usuário'}</span>, sua conta foi atualizada!
          </Title>
          <Text>
            {`Boas-vindas ao banco ${settings.display_name}.
Aqui você tem toda praticidade e comodidade na palma da sua mão.`}
          </Text>
        </TextContainer>
        <ButtonDisposition>
          <ButtonN
            className="botao"
            onClick={() => {
              resetStep();
              resetCheckpoint();
            }}
          >
            <Link to="/"> Acessar meu banco</Link>
          </ButtonN>
          <ButtonN type="primary" className="botao">
            <Link to="/"> Baixar o aplicativo</Link>
          </ButtonN>
        </ButtonDisposition>
      </Container>
    </>
  );
}
