import styled from 'styled-components';
import femaleBlogger from '../../assets/female-blogger.png';

export const ImgContainer = styled.div`
--url-teste: url(${({ settings }) => settings.ib_background_img || femaleBlogger});
background: ${({ showGradient }) => (showGradient
    ? 'linear-gradient(0deg, #191a1ad9 19.09%, rgba(2, 3, 3, 0) 68.1%)'
    : 'none')}, var(--url-teste);
background-size: cover;
background-repeat: no-repeat;
width: 65%;
height: 100%;
flex: 1;
`;

export const MainContainer = styled.div`
display: flex;
width: 100vw;
height: 100vh;
`;

export const Content = styled.div`
display: flex;
overflow: scroll;
overflow-x: hidden;
overflow-y: auto;
justify-content: space-between;
flex-flow: column nowrap;
align-items: center;
background-color: #ffffff;
padding: 2.5rem 1rem 2.5rem 1rem;
/* position: absolute;
right: 0px; */
width: 35%;
height: 100%;
`;

export const Logo = styled.img`
width: 25%;
margin-bottom: 2rem;
`;

export const Container = styled.div`
display: flex;
flex-flow: column nowrap;
align-items: center;
justify-content: space-between;
gap: 1rem;
width: 80%;
height: 90%;
`;

export const InputContainer = styled.div`
width: 100%;
display: flex;
flex-flow: column nowrap;
gap: 1rem;
`;

export const ButtonContainer = styled.div`
width: 100%;
display: flex;
flex-flow: column nowrap;
gap: 1rem;
`;

export const TextContainer = styled.div`
display: flex;
flex-flow: column nowrap;
gap: 1rem;
`;

export const Title = styled.p`
font-weight: 700;
text-align: center;
font-size: 1.5rem;
`;

export const Subtitle = styled.p`
font-weight: 700;
font-size: 1.25rem;
`;

export const Text = styled.p`
font-weight: 400;
font-size: 1rem;
`;

export const TextBold = styled.p`
font-weight: 700;
font-size: 1rem;
`;

export const LightText = styled.p`
font-weight: 400;
color: #6f6f6f;
font-size: 1rem;
`;

export const PrimaryLink = styled.a`
font-weight: 700;
color: var('--primary-color');
font-size: 1rem;
`;

export const ModalStyle = styled.div``;
