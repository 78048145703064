import api from '../api';

export const handleOpPasswordRequisition = async (obj) => {
  const token = localStorage.getItem('@qesh:token');
  const headers = { authorization: `bearer ${token}` };
  const body = {
    transaction_password: obj,
    is_pool: false,
  };

  return api
    .post('onboarding/account', body, { headers })
    .then((response) => ({ status: true, data: response }))
    .catch((error) => {
      console.error('erro request pass', error.data.response.data.message);
      return { status: false, data: error };
    });
};
