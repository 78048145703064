import styled from 'styled-components';

export const Wrapper = styled.div`
  width:100vw;
  min-height:100vh;
  height:fit-content;
  display:flex;
  align-items:center;
  justify-content:center;
  gap:42px;
  background-color:#F4F4F4;
`;

export const EditorWrapper = styled.div`
  width:70vw;
  height: 90vh;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
`;

export const MdBoxWrapper = styled.div`
  width:100%;
  height:85%;
  display:flex;
  justify-content:space-between;
  align-items:center;
`;

export const MdBox = styled.div`
  width:48%;
  height:100%;
  border-radius:15px;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:#FFFFFF;
  overflow: auto;
  position:relative;

`;

export const ContentLabel = styled.div`
  width:fit-content;
  height:fit-content;
  position:absolute;
  font-weight:bold;
  padding:0 15px;
  top:18px;
  left:0;
  right:0;
  margin:auto;
  background-color:#FFFFFF;
`;

export const ContentWrapper = styled.div`
  width:85%;
  height:85%;
  border:1px solid #E0E0E0;
  overflow: auto;
  padding: 15px 0;

  display:flex;
  flex-direction:column;
  align-items:center;
  textarea{
    width:90%;
    height:85%;
    resize:none;
    border:none;
  }
`;

export const RenderedMdBox = styled.div`

  width:85%;
  height:85%;
  border:1px solid #E0E0E0;
  overflow: auto;
  padding: 0 30px;

  display:flex;
  justify-content:flex-start;
  align-items:center;
`;

export const RenderedMd = styled.div`
  width: fit-content; 
  max-width: 100%; 
  height: fit-content; 
  align-self: flex-end;
  background-color:red;
  line-break: anywhere; 
  padding: 30px;
  overflow: auto;
`;
export const IconsWrapper = styled.div`
  width:100%;
  height:15%;
  display:flex;
  justify-content:space-evenly;
  align-items:center;
`;

export const Icon = styled.button`
  width: 11%;
  aspect-ratio: 1.3/1;
  border-radius:5px;
  background-color: #E0E0E0;
  border:none;
  color: #6F6F6F;
  font-size:0.7rem;
  font-weight:bold;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;

  :active{
    background-color:#6F6F6F;
    color:#FFFFFF;
  }
`;

export const SaveFileWrapper = styled.div`
  width:100%;
  min-height:13%;
  border-radius:5px;
  background-color:#FFFFFF;
  padding:0 0 5px 0;
  gap:15px;
  display:flex;
  justify-content:center;
  align-items:flex-end;
`;

export const InfoBox = styled.div`
  width:15%;
  height:fit-content;
  display:flex;
  flex-direction:column;
  gap:10px;
`;

export const Button = styled.button`
  width:20%;
  border:none;
  height: 40px;
  border-radius:5px;
  background-color:#42C1C7;
  color:#FFFFFF;
  font-weight:bold;
`;
