import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  background-image: linear-gradient(
    to right,
    ${(props) => props.primaryColor},
    ${(props) => props.secondaryColor}
  );
  box-shadow: 0px 0px 0px 8px var(--input-gray);
  user-select: none;
`;

export const Content = styled.div`
  width: 100%;
  height: 65%;
  gap: 20px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
`;

export const SideBar = styled.div`
  width: 20%;
  height: 100%;
  background-color: var(--white);
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const Logo = styled.img`
  width: 35%;
  aspect-ratio: 2.5/1;
`;

export const StoreStatus = styled.div`
  width: 30%;
  height: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: green;
  font-size: 0.6rem;
`;

export const GreenStatus = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: green;
`;

export const HomeButton = styled.div`
  padding: 13px 10px;
  border-radius: 5px;
  color: var(--white);
  font-size: 0.7rem;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  :-webkit-backdrop-filter {
    backdrop-filter: blur(10px);
  }
`;

export const Category = styled.span`
  font-size: 0.7rem;
  color: var(--white);
  font-weight: bold;
  margin-left: 25px;
`;

export const SearchInput = styled.div`
  width: 30%;
  height: 55%;
  padding: 0 10px;
  display: flex;
  background-color: var(--white);
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  margin-left: 25px;

  span {
    color: var(--dark-gray);
    font-size: 0.6rem;
    font-weight: bold;
  }
`;

export const LoginButton = styled.div`
  width: 17%;
  height: 60%;
  border: 1px solid var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: var(--white);
  font-size: 0.6rem;
  margin-left: 25px;
`;

export const Cart = styled.div`
  min-width: 10%;
  min-height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: var(--white);
  font-size: 0.6rem;
`;
