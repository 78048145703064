import React from 'react';
import { Bar, Label, Wrapper } from './styles';

const Breaker = ({ label }) => (
  <Wrapper>
    <Bar />
    <Label>{label}</Label>
    <Bar />
  </Wrapper>
);

export default Breaker;
