import styled from 'styled-components';
import { Upload } from 'antd';

const { Dragger } = Upload;

export const Content = styled.div`
  max-width: 1060px;
  width: 100%;
  padding-bottom: 200px;
`;

export const TopicMarker = styled.div`
  width: 10px;
  background-color: #E6E6E6;
  height: 20px;
  margin-right: 5px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

export const IUpload = styled(Dragger)`
  width: 100%;
`;
