import styled from 'styled-components';

export const MainContainer = styled.div`
 background: #ffff;
 border-radius: 24px;
 padding: 48px 96px;
 display: flex;
 flex-direction: column;
 align-items: center;
 margin: 0 auto;
 flex: 1;
 height: fit-content;
 max-width: 60rem;
 min-width: 15rem;

 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
 -webkit-appearance: none;
 margin: 0;
 }

 /* Firefox */
 input[type='number'] {
 -moz-appearance: textfield;
 }
`;

export const Content = styled.div`
 display: flex;
 flex-direction: column;
 width: 100%;
 text-align: center;
 cursor: auto;
`;

export const CenterContainer = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 margin: 5% 0;
`;

export const TitleHeader = styled.h1`
 font-size: 1.8rem;
 font-weight: bold;
 line-height: 1rem;
 margin: 0;
 text-align: left;
`;

export const HeaderArea = styled.div`
 margin-bottom: 0.8rem;
 margin-top: 0.8rem;
`;

export const HeaderSubtitle = styled.h3`
 font-style: normal;
 font-weight: normal;
 font-size: 1rem;
 line-height: 150%;
 letter-spacing: 0.0025em;
 margin-top: 1rem;
 color: #6f6f6f;
`;
export const ContentBody = styled.div`
 margin: 3% 0 0 0;
`;

export const Title = styled.div`
 font-style: normal;
 font-weight: bold;
 font-size: 1.5rem;
 line-height: 120%;
 text-align: center;
 color: #393939;
 width: 80%;
`;
export const Subtitle = styled.div`
 font-style: normal;
 font-weight: normal;
 font-size: 1rem;
 line-height: 150%;
 text-align: center;
 letter-spacing: 0.0025em;
 width: 80%;
 color: #393939;
`;

export const TitleArea = styled.div`
 display: flex;
 gap: 0;
 width: 20rem;
 align-items: center;
 flex-direction: column;
 gap: 1rem;
`;

export const ContainerButtom = styled.div`
 display: flex;
 flex-direction: column;
 margin: auto;
 width: 60%;
 gap: 2;
`;

export const InputArea = styled.div`
 width: 60%;
 min-width: 20rem;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
`;

export const ButtonArea = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 gap: 0;
 width: 60%;
 min-width: 20rem;
 margin-top: -1rem;
`;
export const LoadingContainer = styled.div`
 /* display: flex; */
 height: 100%;
 justify-self: center;
 margin-top: 30%;
`;
