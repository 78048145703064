import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 47%;
  height: 73px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  user-select: none;
`;

export const LabelWrapper = styled.div`
  width: 100%;
  height: 45%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-size: 14px;

  span {
    font-weight: bold;
  }
  p {
    color: red;
    margin:0;
    padding:0;
  }
`;

export const Input = styled.div`
  width: 100%;
  height: 55%;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  position: relative;
  z-index: 1;
`;
export const OptionsWrapper = styled.div`
  width: 100%;
  height: 200px;
  border-top: none;
  position: absolute;
  top: 40px;
  left: 0;
  border: 1px solid #D9D9D9;
  display: flex;
`;

export const Options = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  background-color: #FFFFFF;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const Option = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  background-color: ${(props) => (props.isDisabled ? '#D9D9D9' : 'transparent')};

  :hover {
    background-color: #D9D9D9;
  }
`;
