import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: none;
  
  ${(props) => props.hasLoading
    && css`
      display: flex;
      background: rgba(255,255,255,0.7);
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99999;

      span,
      img {
        width: 80px;
        position: absolute;
        top: calc(50% - 80px / 2);
        left: calc(50% - 80px / 2);
      }

      div {
        width: 180px;
        height: 180px;
        animation: spinloading 2s infinite linear;
        display: block;
        transform-origin: 55% 50%;
        z-index: 999;
        background: url('https://app.qesh.ai/static/media/circular-arrow-with-dots.e69b33e5.svg');
        background-size: cover;

        position: fixed;
        top: calc(50% - 180px / 2);
        left: calc(50% - 180px / 2);


        @keyframes spinloading {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    `}
`;
