/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import axios from 'axios';
import { federationUnitList } from '../utilitys/federationUnitList';

function getAddress() {
  const getAddressWithCep = async (zipCode, addressObject) => axios
    .get(`https://viacep.com.br/ws/${zipCode.replace(/\D/g, '')}/json/`)
    .then((res) => {
      const {
        erro, bairro, localidade, logradouro, uf,
      } = res.data;
      if (!erro) {
        let ufObject = null;
        const getUfName = (uf) => {
          const findTheFederationUnit = federationUnitList.filter((state) => {
            if (state.sigla === uf) {
              return { name: state.name, id: state.sigla };
            }
          });
          ufObject = { name: findTheFederationUnit[0].name, initials: findTheFederationUnit[0].sigla };
        };
        getUfName(uf);
        return {
          ...addressObject,
          zip_code: zipCode,
          street: logradouro,
          neighborhood: bairro,
          city: localidade,
          state: ufObject,
        };
      }
      throw new Error();
    })
    .catch((_err) => ({ error: true }));

  const getAddressWithStateInitials = async (stateInitials) => axios
    .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateInitials}/municipios?orderBy=nome`)
    .then((res) => {
      if (res.data.length !== 0) {
        return res.data;
      }
    })
    .catch((err) => {
      console.error(`ops! ocorreu um erro: ${err}`);
    });

  const getCep = async (cep) => axios
    .get(`https://viacep.com.br/ws/${cep.replace(/\D/g, '')}/json/`)
    .then((res) => {
      const {
        erro, bairro, localidade, logradouro, uf,
      } = res.data;
      if (!erro) {
        return {
          zip_code: cep,
          street: logradouro,
          neighborhood: bairro,
          city: localidade,
          state: uf,
        };
      }
    });
  return { getAddressWithCep, getAddressWithStateInitials, getCep };
}

export { getAddress };
