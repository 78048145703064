import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import ButtonN from '../../../../components/ButtonN';
import { useRefresh } from '../../../../hooks/refresh';
import {
  ButtonContainer,
  Container,
  InputContainer,
  LightText,
  PrimaryLink,
  Subtitle,
  TextContainer,
  Title,
} from '../../styles';
import PasswordDigits from '../../../../components/PasswordDigits';
import { useSettings } from '../../../../hooks/settings';
import getMasks from '../../utils/getMasks';
import messages from '../../../../components/MessageError';

export function Code({ type }) {
  const {
    setStep, refreshApi, user, basicData,
  } = useRefresh();
  const [code, setCode] = useState('');
  const { settings } = useSettings();

  const handleNext = async () => {
    try {
      const body = {
        client_name: settings.name,
        document: user.document,
        contact: type === 'email'
          ? basicData.email
          : `${basicData.phone.substring(0, 2)} ${basicData.phone.substring(2)}`,
      };
      const typeContact = type === 'email' ? 'email' : 'cellphone';
      await refreshApi.validate(body, typeContact, code);
      const successMessage = type === 'email'
        ? 'Email validado com sucesso!'
        : 'Número de telefone validado com sucesso!';
      message.success(successMessage);
      setStep('contact');
    } catch (error) {
      if (error.message.includes('Falha na validação') || error.message.includes('Token inválido')) {
        message.error(messages.codeInvalid);
        return;
      }
      if (error.message.includes('Nenhum token gerado')) {
        message.error('Nenhum token gerado para o contato informado ou código expirado');
        return;
      }
      message.error(error.message);
    }
  };

  const handleResend = async () => {
    try {
      const body = {
        client_name: settings.name,
        document: user.document,
        contact: type === 'email'
          ? basicData.email
          : `${basicData.phone.substring(0, 2)} ${basicData.phone.substring(2)}`,
      };
      const typeContact = type === 'email' ? 'email' : 'cellphone';
      await refreshApi.sendCode(body, typeContact);
      message.success(
        `Código reenviado com sucesso para ${
          type === 'email' ? basicData.email : getMasks('phone', basicData.phone)
        }`,
      );
    } catch (error) {
      if (error.message.includes('many trys')) {
        message.error('Número máximo de tentativas excedidas, tente novamente amanhã');
        return;
      }
      message.error(error.message);
    }
  };

  const handleBack = () => {
    setStep(type);
  };

  const buttonChangeText = type === 'email' ? 'Alterar e-mail' : 'Alterar número de celular';
  const subtitleText = type === 'email' ? 'Confirme seu e-mail' : 'Confirme seu número de celular';
  const lightText = type === 'email'
    ? 'Para continuar, insira o código que nós acabamos de enviar para seu e-mail:'
    : 'Para continuar, insira o código que nós acabamos de enviar para seu número de telefone:';

  const contact = type === 'email' ? basicData.email : basicData.phone;
  return (
    <Container>
      <InputContainer>
        <TextContainer>
          <Title>Só mais alguns passos...</Title>
          <Subtitle>{subtitleText}</Subtitle>
          <LightText>
            {lightText} <span style={{ fontWeight: 700 }}>{getMasks(type, contact)}</span>
          </LightText>
        </TextContainer>
        <PasswordDigits showNumbers setPasswordValue={setCode} numOfDigits={5} />
        <PrimaryLink onClick={handleResend} style={{ textAlign: 'center' }}>
          Reenviar código
        </PrimaryLink>
      </InputContainer>
      <ButtonContainer>
        <ButtonN type="default" style={{ borderColor: 'var(--primary)' }} onClick={handleBack}>
          {buttonChangeText}
        </ButtonN>
        <ButtonN disabled={code.length < 5} type="primary" onClick={handleNext}>
          Confirmar
        </ButtonN>
      </ButtonContainer>
    </Container>
  );
}

Code.propTypes = {
  type: PropTypes.oneOf(['phone', 'email']).isRequired,
};
