/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */

import React, { useCallback, useEffect, useState } from 'react';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Skeleton, notification } from 'antd';
import { addHours, format, parseISO } from 'date-fns';
import { Row, Col } from '../../../../../styles/components';
import Input from '../../../../../components/Input';
import { ChargeSubtitle } from '../../../styles';
import { TopicMarker } from '../../styles';
import PopOverPrevious from '../../../../../components/PopOverPrevious';
import { useSettings } from '../../../../../hooks/settings';
import api from '../../../../../services/api';
import { documentMask, HandleFormatMoney } from '../../../../../services/utils';
import Button from '../../../../../components/Button';
import ButtonText from '../../../../../components/ButtonText';

const CardFields = ({
  amount, setAmount, formRef, card, credentials, setMaxInstallments, maxInstallments,
}) => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const [rerender, setRerender] = useState(false);
  const [latestCharged, setLatestCharged] = useState([]);
  const [selectedCharged, setSelectedCharged] = useState(null);
  const [document, setDocument] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const changeValue = useCallback((event, maskedvalue, floatValue) => {
    setAmount(floatValue);
  }, [setAmount]);

  function formatDate(date) {
    return format(parseISO(date), 'dd/MM/yyyy');
  }

  const loadCpfData = useCallback(async () => {
    try {
      const { data } = await api.get(`https://bank.qesh.ai/api-id/${document}`, {
        headers: {
          client: settings.client_id,
        },
      });
      setTimeout(() => {
        const form = formRef.current;
        form.setData({
          name: data.name || data.trading_name,
        });
      }, 500);
    } catch (err) {
      console.log(err);
    }
    setShowSkeleton(false);
  }, [document, settings, formRef]);

  useEffect(() => {
    if (document && ((document.length === 11 && cpf.isValid(document))
    || (document.length === 14 && cnpj.isValid(document))) && !selectedCharged) {
      loadCpfData();
    }
  }, [document, loadCpfData, selectedCharged]);

  const loadLatestCharged = useCallback(async () => {
    try {
      const { data: latest } = await api.get('charges/last-charged');
      setLatestCharged(latest);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    loadLatestCharged();
  }, [loadLatestCharged]);

  const handleLastChargedSelection = useCallback((item) => {
    setSelectedCharged(item);
    setDocument(item.charged_document);
    if (formRef && formRef.current) {
      const form = formRef.current;
      setTimeout(() => {
        form.setFieldValue('name', item.charged_name);
        if (item.charged_email) {
          form.setFieldValue('email', item.charged_email);
        }
        if (item.charged_phone) {
          form.setFieldValue('phone', item.charged_phone);
        }
      }, 500);
    }
  }, [formRef]);

  return card ? (
    <>

      <div style={{
        maxWidth: '600px',
        background: '#f5f5f5',
        borderRadius: '4px',
      }}
      >
        <div style={{
          background: settings.colors.primary,
          padding: '5px',
          borderRadius: '4px',
          color: '#F9F9F9',
          textAlign: 'center',
          fontWeight: 600,
          fontSize: 17,
        }}
        >
          {t('dados da cobranca por cartao de credito')}
        </div>

        <div style={{ padding: '24px', textAlign: 'center' }}>
          <div style={{
            fontSize: 18,
            fontWeight: 600,
            textAlign: 'center',
            letterSpacing: '1px',
          }}
          >
            {t('cobranca gerada com sucesso')}
          </div>
          <div>
            <a
              style={{ textAlign: 'center' }}
              href={`${settings.payment_url || 'https://payment.qesh.ai'}/card/${card.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >{`${settings.payment_url || 'https://payment.qesh.ai'}/card/${card.id}`}
            </a>
          </div>

          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: 16, margin: '15px 0' }}>
              <CopyToClipboard
                text={`${settings.payment_url || 'https://payment.qesh.ai'}/card/${card.id}`}
                onCopy={() => {
                  notification.success({
                    message: t('link pagamento cartao de credito'),
                    description:
                                t('link para pagamento em cartao...'),
                  });
                }}
              >
                <ButtonText>
                  {t('toque aqui para copiar')}
                </ButtonText>
              </CopyToClipboard>
            </div>

            <div style={{ marginBottom: '15px' }}>
              <div>{t('valor')} <span style={{ fontWeight: 600 }}>{HandleFormatMoney(card.amount)}</span></div>
              <div>{t('vencimento')}{' '}
                <span style={{ fontWeight: 600 }}>
                  {formatDate(addHours(new Date(card.due_at), 3).toISOString())}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  ) : (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">

          <Input
            mask="currency"
            id="value"
            name="value"
            value={amount}
            onChangeEvent={changeValue}
            label={t('valor da cobranca')}
            type="text"
          />
        </Col>
      </Row>
      <Row gutter={16} hidden={amount === null}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
          <Input
            id="date"
            name="date"
            label={t('data de vencimento')}
            type="date"
            className="bottom"
            onChange={() => { setRerender(!rerender); }}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom" hidden={credentials.platform !== 'GETNET'}>
          <Input
            select
            id="max_installments_allowed"
            name="max_installments_allowed"
            label={t('numero maximo de parcelas')}
            onChange={(e) => setMaxInstallments(e)}
            value={maxInstallments}
            options={[
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
              { label: '5', value: 5 },
              { label: '6', value: 6 },
              { label: '7', value: 7 },
              { label: '8', value: 8 },
              { label: '9', value: 9 },
              { label: '10', value: 10 },
              { label: '11', value: 11 },
              { label: '12', value: 12 },
            ]}
            type="text"
          />
        </Col>
      </Row>
      <Row hidden={!formRef.current || !formRef.current.getFieldValue('date')} gutter={16}>
        <Col xs={20} sm={20} md={20} lg={20} xl={20} className="bottom">
          <Input
            id="description"
            name="description"
            label={t('numero maximo de parcelas')}
            className="bottom"
            onChange={() => { setRerender(!rerender); }}
          />
        </Col>
      </Row>

      <Col
        hidden={!formRef.current || !formRef.current.getFieldValue('description')}
        xs={20}
        sm={20}
        md={20}
        lg={20}
        xl={20}
        className="bottom"
      >
        <ChargeSubtitle primaryColor={settings.colors.primary}>
          {t('quem sera cobrado')}
        </ChargeSubtitle>
        <div style={{
          display: 'flex', alignItems: 'center', marginBottom: 30,
        }}
        >
          <TopicMarker />
          <h3 style={{ marginTop: 'auto', marginBottom: 'auto' }}>{t('insira os dados da pessoa que sera cobrada')}</h3>
          <PopOverPrevious
            handleSelection={handleLastChargedSelection}
            primaryColor={settings.colors.primary}
            data={latestCharged}
          />
        </div>
      </Col>

      <Row hidden={!formRef.current || !formRef.current.getFieldValue('description')} gutter={16}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
          <Input
            id="document"
            name="document"
            label={t('cpf / cnpj')}
            type="text"
            value={documentMask(document)}
            onChange={(e) => { setSelectedCharged(null); setDocument(e.target.value?.replace(/[^0-9]/g, '')); }}
          />
        </Col>
      </Row>
      { document && document.length >= 11 && !showSkeleton
        && formRef.current.getFieldValue('description') ? (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={20} lg={20} xl={20} className="bottom">
                <Input id="name" name="name" label={t('nome completo')} type="text" />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={10} lg={10} xl={10} className="bottom">
                <Input id="email" name="email" label={t('email')} type="text" />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
                <Input
                  mask="99 999999999"
                  name="phone"
                  id="phone"
                  label={t('celular')}
                  type="phone"
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={24}>
                <Button right type="submit">
                  <span>{t('gerar cobranca')}</span>
                </Button>
              </Col>
            </Row>

          </>

        ) : (<></>)}
      <Skeleton active title={t('carregando')} loading={showSkeleton} paragraph={2} rows={2} />
      <Skeleton active title={t('carregando')} loading={showSkeleton} paragraph={2} rows={2} />
      <Skeleton active title={t('carregando')} loading={showSkeleton} paragraph={2} rows={2} />
    </>
  );
};
export default CardFields;
