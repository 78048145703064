import React from 'react';
import { Modal as ModalAntd } from 'antd';
import { WarningDisplay } from './style';

const index = ({
  children, title, visibility, setVisibility, maskClosable,
}) => (
  <ModalAntd
    visible={visibility}
    centered
    footer={null}
    closable={false}
    focusTriggerAfterClose={false}
    onCancel={() => {
      setVisibility(false);
    }}
    maskClosable={maskClosable}
  >
    <WarningDisplay>
      {title}
      {children}
    </WarningDisplay>
  </ModalAntd>
);

export default index;
