import styled from 'styled-components';
import { Upload } from 'antd';

const { Dragger } = Upload;

export const Content = styled.div`
  /* max-width: 1060px; */
  width: 100%;
  margin: 5 auto 5 auto;

`;

export const IUpload = styled(Dragger)`
  width: 100%;
  display: flex;
  justify-content: center;
  
  span > img {
    max-height: 150px;
  }
`;
