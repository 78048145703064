import styled, { css } from 'styled-components';
import { Progress } from 'antd';

export const Content = styled.div`
  max-width: 1060px;
  width: 100%;
  margin: 5 auto 5 auto;
  padding-bottom: 150px;

  h2 {
    color: #121212;
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .ant-table-thead > tr > th {
    font-weight: 600 !important;
  }

  .ant-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .ant-table-filter-trigger {
    align-self: center;
    padding-left: 5px;
  }

`;

export const FilterDate = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;

  section {
    display: flex;
    align-items: center; 
  }

  svg {
    color: #46cdd4;
    margin-right: 15px;
    cursor: pointer;
  }

  span {
    font-weight: 600;
    padding: 0px 0px;
    padding-right: 5px;
  }

  .DayPickerInput {
    input {
      border: 0;
      width: 82px;
      cursor: pointer;
    }
  }
`;

export const SelectionButton = styled.button`
    display: flex;
    align-items: center;
    color: ${(props) => (props.active ? 'white' : props.primaryColor)};
    font-weight: 600;
    font-size: 15px;
    padding: 5px 7px;
    border-radius: 40px;
    background: white;
    border: ${(props) => `2px solid ${props.primaryColor}`};
    min-width: 100px;
    justify-content: center;
    margin: 10px;
    margin-left: 0px;
    ${(props) => props.active && css`
      background: ${props.primaryColor};
    `}

    cursor: pointer;
`;

export const ChargeSubtitle = styled.span`
  font-size: 18px;
  margin: 15px;
  margin-left: 0;
  color: ${(props) => props.primaryColor || 'black'};
`;

export const CenterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.primaryColor || 'black'};
  font-size: 14px;
  margin: 20px 0;
  font-weight: bold;
  cursor: pointer;

  .showHideCredentials {
    font-size: 16px;
  }
`;

export const GraphsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%; 
  margin-top: 20px;
  background-color: #FAFAFA; 
  padding: 20px;
  margin: 10px;
`;

export const TypesContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  width: calc(50% - 5px); 
  /* margin-top: 20px; */
  background-color: #FAFAFA; 
  padding: 20px;
`;

export const InfosContainer = styled.div`
  color: #707070;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin: 10px;
  margin-top: 0;
`;

export const TypeContainer = styled.div`

  display: flex;

  .info {
    margin-left: 10px;
    display: flex;
    flex-flow: column;
  }

  .type-count {
    line-height: 1;
    font-size: 20px;
    font-weight: bold;
  }

  .type-name {
    font-size: 15px;
    font-weight: bold;
  }

  .type-value {
    font-size: 15px;
  }
`;

export const GraphContent = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row;
`;

export const PercentagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 95%;

  .percentage {
    color: #707070;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    margin-right: 20px;
  }

  .percentage-container{
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .data-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;
    justify-content: space-between;
  }
`;

export const GraphPart = styled.div`
  width: ${(props) => props.percentage}%;
  height: 10px;
  background-color: ${(props) => props.color};
  border-radius: ${(props) => props.leftRadius || 0}px
   ${(props) => props.rightRadius || 0}px 
   ${(props) => props.rightRadius || 0}px 
   ${(props) => props.leftRadius || 0}px;
`;

export const CustomProgress = styled(Progress)`

  height: 20px;
  width: ${(props) => props.percentage}%;

  .ant-progress-inner {
    vertical-align: super;
    height: 10px;
    border-radius: ${(props) => props.leftRadius || 0}px
    ${(props) => props.rightRadius || 0}px 
    ${(props) => props.rightRadius || 0}px 
    ${(props) => props.leftRadius || 0}px;
  }

  .ant-progress-bg {
    border-radius: ${(props) => props.leftRadius || 0}px
    ${(props) => props.rightRadius || 0}px 
    ${(props) => props.rightRadius || 0}px 
    ${(props) => props.leftRadius || 0}px;
    background-color: ${(props) => props.color};
    height: 10px !important;
  }
`;
