import styled from 'styled-components';

export const Container = styled.div`
 width: 100%;

 label {
 font-size: 0.75rem;
 font-weight: bold;
 }
`;

export const StyledInput = styled.div`
 width: 100%;
 display: flex;
 padding: 0.1rem;
 border-color: ${props => props.isValid && '#da1e28'};
  :hover {
    border-color: ${props => props.isValid ?'#da1e28'  : `var(--primary)`};
  }

 input {
 padding: 0.375rem 0.938rem !important;
 border: none !important;
 :hover {
 border-color: var(--primary);
 }
 height: 35px;
 box-shadow: none !important;
 width: 100%;
 }

 .ant-input-password {
 border: none;
 box-shadow: none !important;
 }

 svg {
 align-self: center;
 margin: 0 1rem;
 color: var(--primary);
 cursor: pointer;
 }
`;
