/* eslint-disable no-useless-escape */
import React, { useState, useCallback } from 'react';
import { InboxOutlined, FileExcelFilled } from '@ant-design/icons';
import XLSX from 'xlsx';
import { notification, Tooltip } from 'antd';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';
import { Content, IUpload } from './styles';
import { Row } from '../../../../styles/components';
import chargesApi from '../../../../services/charges';
import { useAuth } from '../../../../hooks/auth';

const ImportCharges = () => {
  const [importedFile, setImportedFile] = useState(null);
  const [chargesJson, setChargesJson] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const history = useHistory();
  const { token } = useAuth();

  const uploadProps = {
    onRemove: () => {
      setImportedFile(null);
      setChargesJson(null);
    },
    beforeUpload: async (file) => {
      if (file.size > 33096064) {
        notification.error({ message: 'No máximo 3MB por arquivo.' });

        return false;
      }

      const fileName = file.name;
      const extension = fileName ? fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length - 1) : null;

      if (!extension || (extension !== 'csv' && extension !== 'xls' && extension !== 'xlsx')) {
        notification.error({ message: 'Formato de arquivo inválido.' });

        return false;
      }

      const reader = new FileReader();

      reader.onload = async (f) => {
        try {
          const data = new Uint8Array(f.target.result);
          const workbook = XLSX.read(data, { type: 'array', cellText: false, cellDates: true });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const dataJson = XLSX.utils.sheet_to_json(sheet, { dateNF: 'yyyy-mm-dd' });

          const formattedCharges = [];

          // eslint-disable-next-line no-restricted-syntax
          for (const item of dataJson) {
            if (String(item.CPFRESPONSAVEL).replace(/[^0-9]/g, '')) {
              formattedCharges.push({
                student_registration: String(item.MATRICULA).trim(),
                student_name: String(item.NOMEDOALUNO).trim(),
                student_responsible_document: String(item.CPFRESPONSAVEL).replace(/[^0-9]/g, ''),
                student_responsible_name: String(item.RESPONSAVEL).trim(),
                student_course: String(item.CURSO).trim(),
                student_email: String(item.EMAIL).trim(),
                student_phone_number: String(item.CELULAR).trim(),
                street: String(item.ENDERECO).trim(),
                number: String(item.NUMERO).trim(),
                city: String(item.CIDADE).trim(),
                state: String(item.ESTADO).trim(),
                zip_code: String(item.CEP).trim().replace(/[^0-9]/g, ''),
                neighborhood: String(item.BAIRRO).trim(),
                nm_id: String(item.IDBOLETO).trim(),
                complement: String(item.COMPLEMENTO).trim(),
                nosso_numero: String(item.NOSSONUMERO).trim(),
                description: String(item.TIPO).trim(),
                date_1: item.DTPRIMVENC,
                value_date_1: Number(String(item.VLRPRIMDESC).replace(',', '.').trim()),
                date_2: item.DTSEGVENC,
                value_date_2: Number(String(item.VLRSEGDESC).replace(',', '.').trim()),
                due_at: item.DTTERCVENC,
                base_value: Number(String(item.VLRTERCDESC).replace(',', '.').trim()),
                partner_document: String(item.CNPJ).trim().replace(/[^0-9]/g, ''),
              });
            }
          }

          setChargesJson(formattedCharges);
          setImportedFile(file);

          return true;
        } catch (err) {
          notification.error({ message: 'Ocorreu um erro ao importar o arquivo.' });

          return false;
        }
      };

      reader.readAsArrayBuffer(file);
      return false;
    },
  };

  const handleSubmit = useCallback(async () => {
    setButtonLoading(true);
    try {
      if (!chargesJson) {
        notification.error({ message: 'Por favor importe a planilha.' });

        return;
      }

      const formData = {
        charges: chargesJson,
      };

      const response = await chargesApi.post('nmpay/charges', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { errors, new_charges: newCharges } = response.data;

      if (errors) {
        errors.forEach((error) => {
          notification.error({
            message: `Erro na linha ${error.line} da planilha`,
            description: error.error,
            duration: 15000,
          });
        });
      }

      if (newCharges.length > 0) {
        notification.success({ message: 'Cobranças foram criadas.' });
      } else {
        notification.error({ message: 'Nenhuma cobrança foi criada.' });
      }

      setImportedFile(null);
      setChargesJson(null);
    } catch (e) {
      notification.error({ message: 'Ocorreu um erro tente novamente mais tarde.' });
    }

    setButtonLoading(false);
  }, [chargesJson, token]);

  return (
    <>
      <Content>
        <Tooltip title="voltar">
          <FiArrowLeft size={24} onClick={() => { history.push('/educational-payments'); }} />
        </Tooltip>
        <Row gutter={16} style={{ justifyContent: 'center', marginBottom: 20 }}>
          <h1>Envie a planilha para cadastrar as cobranças/mensalidades</h1>
        </Row>
        <div style={{ margin: '0 auto' }}>
          <IUpload
            showUploadList={false}
            maxCount={1}
            accept=".xlsx, .xls, .csv"
            {...uploadProps}
          >
            {importedFile
              ? (
                <>
                  <p className="ant-upload-drag-icon" style={{ color: '3EC0C3' }}>
                    <FileExcelFilled />
                  </p>
                  <p>Arquivo anexado: {importedFile.name}</p>
                  <p className="ant-upload-text">
                    Clique ou arraste a planilha para esta área para substituir o arquivo
                  </p>
                </>
              ) : (
                <>
                  <p className="ant-upload-drag-icon" style={{ color: '3EC0C3' }}>
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Clique ou arraste a planilha para esta área para fazer o upload</p>
                </>
              )}
          </IUpload>

          <div style={{
            width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '16px',
          }}
          >
            <Button disabled={buttonLoading} onClick={handleSubmit}>Enviar</Button>
          </div>
        </div>

      </Content>

    </>
  );
};

export default ImportCharges;
