import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  background: #E6E6E6;

  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 120vh;

  div {
    img {
      max-width: 130px;
      max-height: 90px;
      margin-top: 20px;
    }
  }
`;

export const Content = styled.div`
  background-color: #fff;
  box-shadow: 0 0 2px 0 #414141;
  padding: 48px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: 475px;
  width: 100%;
  /* min-height: 638px; */
  margin: 40px auto 40px auto;

  h1 {
    color: #41414141;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.42;
    text-align: center;
    color: #414141;
    margin-bottom: 40px;
  }

  h2 {
    color: #41414141;
    font-size: 14px;
    line-height: 1.42;
    text-align: center;
    color: #414141;
    margin-bottom: 10px;
  }

  section {
    text-align: center;
    @media(max-width: 1020px) {
      margin-top: 30px;
    }

    a {
      color: #0277bd;
      font-size: 14px;
      font-weight: bold;
    }
  }
`;
