import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
margin-top: 1rem;
height: 100%;
width: 100%;
`;

export const TitleArea = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 1rem;
`;

export const Title = styled.h1`
font-style: normal;
font-size: 20px;
line-height: 120%;
text-align: center;
`;

export const Text = styled.p`
display: flex;
width: 70%;
padding: 10px;
border-radius: 5px;
margin-bottom: 10px;
font-size: 15px;
`;

export const QRCodeContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const QRCodeArea = styled.section`
display: flex;
justify-content: center;
`;

export const ButtonContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
gap: 1rem;
margin-top: 30px;
width: 90%;
`;

export const Icons = styled.div`
 display: flex;
 flex-direction: row;
 gap: 3rem;
`;
export const Icon = styled.img`
 height: 4rem;
 cursor: pointer;
`;
