/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Menu, Badge, Dropdown, Select, Input, List, message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { GlobalOutlined, RetweetOutlined, RightOutlined } from '@ant-design/icons';
import { FaBell, FaUserAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { useAuth } from '../../hooks/auth';
import { useSettings } from '../../hooks/settings';
import SendDocumentsModal from '../Modals/SendDocuments';
import {
  Container,
  Account,
  User,
  BankAccount,
  Notification,
  NotificationTitle,
  NotificationDate,
  Title,
  Divider,
  NotificationDot,
  NotificationButtonContainer,
} from './styles';
import { formatAccount, formatBranch } from '../../pages/Transaction/Receipt/utils';
import i18n from '../../i18n';
import { getNotifications } from '../../services/getNotifications';
import ModalForTransition from '../ModalForTransition';
import NotifyInvitation from '../NotifyInvitation';
import { acceptInvitation, declineInvitation } from '../../services/endPointRegister/PJ/Invitations';
import Text from 'antd/lib/typography/Text';

const { Option } = Select;

function Header() {
  const {
    account, user, signOut, accountsToChoose,
  } = useAuth();
  const [currentNotification, setCurrentNotification] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const token = localStorage.getItem('@qesh:token');
  const accountsList = JSON.parse(localStorage.getItem('@qesh:AccountsList'));

  useEffect(() => {
    getNotifications(token, user.id).then((notificationsList) => {
      if (notificationsList.res.status === 'error') {
        setNotifications([]);
        return;
      }
      setNotifications(notificationsList.res);
    });
  }, []);

  const { settings, setLanguage } = useSettings();

  const [documentsNotification, setDocumentsNotification] = useState(false);
  const { t } = useTranslation();

  const [pattern, setPattern] = useState('');

  const handleSelectedLanguage = useCallback(
    (language) => {
      localStorage.setItem('@qesh:ib_language', `${language}`);
      i18n.changeLanguage(language);
      setLanguage(language);
    },
    [setLanguage],
  );

  const handleAccept = async (token, dataPjId, accountId) => {
    await acceptInvitation(token, dataPjId, accountId);
    setCurrentNotification(null);
    signOut();
    message.success('Convite aceito!');
    getNotifications(token, user.id).then((notificationsList) => {
      if (notificationsList.res.status === 'error') {
        setNotifications([]);
        return;
      }
      setNotifications(notificationsList.res);
    });
  };

  const handleDecline = async (token, dataPjId, accountId) => {
    await declineInvitation(token, dataPjId, accountId);
    setCurrentNotification(null);
    message.success('Convite recusado!');
    getNotifications(token, user.id).then((notificationsList) => {
      if (notificationsList.res.status === 'error') {
        setNotifications([]);
        return;
      }
      setNotifications(notificationsList.res);
    });
  };

  // const handleClick = reference => {
  //  localStorage.setItem('qesh:reference', reference)
  // }
  const FilterAccount = () => {
    const filtered = accountsList?.filter((item) => item.document.includes(pattern))
      .filter((item) => item.account_id !== JSON.parse(localStorage.getItem('@qesh:account')).id)
      .map((filteredName) => (filteredName.pj_access === 'PENDING' || filteredName.pj_access === 'DENIED') ? (
        (localStorage.setItem('@qesh:selectedAccount', filteredName.account_id),
        (
          <List.Item style={{ color: '#adadad' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{`${filteredName.bank_branch} / ${filteredName.bank_account}`}</span>
              <span>{filteredName.name}</span>
              {filteredName.document.length === 11 ? (
                <span>
                  {filteredName.document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}
                </span>
              ) : (
                <span>
                  {filteredName.document.replace(
                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    '$1.$2.$3/$4-$5',
                  )}
                </span>
              )}
            </div>
            <RightOutlined />
          </List.Item>
        ))
      ) : (
        <Link to="/change-account" style={{ cursor: 'pointer', textDecoration: 'none' }}>
          <List.Item onClick={() => localStorage.setItem('@qesh:account_id', filteredName.account_id)}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{`${filteredName.bank_branch} / ${filteredName.bank_account}`}</span>
              <span>{filteredName.name}</span>
              {filteredName.document.length === 11 ? (
                <span>
                  {filteredName.document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}
                </span>
              ) : (
                <span>
                  {filteredName.document.replace(
                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    '$1.$2.$3/$4-$5',
                  )}
                </span>
              )}
            </div>
            <RightOutlined />
          </List.Item>
        </Link>
      ));
    return <>{filtered}</>;
  };

  const notificationList = () => {
    const list = notifications.map((item, index) => (
      <Menu.Item
        key={index}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          margin: '0.5rem 0',
        }}
        onClick={() => setCurrentNotification(item)}
      >
        <NotificationTitle>
          {item.type === 'add_partners' ? 'Pedido de participação como sócio recebido' : 'notificação'}
        </NotificationTitle>
        <NotificationDate>
          <span>{format(parseISO(item.updated_at), 'dd/MM/yyyy')}</span>
          <span>{format(parseISO(item.updated_at), 'hh:mm')}</span>
        </NotificationDate>
        <Divider />
      </Menu.Item>
    ));

    return (
      <Menu style={{ width: '21rem', padding: ' 1rem 0 2rem 0' }}>
        <Title>Notificações</Title>
        {list.length > 0 ? list : <Menu.Item>Sem novas notificacoes</Menu.Item>}
      </Menu>
    );
  };

  const accounts = () => (
    <Menu style={{ width: '21rem', padding: ' 1rem', display:'flex', flexFlow: 'column nowrap', gap: '0.5rem' }}>
      <Title style={{fontSize: '1.25rem'}}>{accountsList.length > 1 ? 'Qual conta você quer utilizar?' : 'Você ainda não possui nenhuma conta!'}</Title>
      <List>{FilterAccount()}</List>
    </Menu>
  );
  useEffect(() => {
    const documents = window.localStorage.getItem('@documents');

    if (!documents) {
      if (account.user.migration && account.user.status !== 'ACTIVE') {
        window.localStorage.setItem('@documents', 1);
        setDocumentsNotification(true);
      }
    } else {
      window.localStorage.setItem('@documents', Number(documents) + 1);
      setTimeout(() => {
        localStorage.removeItem('@documents');
      }, 900000);
    }
  }, [user, account]);

  return settings && settings.name ? (
    <Container style={{
      backgroundImage: settings.name === "oriental_bank" ? `url(${settings.ib_background_img})` : undefined,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: settings.colors.header,
    }}
    >
      <div>
        <img style={{ maxWidth: 105, maxHeight: `${settings.name === 'oriental_bank' ? 60 : 40}` }} src={settings.default_logo} alt="Logo" />
      </div>
      <Account>
        <User>
          <FaUserAlt color="#e6e6e6" size={20} />
        </User>

        <BankAccount style={{color: `${settings.name === 'oriental_bank' ? 'white' : 'var(--text)'}` }}>
          <span style={{display: 'block'}}>{account.user.name}</span>

          {account.bacen_status != null ? <>
           <span style={{display: 'block'}}>
            {t('banco')}: {account.bank_code}
          </span>
          
          <span style={{marginRight: 10}}>
            {t('agencia')}: {formatBranch(account.bank_branch)}
          </span>
          </> : null}

           {t('conta')}: {formatAccount(account.bank_account)}
          <span>
            
          </span>
        </BankAccount>
      </Account>

      <SendDocumentsModal
        title={t('por favor atualize os documentos')}
        visible={!!documentsNotification}
        hidden={() => setDocumentsNotification(false)}
      />

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
       {accountsList?.length > 1 &&
        (<Dropdown overlay={accounts}>
        <RetweetOutlined
          style={{
            fontSize: 40,
            color: settings.colors.icons ?? 'var(--prymary)',
            cursor: 'pointer',
            marginLeft: '1rem',
            marginRight: '20px',
          }}
        />
      </Dropdown>)
     }
        {(settings.name === 'wibbank' || settings.name === 'oriental_bank') && (
        <>
          <GlobalOutlined style={
            { fontSize: '1.063rem', 
            color: settings.name === 'oriental_bank' ? settings.colors.text: settings.colors.primary,
             marginRight: '5px' }} />
          <Select
            bordered={settings.name === 'oriental_bank' ? true : false}
            placeholder={t('alterar idioma')}
            style={{ fontWeight: 'bold', color: settings.colors.primary, minWidth: '100px' }}
            onChange={(value) => handleSelectedLanguage(value)}
          >
            <Option style={{ width: 145 }} value="ptbr">
              Português
            </Option>
            <Option value="zh">{/* Chinês */}中文</Option>
            {/* <Option value="ptbr"><Icon style={{ fontSize: 15 }} component={BrazilFlagSvg} /></Option>
               <Option value="en"><Icon style={{ size: 32 }} component={ChinaFlagSvg} /></Option> */}
          </Select>

          {/* <Icon style={{ size: 32 }} component={ChinaFlagSvg} />
             <Icon style={{ size: 32 }} component={BrazilFlagSvg} /> */}
        </>
        )}
        {/* O conteudo do dropdown esta no overlay. é o notificationList */}
        <Dropdown overlay={notificationList} placement="topLeft">
          <Badge style={{backgroundColor: "#FCBE07"}} size='small' count={notificationList.length} offset={[-7, 7]}>
          <NotificationButtonContainer
      disabled={notifications.length === 0}
      secondaryColor={'#ccc'}
      onPress={() => {
        setOpenNotificationModal(true);
      }}>
            <Notification>
              <FaBell size={23}         
              color={notifications.length > 0 ? settings.colors.primary : '#ccc'}/>
            </Notification>
            {notifications.length > 0 && (
        <NotificationDot detailsColor={settings.colors.details}>
          <Text
            style={{
              color: '#fff',
              fontSize: 11,
              fontWeight: 'bold',
              padding: '0.32rem'
            }}>
            {notifications.length}
          </Text>
        </NotificationDot>
      )}
          </NotificationButtonContainer>

          </Badge>
        </Dropdown>
      </div>
      <ModalForTransition setVisibility={setCurrentNotification} visibility={!!currentNotification}>
        {currentNotification && (
        <NotifyInvitation
          reference={currentNotification.reference}
          handleAccept={handleAccept}
          handleDecline={handleDecline}
        />
        )}
      </ModalForTransition>
    </Container>
  ) : (
    <></>
  );
}

export default Header;
