import styled from 'styled-components';

export const ShopImageSelect = styled.div`
  width:90%;
  min-height: 200px;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  gap:15px;
  `;

export const TextWrapper = styled.div`
  width:fit-content;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:flex-start;
`;

export const Title = styled.div`
  width:fit-content;
  display:flex;
  gap:5px;
  justify-content:center;
  align-items:center;

  span {
    font-size: 1rem;
    font-weight: bold;
  }

  p {
    color: red;
  }
`;

export const ImagePlaceholderWrapper = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  justify-content:space-between;
  align-items:center;
`;

export const FooterInputsWrapper = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  gap:60px;
  margin-top: 20px;
`;

export const InputColumn = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  justify-content:space-between;
  align-items:center;
`;

export const UploadFileWrapper = styled.div`
  width:40%;
  min-height:80px;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:flex-start;
  span {
    font-weight: bold;
  }
`;

export const FileUploadWrapper = styled.div`
  width:fit-content;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:flex-start;
  span {
    font-weight: normal;
  }
`;

export const TermsContainer = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  gap:10px;
`;

export const AnchorContainer = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  gap:3px;
`;

export const Anchor = styled.a`
  text-decoration: none;
  color: ${(props) => props.color};
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;

`;
