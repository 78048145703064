export const listOfNationality = [
  { id: 'Brasil', name: 'Brasileiro(a)' },
  { id: 'Afeganistão', name: 'Afegã(o)' },
  { id: 'África do Sul', name: 'Sul-africano(a)' },
  { id: 'Albânia', name: 'Albanês(esa)' },
  { id: 'Alemanha', name: 'Alemã(ão)' },
  { id: 'Andorra ', name: 'Andorrano(a)' },
  { id: 'Angola', name: 'Angolano(a)' },
  { id: 'Anguila', name: 'Anguilano(a)' },
  { id: 'Antígua e Barbuda', name: 'Antiguano(a)' },
  { id: 'Antilhas Holandesas', name: 'Antilhano(a)' },
  { id: 'Arábia Saudita', name: 'Árabe-saudita' },
  { id: 'Argélia', name: 'Argelino(a)' },
  { id: 'Argentina', name: 'Argentino(a)' },
  { id: 'Armênia', name: 'Arménio(a)' },
  { id: 'Aruba', name: 'Arubano(a)' },
  { id: 'Austrália', name: 'Australiano(a)' },
  { id: 'Áustria', name: 'Austríaco(a)' },
  { id: 'Azerbaijão', name: 'Azeri' },
  { id: 'Bahamas', name: 'Baamiano(a)' },
  { id: 'Bahrein', name: 'Barenita' },
  { id: 'Bangladesh', name: 'Bangladechiano(a)' },
  { id: 'Barbados', name: 'Barbadense' },
  { id: 'Bélgica', name: 'Belga' },
  { id: 'Belize', name: 'Belizenho(a)' },
  { id: 'Benim', name: 'Benineseo(a)' },
  { id: 'Bermudas', name: 'Bermudense' },
  { id: 'Bielorrússia', name: 'Bielorrusso(a)' },
  { id: 'Bolívia', name: 'Boliviano(a)' },
  { id: 'Bósnia e Herzegovina', name: 'Bósnio(a)' },
  { id: 'Botswana', name: 'Botsuano(a)}' },
  { id: 'Brunei', name: 'Bruneíno(a)' },
  { id: 'Bulgária', name: 'Búlgaro(a)' },
  { id: 'Burquina Fasso', name: 'Burquino(a)' },
  { id: 'Burundi', name: 'Burundiano(a)' },
  { id: 'Butão', name: 'Butanês(esa)' },
  { id: 'Cabo Verde Cabo Verde', name: 'Cabo-verdiano(a)' },
  { id: 'Camarões', name: 'Camaronês(esa)' },
  { id: 'Camboja', name: 'Cambojano(a)' },
  { id: 'Canadá', name: 'Canadiano(a)' },
  { id: 'Cazaquistão', name: 'Cazaque' },
  { id: 'Hade', name: 'Chadiano(a)' },
  { id: 'Chile', name: 'Chileno(a)' },
  { id: 'China', name: 'Chinês(esa)' },
  { id: 'Chipre', name: 'Cipriota' },
  { id: 'Colômbia', name: 'Colombiano(a)' },
  { id: 'Comores', name: 'Comoriano(a)' },
  { id: 'República do Congo', name: 'Congolês(a)' },
  { id: 'Coreia do Norte', name: 'Norte-coreano(a)' },
  { id: 'Coreia do Sul', name: 'Sul-coreano(a)' },
  { id: 'Costa do Marfim', name: 'Marfinense' },
  { id: 'Costa Rica', name: 'Costarriquenho(a)' },
  { id: 'Croácia', name: 'Croata' },
  { id: 'Cuba', name: 'Cubano(a)' },
  { id: 'Dinamarca', name: 'Dinamarquês(esa)' },
  { id: 'Djibouti', name: 'Jibutiano(a)' },
  { id: 'Dominica', name: 'Dominiquês(esa)' },
  { id: 'Egito', name: 'Egípcio(a)' },
  { id: 'El Salvador', name: 'Salvadorenho(a)' },
  { id: 'Emirados Árabes Unidos', name: 'Emiradense' },
  { id: 'Equador', name: 'Equatoriano(a)' },
  { id: 'Eritreia', name: 'Eritreu(ia)' },
  { id: 'Escócia', name: 'Escocês(esa)' },
  { id: 'Eslováquia', name: 'Eslovaco(a)' },
  { id: 'Eslovênia', name: 'Esloveno(a)' },
  { id: 'Espanha', name: 'Espanhol(a)' },
  { id: 'Estados Federados da Micronésia', name: 'Micronésio(a)' },
  { id: 'Estados Unidos', name: 'Norte-americano(a)' },
  { id: 'Estónia', name: 'Estónio(a)' },
  { id: 'Etiópia', name: 'Etíope' },
  { id: 'Fiji', name: 'Fijiano(a)' },
  { id: 'Filipinas', name: 'Filipino' },
  { id: 'Finlândia', name: 'Finlandês(esa)' },
  { id: 'França', name: 'Francês(esa)' },
  { id: 'Gabão', name: 'Gabonês(a)' },
  { id: 'Gâmbia', name: 'Gambiano(a)' },
  { id: 'Gana', name: 'Ganês(a)' },
  { id: 'Geórgia', name: 'Georgiano(a)' },
  { id: 'Granada', name: 'Granadino(a)' },
  { id: 'Grécia', name: 'Grego(a)' },
  { id: 'Guadalupe', name: 'Guadalupense' },
  { id: 'Guam', name: 'Guamês(a)' },
  { id: 'Guatemala', name: 'Guatemalteco(a)' },
  { id: 'Guiana', name: 'Guianês(esa)' },
  { id: 'Guiana Francesa', name: 'Guianense' },
  { id: 'Guiné', name: 'Guineano(a)' },
  { id: 'Guiné Equatorial', name: 'Guinéu-equatoriano(a)' },
  { id: 'Guiné-Bissau', name: 'Guineense' },
  { id: 'Haiti', name: 'Haitiano(a)' },
  { id: 'Honduras', name: 'hondurenho(a)' },
  { id: 'Hong Kong', name: 'Honconguês(esa)' },
  { id: 'Hungria', name: 'Húngaro(a)' },
  { id: 'Iêmen', name: 'Iemenita' },
  { id: 'Ilhas Caimã', name: 'Caimanês(a)' },
  { id: 'Ilhas Cook', name: 'Cookense' },
  { id: 'Ilhas Feroé', name: 'Faroense' },
  { id: 'Ilhas Salomão', name: 'Salomonense' },
  { id: 'Ilhas Virgens Americanas', name: 'Virginense' },
  { id: 'Ilhas Virgens Britânicas', name: 'Virginense' },
  { id: 'Índia', name: 'Indiano(a)' },
  { id: 'Indonésia', name: 'Indonésia(o)' },
  { id: 'Inglaterra', name: 'Inglês(esa)' },
  { id: 'Irão', name: 'Iraniano(a)' },
  { id: 'Iraque', name: 'Iraquiano(a)' },
  { id: 'Irlanda', name: 'Irlandês(a)' },
  { id: 'Irlanda do Norte', name: 'Norte-irlandês(esa)' },
  { id: 'Islândia', name: 'Islandês(esa)' },
  { id: 'Israel', name: 'Israelense' },
  { id: 'Itália', name: 'Italiano(a)' },
  { id: 'Jamaica', name: 'Jamaicano(a)' },
  { id: 'Japão', name: 'Japonês(esa)' },
  { id: 'Jordânia', name: 'Jordano(a)' },
  { id: 'Kiribati', name: 'Quiribatiano(a)' },
  { id: 'Kosovo', name: 'Kosovar' },
  { id: 'Kuwait', name: 'Kuwaitiano(a)' },
  { id: 'Laos', name: 'Laociano(a)' },
  { id: 'Lesoto', name: 'Lesotiano(a)' },
  { id: 'Letônia', name: 'Letã(o)' },
  { id: 'Líbano', name: 'Libanês(a)' },
  { id: 'Libéria', name: 'Liberiano(a)' },
  { id: 'Líbia', name: 'Líbio(a)' },
  { id: 'Liechtenstein', name: 'Listenstainiano(a)' },
  { id: 'Lituânia', name: 'Lituano(a)' },
  { id: 'Luxemburgo', name: 'Luxemburguês(a)' },
  { id: 'Macau', name: 'Macaense' },
  { id: 'Macedônia do Norte', name: 'Macedónico(a)' },
  { id: 'Madagáscar', name: 'Malgaxe' },
  { id: 'Malásia', name: 'Malaio(a)' },
  { id: 'Malawi', name: 'Malauiano(a)' },
  { id: 'Maldivas', name: 'Maldivo(a)' },
  { id: 'Mali', name: 'Maliano' },
  { id: 'Malta', name: 'Maltês(a)' },
  { id: 'Marrocos', name: 'Marroquino(a)' },
  { id: 'Martinica', name: 'Martinicano(a)' },
  { id: 'Maurícia', name: 'Mauriciano(a)' },
  { id: 'Mauritânia', name: 'Mauritano(a)' },
  { id: 'México', name: 'Mexicano(a)' },
  { id: 'Mianmar', name: 'Birmanês(a)' },
  { id: 'Moçambique', name: 'Moçambicano(a)' },
  { id: 'Moldávia', name: 'Moldavo(a)' },
  { id: 'Mónaco', name: 'Monegasco(a)' },
  { id: 'Mongólia', name: 'Mongol' },
  { id: 'Montenegro', name: 'Montenegrino(a)' },
  { id: 'Monserrate', name: 'Monserratense' },
  { id: 'Namíbia', name: 'Namibiano(a)' },
  { id: 'Nauru', name: 'Nauruano(a)' },
  { id: 'Nepal', name: 'Nepalês(a)' },
  { id: 'Nicarágua', name: 'Nicaraguense' },
  { id: 'Níger', name: 'Nigerino(a)' },
  { id: 'Nigéria', name: 'Nigeriano(a)' },
  { id: 'Noruega', name: 'Norueguês(esa)' },
  { id: 'Nova Caledônia', name: 'Neocaledónio(a)' },
  { id: 'Nova Zelândia', name: 'Neozelandês(esa)' },
  { id: 'Omã', name: 'Omanense' },
  { id: 'País de Gales', name: 'Galês(esa)' },
  { id: 'Países Baixos', name: 'Neerlandês(a)' },
  { id: 'Palau', name: 'Palauano(a)' },
  { id: 'Palestina', name: 'Palestiniano(a)' },
  { id: 'Panamá', name: 'Paidnho(a)' },
  { id: 'Papua-Nova Guiné', name: 'Papua' },
  { id: 'Paquistão', name: 'Paquistanês(a)' },
  { id: 'Paraguai', name: 'Paraguaio(a)' },
  { id: 'Peru', name: 'Peruano(a)' },
  { id: 'Polinésia Francesa', name: 'Polinésio(a)' },
  { id: 'Polónia', name: 'Polaco(a)' },
  { id: 'Porto Rico', name: 'Porto-riquenho(a)' },
  { id: 'Portugal', name: 'Português(esa)' },
  { id: 'Catar', name: 'Catariano(a)' },
  { id: 'Quênia', name: 'Queniano(a)' },
  { id: 'Quirguistão', name: 'Quirguiz' },
  { id: 'Reino Unido', name: 'Britânico(a)' },
  { id: 'República Centro-Africana', name: 'Centro-africano(a)' },
  { id: 'Chéquia', name: '(t)checo(a)' },
  { id: 'Taiwan', name: 'Taiwanês(a)' },
  { id: 'República Democrática do Congo', name: 'Congolês(a)' },
  { id: 'República Dominicana', name: 'Dominicano(a)' },
  { id: 'Roménia', name: 'Romeno(a)' },
  { id: 'Ruanda', name: 'Ruandês(a) ' },
  { id: 'Rússia', name: 'Russo(a)' },
  { id: 'São Martinho', name: 'São-martinhense' },
  { id: 'Samoa', name: 'Samoano(a)' },
  { id: 'Samoa Americana', name: 'Samoense' },
  { id: 'Santa Lúcia', name: 'Santa-luciense' },
  { id: 'São Cristóvão e Neves', name: 'São-cristovense' },
  { id: 'San Marino', name: 'São-marinhense' },
  { id: 'São Tomé e Príncipe', name: 'Santomenses' },
  { id: 'São Vicente e Granadinas', name: 'São-vicentino(a)' },
  { id: 'Senegal', name: 'Senegalês(esa)' },
  { id: 'Serra Leoa', name: 'Serra-leonês(a)' },
  { id: 'Sérvia', name: 'Sérvio(a)' },
  { id: 'Seicheles', name: 'Seichelense' },
  { id: 'Singapura', name: 'Singapurense' },
  { id: 'São Martinho', name: 'São-martinhense' },
  { id: 'Síria', name: 'Sírio(a)' },
  { id: 'Somália', name: 'Somali' },
  { id: 'Sri Lanka', name: 'Cingalês(a)' },
  { id: 'Essuatíni', name: 'Essuatiniano(a)' },
  { id: 'Sudão', name: 'Sudanês(a)' },
  { id: 'Suécia', name: 'Sueco(a)' },
  { id: 'Suíça', name: 'Suíço(a)' },
  { id: 'Suriid', name: 'Surinamês(a)' },
  { id: 'Tajiquistão', name: 'Tajique' },
  { id: 'Tailândia', name: 'Tailandês' },
  { id: 'Taipé Chinesa', name: 'Taiwanês(a)' },
  { id: 'Tanzânia', name: 'Tanzaniano(a)' },
  { id: 'Timor-Leste', name: 'Timorense' },
  { id: 'Togo', name: 'Togolês(a)' },
  { id: 'Tonga', name: 'Tonganês(esa)' },
  { id: 'Trindade e Tobago', name: 'Trinitário(a)-tobagense' },
  { id: 'Tunísia', name: 'Tunisino(a)' },
  { id: 'Turcas e Caicos', name: 'Turquense' },
  { id: 'Turquemenistão', name: 'Turquemeno(a)' },
  { id: 'Turquia', name: 'Turco(a)' },
  { id: 'Tuvalu', name: 'Tuvaluano(a)' },
  { id: 'Ucrânia', name: 'Ucraniano(a)' },
  { id: 'Uganda', name: 'Ugandense' },
  { id: 'Uruguai', name: 'Uruguaio(a)' },
  { id: 'Uzbequistão', name: 'Usbeque' },
  { id: 'Vanuatu', name: 'Vanuatuense' },
  { id: 'Vaticano', name: 'Vaticano(a)' },
  { id: 'Venezuela', name: 'Venezuelano(a)' },
  { id: 'Vietnã', name: 'Vietnamita' },
  { id: 'Zâmbia', name: 'Zambiano(a)' },
  { id: 'Zimbabwe', name: 'Zimbabuano(a)' },
];
