import styled from 'styled-components';

export const RegisterArea = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 80%;
    height: 100%;
    justify-content: space-between;
`;

export const TitleArea = styled.div`
    width: 80%;
`;

export const Title = styled.h3`
 display: flex;
 color: var(--label);
 text-align: center;
 font-size: 20px;
`;

export const InputArea = styled.div`
width: 100%;
height: 65%;
display: flex;
flex-direction: column;
align-items: center;
gap: 15px;
`;

export const DualButtonsDisposition = styled.nav`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 height: 70%;
 min-height: 7vh;
 div {
 width: 45%;
 height: 150%;
 }
 button {
 height: 150%;
 }
`;
