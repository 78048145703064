import PropTypes from 'prop-types';

export const AntProps = {
  message: PropTypes.node,
  description: PropTypes.node,
  type: PropTypes.string,
};

export const DefaultAntProps = {
  message: undefined,
  description: undefined,
  type: undefined,
};
