/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

export const Container = styled.button`
 background-color: ${(props) => (props.disabled ? '#F4F4F4' : props.colored ? props.backgroundColor : 'transparent')};
 color: ${(props) => (props.disabled ? '#A8A8A8' : props.colored ? props.textColor : props.backgroundColor)};
 font-weight: 700;

 border: 1px solid ${(props) => (props.disabled ? '#F4F4F4' : props.backgroundColor)};
 font-size: 16px;
 border-radius: 8px;
 padding: 12px 24px;
 cursor: pointer;
 min-width: fit-content;

 margin-right: ${(props) => (props.margin ? '60px' : 0)};
 transition: 0.4s;

 ${(props) => props.right
  && css`
   float: right;
  `}

 ${(props) => props.center
  && css`
   text-align: center;
   margin: auto;
   width: 100%;
  `}

  ${(props) => props.default
  && css`
   background: #fff;
   color: #aaaaaa;
   box-shadow: 0px 3px 6px #aaaaaa;
  `}

  &:hover {
  opacity: 0.8;
 }

 :disabled {
  cursor: not-allowed;
 }
`;
