import React from 'react';
import { Input as InputANTD } from 'antd';
// SubMódulos
import CpfCnpj from './CpfCnpj';
import Cnpj from './Cnpj';
import Cpf from './Cpf';
import Date from './Date';
import Email from './Email';
import Text from './Text';
import Mask from './Mask';
import Cep from './Cep';
import Money from './Money';
import Modal from './Modal';
import Num from './Num';
import Password from './Password';
import SelectandSearchModal from './SelectandSearchModal';

import { Container, StyledInput } from './styles';
import { AntProps, DefaultAntProps } from './Types';

const Input = ({
  addonAfter,
  addonBefore,
  allowClear,
  bordered,
  className,
  defaultValue,
  disabled,
  id,
  label,
  maxLength,
  onChange,
  onPressEnter,
  prefix,
  placeholder,
  size,
  style,
  sufix,
  type,
  value,
  onPaste,
  onCopy,
  onKeyDown,
  min,
  max,
  isValid
}) => (
  <Container>
    {label && <label htmlFor={id}>{label}</label>}
    <StyledInput className={`ant-input ${className}`} isValid={isValid}>
      <InputANTD
        onKeyDown={onKeyDown}
        min={min}
        max={max}
        onPaste={onPaste}
        onCopy={onCopy}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        allowClear={allowClear}
        bordered={bordered}
        defaultValue={defaultValue}
        disabled={disabled}
        id={id}
        maxLength={maxLength}
        onChange={onChange}
        onPressEnter={onPressEnter}
        prefix={prefix}
        placeholder={placeholder}
        size={size}
        style={style}
        sufix={sufix}
        type={type}
        value={value}
      />
      <i />
    </StyledInput>
  </Container>
);

Input.propTypes = AntProps;

Input.defaultProps = DefaultAntProps;

Input.CpfCnpj = CpfCnpj;

Input.Cnpj = Cnpj;

Input.Cpf = Cpf;

Input.Date = Date;

Input.Email = Email;

Input.Text = Text;

Input.Password = Password;

Input.Mask = Mask;

Input.Cep = Cep;

Input.Money = Money;

Input.Num = Num;

Input.Modal = Modal;

Input.SelectandSearchModal = SelectandSearchModal;

export default Input;
