/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Select, Spin } from 'antd';
import {
  ButtonContainer,
  Container, Content, HeaderContainer, HelpSection, IconButton, InputContainer, LoginSection, SpanLink,
} from './styles';
import { useAuth } from '../../hooks/auth';
import Notification from '../../components/Notification';
import ModalForTransition from '../../components/ModalForTransition';
import i18n from '../../i18n';

import InputN from '../../components/InputN';
import ButtonN from '../../components/ButtonN';
import api from '../../services/api';
import CnpjInfo from '../../components/CnpjInfo';
import { useSettings } from '../../hooks/settings';
import { useRefresh } from '../../hooks/refresh';
import { useRefreshPj } from '../../hooks/refreshPj';
import appStore from '../../assets/app-store.png';
import googlePlay from '../../assets/google-play.png';
import diverseLogo from '../../assets/diverse-logo.svg';
import Alert from '../../components/SpanError';
import messages from '../../components/MessageError';

const { Option } = Select;
function Main() {
  const {
    signInNewOnboarding, setOnboarding,
  } = useAuth();
  const { getEmailSupportMessage, settings } = useSettings();
  const history = useHistory();
  // eslint-disable-next-line max-len
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState(undefined);
  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState('');
  const [alterPassword, setAlterPassword] = useState(false);
  const [refreshPj, setRefreshPj] = useState(false);
  const [refreshPf, setRefreshPf] = useState(false);
  const [registerPartner, setRegisterPartner] = useState(true);
  // Error handlers
  const [validDocument, setValidDocument] = useState(false);
  const [validPass, setValidPass] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation();
  const { setUser, setToken, resetRefresh } = useRefresh();
  const {
    setUserPj, setTokenPj, resetRefreshPj, setAccountId,
  } = useRefreshPj();
  const [shouldSendGeneralPendency, setShouldSendGeneralPendency] = useState(false);

  const validCpf = document.length <= 0 || /\d{11}/g.test(document) || /\d{14}/g.test(document);
  useEffect(() => {
    sessionStorage.removeItem('@qesh:token');
    localStorage.removeItem('@qesh:user');
    localStorage.removeItem('@qesh:account');
    localStorage.removeItem('@documents');
    localStorage.removeItem('@loginApproved');
    clearInterval();
    sessionStorage.clear();
  }, []);

  const handleSelectedLanguage = useCallback((language) => {
    localStorage.setItem('@qesh:ib_language', `${language}`);
    i18n.changeLanguage(language);
  }, []);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (!validDocument || !validPass) {
      setError('Credenciais inválidas.');
      setLoading(false);
      return;
    }
    sessionStorage.setItem('@qesh:document', `${document}`);
    try {
      await signInNewOnboarding({
        document,
        password,
      });
      setLoading(false);
    } catch (err) {
      console.log('LOGIN: ', err);
      if (err.response?.data?.message === 'User not authorized to modify this company user') {
        setError(() => <div>Como previsto nos <b style={{ color: settings.colors.primary }}>termos e condições de uso</b>, você precisa atualizar o seu cadastro para continuar.<br /><br /> Apenas o criador da conta pode atualizá-la.</div>);
        setLoading(false);
        setNotify(true);
        return;
      }
      if (err.response?.data?.message === 'The user must update their information') {
        if (document.length === 14) {
          resetRefreshPj();
          setTokenPj(err?.response?.data?.payload?.token);
          setUserPj(err?.response?.data?.payload?.user);
          setAccountId(err?.response?.data?.payload?.user?.baas?.accounts[0]?.id);
          setError('Alguns dados do seu perfil precisam ser atualizados para que você possa efetuar login');
          setRefreshPj(true);
          setLoading(false);
          setShouldSendGeneralPendency(true);
          return;
        }
        if (document.length === 11) {
          localStorage.setItem('@qesh:UserData', JSON.stringify(err.response?.data?.payload?.user));
          resetRefresh();
          setToken(err?.response?.data?.payload?.token);
          setUser(err?.response?.data?.payload?.user);
          setError('Alguns dados do seu perfil precisam ser atualizados para que você possa efetuar login');
          setRefreshPf(true);
          setLoading(false);
          return;
        }
      }
      if (err?.response?.data?.message?.includes('BLACKLIST')) {
        setError(getEmailSupportMessage());
        setLoading(false);
        return;
      }
      if (err.message === 'Acesso PJ') {
        setError('Agora para acessar uma conta de Pessoa Jurídica você deverá entrar pela sua conta de Pessoa Física para alternar as contas');
        setNotify(false);
        setLoading(false);
        return;
      }
      if (err.message === 'Registro de Socios') {
        // resetRefreshPj();
        setError('Alguns dados do seu perfil precisam ser atualizados para que você possa efetuar login');
        setLoading(false);
        setRefreshPj(true);
        setRegisterPartner(true);
        return;
      }
      if (err.message === 'Pendente de aprovação') {
        setError('Sua conta está em análise. Ao ser aprovada, você poderá acessá-la normalmente.');
        setLoading(false);
        return;
      }
      if (err.message === 'Conta PF cancelada') {
        setError('Credenciais inválidas.');
        setLoading(false);
        return;
      }
      if (err.response?.data?.message === 'User not found') {
        setError('Usuário não encontrado.');
        setLoading(false);
        return;
      }
      setError(err.response?.data?.message);
      setLoading(false);
    }
  }, [signInNewOnboarding, document, password, validDocument, validPass,
    getEmailSupportMessage, setToken, setUser, resetRefresh, resetRefreshPj, setTokenPj, setUserPj, setAccountId]);

  const handleAdvanceToRegister = () => {
    sessionStorage.setItem('@qesh:step', '0');
    setOnboarding(true);
    history.push('/register/');
  };

  useEffect(() => () => setLoading(false), []);

  const recoveryPassword = async () => {
    setLoading(true);
    setAlterPassword(false);
    if (document) {
      try {
        const response = await api.post('/forgot-password', {
          document,
          client_name: settings.name,
        });
        setLoading(false);
        setShowConfirmationModal(true);
        setMessage(response.data.message);
      } catch (error) {
        setLoading(false);
        setShowConfirmationModal(true);
        setMessage(error.response?.data?.message);
      }
    }
  };

  const componentModalCpf = () => (
    <ModalForTransition
      visibility={alterPassword && error === false}
      title={(
        <h1
          style={{
            height: '30%',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '1.5rem',
            marginBottom: '1rem',
            width: '100%',
            maxWidth: '100%',
          }}
        >
          Alterar Senha
        </h1>
   )}
      style={{ height: '100%' }}
      setVisibility={() => setAlterPassword()}
      maskClosable={!loading}
    >
      <p style={{
        height: '40%', marginBottom: '1rem', width: '100%', maxWidth: '100%',
      }}
      >
        <span>Preencha o campo do CPF da conta para recuperar a senha de acesso</span>
      </p>

      <ButtonN
        loading={loading}
        style={{ marginTop: '1rem' }}
        size="large"
        type="primary"
        className="botao"
        onClick={() => {
          // recoveryPassword()
          setAlterPassword(false);
        }}
        icon={loading && <Spin indicator={<LoadingOutlined />} />}
      >
        {!loading && 'Confirmar'}
      </ButtonN>
    </ModalForTransition>
  );

  const componentModal = () => (
    <ModalForTransition
      visibility={showConfirmationModal && error === false}
      title={(
        <h1
          style={{
            height: '30%',
            textAlign: 'center',
            alignItems: 'center',
            fontSize: '1.5rem',
            marginBottom: '1rem',
            width: '100%',
            maxWidth: '100%',
          }}
        >
          Alterar Senha
        </h1>
   )}
      style={{ height: '100%' }}
      setVisibility={() => setShowConfirmationModal()}
      maskClosable={!loading}
    >
      <p style={{
        height: '40%', marginBottom: '1rem', width: '100%', maxWidth: '100%',
      }}
      >
        <span>{message}</span>
      </p>
      <ButtonN
        loading={loading}
        size="large"
        type="primary"
        className="botao"
        onClick={() => {
          setShowConfirmationModal(false);
        }}
        icon={loading && <Spin indicator={<LoadingOutlined />} />}
      >
        {!loading && 'Confirmar'}
      </ButtonN>
    </ModalForTransition>
  );

  const redirectRefresh = () => {
    if (document.length === 14 && refreshPj) {
      setRefreshPj(true);
      history.push('/refreshDataPj/');
    }
    if (document.length === 11 && refreshPf) {
      history.push('/refreshData/');
    }
    if (registerPartner && document.length !== 11 && error === 'Alguns dados do seu perfil precisam ser atualizados para que você possa efetuar login') {
      setRefreshPj(true);
      history.push('/refreshDataPj/');
    }
    setError(false);
  };

  return settings && settings.name ? (
    <Container settings={settings} showGradient={settings.name !== 'nmpay' && settings.name !== 'diverse'}>
      <Notification
        shouldSendGeneralPendency={shouldSendGeneralPendency}
        type="error"
        visible={error}
        isNotify={notify}
        setIsNotify={setNotify}
        title={t(`${notify ? 'Seu cadastro está desatualizado!' : 'erro ao fazer login'}`)}
        hidden={() => redirectRefresh()}
      >
        {error}
      </Notification>
      <LoginSection>
        <Content>
          <HeaderContainer>
            <img
              style={{ margin: 'auto', maxWidth: '10.125rem', maxHeight: '5.625rem' }}
              src={settings.name === 'diverse' ? diverseLogo : settings.default_logo}
              alt={t('logo')}
            />
            <h2>{t('acesse sua conta')}</h2>
          </HeaderContainer>
          <InputContainer>
            <InputN.CpfCnpj
              placeholder={t('Insira números apenas')}
              label="CPF/CNPJ"
              id="cpf"
              value={document}
              onChange={(e) => setDocument(e.target.rawValue)}
              setIsValid={setValidDocument}
              onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
            />
            <InputN.Password
              placeholder={t('Insira sua senha')}
              label={t('Senha')}
              id="senha"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              isValid={validPass}
              setIsValid={setValidPass}
              onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
            />
            <ButtonN
              type="primary"
              loading={loading}
              icon={loading && <Spin indicator={<LoadingOutlined />} />}
              onClick={handleSubmit}
            >
              {!loading && t('Entrar')}
            </ButtonN>

          </InputContainer>
          <HelpSection>
            <SpanLink
              onClick={() => {
                setDocument('');
                setValidDocument('');
                document ? recoveryPassword() : setAlterPassword(true);
              }}
            >
              {t('esqueci minha senha')}
            </SpanLink>
            {settings?.disabled ? null : (
              <ButtonN
                onClick={() => handleAdvanceToRegister()}
                style={{ zIndex: settings.name === 'diverse' ? -44 : null }}
              >
                {t('cadastrar')}
              </ButtonN>
            )}
          </HelpSection>
          {(settings.name === 'wibbank' || settings.name === 'oriental_bank') && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <GlobalOutlined style={{ fontSize: '1.063rem', color: settings.colors.primary }} />
            <Select
              bordered={false}
              placeholder={t('alterar idioma')}
              style={{ fontWeight: 'bold', color: settings.colors.primary }}
              onChange={(value) => handleSelectedLanguage(value)}
            >
              <Option value="ptbr" style={{ width: '100%' }}>
                Português
              </Option>
              <Option value="zh">中文</Option>
            </Select>
          </div>
          )}
          <CnpjInfo />
          {(settings?.play_store_url && settings?.app_store_url) && (
          <ButtonContainer style={{ marginBottom: 54 }}>
            <a
              style={{ width: '50%' }}
              href={settings.app_store_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <IconButton alt="app store" src={appStore} />
            </a>
            <a
              style={{ width: '50%' }}
              href={settings.play_store_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <IconButton alt="google play " src={googlePlay} />
            </a>
          </ButtonContainer>
          )}
        </Content>

      </LoginSection>
      {showConfirmationModal ? componentModal() : alterPassword ? componentModalCpf() : null}
    </Container>
  ) : (
    <></>
  );
}

export default Main;
