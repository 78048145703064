import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
  margin: 20px auto;
`;

export const AccountsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  width: 50%;
  min-width: 100px;
  justify-content: space-between;
`;

export const AccountItem = styled.div`
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  margin: 10px;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
