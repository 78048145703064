import styled, { css } from 'styled-components';

export const Container = styled.span`
  background: ${(props) => props.backgroundcolor};
  color: #ebebeb;
  box-shadow: 0px 3px 6px ${(props) => props.shadowcolor};
  
  font-weight: 600;


  border: 0;
  font-size: 16px;
  border-radius: 32px;
  padding: 5px 25px;

  cursor: pointer;

  margin-right: ${(props) => (props.margin ? '60px' : 0)};

  ${(props) => props.right && css`
    float: right;
  `}

  ${(props) => props.center && css`
    text-align: center;
    margin: 0 auto;
    width: 100%;
  `}

  a {
    color: #fff;
  }

  &:hover {
    opacity: 0.8;
  }
`;
