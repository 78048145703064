import React, { useState, useCallback } from 'react';

import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { useAuth } from '../../hooks/auth';

import { Container } from './styles';

import { HandleFormatMoney } from '../../services/utils';

function Balance() {
  const [showBalance, setShowBalance] = useState(true);
  const { account } = useAuth();

  const toggleShowBalance = useCallback(() => {
    setShowBalance((oldState) => !oldState);
  }, []);

  return (
    <Container active={showBalance}>
      {showBalance ? (<span>{HandleFormatMoney(account.balance.balance_available)}</span>) : (
        <span />
      )}

      {showBalance
        ? (<FaEyeSlash onClick={toggleShowBalance} size={21} />)
        : (<FaEye size={21} onClick={toggleShowBalance} />)}

    </Container>
  );
}

export default Balance;
