import styled from 'styled-components';

export const Content = styled.div`
  max-height: 480px;
  min-height: 300px;
  overflow-y: auto;


  .ant-table-thead > tr > th, 
  .ant-table-tbody > tr > td, 
  .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 8px 16px;
  }

  .ant-table-row:nth-child(odd) {
    background: ${(props) => props.backgroundcolor};
  }

  .ant-table-row:hover {
    background: inherit;
  }

  .ant-table-cell {
    color: #1A1818;
    font-weight: 500;
  }

  .ant-table-cell:first-child {
    .icon {
      position: relative;
      svg {
        color: ${(props) => props.color};
      }

      div {
        position: absolute;
        width: 1px;
        height: 25px;
        background: #d5d5d5;
        top: 16px;
        left: 6px;
      }
    }
  }
`;
