import styled from 'styled-components';

export const Container = styled.div`
 font-size: 0.9rem;
 span {
  margin-right: 0.3rem;
 }
 p {
  text-align: justify;
  text-align-last: left;
  width: 100%;
 }
`;

export const ModalStyle = styled.div`
 header {
  font-size: 1.5rem;
  font-weight: 800;
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--label);
 }
`;
