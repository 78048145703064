import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { FaInfoCircle } from 'react-icons/fa';
import { CloseOutlined } from '@ant-design/icons';
import Button from '../../Button';

import { useSettings } from '../../../hooks/settings';

import {
  Container,
  Header,
  Content,
  Footer,
} from './styles';

const SendDocumentsModal = ({ title, visible, hidden }) => {
  const { settings } = useSettings();
  const history = useHistory();

  const handleButtonClick = useCallback(() => {
    history.replace('documents');

    hidden();
  }, [history, hidden]);

  return (
    <Modal
      wrapClassName="modal-password"
      centered
      visible={visible}
      onOk={hidden}
      closable={false}
      onCancel={hidden}
      footer={false}
      destroyOnClose
      style={{ padding: 0, margin: 0, borderRadius: '8px' }}
      bodyStyle={{ padding: 0 }}
    >
      <Container>
        <Header backgroundcolor={settings.colors.primary}>
          { title }
          <CloseOutlined onClick={hidden} />
        </Header>
        <Content>
          <FaInfoCircle color={settings.colors.primary} size={40} />
          <p>É necessário atualizar seus documentos</p>

          <Footer>
            <div>
              <Button
                style={{
                  backgroundColor: '#9e9e9e',
                  boxShadow: '0px 3px 6px #9e9e9e',
                }}
                onClick={hidden}
              >
                <span>Pular</span>
              </Button>
              <Button onClick={handleButtonClick}><span>Enviar documentos</span></Button>
            </div>
          </Footer>
        </Content>
      </Container>
    </Modal>
  );
};

SendDocumentsModal.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  hidden: PropTypes.func.isRequired,
};

export default SendDocumentsModal;
