import React, {
  useCallback, useRef, useState, useMemo, useEffect,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { notification, Switch, Tooltip } from 'antd';
import lodash from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import { FiArrowLeft } from 'react-icons/fi';
import { Row, Col } from '../../../../../../styles/components';
import Button from '../../../../../../components/Button';
import chargesApi from '../../../../../../services/charges';

import Loading from '../../../../../../components/Loading';
import { Content, Subtitle } from './styles';
import Notification from '../../../../../../components/Notification';
import Input from '../../../../../../components/Input';
import formErrors from '../../../../../../services/formErrors';
import { useAuth } from '../../../../../../hooks/auth';
import { NM_DOCUMENT } from '../../..';

const EditPartner = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(null);
  const [hideAfterDueDays, setHideAfterDueDays] = useState(true);
  const [currentData, setCurrentData] = useState(null);

  const [maxInstall, setMaxInstall] = useState(1);
  const formRef = useRef(null);
  const history = useHistory();
  const { token, user } = useAuth();
  const params = useParams();

//  useEffect(() => {
//    if (!GOD_DOCUMENTS.includes(user.document) && user.document !== NM_DOCUMENT) {
//     history.push('/educational-payments');
//    }
//  }, [user, history]);

  useEffect(() => {
    setLoading(true);
    chargesApi.get(`nmpay/partners/${params.document}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      setCurrentData(res.data);
      setMaxInstall(res.data.max_installments);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      notification.error({ message: 'Ocorreu um erro ao recuperar dados do parceiro.' });
      setLoading(false);
    });
  }, [params.document, token]);

  const handleSubmit = useCallback(async (data) => {
    setLoading(true);
    formRef.current.setErrors({});

    try {
      const schema = Yup.object().shape({
        document: Yup.string()
          .required('Digite o número do documento.')
          .matches(
            /^(((\d{3}.?){3}-?\d{2}))|(\d{2}){1}\.?(\d{3}){1}\.?(\d{3}){1}\/?(0001)-?(\d{2}){1}$/,
            'Esse número de documento não é válido.',
          ),
        name_1: Yup.string().required('Digite o nome do parceiro!'),
        name_2: Yup.string().required('Digite o nome 2'),
        zip_code: Yup.string(),
        city: Yup.string(),
        street: Yup.string(),
        state: Yup.string(),
        neighborhood: Yup.string(),
        number: Yup.string(),
        complement: Yup.string(),
        bank_number: Yup.string(),
        agency: Yup.string(),
        account: Yup.string(),
        digit: Yup.number(),
        max_due_days: Yup.number().required('Insira a validade!'),
        fine: Yup.number(),
        interest_percent: Yup.number(),
        discount_value: Yup.number(),
        min_installments: Yup.number(),
        max_installments: Yup.number(),
        max_payment_time: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = {
        name_1: data.name_1,
        name_2: data.name_2,
        zip_code: data.zip_code,
        city: data.city,
        state: data.state,
        neighborhood: data.neighborhood,
        number: data.number,
        complement: data.complement || 'N/A',
        bank_number: data.bank_number,
        agency: data.agency,
        account: data.account,
        digit: data.digit,
        max_due_days: data.max_due_days,
        fine: data.fine,
        interest_percent: data.interest_percent,
        discount_value: data.discount_value,
        min_installments: data.min_installments,
        max_installments: data.max_installments,
        // max_payment_time: data.max_payment_time,
        installments_fees: {
          1: data.installments_fees[1] ? +data.installments_fees[1] : 2.95,
          2: data.installments_fees[2] ? +data.installments_fees[2] : 2.95,
          3: data.installments_fees[3] ? +data.installments_fees[3] : 2.95,
          4: data.installments_fees[4] ? +data.installments_fees[4] : 2.95,
          5: data.installments_fees[5] ? +data.installments_fees[5] : 2.95,
          6: data.installments_fees[6] ? +data.installments_fees[6] : 2.95,
          7: data.installments_fees[7] ? +data.installments_fees[7] : 2.95,
          8: data.installments_fees[8] ? +data.installments_fees[8] : 2.95,
          9: data.installments_fees[9] ? +data.installments_fees[9] : 2.95,
          10: data.installments_fees[10] ? +data.installments_fees[10] : 2.95,
          11: data.installments_fees[11] ? +data.installments_fees[11] : 2.95,
          12: data.installments_fees[12] ? +data.installments_fees[12] : 2.95,
        },
        hide_after_due_date: hideAfterDueDays,
      };

      await chargesApi.put(`nmpay/partners/${params.document}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setLoading(false);
      notification.success({ message: 'Parceiro atualizado com com sucesso.' });

      return history.push('/educational-payments/partners');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        formRef.current.setErrors(errors);
        setLoading(false);

        return false;
      }

      notification.error({
        message: 'Occorreu um erro, tente novamente mais tarde.',
      });

      setLoading(false);
      return false;
    }
  }, [hideAfterDueDays, history, token, params.document]);

  const feesInputs = useMemo(() => {
    const installmentsNumber = lodash.range(1, (Number(maxInstall) + 1));

    return installmentsNumber.map((installment, index) => (
      <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom" key={installment}>
        <Input
          type="number"
          step="0.01"
          // defaultValue={0.00}
          id={String(index + 1)}
          name={`installments_fees.${String(index + 1)}`}
          label={index === 0
            ? `Taxa para ${index + 1} parcela (%)`
            : `Taxa para ${index + 1} parcelas (%)`}
          style={{ MozAppearance: 'textfield', WebkitAppearance: 'none' }}
        />
      </Col>
    ));
  }, [maxInstall]);

  return (
    <>
      <Loading hasLoading={loading} />

      <Content style={{ paddingBottom: 100 }}>
        <Notification
          type="error"
          visible={!!error}
          title="Erro ao cadastrar parceiro"
          hidden={() => setError('')}
        >
          {error}
        </Notification>
        <Notification
          visible={!!success}
          title="Parceiro editado!"
          hidden={() => setSuccess('')}
        >
          {success}
        </Notification>
        <Tooltip title="voltar">
          <FiArrowLeft size={24} onClick={() => { history.push('/educational-payments/partners'); }} />
        </Tooltip>
        <h1>Edição de parceiro</h1>

        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={
            currentData
          }
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
              <Input
                name="name_1"
                id="name_1"
                label="Razão Social"
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
              <Input
                name="name_2"
                id="name_2"
                label="Nome Fantasia"
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
              <Input
                name="document"
                id="document"
                label="Documento(CNPJ)"
                maxLength={14}
                length={14}
                disabled
              />
            </Col>
          </Row>
          <Subtitle>Endereço</Subtitle>

          <Row gutter={16}>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="bottom">
              <Input
                name="neighborhood"
                id="neighborhood"
                label="Endereço"
              />
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} className="bottom">
              <Input
                name="number"
                id="number"
                label="Número"
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
              <Input
                name="complement"
                id="complement"
                label="Complemento"
              />
            </Col>
          </Row>

          <Row gutter={16}>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
              <Input
                name="zip_code"
                id="zip_code"
                label="CEP"
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="bottom">
              <Input
                name="city"
                id="city"
                label="Cidade"
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="">
              <Input
                name="state"
                id="state"
                label="Estado"
              />
            </Col>
          </Row>
          <Subtitle>Financeiro</Subtitle>

          <Row gutter={16}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
              <Input
                name="bank_number"
                id="bank_number"
                label="Número do banco"
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6} className="bottom">
              <Input
                name="agency"
                id="agency"
                label="Agência Bancária"
              />
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} className="bottom">
              <Input
                name="account"
                id="account"
                label="Conta Bancária"
              />
            </Col>
            <Col xs={24} sm={24} md={2} lg={2} xl={2} className="bottom">
              <Input
                name="digit"
                id="digit"
                label="Dígito"
                type="number"
              />
            </Col>

          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
              <Input
                name="max_due_days"
                id="max_due_days"
                type="number"
                label="Max. dias após venc."
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
              <Input
                name="fine"
                id="fine"
                label="Multa(%)"
                type="number"
              />
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} className="bottom">
              <Input
                name="interest_percent"
                id="interest_percent"
                label="Juros(%)"
                type="number"
              />
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} className="bottom">
              <Input
                name="discount_value"
                id="discount_value"
                label="Desconto"
                type="number"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
              <Input
                name="min_installments"
                id="min_installments"
                label="Mínimo de parcelas"
                type="number"
                min="1"
              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
              <Input
                name="max_installments"
                id="max_installments"
                value={maxInstall}
                max="12"
                min="1"
                label="Máximo de parcelas"
                type="number"
                onChange={(e) => {
                  if (Number(e.target.value) > 12) {
                    setMaxInstall(12);
                  } else {
                    setMaxInstall(e.target.value);
                  }
                }}

              />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="bottom">
              <Input
                name="max_payment_time"
                id="max_payment_time"
                label="Horário Limite de Pagamento"
                type="time"
              />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={6}
              lg={6}
              xl={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              className="bottom"
            >
              Esconder Cobranças atrasadas?

              <Switch
                onChange={(e) => setHideAfterDueDays(e)}
                defaultChecked={hideAfterDueDays}
                checkedChildren="Sim"
                unCheckedChildren="Não"
                id="hideAfterDueDaysSwitch"
                name="hideAfterDueDaysSwitch"
              />
            </Col>

          </Row>
          <Subtitle>Taxas</Subtitle>
          <Row gutter={16}>
            {feesInputs}
          </Row>
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '36px' }}>
            <Button disabled={!!loading} type="submit">
              {loading ? <span><LoadingOutlined /></span> : <span>Enviar</span> }
            </Button>
          </div>
        </Form>
      </Content>
    </>
  );
};

export default EditPartner;
