import styled from 'styled-components';

export const WarningDisplay = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 text-align: center;
 align-content: center;
 align-items: center;
 height: 100%;
 /* div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
 }
 div > span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 45%;
 }
 div > h1 {
  color: var(--btn-text);
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  text-align-last: center;
  margin-bottom: 1rem;
  margin-top: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
   'Helvetica Neue', sans-serif;
  line-height: 120%;
  width: 100%;
 } */
`;
