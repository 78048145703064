import styled from 'styled-components';

export const ContainerStyledEmail = styled.div`
 h1 {
  font-size: 1.5rem;
  margin-top: 20px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
 }
`;

export const Title = styled.span`
 font-size: 1rem;
 font-weight: 700;
 text-align: center;
`;
export const DualButtonsDisposition = styled.nav`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 height: 70%;
 min-height: 7vh;
 div {
  width: 45%;
 }
 button {
  min-width: 100%;
 }
`;
