import styled from 'styled-components';

export const TextStyled = styled.text`
 align-items: center;
 font-size: 1rem;
 padding-bottom: 1.4rem;
 display: flex;
 gap: 0.5rem;

 b {
 display: flex;
 align-self: flex-end;
 background: green;
 }
`;

export const Section = styled.section`
 @media screen and (min-width: 48.1rem) {
 display: flex;
 position: absolute;
 left: 0rem;
 top: 0rem;
 max-width: 50%;
 height: 100vh;
 background-color: rgba(15, 15, 15, 0.5);
 overflow-y: scroll;
 }

 @media screen and (min-width: 62rem) {
 max-width: 65%;
 }
`;

export const Card = styled.section`
 background: var(--white);
 padding: 2rem;
 width: 100%;
 max-width: 25rem;
 border-radius: 0.7rem;
 margin-top: 5%;
 @media screen and (min-width: 48.1rem) {
 height: 25rem;
 margin-top: 8%;
 }
 @media screen and (min-width: 89.1rem) {
 align-self: center;
 margin-top: 15%;
 }
`;

export const Texts = styled.div`
 @media screen and (min-width: 48.1rem) {
 display: flex;
 align-items: center;
 flex-direction: column;
 margin-top: 1rem;
 }
`;

export const MainTitle = styled.span`
 font-size: 1.2rem;
 font-weight: bold;
 text-align: center;
 display: flex;
 @media screen and (min-width: 48.1rem) {
 color: var(--white);
 padding-top: 1rem;
 }
`;

export const Subtitle = styled.div`
 padding: 1rem;
 text-align: center;
 display: flex;
 margin-bottom: 1rem;
 max-width: 25rem;
 @media screen and (min-width: 48.1rem) {
 color: var(--white);
 padding-top: 1rem;
 margin-bottom: 0;
 position: absolute;
 margin-top: 3%;
 }
`;

export const Options = styled.section`
 display: flex;
 justify-content: center;
 height: 100%;
`;

export const Option = styled.span`
 display: flex;
 width: 100%;
 font-weight: bold;
 justify-content: space-between;
 border-bottom: 0.16rem solid var(--primary);
 align-items: center;
 margin-bottom: 1rem;
 cursor: pointer;
`;

export const Container = styled.div`
 display: flex;
 width: 70%;
 align-items: center;
 flex-direction: column;
 justify-content: center;
 h1 {
 color: #393939;
 font-size: 1.2rem;
 margin: 20px;
 width: 300px;
 text-align: center;
 @media screen and (min-width: 48.1rem) {
 display: none;
 }
 }
`;

export const WarningDisplay = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 text-align: center;
 align-content: center;
 align-items: center;
 width: 100%;
 height: 100%;
 div {
 display: flex;
 flex-direction: column;
 justify-content: space-between !important;
 align-items: center;
 width: 379px;
 height: 212px;
 max-width: none;
 max-height: none;
 }
 div > h3 {
 color: var(--btn-text);
 font-size: 1.5rem !important;
 font-weight: bold !important;
 margin: 20px;
 text-align: center;
 text-align-last: center;
 margin: 0;
 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
 'Helvetica Neue', sans-serif;
 font-style: normal;
 font-weight: bold;
 font-size: 1.2rem;
 line-height: 120%;
 max-width: 379px;
 }
 text {
 display: inline-block;
 color: #393939;
 height: 30%;
 width: 90%;
 text-align: justify;
 text-align-last: center;
 font-size: 1rem;
 text-align-last: center;
 justify-content: center;
 }
`;

export const Box = styled.div`
 display: flex;
 width: 21rem;
 height: 21rem;
 flex-direction: column;
 align-items: center;
 gap: 2rem;
 @media screen and (max-width: 48.1rem) {
 background: var(--label);
 width: 15rem;
 height: 17rem;
 align-items: center;
 border-radius: 10px;
 }
 h2 {
 display: flex;
 font-size: 0.9rem;
 font-weight: bold;
 justify-content: center;
 @media screen and (max-width: 48.1rem) {
 color: var(--white);
 margin: 0.5rem;
 text-align: center;
 }
 }
`;

export const DropContainer = styled.div`
 display: flex;
 justify-content: center;
`;

export const DropButton = styled.div`
 display: flex;
 align-self: center;
 padding: 2rem;
 height: 7rem;
 width: 7rem;
 border-radius: 50%;
 font-size: 0.85rem;
 align-items: center;
 background: var(--primary);
 color: var(--white);
 cursor: pointer;
 font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
 'Helvetica Neue', sans-serif;
 font-style: normal;
 font-weight: bold;
 @media screen and (min-width: 48.1rem) {
 margin-top: 7rem;
 }
 @media screen and (min-width: 89.1rem) {
 margin-top: 9rem;
 }
`;

export const Displayer = styled.section`
 display: flex;
 height: fit-content !important;
 width: fit-content !important;
 justify-content: center;
 align-items: center;
 overflow: hidden;
 position: relative;

 iframe {
 width: 20rem;
 height: 20rem;
 border: none;
 cursor: pointer;
 }
 @media screen and (min-width: 48.1rem) {
 height: 100%;
 }
`;

export const Overlay = styled.section`
 position: absolute;
 background-color: #000000ab;
 height: 100%;
 width: 100%;
 opacity: 0 !important;
 cursor: pointer;
 &:hover {
 opacity: 1 !important;
 display: flex;
 flex-direction: row;

 span {
 margin: 1rem;
 text-align: center;
 color: white;
 font-weight: bold;
 }
 }
`;
export const Preview = styled.img`
 margin: 0 !important;
 max-height: 100% !important;
 max-width: 100% !important;
 background-repeat: no-repeat;
 object-fit: cover;
 background-image: ${(props) => props.src};
`;
export const Buttons = styled.div`
 display: flex;
 align-items: center;
 flex-direction: row;
 gap: 0.5rem;
 width: 100%;
 margin-top: 1rem;
 @media screen and (min-width: 48.1rem) {
 width: 50%;
 margin-bottom: 1rem;
 }
`;

export const Text = styled.span`
 align-items: center;
 font-size: 1rem;
 padding-bottom: 1.4rem;
 display: flex;
 gap: 0.5rem;
`;

export const Hide = styled.section`
 @media screen and (max-width: 48.1rem) {
 }
`;

export const Tips = styled.div`
 display: flex;
 gap: 0.5rem;
 align-items: left;
 flex-direction: column;
`;

export const Cartao = styled.div`
 position: absolute;
 left: 0rem;
 top: 0px;
 padding: 1rem;
 width: 100%;
`;
export const ButtonsDisposition = styled.nav`
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 height: 50%;
 div {
 height: 80%;
 width: 100%;
 }
 button {
 height: 100%;
 }
`;
