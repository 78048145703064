/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { zip } from 'lodash';
import ButtonN from '../../../components/ButtonN';
import { useRefreshPj } from '../../../hooks/refreshPj';
import InputN from '../../../components/InputN';
import {
  Container,
  InputContainer,
  Subtitle,
  Text,
  TextContainer,
  Title,
  NumberContainer,
  InputArea,
  CheckArea,
  CheckInput,
  CheckText,
} from '../styles';
import { getAddress } from '../../../services/getAddress';
import api from '../../../services/api';
import { cepMask } from '../../../utilitys/Masks/cepMask';
import { validNumber } from '../utils/numberValidator';
import Alert from '../../../components/SpanError';
import messages from '../../../components/MessageError';

export function Address() {
  const {
    setStep, address, setAddress, accountId, tokenPj,
  } = useRefreshPj();
  const { getCep } = getAddress();

  const [loading, setLoading] = React.useState(false);
  const [addressInfoFound, setAddressInfoFound] = React.useState({
    street: false,
    neighborhood: false,
    number: false,
    city: false,
    state: false,
    country: false,
  });
  const [disableNumber, setDisableNumber] = React.useState(false);
  const [checkCep, setCheckCep] = React.useState(false);
  const {
    city,
    complement,
    neighborhood,
    number,
    state,
    street,
    zipCode,
  } = address;

  const validHouseNumber = number.length <= 0 || !validNumber.test(number);

  const handleNext = async () => {
    await api.put(`/onboarding/address/${address.id}`, {
      zip_code: address.zipCode.replace('-', ''),
      street: address.street,
      neighborhood: address.neighborhood,
      number: address.number,
      complement: address.complement,
      city: address.city,
      state: address.state,
      country: 'Brasil',
    });
    setStep('documents');
  };

  const handleChange = (key, value) => {
    setAddress({ ...address, [key]: value });
  };

  const handleCep = async (cep) => {
    if (checkCep) {
      setLoading(true);
      const result = await getCep(cep);

      if (!result) {
        message.error('CEP não encontrado', 4);
        setAddress({
          ...address,
          city: '',
          neighborhood: '',
          state: '',
          street: '',
          number: '',
          complement: '',
        });
        setLoading(false);
        return;
      }

      if (!result.error) {
        setAddress({
          ...address,
          city: result.city,
          neighborhood: result.neighborhood,
          state: result.state,
          street: result.street,
          zipCode: result.zip_code.replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1'),
        });
        const tempObj = {
          city: false,
          neighborhood: false,
          state: false,
          street: false,
          zipCode: false,
        };

        for (const key in result) {
          if (result[key]) {
            tempObj[key] = true;
          }
        }
        setAddressInfoFound(tempObj);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (zipCode > 8) {
      setCheckCep(true);
    }
    if (checkCep && zipCode.length === 8) {
      handleCep(zipCode);
    }
  }, [zipCode]);

  const buttonDisabled = city && neighborhood && number && state && street;

  const handleNumberCheck = () => {
    setDisableNumber(!disableNumber);
    handleChange('number', 'S/N');
  };

  React.useEffect(() => {
    if (number === 'S/N' && !disableNumber) {
      handleChange('number', '');
    }
  }, [disableNumber]);

  return (
    <Container>
      <InputContainer>
        <TextContainer>
          <Title>Qual o endereço da empresa?</Title>
          <Subtitle>Nos informe o endereço da empresa</Subtitle>
          <Text>Agora precisamos que você nos passe as informações sobre a localização da matriz.</Text>
        </TextContainer>
        <InputN.Cep
          label="CEP"
          id="cep"
          maxLength={8}
          disabled={loading}
          value={cepMask(zipCode)}
          onChange={({ target: { value } }) => handleChange('zipCode', value.replace(/\D/g, ''))}
        />
        {loading ? <Spin indicator={<LoadingOutlined />} />
          : (
            <>
              <InputN
                label="Endereço"
                id="endereco"
                disabled={addressInfoFound.street}
                value={street}
                onChange={({ target: { value } }) => handleChange('street', value)}
              />
              <InputN
                label="Bairro"
                id="bairro"
                disabled={addressInfoFound.neighborhood}
                value={neighborhood}
                onChange={({ target: { value } }) => handleChange('neighborhood', value)}
              />
              <NumberContainer>
                <InputArea>
                  <InputN
                    placeholder="Insira o número"
                    label="Número"
                    id="numero"
                    isValid={!validHouseNumber}
                    disabled={disableNumber}
                    value={number}
                    onChange={({ target: { value } }) => handleChange('number', value.replace(/\D/g, ''))}
                  />
                  {!validHouseNumber && <Alert type="error" message={messages?.numberInvalid} />}
                </InputArea>
                <CheckArea>
                  <CheckInput type="checkbox" onClick={handleNumberCheck} checked={disableNumber} />
                  <CheckText>Sem número</CheckText>
                </CheckArea>
              </NumberContainer>

              <InputN
                placeholder="Como apartamento, quadra, bloco"
                label="Complemento"
                id="complemento"
                value={complement}
                onChange={({ target: { value } }) => handleChange('complement', value)}
              />
              <InputN
                label="Estado"
                id="estado"
                disabled={addressInfoFound.state}
                value={state}
                onChange={({ target: { value } }) => handleChange('state', value)}
              />
              <InputN
                label="Cidade"
                id="cidade"
                disabled={addressInfoFound.city}
                value={city}
                onChange={({ target: { value } }) => handleChange('city', value)}
              />
            </>
          )}
      </InputContainer>
      <ButtonN type="primary" onClick={handleNext} disabled={!buttonDisabled}>
        Continuar
      </ButtonN>
    </Container>
  );
}
