/* eslint-disable no-useless-escape */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { setLocale } from 'yup';
import { YoutubeFilled } from '@ant-design/icons';
import { Content } from './styles';
import { Row, Col } from '../../styles/components';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import Loading from '../../components/Loading';
import formErrors from '../../services/formErrors';

import { useAuth } from '../../hooks/auth';
import { useSettings } from '../../hooks/settings';

function Store() {
  const formRef = useRef();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [hasAccount, setHasAccount] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { settings } = useSettings();
  const { t } = useTranslation();

  const getAccountExists = useCallback(async () => {
    try {
      const { data } = await axios.get(`https://ecommerce.qesh.ai/users/email?email=${user.email}`);
      if (data && data.id) {
        setHasAccount(true);
      } else {
        setHasAccount(false);
      }
    } catch (err) {
      setHasAccount(false);
    }
  }, [user.email]);

  const checkCep = useCallback(async () => {
    if (formRef && formRef.current
      && (formRef.current.getFieldValue('zip_code').length === 8 || formRef.current.getFieldValue('zip_code').length === 9)) {
      axios.get(
        `https://viacep.com.br/ws/${formRef.current.getFieldValue('zip_code')}/json/unicode`,
      ).then((resp) => {
        formRef.current.setData({
          street: resp.data.logradouro,
          city: resp.data.localidade,
          neighborhood: resp.data.bairro,
          state: resp.data.uf,
        });
        setRefresh(!refresh);
      }).catch(() => { });
    }
  }, [formRef, refresh]);

  async function handleForm(data) {
    formRef.current.setErrors({});
    setError('');
    try {
      const schema = Yup.object().shape({
        storename: Yup.string()
          .typeError(t('o nome da sua loja e obrigatorio'))
          .required(t('o nome da sua loja e obrigatorio'))
          .max(40, t('o nome da loja deve possuir no maximo 40 caracteres')),
        password: Yup.string()
          .typeError(t('a senha deve ter pelo menos 8 digitos'))
          .min(8, t('a senha deve ter pelo menos 8 digitos'))
          .required(t('a senha e obrigatoria e deve ter pelo menos 8 digitos')),
        confpassword: Yup.string()
          .typeError(t('confirme sua senha'))
          .required(t('confirme sua senha'))
          .oneOf([Yup.ref('password'), null], t('as senhas devem ser identicas')),
        phone: Yup.string()
          .typeError(t('o telefone e obrigatorio'))
          .required(t('o telefone e obrigatorio'))
          .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, t('o telefone deve ser no formato...')),
        store_description: Yup.string()
          .typeError(t('a descricao da loja e obrigatoria e em forma de texto'))
          .required(t('a descricao da loja e obrigatoria'))
          .max(64, t('a descricao da loja debe possuir no maximo 64 caracteres')),
        min_payment: Yup.number()
          .typeError(t('insira um pagamento minimo')),
        street: Yup.string()
          .typeError(t('a rua do estabelecimento e obrigatoria'))
          .required(t('a rua do estabelecimento e obrigatoria')),
        neighborhood: Yup.string()
          .typeError(t('o bairro do estabelecimento e obrigatorio'))
          .required(t('o bairro do estabelecimento e obrigatorio')),
        number: Yup.number()
          .typeError(t('o numero do estabelecimento e obrigatorio'))
          .required(t('o numero do estabelecimento e obrigatorio'))
          .positive(t('numero possui formato errado'))
          .integer(t('numero possui formato errado'))
          .min(1, t('numero possui pelo menos um digito, apenas numeros')),
        complement: Yup.string()
          .typeError(t('o complemento deve ser um texto')),
        zip_code: Yup.string()
          .typeError(t('o cep deve ser um texto'))
          .required(t('o cep do estabelecimento e obrigatorio'))
          .matches(/^\d{5}-?\d{3}$/, t('o cep deve estar no formato...')),
        city: Yup.string()
          .typeError(t('a cidade e obrigatoria'))
          .required(t('a cidade do estabelecimento e obrigatoria'))
          .matches(/[A-Za-zÀ-ȕ ]/, t('a cidade deve ser descrita apenas por texto')),
        state: Yup.string()
          .typeError(t('o estado e obrigatorio'))
          .required(t('o estado do estabelecimento e obrigatorio'))
          .matches(/[A-Za-zÀ-ȕ ]/, t('o estado deve ser descrito apenas por texto')),

      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);
      const accountData = user.baas.accounts[0];
      const { data: storeInfo } = await axios.post('https://ecommerce.qesh.ai/users', {
        name: data.storename,
        email: user.email,
        password: data.password,
        phone: data.phone,
        store_description: data.store_description,
        min_payment: data.min_payment,
        address: {
          street: data.street,
          neighborhood: data.neighborhood,
          zip_code: data.zip_code,
          number: data.number,
          complement: data.complement,
          city: data.city,
          state: data.state,
          country: 'Brasil',
        },
        account_id: accountData.id,
        account_info: {
          name: data.storename,
          document: user.document,
          bank: accountData.bank_code,
          agency: accountData.bank_branch,
          account: accountData.bank_account,
          type: 'CC',
        },
        bank_user_id: user.id,
        product_name: settings.name,
      });

      setSuccess(t('loja cadastrada com sucesso'));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        formRef.current.setErrors(errors);
        return;
      }
      setError(t('cadastro invalido. verifique se todos os campos...'));
    }
  }

  useEffect(() => {
    getAccountExists();
  }, [getAccountExists]);

  if (hasAccount === null) {
    return (<Loading hasLoading />);
  }

  return (
    <>
      <Content>
        <Notification
          type="error"
          visible={!!error}
          title={t('erro ao cadastrar a loja')}
          hidden={() => setError('')}
        >
          {error}
        </Notification>
        <Notification
          visible={!!success}
          title={t('loja cadastrada com sucesso')}
          hidden={() => { setSuccess(''); getAccountExists(); }}
        >
          {success}
        </Notification>
        <Loading hasLoading={loading} />
        <h1>{t('loja online')}</h1>

        {hasAccount ? (
          <>
            <Row gutter={16}>
              <Col md={24}>
                <a href="https://loja.qesh.ai/" rel="noreferrer" target="_blank">
                  <Button
                    style={{ marginTop: '10px' }}
                    right={(false)}
                  >
                    <span href="https://loja.qesh.ai/" rel="noreferrer" target="_blank">{t('acessar a sua loja')}</span>
                  </Button>
                </a>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <h2>{t('crie a sua loja virtual')}</h2>
            <Form ref={formRef} onSubmit={handleForm}>
              <div>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10} className="storename">
                    <Input id="storename" name="storename" label={t('escolha o nome da sua loja')} type="text" />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} className="phone">
                    <Input id="phone" name="phone" label={t('telefone para contato')} type="tel" />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="store_description">
                    <Input id="store_description" name="store_description" label={t('descricao da loja')} type="text" />
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} className="min_payment">
                    <Input
                      id="min_payment"
                      name="min_payment"
                      step="0.1"
                      label={t('compra minima...')}
                      type="number"
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={9} lg={9} xl={9} className="password">
                    <Input id="password" name="password" label={t('crie uma senha de acesso')} type="password" />
                  </Col>
                  <Col xs={24} sm={24} md={9} lg={9} xl={9} className="confpassword">
                    <Input id="confpassword" name="confpassword" label={t('confirme a senha')} type="password" />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} className="zip_code">
                    <Input onChange={checkCep} id="zip_code" name="zip_code" label={t('cep')} type="text" />
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10} className="street">
                    <Input id="street" name="street" label={t('rua')} type="text" />
                  </Col>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10} className="neighborhood">
                    <Input id="neighborhood" name="neighborhood" label={t('bairro')} type="text" />
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} className="number">
                    <Input id="number" name="number" label={t('numero')} type="number" />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="complement">
                    <Input id="complement" name="complement" label={t('complemento')} type="text" />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={10} lg={10} xl={10} className="city">
                    <Input id="city" name="city" label={t('cidade')} type="text" />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} className="state">
                    <Input id="state" name="state" label={t('estado')} type="text" />
                  </Col>
                </Row>
                {/* <Row gutter={16}>
                  <Col xs={24} sm={24} md={14} lg={14} xl={14} className="country">
                    <Input id="country" name="country" label="País" type="text" />
                  </Col>
                </Row> */}
                <Row gutter={16}>
                  <Col md={24}>
                    <Button right={(false)} style={{ marginTop: '10px' }} type="submit">
                      <span>{t('confirmar cadastro')}</span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </>
        )}
      </Content>
    </>
  );
}

export default Store;
