import * as FaIcon from 'react-icons/fa';
import React from 'react';
import api from './api';

function findGetParameter(parameterName) {
  let result = null;
  let tmp = [];
  // eslint-disable-next-line no-restricted-globals
  const items = location.search.substr(1).split('&');
  for (let index = 0; index < items.length; index += 1) {
    tmp = items[index].split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    console.log({ items: items[index] });
  }
  return result;
}

const urlClient = () => {
  const productos = [
    { name: 'braip', value: 'braip' },
    { name: 'banksul', value: 'banksul' },
    { name: 'cdlroo', value: 'cdlroo' },
    { name: 'marinabank', value: 'marinabank' },
    { name: 'vibe', value: 'vibe' },
    { name: 'nmpay', value: 'nmpay' },
    { name: 'nobe', value: 'nobe' },
    { name: 't28', value: 't28' },
    { name: 'wip', value: 'wip' },
    { name: 'propagar', value: 'propagar' },
    { name: 'somos', value: 'somosbank' },
    { name: 'guarudigital', value: 'guarudigital' },
    { name: 'flexbank', value: 'flexbank' },
    { name: 'faemg', value: 'faemg' },
    { name: 'dubaibank', value: 'dubaibank' },
    { name: 'ib-vistapay', value: 'vistapay' },
    { name: 'brzbank', value: 'brzbank' },
    { name: 'royalpago', value: 'royalpago' },
    { name: 'conectbank', value: 'conectbank' },
    { name: 'sinapsecapital', value: 'sinapsecapital' },
    { name: 'cashpag', value: 'cashpag' },
    { name: 'paygress', value: 'paygress' },
    { name: 'bancovida', value: 'bancovida' },
    { name: 'imobibank', value: 'imobibank' },
    { name: 'shiftbank', value: 'shiftbank' },
    { name: 'shiftbanco', value: 'shiftbank' },
    { name: 'nxt1', value: 'nxt1' },
    { name: 'seller', value: 'seller' },
    { name: 'mpmm', value: 'mpmm' },
    { name: 'myphonemymoney', value: 'mpmm' },
    { name: 'itabiritodigital', value: 'itabiritodigital' },
    { name: 'wibbank', value: 'wibbank' },
    { name: 'chofer', value: 'chofer' },
    // { name: 'galera', value: 'galera' },
    { name: 'qeshdev', value: 'qeshdev' },
    { name: 'diverse', value: 'diverse' },
    { name: 'xmax', value: 'xmax' },
    { name: 'tikbank', value: 'tikbank' },
    { name: 'vctrade', value: 'vctrade' },
    { name: 'sorteesportiva', value: 'sorteesportiva' },
    { name: 'urano', value: 'urano' },
    { name: 'biosbank', value: 'biosbank' },
    { name: 'terrabank', value: 'terra_bank' },
    { name: 'orientalbank', value: 'oriental_bank' },
    { name: 'ancora', value: 'ancora' },
    { name: 'whin', value: 'whin' },
    { name: 'fxo', value: 'fxo_bank' },
    { name: 'blacksig', value: 'black_sig' },
    { name: 'fastbank', value: 'fast_bank' },
    { name: 'gainbonus', value: 'zuch_tech' },
  ];

  for (let i = 0; i < productos.length; i += 1) {
    if (window.location.host.includes(productos[i].name)) {
      return productos[i].value;
    }
  }
  return false;
};
const paramClient = findGetParameter('client');

export const CLIENT = paramClient || urlClient() || 'qesh';

export const getSettings = async () => {
  try {
    const { data: settings } = await api.get('/client-settings', {
      headers: {
        name: CLIENT,
      },
    });
    return settings;
  } catch (error) {
    return null;
  }
};

export const customOptions = {
  cdlroo: [
    {
      name: 'Loja online',
      path: '/store',
      type: 'default',
      icon: <FaIcon.FaShoppingCart size={23} />,
    },
  ],
  nobe: [
    {
      name: 'Acesso antigo',
      path: 'https://nobe.qesh.ai',
      type: 'link',
      icon: <FaIcon.FaSignInAlt size={23} />,
    },
  ],
  t28: [
    {
      name: 'Extrato antigo',
      path: 'https://extrato-t28.qesh.ai',
      type: 'link',
      icon: <FaIcon.FaSignInAlt size={23} />,
    },
  ],
  guarudigital: [
    {
      name: 'Loja online',
      path: '/store',
      type: 'default',
      icon: <FaIcon.FaShoppingCart size={23} />,
    },
    {
      name: 'Saque',
      path: '/withdraw',
      type: 'default',
      icon: <FaIcon.FaMoneyBill size={23} />,
    },
  ],
  ourobrancodigital: [
    {
      name: 'Loja online',
      path: '/store',
      type: 'default',
      icon: <FaIcon.FaShoppingCart size={23} />,
    },
    {
      name: 'Saque',
      path: '/withdraw',
      type: 'default',
      icon: <FaIcon.FaMoneyBill size={23} />,
    },
  ],
  itabiritodigital: [
    {
      name: 'Loja online',
      path: '/store',
      type: 'default',
      icon: <FaIcon.FaShoppingCart size={23} />,
    },
    {
      name: 'Saque',
      path: '/withdraw',
      type: 'default',
      icon: <FaIcon.FaMoneyBill size={23} />,
    },
  ],
  conectbank: [
    {
      name: 'Loja online',
      path: '/store',
      type: 'default',
      icon: <FaIcon.FaShoppingCart size={23} />,
    },
  ],
  somosbank: [
    {
      name: 'Extrato antigo',
      path: 'https://extrato-somosbank.qesh.ai',
      type: 'link',
      icon: <FaIcon.FaSignInAlt size={23} />,
    },
  ],
  nmpay: [
    {
      name: 'Pagamentos Educacionais',
      path: '/educational-payments',
      type: 'default',
      icon: <FaIcon.FaGraduationCap size={23} />,
    },
  ],
  faemg: [
    {
      name: 'Extrato antigo',
      path: 'https://extrato-faemg.qesh.ai',
      type: 'link',
      icon: <FaIcon.FaSignInAlt size={23} />,
    },
  ],
  vibe: [
    {
      name: 'e-Vibe',
      path: '/evibe',
      type: 'default',
      icon: <FaIcon.FaShoppingCart size={23} />,
    },
  ],
  royalpago: [
    {
      name: 'Saque',
      path: '/withdraw',
      type: 'default',
      icon: <FaIcon.FaMoneyBill size={23} />,
    },
  ],
  shiftbank: [
    {
      name: 'Saque',
      path: '/withdraw',
      type: 'default',
      icon: <FaIcon.FaMoneyBill size={23} />,
    },
  ],
};

export const defaultColors = {
  primaryColor: '#000000',
  secondaryColor: '#FFFFFF',
};
