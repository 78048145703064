import styled from 'styled-components';

export const InputColumn = styled.div`
  width:100%;
  min-height:15%;
  height:fit-content; 
  display:flex;
  justify-content: space-between;
  align-items:flex-end;
`;

export const InputWrapper = styled.div`
  width:${(props) => `${props.size}`};
  height:fit-content;
  display:flex;
  gap:40px;
  position:relative;
  display:flex;
  justify-content:space-evenly;
  align-items:flex-end;
`;

export const TimeInputWrapper = styled.div`
  width:30%;
  height:100%;
  display:flex;
  justify-content:space-evenly;
  align-items:flex-end;
  position:relative;
  gap:15px;
`;

export const CheckBoxWrapper = styled.div`
  min-width:90px;
  width:fit-content;
  height:fit-content;
  display:flex;
  gap:10px;
  justify-content:space-evenly;
  align-items:center;
  position:absolute;
  bottom:-30px;
  right:0;
`;
