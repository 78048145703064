import React from 'react';
import RegisterHeader from '../../components/RegisterHeader';
import {
  Board, Cell, Clause, ClauseTitle, Container, Header, List, Paragraph, Point, Row, Table,
} from './style';

function Privacy() {
  return (
    <>
      <RegisterHeader noTitle />
      <Container>
        <Paragraph>
          Esta Política de Privacidade (“Política”) se aplica a todos os serviços prestados pela QESH
          INSTITUIÇÃO DE PAGAMENTO LTDA (“QESH”), pessoa jurídica de direito privado, inscrita
          no CNPJ/MF sob o nº 31.818.873/0001-30, com sede na Alameda do Ingá, nº 16, Sala 2,
          Vale do Sereno, Nova Lima/MG, CEP 34.006-042, em razão da utilização pelo USUÁRIO
          dos Serviços prestados pela QESH.
        </Paragraph>
        <Board>
          <Paragraph>
            A presente Política tem o objetivo de informar, de forma clara e completa, sobre
            como será o Tratamento dos Dados Pessoais do USUÁRIO, em decorrência da
            utilização dos Serviços prestados pela QESH.
          </Paragraph>
          <Paragraph>A QESH divulga sua Política para proteger a privacidade do USUÁRIO, garantindo
            que o Tratamento dos Dados Pessoais servirá apenas para possibilitar a prestação
            dos Serviços ou para outras finalidades previstas nesta Política.
          </Paragraph>
          <Paragraph>Ao utilizar os Serviços da QESH, de acordo com as condições previstas nos
            Termos e Condições de Uso, o USUÁRIO declara-se ciente desta Política e com o
            modo como se dará o Tratamento de seus Dados Pessoais pela QESH. Caso o
            USUÁRIO não concorde com o Tratamento de seus Dados Pessoais, na forma
            prevista nesta Política, deverá se abster de realizar o Cadastro no Sistema de
            Pagamentos e de utilizar os Serviços da QESH.
          </Paragraph>
          <Paragraph>Caso o USUÁRIO não queira divulgar os seus Dados Pessoais ou exija sua
            exclusão, o Cadastro no Sistema de Pagamentos poderá ser negado e/ou a
            prestação dos Serviços poderá ser limitada, visto que o uso dos Dados Pessoais é
            necessário para essas finalidades.
          </Paragraph>
          <Paragraph>Caso o USUÁRIO seja pessoa jurídica, algumas condições previstas nesta Política
            poderão não ser aplicáveis, nos termos da Legislação Aplicável, ou se aplicará às
            pessoas físicas responsáveis pela utilização direta do Sistema de Pagamentos.
          </Paragraph>
          <Paragraph>Para maiores informações sobre a utilização dos Serviços prestados pela QESH, o
            USUÁRIO deve consultar os Termos e Condições de Uso. A QESH poderá alterar as
            condições desta Política periodicamente, sendo que a versão atualizada poderá ser
            consultada a qualquer momento pelo USUÁRIO na Plataforma ou outro meio
            disponível pela QESH.
          </Paragraph>
        </Board>
        <ClauseTitle>1.Definições</ClauseTitle>
        <Clause>1.1.Sem prejuízo de outras definições constantes nesta Política ou no Termo de Uso, as
          palavras e expressões utilizadas pela primeira letra maiúscula, terão as seguintes
          definições:
        </Clause>
        <Paragraph>- “Cadastro”: dados pessoais e demais informações solicitadas pelas QESH,
          necessárias para o credenciamento e manutenção do USUÁRIO no Sistema de Pagamentos.
        </Paragraph>
        <Paragraph>- “Termo de Uso”: contrato que regula as regras, condições e limites dos Serviços
          prestados pela QESH ao USUÁRIO em razão da utilização do Sistema de Pagamentos.
        </Paragraph>
        <Paragraph>- “Dados Anonimizados”: Dados Pessoais que, isoladamente ou em conjunto com os
          Dados de Uso Técnico, Informações do Dispositivo e/ou Localização Geográfica, não
          permitem a identificação do USUÁRIO, considerando a utilização de meios técnicos
          disponíveis na ocasião de seu Tratamento.
        </Paragraph>
        <Paragraph>- “Dados de Uso Técnico”: informações que a QESH tratar em razão da utilização de
          dispositivo celular, computador ou outro dispositivo que o USUÁRIO utilizar para acessar a
          Plataforma e o Sistema de Pagamentos. Os Dados de Uso Técnico mostram como o
          USUÁRIO utiliza os serviços prestados pela QESH, incluindo o endereço IP (Protocolo da
          Internet), estatísticas sobre como as páginas são carregadas ou visualizadas, os sites que o
          USUÁRIO visitou e informações de navegação coletadas por meio de Cookies ou tecnologia
          semelhante.
        </Paragraph>
        <Paragraph>- “Dados Pessoais”: informações pessoais associadas ao USUÁRIO como uma pessoa
          física identificada ou identificável. Podem incluir nome de pessoa física, firma ou denominação
          social de pessoa jurídica a que esteja relacionado, autorretrato, endereço, número de telefone,
          e-mail, nome e número da instituição bancária, número de agência, número da conta corrente
          ou poupança (“Conta Bancária”), data de nascimento, nome completo da mãe, número ou
          cópia de documentos oficiais (por exemplo, RG, CNH, CPF, dentre outros). Os Dados de Uso
          Técnico e as Informações do Dispositivos serão considerados Dados Pessoais quando
          utilizados para individualizar o USUÁRIO ou sempre que seja possível sua identificação.
        </Paragraph>
        <Paragraph>- “Informações do Dispositivo”: dados que podem ser coletados automaticamente de
          qualquer dispositivo utilizado para acessar a Plataforma. Essas informações podem incluir,
          mas não se limitam ao tipo de dispositivo, conexões de rede do dispositivo, nome do
          dispositivo, endereço IP do dispositivo, informações sobre o navegador do dispositivo e a
          conexão de internet usada para acessar a Plataforma.
        </Paragraph>
        <Paragraph>- “Legislação Aplicável”: toda a legislação em vigor, aplicável sobre segurança da
          informação, privacidade e proteção de dados, incluindo, mas não se limitando, a Lei nº
          Qesh Instituição de Pagamento Ltda
          2
          POLÍTICA DE PRIVACIDADE
          13.709/2018 - Lei Geral de Proteção de Dados Pessoais, Lei nº 12.965/2014 - Marco Civil da
          Internet, Lei nº 8.078/1990 - Código de Defesa do Consumidor, Lei Complementar nº 166/2019
          - Lei do Cadastro Positivo, Lei nº 12.527/2011 - Lei de Acesso à Informação, Decreto nº
          7.962/2013 - Decreto Comércio Eletrônico e outras leis e normas aplicáveis ao Sistema de
          Pagamentos.
        </Paragraph>
        <Paragraph>- “Localização Geográfica”: informações que identificam a localização do USUÁRIO
          mediante, por exemplo, coordenadas de latitude e longitude obtidas por GPS, Wi-Fi ou
          triangulação de localização celular. A Plataforma pode solicitar permissão para compartilhar a
          localização atual do USUÁRIO. Se o USUÁRIO não concordar com a coleta das informações
          de Localização Geográfica, o Sistema de Pagamentos pode não funcionar adequadamente.
        </Paragraph>
        <Paragraph>- “Prestadores de Serviço”: prestadores de serviços, cujo sistema se encontra integrado
          com o Sistema de Pagamentos, para possibilitar a execução de determinados Serviços.
        </Paragraph>
        <Paragraph>- “Serviços”: serviços prestados pela QESH ao USUÁRIO, de acordo com as condições
          previstas no Termo de Uso.
        </Paragraph>
        <Paragraph>- “Sistema de Pagamentos”: tecnologia disponibilizada pela QESH, e que se encontra
          integrada com o sistema dos Prestadores de Serviço, para possibilitar a prestação dos
          Serviços ao USUÁRIO.
        </Paragraph>
        <Paragraph>- “Transação”: operação em que o USUÁRIO realiza ou recebe pagamentos por meio
          dos instrumentos de pagamento disponíveis no Sistema de Pagamentos.
        </Paragraph>
        <Paragraph>- “Tratamento”: toda operação realizada com os Dados Pessoais do USUÁRIO, em
          razão da coleta, produção, recepção, classificação, utilização, acesso, reprodução,
          transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação,
          avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou
          extração.
        </Paragraph>
        <Paragraph>- “USUÁRIO”: pessoa jurídica ou pessoa física (incluindo representantes, mandatários
          ou prepostos autorizados para executar as Transações) que fornece seus Dados Pessoais
          para Tratamento pela QESH, em razão dos Serviços prestados por meio do Sistema de
          Pagamentos.
        </Paragraph>
        <Paragraph>- “PARCEIRO”: pessoa jurídica que atua como um PARCEIRO de negócios da QESH e
          integra a relação estabelecida entre o USUÁRIO e a QESH, na medida em que o PARCEIRO
          disponibiliza ao USUÁRIO, em conjunto com a QESH, a tecnologia necessária para prestação
          dos Serviços que o USUÁRIO selecionou junto à QESH.
        </Paragraph>
        <ClauseTitle>2.Cultura De Proteção De Dados Pessoais</ClauseTitle>
        <Paragraph>A QESH preza pela sua privacidade e pela segurança dos seus dados e nossa atuação é
          pautada na transparência e no respeito à privacidade. Nos dedicamos para manter a
          conformidade com as principais legislações e melhores práticas de governança do mercado.
        </Paragraph>
        <Clause>2.1.Disposições Gerais</Clause>
        <Paragraph>A QESH e os seus Bancos parceiros (“PARCEIRO”), disponibilizam uma conta de pagamento
          digital com o intuito de facilitar suas Compras em Estabelecimentos e permitir a realização de
          transações de saques, Pix, transferência entre contas, pagamento de contas, emissão de
          boletos, recarga de celular, entre outras funcionalidades (os “Serviços”).
        </Paragraph>
        <Paragraph>O PARCEIRO, por sua vez, é um PARCEIRO de negócios da QESH e integra a relação
          estabelecida entre o USUÁRIO e a QESH, na medida em que o PARCEIRO disponibilizar ao
          USUÁRIO, em conjunto com a QESH, a tecnologia necessária para prestação dos Serviços
          que o USUÁRIO selecionou junto à QESH. Em razão da existência dessa relação, esta
          Política se aplica na forma como a QESH e o PARCEIRO utilizam os seus dados pessoais, de
          forma conjunta, para possibilitar a prestação dos Serviços.
        </Paragraph>
        <Paragraph>Este documento foi redigido de forma simples e acessível, contando com vários exemplos
          de coleta e de uso dos dados, justamente para que o USUÁRIO possa ler e entender a
          forma como utilizamos os seus dados e lhe oferecer uma experiência segura e confortável
          durante o uso dos Serviços.
        </Paragraph>
        <ClauseTitle>3.Obtenção dos Dados Pessoais</ClauseTitle>
        <Clause>3.1.A QESH realiza o Tratamento dos Dados Pessoais mínimos necessários para a
          utilização, pelo USUÁRIO, do conjunto de Serviços prestados por meio do Sistema
          de Pagamentos, para, de acordo com o Termo de Uso, possibilitar o(a):
        </Clause>
        <List>
          <Point>Abertura de uma conta de pagamento (“Conta de Pagamento”) e a realização de
            Transações;
          </Point>
          <Point>Recebimento de recursos em razão do pagamento com cartão de crédito ou débito
            (“Cartão”) por meio dos arranjos de pagamento instituídos pelas bandeiras aceitas no Sistema
            de Pagamentos; e/ou
          </Point>
          <Point>Realização de pagamentos instantâneos no âmbito do Pix, de acordo com as regras
            instituídas pelo Bacen (“Pagamentos Instantâneos”); e/ou
          </Point>
          <Point>Emissão de cartão pré-pago (“Cartão Pré-Pago”) por uma Emissora.</Point>
        </List>
        <Clause>
          3.2. Para o credenciamento ao Sistema de Pagamentos, o USUÁRIO deverá
          disponibilizar os Dados Pessoais solicitados no Cadastro, para que o USUÁRIO
          possa ser devidamente identificado. O Cadastro solicita apenas os dados
          necessários para a prestação dos Serviços pela QESH.
        </Clause>
        <Clause>3.2.1.Com a finalidade de prevenir fraudes e garantir a autenticidade dos Dados Pessoais
          fornecidos, poderão ser solicitadas outras informações não contidas no Cadastro, bem como o
          envio de fotos ou cópia de documentos que permitam a confirmação dos dados fornecidos
          pelo USUÁRIO. Neste caso, o USUÁRIO será contatado diretamente. Essas informações e
          documentos adicionais poderão ser armazenados pela QESH pelo tempo necessário para
          garantia de integridade e confiabilidade do uso de seus Serviços pelo USUÁRIO.
        </Clause>
        <Clause>3.2.2.Ainda, a QESH poderá consultar as informações disponíveis em bancos de dados públicos ou
          privados, inclusive bureaus de crédito
        </Clause>
        <Clause>3.2.3.A QESH também solicitará os dados financeiros do USUÁRIO que forem necessários para a
          prestação dos Serviços, e que podem incluir, conforme aplicável: (i) os dados de identificação
          de Conta Bancária de titularidade do USUÁRIO; (ii) as informações sobre a Transação; e (iii) a
          outras informações associadas à Transação, como a origem dos recursos e os motivos para
          sua realização.
        </Clause>
        <Clause>3.3.Caso o USUÁRIO seja indicado por um PARCEIRO comercial da QESH, seus Dados
          Pessoais poderão ser compartilhados diretamente pelo PARCEIRO, com a finalidade
          de facilitar o Cadastro no Sistema de Pagamentos. O compartilhamento dos Dados
          Pessoais se dará mediante consentimento prévio e expresso do USUÁRIO, de
          acordo com a Legislação Aplicável.
        </Clause>
        <Clause>3.4.No Tratamento de Dados Pessoais serão observadas, de acordo com a Legislação
          Aplicável, os princípios estritos da finalidade, adequação, necessidade, livre acesso,
          qualidade dos dados, transparência, segurança, prevenção, não discriminação,
          responsabilização e prestação de contas.
        </Clause>
        <Clause>3.4.1.Antes de concluída a Transação solicitada pelo USUÁRIO no Sistema de Pagamentos, a
          QESH poderá solicitar documentos e informações adicionais que se façam necessários para a
          identificação e prevenção de situações de fraude, lavagem de dinheiro ou destinação irregular
          dos recursos. Essas informações e documentos adicionais poderão ser armazenados pela QESH pelo
          tempo necessário para garantia do cumprimento da
          legislação e da política de
          prevenção a fraudes e lavagem de dinheiro da QESH.
        </Clause>
        <Clause>3.4.2.Os Dados Pessoais podem incluir a condição financeira do USUÁRIO, que serão coletados,
          armazenados e compartilhados para a verificação da pontuação de crédito (credit score) e o
          acompanhamento da situação da inscrição no CPF ou CNPJ.
        </Clause>
        <Clause>3.5.A Plataforma da QESH pode utilizar informações do Dispositivo, Dados de Uso
          Técnico e Localização Geográfica do USUÁRIO.
        </Clause>
        <Clause>3.5.1.Ainda, a Plataforma da QESH pode utilizar “Cookies” (arquivos gravados em seu dispositivo
          para obter informação de navegação dentro do website), para fim de confirmação de
          identidade e análise do comportamento de navegação, de modo a melhorar a segurança e
          identificar problemas e dificuldades de utilização da Plataforma. Caso o USUÁRIO não
          concorde com sua utilização, poderá desabilitar o uso dessa função nas opções de seu
          browser para recusar o recebimento de Cookies e removê-los a qualquer momento. O
          USUÁRIO deverá verificar as opções e ferramentas disponíveis no software utilizado
        </Clause>
        <Clause>3.6.Em geral, os dados pessoais que o USUÁRIO fornece são usados para que o
          USUÁRIO possa usufruir dos Serviços, bem como para que eles possam ser
          aprimorados. Os dados pessoais são utilizados para atingir as finalidades
          apresentadas na tabela abaixo, com as respectivas bases legais, que autorizam o
          seu tratamento:
        </Clause>
        <Table>
          <Row>
            <Header>DADOS PESSOAIS</Header>
            <Header>FINALIDADE</Header>
            <Header>BASE LEGAL</Header>
          </Row>
          <Row>
            <Cell>
              <Paragraph>
                CPF
              </Paragraph>
              <Paragraph>
                Nome completo
              </Paragraph>
              <Paragraph>
                Data de nascimento
              </Paragraph>
              <Paragraph>
                Nacionalidade
              </Paragraph>
              <Paragraph>
                Endereço residencial
              </Paragraph>
              <Paragraph>
                Email
              </Paragraph>
              <Paragraph>
                Nome completo da mãe
              </Paragraph>
              <Paragraph>
                Número de telefone
              </Paragraph>
              <Paragraph>
                Escolaridade
              </Paragraph>
              <Paragraph>
                Profissão
              </Paragraph>
              <Paragraph>
                Renda
              </Paragraph>
              <Paragraph>
                Patrimônio
              </Paragraph>
              <Paragraph>
                Estado civil
              </Paragraph>
              <Paragraph>
                Foto da cédula de identidade ou CNH
              </Paragraph>
              <Paragraph>
                Foto tipo auto retrato (selfie) do titular
              </Paragraph>
              <Paragraph>
                Autodeclaração PEP*
              </Paragraph>
            </Cell>
            <Cell>
              <Paragraph>
                Cadastro nos Serviços
              </Paragraph>
              <Paragraph>
                Verificação da identidade
              </Paragraph>
              <Paragraph>
                Análise de crédito
              </Paragraph>
              <Paragraph>
                Prevenção de eventuais fraudes
              </Paragraph>
              <Paragraph>
                Prevenção de ameaças de segurança
              </Paragraph>
              <Paragraph>
                Cumprimento de Obrigação Legal
              </Paragraph>
              <Paragraph>
                Execução de contrato
              </Paragraph>
            </Cell>
            <Cell>
              <Paragraph>Cumprimento de Obrigação Legal</Paragraph>
              <Paragraph>Execução de contrato</Paragraph>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Paragraph>IP</Paragraph>
              <Paragraph>Data e hora de acesso</Paragraph>
              <Paragraph>Localização Geográfica</Paragraph>
              <Paragraph>Dados de acesso e uso (Cookies)</Paragraph>
            </Cell>
            <Cell>
              <Paragraph>Prevenção de eventuais fraudes</Paragraph>
              <Paragraph>Prevenção de ameaças de segurança</Paragraph>
              <Paragraph>Aprimoração dos Serviços</Paragraph>
            </Cell>
            <Cell>
              <Paragraph>Cumprimento de Obrigação Legal</Paragraph>
              <Paragraph>Execução de contrato</Paragraph>
              <Paragraph>Consentimento</Paragraph>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Paragraph>Perfil e comportamento transacional</Paragraph>
              <Paragraph>Número de transações</Paragraph>
              <Paragraph>Volume de transações</Paragraph>
            </Cell>
            <Cell>
              <Paragraph>Prevenção de eventuais fraudes</Paragraph>
              <Paragraph>Prevenção de ameaças de segurança</Paragraph>
              <Paragraph>Análise de crédito</Paragraph>
              <Paragraph>Aprimoramento dos Serviços</Paragraph>
            </Cell>
            <Cell>
              <Paragraph>Cumprimento de Obrigação Legal</Paragraph>
              <Paragraph>Consentimento</Paragraph>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Paragraph>Dados gerais de navegabilidade</Paragraph>
              <Paragraph>Utilização das Aplicações</Paragraph>
              <Paragraph>Funis de eventos</Paragraph>
            </Cell>
            <Cell>
              <Paragraph>Reconhecimento de perfis e hábitos
                de consumo coletivos dos usuários
                dos Serviços, a fim de gerar dados
                estatísticos que possibilitem melhor
                compreensão de como se dão as
                interações dos usuários com as
                Aplicações
              </Paragraph>
            </Cell>
            <Cell>
              <Paragraph>Consentimento</Paragraph>
            </Cell>
          </Row>
        </Table>
        <Paragraph>*Pessoa Exposta Politicamente</Paragraph>
        <ClauseTitle>4.Utilização dos Dados Pessoais</ClauseTitle>
        <Clause>4.1.A QESH realizará o Tratamento dos Dados Pessoais, Dados de Uso Técnico, Informações do
          Dispositivo ou Localização Geográfica do USUÁRIO, para operar o Sistema de Pagamentos e
          prestar os Serviços, incluindo, mas não se limitando, às seguintes situações:
        </Clause>
        <List>
          <Point>
            Realizar o Cadastro e autenticar o acesso do USUÁRIO na Plataforma;
          </Point>
          <Point>
            Comunicar-se com o USUÁRIO sobre o Cadastro, utilização do Sistema de
            Pagamentos ou prestação dos Serviços;
          </Point>
          <Point>
            Enviar ou solicitar pagamentos, em razão das Transações realizadas pelo USUÁRIO
            no Sistema de Pagamentos;
          </Point>
          <Point>
            Realizar a verificação de crédito e da reputação financeira do USUÁRIO;
          </Point>
          <Point>
            Manter os Dados Pessoais do USUÁRIO atualizados;
          </Point>
          <Point>
            Realizar a verificação da identidade do USUÁRIO para gerenciar riscos e proteger o
            Sistema de Pagamentos contra fraudes;
          </Point>
          <Point>
            Classificar o USUÁRIO, bem como monitorar e analisar seu comportamento na
            utilização do Sistema de Pagamento, com a finalidade de prevenir fraudes e atos ilícitos,
            inclusive quando se tratar de pessoa politicamente exposta;
          </Point>
          <Point>Criar a conexão do USUÁRIO com o sistema dos Prestadores de Serviço;</Point>
          <Point>Realizar e promover campanhas de marketing e aprimoramento dos Serviços ou da
            experiência na utilização do Sistema de Pagamentos;
          </Point>
          <Point>Oferecer serviços personalizados prestados por terceiros, inclusive mediante a
            utilização de Cookies;
          </Point>
          <Point>Promover ofertas de produtos ou serviços específicos - se o USUÁRIO optar por
            compartilhar seus Dados Pessoais -, mediante a disponibilização de anúncios, resultados de
            pesquisas e outros conteúdos personalizados; e
          </Point>
          <Point>Dar cumprimento às obrigações previstas no Termo de Uso, na Legislação Aplicável e
            ou de leis e normas decorrentes dos órgãos reguladores
          </Point>
        </List>
        <Clause>A QESH poderá coletar, armazenar e compartilhar os Dados Pessoais do USUÁRIO sempre
          que houver legítimo interesse da QESH nessa utilização ou quando for necessário para a
          execução dos Serviços, cumprimento de deveres legais ou regulatórios impostos à QESH ou
          exercício e defesa de direitos da QESH ou de terceiros.
        </Clause>
        <Clause>A QESH poderá compartilhar os Dados Pessoais com empresas do grupo da QESH e/ou
          Prestadores de Serviço que integram o Sistema de Pagamentos, quando se fizer necessário
          para a execução dos Serviços contratados
        </Clause>
        <Clause>Os Dados Pessoais do USUÁRIO serão compartilhados pela QESH com outros PARCEIROS
          e Prestadores de Serviço, por rede segura, limitado somente ao que for necessário para
          identificar o USUÁRIO e a Transação realizada por meio do Sistema de Pagamentos.
        </Clause>
        <Clause>Os Dados Pessoais do USUÁRIO também serão compartilhados pela QESH com terceiros
          contratados para prover serviços de computação, transferência de dados e hospedagem em
          nuvem, serviços de proteção ao crédito, ferramentas de análise de fraude e ferramentas de
          análise de prevenção à lavagem de dinheiro; contanto que esses terceiros guardem o mesmo
          padrão de privacidade e segurança aplicados pela QESH e estejam contratualmente
          obrigados a não acessar o conteúdo ou compartilhar os Dados Pessoais, exceto mediante
          ordem expressa da QESH.
        </Clause>
        <Clause>Os Dados Pessoais do USUÁRIO e os dados das Transações realizadas no Sistema de
          Pagamentos poderão ser utilizados pela QESH para elaboração de pesquisas e estatísticas
          voltadas a analisar eficiência do Sistema de Pagamentos, número de USUÁRIOS, o valor das
          Transações realizadas no Sistema de Pagamentos, entre outros; desde que tais informações sejam
          convertidas em Dados Anonimizados ou na forma de valores totais para a criação de
          estatísticas, de forma a preservar a individualidade e identificação do USUÁRIO.
        </Clause>
        <Clause>.Ressalvado o disposto nesta Política, e salvo previsão em contrário na Legislação Aplicável, a
          QESH não divulgará nem compartilhará Dados Pessoais do USUÁRIO com terceiros.
        </Clause>
        <ClauseTitle>
          5.Regras de compartilhamento
        </ClauseTitle>
        <Paragraph>Por ser um Serviço que envolve operações de pagamento, a QESH e o PARCEIRO podem
          atuar em conjunto com outras empresas para realizar diversas atividades, inclusive para a
          avaliação da sua capacidade financeira, por meio da verificação de score de crédito e
          acompanhamento da situação cadastral do seu CPF.
        </Paragraph>
        <Paragraph>Desta forma, a QESH e o PARCEIRO reservam-se no direito de compartilhar as suas
          informações com terceiros no intuito de receber, analisar e tomar decisões sobre
          solicitações relacionadas aos Serviços. Tais compartilhamentos de informações com
          terceiros podem incluir dados pessoais relativos à sua situação financeira, e, sempre que
          for possível, de forma anonimizada, visando preservar ao máximo a sua privacidade.
        </Paragraph>
        <Paragraph>Os dados pessoais também são compartilhados com empresas prestadoras de serviço, que
          forneçam ou tenham acesso às soluções utilizadas para desenvolvimento e gestão dos
          Serviços da QESH e o PARCEIRO, como, por exemplo, o uso de ferramentas de gestão de
          marketing. Mais informações a respeito deste compartilhamento podem ser obtidas nas
          sessões “ARMAZENAMENTO” e “SEGURANÇA DA INFORMAÇÃO” indicadas desta
          Política.
        </Paragraph>
        <Paragraph>Os dados também podem ser compartilhados em casos de necessidades específicas
          como:
        </Paragraph>
        <Paragraph>1. cumprimento de ordens e de solicitações de órgãos públicos e jurídicos</Paragraph>
        <Paragraph>2. investigação e combate à fraude ou possíveis violações de segurança</Paragraph>
        <Paragraph>3. proteção contra prejuízos aos direitos estabelecidos em Lei</Paragraph>
        <Paragraph>4. proteção dos interesses da QESH, em ações judiciais ou outros conflitos</Paragraph>
        <Paragraph>A QESH e o PARCEIRO não licenciam, nem transferem seus dados pessoais para
          ninguém, exceto para as modalidades de empresas elencadas abaixo.
        </Paragraph>
        <Paragraph>BUREAU DE CRÉDITO: A QESH e o PARCEIRO irão compartilhar seus dados pessoais,
          quando o USUÁRIO consentir com a adesão aos Serviços, com empresas que realizam a
          análise de seu perfil pessoal com relação à risco de concessão de crédito, a fim de dar
          maior segurança às operações e evitar eventuais fraudes.
        </Paragraph>
        <Paragraph>ANALYTICS: Os dados armazenados pela QESH e o PARCEIRO podem vir a ser utilizados
          para fins de reconhecimento de perfis e hábitos de consumo coletivos dos USUÁRIOS dos
          Serviços visando gerar dados estatísticos, que possibilitem compreender melhor como é o
          acesso dos USUÁRIOS às Aplicações. Esta análise é considerada importante para a
          melhoria contínua da prestação de Serviços e customização de produtos direcionados aos
          interesses dos USUÁRIOS. Importante: Os dados relacionados ao USUÁRIO e utilizados
          para essa finalidade são anonimizados, não permitindo a sua identificação de forma
          individual.
        </Paragraph>
        <Paragraph>BANDEIRA: Seus dados pessoais, incluindo o número do seu cartão, podem ser
          compartilhados com a bandeira para que haja sua integração (e de seus adicionais, quando
          indicados) às plataformas promocionais da bandeira, com a finalidade de oferecer
          descontos, ofertas e promoções, etc. É necessária a leitura dos Termos de Uso e da
          Política de Privacidade da bandeira no momento da adesão
        </Paragraph>
        <Paragraph>PARA RESGUARDAR E PROTEGER OS DIREITOS DA QESH E DO PARCEIRO: SEM
          PREJUÍZO DO DISPOSTO NAS LEGISLAÇÕES BRASILEIRAS APLICÁVEIS, A QESH E
          O PARCEIRO SE RESERVAM O DIREITO DE ACESSAR, LER, PRESERVAR E
          DIVULGAR QUAISQUER DADOS NECESSÁRIOS PARA CUMPRIR UMA OBRIGAÇÃO
          LEGAL OU UMA ORDEM JUDICIAL EMANADA DE AUTORIDADES COMPETENTES,
          FAZER CUMPRIR OU APLICAR OS TERMOS DE USO E OUTROS ACORDOS
          FIRMADOS COM O USUÁRIO OU PROTEGER OS DIREITOS, PROPRIEDADE OU
          SEGURANÇA DA QESH E DO PARCEIRO, E SEUS RESPECTIVOS
          REPRESENTANTES, PRESTADORES DE SERVIÇOS, COLABORADORES OU
          USUÁRIOS.
        </Paragraph>
        <ClauseTitle>5.1.Transações com Cartão</ClauseTitle>
        <Clause>5.1.1.Os dados referentes ao Cartão serão coletados apenas no momento da utilização do
          Sistema de Pag
        </Clause>
        <Clause>5.1.1.1.O Tratamento dos dados do Cartão será realizado apenas para possibilitar a realização da
          Transação perante as credenciadoras, emissoras, bandeiras, processadoras e outros
          Prestadores de Serviço integrantes do Sistema de Pagamentos. Estes dados não serão
          armazenados pela QESH, de acordo com as regras instituídas pelo PCI.
        </Clause>
        <Clause>5.1.1.2.Para fins desta Política, entende-se por “PCI” (abreviação de Payment Card Industry):
          programa de gerenciamento de riscos patrocinado pelas bandeiras instituidoras dos
          arranjos de pagamento de Cartões, de alcance geral e vinculação do USUÁRIO, da QESH
          e dos Prestadores de Serviço; desenvolvido com o objetivo de estipular padrões mínimos
          para proteção dos Dados Pessoais, dados das Transações e dados do Cartão. É baseado
          nas normativas definidas pelo PCI Council e tem como função determinar os padrões e
          regras de segurança da informação para todos os integrantes do mercado de Cartões,
          conforme divulgado em www.pcisecuritystandards.org.
        </Clause>
        <Clause>5.1.1.3.O padrão de segurança instituído pelo PCI foi desenvolvido para incentivar e aprimorar a
          segurança dos dados do titular do Cartão e de todas as Transações realizadas no âmbito
          dos arranjos das bandeiras.
        </Clause>
        <Clause>5.1.1.4.O PCI compreende um conjunto mínimo de requisitos para proteger os dados do Cartão e
          pode ser aperfeiçoado por controles e práticas adicionais para amenizar ainda mais os
          riscos de sua utilização. Além disso, os requisitos legais ou regulatórios podem exigir
          proteção específica para Dados Pessoais, de modo que o PCI é aplicado de maneira
          complementar, e não substitui a Legislação Aplicável.
        </Clause>
        <Clause>5.1.2.Os dados do Cartão são capturados no Sistema de Pagamentos, de modo criptografado,
          dentro dos padrões de segurança instituídos pelo PCI. A coleta se dará por uma
          processadora, que as transmitirá para as credenciadoras e, por conseguinte, para os
          emissores e bandeiras. A QESH não terá acesso a tais informações, eximindo-se de toda e
          qualquer responsabilidade com relação à coleta e transmissão dos dados do Cartão.
        </Clause>
        <Clause>5.1.3.Os Prestadores de Serviço são responsáveis pela observância e aplicação das regras e
          requisitos do PCI, que se aplica a todas as entidades envolvidas no processo de realização
          da Transação com Cartão.
        </Clause>
        <ClauseTitle>
          5.2.Pagamentos Instantâneos pelo Pix
        </ClauseTitle>
        <Clause>5.2.1.Para a realização de Transações pelo Pix, o USUÁRIO poderá solicitar o registro de uma
          Chave Pix vinculada à sua Conta de Pagamento ou Conta Bancária, sendo necessário que o
          USUÁRIO tenha a posse da Chave Pix escolhida – com exceção da chave aleatória – e que
          dê o seu consentimento para tal registro.
        </Clause>
        <Clause>5.2.1.1.Ao registrar uma Chave Pix, o USUÁRIO declara ter conhecimento de que terceiros com
          quem o USUÁRIO venha a realizar Transações por meio do Pix, terão visibilidade, a cada
          Transação, de seu nome, dados de identificação da Chave Pix, e do nome da QESH e dos
          Prestadores de Serviço envolvidos.
        </Clause>
        <Clause>5.2.1.2.Para fins desta Política entende-se por:</Clause>
        <Paragraph>Chave Pix”: informação indicada pelo USUÁRIO para identificar sua Conta de Pagamento ou
          Conta Bancária no âmbito do arranjo Pix, por meio de: (i) número do CPF ou CNPJ (conforme
          aplicável); (ii) número do telefone celular; (iii) endereço de e-mail; ou (iv) chave aleatória
          (sequência de letras e números gerados aleatoriamente pelo Bacen), de livre escolha do
          USUÁRIO. A utilização da Chave Pix permite obter as informações sobre os usuários
          pagadores e recebedores armazenadas no Diretório de Identificadores de Contas
          Transacionais (“DICT”) gerido pelo Bacen, com a finalidade de facilitar o processo de iniciação
          das Transações de Pagamentos Instantâneos e de mitigar o risco de fraude no âmbito do Pix.
        </Paragraph>
        <Paragraph>“Pix”: arranjo de pagamento que disciplina a prestação de serviços relacionados com as
          Transações de Pagamentos Instantâneos, cujas regras e condições são instituídas pelo
          Bacen.
        </Paragraph>

        <Clause>5.2.2.O USUÁRIO declara-se ciente de que, nos termos do regulamento do Pix e demais normas
          instituídas pelo Banco Central do Brasil (“Bacen”), a QESH será responsável pela realização
          das Transações de Pagamentos Instantâneos, mediante a transmissão dos Dados Pessoais e
          da Transação ao Prestador de Serviço responsável pela liquidação no âmbito do Pix
        </Clause>
        <Clause>5.2.2.1.Para a realização das Transações de Pagamentos Instantâneos, o USUÁRIO manifesta seu
          expresso consentimento, para coleta, tratamento e transmissão de seus Dados Pessoais ao
          Prestador de Serviço, que, na qualidade de participante direto, será responsável pela
          liquidação das Transações perante o Pix.
        </Clause>
        <Clause>5.2.3.O USUÁRIO também autoriza a QESH a realizar a coleta, Tratamento e transmissão, ao
          Prestador de Serviço com acesso ao DICT, para realizar o Cadastro, exclusão e reivindicação
          das Chaves Pix.
        </Clause>
        <Clause>5.2.3.1.A realização, pelo USUÁRIO, do cadastro para registro de sua Chave Pix implica na
          manifestação prévia de seu consentimento para o registro das Chaves Pix no DICT. Tal
          manifestação será confirmada e o registro da Chave Pix será realizado no DICT, caso o
          USUÁRIO não exclua sua Chave Pix no Sistema de Pagamentos da QESH.
        </Clause>
        <ClauseTitle>5.3.Emissão de Cartão Pré-Pago</ClauseTitle>
        <Clause>5.3.1.O USUÁRIO poderá, nos termos do Termos de Uso, solicitar um Cartão Pré-Pago que será
          emitido por uma emissora parceira da QESH (“Emissora”).
        </Clause>
        <Clause>5.3.2.Ao solicitar a emissão de Cartão Pré-Pago, o USUÁRIO expressamente autoriza que a QESH
          compartilhe seus Dados Pessoais com a Emissora, bem como declara-se ciente e concorda que a QESH terá
          acesso a todos os dados financeiros decorrentes das Transações realizadas
          com o Cartão Pré-Pago.
        </Clause>
        <Clause>5.3.3.A Emissora realizará o Tratamento dos Dados Pessoais do USUÁRIO e poderá:</Clause>
        <List>
          <Point>Adotar procedimentos para assegurar a devida diligência na identificação,
            qualificação e classificação do USUÁRIO;
          </Point>
          <Point>Realizar a verificação da condição do USUÁRIO de pessoa exposta politicamente;</Point>
          <Point>Coletar informações sobre a renda e/ou o faturamento do USUÁRIO; e</Point>
          <Point>Monitorar as Transações realizadas pelo USUÁRIO com o objetivo de identificar
            suspeitas de lavagem de dinheiro e/ou de financiamento ao terrorismo.
          </Point>
        </List>
        <Clause>5.3.3.1.A Emissora poderá solicitar informações e documentos adicionais, além daqueles
          informados no Cadastro, para possibilitar a emissão do Cartão Pré-Pago.
        </Clause>
        <ClauseTitle>6.Regras para armazenamento</ClauseTitle>
        <Clause>6.1.Os Dados Pessoais coletados pela QESH são armazenados em servidores seguros, de forma
          criptografada, com a utilização de medidas de segurança da informação constantemente
          atualizadas. Os Dados Pessoais serão mantidos confidenciais e serão adotadas todas as
          medidas possíveis contra perda, roubo, uso indevido, alteração e acesso não autorizado.
        </Clause>
        <Clause>6.2.Os Dados Pessoais relacionados ao Cadastro e realização de Transações no Sistema de
          Pagamentos serão armazenados enquanto o USUÁRIO mantiver um Cadastro ativo e utilizar
          os Serviços da QESH, pelo tempo que for necessário para atingir as finalidades relacionadas
          com os Serviços, inclusive para fins de cumprimento de quaisquer obrigações legais,
          regulatórias, contratuais, de prestação de contas ou requisição por autoridades competentes.
        </Clause>
        <Clause>6.2.1.Os Dados Pessoais serão armazenados pelo prazo mínimo de 05 (cinco) anos contados do
          encerramento do Termo de Uso, ou outro prazo que vier a ser determinado na Legislação
          Aplicável.
        </Clause>
        <Clause>6.3.A QESH emprega padrões de segurança avançados com a finalidade de garantir a proteção
          dos Dados Pessoais e de fornecer um ambiente seguro para a realização de Transações,
          mediante a adoção de práticas relativas à segurança da informação, como autenticação dos
          USUÁRIOS, rígido controle de acesso, criptografia dos Dados Pessoais e do conteúdo das
          Transações, prevenção e detecção de intrusão e acessos não autorizados, prevenção de
          vazamento de informações, realização periódica de testes e varreduras para detecção de
          vulnerabilidades, proteção contra softwares maliciosos, mecanismos de rastreabilidade, controles de
          acesso e de segmentação da rede de computadores, manutenção de cópias de
          segurança dos Dados Pessoais, entre outros
        </Clause>
        <Clause>6.3.1.Apesar da dedicação da QESH em proteger o Sistema de Pagamentos, o USUÁRIO é
          responsável por proteger e manter a confidencialidade de seus Dados Pessoais.
        </Clause>
        <Clause>6.3.2.A QESH não se responsabiliza por Dados Pessoais que o USUÁRIO compartilhar com
          terceiros. Assim, é importante que o USUÁRIO adote um comportamento seguro,
          identificando e evitando situações que possam ameaçar a segurança dos seus Dados
          Pessoais
        </Clause>
        <Clause>6.3.3.Caso o USUÁRIO venha a utilizar seus Dados Pessoais em sites ou serviços de terceiros, a
          responsabilidade de proteção e armazenamento dos Dados Pessoais será dos provedores de
          tais serviços; cabendo ao USUÁRIO se atentar ao conteúdo das políticas de privacidade
          aplicáveis ao uso dos sites ou serviços de terceiros.
        </Clause>
        <Clause>6.4.Na medida da Legislação Aplicável, a QESH não se responsabiliza por violações ilegais de
          seu Sistema de Pagamentos, que venham a comprometer a sua base de dados e os Dados
          Pessoais dos USUÁRIOS, bem como não se responsabiliza pela utilização indevida dos
          Dados Pessoais obtidos de forma fraudulenta ou ilícita.
        </Clause>
        <Clause>6.5.Em caso de suspeita ou confirmação de violação do Sistema de Pagamentos ou de perda de
          Dados Pessoais do USUÁRIO, a QESH envidará seus melhores esforços e tomará medidas
          imediatas para eliminar ou reduzir os riscos de danos ao USUÁRIO, e informará os
          USUÁRIOS potencialmente afetados e às autoridades competentes de tal fato, os riscos
          envolvidos e as medidas necessárias para evitar tais danos.
        </Clause>
        <Paragraph>Se o Termo de Uso for encerrado pelo USUÁRIO, a QESH poderá utilizar e divulgar os Dados
          Pessoais do USUÁRIO, pelo período que for necessário, de acordo com o termos dessa
          Política e com a Legislação Aplicável.
        </Paragraph>
        <ClauseTitle>7.Transferência de dados pessoais</ClauseTitle>
        <Clause>7.1.Os Dados Pessoais serão coletados no Brasil e podem ser transferidos para outro país, no
          qual a empresa responsável pela hospedagem se encontra sediada e/ou mantém seus
          servidores. Neste caso, a QESH irá garantir que o destinatário estrangeiro proporcione o grau
          de proteção de Dados Pessoais exigido nesta Política, em consonância com a Legislação
          Aplicável.
        </Clause>
        <Clause>7.2.A QESH e o PARCEIRO possuem sede no Brasil e os dados coletados são regidos pela Lei
          brasileira. Ao acessar ou usar os Serviços ou fornecer dados pessoais para a QESH e o
          PARCEIRO, o USUÁRIO concorda com o tratamento e a transferência de tais dados para o
          Brasil e para o EUA, se for o caso, observadas as condições expostas no parágrafo acima.
        </Clause>
        <Clause>7.3.A QESH e o PARCEIRO declaram ao USUÁRIO que seus dados pessoais serão
          compartilhados entre elas, devendo ser mantidos, para fins de cumprimento de regras de
          diligência dos clientes, conhecido como Conheça seu Cliente - KYC e de Prevenção à
          Lavagem de Dinheiro - PLD ou AML, nos termos da regulamentação em vigor.
        </Clause>
        <Clause>7.4.As soluções contratadas pela QESH estão em conformidade com os principais padrões de
          segurança e com as legislações nacionais e internacionais. São exigidas práticas de
          desenvolvimento seguro e de verificações proativas de segurança, além da disponibilidade de
          equipes especializadas para identificação e tratamento de vulnerabilidades.
        </Clause>
        <Clause>7.5.O padrão de análise e seleção das soluções contratadas pela QESH são mantidos em todas
          as aquisições, atualizações e substituições de ferramentas.
        </Clause>
        <ClauseTitle>
          8.Redirecionamento para outros sites
        </ClauseTitle>
        <Clause>8.1.Poderemos disponibilizar links para sites de empresas e PARCEIROS e terceiros e para sites
          externos. Disponibilizamos também links para compartilhamentos de notícias em mídias
          sociais.
        </Clause>
        <Clause>8.2.A QESH e o PARCEIRO não possuem qualquer governança sobre as normas de
          relacionamento de PARCEIROS e terceiros, por isso orientamos que os nossos USUÁRIOS
          avaliem as Políticas de Privacidade e de uso das respectivas empresas antes de aceitar
          compartilhar seus dados.
        </Clause>
        <Board style={{ textAlign: 'center' }}>
          <Paragraph>ATENÇÃO</Paragraph>
          <Paragraph>NÃO NOS RESPONSABILIZAMOS PELA
            PRIVACIDADE E SEGURANÇA DOS DADOS
            FORNECIDOS À TERCEIROS E PELAS PRÁTICAS
            DE RELACIONAMENTO ESTABELECIDAS POR
            OUTRAS EMPRESAS.
          </Paragraph>
        </Board>
        <ClauseTitle>9.Direitos do USUÁRIO</ClauseTitle>
        <Clause>9.1.É permitido ao USUÁRIO, a qualquer tempo, nos limites da Legislação Aplicável, exercer os
          seguintes direitos sobre seus Dados Pessoais (“Direitos”):
        </Clause>
        <List>
          <Point>Direito de confirmação de existência de tratamento de seus Dados Pessoais.</Point>
          <Point>Direito de acesso aos Dados Pessoais tratados.</Point>
          <Point> Direito de correção de eventuais Dados Pessoais incompletos, inexatos ou
            desatualizados
          </Point>
          <Point>Direito ao bloqueio, eliminação ou anonimização de Dados Pessoais desnecessários,
            excessivos ou tratados em desconformidade com a legislação, assim como a oposição ao
            tratamento dos Dados Pessoais nessas mesmas situações.
          </Point>
          <Point>Direito à portabilidade dos seus Dados Pessoais a outra empresa, na medida em que
            seja exigida por regulamentos oficiais sobre o assunto
          </Point>
          <Point> Direito de obter informação das entidades públicas e privadas com as quais houve uso
            compartilhado de seus dados.
          </Point>
          <Point>Direito ser informado sobre a possibilidade de não fornecer seu consentimento e sobre
            as consequências da negativa, nos casos em que seus Dados Pessoais forem coletados e
            tratados mediante consentimento, assim como o direito à eliminação, quando requerida, dos
            Dados Pessoais coletados mediante seu consentimento, na forma da legislação aplicável, e
            direito de revogação do seu consentimento para coleta e tratamento de dados nestes mesmos
            casos.
          </Point>
        </List>
        <Clause>9.2.O USUÁRIO poderá, a qualquer tempo, exercer os Direitos assegurados nesta Política ou
          previstos na Legislação Aplicável, mediante solicitação expressa à QESH, por meio dos
          canais de atendimento indicados nesta Política.
        </Clause>
        <Clause>9.2.1.O requerimento do USUÁRIO deverá ser solicitado por escrito e acompanhado de prova de
          identidade. A QESH poderá contatar o USUÁRIO para confirmar sua identidade antes do
          cumprimento do requerimento.
        </Clause>
        <Clause>9.2.2.Serão fornecidas confirmações da existência de Tratamento de Dados Pessoais, em formato
          simplificado, no prazo de até 15 (quinze) dias. Para os demais requerimentos, a QESH poderá
          apresentar sua resposta no prazo de 30 (trinta) dias, o qual poderá ser estendido a depender
          da natureza e complexidade do requerimento
        </Clause>
        <ClauseTitle>10.Regras gerais para retenção e descarte</ClauseTitle>
        <Clause>10.1.A QESH e o PARCEIRO mantém seus dados pessoais somente pelo tempo que for necessário
          para atingir as finalidades para as quais foram coletamos, inclusive para fins de
          cumprimento de quaisquer obrigações legais, contratuais, de prestação de contas ou
          requisição de autoridades competentes, observado prazo mínimo de 5 (cinco) anos contados
          da data da coleta dos dados, em observância ao disposto na Resolução 4.893/21 do Banco
          Central do Brasil.
        </Clause>
        <Clause>10.2.Para determinar o período de retenção adequado para os dados pessoais, consideramos a
          quantidade, a natureza e a sensibilidade dos dados pessoais, o risco potencial de danos
          decorrentes do uso não autorizado ou da divulgação de seus dados pessoais, a finalidade de
          tratamento dos seus dados pessoais e se podemos alcançar tais propósitos através de outros
          meios, observando os requisitos legais aplicáveis
        </Clause>
        <Clause>10.3.E se o USUÁRIO registrou nos Serviços da QESH , as suas informações serão mantidas
          enquanto a conta estiver ativa, observado o prazo legal acima mencionado, ou até que a
          finalidade para o qual os dados foram solicitados seja alcançada. Caso determinados dados
          deixem de ser necessários para o alcance de determinada finalidade, serão excluídos ou
          anonimizados. Os dados também serão excluídos na hipótese de o Poder Público determinar
          o término do tratamento.
        </Clause>
        <Clause>10.4.Caso o USUÁRIO solicite a exclusão dos seus dados e/ou da sua conta, a QESH e o
          PARCEIRO excluirão os seus dados ou os sujeitarão à anonimização, assim que decorrer o
          prazo legal de mencionado acima, a menos que a sua manutenção seja determinada com
          base em obrigações legais, ou requisição de autoridades competentes.
        </Clause>
        <ClauseTitle>11.Segurança e proteção dos dados</ClauseTitle>
        <Clause>11.1.Em nossos produtos e serviços aplicamos rígidos controles tecnológicos e procedimentais
          para garantir a segurança e proteção de todos os dados coletados e armazenados em nosso
          ambiente.
        </Clause>
        <Clause>11.2.Com a finalidade de garantir a proteção de seus dados pessoais e fornecer um ambiente
          seguro, implementamos mecanismos de Segurança da Informação de acordo com as
          melhores práticas do mercado. Dedicamos esforços na prevenção e na mitigação dos riscos
          de segurança e estabelecemos processos internos de comunicação e de acompanhamento
          de falhas. Não obstante, esclarecemos que todo ambiente cibernético está sujeito a sofrer
          incidentes e violações de segurança, acessos ou interceptações ilegais e utilização indevida
          dos dados não configuram responsabilidade da QESH .
        </Clause>
        <Clause>11.3.Por isso, orientamos os nossos USUÁRIOS a utilizarem os recursos computacionais com
          cuidado e atenção. É importante que o USUÁRIO tenha um comportamento seguro identificando e evitando
          situações que possam ameaçar a segurança dos seus dados e de
          nossos produtos e serviços. Caso o USUÁRIO identifique ou tome conhecimento de algo que
          comprometa a segurança dos seus dados, é essencial que entre em contato com a QESH.
        </Clause>
        <Clause>11.4.Em situações onde o USUÁRIO utilize seus dados pessoais e de cartão para cadastro ou
          transações em sites ou serviço de terceiros, a responsabilidade de proteção e
          armazenamento dos dados nestes locais é dos provedores de tal serviço e o USUÁRIO deve
          se atentar ao conteúdo das políticas de privacidade aplicáveis ao uso de tais sites ou serviços
          de terceiros
        </Clause>
        <ClauseTitle>12.Modificações na Política de Privacidade</ClauseTitle>
        <Clause>12.1.Essa Política será revista periodicamente pela QESH para adequá-la à prestação de Serviços,
          mediante a exclusão, modificação ou inserção de novas cláusulas e condições.
        </Clause>
        <Clause>12.2.As alterações deverão ser informadas ao USUÁRIO mediante divulgação da versão
          atualizada desta Política
        </Clause>
        <Clause>12.3.Caso o USUÁRIO não concorde com as alterações, poderá solicitar o encerramento do Termo
          de Uso com a QESH, na forma prevista em tal instrumento.
        </Clause>
        <Clause>12.4.Com a realização do Cadastro e/ou utilização do Sistema de Pagamentos e dos
          Serviços da QESH pelo USUÁRIO, será interpretado que o USUÁRIO tem ciência da
          versão vigente da Política e não se opõe ao uso de seus Dados Pessoais na forma nela
          descrita, incluindo as últimas alterações realizadas, que passarão a ser integralmente
          aplicáveis.
        </Clause>
        <ClauseTitle>13.Comunicação</ClauseTitle>
        <Clause>13.1.O e-mail do USUÁRIO, informado no preenchimento do Cadastro, será utilizado como meio
          de comunicação pela QESH, apenas para a solicitação de documentos e de Dados Pessoais,
          assim como para comunicação sobre os Serviços prestados.
        </Clause>
        <Clause>13.2.O USUÁRIO poderá, a qualquer momento, optar por não receber newsletter, materiais
          promocionais e de marketing mediante solicitação expressa à QESH, mantendo-se, nesse
          caso, apenas o envio de mensagens relacionadas à execução dos Serviços contratados.
        </Clause>
        <Clause>13.3.A QESH não utiliza serviços de terceiros para enviar e-mails em seu nome. Se o USUÁRIO
          receber e-mail que acredita não ter sido enviado pela QESH, deverá se abster de adotar
          qualquer ação e entrar em contato imediatamente com a QESH para confirmar sua
          veracidade.
        </Clause>
        <Clause>13.4.O USUÁRIO declara-se ciente de que, para verificar a realização de qualquer Transação
          no Sistema de Pagamentos, deverá acessar a Plataforma da QESH; não servindo como comprovação o mero
          recebimento de qualquer comunicação por outros meios
          (incluindo e-mail, WhatsApp, telefone e SMS).
        </Clause>
        <ClauseTitle>14. Canais de Atendimento</ClauseTitle>
        <Clause>Se o USUÁRIO acredita que suas informações pessoais foram usadas de maneira
          incompatível com esta Política ou com as suas escolhas enquanto titular destes dados, ou,
          ainda, se o USUÁRIO tiver outras dúvidas, comentários, sugestões ou solicitações
          relacionadas a esta Política, o USUÁRIO pode entrar em contato nos seguintes endereços
          de contato:
        </Clause>
        <Paragraph>● Chat Online: Acesso direto pelo aplicativo da conta.</Paragraph>
        <Paragraph>● Canal de comunicação com o encarregado de dados da QESH: dpo@qesh.ai</Paragraph>
        <ClauseTitle>15.Disposições finais</ClauseTitle>
        <Paragraph>Como estamos sempre buscando melhorar os Serviços, essa Política pode passar por
          atualizações. Desta forma, recomendamos visitar periodicamente esta página para que o
          USUÁRIO tenha conhecimento sobre as modificações. Caso sejam feitas alterações
          relevantes que necessitem de um novo consentimento, publicaremos essa atualização e
          solicitaremos um novo consentimento ao USUÁRIO.
        </Paragraph>
        <Paragraph>*Em atendimento ao disposto no art. 41 da Lei n° 13.709 de 2018 (Lei Geral da Proteção de
          Dados), o contato com os Encarregados poderá ser feito pelo e-mail dpo@qesh.ai
        </Paragraph>
      </Container>
    </>
  );
}

export default Privacy;
