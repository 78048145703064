import styled from 'styled-components';

export const Container = styled.div`

`;

export const Header = styled.div`
  background: ${(props) => props.backgroundcolor};
  color: #F9F9F9;
  text-align: center;
  padding: 8px 0;
  font-weight: bold;
  border-radius: 8px;
  font-size: 16px;
  position: relative;

  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 600;
    font-size: 21px;
  }
`;

export const Content = styled.div`
  padding: 16px;
  text-align: center; 
  font-weight: 400 !important;
  font-size: 15px;
  min-height: 110px;

  display: flex;
  align-items: center;
  flex-direction: column;
  
  p {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
`;

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  border-top: 1px solid #f0f0f0;

  justify-content: flex-end;

  padding: 10px;

  button{
    & + button {
      margin-left: 8px;
    }
  }
`;
