import styled, { css } from 'styled-components';
import { Progress } from 'antd';

export const Content = styled.div`
  max-width: 1060px;
  width: 100%;
  margin: 5 auto 5 auto;
  padding-bottom: 150px;

  h2 {
    color: #121212;
    font-size: 21px;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .ant-table-thead > tr > th {
    font-weight: 600 !important;
  }

  .ant-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .ant-table-filter-trigger {
    align-self: center;
    padding-left: 5px;
  }

`;

export const SelectionButton = styled.button`
    
    display: flex;
    flex-direction:row;
    justify-content: center;
    color: #FFDA16;
    font-weight: 900;
    font-size: 15px;
    border-radius: 10px;
    background: #393939;
    text-align: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 7px;
    width: 100%;

`;

export const TypesContainer = styled.div`
display: flex;
align-Items: center;
background-color: #FAFAFA; 
border-radius: 15px;
flex-wrap: wrap;
justify-content: space-evenly;
width:100%;
`;

export const TypesContainertop = styled.div`
display: flex;
align-Items: center;
background-color: #FFFFFF; 
border-radius: 15px;
flex-wrap: wrap;
justify-content: space-evenly;
width:100%;

`;

export const TypesContainerminisuperior = styled.div`
padding: 20px;
margin: 20px;
background-color: #FAFAFA;
border-radius: 15px;
display: flex;
flex-direction:column;
justify-content: space-between;
height: 230px;
width:100%;
max-width: 490px;


`;

export const TypesContainertopright = styled.div`
background-color: #FAFAFA;
border-radius: 15px;
display: flex;
flex-direction:column;
justify-content: space-between;
padding: 20px;
margin: 20px;
max-width: 490px;
width:100%;


`;

export const TypesContainerlowerleft = styled.div`
padding: 20px;
margin: 20px;
background-color: #FFFFFF;
border-radius: 15px;
display: flex;
flex-direction:column;
justify-content: space-between;
max-width: 490px;
max-height: 300px;


`;

export const TypesContainerlowerright = styled.div`
background-color: #FFFFFF;
border-radius: 15px;
display: flex;
flex-direction:column;
justify-content: space-between;
padding: 20px;
margin: 20px;
max-width: 490px;
max-height: 300px;
width:100%;

`;

export const InfosContainer = styled.div`
  color: #707070;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin: 5px;
  margin-top: 0;
`;

export const TypeContainer = styled.div`

  display: flex;

  .info {
    margin-left: 10px;
    display: flex;
    flex-flow: column wrap;
  }

  .type-count {
    line-height: 1;
    font-size: 20px;
    font-weight: bold;
  }

  .type-name {
    font-size: 15px;
    font-weight: bold;
  }

  .type-value {
    font-size: 15px;
  }
`;
export const PercentagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: column wrap;
  margin-left: auto;
  margin-right: auto;
  width: 95%;

  .percentage {
    color: #707070;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    margin-right: 20px;
  }

  .percentage-container{
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .data-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;
    justify-content: space-between;
  }
`;

export const GraphPart = styled.div`
  width: ${(props) => props.percentage}%;
  height: 10px;
  background-color: ${(props) => props.color};
  border-radius: ${(props) => props.leftRadius || 0}px
   ${(props) => props.rightRadius || 0}px 
   ${(props) => props.rightRadius || 0}px 
   ${(props) => props.leftRadius || 0}px;
`;

export const GraphContent = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column wrap;
`;

export const CustomProgress = styled(Progress)`

  height: ${(props) => props.percentage}%;
  flex-direction:column;
  flex-flow: column wrap;

  width: 20px

  .ant-progress-inner {
    vertical-align: super;
    height: 10px;
    border-radius: ${(props) => props.leftRadius || 0}px
    ${(props) => props.rightRadius || 0}px 
    ${(props) => props.rightRadius || 0}px 
    ${(props) => props.leftRadius || 0}px;
  }

  .ant-progress-bg {
    border-radius: ${(props) => props.leftRadius || 0}px
    ${(props) => props.rightRadius || 0}px 
    ${(props) => props.rightRadius || 0}px 
    ${(props) => props.leftRadius || 0}px;
    background-color: ${(props) => props.color};
    height: 10px !important;
  }
`;
