/* eslint-disable react/prop-types */
import React from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { documentMask, HandleFormatMoney } from '../../../../services/utils';

import { useAuth } from '../../../../hooks/auth';

import {
  Title, Content,
} from '../styles';

function Receipt({ item, settings }) {
  const { account } = useAuth();
  const { t } = useTranslation();
  return (
    <>
      {item && item.id && (
      <>
        <Title separatorcolor={settings.colors.primary}>
          <h3>{t('comprovante de pagamento')}</h3>
          <span>{format(parseISO(item.date), 'dd/MM/yyyy HH:mm')}</span>
        </Title>
        <Content separatorcolor={settings.colors.primary}>
          <section>
            <h3>{t('pagamento para')}</h3>
            <>
              <span style={{ display: 'block', paddingBottom: '10px' }}>{item.detail.description}</span>
              <span>{item.detail.digitable_line}</span>
            </>

            <h3 style={{ marginTop: '15px' }}>{t('pago por')}</h3>
            <div>
              <span>{t('nome')}</span>
              <span>{account.user.name}</span>
            </div>
            <div>
              <span>{t('documento')}</span>
              <span>{documentMask(account.user.document)}</span>
            </div>

            <div>
              <span>INSTITUIÇÃO:</span>
              <span>CELCOIN PAGAMENTOS S.A.</span>
            </div>
            <div>
              <span>PROCESSADORA:</span>
              <span>{settings?.display_name}</span>
            </div>

          </section>
        </Content>

        <Content separatorcolor={settings.colors.primary}>
          <h3>{t('valor')}</h3>
          <h4>{HandleFormatMoney(item.amount)}</h4>
        </Content>

        <div style={{ textAlign: 'center', borderTop: '1px solid #f1f1f1', paddingTop: '10px' }}>
          <h5 style={{ fontWeight: 600, fontSize: '13px' }}>{t('codigo de autenticacao')}</h5>
          <span>{item.id}</span>
        </div>
      </>
      )}
    </>
  );
}

export default Receipt;
