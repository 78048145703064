import React from 'react';
import { useShop } from '../../../hooks/shop';
import InputUrl from '../../InputUrl';
import UploadImage from '../../UploadImage';
import ShopConfigColor from '../../ShopConfigColor';
import {
  TextWrapper, ShopImageSelect, Title, ImagePlaceholderWrapper,
} from './styles';

export const ShopStyle = () => {
  const {
    setShopLogo, shopLogo, handleGetShopIcons,
  } = useShop();

  function handleFileSelection(event, key) {
    if (event?.target.files) {
      const dataURL = URL.createObjectURL(event.target.files[0]);
      setShopLogo((prevValue) => ({ ...prevValue, [key]: { ...prevValue, url: dataURL } }));
    }
  }

  React.useEffect(() => {
    if (shopLogo.img_web.file && shopLogo.img_mobile.file) {
      const formData = new FormData();
      formData.append('web', shopLogo.img_web.file);
      formData.append('mobile', shopLogo.img_mobile.file);
      handleGetShopIcons(formData);
    }
  }, [handleGetShopIcons, shopLogo]);

  return (
    <>
      <ShopImageSelect>
        <TextWrapper>
          <Title>
            <span>Imagem da loja</span>
            <p>*</p>
          </Title>
          <span>Formato PNG ou JPG de no máximo 5MB</span>
        </TextWrapper>
        <ImagePlaceholderWrapper>
          <UploadImage
            format="desktop"
            name="img_web"
            handleFileSelection={handleFileSelection}
            imgSrc={shopLogo}
          />
          <UploadImage
            format="mobile"
            size={[140, 50]}
            name="img_mobile"
            handleFileSelection={handleFileSelection}
            imgSrc={shopLogo}

          />
        </ImagePlaceholderWrapper>
      </ShopImageSelect>
      <InputUrl />
      <ShopConfigColor />
    </>
  );
};
