/* eslint-disable no-alert */
/* eslint-disable react/jsx-no-bind */
import React, {
  useState, useRef, useMemo, useCallback, useEffect,
} from 'react';

import {
  Skeleton, Table, Dropdown, Menu, Pagination, Input, notification,
} from 'antd';

import {
  FaCalendarAlt, FaPlus, FaEllipsisH, FaBarcode, FaCreditCard, FaDownload, FaSortDown,
} from 'react-icons/fa';

import {
  format, isAfter, parseISO, setHours, setMinutes, startOfMonth,
  isValid, addHours,
} from 'date-fns';
import DayPicker from 'react-day-picker/DayPickerInput';
import { useTranslation } from 'react-i18next';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/pt-br';
import 'react-day-picker/lib/style.css';

import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { HandleFormatMoney } from '../../services/utils';
import { useSettings } from '../../hooks/settings';

import {
  Content, FilterDate, SelectionButton, CenterContent,
  GraphsContainer, GraphContent, PercentagesContainer, CustomProgress,
  TypesContainer,
  TypeContainer,
  InfosContainer,
} from './styles';
import useOnClickOutside from '../../hooks/onClickOutside';
import PixIcon from '../../assets/PixIcon';
import CustomFileIcon from '../../components/CustomFileIcon';

const { Column } = Table;
const { Search } = Input;

function Charges() {
  const { t } = useTranslation();
  const [charges, setCharges] = useState([]);
  const [loadingGraph, setLoadingGraph] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const [loadingCharges, setLoadingCharges] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [, setTotalItemsPage] = useState(0);
  const { settings } = useSettings();
  const history = useHistory();
  const endRef = useRef(null);
  const startRef = useRef(null);
  const [orderBy, setOrderBy] = useState(null);
  const [start, setStart] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [end, setEnd] = useState(format(new Date(), 'yyyy-MM-dd'));
  const refStartCalendar = useRef();
  const refEndCalendar = useRef();
  useOnClickOutside(refStartCalendar, () => startRef.current.handleInputBlur());
  useOnClickOutside(refEndCalendar, () => endRef.current.handleInputBlur());

  const startDate = useMemo(() => format(parseISO(start), 'dd/MM/yyyy'), [start]);
  const endDate = useMemo(() => format(parseISO(end), 'dd/MM/yyyy'), [end]);

  const loadGraphData = useCallback(async () => {
    setLoadingGraph(true);
    try {
      const url = `charges/statistics?start=${start}&end=${format(addHours(new Date(end), 3), 'yyyy-MM-dd HH:mm')}`;
      const { data } = await api.get(url);
      setGraphData(data);
    } catch (err) {
      console.log(err.response.data);
    }
    setLoadingGraph(false);
  }, [start, end]);

  useEffect(() => {
    loadGraphData();
  }, [loadGraphData]);

  const handleLoadCharges = useCallback(async (selectedPage) => {
    setLoadingCharges(true);
    try {
      const { data } = await api.get(
        `charges?start=${start}&end=${format(addHours(new Date(end), 3), 'yyyy-MM-dd HH:mm')}&page=${selectedPage
          ? selectedPage - 1 : 0}${orderBy ? `&orderField=${orderBy}` : ''}`,
      );

      setCurrentPage(selectedPage ? selectedPage - 1 : 0);
      setCharges(data.items);
      setTotalPages(data.total_pages + 1);
      setTotalItems(data.total_items);
      setTotalItemsPage(data.total_items_page);
    } catch (err) {
      console.log(err);
    }
    setLoadingCharges(false);
  }, [start, end, orderBy]);

  const orderCharges = useCallback(async (clicked) => {
    if (orderBy === clicked) {
      setOrderBy(null);
      return;
    }

    try {
      setOrderBy(clicked);
      handleLoadCharges();
    } catch (err) {
      console.log(err);
    }
  }, [handleLoadCharges, orderBy]);

  const handleSearchCharges = useCallback(async (search) => {
    if (!search || !search.length) {
      handleLoadCharges();
      return;
    }
    setLoadingCharges(true);
    try {
      const { data } = await api.get(
        `charges/search?text=${search}`,
      );

      setCurrentPage(0);
      setCharges(data);
      setTotalPages(1);
      setTotalItems(data.length);
      setTotalItemsPage(data.length);
    } catch (err) {
      console.log(err);
    }
    setLoadingCharges(false);
  }, [handleLoadCharges]);

  const handleDownloadReport = useCallback(async (type) => {
    try {
      const u = `/charges/reports/${type}?start=${start}&end=${format(addHours(new Date(end), 3), 'yyyy-MM-dd HH:mm')}`;
      const { data } = await api.get(u);
      setTimeout(() => {
        window.open(data.fileName, '_blank', 'noopener,noreferrer');
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  }, [start, end]);

  const handleOptionsReport = useCallback(async (e) => {
    if (e.key === '1') {
      handleDownloadReport('pdf');
    } else if (e.key === '2') {
      handleDownloadReport('xlsx');
    }
  }, [handleDownloadReport]);

  function formatDate(date) {
    return format(parseISO(date), 'dd/MM/yyyy');
  }

  function formatPickerData(date) {
    return format(date, 'dd/MM/yyyy');
  }

  const handleChangeDate = useCallback((day, type) => {
    if (isValid(day)) {
      const formatDay = format(day, 'yyyy-MM-dd');
      if (type === 'start') {
        setStart(formatDay);
      } else {
        setEnd(formatDay);
      }
    }
  }, []);

  useEffect(() => {
    handleLoadCharges();
  }, [handleLoadCharges]);

  const getStatus = useCallback((item) => {
    const now = setMinutes(setHours(new Date(), 8), 10);
    const dueAt = setMinutes(setHours(addHours(parseISO(item.due_at), 3), 8), 11);

    if (item.charge_type.name === 'BILLET') {
      if (item.paid_at && item.paid_amount) {
        return <span style={{ color: '#38c77e', fontWeight: 600 }}>{t('recebido')}</span>;
      }
    }

    if (item.deleted_at) {
      return <span style={{ color: 'red', fontWeight: 600 }}>Cancelado</span>;
    }

    if (item.paid_at) {
      return <span style={{ color: '#38c77e', fontWeight: 600 }}>{t('pago (em processamento)')}</span>;
    }

    if (isAfter(now, dueAt)) {
      return <span style={{ color: 'red', fontWeight: 600 }}>{t('vencido')}</span>;
    }

    return <span style={{ color: 'inherit', fontWeight: 600 }}>{t('em aberto')}</span>;
  }, [t]);

  const getType = useCallback((type) => {
    switch (type.charge_type.name) {
      case 'BILLET':
        return 'Boleto';
      case 'CARD':
        return 'Cartão de crédito';
      case 'PIX':
        return 'Pix';
      default:
        return '';
    }
  }, []);

  const handleOptions = useCallback(async (e, obj) => {
    if (e.key === '1') {
      let linkUrl = `https://qesh-storage.s3.amazonaws.com/billets/${obj.type_id}/bill_file.pdf`;
      if (obj.charge_type.name === 'CARD') {
        linkUrl = `${settings.payment_url || 'https://payment.qesh.ai'}/card/${obj.type_id}`;
      }
      navigator.clipboard.writeText(linkUrl);
      notification.success({ message: t('link copiado com sucesso') });
    }
    if (e.key === '2') {
      if (obj.charge_type.name === 'BILLET') {
        window.open(`https://qesh-storage.s3.amazonaws.com/billets/${obj.type_id}/bill_file.pdf`, '_blank', 'noopener,noreferrer');
      } else if (obj.charge_type.name === 'CARD') {
        window.open(`${settings.payment_url || 'https://payment.qesh.ai'}/card/${obj.type_id}`, '_blank', 'noopener,noreferrer');
      }
    }

    if (e.key === '3') {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Deseja mesmo cancelar essa cobrança? Essa ação não pode ser desfeita.')) {
        await api.patch(`/charges/cancel/${obj.id}`);
      }
      handleLoadCharges();
      loadGraphData();
    }
  }, [settings, t, handleLoadCharges, loadGraphData]);

  return settings && settings.name ? (
    <>
      <div style={{
        display: 'flex', alignItems: 'center', maxWidth: '1060px', justifyContent: 'space-between',
      }}
      >
        <h1 style={{ marginLeft: 10 }}>{t('cobrancas')}</h1>
        <div style={{ display: 'flex' }}>
          <SelectionButton
            primaryColor={settings.colors.primary}
            onClick={() => history.push('/charges/new')}
            style={{ marginLeft: 10, padding: '3px 17px' }}
          >
            <div style={{ display: 'flex' }}>
              <FaPlus
                color={settings.colors.primary}
                size={15}
                style={{ alignSelf: 'center', marginRight: 10 }}
              />
              <span style={{ color: settings.colors.primary }}>{t('nova cobranca')}</span>
            </div>
          </SelectionButton>
          <SelectionButton
            primaryColor={settings.colors.primary}
            onClick={() => history.push('/charges/import')}
            style={{ marginLeft: 10, padding: '3px 17px' }}
          >
            <div style={{ display: 'flex' }}>
              <FaPlus
                color={settings.colors.primary}
                size={15}
                style={{ alignSelf: 'center', marginRight: 10 }}
              />
              <span style={{ color: settings.colors.primary }}>{t('importar cobrancas')}</span>
            </div>
          </SelectionButton>
        </div>
      </div>
      <Content>

        <FilterDate>
          <span>{t('de:')}</span>
          <section ref={refStartCalendar}>
            <DayPicker
              ref={startRef}
              dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
              format="dd/MM/yyyy"
              formatDate={formatPickerData}
              value={startDate}
              onDayChange={(day) => handleChangeDate(day, 'start')}
            />
            <FaCalendarAlt
              color={settings.colors.primary}
              onClick={() => startRef.current.handleInputClick()}
            />
          </section>

          <span>{t('ate:')}</span>

          <section ref={refEndCalendar}>
            <DayPicker
              ref={endRef}
              dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-br' }}
              format="dd/MM/yyyy"
              formatDate={formatPickerData}
              onDayChange={(day) => handleChangeDate(day, 'end')}
              value={endDate}
            />
            <FaCalendarAlt
              color={settings.colors.primary}
              onClick={() => endRef.current.handleInputClick()}
            />
          </section>
        </FilterDate>
        {loadingGraph ? (
          <>
            <Skeleton active title={t('cobrancas')} loading paragraph={2} rows={2} />
            <Skeleton active title={t('cobrancas')} loading paragraph={2} rows={2} />
            <Skeleton active title={t('cobrancas')} loading paragraph={2} rows={2} />
          </>
        ) : (
          <>
            <GraphsContainer>
              <h3 style={{ fontWeight: 'bold', color: '#707070' }}>{t('relatorio de cobrancas')}</h3>
              <PercentagesContainer>
                <div className="data-container">
                  <div className="percentage-container">
                    <span style={{ color: '#707070' }}>{t('pago')}</span>
                    <span className="percentage">{graphData.paidPercent || 0}%</span>
                  </div>
                  <div className="percentage-container">
                    <span style={{ color: '#06A532', fontWeight: 'bold' }}>
                      {HandleFormatMoney(graphData.paidValue)}
                    </span>
                    <span style={{ color: '#707070' }}>{graphData.paidCount}
                      {graphData.paidCount > 1 ? ` ${t('cobrancas')}` : ` ${t('cobranca')}`}
                    </span>
                  </div>
                </div>
                <div className="data-container">
                  <div className="percentage-container">
                    <span style={{ color: '#707070' }}>{t('pendente')}</span>
                    <span className="percentage">{graphData.pendingPercent || 0}%</span>
                  </div>
                  <div className="percentage-container">
                    <span style={{ color: '#D5B43C', fontWeight: 'bold' }}>
                      {HandleFormatMoney(graphData.pendingValue)}
                    </span>
                    <span style={{ color: '#707070' }}>{graphData.pendingCount}
                      {graphData.pendingCount > 1 ? ` ${t('cobrancas')}` : ` ${t('cobranca')}`}
                    </span>
                  </div>
                </div>
                <div className="data-container">
                  <div className="percentage-container">
                    <span style={{ color: '#707070' }}>{t('vencido')}</span>
                    <span className="percentage">{graphData.overduePercent || 0}%</span>
                  </div>
                  <div className="percentage-container">
                    <span style={{ color: '#D00010', fontWeight: 'bold' }}>
                      {HandleFormatMoney(graphData.overdueValue)}
                    </span>
                    <span style={{ color: '#707070' }}>{graphData.overdueCount}
                      {graphData.overdueCount > 1 ? ` ${t('cobrancas')}` : ` ${t('cobranca')}`}
                    </span>
                  </div>
                </div>
              </PercentagesContainer>
              <GraphContent>
                <CustomProgress
                  percent={100}
                  percentage={graphData.paidPercent}
                  leftRadius={15}
                  rightRadius={(graphData.pendingPercent
                    || graphData.overduePercent
                    || graphData.totalCount === 0) ? 0 : 15}
                  status="active"
                  color="#06A532"
                  showInfo={false}
                />
                <CustomProgress
                  percent={100}
                  percentage={graphData.pendingPercent}
                  leftRadius={graphData.paidPercent || graphData.totalCount === 0 ? 0 : 15}
                  rightRadius={graphData.overduePercent || graphData.totalCount === 0 ? 0 : 15}
                  // status="active"
                  color="#D5B43C"
                  showInfo={false}
                />
                <CustomProgress
                  percent={100}
                  percentage={graphData.overduePercent}
                  leftRadius={(graphData.paidPercent
                    || graphData.pendingPercent
                    || graphData.totalCount === 0) ? 0 : 15}
                  rightRadius={15}
                  // status="active"
                  color="#D00010"
                  showInfo={false}
                />
              </GraphContent>
              <CenterContent style={{ flexFlow: 'column' }}>
                <span style={{
                  fontSize: 16,
                  fontWeight: 'normal',
                  color: '#707070',
                }}
                >
                  {t('total de cobrancas nesse periodo')}
                </span>
                <span style={{ color: '#707070', fontSize: 20 }}>
                  {HandleFormatMoney(graphData.totalValue)}
                  <span style={{ fontSize: 15 }}>  em </span> {graphData.totalCount} {t('cobrancas')}
                </span>
              </CenterContent>
            </GraphsContainer>
            <InfosContainer>
              <TypesContainer>
                <h3 style={{ fontWeight: 'bold', color: '#707070' }}>{t('tipos de cobranca')}</h3>
                <div style={{
                  display: 'flex', width: '100%', justifyContent: 'space-around', flexWrap: 'wrap',
                }}
                >
                  <TypeContainer>
                    <FaBarcode size={30} color="#707070" />
                    <div className="info">
                      <div className="type-count">{graphData.billets.totalCount}</div>
                      <div className="type-name">
                        {graphData.billets.totalCount > 1 ? ` ${t('boletos')}` : ` ${t('boleto')}`}
                      </div>
                      <div className="type-value">{HandleFormatMoney(graphData.billets.totalValue)}</div>
                    </div>
                  </TypeContainer>
                  <TypeContainer>
                    <FaCreditCard size={30} color="#707070" />
                    <div className="info">
                      <div className="type-count">{graphData.cards?.totalCount || 0}</div>
                      <div className="type-name">{t('cartao')}</div>
                      <div className="type-value">{HandleFormatMoney(graphData.cards?.totalValue || 0)}</div>
                    </div>
                  </TypeContainer>
                  <TypeContainer>
                    <div style={{ width: 30, height: 30 }}>
                      <PixIcon color="#707070" />
                    </div>
                    <div className="info">
                      <div className="type-count">0</div>
                      <div className="type-name">Pix</div>
                      <div className="type-value">{HandleFormatMoney(0)}</div>
                    </div>
                  </TypeContainer>
                </div>
              </TypesContainer>
              <TypesContainer>
                <h3 style={{ fontWeight: 'bold', color: '#707070' }}>{t('cobrancas em atraso')}</h3>
                <div style={{
                  display: 'flex', width: '100%', justifyContent: 'space-around', flexWrap: 'wrap',
                }}
                >
                  <TypeContainer>
                    <FaBarcode size={30} color="#707070" />
                    <div className="info">
                      <div className="type-count">{graphData.billets.overdueCount}</div>
                      <div className="type-name">
                        {graphData.billets.overdueCount > 1 ? ` ${t('boletos')}` : ` ${t('boleto')}`}
                      </div>
                      <div className="type-value">{HandleFormatMoney(graphData.billets.overdueValue)}</div>
                    </div>
                  </TypeContainer>
                  <TypeContainer>
                    <FaCreditCard size={30} color="#707070" />
                    <div className="info">
                      <div className="type-count">{graphData.cards.overdueCount}</div>
                      <div className="type-name">{t('cartao')}</div>
                      <div className="type-value">{HandleFormatMoney(graphData.cards.overdueValue)}</div>
                    </div>
                  </TypeContainer>
                  <TypeContainer>
                    <div style={{ width: 30, height: 30 }}>
                      <PixIcon color="#707070" />
                    </div>
                    <div className="info">
                      <div className="type-count">0</div>
                      <div className="type-name">Pix</div>
                      <div className="type-value">{HandleFormatMoney(0)}</div>
                    </div>
                  </TypeContainer>
                </div>
              </TypesContainer>
            </InfosContainer>
          </>
        )}

        <div style={{
          marginLeft: 10, marginBottom: 10, marginTop: 30, display: 'flex', flexWrap: 'wrap', alignItems: 'center',
        }}
        >
          <h3 style={{ fontWeight: 'bold', color: '#707070', paddingLeft: 20 }}>{t('lista de cobrancas')}</h3>
          <Search
            placeholder={t('pesquisar por nome, documento...')}
            allowClear
            size="middle"
            style={{
              marginLeft: 'auto', width: 350, maxWidth: '100%',
            }}
            onSearch={handleSearchCharges}
          />
          <Dropdown
            trigger={['click']}
            onC
            overlay={(
              <Menu onClick={(e) => handleOptionsReport(e)}>
                <Menu.Item key="1">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CustomFileIcon
                      size={30}
                      text="PDF"
                      onClick={() => { }}
                    />
                    <span style={{ marginLeft: 10, fontWeight: 'bold', color: '#707070' }}>PDF</span>
                  </div>
                </Menu.Item>
                <Menu.Item key="2">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CustomFileIcon
                      size={30}
                      text="XLS"
                      onClick={() => { }}
                    />
                    <span style={{ marginLeft: 10, fontWeight: 'bold', color: '#707070' }}>Arquivo Excel</span>
                  </div>
                </Menu.Item>
                {/* <Menu.Item key="3">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CustomFileIcon
                          size={30}
                          text="CSV"
                          onClick={() => {}}
                        />
                        <span style={{ marginLeft: 10, fontWeight: 'bold', color: '#707070' }}>PDF</span>
                      </div>
                    </Menu.Item> */}
              </Menu>
            )}
          >
            <span style={{
              cursor: 'pointer',
              color: settings.colors.primary,
              fontWeight: 'bold',
              marginLeft: 20,
            }}
            >{t('baixar extrato')} <FaDownload size={10} color={settings.colors.primary} />
            </span>
          </Dropdown>
        </div>
        {loadingCharges ? (
          <>
            <Skeleton active title={t('cobrancas')} loading paragraph={2} rows={2} />
            <Skeleton active title={t('cobrancas')} loading paragraph={2} rows={2} />
            <Skeleton active title={t('cobrancas')} loading paragraph={2} rows={2} />
          </>
        ) : (
          <div style={{ margin: 10, width: '100%' }}>
            <Table
              dataSource={charges}
              pagination={false}
              rowKey="id"
              locale={{
                emptyText: t('nenhuma cobranca para exibir'),
              }}
              scroll={{ x: true }}
            >

              <Column
                filterDropdown
                onFilterDropdownVisibleChange={() => orderCharges('charged_name')}
                style={{ fontWeight: '700' }}
                filterIcon={<FaSortDown size={20} />}
                title={t('nome')}
                dataIndex="charged_name"
                key="charged_name"
                responsive={['xs', 'sm', 'md', 'xl']}
              />

              <Column
                filterDropdown
                onFilterDropdownVisibleChange={() => orderCharges('charged_document')}
                style={{ fontWeight: '700' }}
                filterIcon={<FaSortDown size={20} />}
                title={t('cpf / cnpj')}
                dataIndex="charged_document"
                key="charged_document"
                responsive={['xs', 'sm', 'md', 'xl']}
              />

              <Column
                filterDropdown
                onFilterDropdownVisibleChange={() => orderCharges('created_at')}
                title={t('emissao')}
                filterIcon={<FaSortDown size={20} />}
                dataIndex="created_at"
                key="created_at"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(text) => (
                  <>
                    {formatDate(text)}
                  </>
                )}
              />

              <Column
                filterDropdown
                onFilterDropdownVisibleChange={() => orderCharges('due_at')}
                title={t('vencimento')}
                filterIcon={<FaSortDown size={20} />}
                dataIndex="due_at"
                key="due_at"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(text) => (
                  <>
                    {formatDate(text)}
                  </>
                )}
              />

              <Column
                filterDropdown
                onFilterDropdownVisibleChange={() => orderCharges('amount')}
                title={t('valor')}
                filterIcon={<FaSortDown size={20} />}
                dataIndex="amount"
                key="amount"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(text) => (
                  <>
                    {HandleFormatMoney(Number(text))}
                  </>
                )}
              />

              <Column
                title={t('valor recebido')}
                dataIndex="paid_amount"
                key="paid_amount"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(text, item) => (
                  <>
                    {item.credited ? HandleFormatMoney(Number(text)) : 'R$ 0,00'}
                  </>
                )}
              />

              <Column
                filterDropdown
                onFilterDropdownVisibleChange={() => orderCharges('charge_type.name')}
                title={t('tipo')}
                filterIcon={<FaSortDown size={20} />}
                dataIndex="charge_type.name"
                key="charge_type.name"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(text, record) => (
                  <>
                    {getType(record)}
                  </>
                )}
              />

              <Column
                filterDropdown
                onFilterDropdownVisibleChange={() => orderCharges('paid_at')}
                title={t('status')}
                filterIcon={<FaSortDown size={20} />}
                dataIndex="paid_at"
                key="paid_at"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(text, record) => (
                  <>
                    {getStatus(record)}
                  </>
                )}
              />

              <Column
                title=""
                align="center"
                dataIndex="name"
                key="name"
                responsive={['xs', 'sm', 'md', 'xl']}
                render={(_text, record) => (
                  <Dropdown
                    trigger={['click']}
                    overlay={(
                      <Menu onClick={(e) => handleOptions(e, record)}>
                        <Menu.Item key="1">{t('copiar o link da cobranca')}</Menu.Item>
                        <Menu.Item key="2">{t('visualizar cobranca')}</Menu.Item>
                        {!record.paid_at && !record.deleted_at && record.charge_type.name === 'BILLET'
                          && <Menu.Item key="3">{t('cancelar cobranca')}</Menu.Item>}
                      </Menu>
                    )}
                  >
                    <FaEllipsisH style={{ cursor: 'pointer' }} />
                  </Dropdown>

                )}
              />
            </Table>
            <Pagination
              current={currentPage + 1}
              onChange={handleLoadCharges}
              total={totalItems}
              pageSize={20}
              style={{ alignSelf: 'flex-end' }}
            />
          </div>
        )}

      </Content>
    </>
  ) : (<></>);
}

export default Charges;
