/* eslint-disable react/jsx-no-bind */
import React, {
  useState, useRef,
} from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, Content } from './styles';
import { Row, Col } from '../../styles/components';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Notification from '../../components/Notification';

import formErrors from '../../services/formErrors';

import api from '../../services/api';
import getErrors from '../../services/errors';

import { useSettings } from '../../hooks/settings';

function ForgotPassword() {
  const { t } = useTranslation();
  const formRef = useRef();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const { settings } = useSettings();
  const history = useHistory();

  async function handleForm(data) {
    formRef.current.setErrors({});
    setError('');
    try {
      const schema = Yup.object().shape({
        document: Yup.string().required(t('o documento e obrigatorio')),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);
      const { data: response } = await api.post('/forgot-password',
        {
          document: data.document,
          client_name: settings.name,
        });
      setSuccess(response.message);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = formErrors(err);
        formRef.current.setErrors(errors);
        return;
      }
      setError(getErrors(err));
    }
  }

  return settings && settings.name ? (
    <Container>
      <div className="content-logo">
        <img src={settings.default_logo} alt="Logo" />
      </div>

      <Content>
        <Notification
          type="error"
          visible={!!error}
          title={t('erro ao recuperar senha')}
          hidden={() => setError('')}
        >
          {error}
        </Notification>
        <Notification
          visible={!!success}
          title={t('recupere sua senha pelo email')}
          hidden={() => { setSuccess(''); history.replace('/'); }}
        >
          {success}
        </Notification>
        <div>
          <h1>
            {t('recupere a sua senha de acesso')}
          </h1>

          <Form ref={formRef} onSubmit={handleForm}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <Input
                  type="text"
                  name="document"
                  id="document"
                  label={t('insira o cpf/cnpj cadastrado')}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} />
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="bottom">
                <Button center right={false} type="submit">
                  {loading ? (
                    <span><LoadingOutlined /></span>
                  ) : (
                    <span>{t('recuperar senha')}</span>
                  )}
                </Button>
              </Col>
            </Row>
          </Form>

        </div>
      </Content>

    </Container>
  ) : (<></>);
}

export default ForgotPassword;
