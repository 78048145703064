/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message as messageAntd, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SpanSub, DualButtonsDisposition } from './styles';
import InputN from '../../../../../components/InputN';
import ButtonN from '../../../../../components/ButtonN';
import api from '../../../../../services/api';
import { SubTitle, Title } from '../../../styles';
import { useSteps } from '../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../hooks/checkpoint';
import { useSettings } from '../../../../../hooks/settings';
import ModalForTransition from '../../../../../components/ModalForTransition/index';

const Cellphone = () => {
  const {
    nextStep, prevStep, step, stepsPf, exactStep,
  } = useSteps();
  const { settings } = useSettings();
  const history = useHistory();

  const { checkpoint, setCheckpoint } = useCheckpoint();
  const [cellNumber, setCellNumber] = useState({ cellPhoneNumber: '' });
  const [cellNumberPreview, setCellNumberPreview] = useState({ cellPhoneNumberPreview: 0 });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [, setErrorInRequisition] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (checkpoint?.cpfData?.name !== undefined) {
      exactStep(2);
    } else {
      exactStep(1);
      history.replace('/register/basicData');
    }
  }, []);

  useEffect(() => {
    setCellNumber({ cellPhoneNumber: '' });
  }, []);

  const formatNumberReceiverCode = () => {
    const dddNumber = cellNumber.cellPhoneNumber.slice(0, 2);
    const numberPhone = cellNumber.cellPhoneNumber.slice(2);
    return `${dddNumber} ${numberPhone}`;
  };

  const handleAdvance = (numberOfSteps) => {
    setCheckpoint({ ...checkpoint, cellphoneConfirmationCode: 'confirmed', cellNumber: cellNumber.cellPhoneNumber });
    nextStep(numberOfSteps);
    setCellNumber({ cellPhoneNumber: '' });
    if (numberOfSteps === 1) {
      messageAntd.success('Um SMS foi enviado para o número informado.');
    } else {
      messageAntd.success(settings.name === 'oriental_bank' ? 'Número enviado' : 'O número informado já foi validado.');
    }
    if (stepsPf.length >= step + numberOfSteps) {
      history.push(`/register/${stepsPf[step + numberOfSteps]}`);
    } else {
      history.push(`/register/${stepsPf[step]}`);
    }
  };

  const handleSMS = () => {
    if (error) {
      message.error('Verifique o número informado.');
    }
    if (cellNumber.cellPhoneNumber?.length === 11) {
      setShowConfirmationModal(true);
    }
  };

  const handleSend = async () => {
    setLoading(true);
    setError(false);
    setErrorInRequisition(false);
    await api
      .post('onboarding/send', {
        client_name: settings.name,
        document: checkpoint.cpfCnpj,
        contact: formatNumberReceiverCode(),
      })
      .then((response) => {
        setLoading(false);
        setMessage(response.data.message);
        if (response.data.message === `The ${formatNumberReceiverCode()} was already validate.`) {
          setMessage('O número informado já foi validado.');
          return handleAdvance(2);
        }
        handleAdvance(1);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message === `The ${formatNumberReceiverCode()} was already validate.`) {
          setMessage('O número informado já foi validado.');
          return handleAdvance(2);
        }
        console.log('handleSend catch: ', error);
        setError(true);
        setErrorInRequisition(true);
        messageAntd.error(error.response.data.message);
        setMessage(error.response.data.message);
        console.error('smsRequisition: ', error.response.data.message);
      });
  };

  return (
    <>
      <Title>Só mais alguns passos...</Title>
      <SubTitle>
        <header>Qual o número do seu celular? </header>
        <span>Agora vamos garantir que somente você terá acesso à sua conta. </span>
      </SubTitle>
      <InputN.Mask
        id="conf-celular"
        label="Número de celular"
        numericOnly
        blocks={[0, 2, 0, 1, 4, 4]}
        delimiters={['(', ')', ' ', ' ', '-']}
        placeholder="(00) 0 0000-0000"
        onChange={(e) => {
          setCellNumber({ ...cellNumber, cellPhoneNumber: e.target.rawValue });
          setCellNumberPreview({ ...cellNumberPreview, cellPhoneNumberPreview: e.target.value });
        }}
        value={cellNumber.cellPhoneNumber}
      />
      {/* {cellNumber.cellPhoneNumber.length === 0 ? null : cellNumber.cellPhoneNumber.length < 11 ? (
    <Alert message={messages.celularInvalid} type='error' />
   ) : null} */}
      <SubTitle>
        <SpanSub>Não se esqueça do DDD</SpanSub>
      </SubTitle>
      {/* {errorInRequisition ? <Alert message={message} type='error' /> : null} */}
      <ButtonN
        onClick={() => {
          {settings.name === 'oriental_bank' ? handleAdvance(2) : handleSMS();}
        }}
        disabled={cellNumber.cellPhoneNumber.length < 11 || showConfirmationModal}
        type="primary"
        className="botao"
      >
        {settings.name === 'oriental_bank' ? 'Continuar' : 'Receber código via SMS'}
      </ButtonN>
      <ButtonN
        onClick={() => {
          prevStep();
          history.push(`/register/${stepsPf[step - 1]}`);
        }}
        disabled={loading}
      >
        Voltar
      </ButtonN>

      <ModalForTransition
        visibility={showConfirmationModal}
        setVisibility={setShowConfirmationModal}
        maskClosable={!loading}
        title={(
          <h1
            blocks={[0, 2, 0, 1, 4, 4]}
            delimiters={['(', ')', ' ', ' ', '-']}
            style={{
              height: '30%',
              textAlign: 'center',
              alignItems: 'center',
              marginBottom: '1rem',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            {cellNumberPreview.cellPhoneNumberPreview}
          </h1>
   )}
        style={{ height: '100%' }}
      >
        <p
          style={{
            height: '30%',
            width: '100%',
            marginBottom: '1rem',
          }}
        >
          Confirme seu número de contato
        </p>
        <DualButtonsDisposition>
          <ButtonN
            size="large"
            className="botao"
            onClick={() => {
              setShowConfirmationModal(false);
            }}
            disabled={loading}
          >
            Alterar
          </ButtonN>
          <ButtonN
            size="large"
            type="primary"
            className="botao"
            onClick={() => handleSend()}
            loading={loading}
            disabled={cellNumber.cellPhoneNumber.length < 11}
            icon={loading && <Spin indicator={<LoadingOutlined />} />}
          >
            {!loading && 'Confirmar'}
          </ButtonN>
        </DualButtonsDisposition>
      </ModalForTransition>
    </>
  );
};

export default Cellphone;
