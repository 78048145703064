import styled from 'styled-components';

import { Layout } from 'antd';

const { Header } = Layout;

export const Container = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  min-height: 100px;
  min-width: 330px;

  @media(max-width: 800px) {
    flex-direction: column;
    justify-content: start;
    height: auto;
    min-height: initial;
  }

  img {
    max-width: 200px;
    max-height: 100%;
    padding: 20px;
  }
`;
