import styled from 'styled-components';

export const ShopImageSelect = styled.div`
  width:100%;
  min-height: 200px;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  gap:15px;
`;

export const TextWrapper = styled.div`
  width:fit-content;
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:flex-start;
`;

export const Title = styled.div`
  width:fit-content;
  display:flex;
  gap:5px;
  justify-content:center;
  align-items:center;

  span {
    font-size: 1rem;
    font-weight: bold;
  }

  p {
    color: red;
  }
`;

export const ImagePlaceholderWrapper = styled.div`
  width:90%;
  height:70%;
  display:flex;
  justify-content:center;
  align-items:center;
  gap:50px;
  position:relative;

`;
