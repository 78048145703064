/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'moment/locale/pt-br';
import 'react-day-picker/lib/style.css';
import { Divider } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  Content, TitleHeader, ContainerButtom, HeaderArea, Subtitle,
} from '../styles';
import { useSettings } from '../../../hooks/settings';
import ModernButton from '../../../components/ModernButton';
import { useAuth } from '../../../hooks/auth';

function InviteAcepted() {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const { signOut } = useAuth();
  return (
    <>
      <Content>
        <HeaderArea>
          <TitleHeader>{t('Convite aceito!')}</TitleHeader>
        </HeaderArea>
      </Content>
      <Divider style={{ borderColor: settings.colors.primary, marginBottom: 24 }} />
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Subtitle>
          {t(
            'Agora basta você sair da conta e após fazer o login de novo podera alternar entre sua conta Pessoa Física e Pessoa Jurídica a qualquer momento.',
          )}
        </Subtitle>
        <Subtitle>{t('Clique neste ícone que estará ao lado do seu nome para trocar de conta.')}</Subtitle>
        <RetweetOutlined style={{ fontSize: 80, color: 'var(--primary)' }} />
      </div>
      <ContainerButtom>
        <ModernButton
          onClick={signOut}
          type="submit"
          style={{
            marginTop: 50,
            width: '50%',
            alignSelf: 'center',
          }}
        >
          <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
            {t('Sair')}
          </Link>
        </ModernButton>
      </ContainerButtom>
    </>
  );
}

export default InviteAcepted;
