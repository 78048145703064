import React from 'react';
import { weekDays, weekDaysTranslate } from '../../utilitys/timeDeclaration';
import CheckBox from '../Checkbox';
import { WeekDay, WeekDays, Wrapper } from './styles';

const WorkDaysInput = ({ shopInfo, handleCheckboxChange, isDisabled = false }) => (
  <Wrapper>
    <span>Dias de funcionamento </span>
    <WeekDays>
      {weekDays.map((day) => {
        const isChecked = shopInfo?.days_store_operation?.includes(weekDaysTranslate[day]);
        return (
          <WeekDay key={day}>
            <p>{day}</p>
            <CheckBox
              isDisabled={isDisabled}
              isChecked={isChecked}
              onCheck={isDisabled ? null : (isChecked) => handleCheckboxChange(weekDaysTranslate[day], isChecked)}
            />
          </WeekDay>
        );
      })}
    </WeekDays>
  </Wrapper>
);

export default WorkDaysInput;
