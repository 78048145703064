import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSun, faHiking, faGlasses, faFaceLaugh, faPerson, faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import {
  Container, TitleArea, Title, TipsArea, TextStyled, InfoArea, InfoStyled, FalseModel, RedText,
} from './styles';
import { useRefresh } from '../../../../hooks/refresh';

const Selfie = () => {
  const { photoAttempts } = useRefresh();
  const lastAttempt = photoAttempts === 1;

  return (
    <Container>
      <TitleArea>
        {
          lastAttempt ? (
            <>
              <Title>Você só tem mais <span style={{ color: '#e22012' }}>1 tentativa</span>!</Title>
              <TextStyled>Você só tem mais uma chance de tirar uma foto dentro dos padrões adequados</TextStyled>
            </>
          ) : (
            <><Title>Confira algumas dicas para você</Title>
              <Title> se sair bem na foto</Title>
            </>
          )
        }
      </TitleArea>
      {
        lastAttempt && (
          <>
            <TipsArea>
              <RedText>
                <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
                Verifique a distância entre seu rosto e o câmera
              </RedText>
              <RedText>
                <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
                Confira a orientação da foto (paisagem/horizontal)
              </RedText>
              <RedText>
                <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
                Confira se sua foto possui nitidez suficiente
              </RedText>
              <RedText>
                <FontAwesomeIcon color="#e22012" icon={faCircleExclamation} />
                Certifique de que seu rosto está centralizado no local indicado
              </RedText>
            </TipsArea>
            <Title style={{ marginTop: '5rem' }}>Além disso</Title>
          </>
        )
      }
      <TipsArea>
        <TextStyled>
          <FontAwesomeIcon icon={faFaceLaugh} />
          Lembre-se de encaixar o seu rosto no círculo para melhores resultados.
        </TextStyled>
        <TextStyled>
          <FontAwesomeIcon icon={faSun} />
          Encontre um lugar com boa iluminação.
        </TextStyled>
        <TextStyled>
          <FontAwesomeIcon icon={faPerson} />
          Essa é uma foto formal, então não faça poses ou caretas.
        </TextStyled>
        <TextStyled>
          <FontAwesomeIcon icon={faGlasses} />
          Retire os acessórios que cobrem seu rosto, como óculos e bonés.
        </TextStyled>
        <TextStyled>
          <FontAwesomeIcon icon={faHiking} />
          Siga as instruções apresentadas na tela.
        </TextStyled>
      </TipsArea>
      <InfoArea>
        <InfoStyled>
          Quando essa mensagem aparecer, clique em permitir para que possamos acessar a sua câmera.
        </InfoStyled>
        <FalseModel src="https://res.cloudinary.com/djbzvkmqm/image/upload/v1638384172/Platter_3_gqtbtn.png" />
      </InfoArea>
    </Container>
  );
};

export default Selfie;
