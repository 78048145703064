/* eslint-disable react/prop-types */
import React from 'react';

import AvatarModal from '../AvatarModal';
import FileUpload from '../FileInput';
import ImagePlaceholder from '../ImagePlaceholder';
import {
  Wrapper,
} from './styles';

const UploadImage = ({
  size = [50, 50], format, name, handleFileSelection, imgSrc, isDisabled = false, width = '50%',
}) => {
  const [displayAvatarEditor, setDisplayAvatarEditor] = React.useState(false);

  const idealFormats = {
    mobile: 'Formato ideal mobile: 50px x 50px',
    desktop: 'Formato ideal desktop: 140px x 50px',
  };

  function handleChange(event) {
    setDisplayAvatarEditor(true);
    handleFileSelection(event, name);
  }

  return (
    <Wrapper width={width}>
      <ImagePlaceholder
        hasDisplaySize
        size={size}
        src={imgSrc[name]?.url}
        handleClick={() => setDisplayAvatarEditor(true)}
      />
      <span>{idealFormats[format]}</span>
      <FileUpload
        name={name}
        handleChange={handleChange}
        fileName={imgSrc[name]?.file?.name}
        isDisabled={isDisabled}
      />
      {displayAvatarEditor
      && (
      <AvatarModal
        size={size}
        name={name}
        imgSrc={imgSrc[name]?.url}
        setDisplayAvatarEditor={setDisplayAvatarEditor}
        handleChange={handleChange}
      />
      )}
    </Wrapper>
  );
};

export default UploadImage;
