import React, { useState, useCallback } from 'react';

import XLSX from 'xlsx';
import { BsFileEarmarkPlus } from 'react-icons/bs';
import { FiLoader } from 'react-icons/fi';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { IUpload, Content, TopicMarker } from './styles';
import api from '../../../services/api';

function ImportCharges() {
  const history = useHistory();
  const { t } = useTranslation();
  const [currentFile, setCurrentFile] = useState(false);
  const [chargesJson, setChargesJson] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    if (!chargesJson) {
      notification.error({ message: t('por favor anexe um documento') });

      return;
    }

    if (chargesJson.length < 1) {
      notification.error({ message: t('esse arquivo não nenhuma linha com os dados corretos') });

      return;
    }

    const formData = {
      charges: chargesJson,
    };

    try {
      const { data: responseData } = await api.post('/billets/multiple', formData);

      if (responseData.createdCharges.length >= 1 && responseData.errors.length < 1) {
        notification.success({ message: t('todas as linhas do arquivo foram importadas com sucesso') });
      }

      if (responseData.createdCharges.length >= 1 && responseData.errors.length >= 1) {
        responseData.errors.forEach((error) => {
          notification.error({ message: `${t('erro na linha')}${error.line}`, description: error.message });
        });

        notification.success({ message: t('demais linhas foram criadas com sucesso') });
      }

      if (responseData.createdCharges <= 0) {
        responseData.errors.forEach((error) => {
          notification.error({ message: `${t('erro na linha')}${error.line}`, description: error.message });
        });

        notification.error({ message: t('erro na importação de todas as linhas da planilha') });
      }

      history.push('/charges');
    } catch (e) {
      notification.error({ message: t('ocorreu um erro tente novamente mais tarde') });
    }
    setLoading(false);
  }, [chargesJson, history, t]);

  return (
    <>
      <h1>{t('importar cobranças em lote')}</h1>
      <Content>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <TopicMarker />
          <h3 style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <a href="https://qesh-storage.s3.amazonaws.com/termos/cobranca_lote.xlsx">
              {t('clique aqui para fazer o download da planilha de exemplo')}
            </a>
          </h3>
        </div>

        <IUpload
          name="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
          listType="text"
          showUploadList
          maxCount={1}
          onChange={(info) => {
            setCurrentFile(info.file);
          }}
          onRemove={() => {
            setCurrentFile(null);
            return currentFile;
          }}
          beforeUpload={(file) => {
            const reader = new FileReader();

            reader.onload = async (f) => {
              try {
                const data = new Uint8Array(f.target.result);
                const workbook = XLSX.read(data, { type: 'array', cellText: false, cellDates: true });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const dataJson = XLSX.utils.sheet_to_json(sheet, { dateNF: 'yyyy-mm-dd' });

                const formattedCharges = [];

                const splitAttributes = (Object.keys(dataJson[0]).filter((attribute) => attribute.includes('Split')));

                const formattedSplits = [];

                const alreadyStored = [];

                splitAttributes.forEach((a) => {
                  if (a.split(' ')[0] === 'Conta') {
                    const number = a[a.length - 1];

                    const attributes = splitAttributes.filter((attr) => attr[attr.length - 1] === number);

                    const accountAttr = attributes.find((attr) => attr.includes('Conta'));
                    const percentAttr = attributes.find((attr) => attr.includes('Porcentagem'));

                    if (!alreadyStored.includes(number)) {
                      formattedSplits.push({
                        percent: Number(String(dataJson[0][percentAttr]).replace(',', '.').trim()),
                        account: String(dataJson[0][accountAttr]).trim(),
                      });
                    }

                    alreadyStored.push(number);
                  }
                });

                for (const item of dataJson) {
                  formattedCharges.push({
                    type: 'CHARGE',
                    description: String(item['Descrição']).trim(),
                    name: String(item['Nome Completo Cobrado']).trim(),
                    document: String(item['Documento Cobrado']).trim().replace(/[^0-9]/g, ''),
                    due_at: String(item.Vencimento).trim(),
                    address: String(item.Logradouro).trim(),
                    number: String(item['Número']).trim(),
                    city: String(item.Cidade).trim(),
                    state: String(item.Estado).trim(),
                    zipcode: String(item.CEP).trim(),
                    neighborhood: String(item.Bairro).trim(),
                    complement: String(item.Complemento).trim(),
                    amount: item.Valor ? Number(String(item.Valor).replace(',', '.').trim()) : 0,
                    charged_email: String(item.Email).trim(),
                    charged_phone: String(item.Celular).trim(),
                    corpoJuros: {
                      juros: {
                        type: 'Percentual',
                        valor: item.Juros ? Number(String(item.Juros).replace(',', '.').trim()) : 0,
                      },
                    },
                    corpoMulta: {
                      multa: {
                        type: 'Percentual',
                        valor: item.Multa ? Number(String(item.Multa).replace(',', '.').trim()) : 0,
                      },
                    },
                    corpoSplit: formattedSplits,
                  });
                }

                setChargesJson(formattedCharges);

                return true;
              } catch (err) {
                notification.error({ message: t('ocorreu um erro ao importar o arquivo') });

                return false;
              }
            };

            reader.readAsArrayBuffer(file);
            return false;
          }}
        >
          <div style={{
            paddingLeft: '16px',
            height: '250px',
          }}
          >
            <div style={{
              height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
              <BsFileEarmarkPlus size={40} />
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginLeft: '16px',
            }}
            >
              <span style={{ color: '#707070', fontWeight: 'bold' }}>{t('anexar arquivo')}</span>
              <span style={{ color: '#707070', textAlign: 'start' }}>
                {t('arraste para esse retangulo...')}
              </span>
            </div>
          </div>
        </IUpload>

        <div style={{ display: 'flex', marginTop: '12px', justifyContent: 'flex-end' }}>
          <Button onClick={handleSubmit} disabled={loading}>
            {!loading ? <span>{t('enviar arquivo')}</span> : <FiLoader />}
          </Button>
        </div>
      </Content>
    </>
  );
}

export default ImportCharges;
