/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
  Container, Title, Text, ButtonDisposition, TextContainer,
} from './styles';
import ButtonN from '../../../../../../components/ButtonN';
import { useSteps } from '../../../../../../hooks/steps';
import { useCheckpoint } from '../../../../../../hooks/checkpoint';
import LowerLeftMessage from '../../../../../../components/LowerLeftMessage';
import { useSettings } from '../../../../../../hooks/settings';

const ContaAprovadaPf = () => {
  const { resetStep } = useSteps();
  const { resetCheckpoint } = useCheckpoint();
  const { checkpoint } = useCheckpoint({
    userName: '',
  });
  const { settings } = useSettings();
  const name = JSON.parse(localStorage.getItem('@qesh:userOnboarding'))?.name?.split(' ')[0];
  const isPending = localStorage.getItem('oldRG');

  return (
    <>
      <Container>
        <TextContainer>
          <FontAwesomeIcon icon={faCheck} className="fa-lg" />
          <Title>
            <span style={{ color: 'var(--primary)' }}>{checkpoint?.cpfData?.name || name ||'Usuário'}</span>, sua
            conta {settings?.display_name?.toLowerCase() === 'kexion' || isPending ? 'está em análise' : 'foi aprovada'}!
          </Title>
          <Text>
            {`Boas-vindas ao banco ${
              settings.display_name ? settings.display_name : 'Banco Cliente'
            }. Aqui você tem toda praticidade e comodidade na palma da sua mão.`}
          </Text>
        </TextContainer>
        <ButtonDisposition>
          <ButtonN
            onClick={() => {
              localStorage.removeItem('oldRG')
              sessionStorage.removeItem('@qesh:token');
              localStorage.removeItem('@qesh:token');
              localStorage.removeItem('@qesh:user');
              localStorage.removeItem('@qesh:account');
              localStorage.removeItem('@documents');
              localStorage.removeItem('@loginApproved');
              resetStep();
              resetCheckpoint();
            }}
            className="botao"
          >
            <Link to="/"> Acessar meu banco</Link>
          </ButtonN>
          <ButtonN
            onClick={() => {
              localStorage.removeItem('oldRG')
              sessionStorage.removeItem('@qesh:token');
              localStorage.removeItem('@qesh:token');
              localStorage.removeItem('@qesh:user');
              localStorage.removeItem('@qesh:account');
              localStorage.removeItem('@documents');
              localStorage.removeItem('@loginApproved');
              resetStep();
              resetCheckpoint();
            }}
            type="primary"
            className="botao"
          >
            <Link to="/"> Baixar o aplicativo</Link>
          </ButtonN>
        </ButtonDisposition>
        <LowerLeftMessage />
      </Container>
    </>
  );
};

export default ContaAprovadaPf;
