import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 95%;
  height:fit-content;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 5px;


`;

export const Label = styled.div`
    font-size: 1rem;
    font-weight: bold;
    width: fit-content;
    word-wrap: no-wrap;
    font-family: 'Fira Sans', sans-serif;
`;

export const Bar = styled.div`
  width: 35%;
  height: 2px;
  background-color: #C4C4C4;
`;
